#assetsTableLoaderTd {
    height: 8rem;
    text-align: center;
    align-items: center;
}

#buttonTableThead{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

#buttonTableThead:hover {
    color: black;
    background: var(--goldColor);
}

.asstesTableContainer {
    width: 95%;
    overflow-x: hidden;
    max-height: 32rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    margin-top: 2rem;
}

.asstesTableContainer thead tr:first-child th {
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg);
    width: 100%;
    /* border-radius: 10px 0 0 0; */
}

.asstesTableContainer thead tr:first-child th div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
}

.asstesTableContainer thead tr:first-child th div p {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
}

.asstesTableContainer thead tr:first-child th div input {
    background-color: #313131;
    height: 1.8rem;
    font-size: 0.9rem;
    border: none;
    padding-left: 0.5rem;
    color: white;
}

.asstesTableContainer thead tr:first-child th div .assetsTableThDivLeft {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.asstesTableContainer thead tr:nth-child(2) th{
    position: sticky;
    top: 4rem;
    z-index: 99;
    background: var(--tableSecondTh);
    height: 1.8rem;
    color: white;
}

.asstesTableContainer tbody tr {
    background: white;
    transition: 0.2s;
}

.asstesTableContainer tbody tr:hover {
    background: var(--tableHover);
    transition: 0.2s;
}

.assetsTableSecondTheadTr th {
    position: sticky;
    top: 3rem;
    font-size: 0.9rem;
    font-weight: 300;
}

.asstesTableContainer tbody td{
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    border: 1px solid var(--tableBorder);
    height: 2rem;
    transition: all ease 0.2s;
}

