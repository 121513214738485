.bookMainPage {
    display: flex;
    flex-direction: column;
    width:100%;
    height: 90%;
    transition: .2s;  
}
.bookPageBtn div:hover {
    filter: invert(0.1)
}

.bookRubiMainContainer {
    
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height: 100%;
    transition: .2s;   
}
.bookRubiOperationContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s;  
    align-items: center;
}

.bookRubiOperationContainerAdm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s;  
    align-items: center;
}

.bookRubiMainContainerShow{
    width: 100%;
}

.bookRubiMainContainerHide {
    left: 50px;
    background-color: rgba(255, 255, 255, 0.6);
    flex-direction: row;
    width: calc(100% - 50px);
    position: fixed;
    z-index: 30;
    height: calc(100% - 50px);
    transition: .3s;
}

.bookRubiMainContainerHourHide {
    margin-left: 1rem;
    padding-left: 2rem;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: row;
    width: 80%;
    position: absolute;
    z-index: 150;
    height: 90vh;
    transition: .3s;
}

.bookRubiWarning {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    height: 100%;
    width: 100%;
}

.bookRubiOrdersContainer {   
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 1;
    background-color: white;
    width: 100%;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 0px 20px -7px #000000;
}


.bookPageBtn div:hover {

    color: white;
}

.bookRubiButtonOrder {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 11rem
}

.bookRubiButtonOrder div {
    border-radius: 0.3rem 0 0rem 0.3rem;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 5rem;
    font-size: 1rem;
    font-weight: 600;
    background: #333333;
    color: #a5a5a5;
    cursor: pointer;
    transition: .2s;
}

.bookRubiButtonOrder a {
    margin-top: 0.5rem;
}

.bookRubiButtonOrder div:hover{
    color: white;
}

.bookRubiOrderHide {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    height: 100%;
    width: 0px;
    max-width: 0;
    overflow: hidden;
    transition: .15s;
}

.bookRubiOrderShow{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 300;
    opacity: 1;
    width: 60%;   
    height: 100%; 
    transition: .3s;
    flex-direction: row;
    overflow: hidden;

    
}

.bookRubiOrderShow button {
  
    margin-top: 8rem;
    height: 2.7rem;
    width: 3rem;
    border-radius: 50%;
    border: 0.1rem solid rgb(168, 168, 168);
    color: solid rgb(168, 168, 168);
    opacity: 1;
    cursor: pointer;
    transition: .2s;
    font-size: 1rem;
    background: rgb(253, 253, 253);
}

.bookRubiOrderShow button:hover {
    color: var(--formBorder);
    border: 0.2rem solid var(--formBorder);
}

@media (max-width: 1368px){

    .bookRubiOrderShow button {
        margin-top: 8rem;
        height: 2rem;
        width: 2.2rem;
        border-radius: 50%;
        border: 0.1rem solid rgb(168, 168, 168);
        color: solid rgb(168, 168, 168);
        opacity: 1;
        cursor: pointer;
        transition: .2s;
        font-size: 1rem;
        background: rgb(253, 253, 253);
    }
}

@media (max-width: 1020px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90%;
   }

   .bookRubiOperationContainer {
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    transition: .2s;   
}
   

   .bookRubiOrderShow {
       width: 95%;
   }

   .bookRubiMainContainer table {
    overflow: auto;
    border-collapse: collapse;
   }

   .bookRubiMainContainer td {
       padding-left: 1.75rem;
       padding-right: 1.75rem;
   }

   
}

@media(max-width: 750px){
    .bookRubiOperationContainer { overflow: scroll;}
    
    .bookRubiMainContainerHide {
        width: 93%;
    }
}

@media (max-width: 550px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }

   .bookRubiOrderShow button {
       border-radius: 0;
       width: 1.5rem;
       height: 1.5rem;
   }

   .bookRubiMainContainerHide {
    width: 92%;
}
}

@media (max-width: 400px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }

   .bookRubiMainContainerHide {
    width: 89%;
}
}

@media (max-width: 350px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }
}