.recomendationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-width: 1100px;
   }
   
   .recommendationsTableContainer {
       width: 95%;
       display: flex;
       flex-direction: column;
       align-items: center;
       overflow-x: hidden;
       -webkit-box-shadow: -7px 0px 9px -7px #000000; 
       box-shadow: -2px 2px 20px -7px #000000;
   }
   
   .containerAddNewAsset{
       width: 60%;
       height: fit-content;
       background: var(--tableSecondTHGray);
       border-radius: 4px;
       -webkit-box-shadow: -7px 0px 9px -7px #000000; 
       box-shadow: -2px 2px 20px -7px #000000;
       border: 1px solid var(--topBarBg);
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr;
       margin: auto;
       margin-top: 1rem;
   }
   
   .selectAsset{
       width: calc(100% - 0.8rem) !important;
       margin-left: 0.4rem !important;
       margin-right: 0.4rem !important;
       background-color: white !important;
       border-radius: 4px !important;
       height: fit-content;
   }
   
   .selectAsset .MuiOutlinedInput-input{
       padding: 0 !important;
   }
   
   .inputTextTitle{
       color: white;
       text-align: center;
       padding: 0.2rem;
   }
   
   .inputTextTitleEdit{
       color: black;
       text-align: center;
       padding: 0.2rem;
   }
   
   .inputInfo{
       font-size: 1rem;
       padding: 0.4rem;
       margin-left: 0.4rem;
       margin-right: 0.4rem;
       margin-bottom: 0.3rem;
       border-radius: 4px;
       border: none;
       height: fit-content;
   }
   
   .inputInfo:focus{
       outline: none;
       background-color: rgb(241, 241, 241);
   }
   
   .inputInfoEdit{
       font-size: 1rem;
       padding: 0.4rem;
       margin-left: auto;
       margin-right: auto;
       margin-bottom: 0.3rem;
       border-radius: 4px;
       border: none;
       height: fit-content;
       width: 80%;
       border: 1px solid rgb(66, 66, 66);
   }
   
   .inputInfoEdit:focus{
       outline: none;
       background-color: rgb(255, 255, 255);
   }
   
   .MuiOutlinedInput-input{
       padding: 0.4rem !important;
   }
   
   .MuiFormControl-root{
       width: calc(100% - 0.4rem);
   }
   
   .currencyTextFieldCustom{
       background-color: white !important;
       border-radius: 4px !important;
       margin: auto !important;
       margin-top: 0 !important;
       margin-bottom: 0.3rem !important;
       width: 96%;
   }
   
   .createRow{
       grid-row-start: 1;
       grid-row-end: 3;
       grid-column-start: 4;
       margin: 0.5rem 0.6rem 0.5rem 0.2rem;
       background: var(--topBarBg);
       border: 0.13rem solid var(--formBorder);
       color: var(--formButtonFontColor);
       font-size: 1.2rem;
       font-weight: 400;
   }
   
   .createRow:hover{
       background: var(--formButtonFontColor);
       color: var(--topBarBg);
   }
   
   .containerClientControl{
       width: 40rem;
   }
   
   .formEditRow{
       width: 30rem;
       margin-left: auto;
       margin-right: auto;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       row-gap: 0.5rem;
       column-gap: 1rem;
   }
   
   .assetsOfferingsReservConfirmation{
       width: 25%;
       /* width: 50%; */
       grid-row-start: 4;
       margin: auto;
   }
   
   .containerPesoPreco{
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: auto 1fr;
       grid-column-start: 1;
       grid-column-end: 3;
       grid-row-start: 2;
   }
   
   .containerCompraVenda{
       width: 96%;
       height: 15rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 1fr auto 2fr;
       text-align: center;
       margin: 2%;
       margin-top: 1rem;
   }
   
   .containerCompraVendaUpdate{
       width: 96%;
       height: 28rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 1fr auto 2fr;
       text-align: center;
       margin: 2%;
       margin-top: 1rem;
   }
   
   .containerCompraVendaAporte{
       /* position: absolute;
       transform: translate(-20rem,8rem);
       width: 66rem; */
       height: 15rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 1fr;
       text-align: center;
   }
   
   .containerCompraVendaResgate{
       height: 15rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 3fr auto 3fr;
       text-align: center;
   }
   
   .containerCompraVenda th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVenda td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .containerCompraVendaUpdate th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVendaUpdate td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .containerCompraVendaAporte th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVendaAporte td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .containerCompraVendaResgate th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVendaResgate td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .divisaoTabelas{
       width: 1px;
       height: 90%;
       background-color: #e9e9e9;
       margin: auto;
   }
   
   .tituloTabelaCompraVenda{
       font-size: 1.2rem;
       width: 90%;
       margin: auto;
       margin-top: 0.5rem;
       margin-bottom: 0.8rem;
       border-bottom: 1px solid #e9e9e9;
       height: 2rem
   }
   
   .tabelaVenda{
       height: 7.8rem;
   }
   
   .containerTabelaVenda tbody {
       display:block;
       height: 6.4rem;
       overflow:auto;
   }
   
   .containerTabelaVendaUpdate tbody {
       display:block;
       height: 19rem;
       margin-bottom: 0.6rem;
       overflow:auto;
   }
   
   .containerTabelaVenda thead, .containerTabelaVenda tbody tr {
       display:table;
       width: 100%;
       table-layout:fixed;
   }
   
   .containerTabelaVendaUpdate thead, .containerTabelaVendaUpdate tbody tr {
       display:table;
       width: 100%;
       table-layout:fixed;
   }
   
   .containerTabelaCompra tbody {
       display:block;
       height: 9.6rem;
       overflow:auto;
   }
   
   .containerTabelaCompra thead, .containerTabelaCompra tbody tr {
       display:table;
       width: 100%;
       table-layout:fixed;
   }
   
   .containerAddVenda{
       width: 99%;
       border: 1px solid #dfdfdf;
       margin: auto;
       margin-top: 0.6rem;
       border-radius: 4px;
   }
   
   .containerAddVenda{
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   }
   
   .inputAddVenda{
       width: 90%;
       margin: 5%;
       font-size: 1rem;
       border-radius: 4px;
       border: 1px solid #c2c2c2;
   }
   
   .inputAddVenda:hover{
       border: 1px solid #2b2b2b;
   }
   
   .labelCurrencyTextField{
       width: 100%;
       text-align: center;
       height: fit-content;
       margin: auto !important;
   }
   
   .inputNumberVenda{
       margin: auto !important;
   }
   
   .addVenda{
       margin: 0.4rem 0.3rem 0.4rem 0.2rem;
       font-size: 1rem;
       border: none;
       background-color: #dddddd;
       border-radius: 2px;
       transition: all ease-in-out 0.1s;
   }
   
   .addVenda:hover{
       background-color: #bbbbbb;
   }
   
   .substitutoAutocomplete{
       margin: 5%;
       width: 90%;
       height: 1.8rem;
       line-height: 1.8rem;
       border: 2px solid #c2c2c2;
       border-radius: 4px;
   }
   
   .tableTrackingWisir td{
       width: 0px;
       font-size: 1rem !important;
   }
   
   .tableTrackingWisir tr{
       width: 0px;
       font-size: 1rem !important;
   }
   
   .containerHistoryList{
       width: 100%;
       height: 20rem;
       background-color: #f3f3f3;
       border-left: 1px solid #dbdbdb;
       border-right: 1px solid #dbdbdb;
   }
   
   .containerAssetsHistory{
       width: 100%;
       height: 14rem;
       margin: auto;
       background-color: #e4e4e4;
       border-left: 1px solid #c2c2c2;
       border-right: 1px solid #c2c2c2;
       border-radius: 4px;
   }
   
   .historyListTitle{
       font-size: 1.1rem;
       padding-top: 0.8rem;
       text-align: center;
   }
   
   .headerHistoryList{
       margin-top: 1rem;
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
       text-align: center;
   }
   
   .headerOperationAssets{
       margin: auto;
       margin-top: 1rem;
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr;
       text-align: center;
   }
   
   .headerOption{
       font-size: 1rem;
       width: 80%;
       margin: auto;
       padding-bottom: 0.4rem;
       border-bottom: 1px solid #dfdfdf;
   }
   
   .loadingOperationList{
       width: 100%;
       height: fit-content;
       margin-top: 5rem;
       text-align: center;
   }
   
   .loadingOperationAssetsList{
       width: 100%;
       height: fit-content;
       margin-top: 4.6rem;
       text-align: center;
   }
   
   .dataHistoryList{
       padding-top: 0.6rem;
       padding-bottom: 0.6rem;
       margin-top: 0.1rem;
       margin-bottom: 0.1rem;
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
       text-align: center;
       background-color: #ececec;
   }
   .dataHistoryList:hover{
       background-color: #e4e4e4;
       cursor: pointer;
   }
   
   .dataOption{
       font-size: 0.9rem;
       width: 90%;
       margin: auto;
       font-weight: 500;
   }
   
   .containerDataHistoryList{
       overflow: auto;
       height: 15rem;
   }
   
   .disclaimerSelectOperation{
       width: 100%;
       height: fit-content;
       margin-top: 6rem;
       text-align: center;
       font-weight: 500;
       font-size: 1.2rem;
   }
   
   .containerCarteiras{
       display: grid;
       grid-template-columns: 1fr 2px 1fr;
       height: 100%;
   }
   
   .divisaoAnteriorPosterior{
       width: 100%;
       height: 84%;
       margin: auto;
       background-color: #d3d3d3;
   }
   
   .dataOperationAssets{
       display: grid;
       grid-template-columns: 1fr 1fr;
       text-align: center;
   }
   .dataOperationAssets:hover{
       background-color: #d8d8d8;
   }
   
   .containerDataOperationAssets{
       max-height: 8.4rem;
       overflow: auto;
   }
   
   .disclaimerEmptyWallet{
       text-align: center;
       padding-top: 4.4rem;
       font-size: 1.2rem;
       font-weight: 500;
   }
   
   .selectWisirTable{
       width: 100%;
       border: 1px solid #bdbdbd;
   }
   
   .selectWisirTable:focus {
       outline: none;
   }

.containerComponent{
    height: 100%;
}

.inputItemQtd{
    width: 40%;
    color: black;
    padding-left: 0.1rem;
}