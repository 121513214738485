.usersToForLoader {
    width: 80rem;
    height: 50rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.usersToForContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80rem;
    height: 50rem;
    background: white;
}

.usersToForHead {
    height: 4rem;
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
}

.usersToForHead p {
    border-bottom:  1px solid var(--goldColor);
    width: 30%;
    padding-left: 2rem;
    font-size: 1.2rem;
}

.usersToForHead button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-right: 1rem;
}

.usersToForHead button:hover {
    background: var(--goldColor);
    color: white;
}

.usersToForBody {  
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: calc(100% - 10rem);
    width: 100%;
}

.usersToForBody b {
    font-size: 1rem;
}

.usersToForBody a {
    font-size: 1.2rem;
}

.usersToForBodySuccessMsg {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.usersToForBodySuccessMsg a {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--goldColor);
}

.usersToForOptions {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 10rem;
}


#usersToForTableContainer {
    max-height: 24rem;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersToForTable thead th{
    position: sticky;
    top: 0;
    z-index: 100;
    background: var(--topBarBg);
    color: white;
    height: 1.8rem;
    font-size: 1rem;
}

#userToForClientsSelectionAllInput {
    margin-right: 0.5rem;
}

#usersToForTable tbody td {
    height: 1.6rem;
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
    transition: 0.2s;
}

#usersToForTable tbody tr:hover {
    background: var(--tableHover);
}

#usersToForTable tbody td:first-child {
    width: 8rem;
}

#usersToForTable tbody td:first-child input:checked tr {
    background: red !important;
}

.usersToForOptions label {
    width: 50%;
}

.usersToForContainer button[type='submit'] {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    padding: 0.5rem;
    width: 6.8rem;
    font-weight: 400;
    height: 2.5rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
    
}

.usersToForContainer button[type='submit']:hover {
    background-color: var(--goldColor);
    color: white;
}

@media(max-width: 1368px){
    .usersToForLoader {
        width: 60rem;
        height: 40rem;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .usersToForContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60rem;
        height: 35rem;
        background: white;
    }
    
    .usersToForHead {
        height: 4rem;
        background: var(--headerAllBg);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
    }
    
    .usersToForHead p {
        border-bottom:  1px solid var(--goldColor);
        width: 30%;
        padding-left: 2rem;
    }

    .usersToForHead button {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
    }

    .usersToForHead button:hover {
        background: var(--buttonHover);
    }
    
    .usersToForBody {  
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: calc(100% - 10rem);
        width: 100%;
    }
    
    .usersToForBody b {
        font-size: 1rem;
    }
    
    .usersToForBody a {
        font-size: 1.2rem;
    }

    .usersToForOptions {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        height: 8rem;
    }

    .usersToForOptions label {
        width: 50%;
    }

    .usersToForContainer button[type='submit'] {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        padding: 0.5rem;
        width: 6.8rem;
        font-weight: 400;
        height: 2.5rem;
        border-radius: 0.6rem;
        transition: 0.2s;
        color: var(--formButtonFontColor);
        
    }
    
    .usersToForContainer button[type='submit']:hover {
        background-color: var(--goldColor);
        color: white;
    }

    @media(max-width: 1368px){
        #usersToForTableContainer {
            max-height: 14rem;
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: hidden;
            -webkit-box-shadow: -7px 0px 9px -7px #000000; 
            box-shadow: -2px 2px 20px -7px #000000;
        }
    }
}