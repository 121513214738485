@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,100;1,300;1,400&display=swap');

*{
    margin: 0;
    padding: 0;
}

#root{
    height: 100vh;
}

body{
    margin: 0;
    padding: 0;
    background:#EBEFF3;
}

body a, p {
    text-decoration: none;
}

body, input, button, textarea {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

table {
    width: 100%;
    border-collapse: collapse;   
}

#errorMsgFields {
    color: #e60909
}

.indentationRigth{
    margin-left: 200px;
    transition: .2s;
    align-items: center;
    justify-content: center;
    height: calc(100% - 48px);
    position: relative;
    overflow-y: auto;
    
}

.selectFilterTable {
    background: #313131;
    color: white;
    height: 1.8rem;
    outline: none;
    font-size: 0.9rem;
    border: none;
    width: 8rem;
}

.tableAllInputThead {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.tableAllInputThead::placeholder{
    font-size: 0.9rem;
}

.buttonWhiteBg{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.buttonWhiteBg:hover {
    background: var(--buttonHover);
    color: white;
}

.buttonTableThead {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.buttonTableThead:hover {
    background: var(--goldColor);
    color: black;
}

.buttonTableThead:focus {
    background: var(--goldColor);
    color: black;
    outline:none
}

.modalCloseButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.modalCloseButton:hover {
    background: var(--buttonHover);
    color: white;
}

.indentationRigthTop{
    margin-left: 200px;
    transition: .2s;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
}

.indentationImgRight img {
    display: none;
}

.indentationNormal {
    margin-left: 0;
    justify-content: center;
    align-items: center;
    height: calc(100% - 48px);
    width: calc(100% - 48px);
}

.indentationNormalTop{
    
    margin-left: 0;
    justify-content: center;
    align-items: center;    

}

.indentationNormalTop h2 {
    font-size: 1.2rem;
}

.body{
    padding-top: 3rem;
    padding-left: 3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

}

.body h2{
    margin-right: 3.2rem;    
}

.body h3{
    margin-right: 3.2rem;    
}

.body h4{
    margin-right: 3.2rem;
}

button {
    cursor: pointer;
}

[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
}

.preloaderHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.notificationAlertFooter {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.textEditorLoading {
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.monitoringTableNameDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.monitoringTableDownloadTitle {
    opacity: 0;
    /* max-width: 0; */
    overflow: hidden;
    right: 0;
    display: flex;
    flex-direction: row;
    gap:0.5rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.monitoringTableDowloadButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:0.5rem;
}

.monitoringTableDowloadButton svg {
    cursor: pointer;
    transition: 0.2s;
}

.monitoringTableDowloadButton svg:hover {
    color: gray;
}

.monitoringTableDowloadButton:hover .monitoringTableDownloadTitle {
    cursor: pointer;
    opacity: 1;
    width: auto;
    max-width: auto;    
}

.monitoringTableDownloadTitleAbsolute {
    background: rgb(78, 78, 78);
    right: inherit;
    padding: 0.3rem;
    cursor: default;
    font-size: 1rem;
    border-radius: 5px;
}

::-webkit-scrollbar{
    width:13px;
    height: 13px;
}
::-webkit-scrollbar-track{
    background-color: rgb(35, 37, 7);
    border-radius: 1px;
}
::-webkit-scrollbar-thumb{
    background:  var(--goldColor);
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover{
    background: #daad1c;
}

@media only screen and (max-width: 600px) {
    .indentationRigth{
        margin-left: 0px;
    }
}

@media (max-width: 1368px){

    .indentationRigth{
       max-height: calc(100% - 46px);
    }

    .indentationNormal {
        margin-left: 0;
        justify-content: center;
        align-items: center;
        max-height: calc(100% - 47px);
        max-width: calc(100% - 47px);
    }

    .buttonWhiteBg{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        cursor: pointer;
        border: 1px solid var(--goldColor);
        font-size: 1rem;
        font-weight: 400;
        height: 1.6rem;
        width: 8rem;
        transition: 0.1s;
        background-color: white;
        color: var(--formButtonFontColor)
    }
    
    .buttonWhiteBg:hover {
        background: var(--buttonHover);
        color: white;
    }
}



 @-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #4d4d4d;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.containerLoading{
    position: absolute;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #EBEBEB;
}

.alertLoad{
    margin-top: 0.3rem;
    color: #4d4d4d;
}

.largerBorder {
    border: 0.2rem solid var(--goldColor);
    background-color: aqua;
 }

 input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

/* Variaveis */

:root {
   --loginContainerBG: #ececec44;
   --loginBoxBG: #1c2833;
   --calendarBorder: #c8c8c8;
   --calendarDPevent: #CDD945;
   --calendarDPeventHover: #e6f170;
   --calendarBgColor: #eeeeee;
   --calendarBodyTdBg: #f1f1f1;
   --calendarBodyTdBgHover: #e7e7e7;
   --calendarSobraEvent: black;
   --calendarSobraEventHover: rgb(63, 63, 63);
   --calendarOfertaEvent: #3DA460;
   --calendarOfertaEventHover: #59bd7a;
   --menuBackgroundSelected: #202d3a;;
   --menuSelectedItem: #2f2f2f;
   --menuSelectedOpen: #141414;
   --topBarBg: rgb(20, 20, 20);
   --menuBorderColor: #292929;
   --formFontColor: #11639e;
   --formBorder: #c49e17;
   --goldColor: #c49e17;
   --bookRubiHeader: #27292c;
   --bookRubiBDRHeader: #d6a908f8;
   --bookRubiBIDIHeader: #447431f8;
   --bookBoosterHeader: #202020;
   --formButtonFontColor: #bea032;
   --buttonBG: rgb(248, 240, 240);
   --buttonHover: #c3980e;
   --headerAllBg: rgba(214,213,213,0.5);
   --headerFontColor: #505050;
   --tableBorderEven: #ffffff70;
   --tableSecondTHGray: #3E3E3E;
   --tableOrderBgEven: #e6e6e770;
   --tableBorder: #bbbbbb70;
   --tableHover: #cecfd170;
   --tableSelectedOperation: #3E3E3E;
   --tableEstruturadasHeader: #11639e;
   --tableSecondTh: #4e4e4e;
   --errorFields: #ab0000;
}

