.clientsIndicationMainContainerBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF;
}

#clientsIndicationErrorFieldsMsg{
    color: red;
    font-weight: 400;
    margin-bottom: 0.3rem;
    font-size: 1rem;
}

.clientsIndicationBoxRequiredFields {
    color: red;
    font-size: 0.9rem;
    font-weight: 400;
    position: fixed;
    transform: translate(-28rem, 3.6rem);
    
}

.clientsIndicationBoxRequiredFieldsTextArea {
    color: red;
    font-weight: 400;
    font-size: 0.9rem;
    position: fixed;
    transform: translate(-58rem, 7.3rem); 
}


.clientsIndicationLoader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientsIndicationMainContainerBox a {
    font-size: 1.5rem;
    font-weight: 500;
}

.clientsIndicationsHeader {
    height: 5rem;
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientsIndicationsHeader button {
    margin-right: 2rem;
}

.clientsIndicationsHeader p {
    font-size: 1.2rem !important;
    margin-left: 2rem;
    border-bottom: 1px solid var(--goldColor);

}

.clientsIndicationsBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: #FFF;
}

.clientsIndicationsBody form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
}

.clientsIndicationCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.clientsIndicationProfileList{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}

.clientsIndicationCategory .MuiFormLabel-root {
    color: black;
    border-bottom: 1px solid var(--goldColor);
}

.clientsIndicationCategory .MuiFormGroup-root{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.clientsIndicationCategory .MuiRadio-colorSecondary.Mui-checked {
    color: blue;
}

.clientsIndicationDualGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
}

.clientsIndicationOneGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
}

.clientsIndicationOneGrid label {
    width: 100%;
}

#submitButton {
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

#submitButton:hover {
    background: var(--buttonHover);
}

@media(max-width:1368px){
    .clientsIndicationMainContainerBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFF;
    }
    
    .clientsIndicationDualGrid .MuiInputBase-root {
        height: 2rem;
    }


.clientsIndicationBoxRequiredFields {
    color: red;
    font-size: 0.8rem;
    font-weight: 400;
    position: fixed;
    transform: translate(-320px, 35px);
    
}

.clientsIndicationBoxRequiredFieldsTextArea {
    color: red;
    font-weight: 400;
    font-size: 0.8rem;
    position: fixed;
    transform: translate(-680px,115px); 
}


.clientsIndicationsBody form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  
    
    .clientsIndicationLoader {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .clientsIndicationMainContainerBox a {
        font-size: 1.5rem;
        font-weight: 500;
    }
    
    .clientsIndicationsHeader {
        height: 5rem;
        background: var(--headerAllBg);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsIndicationsHeader button {
        margin-right: 2rem;
    }
    
    .clientsIndicationsHeader p {
        font-size: 1.2rem !important;
        margin-left: 2rem;
        border-bottom: 1px solid var(--goldColor);
    
    }
    
    .clientsIndicationsBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    
    .clientsIndicationsBody form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
    }
    
    .clientsIndicationCategory {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }
    
    .clientsIndicationProfileList{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
    }
    
    .clientsIndicationCategory .MuiFormLabel-root {
        color: black;
        border-bottom: 1px solid var(--goldColor);
    }
    
    .clientsIndicationCategory .MuiFormGroup-root{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    
    .clientsIndicationCategory .MuiRadio-colorSecondary.Mui-checked {
        color: blue;
    }
    
    .clientsIndicationDualGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    
    .clientsIndicationOneGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    
    .clientsIndicationOneGrid label {
        width: 100%;
    }
    
    #submitButton {
        width: 8rem;
        height: 2rem;
        background: white;
        font-size: 0.9rem;
        margin-top: 1rem;
        font-weight: 400;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
    }
    
    #submitButton:hover {
        background: var(--buttonHover);
    }
    
    
}

.idNomeCliente{
    font-size: 1.4rem;
    padding: 0.3rem;
    border-bottom: 1px solid #c8c8c8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
}

.containerNetRv{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.containerField{
    display: grid;
    grid-template-rows: auto auto;
}

.containerOp{
    padding-left: 2rem;
    padding-right: 2rem;
    width: fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.opcional{
    margin-bottom: 0.4rem;
}

.netRvAtual{
    font-size: 1.2rem;
    font-weight: 400;
}

.netRvAtualValor{
    font-size: 1.2rem;
    color: #a57e00;
}

.netRvPotencialValor{
    font-size: 1.2rem;
    color: #1976d2;
}

.brokerSegmentoBox{
    padding-top: 0.65rem;
}

.infoBrokerSegmento{
    background-color: #c8c8c850;
    padding: 1.6rem;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    width: fit-content;
    border-radius: 0.5rem;
    margin: auto;
}

.tituloBrokerSegmento{
    position: absolute;
    text-align: center;
    width: 64rem;
}

.tituloBrokerSegmentoSUBS{
    text-align: center;
}

.obervationsIndication{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.containerSubmitButton{
    text-align: center;
    margin-bottom: 2rem;
}

.infoBoxIndication{
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: #dfdfdf;
    padding: 0.6rem;
    font-weight: 400;
    box-shadow: 0px 0px 0.3rem 1px rgb(158, 158, 158);
}

.tituloSucessoIndicacao{
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    padding-top: 3rem;
}

.infoClienteSucesso{
    text-align: center;
    font-size: 0.9rem;
    padding-top: 2rem;
    font-weight: 500;
}

.aporteClienteSucesso{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.disclaimerContatoBroker{
    text-align: center;
    padding-top: 2rem;
    font-weight: 400;
}

.containerOpcoesSucessoIndicacao{
    /* display: grid; */
    grid-template-columns: 1fr 2px 1fr;
    width: 100%;
    margin: auto;
    text-align: center;
    padding-top: 2rem;
}

.linhaDivisoria{
    background-color: rgba(37, 64, 109, 1);
    width: 100%;
    height: 100%;
}

.textoOpcaoSucesso{
    font-size: 1.1rem;
    font-weight: 400;
}

.closeIndicationSuccess{
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-top: 3rem;
}

.closeIndicationSuccess:hover{
    background: var(--buttonHover);
    color: white;
}

.containerBrokerSelection{
    display: grid;
    width: 90%;
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0.6rem;
}

.brokerSelectionOption{
    width: 94%;
    max-width: 94%;
    margin: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5.5rem;
    font-size: 1rem;
    background: #F9F9F9;
    border-radius: 8px;
    border: 1px solid rgb(216, 216, 216);
    color: rgb(63, 63, 63);
    transition: all ease-in-out 0.1s;
}
.brokerSelectionOption:hover{
    background: #fcfcfc;
    border: 1px solid #c49f17bb;
    color: #5c4800d5;
    box-shadow: 0px 0px 14px #c49f17bb;
}
.brokerSelectionOptionActive{
    width: 94%;
    max-width: 94%;
    margin: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5.5rem;
    font-size: 1rem;
    background: #fcfcfc;
    border-radius: 8px;
    border: 1px solid #c49e17;
    color: #5c4800;
    transition: all ease-in-out 0.1s;
    box-shadow: 0px 0px 14px #c49e17;
}

.containerClientIndicationForm{
    width: 100%;
    display: grid;
}

.brokerSelectionType{
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.brokerSelectionName{
    font-size: 1rem;
}

.brokerSelectionId{
    margin-top: 0.2rem;
    font-size: 0.9rem;
}

.warningNoDefaultBroker{
    background-color: rgb(156, 0, 0);
    color: white;
    font-size: 1.2rem;
    padding: 0.4rem;
    text-align: center;
}