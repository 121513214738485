.mainLongShortHistorico {
    width: 20rem;
    height: 25rem;
    background-color: white;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
}

.mainLongShortHistoricoTitle{
    padding: 1rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
}

.mainLongShortHistoricoBody{
    padding: 1rem;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
}

.mainLongShortHistoricoSubmit{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataHistoricoLS{
    margin-top: 1rem;
}