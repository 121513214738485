.recomendationsTable {
    display: flex;  
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.recomendationsTableArea {
    margin-top: 1rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    overflow-x: hidden;
    width: 95%;
}
.recommendationsTableLoader {
    height: 20rem;
}

.recomendationsTable thead tr:first-child th{
    border-radius: 0.5rem 0 0;
}

.recomendationsTable thead tr:first-child th {
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg);
    z-index: 99;
}

.recomendationsTable thead tr:nth-child(2) th {
    position: sticky;
    top: 3.9rem;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
}

.recomendationsTable table thead tr th div {
    display: flex;
    flex: row;
    align-items: center;
    justify-content: space-between;
}

.recomendationsTable table thead tr th div p{
    font-size: 1.2rem;
    color: white;
    font-weight: 300;
    padding-left: 1rem;
}

.recomendationsHeaderRight {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    gap: 0.5rem;
}

.recomendationsTable tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
}

.recomendationsTable tbody tr:hover td {
    background: var(--tableHover);
}
