.myStructuredEditContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   background: white;
}

.myScructuredEditHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    background: var(--headerAllBg);
}

.myScructuredEditHead  p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.myScructuredEditHead  button {
    margin-right: 1rem;
}

.myStructuredEditBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
}

.mySctructuredDefaultView {
    display: flex;
    flex-direction: column;
    height: 80%;
}

.myStructuredMergedLabel {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
}

.myStructuredMergedLabel label {
    font-size: 1rem;
    border: 1px solid rgb(189, 189, 189);
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
    border-radius: 0.3rem;
}

.myStructuredMergedLabel label a:first-child {
    font-weight: 500;
    margin-left: 0.5rem;
}

.tableBookArea {
    margin-top: 2rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

#tableBookAreaFirstDiv {

    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.tableBookArea thead tr:first-child th {
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableBookArea thead tr:nth-child(2) th{
    background: var(--tableSecondTh);
    color: white;
    position: sticky;
    top: 2rem;
    z-index: 100;
    font-size: 1rem;
}

#tableBookAreaFirstDiv p{
    font-size: 1.1rem;
    color: white;
}

.tableBookArea tr td{
    border: 1px solid var(--tableBorder);
    text-align: center;
    font-size: 0.9rem;
    height: 1.8rem;
    cursor: default;
}

.myStructuredEditLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}