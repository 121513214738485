.brokerPayrollContainer {
 display: flex;
 flex-direction: column;
 align-items: center;
 height: 100%;
 min-width: 1100px;
}

.recommendationsTableContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}