/* BooksRegisterTable */
#bookRegisterPlusTd {
    cursor: pointer !important;
}

#bookRegisterTypeOperationDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

#booksRegisterEditNameDiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    height: 2rem;
}

#bookRegisterEditNameButton {
    font-size: 1.2rem;
    cursor: pointer;
}


#bookRegisterTypeOperationDiv a {
    font-size: .9rem;
    font-weight: 400;
}

#bookRegisterLabelInstructions button {
    width: 1.5rem;
    height: 1.2rem;
    display: inline-block;
    justify-content: center;
    transition: 0.2s;
    outline: none;
}


#bookRegisterEditNameConfirmButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

#bookRegisterEditNameConfirmButton:hover {

    background: var(--goldColor);
    color: white;
}

#bookRegisterLabelInstructions button:focus {
    border: 1px solid black !important;
}

#bookRegisterLabelInstructions button span {
    border-top: 1px solid rgb(255, 255, 255);
    border-left: 1px solid rgb(255, 255, 255);
    width: 0.4rem;
    height: 0.6rem;
    transform: rotate(220deg) translate(-1px);
    
    display: inline-block;
    transition: 0.2s;
}

#bookRegisterTypeOperation span a {
    opacity: 0;
}

.bookRegisterFixedFields td {
    cursor: default !important;
    color: rgb(80, 80, 80);
}


.booksRegisterTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.bookRegisterLoader {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booksRegisterHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.booksRegisterHead  p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1rem !important;
    font-weight: 300;
    margin-left: 2rem;
    
}

.booksRegisterHead .modalCloseButton {
    margin-right: 2rem;
    margin-top: 1rem;
}

.bookRegisterTableContent {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    width: 85rem;
    height: 75%;
    max-height: 75%;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.bookRegisterTableContent p {
    font-size: 0.9rem !important;
}

.bookRegisterTableContent label:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bookRegisterInputName {
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

#bookRegisterLabelInstructions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

#bookRegisterLabelInstructions input {
    height: 1.5rem;
    width: 15rem;
    font-size: 1.1rem;
    font-weight: 400;
    padding-left: 0.5rem;
}

#bookRegisterLabelInstructions input::placeholder{
    color: rgb(78, 78, 78);
    font-size: 1.1rem;
}

#bookRegisterLabelInstructions input:focus {
    border-bottom: 1px solid var(--goldColor);   
}

#bookRegisterEditName{
    font-size: 1.2rem !important;
}

.bookRegisterTableContent label p{
    font-size: 1rem !important;
    font-weight: 400;
}

#bookRegisterPreviewText {
    font-size: 1.1rem !important;
}


.bookRegisterInstructions {
    display: flex;
    width: 95%;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}

.bookRegisterInstructions p{
    font-size: 1rem !important;
}

.bookRegisterInstructions label {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
.bookRegisterInstructions label div input {
    height: 1.5rem;
}

/*Tabela preview */

.booksRegisterTablePreview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booksRegisterTablePreview div {
    height: 3rem;
}

.booksRegisterTablePreview div p{
    font-size: 1rem !important;
}

.booksRegisterTablePreview 

#bookRegisterPreviewTableTitle {
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}


#bookRegisterPreviewTableTitle p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1rem;
}

.booksRegisterTablePreview #booksRegisterTableToPreview {

    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#booksRegisterTableToPreview thead th {
    font-size: 1rem;
}

#booksRegisterTableToPreview thead tr:first-child {
    background: var(--topBarBg);
    color: white;
    height: 4rem;
}

#booksRegisterTableToPreview thead tr:first-child th:first-child  {
    border-radius: 5px 0px 0px 0px;
}

#booksRegisterTableToPreview thead tr:nth-child(2) {
    color: white;
    background: var(--tableSecondTh);
} 

#booksRegisterTableToPreview thead tr:nth-child(2) th{
    font-weight: 300 !important;
    font-size: 1rem !important;
    padding: 0.3rem;
}

#booksRegisterTableToPreview tbody td {
    height: 2rem;
    text-align: center;
    width: 8rem;
    cursor: default !important;
}

#bookRegisterConfirmationBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#bookRegisterConfirmationBox a {
    font-size: 1rem !important;
    margin-bottom: 0.8rem;
}

#bookRegisterConfirmationBoxButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4rem;
}
.booksRegisterTablePreview tbody td {
    font-size: 1rem;
}

/*Tabela para configuração do Book*/

#bookRegisterNewFieldsTable{
    box-shadow: 0px 2px 19px -8px black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable{
    background-color: #ececec;
    border-radius: 0.3rem;
    width: 100%;
    font-size: 1rem !important;
  }

#bookRegisterNewFieldsTable tr:last-child td:hover {
    font-weight: 300 !important;
}

#bookRegisterNewFieldsTable ::-webkit-scrollbar {
    width: 10px;
}
  
#bookRegisterNewFieldsTable ::-webkit-scrollbar-track {
    background: #ebebeb;
}
  
#bookRegisterNewFieldsTable ::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194);
    transition: 0.2s;
}
  

#bookRegisterNewFieldsTable ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}


#bookRegisterFixedFields {
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bookRegisterFixedFields a {
    font-size: 1rem;
}

#bookRegisterFixedFields input:hover::placeholder {
    font-weight: 400;
}

#bookRegisterFixedFields input {
    width: 13.5rem;
    height: 2.4rem;
    padding-left: 4px;
    text-align: center;
    border: none;
    font-size: 1rem;
}

#bookRegisterFixedFields input::placeholder {
    color: rgb(68, 68, 68);
    font-size: 1rem;
}

#bookRegisterNewFieldButton {
    background: white;
    border: none;
    font-size: 1rem;
    width: 7rem;
    transition: 0.2s;
}

#bookRegisterNewFieldButton:hover {
    background: var(--goldColor);
    color: black;
}
#bookRegisterNewFieldButton:focus {
    background: var(--goldColor);
    color: black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable  #bookRegisterTableThead{
    text-align: center;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableThead {
    background: var(--topBarBg);
    color: white;
    height: 2.5rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody{
    background-color: #fff;  
    text-align: center;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableBody {
    display: block;
    overflow-x: hidden;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead, #bookRegisterTableBody tr {
    padding-top: 0.35rem;
    display: table;
    width: 85rem;
    table-layout: fixed;
  }
  #bookRegisterNewFieldsTable  #bookRegisterTableThead {
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid var(--goldColor) !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(1) td{
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #005f9e36 !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(2) td{
      padding-top: 0.5rem;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody tr {
    height: 2rem;
    font-size: 1rem !important;
}
    

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody td {
      border: 1px solid var(--tableBorder);
      height: 2.5rem;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody tr:nth-last-child(2) td:nth-child(6):hover {
    background-color: rgba(128, 128, 128, 0.267);
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(1){
      width: 4rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(2){
      width: 8rem;
  }
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(3){
      width: 8rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(4){
      width: 8rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(5){
      width: 6rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(6){
      width: 4rem;
      transition: 0.2s;
  }
  
  #bookRegisterSelectDiv {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      background: white;
      position: relative;
      width: 13.5rem;
      height: 2.4rem;

  }

  #bookRegisterSelectDiv svg {
     cursor: pointer !important;
     transform: translate(-10px, 0px);
     z-index: 100;
     position: absolute;
     right: 0;
  }

  #bookRegisterNewFieldsTable #bookRegisterSelectType { 
    width: 13.5rem;
    border-style: none;
    font-family: 'Roboto';
    text-align: center;
    text-align-last: center;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    z-index: 1000;
    background: rgba(177, 177, 177, 0.013);
    position: absolute;
    
  }

  #bookRegisterNewFieldsTable #bookRegisterSelectType:hover {
      font-weight: 400;
  }

  #bookRegisterButtonWhiteBg {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 500;
    width: 11rem;
    height: 2.2rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
  }

  #bookRegisterButtonWhiteBg:hover {
    color: white;
    background: var(--goldColor);
  }

  #bookEditLoaderDiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bookRegisterLoaderDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}


.booksRegisterFixedFields {
    color: gray;
}

.booksRegisterFixedFields:hover {
    color: gray;
    cursor: default;
}

.booksRegisterFixedFields td:hover {
    color: gray;
    cursor: default;
}


@media(max-width: 1368px){
/* BooksRegisterTable */
.booksRegisterTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.bookRegisterLoader {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booksRegisterHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.booksRegisterHead  p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1rem !important;
    font-weight: 300;
    margin-left: 2rem;
    
}

.booksRegisterHead .modalCloseButton {
    margin-right: 2rem;
    margin-top: 1rem;
}

.bookRegisterTableContent {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    height: 70%;
    max-height: 70%;
    width: auto;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.bookRegisterTableContent p {
    font-size: 0.9rem !important;
}

.bookRegisterTableContent label:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bookRegisterInputName {
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

#bookRegisterLabelInstructions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

#bookRegisterLabelInstructions input {
    height: 1.5rem;
    width: 15rem;
    font-size: 1.1rem;
    font-weight: 400;
    padding-left: 0.5rem;
}

#bookRegisterLabelInstructions input::placeholder{
    color: rgb(78, 78, 78);
    font-size: 1rem;
}

#bookRegisterLabelInstructions input:focus {
    border-bottom: 1px solid var(--goldColor);   
}

#bookRegisterEditName{
    font-size: 1.2rem !important;
    border: none;
    
}

.bookRegisterTableContent label p{
    font-size: 1rem !important;
    font-weight: 400;
}

#bookRegisterPreviewText {
    font-size: 1.1rem !important;
}

.bookRegisterInstructions {
    display: flex;
    width: 95%;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}

.bookRegisterInstructions p{
    font-size: 0.9rem !important;
}

.bookRegisterInstructions label {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
.bookRegisterInstructions label div input {
    height: 1.5rem;
}

/*Tabela preview */
.booksRegisterTablePreview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booksRegisterTablePreview div {
    height: 3rem;
}

.booksRegisterTablePreview div p{
    font-size: 0.9rem !important;
}

#bookRegisterPreviewTableTitle {
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}


#bookRegisterPreviewTableTitle p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1rem;
}

.booksRegisterTablePreview #booksRegisterTableToPreview {

    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#booksRegisterTableToPreview thead th {
    font-size: 0.9rem;
}

#booksRegisterTableToPreview thead tr:first-child {
    background: var(--topBarBg);
    color: white;
    height: 4rem;
}

#booksRegisterTableToPreview thead tr:first-child th:first-child  {
    border-radius: 5px 0px 0px 0px;
}

#booksRegisterTableToPreview thead tr:nth-child(2) {
    color: white;
    background: var(--tableSecondTh);
} 

#booksRegisterTableToPreview thead tr:nth-child(2) th{
    font-weight: 300 !important;
    font-size: 0.9rem !important;
}

#booksRegisterTableToPreview tbody td {
    height: 2rem;
    text-align: center;
    width: 8rem;
}

#bookRegisterConfirmationBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#bookRegisterConfirmationBox a {
    font-size: 0.9rem !important;
    margin-bottom: 0.8rem;
}

#bookRegisterConfirmationBoxButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.booksRegisterTablePreview tbody td {
    font-size: 0.9rem;
}

/*Tabela para configuração do Book*/

#bookRegisterNewFieldsTable{
    box-shadow: 0px 2px 19px -8px black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable{
    background-color: #ececec;
    border-radius: 0.3rem;
    width: 100%;
    font-size: 0.9rem !important;
  }


#bookRegisterFixedFields {
    height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bookRegisterFixedFields a {
    font-size: 0.9rem;
}

#bookRegisterFixedFields input {
    width: 10rem;
    height: 1.9rem;
    padding-left: 4px;
    text-align: center;
    border: none;
    font-size: 0.9rem;
}

#bookRegisterFixedFields input::placeholder {
    color: rgb(68, 68, 68);
    font-size: 0.9rem;
}

#bookRegisterNewFieldButton {
    background: white;
    border: none;
    font-size: 0.9rem;
    width: 7rem;
    transition: 0.2s;
}

#bookRegisterNewFieldButton:hover {
    background: var(--goldColor);
    color: black;
}
#bookRegisterNewFieldButton:focus {
    background: var(--goldColor);
    color: black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable  #bookRegisterTableThead{
    text-align: center;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableThead {
    background: var(--topBarBg);
    color: white;
    height: 2.3rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody{
    background-color: #fff;  
    text-align: center;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableBody {
    display: block;
    overflow-x: hidden;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead, #bookRegisterTableBody tr {
    padding-top: 0.35rem;
    display: table;
    width: 65rem;
    table-layout: fixed;
  }
  #bookRegisterNewFieldsTable  #bookRegisterTableThead {
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid var(--goldColor) !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(1) td{
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #005f9e36 !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(2) td{
      padding-top: 0.5rem;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody tr {
    height: 2rem;
    font-size: 0.9rem !important;
}
    

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody td {
      cursor: default;
  }
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(1){
      width: 2rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(2){
      width: 4rem;
  }
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(3){
      width: 5rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(4){
      width: 5rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(5){
      width: 3rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(6){
      width: 2rem;
      transition: 0.2s;
  }
  
  #bookRegisterSelectDiv {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      background: white;
      position: relative;
      width: 10rem;
      height: 1.9rem;

  }

  #bookRegisterSelectDiv svg {
     cursor: pointer !important;
     transform: translate(-10px, 0px);
     z-index: 100;
     position: absolute;
     right: 0;
  }

  #bookRegisterNewFieldsTable #bookRegisterSelectType { 
    width: 10rem;
    border-style: none;
    border:0px;
    font-family: 'Roboto';
    text-align: center;
    text-align-last: center;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    border: none;
    font-size: 0.9rem;
    font-weight: 300;
    z-index: 1000;
    background: rgba(177, 177, 177, 0.013);
    position: absolute;
    
  }

  #bookRegisterButtonWhiteBg {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    width: 11rem;
    height: 2.2rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
  }

  #bookRegisterButtonWhiteBg:hover {
    background: var(--buttonHover) !important;
    color: white;
  }

  #bookEditLoaderDiv {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  #bookRegisterLoaderDiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
  }
}
