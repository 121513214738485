.myPushOrdersTableMainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.myPushOrdersLoadingPeriods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.myPushOrdersSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    max-width: 8rem;
}

.monitoringTableExcelButton {
   display: none;
}

#myStructuredLoadingTd {
    height: 10rem;
}

.mainPushOrdersTableContainerOverflow {
    overflow-x: hidden;
    width: 95%;
    margin-top: 1rem;
    -webkit-box-shadow: 0px 6px 12px -9px #000000; 
    box-shadow: 0px 6px 12px -9px #000000;
}

.mainPushOrdersTableContainer{
    border-collapse: collapse;
    max-height: 100%;
}

.mainPushOrdersTableContainer thead tr th{
    animation: scroll .5s ease-out;
    transition: 0.2s;
}

.myPushOrdersTheadDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.myPushOrdersTheadDiv p {
    color: white;
    margin-left: 1rem;
}

.myPushOrdersTheadRightGroup {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainPushOrdersTableContainer thead tr:first-child th{
    background: var(--topBarBg);
    height: 5rem;
    position: sticky;
    overflow: hidden;
    border-radius: 0.5rem 0 0 0;
    top: 0px;
    color: white;
    font-size: 1.3rem;
    font-weight: 300;
    transition: 0.2s;
    z-index: 10;
    animation: scroll .5s ease-out;
    
}


#myPushOrdersMultipleActions {
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--goldColor);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
}

#myPushOrdersMultipleActions:hover {
    background: var(--goldColor);
    color: black;
}

.myPushOrdersMultipleActionsDiv {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 1.1rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: var(--goldColor);
}

.myPushOrdersMultipleActionsDiv p {
    color: var(--goldColor);
    margin-left: 0;
}

#myPushOrdersMultipleActions div {
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
}

#myPushOrdersTheadDateSelection {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.myPushOrdersSelectionAll {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
}

@keyframes scroll {
    from {
      transform: tranlateY(-100%);
    }
  
    to {
       transform: tranlateY(0%);
    }
  }

.mainPushOrdersTableContainer thead tr:nth-child(2) th{
    background: #3E3E3E;
    height: 1.5rem;
    position: sticky;
    overflow: hidden;
    margin-top: 1rem;
    top: 5rem;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 10;
    transition: 0.9s;
    animation: scroll .5s ease-out;
}

.mainPushOrdersTableContainer tbody {
    overflow: auto;
}

.mainPushOrdersTableContainer td:nth-child(1){
    
    width: 1rem !important;

}

.mainPushOrdersTableContainer td:nth-child(3){
    
    max-width: 8rem !important;

}

.mainPushOrdersTableContainer td:nth-child(7){
    
    max-width: 4rem !important;

}

.mainPushOrdersTableContainer td:nth-child(9){
    
    max-width: 9rem !important;

}



.mainPushOrdersTableContainer td{
    border: 1px solid var(--tableBorder);
    text-align: center;
    text-overflow: ellipsis;
    cursor: default !important;
    overflow: hidden;
    white-space: nowrap;
    /* max-width: 11rem; */
    height: 2rem;
    font-size: 1rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.mainPushOrdersTableContainer tr{
    transition: 0.1s;
}

.mainPushOrdersTableContainer tr {
    background: #FFF;
}

.mainPushOrdersTableContainer tr:hover{
    background: var(--tableHover) !important;

}

.myPushOrderDetails label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.myPushOrderDetails label a:first-child{
    font-weight: 400;
}

#myPushOrderDetailsTable {
    margin-top: 1.5rem;
    
}

#myPushOrderDetailsRow {
    transition: all ease 0.2s;
}

.myPushOrderDetails { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.2rem;
    align-items: center;
    width: 100%;
    background: #3e3e3e;
    height: 3rem;
    transition: 0.2s;
}

.myPushOrderDetails a {
    color: white;
}


.myPushOrderDetails:hover {
    background: #444444;
}


#myStructuredNotifyBox {
    position: absolute;
    bottom: 60px;
    right: 20px;
    background: var(--goldColor);
    height: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.3rem;
    font-weight: 500;
    transition: 0.5s ;
    border-radius: 0 1rem 0 1rem;
    animation: notify 1s ease;
    z-index: 100;
    box-shadow: 0px 7px 20px -7px #093459;

}

#myStructuredNotifyBox p {
    font-size: 0.9rem;
    color: rgb(49, 49, 49);
}

#myStructuredNotifyBox button {
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    width: 0.9rem;
    height: 0.9rem;
    background: #f0f6fb;
    border:none;
}

@media(max-width:1366px){
    .myPushOrdersSelectionFilter {
        background: var(--tableSecondTh);
        color: white;
        font-size: 0.8rem;
        max-width: 6rem;
    }

    .mainPushOrdersTableContainer td{
        border: 1px solid var(--tableBorder);
        text-align: center;
        text-overflow: ellipsis;
        cursor: default !important;
        overflow: hidden;
        white-space: nowrap;
        /* max-width: 11rem; */
        height: 2rem;
        font-size:0.9rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }
}