.fiisOfferingsNewTableContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.fiisOfferingsFilterHeader {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.83); 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    z-index: 99;

}

.fiisOfferingsFilterHeader p{
 font-size: 1.2rem;
 font-weight: 600;
 padding: 1rem;
}

.fiisOfferingsCardBodyContainer {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;   
    gap: 1.5rem;
    width: 80%;
}

.fiisOfferingsCard {

    width: 100%;
    min-width: 65rem;
    border-radius: 4px;
    background: white;
    color: rgb(87, 87, 87) !important;
    display: flex;
    flex-direction: column;
    border: none;
    -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.83); 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    transition: 0.3s;
    margin-bottom: 1rem;
}

.fiisOfferingsRecommendationLabel {
    background: rgb(1, 102, 48);
    border-radius: 15px;
    height: 1.3rem;
    width: 5.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fiisOfferingsButtonLabels {
    display: flex;
    flex-direction: row !important;   
    gap: 0.5rem;
    justify-content: space-between
}

.fiisOfferingsCardBody textarea {
    border: 1px solid gray;
    background: #dbdbdb;
    height: 2.1rem;
    outline: none;

}

.fiisOfferingsCard:hover {
    -webkit-box-shadow: 0px 2px 25px -13px #302d6f;
    box-shadow: 0px 2px 15px -7px #302d6f;
    
}

.fiisOfferingsCardHeader {
    border-radius: 10px 10px 0px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 2rem;
    height: 4rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.fiisOfferingsCardHeader label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;

}

.fiisOfferingsCardHeader label a:first-child {
    font-weight: 400;
    font-size: 1rem;    
    font-weight: 600;
}

.fiisOfferingsCardHeader label a:nth-child(2){
   
    font-size: 1rem;
    font-weight: 400;
}

.fiisOfferingsCardBody {
    border: 1px solid rgba(0, 0, 0, 0.116);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: grid;
    margin-top: 1rem;
    grid-template-columns: repeat(2,1fr);
    align-items: flex-start;
    gap: 1.5rem;
}

.fiisOfferingsCardFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.fiisOfferingsBlueButton {
    border: none;
    height: 2rem;
    width: 14rem;
    margin-right: 4rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.fiisOfferingsBlueButtonTrash {
    border: none;
    height: 2rem;
    width: 5rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.fiisOfferingsCardHeaderOptions {
    display: grid;
    width: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    
}

.fiisOfferingsCardBodySelected {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    align-items: flex-start;
    gap: 1.5rem;
    border-top: 1px solid rgba(128, 128, 128, 0.322);
}


.fiisOfferingsCardBodyDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgb(230, 227, 227);
}

.fiisOfferingsCardBodyDetails a {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.fiisOfferingsCardBodyDetails a:first-child {
    font-weight: 300;
}

.fiisOfferingsCardBodyDetails a:nth-child(2){
    margin-right: 1rem;
    font-weight: 600;
}

.fiisOfferingsCardBodyDetailsLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.fiisOfferingsCardBodyDetailsLine a:first-child {
    font-weight: 400;
    margin-left: 1rem;
    margin-top: 1rem;
}

.fiisOfferingsCardBodyDetailsLine a:nth-child(2){
    margin-right: 1rem;
}


.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(35, 37, 7);
    border-radius: 1px;
}
.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb{
    background:  rgba(225, 225, 226, 0.466);
    border-radius: 2px;
}
.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover{
    background: rgb(156, 84, 84);
}

.fiisOfferingsCardBodyDetailsText {
    background: #f5f5f5;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: flex;
    word-break: break-all;
    width: 100%;
    gap: 0.3rem;
    padding-bottom: 1rem;
    max-height: 10rem;
    min-height: 10rem;
    flex-direction: column;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar {
    width: 10px;
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(231, 231, 231);
    border-radius: 1px
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 20%;
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover {
    background: rgb(204, 204, 204);
}

.fiisOfferingsCardBodyDetailsText a:first-child {
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    white-space: nowrap;

}

.fiisOfferingsCardBodyDetailsText a:nth-child(2) {
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;

}

.fiisOfferingsCardBodyLeftGrid {
    display: flex;
    flex-direction: column;
}

.fiisOfferingsReservDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 4rem;
}

.fiisOfferingsReservConfirmation {
    background: rgba(37, 64, 109, 1);
    border: none;
    color: white;
    font-weight: 500;
    height: 2.5rem;
    width: 20%;
    font-size: 1rem;
    transition: 0.2s;
}

#fiisOfferingsReservationLabel {
    width: 100%;
}

.fiisOfferingsReservConfirmation:hover,
.fiisOfferingsReservConfirmation:focus  {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(37, 64, 109, 1);
    box-shadow: 0px 0px 12px 0px rgba(37, 64, 109, 1);
}

.fiisOfferingsCompanyName {
    cursor: pointer;
    transition: 0.2s;
}

.fiisOfferingsCompanyName:hover a{
    color: black;
}

#fiisOfferingsButtonLabelsRecomendation {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    margin-right: 2rem;
}

#fiisOfferingsButtonsRequest {

    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.stocksOffersValueTag {
    background: #302d6f;
    width: 4rem;
    height: 1.5rem;
    color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;

}

.stockOffersValueTag a{
    margin: 0 auto;
    padding: 0;
    font-size: 1rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 1368px){

    .fiisOfferingsCardBodyContainer {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        gap: 1.5rem;
        width: 85%;
    }
}

.fiisOfferingsEmptyOffers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.fiisOfferingsEmptyOffers p {
    font-size: 1.5rem;
    font-weight: 500;
}

.fiisOfferingsLoader {

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}
