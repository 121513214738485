#containerLogon {
    overflow: hidden;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background: var(--loginBoxBG);
    background-image: radial-gradient(circle, #1e2a36, #1e2a34, #1d2830, #1b242a, #1a2126);
}

.loginForgottenPassword a{
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.2s;
}

.loginForgottenPassword a:hover {
    color: var(--goldColor)
}

.logoContainer {

    animation: Slide_right 3s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    transition: 0.1s;
    
}

.forgetPassUser {
    width: 100%;
    animation: Slide_right 3s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    transition: 0.1s;
 
}

.forgetPassUser #arrowLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.forgetPassUser div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forgetPassUser div:first-child svg {
    margin-left: 2rem;
    color: var(--goldColor);
    transition: 0.2s;
    cursor: pointer;
}

.forgetPassUser div:first-child svg:hover {
    color: white;
}

.logoContainerForgetPass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.logoContainerForgetPass h2 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-weight: 300;
    font-size: 1.6rem;
}

#forgetPasswordButton{
    margin: 0.7rem;
    width: 120px;
    height: 30px;
    font-size: 15px;
    /* background-color: var(--loginBoxBG); */
    background-image: radial-gradient(circle, #1e2a36, #1e2a34, #1d2830, #1b242a, #1a2126);
    color: white;
    border-radius: 0.3rem;
    border: 1px solid var(--goldColor);
    cursor: pointer;
    transition: 0.3s;
}


#forgetPasswordButton:hover{
    background-color:var(--goldColor);
    color: var(--lo);
}



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(219, 219, 219);
    opacity: 1; /* Firefox */
    text-align: center;
  }

  

#containerLogon input{
    text-align: center;
    color: white;
    width: 220px;
    height: 30px;
    margin: 0.7rem;
    font-size: 15px;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    border-radius: 0.3rem;
    background-color: #1c283300;
    border: none;
    outline: none;
    border-bottom: 0.1rem solid var(--goldColor);
}

#containerLogon #loginEnterButton{
    margin: 0.7rem;
    width: 90px;
    height: 30px;
    font-size: 15px;
    background-color: var(--loginBoxBG);
    
    color: white;
    border-radius: 0.3rem;
    border: 1px solid var(--goldColor);
    cursor: pointer;
    transition: 0.3s;
}

.loginSeparator {
    z-index: 100;
    height: 15rem;
    width: 1px;
}

.loginSeparator:after{
    content: '';
    height: 100%;
    border-right: 1px solid var(--goldColor);
    display: block;
    width: 0px;
    border-radius: 50%;
}


#containerLogon #loginEnterButton:hover{
    background-color:var(--goldColor);
    color: var(--lo);
}

.inputContainer {
    height: 15rem;
    z-index: 200;
    /* background: var(--loginBoxBG); */
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    animation: Slide_left 3s ease;
}

.inputContainer  form {
    height: 15rem;
    z-index: 200;
    /* background: var(--loginBoxBG); */
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    animation: Slide_left 3s ease;
}

.logoId {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: white;
    bottom: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    justify-content: center;
    position: absolute;
    filter: brightness(0.8);
    cursor: pointer;
}

.inLoginEvent {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: all ease 3s;
}

.inProcessLoading {
    display: flex;
    opacity: 0;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: 0.1s;
}

.loginLoaderHide {
    display: none;
}


.loginLoaderShow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    animation: opacity 1s ease;
    margin-right: 6rem;
}

@keyframes Slide_right{
    40%{margin-left: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-left: -400px;
       opacity: 0;
       transition: 0.1s}
}

@keyframes Slide_left{
    40%{margin-right: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-right: -400px;
       opacity: 0;
       transition: 0.1s}
}
@keyframes override{
    100%{margin-right: -700px;
         opacity: 0;
         position: relative;
        transition: 0.1s;}
    0%{margin-left: 0;
       opacity: 1;
       position: relative;
       z-index: 1;
       transition: 0.1s}
}

@keyframes opacity {

    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}



#logoPrimeIMG {
    width: 7rem;
    filter: grayscale(100);
    transition: 0.2s;
}

#logoPrimeIMG:hover {
    filter: grayscale(0)
}

@media(max-width: 1570px){
    #containerLogon {
        grid-template-columns: 49% 2% 49%;
    }
}

@media(max-width: 1230px){
    #containerLogon {
        grid-template-columns: 45% 5% 45%;
    }
}

@media(max-width: 501px){
    #loginSeparator {
        display: none;
    }
    .loginSeparator {
        display: none;
    }
    #containerLogon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .loginLoaderShow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }
}

