.columnExpandableLs{
    text-align: center;
    display: grid !important;
    grid-template-columns: auto 1rem;
    height: 2.2rem;
    white-space: nowrap;
}

.expandleLsIcon {
    padding-top: 0.2rem;
}

.expandedColumnLs{
    border-top: 1px solid rgb(192, 192, 192);
    text-align: center;
    white-space: nowrap;
    /* font-weight: 450; 
    font-size: 0.8rem; */
}

.expandedColumnLsLeft{
    position: relative;
    border-top: 1px solid rgb(192, 192, 192);
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnLsLeft::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateY(-100%);
    height: 40%;
    border-left: 1px solid rgb(192, 192, 192); /* Altere a cor e espessura da borda conforme necessário */
}

.expandedColumnLsRight{
    position: relative;
    border-top: 1px solid rgb(192, 192, 192);
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnLsRight::before {
    content: "";
    position: absolute;
    right: 0;
    transform: translateY(-100%);
    height: 40%;
    border-right: 1px solid rgb(192, 192, 192); /* Altere a cor e espessura da borda conforme necessário */
  }

.buttonEditCostLs{
    background-color: transparent;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.buttonEditCostLs:hover{
    background-color: #c4f5bd;
    border-radius: 5px;
}

  .btnValidationOk {
    width: 5rem;
    height: 1rem;
    margin: auto;
    padding: 0.3rem;
    color: #141414;
    /* border-radius: 2px; */
    font-size: 1rem;
    font-weight: 500;
    /* transition: all ease 0.1s; */
    /* box-shadow: 0px 0px 2px #c0b091; */
}


.btnValidationValidate {
    width: 5rem;
    height: 1rem;
    margin: auto;
    padding: 0.3rem;
    background-color: rgb(253, 253, 122);
    color: #141414;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 500;
    transition: all ease 0.1s;
    box-shadow: 0px 0px 2px #c0b091;
}

.btnValidationValidate:hover {
    background-color: #141414;
    color: rgb(253, 253, 122);
    cursor: pointer;
}

.btnValidationValidated {
    width: 5rem;
    height: 1rem;
    margin: auto;
    padding: 0.3rem;
    background-color: #5bd068;
    color: #141414;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 500;
    transition: all ease 0.1s;
    box-shadow: 0px 0px 2px #c0b091;
}

.btnValidationValidated:hover {
    background-color: #141414;
    color: #5bd068;
    cursor: pointer;
}
