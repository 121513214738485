.wisir2DetailsContainer {
    display: grid;
    height: 36rem;
    grid-template-columns: 1fr 1px 1fr;
    margin-top: 1rem;
}

.timelineCommentBoxWisir{
    height: 22rem;
    max-height: 22rem;
    overflow: auto;
    border-radius: 2px;
    border: 1px solid #dadada;
    background-color: #f8f8f8;
    display: grid;
  }

  .timelineTitleWisir{
    /* margin-top: 1rem; */
    height: 3rem;
    font-size: 1.1rem;
    font-weight: 500;
    display: grid;
    grid-template-columns:3fr 3fr; 
    padding-bottom: 0.3rem;
    align-items: center;
  }


.wisir2DetailsSide {
    max-height: 35rem;
    /* border: 1px solid #dadada;
    border-radius: 2px;
    margin: 2 rem; */
    margin: 0.5rem;
    margin-top: 0.75rem;
    display: grid;
    grid-template-rows: 2rem auto;
}

.wisir2DetailsSideBody {
    overflow: auto;
}

.wisir2CustodyItem {
    border-radius: 2px;
    border: 1px solid #dadada;
    margin: 1rem 2rem;
    padding: 0.5rem;
}

.wisir2CustodyItemLineLabel {
    font-weight: 500;
}

.mappingContainer {
    display: grid;
    grid-template-rows: auto auto 5rem;
    width: 80rem;
    background: #FFF;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
}

.mappingContainerUpperGrid {
    /* display: grid;
  grid-template-columns: 1fr;   
  grid-template-rows: auto;    */
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  /* gap: 1rem; */
  margin: 1rem 2rem 0 2rem;
}

.mappingSubmitBtn {
    margin-left: 2rem;
}