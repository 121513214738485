.containerExportCRM{
  width: 25rem;
  height: fit-content;
  background-color: white;
  display: grid;
  grid-template-rows: 1fr auto auto auto auto auto;
}

.containerExportCRM p{
  margin-left: 1rem;
  padding: 0.4rem !important;
}

.buttonExportCRM{
  width: fit-content;
  height: fit-content;
  padding: 0.6rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #c49e17;
  border: 1px solid #c49e17;
  background-color: white;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}
.buttonExportCRM:hover{
  color: #fff;
  border: 1px solid #fff;
  background-color: #c49e17;
}

.exportCRMdivline{
  background-color: #d6d5d580;
  width: 90%;
  height: 2px;
  margin: auto;
}

.inputDateExportCRM{
  height: fit-content;
  width: 8rem;
  padding: 0.4rem;
}

.containerExportDate{
  width: fit-content;
  margin: auto;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto auto;
}

.exportDateTitle{
  height: fit-content;
  margin: auto;
  margin-right: 0.4rem;
}