.tooltipSelect {
    padding: 0.5rem;
    margin: 0rem 0.2rem 0rem 0.2rem;
}

.tooltipSelectOptions {
    width: 10rem;
    height: 1.7rem;
    padding: 0.1rem;
    border: 1px solid #c49e17;
    font-weight: 500;
    border-radius: 3px;
    background-color: #c49e17;
    color: white;
    transition: all ease-in-out 0.1s;
}

.tooltipSelectOptions:hover {
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.tooltipSelectOptions:focus {
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
    outline: none;
}

.tooltipButton {
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.tooltipButtonDelete{
    background-color: #ec7a7a;
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.4rem;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.tooltipButtonDelete:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.tooltipButtonDelete:focus{
    outline: none;
}

.tooltipButton:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.tooltipButton:focus{
    outline: none;
}

.boxUpdateStatusOffer{
}

.boxDeleteOffer{
    margin-right: 2rem;
}

.boxGeralToolbarMenu{
    display: grid;
    grid-template-columns: 40rem auto;
}