.editBrancheBoxContainer {
    width: 70rem;
    height: 40rem;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.editBrancheBoxLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editBrancheBoxHeader {
    background: var(--headerAllBg);
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.editBrancheBoxHeader p {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--goldColor);
    margin-left: 1rem;
}

.editBrancheBoxHeader button {
    margin-right: 1rem;
}

.editBrancheBoxBody {
    height: 100%;
    width: 95%;
    overflow-x: hidden;
    display: flex;
    gap:1rem;
    flex-direction: column;
    align-items: center;
}

.editBrancheBoxButtonEdit {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

#editBrancheForm {
    height: 100%;
    width: 100%;
    display: flex;
    gap:1rem;
    flex-direction: column;
    align-items: center;
}

.editBranchesBoxDualDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
    align-items: center;

}

.editBranchesBoxLabelDiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    height: 2.4rem;
    border-radius: 5px;
    border: 1px solid rgba(34, 34, 34, 0.294);
}

.editBranchesBoxLabelDiv a {
    font-size: 1rem;
}

.editBranchesBoxHideLabel {
    display: none;
}
.editBrancheBoxShowFields {
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(34, 34, 34, 0.294);
}

#editBrancheBoxCNPJ {
    height: 2.4rem;
    width: calc(100% - 8px);
    border: none;
    outline: none;
    font-size: 1rem;
    transition: 0.2s;
    padding-left: 0.5rem;
    
}

#editBrancheBoxCNPJ:focus {
    border: 1px solid var(--goldColor);
    transition: 0.2s;
}

#editBrancheBoxCNPJ text {
    color: blue;
}

#editBrancheBoxCNPJ::placeholder {
    color:rgba(0, 0, 0, 0.815);
    padding-left: 0.5rem;
    font-size: 1rem;
}
.editBranchesBoxLabelDiv a:first-child {
    font-weight: 400;
    margin-left: 0.5rem;
}


@media (max-width: 1368px){
    .editBrancheBoxContainer {
        width: 60rem;
        height: 35rem;
        background: white;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
}