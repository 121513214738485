#rubiOrderContainer {
    font-size: 1rem;
    height: calc(100% - 50px);
}

.rubiOrderHeader div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

#bookOrdersTr {
    cursor: default !important;
}

#bookOrdersTr td{
    cursor: default !important;
}

#bookOrdersButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding-left: 0.5rem;
}

#bookOrdersMsgSendOrDelete {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    font-size: 1rem;
}

#bookOrdersMsgHideMsg {
    display: hidden;
    opacity: 0;
    width: 0;
    height: 0;
}

#bookOrdersMsgShowSuccess {
    display: flex;
    opacity: 1;
    transition: 0.2s;
    width: auto;
    height: auto;
}

.orderSendCleanTD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 18px;
}


.headerTh input[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.headerTh input[type=checkbox]:hover:before {
    border: 1px solid var(--goldColor);
    transition: 0.2s;

}

.headerTh input[type=checkbox]:before{
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    transition: 0.2s;
    height: 1rem;
    top: 0;
    border: 1px solid rgb(85, 85, 85);
    left: 0;
    background-color:#353535;
    margin-right: 0.2rem;
}

.headerTh input[type=checkbox]:checked:before{
    content: "";
    display: block;
    position: absolute;
    border: 1px solid var(--goldColor);
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    background-color:#333333;
}

.headerTh input[type=checkbox]:checked:after{
    content: "";
    display: block;
    width: 0.2rem;
    height: 0.6rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

.headerTh a {
    margin-left: 0.5rem;
    font-size: 0.9rem;
}

#bookOrdersTr input[type=checkbox]:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    top: 0;
    border: 1px solid rgb(221, 221, 221);
    left: 0;
    background-color:white;
}

#bookOrdersTotalTr input[type=checkbox]:before{
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    top: 0;
    border: 1px solid rgb(168, 168, 168);
    left: 0;
    background-color:white;
}

#bookOrdersTr input[type=checkbox]:checked:before,
#bookOrdersTotalTr input[type=checkbox]:checked:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    border: 1px solid rgb(221, 221, 221);
    top: 0;
    left: 0;
    background-color:white;
}


#bookOrdersTr input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.4rem;
    height: 0.8rem;
    border: solid rgb(0, 126, 27);
    border-width: 0px 2px 2px 0px;
    margin-top: -1.2rem;
    margin-left: 0.3rem;
    
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

#bookOrdersTotalTr input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.4rem;
    height: 0.8rem;
    border: solid rgb(146, 146, 146);
    border-width: 0px 2px 2px 0px;
    margin-top: -1.2rem;
    margin-left: 0.3rem;
    
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rubiOrderHeader div section:nth-child(1){
    width: 50%;
}

.rubiOrderHeader div section:nth-child(1) h1{
    font-size: 1.8rem;
    font-weight: 400;
    
}

.rubiOrderHeader div section:nth-child(2){
    
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    font-weight: 200;
    width: 50%;

}

.rubiOrderHeader div section:nth-child(2) p:nth-child(1){
    margin-right: .8rem;
}

#rubiOrderContainer {
    max-height: 100%;
    height: 100%;
    overflow-x: hidden;
}

#rubiOrderContainer table thead tr:first-child th {
    background: var(--formBorder) !important ;
    height: 6rem;
    font-size: 1.8rem;
    font-weight: 400;
    z-index: 100;
    position: sticky;
    top:0;
}


#rubiOrderContainer table thead tr:nth-child(2) th {

    background-color: #3E3E3E;
    height: 2rem;
    z-index: 100;
    position: sticky;
    top: 6rem;
}


#rubiOrderContainer table thead tr:nth-child(3) th:first-child{
    width: 3rem;
}

#rubiOrderContainer table thead tr:nth-child(3) th {
   
    background-color: #3E3E3E;
    height: 2rem;
    z-index: 100;
    position: sticky;
    top: 9rem;
    font-weight: 500;
    font-size: 0.9rem;
}

#rubiOrderContainer table tr {
    width: 100%;
}

#rubiOrderContainer tbody {
    overflow-y: auto;
    height: calc(100% - 50px);
}

#rubiOrderContainer tbody tr {
    cursor: pointer;
    table-layout: fixed;
    height: 2rem;
    background: #FFF;
}

#bookOrdersTotalTr td {
    border: none !important;
    background: rgb(235, 235, 235);
    color: rgb(92, 92, 92);
    font-weight: 600;
    font-size: 2rem !important;

}

#bookOrdersTotalLabel {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    align-items: center;
    justify-content: flex-start;
}
#bookOrdersTotalLabel a {
    font-size: 0.9rem;
}

#rubiOrderContainer table tbody tr th{
    width: 70rem;
    height: 3rem;
    font-size: 1.2rem;
}


#rubiOrderContainer table thead tr {
    color: white;   
    border: none;
    font-size: 1rem;
    text-align: center;
}



#rubiOrderContainer table tbody tr:hover{
    background-color: var(--tableHover);
}

#rubiOrderContainer table tbody td:first-child{
    width: 4rem;
}

#rubiOrderContainer table tbody td {
    border: 1px solid rgb(224, 224, 224);
    height: 2rem;
    text-align: center;
    font-size: 0.9rem !important;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#bookOrdersLoadingTr {
    height: 8rem !important;
    border: none !important;
}

#bookOrdersLoadingTr:hover{
    background: white !important;
}

.rubiOrderButton {
    
    height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    background: #333333  !important;
    color: #a5a5a5 !important;
    border-radius: 0 !important;
    width: 100px;
    font-size: 0.9rem;
    font-weight: 400;

}

.rubiOrderButton:hover{
    color: white !important;
}

.rubiOrderButton a {
    margin-left: 0.3rem;
}

.sendOrdersHide {
    display: none;
}

.fadeIn {

    transition: opacity 1s ease;
}