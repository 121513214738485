.containerGlobalAlert{
  width: 16rem;
  height: fit-content;
  position: absolute;
  background-color: #fdfdfd;
  border-radius: 4px;
  z-index: 999;
  top: 3.4rem;
  left: calc(50% + 1.5rem);
  transform: translate(-50%, 0%);
  transition: all ease 0.1s;
  box-shadow: 0px 0px 10px #a5a5a5;
  display: grid;
}
.containerGlobalAlert:hover{
  
}

.containerHeaderAlert{
  display: grid;
  grid-template-columns: 7fr 1fr;
  height: 1rem;
  margin-top: 0.2rem;
}

.alertHeaderText{
  font-size: 0.9rem;
  color: black;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  margin-left: 0.6rem;
}

.alertClose{
  margin: auto;
  height: 1rem;
  width: fit-content;
  padding: 0.15rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}
.alertClose:hover{
  background-color: #e7e7e7;
  cursor: pointer;
}

.alertbell{
  margin: auto;
  height: 1rem;
  width: fit-content;
  padding: 0.15rem;
  border-radius: 50%;
  transition: all ease 0.1s;
  animation-name: bellRing;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.containerAlertMessage{
  width: 14.4rem;
  height: 3.8rem;
  overflow: auto;
  margin: 0.3rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  background-color: #f8f8f8;
  border-left: 2px solid #c49e17;
  border-radius: 4px;
  padding: 0.4rem;
}

.alertMessageText{
  font-size: 0.8rem;
}

.containerAlertResponseOptions{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

.alertConfirmButton{
  border: none;
  background-color: #f1f1f1;
  font-weight: 500;
  border-bottom-right-radius: 4px;
  transition: all ease 0.1s;
  height: 1.6rem;
  padding-bottom: 0.1rem;
}
.alertConfirmButton:hover{
  background-color: #141414;
  color: #f1f1f1;
}

.alertCancelButton{
  border: none;
  background-color: #f1f1f1;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  transition: all ease 0.1s;
  height: 1.6rem;
  padding-bottom: 0.1rem;
}
.alertCancelButton:hover{
  background-color: #141414;
  color: #f1f1f1;
}

.alertOkButton{
  border: none;
  background-color: #f1f1f1;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all ease 0.1s;
  height: 1.6rem;
  padding-bottom: 0.1rem;
  grid-column-start: 1;
  grid-column-end: 3;
}
.alertOkButton:hover{
  background-color: #141414;
  color: #f1f1f1;
}

@keyframes bellRing {
  0% {
    transform:rotate(0deg);
  }
  30% {
    transform:rotate(15deg);
  }
  80% {
    transform:rotate(-15deg);
  }
  100% {
    transform:rotate(0deg);
  }
}