#editAssetBackButton {
    margin-top: -8rem;
}

.assetsCadastrationContainer {
    overflow: hidden;
    width: 50rem;
    height: 30rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.assetsCadastrationHeader {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--headerAllBg);
    height: 3rem;
    width: 100%;
}

.assetsCadastrationHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.assetsCadastrationHeader button {
    margin-right: 1rem;
}

.editClassAssets {
    width: 50rem;
    height: inherit;
    background: transparent;
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;
}

.editClassAssetsModal {
    transition: 0.3s;
    height: inherit;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 10;
}


.editClassAssetsBody {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: inherit;
    align-items: center;
}

.editAssetsTable {
    display: flex;
    flex-direction: column;
    width: inherit;
    margin-top: 2rem;
    align-items: center;
    overflow-x: hidden;
    max-height: 18rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000; 
}

.editClassAssetsBody thead tr th {
    position: sticky;
    top:0;
    background: var(--topBarBg);
    height: 2rem;
    color: white;
    font-size: .9rem;
    font-weight: 300;
}

.editClassAssetsBody thead tr th:first-child {
    border-radius:  0.5rem 0 0;
}

.editClassAssetsBody tbody tr td {
    height: 1.4rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    font-size: 0.9rem;
}

.editClassAssetsBody tbody tr td:last-child {
    width: 5rem;
    transition: 0.2s;
}

.editClassAssetsBody tbody tr td:last-child:hover {
    background: rgb(192, 192, 192);
}

.editClassAssetsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
    gap: 0.5rem;
    width: 100%;
    height: 3rem;
    font-size: 1.2rem;
    background: var(--headerAllBg);
}

.editClassAssetInput {
    border:none;
    width: 100%;
    height: 1.4rem;
    text-align: center;
    font-size: 0.9rem;
    background: rgb(216, 215, 215);
}

.editClassAssetInput::placeholder {
    color: rgb(7, 7, 7);
}

.editClassAssetsHeader svg {
    margin-left: 1rem;
    transition: 0.2s;
}

.editClassAssetsHeader svg:hover {
    color: var(--goldColor);
}

.editClassAssetsHeader p {
    font-size: 1.2rem;
}

.assetsCadastrationBody {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 3rem);
    /* transition: 0.2s; */
}

.assetCadastrationDualDiv {
    width: 80%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2,1fr);
}

.assetCadastrationDualDiv .assetCadastrationInput {
    width: 100%;
    height: 2.4rem;
    border: 1px solid var(--goldColor);
}

.assetCadastrationDualDiv .assetCadastrationInput:focus {
    border: 2px solid var(--goldColor);
    outline: none;
}

.assetCadastrationTreeDiv {
    width: 80%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3,1fr);

}

#assetCadastrationButton {
    margin-top: 2rem;
}

.assetsCadastrationBody span {
    width: 80%;
}

.assetsCadastrationBody span p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

