.containerGeralCreateFormStructure{
  width: 100%;
  margin-left: auto;
  height: calc(100vh - 3rem);
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.containerSecoesPerguntas{
  overflow: auto;
  width: 98%;
  padding-left: 2%;
  max-height: calc(100vh - 18rem);
}

.containerTituloForm{
  display: grid;
  height: 5rem;
  padding-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.tituloTitulo{
  font-size: 1.8rem;
  font-weight: 400;
  color: #11639E;
  text-align: center;
}

.inputTituloTitulo{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.6rem;
  width: 14rem;
  padding: 0.2rem;
  text-align: center;
  margin: auto;
  margin-top: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloTitulo:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.tituloSecao{
  font-size: 1.4rem;
  font-weight: 400;
  color: #11639E;
  margin-left: 0.4rem;
}

.inputTituloSecao{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.2rem;
  width: 14rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-top: 0.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloSecao:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.pergunta{
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  width: 92%;
  border-top: 2px solid #11639E;
  margin: auto;
  margin-top: 0.6rem;
  padding: 0.6rem;
  background: white;
  box-shadow: -1px 3px 6px gray;
}

.barraLateral{
  width: 0.2rem;
  height: 100%;
  background-color: #11639E;
  margin: auto;
  margin-right: 0.6rem;
}

.inputTituloPergunta{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloPergunta:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.opcaoPergunta{
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

.tituloOpcaoPergunta{
  color: #11639E;
  margin-bottom: 0.1rem;
}

.selectTipoPergunta{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.selectTipoPergunta:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tipoPerguntaTexto{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-top: 1rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaTexto:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerRadioOption{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: fit-content;
  margin-top: 0.6rem;
}

.containerTipoPerguntaOpcaoMultipla{
  margin-top: 1rem;
}

.inputOptionName{
  border: none;
  border: 1px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  min-width: 4rem;
  width: min-content;
  height: fit-content;
}

.inputOptionName:focus{
  outline: none;
  border: 1px solid #11639E;
}

.addSubSection{
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
  border: none;
  background: transparent;
  color: rgb(97, 97, 97);
  transition: all ease-in-out 0.1s;
  white-space: nowrap;
}

.addSubSection:hover{
  color: black;
}

.addRadioOption{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addRadioOption:hover{
  background: #dbdbdb;
}

.deleteRadioOption{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteRadioOption:hover{
  background: #dbdbdb;
}

.botaoNovaPergunta{
  width: 30%;
  margin-top: 0.8rem;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaPergunta:hover{
  background-color: #064d80;
}

.botaoNovaSecao{
  width: 20%;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  height: fit-content;
  font-size: 1rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaSecao:hover{
  background-color: #064d80;
}

.habilitarObservacao{
  font-weight: 500;
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
}

.containerTipoPerguntaPorcentagem{
  display: grid;
  grid-template-columns: 1fr auto;
  width: 20%;
}

.tipoPerguntaPorcentagem{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 1rem !important;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaPorcentagem:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.porcentagem{
  margin-top: auto;
  padding: 0.05rem;
  padding-left: 0.4rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.tipoPerguntaMonetario{
  margin-top: 1rem !important;
  width: 20%;
}

.containerTipoPerguntaCaixaSelecao{
  margin-top: 1rem;
}

.containerCheckBoxOption{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: fit-content;
  margin-top: 0.6rem;
}

.addCheckBoxOption{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addCheckBoxOption:hover{
  background: #dbdbdb;
}

.deleteCheckBoxOption{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteCheckBoxOption:hover{
  background: #dbdbdb;
}

.containerCabecalhoPortfolio{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
  max-height: 6rem;
}

.descricaoPortfolio{
  width: 90%;
  margin: auto;
  margin-top: 0.4rem;
  height: 2.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  box-shadow: -1px 3px 6px gray;
}

.descricaoPortfolio:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerDescricaoPortfolio{
  display: grid;
  grid-template-rows: auto 1fr;
}

.containerSelectTipoPortfolio{
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

.selectTipoPortfolio{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  margin-left: 1.2rem;
  height: 2.4rem;
}

.selectTipoPortfolio:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.inputNameFilter{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  height: 1.95rem;
}

.inputNameFilter:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.secao{
  margin-top: 1rem;
}

.containerTags{
  display: flex;
  width: fit-content;
  border-left: 2px solid #11639E;
  padding-left: 0.6rem;
}

.inputNameTag{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.6rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputNameTag:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tagTitle{
  font-size: 1.5rem;
  font-weight: 400;
}

.addTagButton{
  border: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  font-size: 1rem;
  background: #e6e6e6;
  height: fit-content;
}

.addTagButton:hover{
  background: #c9c9c9;
  cursor: pointer;
}

.containerInfoTags{
  margin: auto;
}

.deleteTagButton{
  border: none;
  border-right: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1rem;
  background: #c4e6fd;
  font-weight: 500;
}

.deleteTagButton:hover{
  background: #a4c6dd;
  cursor: pointer;
}

.nomeTag{
  font-size: 1.2rem;
  background: #c4e6fd;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin: auto;
  border-left: none;
}

.containerTag{
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  border-radius: 10px;
}

.containerDisplayTags{
  overflow: hidden;
  border: 1px solid #dadada;
  margin-left: 0.4rem;
  max-width: 10rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 4px;
}

.formTags{
  display: flex;
}

.tituloSelectPortfolio{
  font-size: 1.8rem;
  color: #11639E;
  height: 3rem;
}

.containerHeaderSelectPortfolio{
  text-align: center;
  height: 8rem;
  padding-top: 2rem;
  background-color: #d8e0e7;
}

.containerPortfoliosExistentes{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 18rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow: auto;
}

.itemPortfolioAgressivo{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioAgressivo:hover{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.itemPortfolioModerado{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioModerado:hover{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.itemPortfolioConservador{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioConservador:hover{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.itemPortfolioGeral{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioGeral:hover{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #ebebeb;
  cursor: pointer;
}

.tituloPortfolio{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.separacaoItemPortfolioAgressivo{
  background-color: #ff0000;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioModerado{
  background-color: #ffae00;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioConservador{
  background-color: #00ccff;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioGeral{
  background-color: #ebebeb;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.divDescricao{
  display: table;
}

.descricaoItemPortfolio{
  color: white;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.tipoPerfilPortfolioAgressivo{
  color: red;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioModerado{
  color: #ffae00;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioConservador{
  color: #00ccff;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioGeral{
  color: #ebebeb;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.containerOverlayPreviaPortfolio{
  position: absolute;
  width: 100%;
  height: calc(100% - 3rem);
  margin-top: 3rem;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 2fr 3fr;
  background-color: #00000063;
}

.overlayPreviaPortfolio{
  width: 100%;
  height: 100%;
}

.containerDetalhesPortfolioModerado{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #302700;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioConservador{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #00323f;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioGeral{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #3a3a3a;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioAgressivo{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #270000;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}


.containerHeaderDetalhesPortfolio{
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  height: fit-content;
}

.closeDetailButton{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.closeDetailButton:hover{
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.titleHeaderDetail{
  color: white;
  font-size: 1.8rem;
  height: fit-content;
  margin: auto;
  text-align: center;
}

.showInfoDetail{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.containerSubHeaderDetalhesPortfolio{
  display: grid;
  grid-template-columns: 10rem auto 10rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
}

.infoSubHeaderL{
  color: white;
  font-size: 1.4rem;
  margin-left: auto;
  width: fit-content;
}

.infoSubHeaderR{
  color: white;
  font-size: 1.4rem;
  margin-right: auto;
  width: fit-content;
}

.divisaoSubHeaderConservador{
  width: 2px;
  height: 100%;
  background-color: #00ccff;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderGeral{
  width: 2px;
  height: 100%;
  background-color: #ebebeb;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderModerado{
  width: 2px;
  height: 100%;
  background-color: #ffae00;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderAgressivo{
  width: 2px;
  height: 100%;
  background-color: red;
  margin-left: 2rem;
  margin-right: 2rem;
}

.containerSecoesPortfolio{
  width: 90%;
  margin-left: auto;
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.selectPortfolioAtual{
  margin-top: auto;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  text-align: center;
  height: fit-content;
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
}

.selectPortfolioAtual:hover{
  background-color: #e6e6e6;
  color: #141414;
}

.secaoDetalhePortfolioConservador{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioConservador:hover{
  color: #00ccff;
  cursor: pointer;
}

.secaoDetalhePortfolioGeral{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioGeral:hover{
  color: #ebebeb;
  cursor: pointer;
}

.secaoDetalhePortfolioModerado{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioModerado:hover{
  color: #ffae00;
  cursor: pointer;
}

.secaoDetalhePortfolioAgressivo{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioAgressivo:hover{
  color: #ff0000;
  cursor: pointer;
}

.perguntaDetalhePortfolioConservador{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioConservador:hover{
  color: #00ccff;
  cursor: default;
}

.perguntaDetalhePortfolioGeral{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioGeral:hover{
  color: #ebebeb;
  cursor: default;
}

.perguntaDetalhePortfolioModerado{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioModerado:hover{
  color: #ffae00;
  cursor: default;
}

.perguntaDetalhePortfolioAgressivo{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioAgressivo:hover{
  color: #ff0000;
  cursor: default;
}

.loaderAllForms{
  margin-top: 5rem !important;
}

.loaderSpecificForm{
  margin-top: 42vh;
}

.resetFilter{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 2.4rem;
  width: fit-content;
  border: none;
  background: transparent;
  background-color: rgb(218, 218, 218);
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
  box-shadow: -1px 3px 6px gray;
}

.resetFilter:hover{
  background-color: rgb(218, 218, 218);
  box-shadow: -1px 2px 2px gray;
}

.containerButtonCreateForm{
  display: grid;
  height: 6rem;
  margin-top: auto;
  background-color: #d8e0e7;
}

.createFormButton{
  font-size: 2rem;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.createFormButton:hover{
  background-color: #cecece;
  color: #141414;
}

.containerOverlay{
  position: absolute;
  background-color: #d8e0e7;
  width: 100%;
  height: calc(100vh - 3rem);
}

.containerOverlay{
  display: grid;
}

.containerEscolhaTitulo{
  display: grid;
  grid-template-rows: auto auto auto;
  width: 50%;
  margin: auto;
}

.tituloEscolhaTitulo{
  font-size: 1.8rem;
  color: #11639E;
  width: fit-content;
  margin: auto;
}

.inputReplyTitle{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 2rem;
  height: 1.95rem;
}

.inputReplyTitle:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.confirmReplyTitle{
  width: 30%;
  margin: auto;
  margin-top: 2rem;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.confirmReplyTitle:hover{
  background-color: #064d80;
}

.containerMeusPortfoliosExistentes{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 15rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 17rem);
  max-height: calc(100vh - 17rem);
  overflow: auto;
}

.MeuItemPortfolioAgressivo{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioAgressivo:hover{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.MeuItemPortfolioModerado{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioModerado:hover{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.MeuItemPortfolioConservador{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioConservador:hover{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.MeuItemPortfolioGeral{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioGeral:hover{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #c2c1c1;
  cursor: pointer;
}

.tituloMeuPortfolio{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detalheMeuItemPortfolio{
  color: white;
  text-align: center;
  height: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nomeAssessorPortfolioConservador{
  color: #001318;
  background-color: #33d6ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioModerado{
  color: #1f1900;
  background-color: #ffb923;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioAgressivo{
  color: #160000;
  background-color: #ff2d2d;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioGeral{
  color: #141414;
  background-color: #c2c2c2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dataPortfolioGeral{
  color: #141414;
  background-color: #c2c2c2;
  position: absolute;
  bottom: 97%;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.containerButtonCreateForm{
  display: grid;
  grid-template-columns: auto 1fr;
}

.modoEdicaoText{
  font-size: 1.6rem;
  font-weight: 500;
  height: auto;
  width: auto;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #188f00;
}