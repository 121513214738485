.HelpCenterContainer {

    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 49px;
    left: 49px;
    right: 0;
    bottom: 0;
    background: var(--topBarBg);
    width: calc(100vw -49px);
    height: 100vh;
    transition: 0.2s;
    overflow: hidden;
}

.HelpCenterContainer h2 {
    margin-right: 49px;
}

.helpCenterHide {
    opacity: 0;
    display: hidden;
    max-width: 0;
    width: 0;
    max-height: 0;
    height: 0;
    position: absolute;
    transition: 0.2s;
}

.helpCenterContent {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: white;
}