.buttonSetToBase{
    width: fit-content;
    height: 2rem;
    margin-bottom: max(auto,0.2rem);
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
    margin-top: 1rem;
}

.buttonSetToBase:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.buttonSetToBaseConfirm{
    width: fit-content;
    height: 2rem;
    margin-bottom: max(auto,0.2rem);
    margin-left: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.buttonSetToBaseConfirm:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.itemButton{
    display: flex;
    justify-content: center;
}

.clientSetToBaseInformationsBox{
    width: 70%;
    height: 20%;
    display: grid;
    grid-template-columns: auto auto;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    min-height: 2rem;
}

.titleSetToBase {
    font-size: 1rem;
    margin-left: 1rem;
    font-weight: 600;
}

.clientSetToBaseBodycontent{
    display: grid;
    height: 36rem;
    margin-top: 1rem;
}

.clientDetailsBox {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0.8rem;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.clientDetailsBox2 {
    display: grid;
    grid-template-columns: 2fr 7fr ;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0.8rem;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.selectClientSetToBase{
    width: 25rem;
}

.titleSelectClient{
    margin-top: 1.0rem;
}

.subTitleSetToBase {
    font-weight: 500;
    font-size: 1.1rem;
    margin-left: 3.5rem;
    margin-top: 1rem;
}

.clientSetToBaseContainer{
    width: 60rem;
    height: 37rem;
    background: white;
    display: flex;
    flex-direction: column;
}
