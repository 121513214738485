.clientsBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    /* width: 95%; */
}

.clientsContainer {
    display: flex;
    flex-direction: column;
    height:100%;   
}

.clientsHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--headerAllBg);
    width: 100%;
    height:6rem;
    color: var(--headerFontColor);
    font-size: 1.3rem;
}

.clientsHeader p{
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
}

.clientsBlockedArea {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    background: rgba(73, 73, 73, 0.4);
    position: absolute;
}

.clientsModalButton {
    background: var(--headerAllBg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;
}

.clientsModalButton button {
    right:0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background: var(--formBorder);
    color: white;
}




.clientsButtons {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    gap: 1rem;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}