.boxCSGeral {
    /* display: flex;
  flex-direction: column; */
    color: rgb(9 3 59);
    background: #eaeff4;
    height: 90vh;
    overflow-x: auto;
    margin: -2rem;
    padding: 2rem;
    box-shadow: none;
}

.boxCenarioCS {
  height: 0px;  
  transition: height 0.3s ease;
  background: #FFFFFF;
  padding-left: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;

  /* border: 1px solid #F8F9FA; */
  border-radius: 30px;
  /* box-shadow: -5px 5px 5px 1px  #D0D5D8; */

  
  align-items: center;
  
}
.buttonGraphsCubastar:hover{
  border: none;
  font-weight: bold;
  color: #c49e17;
  height: fit-content;
  transition: 0.1s ease-in-out;
}

.saveButtonClass{
  background-color: #c49e17;
  border: none;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: white;
  height: fit-content;
  min-width: 10rem;
  display: grid;

  transition: 0.2s ease-in-out;
}
.saveButtonClass:hover{
  background-color: #d4ac00;
}
.cancelButtonClass{
  background-color: #bbbbbb;
  border: none;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: white;
  height: fit-content;
  min-width: 10rem;
  display: grid;

  transition: 0.2s ease-in-out;

}
.cancelButtonClass:hover{
  background-color: #d4ac00;
}
.editButtonClass{
  background-color: #c49e17;
  border: none;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: white;
  height: fit-content;
  min-width: 10rem;
  display: grid;
}
.editButtonClass:hover{
  background-color: #d4ac00;
}

.textareaCubastar{
  resize: none;
  /* width: 96%; */
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;

  background-color: #f6f6f6;
  padding: 0.5rem;
  border: #494946 1px solid;
  border-radius: 10px;
}

.transition {
  transition: height 0.2s ease;
}

.boxCenarioCSExpand {
  /* height: 300px; */
  transition: height 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;
}

.boxTaticasCS {
  height: 0PX;
  transition: height 0.3s ease;
  background: #FFFFFF;
  /* padding-left: 1rem; */
  margin-bottom: 1rem;
  overflow: hidden;

  border-radius: 30px;
 

    display: flex;
    align-items: center;
}

.boxTaticasCSExpand {
    height: 300px;
    transition: height 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.buttonExpandableDiv {
  background-color: transparent;
  border: none;
  outline: none;
  /* height: 1.5rem; */
  /* display: grid; */
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  transition: 0.2s ease-in-out;
}
.selectedMenu button {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.selectedMenu button {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: 10rem;
}
.boxTablesCS {
    display: grid;
    grid-template-rows: auto auto;
    background-color: white;
    border-radius: 30px;
    padding-top: 1rem;
}

.boxTablesCSHeader {
    position: relative;
    top: 10;
    z-index: 3;
    display: inline-flex;
    align-items: center;
    grid-template-columns: 2fr 3fr;
    margin-left: 1.5rem;
}

.cubastarTableGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.boxTablePrincipalMenu {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    font-size: 13.33px;
    height: 50px;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: calc(min(30vw, 15.5rem) - 1rem);

    display: flex;
    justify-content: center;
    align-items: center;
}
.TacticalTdStyleLeft {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    /* border-top-right-radius: 30px;
  border-bottom-right-radius: 30px; */
}
.TacticalTdStyleRight {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.teste * {
    margin-bottom: 1rem;
}
.boxTablesCSHeaderLeftNone {
    display: none;
}

.boxTablesCSHeaderLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0.5rem; */
    gap: 0.5rem;
    width: 99%;
    justify-content: space-between;
}

.boxTablesCSHeaderRight {
    /* position: absolute;
  z-index: 3; */
    display: flex;
    /* margin-left: 170px; */
    flex-direction: row;
    margin-top: 1rem;
    align-items: space-around;
    /* padding: 0.5rem; */
    gap: 0.5rem;
    overflow-x: auto;
    min-width: 10rem;
}

.boxTablesCSBody {
    display: grid;
    /* grid-template-columns: auto 1fr; */
    min-height: 90vh;
    margin-top: 0.5rem;
}

.bodyLeftCSMenu {
    /*   position: absolute;
 */
    display: flex;
    flex-direction: row;
    z-index: 3;
    /* padding-left: 0.5rem; */
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
    /* margin-top: 0.5rem; */
    gap: 0.5rem;
    /* height: 42rem; */
    overflow-x: auto;
}
.bodyRightCSMenu {
    /* position: absolute;
  margin-left: 340px; */
    display: flex;
    flex-direction: row;
    z-index: 3;
    /* padding-left: 0.5rem; */
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
    /* margin-top: 0.5rem; */
    gap: 0.5rem;
    /* height: 42rem; */
    overflow-x: auto;
}

.bodyCSContent {
    /* background-color: #c4c4c4; */
    margin: 0.5rem;
    margin-left: 0px;
    padding: 0.5rem;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.itemMenuCS {
    background-color: #c4c4c4;
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: rgb(9 3 59);
    min-width: 10rem;
    /* min-width: calc(min(30vw, 15.5rem) - 1rem); */
    /* overflow: hidden; */
}

.itemMenuSelectedCS {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: 10rem;
    /* min-width: calc(min(30vw, 15.5rem) - 1rem); */
}

.itemMenuCS2 {
    background-color: #c4c4c4;
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: rgb(9 3 59);
    overflow: hidden;
    min-width: fit-content;
}

.itemMenuSelectedCS2 {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: fit-content;
}

.boxCenarioCSContent {
    /* background-color: #c49e17; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-items: center;
}

.boxTaticasCSContent {
    margin-top: 1.5rem;
    width: 100%;
    /* overflow: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.contentCenarioCsItem {
    width: 95%;
    display: grid;
    grid-template-columns: 5fr 1fr;
    /* border-radius: calc(2.75rem + 2px); */
    border-radius: 10px;
    overflow: hidden;
    /*  border: 1px solid rgb(9 3 59); */

    background-color: #f0f1f1;
}

.cenarioItemCSTextHeader {
    font-weight: bold;
    padding-left: 2rem;
    padding-top: 0.5rem;

    color: #d7b32e;
}
.cenarioItemCSTextCopy {
    background-color: #fffef3;

    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #ebd580;
    padding: 0.5rem;
    gap: 0.25rem;
}

.cenarioItemCSText {
    padding: 1rem 0rem 1rem 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /* display: flex; */
    flex-wrap: nowrap;
    padding-left: 2rem;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    color: #494946;
    max-height: 15rem;
    overflow: auto;
}

.cenarioItemCSAvatar {
  min-height: 6rem;
  align-items: center;
  /* display: grid; */
  /* grid-template-rows: 4fr 1fr 1fr; */
  /* align-items: center; */
  flex-direction: column;
  font-weight: bold;
  margin-right: 0.5rem;
  /* align-content: center; */
  margin-top: 0.5rem;
  justify-content: center;

}
.tacticalTdStyleAl *l {
    margin-bottom: 1rem;
}

.csAvatar {
  background-color: #c4c4c4;
  border-radius: 10px;
  height: 6.25rem;
  width: 6.25rem;
  margin-right: 10px;
  /* margin-top: 0.5rem; */
  overflow: hidden; 
  float: right;
}
.csName {
  float: right;
    color: #dcc04f;
    font-size: 1rem;
  width: 110px;
  min-height: 2.5rem;
  padding:'0.5rem 0rem';
  display: flex;
  align-items: center;
  /* margin-top: -20px; */
}

.cubastarTacticalContent {
    background: white;
    width: 80vw;
    height: fit-content;
}

.cubastarTacticalDivGrid {
    display: grid;
    grid-template-columns: 49.5% 1% 49.5%;
    height: 26rem;
}

.cubastarTacticalContainer {
    margin: 10px;
    border-radius: 10px;
    background-color: #eff0f1;
    /* padding: 5px; */
}

.cubastarTacticalInternalUse {
    text-align: center;
    font-size: 20px;
    background-color: #bbbbbb;
    font-weight: bold;
    border-radius: 10px;
    padding: 1rem;
    align-items: center;
}

.bodyCSContentCarteiras {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 100%;
}

.bodyCSContentCarteirasTabelas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 99%;
}

.bodyCSContentCarteirasAlocacao {
    /* background-color: aquamarine; */
    /*border: 1px solid rgb(9 3 59);*/
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyCSContentCarteirasExemplo {
    /* background-color: bisque; */
    /*border: 1px solid rgb(9 3 59);*/
    padding: 0.5rem;
}

.bodyCSContentCarteirasImagens {
    /* background-color: cadetblue; */
    border: 1px solid rgb(9 3 59);
    padding: 0.5rem;
}

.bodyCSContentFundos {
    display: grid;
    /* grid-template-rows: 20rem 21rem; */
    height: 100%;
}

.bodyCSContentFundosGrafico {
    /* border: 1px solid rgb(9 3 59); */
    padding-right: 0.5rem;
    background-color: white;
    padding: 0.5rem;
    display: grid;
}

.vertical-line {
    width: 1px; /* Largura da linha vertical */
    background-color: #000000; /* Cor da linha vertical */
    height: 100%; /* Define a altura da linha vertical para que ela ocupe todo o espaço vertical entre as tabelas */
    margin: 0 10px; /* Espaçamento horizontal entre as tabelas e a linha vertical */
}

.cubastar-rentability-wrapper {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}

.bodyCSContentCarteirasGraph {
    /* width: 100%; */
    padding: 0.5rem;
}
.bodyCSContentRentabilityTabelas {
    width: 100%;
    padding: 0.5rem 0px 0px 9px;
}

@media (max-width: 1920px) {
    .boxTablesCSBody {
        display: flex;
        flex-direction: column;
    }

    .bodyCSContentCarteirasExemplo {
        overflow-x: auto;
    }
    .bodyCSContentCarteirasTabelas {
        width: 100%;
        display: flex;
    }
}

@media only screen and (max-width: 1600px) {
    .cubastar-rentability-wrapper {
        display: grid;
        justify-content: space-around;
    }
}
/* @media (max-width: 1447px) {
  .bodyCSContent {

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    overflow-x: hidden;
  }

  .bodyCSContent .bodyCSContentCarteirasAlocacao,
  .bodyCSContentCarteirasExemplo {
    margin-top: 10px;
    overflow-x: hidden;
  }
  .bodyCSContentCarteirasTabelas {
    display: flex;
    flex-direction: column;      
    width: 100%;

    display:flex ;


  }

  .bodyCSContentCarteirasExemplo {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }

  .bodyCSContentCarteirasGraph {
    width: 100%;
  }
} */

.buttonCopyAllCS {
    background-color: #bbbbbb;
    border: none;
    font-weight: bold;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    color: white;
    height: fit-content;
    min-width: 10rem;
    display: grid;
    grid-template-columns: auto 2rem;
}

.buttonCopyAllCS:hover {
    background-color: #d4ac00;
}

.menuDivParent{
  position: relative;
  display: inline-block;
  cursor: pointer;
/*   margin-left: 8px;
  margin-bottom: 12px; */
}

.menuDivCS{
  position: absolute;
  top: 0px;
  left: 0;
  /* display: none; */
  /* padding: 8px;
  border: 1px solid black; */
  border-radius: 1rem;
  background-color: rgba(212, 212, 212, 0.923);
  z-index: 3;
}

.menuDivParent:hover .menuDivCS {
  display: block;
}

.itemMenuNewCS{
  padding: 1rem;
  color: #d4ac00;
  font-weight: 500;
  background-color: #f5f5f559;
  white-space: nowrap;
}

.itemMenuNewCSInative{
  color: black;
  padding: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.itemMenuNewCSInative:hover{
  background-color: #77777759;
  color: black;
}

.itemMenuNewCS2{
  padding: 0.7rem;
  color: #d4ac00;
  font-weight: 500;
  background-color: #f5f5f559;
  white-space: nowrap;
}

.itemMenuNewCSInative2{
  color: black;
  padding: 0.7rem;
  font-weight: 500;
  white-space: nowrap;
}

.itemMenuNewCSInative2:hover{
  background-color: #77777759;
  color: black;
}

.menuCSRight2{
  /* border-left: 3px solid black; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* borda tem só 40% de altura */
.menuCSRight2::before{
  content: '';
  position: absolute;
  width: 3px;
  height: 60%;
  background-color: #d4ac00;
}