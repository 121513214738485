.containerStatusAbc{
    width: 20rem;
    height: 20%;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

.containerStatusConfirm{
    width: 20rem;
    height: 5rem;
    background-color: #D9D9D9;
}

.statusConfirm{
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10%;
    justify-items: center;
}

.containerStatusList{
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.containerStatusList > h3{
    display: flex;
    justify-content: center;
}

.containerStatusName{
    display: grid;
    grid-template-columns: auto auto 1fr;
  }

.containerSelectAll{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 10%;
    margin-bottom: 5%;
}

.containerCheckbox{
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.h2status{
    font-size: 24px;
}

.observationFilterAbc{
    /* color: red; */
    padding: 1rem 1rem 0 1rem;
    font-size: 0.7rem;
    font-weight: 400;
}