
.formReportsCadastration{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 1.2rem;
}

.formReportsCadastration .divInputsReportsCadastration{
    position: relative;
    flex-grow: 1;
    margin: 0.6rem;
}

.formReportsCadastration .divInputsReportsCadastration input[type='text']{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    width: 20rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
  
.formReportsCadastration .divInputsReportsCadastration input[type='text']:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.formReportsCadastration .divInputsReportsCadastration input[type='text']::placeholder{
    color:transparent;
}
  
.formReportsCadastration .divInputsReportsCadastration label{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.formReportsCadastration .divInputsReportsCadastration input[type='text']:required:invalid + label:before{
    content: '*';
}
.formReportsCadastration .divInputsReportsCadastration input[type='text']:focus + label,
.formReportsCadastration .divInputsReportsCadastration input[type='text']:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}


.formReportsCadastration .ReportCadastrationsButton {
    margin-top: 1.2rem;
    background-color: var(--formBorder);
    border: 1px solid var(--formBorder);
    color: #fff;
    padding: 0.4rem;
    font-size: 1rem;
    transition: .2s;
    font-weight: 400;
    border-radius: 0.3rem;

}

.periodicReportLabel {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    gap: 1.5rem;
    
    
}

.periodicReportLabel label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.formReportsCadastration .ReportCadastrationsButton:hover {
    background-color: #fff;
    color: var(--formBorder);
    border: 1px solid var(--formBorder);
}



.radioSelectContainerColumn{
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;  
    font-size: 0.95rem;
    font-family: 'Roboto';
    color: #7F777F;
    font-weight: 500;
    margin-top: 0.6rem;
    width: 100%;
    margin-bottom: 0.6rem;
}

.radioSelectContainerColumnLine{
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}

.radioSelectContainerColumnLine div{
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

.fileInputAdjust input[type='file'] {
    display: none !important;
    height: 2.2rem;
}
  
  /* Aparência que terá o seletor de arquivo */
.fileInputAdjust label {
    background-color: #3498db;
    border: 1px solid #3498db;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    font-size: 1rem;
    padding: 6px 20px;
    font-weight: 400;
    transition: .2s;
}

  /* Aparência que terá o seletor de arquivo */
  .fileInputAdjust label:hover {
    background-color: #fff;
    height: 2.2rem;
    border-radius: 5px;
    color: #3498db;
    cursor: pointer;
    margin: 5px;
    padding: 6px 20px
}


.InputToFormat{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.InputToFormat div{
    position: relative;
    flex-grow: 1;
    /* margin: 1rem; */
}

.InputToFormat div input{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    min-width: 7rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
  
.InputToFormat div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.InputToFormat div input::placeholder{
    color:transparent;
}
  
.InputToFormat div label{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.InputToFormat div input:required:invalid + label:before{
    content: '*';
}
.InputToFormat div input:focus + label,
.InputToFormat div input:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
