.loading{
    display:inline-block;
    position:relative;
    vertical-align:middle;
    width:  2.5rem;
    height: 2.5rem;
    border:0.2rem solid transparent;
    border-top-color:rgb(122, 122, 122);
    border-bottom-color:rgb(122, 122, 122);
    border-radius:50%;
    animation: rotate 3s linear infinite, textIn 2s linear infinite;
}


.loadingGold {
  display:inline-block;
  position:relative;
  vertical-align:middle;
  width:  2.5rem;
  height: 2.5rem;
  border:0.2rem solid transparent;
  border-top-color: var(--goldColor);
  border-bottom-color:var(--goldColor);
  border-radius:50%;
  animation: rotate 3s linear infinite, textIn 2s linear infinite;

}
  
  @keyframes rotate{
     to { transform: rotate(360deg); }
  }

  @keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .container {
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content:center
  }

  .container span {
      margin-bottom: 0.5rem;
  }

  .text {
    font-size: 1.5rem;
    color:rgb(122, 122, 122)
  }

  .textGold {
    font-size: 1rem;
    font-weight: 200;
    color:rgb(204, 204, 204)
  }