.assetsOfferingsTable {
    width: 95%;
    border-radius: 10px 0 0 0;
    overflow: auto;
    margin-top: 2rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000;
    box-shadow: -2px 2px 20px -7px #000000;
}

.assetsOfferingsTable table {
    width: 130rem;
}

.assetsOfferingsTable thead tr:first-child th {
    background: var(--topBarBg);
    color: white;
    font-size: 1rem;
    font-weight: 300;
}

.assetsOfferingsTable thead tr:first-child th p {
    font-size: 1.3rem;
    font-weight: 300;
}

.assetsOfferingsTable thead tr:first-child th:first-child {
    height: 4rem;
    width: 10rem;
    border-radius: 10px 0px 0px 0px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
}

.assetsOfferingsTable thead tr:first-child th:nth-child(2){
    left:10px
}

.assetsOfferingsTableTheadDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 300;
}

.assetsOfferingsTheadRightDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
}

.assetsOfferingsTable thead tr:first-child th:nth-child(2) {
    height: 3rem;
    position: sticky;
    top: 0;
    left: 10rem;
}

.assetsOfferingsTableTheadDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.assetsOfferingsTable thead tr:nth-child(2) th {
    height: 1.5rem;
    position: sticky;
    top: 4rem;
    background: var(--tableSecondTh);
    color: white;
    font-size: 1rem;
    font-weight: 300;
}

.assetsOfferingsTable thead tr:nth-child(2) {
    background: var(--tableSecondTh);
}

.assetsOfferingsTable thead tr:nth-child(2) th:first-child {
    height: 1.5rem;
    position: sticky;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    top: 4rem;
    left: 0;
    width: 8rem;
    background: var(--tableSecondTh);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 110;
}

.assetsOfferingsTable thead tr:nth-child(2) th:nth-child(2) {
    height: 1.5rem;
    position: sticky;
    top: 4rem;
    left: 9rem;
    background: var(--tableSecondTh);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 110;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.assetsOfferingsTable tbody tr td {
    font-size: 1rem;
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    height: 2rem;
    transition: 0.2s;
    border-left: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
    border-bottom: 1px solid var(--tableBorder);
}

.assetsOfferingsTable tbody tr {
    background: rgb(255, 255, 255);
    transition: 0.2s;
}

.assetsOfferingsTable tbody tr:hover  {
    background: var(--tableHover) !important;
}

.assetsOfferingsTable tbody tr th {
    cursor: pointer;
    top: 4rem;
    color: white;
    background: var(--tableSecondTh);
    transition: 0.2s;
}

.assetsOfferingsTable tbody tr .companyTh:hover {
    color: var(--goldColor)
}

.assetsOfferingsTable tbody tr .companyTh {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    left: 0;
    width: 8rem;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: sticky;
    z-index: 99;
    z-index: 100;
    font-weight: 400;
}

.assetsOfferingsTable tbody tr .companyThSelected {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    left: 0;
    /* color: var(--goldColor); */
    background:#3e3e3e;
    width: 8rem;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: sticky;
    z-index: 99;
    z-index: 100;
    font-weight: 400;
}

.assetsOfferingsTable tbody tr .companyThSecond {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    left: 9rem;
    position: sticky;
    z-index: 99;
    font-weight: 400;
    z-index: 100;

}

.assetsOfferingsTable tbody tr .companyThSecondSelected {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background:#3e3e3e;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    /* color: var(--goldColor); */
    left: 9rem;
    position: sticky;
    z-index: 99;
    font-weight: 400;
    z-index: 100;

}


.assetsOfferingsShowTr {
    background:#333333;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}
  
.assetsOfferingsHideTr {
    display: none;
}

.assetsOfferingsReservation {
    background-color: var(--tableSelectedOperation);
    display: grid;
    grid-template-columns: 15% 85%;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    width: 100%;
    height: 6rem;
    font-weight: 100;
}
.assetsOfferingsReservationTitle {
    display: flex;
    flex-direction: column;align-items: center;
    justify-content: center;
}

.assetsOfferingsReservationTitle p {
    font-size: 1rem;
    color: white !important;
    border-bottom: 1px solid var(--goldColor);
}

.assetsOfferingsReservationHide {
    display: none;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }

  .assetsOfferingsShowTr th:first-child{
      position: sticky;
      left: 0;
  }


  .assetsOfferingsReservationsSelection {
      display: flex;
      width: 100%;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;
  }

  .assetsOfferingsSelectionLabel {
      display: flex;
      width: 25%;
      gap: 1px;
      flex-direction: column;
      justify-content: flex-start;
  }

  .assetsOfferingsSelectionLabel span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

  }

  .assetsOfferingsSelectionLabel span a {
      font-size: 0.9rem;
  }

  .assetsOfferingsSelectionValueLabel {
    display: flex;
    width: 12% !important;
    gap: 1px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .assetsOfferingsSelectionValueLabel span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.assetsOfferingsSelectionValueLabel span a {
    font-size: 0.9rem;
}

.assetsOfferingsSelectionLabelButton {
    display: flex;
    width: 12% !important;
    gap: 1px;
    flex-direction: column;
    justify-content: flex-start;
}

.assetsOfferingsSelectionLabelButton span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.assetsOfferingsSelectionLabelButton span a {
    font-size: 0.9rem;
}

  .assetsOfferingsIndicateButton {
      background: #11639e;
      border: none;
      color: white;
      font-size: 1rem;
      height: 2.1rem;
      transition: 0.2s;
  }

  .assetsOfferingsIndicateButton:hover {
      filter:brightness(0.95);
      -webkit-box-shadow: -0px 0px 15px 0px #11639e;
      box-shadow: -0px 0px 15px 0px #11639e;
  }

  #hideElement {
      opacity: 0;
  }