.userBoxArrow{
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
}
.userEditBox {
    background: white;
}

.headerBoxUser{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10rem;
}

.headerBoxUser h1:nth-child(2) {
    font-size: 1.5rem;
    font-weight: 300;
}

.headerBoxUser h1:first-child {
    font-size: 1.4rem;
    font-weight: 500;
}

.headerBoxUser div:first-child{
    margin-left: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
.headerBoxUser div:last-child{
    margin-right: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.headerBoxUser div a:first-child{
    font-weight: 500;
}

.headerBoxUser a {
    font-size: 1.4rem;
}

.formBoxUser{
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    /* flex-wrap: wrap; */
}

.formBoxUser #relative{
    position: relative;
}

.formBoxUser div #dinamic{
    border: 0;
    border: 1px solid var(--formBorder);
    height: 2.8rem;
    outline: none;
    min-width: 13rem;
    width: calc(100% - 1rem);
    padding-left: 1rem;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0.3rem;
}
  
.formBoxUser div #dinamic:focus{
    border: 1px solid #c39e16
}
  
.formBoxUser div #dinamic::placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.formBoxUser div #labelDinamic{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
}
  
.formBoxUser div #dinamic:required:invalid + #labelDinamic{
    color: red;
}
.formBoxUser div #dinamic:focus:required:invalid{
    border-bottom: 2px solid red;
}
.formBoxUser div #dinamic:required:invalid + #labelDinamic:before{
    content: '*';
}
.formBoxUser div #dinamic:focus + #labelDinamic,
.formBoxUser div #dinamic:not(:placeholder-shown) + #labelDinamic{
    font-size: 0.7rem;
    margin-top: -0.5rem;
    margin-left: 0.5rem;
    color: #3C3C3C;
    background-color: white;
}

.usersInformationDiv {
    border: 1px solid lightgrey;
    width: 15rem;
    height: 2.8rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    gap: 0.5rem;
    border-radius: 0.3rem;
}

.usersInformationDiv a {
    font-size: 0.9rem;
    margin-left: 1rem;
}


.usersInformationDiv a:first-child {
    font-weight: 600;
}

.userBoxHideFields {
    display: none;
}

.userBoxShowFields {
    width: 100%;
}


.formBoxUser #userBoxButtonShow {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.formBoxUser #userBoxButtonShow:hover {
    background: var(--buttonHover);
    color: white;
}
.formBoxUser #userBoxButtonHide {
    display: none;
}

.selectBrokers {
    width: 40rem;
}

@media(max-width: 1368px){
    .userEditBox {
        background: white;
    }

    .userBoxArrow{ 
        height: 30rem;
    }
    
    .headerBoxUser{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 5rem;
    }
    
    .headerBoxUser h1:nth-child(2) {
        font-size: 1rem;
        font-weight: 300;
    }
    
    .headerBoxUser h1:first-child {
        font-size: 1rem;
        font-weight: 500;
    }
    
    .headerBoxUser div{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
    
    .headerBoxUser div a:first-child{
        font-weight: 500;
    }
    
    .headerBoxUser a {
        font-size: 1rem;
    }
    
    .formBoxUser{
        padding-left: 4rem;
        padding-right: 4rem;
        padding-bottom: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        /* flex-wrap: wrap; */
    }
    
    .formBoxUser #relative{
        position: relative;
    }
    
    .formBoxUser div #dinamic{
        border: 0;
        border: 1px solid var(--formBorder);
        height: 2.4rem;
        outline: none;
        min-width: 13rem;
        width: calc(100% - 1rem);
        padding-left: 1rem;
        font-size: 1rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
        -webkit-appearance:none;
        border-radius: 0.3rem;
    }
      
    .formBoxUser div #dinamic:focus{
        border: 1px solid #c39e16
    }
      
    .formBoxUser div #dinamic::placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .formBoxUser div #labelDinamic{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
    }
      
    .formBoxUser div #dinamic:required:invalid + #labelDinamic{
        color: red;
    }
    .formBoxUser div #dinamic:focus:required:invalid{
        border-bottom: 2px solid red;
    }
    .formBoxUser div #dinamic:required:invalid + #labelDinamic:before{
        content: '*';
    }
    .formBoxUser div #dinamic:focus + #labelDinamic,
    .formBoxUser div #dinamic:not(:placeholder-shown) + #labelDinamic{
        font-size: 0.7rem;
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        color: #3C3C3C;
        background-color: white;
    }
    
    .usersInformationDiv {
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2.4rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: .9rem;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }
    
    .usersInformationDiv a {
        font-size: 0.9rem;
        margin-left: 1rem;
    }
    
    
    .usersInformationDiv a:first-child {
        font-weight: 600;
    }
    
    .userBoxHideFields {
        display: none;
    }
    
    .userBoxShowFields {
        width: 100%;
    }
    
    
    .formBoxUser #userBoxButtonShow {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
        outline: none;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        font-size: 1rem;
        font-weight: 400;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        background-color: white;
        color: var(--formButtonFontColor)
    }
    
    .formBoxUser #userBoxButtonShow:hover {
        background: var(--buttonHover);
        color: white;
    }
    
    .formBoxUser #userBoxButtonHide {
        display: none;
    }
    
    
}