@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,100;1,300;1,400&display=swap);
.loading{
    display:inline-block;
    position:relative;
    vertical-align:middle;
    width:  2.5rem;
    height: 2.5rem;
    border:0.2rem solid transparent;
    border-top-color:rgb(122, 122, 122);
    border-bottom-color:rgb(122, 122, 122);
    border-radius:50%;
    -webkit-animation: rotate 3s linear infinite, textIn 2s linear infinite;
            animation: rotate 3s linear infinite, textIn 2s linear infinite;
}


.loadingGold {
  display:inline-block;
  position:relative;
  vertical-align:middle;
  width:  2.5rem;
  height: 2.5rem;
  border:0.2rem solid transparent;
  border-top-color: var(--goldColor);
  border-bottom-color:var(--goldColor);
  border-radius:50%;
  -webkit-animation: rotate 3s linear infinite, textIn 2s linear infinite;
          animation: rotate 3s linear infinite, textIn 2s linear infinite;

}
  
  @-webkit-keyframes rotate{
     to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes rotate{
     to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }

  @-webkit-keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .container {
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content:center
  }

  .container span {
      margin-bottom: 0.5rem;
  }

  .text {
    font-size: 1.5rem;
    color:rgb(122, 122, 122)
  }

  .textGold {
    font-size: 1rem;
    font-weight: 200;
    color:rgb(204, 204, 204)
  }
#containerLogon {
    overflow: hidden;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background: var(--loginBoxBG);
    background-image: radial-gradient(circle, #1e2a36, #1e2a34, #1d2830, #1b242a, #1a2126);
}

.loginForgottenPassword a{
    color: white;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 0.2s;
}

.loginForgottenPassword a:hover {
    color: var(--goldColor)
}

.logoContainer {

    -webkit-animation: Slide_right 3s ease;

            animation: Slide_right 3s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    transition: 0.1s;
    
}

.forgetPassUser {
    width: 100%;
    -webkit-animation: Slide_right 3s ease;
            animation: Slide_right 3s ease;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    transition: 0.1s;
 
}

.forgetPassUser #arrowLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.forgetPassUser div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.forgetPassUser div:first-child svg {
    margin-left: 2rem;
    color: var(--goldColor);
    transition: 0.2s;
    cursor: pointer;
}

.forgetPassUser div:first-child svg:hover {
    color: white;
}

.logoContainerForgetPass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.logoContainerForgetPass h2 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-weight: 300;
    font-size: 1.6rem;
}

#forgetPasswordButton{
    margin: 0.7rem;
    width: 120px;
    height: 30px;
    font-size: 15px;
    /* background-color: var(--loginBoxBG); */
    background-image: radial-gradient(circle, #1e2a36, #1e2a34, #1d2830, #1b242a, #1a2126);
    color: white;
    border-radius: 0.3rem;
    border: 1px solid var(--goldColor);
    cursor: pointer;
    transition: 0.3s;
}


#forgetPasswordButton:hover{
    background-color:var(--goldColor);
    color: var(--lo);
}



::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(219, 219, 219);
    opacity: 1; /* Firefox */
    text-align: center;
  }



::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(219, 219, 219);
    opacity: 1; /* Firefox */
    text-align: center;
  }

  

#containerLogon input{
    text-align: center;
    color: white;
    width: 220px;
    height: 30px;
    margin: 0.7rem;
    font-size: 15px;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    border-radius: 0.3rem;
    background-color: #1c283300;
    border: none;
    outline: none;
    border-bottom: 0.1rem solid var(--goldColor);
}

#containerLogon #loginEnterButton{
    margin: 0.7rem;
    width: 90px;
    height: 30px;
    font-size: 15px;
    background-color: var(--loginBoxBG);
    
    color: white;
    border-radius: 0.3rem;
    border: 1px solid var(--goldColor);
    cursor: pointer;
    transition: 0.3s;
}

.loginSeparator {
    z-index: 100;
    height: 15rem;
    width: 1px;
}

.loginSeparator:after{
    content: '';
    height: 100%;
    border-right: 1px solid var(--goldColor);
    display: block;
    width: 0px;
    border-radius: 50%;
}


#containerLogon #loginEnterButton:hover{
    background-color:var(--goldColor);
    color: var(--lo);
}

.inputContainer {
    height: 15rem;
    z-index: 200;
    /* background: var(--loginBoxBG); */
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    -webkit-animation: Slide_left 3s ease;
            animation: Slide_left 3s ease;
}

.inputContainer  form {
    height: 15rem;
    z-index: 200;
    /* background: var(--loginBoxBG); */
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    -webkit-animation: Slide_left 3s ease;
            animation: Slide_left 3s ease;
}

.logoId {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: white;
    bottom: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    justify-content: center;
    position: absolute;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    cursor: pointer;
}

.inLoginEvent {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: all ease 3s;
}

.inProcessLoading {
    display: flex;
    opacity: 0;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.815);
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 15rem;
    transition: 0.1s;
}

.loginLoaderHide {
    display: none;
}


.loginLoaderShow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    -webkit-animation: opacity 1s ease;
            animation: opacity 1s ease;
    margin-right: 6rem;
}

@-webkit-keyframes Slide_right{
    40%{margin-left: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-left: -400px;
       opacity: 0;
       transition: 0.1s}
}

@keyframes Slide_right{
    40%{margin-left: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-left: -400px;
       opacity: 0;
       transition: 0.1s}
}

@-webkit-keyframes Slide_left{
    40%{margin-right: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-right: -400px;
       opacity: 0;
       transition: 0.1s}
}

@keyframes Slide_left{
    40%{margin-right: 0px;
         opacity: 1;
        transition: 0.1s;}
    0%{margin-right: -400px;
       opacity: 0;
       transition: 0.1s}
}
@-webkit-keyframes override{
    100%{margin-right: -700px;
         opacity: 0;
         position: relative;
        transition: 0.1s;}
    0%{margin-left: 0;
       opacity: 1;
       position: relative;
       z-index: 1;
       transition: 0.1s}
}
@keyframes override{
    100%{margin-right: -700px;
         opacity: 0;
         position: relative;
        transition: 0.1s;}
    0%{margin-left: 0;
       opacity: 1;
       position: relative;
       z-index: 1;
       transition: 0.1s}
}

@-webkit-keyframes opacity {

    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}

@keyframes opacity {

    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}



#logoPrimeIMG {
    width: 7rem;
    -webkit-filter: grayscale(100);
            filter: grayscale(100);
    transition: 0.2s;
}

#logoPrimeIMG:hover {
    -webkit-filter: grayscale(0);
            filter: grayscale(0)
}

@media(max-width: 1570px){
    #containerLogon {
        grid-template-columns: 49% 2% 49%;
    }
}

@media(max-width: 1230px){
    #containerLogon {
        grid-template-columns: 45% 5% 45%;
    }
}

@media(max-width: 501px){
    #loginSeparator {
        display: none;
    }
    .loginSeparator {
        display: none;
    }
    #containerLogon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .loginLoaderShow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }
}


/* SIDE BAR */

#checkMenu{
    display: none;
}

#icon{
    cursor: pointer;
    position: absolute;
    z-index: 1;
    color: #fff;
    margin-left: 200px;
    background-color: var(--topBarBg);
    margin-top: 30px;
    border-radius: 50%;
}

.menuIconsOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

#menuNotificationBellArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    transition: 0.2s;
}

#menuNotificationBellArea:hover {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8)
}

#menuNotificationBellArea label {
    width: 1rem;
    height: 1rem;
    background: red;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: white;
    font-weight: 400;
    margin-top: -0.9rem;
    margin-left: -0.6rem;
}

.bar{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    overflow: auto;
    background-color: var(--topBarBg);
    height: calc(100% - 45px);
    width: 250px;
    position: fixed;
    transition: all 0.1s linear;
    left: -200px;
    border-right: 1px solid var(--menuBorderColor);
    display: flex;
    padding: 0;
    justify-content: center;
    top: 45px;
    z-index: 250;

}

.barOverflow {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--topBarBg);
    height: calc(100% - 45px);
    width: 250px;
    position: fixed;
    transition: all 0.1s linear;
    left: -200px;
    border-right: 1px solid var(--menuBorderColor);
    display: flex;
    padding: 0;
    justify-content: center;
    top: 45px;
    z-index: 250;
}

.bar nav{
    width: 100%;
    position: absolute;
    top: 15px;
    color: white;
    
}


#checkMenu:checked ~ .bar{
    /* width: 15%; */
    z-index: 250;
    top: 45px;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
}

#divLogoMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sideBarClose{
    z-index: 1;
    width: 100%;
    float: right;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 70px;
    overflow: hidden;
}

/* LEGENDAS DO MENU FECHADO */
.selectedItem .toolTipList {
    -webkit-transform: translate(36px, -25px);
            transform: translate(36px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    background: var(--topBarBg);
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s ;
}

.selectedItem:hover .toolTipList {
    display: flex;
    max-width: 10rem;
    white-space: nowrap;
    flex-direction: column;
    padding: 0.5rem !important;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    width: auto !important;
    position: absolute;
    /* background: var(--menuSelectedItem) !important;  */
    -webkit-transform: translate(36px, -25px);
            transform: translate(36px, -25px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.2s ease;  
}

.selectedItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList div:hover {
    /* background: var(--menuSelectedItem) !important;  */
}

.selectedItem .toolTip a {
    margin: 0 auto !important;
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.toolTipList {
    -webkit-transform: translate(36px, -25px);
            transform: translate(36px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s ;
}

.menuItem:hover .toolTipList {
    display: flex;
    max-width: 15rem;
    white-space: nowrap;
    flex-direction: column;
    padding: 0.5rem !important;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    width: auto !important;
    position: absolute;
    background: var(--topBarBg) !important; 
    -webkit-transform: translate(36px, -25px); 
            transform: translate(36px, -25px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.4s ease;
}

.menuItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList div:hover {
    background: var(--topBarBg) !important; 
}

.selectedItem div {
    display: flex;
}

.menuItemNotList {
    display: flex;
}

.toolTipNormal {
    -webkit-transform: translate(20px, -10px);
            transform: translate(20px, -10px);
    max-width: 0px !important;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    color: white;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;    
} 

.menuItemNotList:hover .toolTipNormal a {
    margin: 0 !important;
    padding: 0 !important;
    transition: 0.1s;
    font-size: 0.9rem !important;
    font-weight: 400;
}

.menuItemNotList .toolTipNormal a:hover {
    color: rgb(207, 207, 207);
    font-size: 0.9rem;
    margin: 0 !important;
    padding: 0 !important;
}


.menuItemNotList:hover .toolTipNormal{
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
    width: 100%;
    position: absolute;
    padding: 0.5rem !important;
    max-width: 10rem !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    width: auto !important;
    font-size: 0.9rem;
    opacity: 1;
    background: var(--topBarBg) !important;      
    -webkit-transform: translate(32px, -10px);      
            transform: translate(32px, -10px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    transition: all 0.4s ease;
}

.linkMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#sideBarClose #firstMenuDiv{
    width: 10%;
    height: 25px;
    padding: 0.5rem;
    padding-right: 0.7rem;
    padding-left: 0.9rem;
    transition: .2s;
    cursor: pointer;
    margin: 0;
}

#sideBarClose a{
    margin-right: 0.88rem;
}

#sideBarClose a div{    
    width: 100%;
    display: flex;
    padding-right: 0.6rem;
    padding-left: 0.3rem;
    align-items: center;
}

#sideBarClose div:hover{
    background-color: var(--menuBorderColor);
}

.setNone{
    display: none;
}

.setFlex{

    display: flex;
}

.setShow {
    padding: 0;
}

.chevronIcon{
    margin-left: 0.2rem;
}

/* SIDE BAR ACCORDION AND SIMPLE ITEMS BAR*/

.accordions{
    transition: .2s;
    width: 100%;
    position: relative;
    padding: 0;
    overflow-x: hidden;
}

.accordion-item{
    position: relative;
    width: 100%;
}

.accordion-item input{
	display:none;
    transition: .2s;
}

.accordion-item label{
    position: relative;
    display: flex;
    cursor:pointer;
    width: 100%;
    transition: .2s;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    align-items: center;
}

.accordion-item label p{
    margin-left: 0.5rem;
    font-size: 0.9rem;
}

.accordion-item label div{
    margin-left: 0.9rem;
}


.accordion-item label:hover{
    background-color: var(--menuBorderColor);
    transition: .2s;
}

.accordion-content{
    transition: .3s;
    font-size: 0.9rem;
    height: 0px;
    overflow: hidden;
    background-color: var(--menuSelectedOpen);
    -webkit-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    -ms-transition: height 0.3s ease-in-out;
    cursor: pointer;
 }

.accordion-content a{
    text-decoration: none;
    color: white;
    width: 100%;
}

.accordion-content:hover{
    background-color: var(--menuBorderColor);
}
 
[id^=option]:checked ~ .accordion-content {
    transition: .2s;
    height: auto;
    padding: 0.5rem 0.2rem 0.5rem 2.7rem;
}

.simple-item{
    width: 100%;
    text-decoration: none;
    font-size: 1rem;
}

.simple-item a{
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.simple-item div{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    padding-left: 0.8rem;
    transition: 0.2s;
}

.simple-item div p{
    padding-left: 0.6rem;
    font-size: 0.9rem;
}

.simple-item:hover{
    background-color: var(--menuBorderColor);
}


/* TOP BAR */

.topBar{
    z-index: 210;
    background-color: var(--topBarBg);
    border-bottom: 1px solid var(--menuBorderColor);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
}

.topBar img{
    color: white;
    font-style: normal;
    margin: 0;
    float: left;
    justify-content: center;
    margin-left: 0.4rem;
}

.topBar img:hover {
    -webkit-filter: brightness(0.90);
            filter: brightness(0.90);
    cursor: pointer;
}

.topBar h2{
    color: white;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.userIcon{
    margin-right: 10px;
    cursor: pointer;
}

.backgroundSelected{
    background-color: var(--menuBackgroundSelected) !important;
}

.selectedItem{
    background-color: var(--menuSelectedItem);
    color: var(--formBorder) !important;
    
}

.formatLabel {
    font-size: 0.9rem;
}


@media(max-width:1368px){
    /* LEGENDAS DO MENU FECHADO */
    .selectedItem .toolTipList {
        -webkit-transform: translate(35px, -25px);
                transform: translate(35px, -25px);
        max-width: 0px;
        z-index: 0;
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        opacity: 0;
        position: absolute;
        font-size: 0.9rem;
        display: flex !important;
        justify-content: space-between !important;
        transition: all ease 0.2s;
        transition-delay: 0.15s ;
    }
    
    .selectedItem:hover .toolTipList {
        display: flex;
        max-width: 10rem;
        white-space: nowrap;
        flex-direction: column;
        padding: 0.5rem !important;
        grid-gap: 0.8rem;
        gap: 0.8rem;
        width: auto !important;
        position: absolute;
        background: var(--topBarBg) !important; 
        -webkit-transform: translate(35px, -25px); 
                transform: translate(35px, -25px); 
        box-shadow: 5px 8px 15px -7px #000000;
        color: white;
        opacity: 1;
        border-radius: 0 5px 5px 0;
        font-size: 0.9rem;
        font-weight: 400;
        transition: all 0.2s ease;  
    }
.selectedItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList div:hover {
    background: var(--topBarBg) !important; 
}

.selectedItem .toolTip a {
    margin: 0 auto !important;
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.toolTipList {
    -webkit-transform: translate(35px, -25px);
            transform: translate(35px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s ;
}

.menuItem:hover .toolTipList {
    display: flex;
    max-width: 10rem;
    white-space: nowrap;
    flex-direction: column;
    padding: 0.5rem !important;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    width: auto !important;
    position: absolute;
    background: var(--topBarBg) !important; 
    -webkit-transform: translate(35px, -25px); 
            transform: translate(35px, -25px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.4s ease;
}


.menuItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList div:hover {
    background: var(--topBarBg) !important; 
}

.selectedItem div {
    display: flex;
}

.menuItemNotList {
    display: flex;
}


.toolTipNormal {
    -webkit-transform: translate(20px, -10px);
            transform: translate(20px, -10px);
    max-width: 0px !important;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    color: white;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;    
} 

.menuItemNotList:hover .toolTipNormal a {
    margin: 0 !important;
    padding: 0 !important;
    transition: 0.1s;
    font-size: 0.8rem !important;
    font-weight: 400;
}

.menuItemNotList .toolTipNormal a:hover {
    color: rgb(207, 207, 207);
    font-size: 0.9rem;
    margin: 0 !important;
    padding: 0 !important;
    
}


.menuItemNotList:hover .toolTipNormal{
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
    width: 100%;
    position: absolute;
    padding: 0.5rem !important;
    max-width: 10rem !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    width: auto !important;
    font-size: 0.9rem;
    opacity: 1;
    background: var(--topBarBg) !important;      
    -webkit-transform: translate(32px, -10px);      
            transform: translate(32px, -10px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    transition: all 0.4s ease;
}

/*MENU FECHADO PARA TELA PEQUENA*/
}

.multMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background: rgb(32, 32, 32); */
    font-size: 1.5rem;
    color: white;
}

.multMenu svg {
    margin-right: 0.3rem;
}

.optionMultMenuItem {

    -webkit-transform: translate(35px, -25px);

            transform: translate(35px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s 
}

.multMenu:hover .optionMultMenuItem {
    display: flex;
    max-width: 160rem;
    min-width: 168px;
    white-space: nowrap;
    overflow: initial;
    flex-direction: column;
    padding: 0.5rem !important;
    background: var(--topBarBg);
    grid-gap: 0.8rem;
    gap: 0.8rem;
    -webkit-transform: translate(122px, 0px);
            transform: translate(122px, 0px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.2s ease;  
}

.optionMultMenuItem a {
    text-decoration: none;
    color: white;
}

.mulMenuFatherItemsToolTip {
    -webkit-transform: translate(35px, 0px);
            transform: translate(35px, 0px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s 
}

.multMenuFatherItems:hover .mulMenuFatherItemsToolTip  {
    display: flex;
    max-width: 800px;
    white-space: nowrap;
    flex-direction: row;
    padding: 0.5rem !important;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: absolute;
    background: var(--topBarBg) !important; 
    -webkit-transform: translate(185px, -25px); 
            transform: translate(185px, -25px); 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.2s ease;  
}

.containerItemMenu{
    display: grid;
    grid-template-columns: auto auto;
}

.subNotch{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: red;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 0.4rem;
}

.notch{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: red;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
}

.notchClosedMenu{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: red;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    color: white;
    -webkit-transform: translate(-0.8rem, -0.5rem);
            transform: translate(-0.8rem, -0.5rem);
}
.notchClosedMenu:hover{
    background-color: red !important;
}
.actionMin {

    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.actionMin a {
    font-size: 1rem;
}

.actionMin a:nth-child(2) {
    color: var(--goldColor);
    font-size: 1.2rem;
    cursor: default;
}



.actionMin a:nth-child(3):hover {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85)
}

.actionMin a:nth-child(4):hover {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85)
}

.actionMinHide {

    display: none;
}

.userActions {
    
  top: 45px;
  position: fixed;
  right: 0;
  background: #262626;
  color: white;
  width: 13rem;
  padding: 1rem;;
  transition: 0.2s;
  display: flex;
  /* right: 0; */
  flex-direction:column;
  justify-content: center;
  align-items: center;
  border-radius:  0 0 0 0.5rem;
  /* gap: 0.5rem; */
  -webkit-user-select: none;      
  user-select: none; 
  box-shadow: -3px 4px 15px -5px #000000;
  
}

.userActions a {
    font-size: 1rem; 
    cursor: pointer; 
    margin-bottom: 0.5rem;
}

.userActions img {
    border: 0.2rem solid rgba(175, 175, 175, 0.801);
    margin-bottom: 1rem !important;
    width: 100px;
    border-radius: 50%;
}

.userActions .LoaderMyProfil {
    border: 0.2rem solid rgba(175, 175, 175, 0.801);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    padding-top: 0;
    justify-content: center;
}

.userActionsWide {
  overflow: auto;
  top: 45px;
  position: absolute;
  background: #262626;
  right: 0;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  align-items: center;
  height: 100vh;
  z-index: 400;
  
}

.userChangePassSuccess {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userChangePassSuccess a {
    font-size: 1.4rem;
}

.userActionsWide a {
    font-size: 1.4rem; 
}

.userChangePassSuccess button {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    width: 6.6rem;
    height: 1.8rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    margin-top: 2rem;
    color: var(--formButtonFontColor);
}

.userChangePassSuccess button:hover {
    background-color: var(--formButtonFontColor);
    color: rgba(47, 47, 47, 1);
}

.usersActionsWideLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.userActionsWide img {
    border: 0.2rem solid rgba(175, 175, 175, 0.801);
    margin-bottom: 1rem !important;
    width: 100px;
    margin-left: 0 !important;
    border-radius: 50%;
}

.usersActionWideShowOptions {
    padding-left: 40px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    height: 100%;
    width: 100%;
}


.usersActionWideShowOptions div:nth-child(2) form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0;
    
}

.profileUserAvatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    width: 100%;
}
   

.profileUserAvatar div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 8rem;
} 

.profileUserAvatar div span:first-child{
    display: flex;
    flex-direction: row;
    grid-gap:0.5rem;
    gap:0.5rem;
}

.profileUserAvatar div span:first-child a {
    font-size: 1rem !important;
}

.profileUserAvatar div span:nth-child(2) a{
    cursor: pointer;
    transition: 0.2s;
}
.profileUserAvatar div span:nth-child(2) a:hover{
    -webkit-filter: brightness(0.70);
            filter: brightness(0.70)
}
.profileUserAvatar div #spanEdit{
    display: flex;
    width: 100%;
    flex-direction: column; 
    height: 3rem;
}


.profileUserAvatar div span {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profileUserAvatar:nth-child(1) a:nth-child(1) {
    font-size: 1.4rem;
    cursor: default;
}

.profileUserAvatar:nth-child(1) a:nth-child(2) {
    color: var(--goldColor);
    transition: 0.2s;
}

.profileUserAvatar:nth-child(1) a:nth-child(2):hover {
    -webkit-filter:  brightness(0.8);
            filter:  brightness(0.8);
}

.formDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-gap: 2rem;
    gap: 2rem;
    margin-top: 1rem;
}
#fileUserInput {
    display: none;
}

.bodyDataUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
}

.formLeftDiv {
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.formLeftDiv #leftLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50% !important;
    height: 100%;
}

.profileSeparator{
    height: 100%;
    border: 1px solid var(--goldColor);
    border-radius: 50%;
    height: 15rem;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}


.profileSeparator:after{
    content: '';
    height: 100%;
    border-right: 1px solid var(--goldColor);
    display: block;
    width: 0px;
    border-radius: 50%;
}


.formRightDiv {
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;

}

.formRightDiv #rightLabel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50% !important;
    height: 100%;
}

.profileAlterProperty {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1.2rem;
    align-items: center;
    width: 100%;

}

.profileAlterProperty label {
    width: 100%;
    
}

#passwordLabel {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.profileAlterProperty a:first-child {
    color: var(--goldColor);
    font-size: 1rem;
}

.profileAlterProperty a {
    font-size: 1rem;
}

.userActionsHide{
    max-width: 0;
    max-height: 0;
    height: 0;
    width: 0rem;
    position: absolute;
    opacity: 0;
    z-index: 0;
}

.userActionsHide img {
    display: none;
}

.profileHideLabel {
    display: none;
}

.userAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 110px;
    height: 110px;
}

.inEditAvatar {
    display: flex;
    flex-direction: column;
    background: white;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 0;
    overflow: hidden;

}

.ImgCropper {

    position: relative;
}

#myProfileButtonHide {
   display: none;

}

#myProfileSubmit {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    width: 6.6rem;
    height: 1.8rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    margin-top: 2rem;
    color: var(--formButtonFontColor);
}

#myProfileSubmit:hover {
    background-color: var(--formButtonFontColor);
    color: rgba(47, 47, 47, 1);
}

.formDiv button:hover {
    background-color: var(--formButtonFontColor);
    color: rgba(47, 47, 47, 1);
}

#profileCloseButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #262626;
    color: rgb(233, 233, 233);
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--goldColor);
    font-weight: bold;
    position: absolute;
    right: 1rem;
    top: 1rem;
}

#profileCloseButton:hover {
    background-color: #4d4d4d;
    color: var(--goldColor);
}


@media(max-width: 1366px) {
    .actionMin {

        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
    
    .actionMin a {
        font-size: 1rem;
    }
    
    .actionMin a:nth-child(2) {
        color: var(--goldColor);
        font-size: 1.2rem;
        cursor: default;
    }
    
    
    
    .actionMin a:nth-child(3):hover {
        -webkit-filter: brightness(0.85);
                filter: brightness(0.85)
    }
    
    .actionMin a:nth-child(4):hover {
        -webkit-filter: brightness(0.85);
                filter: brightness(0.85)
    }
    
    .actionMinHide {
    
        display: none;
    }
    
    .userActions {
        
      top: 45px;
      position: fixed;
      right: 0;
      background: #262626;
      color: white;
      width: 13rem;
      padding: 1rem;;
      transition: 0.2s;
      display: flex;
      /* right: 0; */
      flex-direction:column;
      justify-content: center;
      align-items: center;
      border-radius:  0 0 0 0.5rem;
      /* gap: 0.5rem; */
      -webkit-user-select: none;      
      user-select: none; 
      box-shadow: -3px 4px 15px -5px #000000;
      
    }
    
    .userActions a {
        font-size: 1rem; 
        cursor: pointer; 
        margin-bottom: 0.5rem;
    }
    
    .userActions img {
        border: 0.2rem solid rgba(175, 175, 175, 0.801);
        margin-bottom: 1rem !important;
        width: 100px;
        border-radius: 50%;
    }
    
    .userActions .LoaderMyProfil {
        border: 0.2rem solid rgba(175, 175, 175, 0.801);
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-bottom: 1rem !important;
        display: flex;
        align-items: center;
        padding-top: 0;
        justify-content: center;
    }

    .userActionsWide {
      overflow: auto;
      top: 45px;
      position: absolute;
      background: #262626;
      right: 0;
      color: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      transition: 0.2s;
      align-items: center;
      height: 100vh;
      z-index: 400;
      
    }
    
    .userActionsWide a {
        font-size: 1rem; 
    }
    
    
    .userActionsWide img {
        border: 0.2rem solid rgba(175, 175, 175, 0.801);
        margin-bottom: 1rem !important;
        width: 100px;
        margin-left: 0 !important;
        border-radius: 50%;
    }
    
    .usersActionWideShowOptions {
        padding-left: 40px;
        display: grid;
        grid-template-rows: 1fr 2fr;
        height: 100%;
        width: 100%;
    }
    
    
    .usersActionWideShowOptions div:nth-child(2) form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 0;
    }
    
    .profileUserAvatar {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        justify-content: center;
        width: 100%;
    }
       
    
    .profileUserAvatar div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: 8rem;
    } 
    
    .profileUserAvatar div span:first-child{
        display: flex;
        flex-direction: row;
        grid-gap:0.5rem;
        gap:0.5rem;
    }
    
    .profileUserAvatar div span:first-child a {
        font-size: 1rem !important;
    }

    .profileUserAvatar div span:nth-child(2) a{
        cursor: pointer;
        transition: 0.2s;
    }
    .profileUserAvatar div span:nth-child(2) a:hover{
        -webkit-filter: brightness(0.70);
                filter: brightness(0.70)
    }
    .profileUserAvatar div #spanEdit{
        display: flex;
        width: 100%;
        flex-direction: column; 
        height: 3rem;
    }
    
    
    .profileUserAvatar div span {
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .profileUserAvatar:nth-child(1) a:nth-child(1) {
        font-size: 1.4rem;
        cursor: default;
    }
    
    .profileUserAvatar:nth-child(1) a:nth-child(2) {
        color: var(--goldColor);
        transition: 0.2s;
    }
    
    .profileUserAvatar:nth-child(1) a:nth-child(2):hover {
        -webkit-filter:  brightness(0.8);
                filter:  brightness(0.8);
    }
    
    .formDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        grid-gap: 2rem;
        gap: 2rem;
        margin-top: 1rem;
    }
    #fileUserInput {
        display: none;
    }
    
    .bodyDataUser {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        margin-top: 2rem;
    }
    
    .formLeftDiv {
        border-radius: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    
    .formLeftDiv #leftLabel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50% !important;
        height: 100%;
    }
    
    .profileSeparator{
        height: 100%;
        border: 1px solid var(--goldColor);
        border-radius: 50%;
        height: 15rem;
        -webkit-filter: brightness(0.8);
                filter: brightness(0.8);
    }
    
    
    .profileSeparator:after{
        content: '';
        height: 100%;
        border-right: 1px solid var(--goldColor);
        display: block;
        width: 0px;
        border-radius: 50%;
    }
    
    
    .formRightDiv {
        border-radius: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
    
    }
    
    .formRightDiv #rightLabel{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50% !important;
        height: 100%;
    }
    
    .profileAlterProperty {
        display: flex;
        flex-direction: row;
        grid-gap: 1rem;
        gap: 1rem;
        margin-bottom: 1.2rem;
        align-items: center;
        width: 100%;
    
    }
    
    .profileAlterProperty label {
        width: 100%;
        
    }
    
    #passwordLabel {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
    }
    
    .profileAlterProperty a:first-child {
        color: var(--goldColor);
        font-size: 1rem;
    }
    
    .profileAlterProperty a {
        font-size: 1rem;
    }
    
    .userActionsHide{
        max-width: 0;
        max-height: 0;
        height: 0;
        width: 0rem;
        position: absolute;
        opacity: 0;
        z-index: 0;
    }
    
    .userActionsHide img {
        display: none;
    }
    
    .profileHideLabel {
        display: none;
    }

    .userAvatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 110px;
        height: 110px;
    }

    .inEditAvatar {
        display: flex;
        flex-direction: column;
        background: white;
        width: 100vw;
        height: 100vh;
        position: relative;
        z-index: 0;
        overflow: hidden;

    }

    .ImgCropper {

     
        position: relative;
    }
    
    #myProfileButtonHide {
       display: none;
    
    }
    
    #myProfileSubmit {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        width: 6.6rem;
        height: 1.8rem;
        border-radius: 0.6rem;
        transition: 0.1s;
        margin-top: 2rem;
        color: var(--formButtonFontColor);
    }

    #myProfileSubmit:hover {
        background-color: var(--formButtonFontColor);
        color: rgba(47, 47, 47, 1);
    }
    
    .formDiv button:hover {
        background-color: var(--formButtonFontColor);
        color: rgba(47, 47, 47, 1);
    }
    
}

@media (max-width: 480px){

    .usersActionWideShowOptions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profileUserAvatar div:first-child {
        display: flex;
        flex-direction: column;
    }

    .formDiv {
        margin-top: 7rem;
    }

    .formDiv form:first-child {
        margin-left: 5rem;
    }
    
    .formLeftDiv {
        width: 100%;
    }

    .formRightDiv {
        width: 100%;
    }

    .formLeftDiv label:first-child {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .formRightDiv label:first-child {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .formLeftDiv #leftLabel {
        width: 100% !important;
    }

    .profileSeparator {
        display: none;
    }

    .bodyDataUser {
        display: flex;
        flex-direction: column;
    }
}
.notificationCenterMainContainer {
    width: 24rem;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background:rgba(15, 15, 15, 0.671);
    position: absolute;
    top: 46px;
    right: 0;
    display: flex;
    flex-direction: column;
    color: white;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
}

#notificationHeader{
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    font-weight: 500;
    font-size: 1rem;
    height: 2.3rem;
    min-height: 2.4rem;
    margin-bottom: 0.7rem;
    border-bottom: 1px solid rgba(117, 117, 117, 0.342);
}

.notificationAlerts {
    overflow-x:hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.notificationAlerts #notificationDiv {
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: center;
    width: 22rem;
    border-radius: 5px;
    padding: 0.3rem;
    background:rgba(197, 197, 197, 0.075);
    transition: 0.2s;
}

.notificationAlerts #notificationDiv:hover {
    background: rgba(197, 197, 197, 0.246);
}

.notificationAlerts div svg {
    margin-left: 1rem;
}

#textNotification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 3rem;
    font-size: 0.8rem;
    cursor: pointer;
    text-align: center;
}

.notificationAlerts label {
     
    display: inline-block;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    transition: 0.2s;
    font-size: 1rem;
    font-weight: 500;
    
}
.notificationAlerts:last-child{
    margin-bottom: 5rem;
}

.notificationTitleText{
    width: 100%;
    white-space: normal !important;
    overflow-wrap: break-word;
}
.HelpCenterContainer {

    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 49px;
    left: 49px;
    right: 0;
    bottom: 0;
    background: var(--topBarBg);
    width: calc(100vw -49px);
    height: 100vh;
    transition: 0.2s;
    overflow: hidden;
}

.HelpCenterContainer h2 {
    margin-right: 49px;
}

.helpCenterHide {
    opacity: 0;
    display: hidden;
    max-width: 0;
    width: 0;
    max-height: 0;
    height: 0;
    position: absolute;
    transition: 0.2s;
}

.helpCenterContent {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background: white;
}
.helpSelectedScreen {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.helpSelectedScreenLeft {
    background: rgb(255, 255, 255);
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.helpSelectedScreenLeft label {
    width: 100%;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
}

.helpSelectedScreenLeft label svg {
    margin-right: 1rem;
}

.helpSelectedScreenLeft label:hover {
    background: gray;
}

.helpSelectedScreenLeft a {
    cursor: pointer;
    margin-left: 2rem;
}


.helpSelectedScreenRight {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.helpSelectedScreenRightContent {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 90%;
    padding: 2rem;
    overflow-x: hidden;
    background: white;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.helpSelectedScreenRightContent  h1 {
    font-size: 1.8rem;
    font-weight: 300;
}

.helpSelectedScreenRightContent span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
}

#imgHelp {
    width: 1400px;
}
.modal_modalHidden__3BtsI{
    position: absolute;
    z-index: 0;
    height: 0;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    left: 0;
    top: 0;
    display: hidden;
}

.modal_modalShow__13bvb{
    
    overflow: auto;
    display: flex;
    margin: 0 auto;
    position: absolute;
    z-index: 240;
    width: calc(100% - 49px);
    height: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.modal_modalContent__10T8x {
 
    z-index: 240;
    border-radius: 0.5rem;
    /* -webkit-box-shadow: 0px 0px 0.5px 0px var(--goldColor); 
    box-shadow: 0px 0px 0.5rem 1px var(--goldColor); */
}

.modal_elementsOfModal__2bZv9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal_modalBackgroundShow__8V-Qn{
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    background: rgb(0,0,0,0.5);
    position: absolute;
    z-index: 151;
}

@media (max-width: 1368px){

    .modal_modalShow__13bvb{
        min-width: 1150px;
        position: absolute;
        display: flex;
        margin: 0 auto;
        position: absolute;
        z-index: 240;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
        overflow: auto;
    }

    .modal_modalContent__10T8x {
        max-height: 50rem;
      
    }

    .modal_modalBackgroundShow__8V-Qn{
        min-width: 1150px;
        width: calc(100% - 48px);
        height: calc(100% - 48px);
    }
}


.modal_moldaBackgroundHide__2XlPm{
    display: none !important;
}

.modal_displayAlignModal__1dpn7{
    min-width: 1150px;
    display: flex;
    justify-content: center;
}
.modal_displayAlignModalNone__16StR{
    display: hidden;
}

.modal_modalContentNone__2pCl_{
    display: none;
}

@media (max-height: 575px){

    .modal_modalShow__13bvb{
        min-width: 1150px;
        position: absolute;
        display: flex;
        margin: 0 auto;
        position: absolute;
        z-index: 240;
        display: flex;
        align-items: center;
        justify-content: left;
        transition: .2s;
        overflow: auto;
    }
}
.confirmationBoxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    width: 100vh;
    grid-gap: 4rem;
    gap: 4rem;
    background: white;
}

.confirmationBoxMsgContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
    padding: 1rem;
}
.confirmationBoxContainer .confirmationBoxMsgContainer #info {
    font-size: 1.5rem !important;
    font-weight: 400;
    border-bottom: 1px solid var(--goldColor);
}

.confirmationBoxContainer h2{
    font-size: 1.5rem;
    margin: 0 !important;
}


.confirmationBoxContainer p{
    font-size: 1.2rem;
}

.confirmationBoxButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    grid-gap: 2rem;
    gap: 2rem;

}

.confirmationBoxButtons button {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    padding: 0.5rem;
    width: 6.8rem;
    height: 2.4rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.confirmationBoxButtons button:hover {
    background: var(--buttonHover);
}

.confirmationBoxButtonHide {
    display: none;
}

@media(max-width: 1368px){

    .confirmationBoxContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40vh;
        width: 40vw;
        grid-gap: 2rem;
        gap: 2rem;
        background: white;
    }
    
    .confirmationBoxMsgContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 2rem;
        gap: 2rem;
        justify-content: center;
        padding: 1rem;
    }
    .confirmationBoxContainer .confirmationBoxMsgContainer #info {
        font-size: 1.2rem !important;
        font-weight: 300;
    }
    
    .confirmationBoxContainer h2{
        font-size: 1.5rem;
    }
    
    
    .confirmationBoxContainer p{
        font-size: 1.2rem;
    }
    
    .confirmationBoxButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        grid-gap: 2rem;
        gap: 2rem;
    
    }
    
    .confirmationBoxButtons button {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        padding: 0.5rem;
        width: 6.8rem;
        height: 2.4rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    .confirmationBoxButtonHide {
        display: none;
    }
    
    .confirmationBoxContainer p{
        font-size: 1rem !important;
    }
}
.dashboardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 900px;
    overflow-x: auto;
    background-color: white;
}

.retracaoCarrouselAbc{
    transition: all 0.2s;
}

.retracaoCarrouselAbc:hover{
    cursor: pointer;
    color: #c29c00;
}

.tileLoaderSm {
    width: 15rem;
}

.tileLoaderFullW {
    width: 100%;
}

.tileLoaderLg {
    width: 35rem;
}

#dashBoardLoader {
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboardContainer ::-webkit-scrollbar {
    width: 0.8rem;
}

.dashboardContainer ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(197, 197, 197);
    background: rgb(238, 238, 238);
}

.dashboardContainer ::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
    border-radius: 10px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.dashboardContainer ::-webkit-scrollbar-thumb:hover {
    background: rgb(192, 192, 192);
}

#dashboardIndicationButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background: rgba(37, 64, 109, 1);
    color: white;
    transition: 0.2s;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 1.8rem;
}

#dashboardIndicationButton svg {
    margin-right: 1.5rem;
    color: white;
}

#dashboardIndicationButton:hover {
    background: rgb(238, 238, 238);
    color: black;
}

#dashboardIndicationButton a {
    font-size: 1rem;
    font-weight: 400;
}

.dashboardLeftSide {
    border: 1px solid rgba(0, 0, 0, 0.178);
    height: calc(100vh - 49px);
    width: 25rem;
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
}

.dashboardUpSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    width: 100%;
}

.dashboardUpSide label {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dashboardUpSide label a {
    margin-left: 1.5rem;
}

#dashboardMyclientsText {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.dashboardCustomButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background: white;
    transition: 0.2s;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 1.8rem;
}

.dashboardCustomButton svg {
    margin-right: 1.5rem;
    color: rgba(37, 64, 109, 1);
}

.dashboardCustomButton:hover {
    background: rgba(37, 63, 109, 0.123);
}

.dashboardCustomButton a {
    font-size: 0.9rem !important;
}

.dashboardUpSide label {
    border-bottom: 1px solid rgb(214, 214, 214);
}

.dashboardRightSide {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: hidden; */
}

.expandleLsIcon2 {
    /* padding-top: 2rem; */
}

.dashboardRightSideContent {
    width: 98%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

#dashboardWelcomeMsg {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboardRightSideContentAdvisor {
    width: 98%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    /* gap:0.5rem; */
    padding-bottom: 0.5rem;
}

.headerProductCellBoxAdvisor {
    background-color: #fffcef;
    display: grid;
    grid-template-columns: 1fr auto;
    margin-right: 0.5rem;
    /* margin-left: 0.5rem; */
    /* border-left: 1px solid black !important; */
    /* padding: 0.2rem; */
}

.headerProductCellBoAdvisor:hover {
    cursor: pointer;
}

#dashboardWelcomeMsgAdvisor {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.dashboardContainer h2 {
    font-size: 1.5rem;
    font-weight: 300;
}

.dashboardContainer a {
    font-size: 1rem;
}

.dashboardBoxContainer {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
    min-height: 6rem;
}
.dashboardBoxContainerBaterBase {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    flex-direction: column;
    align-items: center;
    width: 80vw;
    overflow-x: hidden;
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
    min-height: 6rem;
}

.wisirContainer {
    display: grid;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* max-height: 100%; */
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
}

.campaignsContainer {
    display: grid;
    flex-direction: column;
    align-items: center;
    /*     height: 330px; */

    width: 100%;
    /* padding: 10px 0px; */
    grid-template-columns: 4fr 1fr;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
}

.carouselBox {
    /* background-color: #47e26e; */
    height: 20rem;
}

.carousel-container-dash {
    /* margin-top: 0.5rem;
    margin-bottom: 0.5rem; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: grid;
    grid-template-columns: 2rem calc(100% - 4rem) 2rem;
}

.carousel-menu-dash {
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
}

#menu-items-dash {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    transition: -webkit-transform 0.3s ease-in-out;

    transition: transform 0.3s ease-in-out;

    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.itemCarrouselDash {
    /* background-color: #e9e8e8; */
    border-radius: 10px;
    margin-right: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    font-size: 1.2rem;
    height: 14.5rem;
    width: 20rem;
    opacity: 100%;
}

.boxInfosItemCarrousel {
    padding: inherit;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows:0.7rem 3rem 2.8rem 6rem 2rem;
}

.boxInfosItemCarrouselHeader {
    padding-top: 0.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    max-width: 20rem;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: grid;
    align-items: center;
    grid-template-columns: 2rem auto 2rem;
}

.boxInfosItemCarrouselSubheader {
    margin-top: 0.2rem;
    font-size: 1rem;
    font-weight: 400;
    max-width: 20rem;
    max-height: 2.4rem;
    overflow-y: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
}

.boxInfosItemCarrouselBody {
    /* margin-top: 0.5rem; */
    /* text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem; */
    font-size: 1rem;
    overflow: auto;
    overflow-y: hidden  ;
    position: relative;
    top: -15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.containerCardsData {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: space-between;
    align-items: center;
    grid-gap: .3rem;
    gap: .3rem;
    border-radius: 10px;
    /*     background-color: #525252;
 */
    font-weight: 600;
    padding: 10px;
}


.boxInfosItemCarrouselMaterial {
    border-radius: 10px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    color: white;

    font-weight: bold;
}

.boxInfosItemCarrouselMaterial a {
    color: inherit;
    font-size: 1.2rem;
}

/* .itemCarrouselDash:hover{
    background: #e9e9e9;
  } */

.columnExpandableLsDash {
    text-align: center;
    display: grid !important;
    grid-template-columns: auto 1rem;
    height: 5.7rem;
    white-space: nowrap;
}

.columnExpandableLsDash2 {
    text-align: center;
    /* display: grid !important; */
    grid-template-columns: auto 1rem;
    height: 5.7rem;
    margin-top: -1rem;
    white-space: nowrap;
}

.containerCellProductDash {
    display: grid;
    background-color: #fffcef;
    margin-right: 0.5rem;
    /* align-items: center; */
}

.containerCellNameProductDash {
    display: flex;
    justify-content: center;
    align-items: center;

    /* display: grid; */
    /* background-color: #fffcef; */
    margin-right: 0.5rem;
    /* position: absolute;
    width: 200%; */
}

.subtitleCellBoxDash {
    display: grid;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    grid-template-rows: 1rem 1rem;
    padding-bottom: 0.4rem;
}

.subtitleCellBoxDashSum {
    display: table;
    text-align: center;
    color: green;
}

.statusColorFooterDivAbcDash {
    width: 14px;
    height: 14px;
    margin: auto;
    border-radius: 5px;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    border: 2px solid #ffffff;
}


.productNameDash {
    /* position: absolute;
    width: 15rem; */
    font-size: 1rem;

}

.subtitleObjectiveAbcDash {
    display: table;
    text-align: center;
}

.statusDivDash {
    background-color: #EFEDED;
    color: #595858;
    border-radius: 4px;
    vertical-align: middle;
    display: grid;
    grid-template-columns: auto auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    /* margin-left: 0.2rem; */
    /* margin-right: 0.4rem; */
    transition: all ease 0.1s;
    margin-left: auto;
    margin-right: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.statusDivDash:hover {
    background-color: #141414;
    color: #fffdf9;
    cursor: pointer;
}
.statusDivDashCopy:hover {
    color: #fffdf9;
    cursor: pointer;
}

.detailscampangeDash {
    background:'#D5AF01';
    border-radius:'10px';
    padding:'6px'; 
    transition:'0.2s';
}
.detailscampangeDash:hover{
    background-color: #C29C00;
}

#menu-items-dash li {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    /* padding: 10px; */
}

.carousel-button-dash {
    border: none;
    color: #5f5f5f;
    background-color: transparent;
}

.carousel-button-dash:hover {
    color: #c49e17;
}

.buttond0{
    color: white;
    background-color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}
.buttond1{
    background-color: #C8AA20;    
    color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
    color: white;


}

.buttond2{
    color: white;
    background-color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}

.buttond3{
    background: #C8AA20;
    color: white;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}

.buttond3Plus{
    color: white;
    background-color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}

.actionsDash {
    position: relative;
    content: "";
    height: 14rem;
    /* border-left: 1px solid rgb(170, 170, 170); */
    align-items: center;
    display: grid;
    top: -10px;
    grid-template-rows: 1fr 1fr 1fr ;
    transition: 0.3s;
    /* flex-direction: column; */
    /* flex-direction: row; */

}

.actionsDash::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "";
    position: absolute;
    left: 0;
    -webkit-transform: translateY(4%);
            transform: translateY(4%);
    height: 92%;
    border-left: 1px solid rgb(167, 167, 167);
    /* Altere a cor e espessura da borda conforme necessário */
}

.dashButton {
    font-size: 1.2rem;
    font-weight: 400;
    width: 90%;
    /* height: 135px; */
    max-width: 165.75px;
    padding: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    /* margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.8rem; */
    margin: 0.25rem 1rem;

    color: #c49e17;
    border: 2px solid white;
    outline: 1px solid #bababa;
    border-radius: 10px;
    background-color: white;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;

    border-radius: 1.75rem;
    box-shadow: #bababa 0px 0px 5px 1px;


    display: grid;
    grid-template-columns: 1fr 4fr;
    max-width: none;
    /* height: 50px; */
    border-radius: 10px;
    /*  padding: 0.5rem 0rem;
 */
    justify-content: center;
    justify-items: start;
    align-items: center;
}

.dashButton:hover {
    color: #fff;
    /* border: 2px solid #c49e17; */
    background-color: #c49e17;
    order: 5px solid white;
    outline: 1px solid #bababa;
    /* box-shadow: 0px 0px 10px #c49e17; */
}

#dashboardWisir {
    flex: 0rem 1;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    padding: 5px;
    align-items: center;
    width: 90%;
    height: 96%;
    align-items: center;
    overflow-x: hidden;
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
}

/* .dashboardWisirTable{
    border: none;
    box-shadow: 3px 4px 10px -10px #000000;
} */
.dashWisirItensBox {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0%;
    width: 100%;
    align-content: space-evenly;
    padding-left: 1rem;
    padding-right: 1rem;
}

.dashWisirItens {
    display: flex;
    flex-wrap: wrap;
    padding: 0%;
    width: 100%;
    justify-content: space-between;
}

.dashWisirItensBox2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0%;
    width: 100%;
    justify-content: space-between;
    min-height: 6rem;
}

#dashboardWisir h2 {
    font-size: 1rem;
    color: rgb(109, 109, 109);
    font-weight: 500;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
}

#dashboardWisir h4 {
    font-size: 1.1rem;
    color: rgb(131, 131, 131);
    font-weight: 400;
    margin: 0;
    margin-bottom: 0.5rem;
}

#dashboardWisir {
    width: 94%;
    /* height: fit-content; */
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 20rem;
    overflow-x: hidden;
    margin: auto;
}

.dashboardBoxContainer h2 {
    font-size: 1.1rem;
    color: rgb(109, 109, 109);
    font-weight: 500;
    margin: 0;
    margin-bottom: 0.5rem;
}

#dasboardTopTradersContent {
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 20rem;
    overflow-x: hidden;
}

#dasboardTopTradersContent .dashboardList {
    cursor: default;
}

.dashboardListTitle {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}

.dashboardListTitle2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}

.body h2 {
    margin-right: 0rem !important;
}

.cardBrokerTitle {
    margin-top: 1rem;
}

.containerCardsBrokers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
    height: 18rem;
    margin: auto;
    margin-bottom: 0.8rem;
    padding-top: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border-radius: 10px;
    font-weight: 400;
}

.labelCardBroker {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.cardBrokerImgframe {
    width: 12rem;
    height: 12rem;
    background: white;
    margin: 0.8rem;
    border-radius: 100%;
    border: 0.2rem solid #fff;
    box-shadow: -3px 4px 10px -10px #000000;
    overflow: hidden;
    text-align: center;
    z-index: 90;
}

.cardBrokerImg {
    width: auto;
    max-height: 12.6rem;
    height: 12.6rem;
    -webkit-transform: translate(-0.3rem, -0.3rem);
            transform: translate(-0.3rem, -0.3rem);
    border-radius: 100%;
}

.infoBrokerDash {
    text-align: center;
    padding-top: 3.2rem;
    line-height: 1.8;
    height: 100%;
    background: #d1d8df;
    font-weight: 500;
}

.hoverAreaBrokerDash {
    background: transparent;
    width: 12rem;
    height: 12rem;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

.infoBoxContainer {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #f1f1f1;
    z-index: 110;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid white;
    white-space: nowrap;
}

.titleInfoBoxDash {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
}

.TableCell {
    position: sticky;
    top: 0px;
    justify-content: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    justify-content: center;
    white-space: nowrap;
    -webkit-backgroun-color: white;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #fff;

    /*     min-width: 10rem;
 */
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.containerTableCellButton {
    min-width: 5rem;
    padding: 20px;

    display: flex;
    justify-content: center;
    /* }
.TableCell div{
    
    transition: 0.2s;
    display: inline-block;
*/
}
.containerTableCellButtonBaterBase {
    min-width: 5rem;    
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.containerTableCellButtonBaterBase div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.containerTableCellButton div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.TableCell div:hover {
    cursor: pointer;
    /* 
    padding: 0.8rem; */
    border-radius: 10px;
    background: #efefef;
}

.textInfoBoxDash {
    font-size: 1rem;
    font-weight: 400;
    margin: 0.4rem;
    padding: 0.4rem;
    padding-right: 0.8rem;
    padding-right: 0.8rem;
}

.containerContato {
    display: flex;
    margin: 0.4rem;
}

.logoWPP {
    background-color: #47e26e;
    padding: 0.3rem;
    border-bottom-left-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
}

.buttonWPP {
    background-color: #47e26e;
    border: none;
    border-bottom-right-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    border-left: 4px solid #5df784;
}

.buttonWPP:hover {
    background-color: #5df784;
}

.logoEMAIL {
    background-color: #71ace2;
    padding: 0.3rem;
    border-bottom-left-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
}

.buttonEMAIL {
    background-color: #71ace2;
    border: none;
    border-bottom-right-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    border-left: 4px solid #85bdf1;
}

.buttonEMAIL:hover {
    background-color: #85bdf1;
}

.overlayBrokerImgInfoFixed {
    width: 12.4rem;
    height: 12.4rem;
    background-color: white;
    opacity: 0.5;
    position: absolute;
    border-radius: 100%;
    z-index: 80;
    margin: auto;
}

.overlayLiderImgInfoFixed {
    width: 5.3rem;
    height: 5.3rem;
    background-color: white;
    opacity: 0.5;
    position: absolute;
    border-radius: 100%;
    z-index: 80;
    margin: auto;
}

.textoFixoOverlayImg {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    padding-top: 5.2rem;
    font-size: 1.8rem;
    color: #a7a7a7;
}

.tituloContainerLiderPorBroker {
    text-align: center;
    font-size: 1.6rem;
    display: grid;
    grid-template-columns: 3rem 1fr auto auto 1fr;
}

.tituloLiderPorBroker {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    grid-column-start: 3;
}

.containerLiderPorBroker {
    width: 80%;
    margin: auto;
    height: 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.labelCardLider {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.cardLiderImgframe {
    width: 5rem;
    height: 5rem;
    background: white;
    margin: 0.8rem;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: -3px 4px 10px -10px #000000;
    overflow: hidden;
    text-align: center;
    z-index: 80;
}

.cardLiderImg {
    width: auto;
    max-height: 5.6rem;
    height: 5.6rem;
    -webkit-transform: translate(-0.3rem, -0.3rem);
            transform: translate(-0.3rem, -0.3rem);
    border-radius: 100%;
}

.filterDashboardButtonOk {
    width: 100%;
    margin-top: 0.75rem;
    background-color: #525252;

    background: #D4AC00;
    color: white;
    height: 2rem;
    font-weight: 500;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    transition: .1s;
    font-size: 0.9rem;
    margin-right: 0.5rem;
    transition: 0.2s ease;
}

.filterDashboardButtonOk:hover {
    background: #E6CE74;
    color: white;
}

.containerButtons-Dash {
    width: 90%;
    display: grid;
    justify-items: center;
    justify-content: center;
}

.expandLideresPorBroker {
    width: 3rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: transparent;
    border: none;
    color: gray;
    grid-column-start: 4;
}

.expandLideresPorBroker:hover {
    color: black;
}

.hoverAreaLiderDash {
    background: transparent;
    width: 5rem;
    height: 5rem;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

#dashboardTextEditor {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#dasboardTextEditor div div div div div div div div span:nth-child(1) {
    word-break: break-all;
}

#dashboardTextTitle {
    outline-color: rgb(173, 173, 173);
    font-size: 1rem;
    border: 1px solid rgb(189, 189, 189);
    padding-left: 0.5rem;
    height: 2rem;
}

#dashboardTextTitle::-webkit-input-placeholder {
    color: rgb(143, 143, 143);
    font-size: 1rem;
}

#dashboardTextTitle::placeholder {
    color: rgb(143, 143, 143);
    font-size: 1rem;
}

#dashboardTextEditorContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 30rem;
}

#dashboardNewTextButton {
    background: rgba(37, 64, 109, 1);
    border: none;
    height: 1.2rem;
    border-radius: 4px;
    width: 2rem;
    color: white;
    font-size: 1rem;
    transition: 0.2s;
    border: 1px solid rgba(37, 64, 109, 1);
}

#dashboardNewTextButton:hover {
    cursor: pointer;
    background: white;
    color: rgba(37, 64, 109, 1);
    border: 1px solid rgba(37, 64, 109, 1);
}

.dasboardListContent {
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.dashboardList {
    border-radius: 5px;
    border-bottom: 1px solid rgba(207, 207, 207, 0);
    border-top: 1px solid rgba(207, 207, 207, 0);
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    padding: 0.4rem;
}

.dashboardListToday {
    border-radius: 5px;
    color: rgb(0, 0, 34);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-top: 1px solid rgb(255, 255, 255);
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dashboardListToday:hover {
    cursor: pointer;
    background: rgb(231, 231, 231);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-top: 1px solid rgb(207, 207, 207);
}

.dashboardList:hover a {
    color: black;
}

.dashboardList:hover {
    cursor: pointer;
    background: rgb(231, 231, 231);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-top: 1px solid rgb(207, 207, 207);
}

/* HOVER PARA INDICAÇÃO */

.dashboardListIndicate {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 96%;
    margin: auto;
    padding: 0.5rem;
    border: outset;
    background: #ffd900;
    border: none;
    transition: all ease 0.1s;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

#dashboardWisir .dashboardListIndicate h4 {
    color: #000000;
    margin-bottom: 0;
    font-size: 1.2rem;
}

.dashboardListIndicate:hover {
    cursor: pointer;
    background: #ebc700;
}

/* FIM HOVER PARA INDICAÇÃO */

#dashboardListRigthSide {
    margin-left: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    max-width: 220px;
    min-width: 80px;
}

#dashboardLeftSide {
    margin-right: 1rem;
    max-width: 550px;
    text-overflow: ellipsis;
    width: 50%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

#dashboardMidlleSide {
    margin-right: 1rem;
    max-width: 550px;
    text-overflow: ellipsis;
    width: 30%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.dashboardList a {
    transition: 0.2s;
    font-size: 1rem;
    font-weight: 300;
    color: rgb(109, 109, 109);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dashboardListResult {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dashboardListResult a {
    font-size: 1rem;
    font-weight: 400;
    color: rgb(109, 109, 109);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#dasboardLastUpdatesContent {
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 20rem;
    overflow-x: hidden;
}

.dashboardListLeftSide {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 550px;
    margin-right: 2rem;
    width: 50%;
    white-space: nowrap;
}

.dashboardListLeftSide p {
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dashboardDeleteTopTraderTextButton {
    width: 5rem;
}

#dashboardTopTradeButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#dashboardTopTradeButtons svg:first-child {
    -webkit-transform: translate(-0.5rem, 0);
            transform: translate(-0.5rem, 0);
    color: rgb(117, 1, 1);
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
}

#dashboardTopTradeButtons svg:last-child {
    -webkit-transform: translate(-0.5rem, 0);
            transform: translate(-0.5rem, 0);
    color: rgb(7, 0, 104);
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
}

#dashboardTopTradesContainer:hover #dashboardTopTradeButtons svg {
    opacity: 1;
}

#dashboardTopTradesContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
}

body {
    overflow: hidden;
}

.tableReceita {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2rem;
}

.containerDocViewerRecadosDash {
    height: calc(100vh - 6rem);
}

.containerButtonRecadosDashLeft {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 80%;
    padding-left: 0.6rem;
    padding-right: 0.8rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-left: 0.5rem;
    color: #141414;
    background-color: white;
    border-radius: 4px;
}

.containerButtonRecadosDashLeft:hover {
    color: #fff;
    background-color: #383838;
}

.containerButtonRecadosDashRight {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 80%;
    padding-left: 0.8rem;
    padding-right: 0.6rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-right: 0.3rem;
    color: #141414;
    background-color: white;
    border-radius: 4px;
}

.containerButtonRecadosDashRight:hover {
    color: #fff;
    background-color: #383838;
}

.containerButtonChangeMessage {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.8rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-left: 5vw;
    margin-bottom: 0.6rem;
    color: #141414;
    background-color: white;
    border-radius: 4px;
}

.containerButtonChangeMessage:hover {
    color: #fff;
    background-color: #383838;
}

.containerButtonChangeViewMessage {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.8rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-left: 5vw;
    margin-bottom: 0.6rem;
    color: #141414;
    background-color: white;
    border: 1px solid #141414;
    border-radius: 4px;
    position: absolute;
    left: 2rem;
    bottom: 1rem;
    z-index: 1;
}

.containerButtonChangeViewMessage:hover {
    color: #fff;
    background-color: #383838;
}

.containerHeaderRecadosDash {
    height: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    background-color: #dbdbdb;
}

.textReloadFile {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    font-size: 1.2rem;
    font-weight: 400;
}

.iconReloadFileLeft {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
}

.iconChangeView {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 0.3rem;
    margin-top: 0.1rem;
}

.iconReloadFileRight {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
}

.iconChangeMessage {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 0.3rem;
}

.fileNameRecadosDash {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    color: #383838;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
}

.containerFileNameRecadosDash {
    max-width: 90%;
    overflow: auto;
    margin: auto;
}

.containerDragNDrop {
    display: grid;
}

.titleDragNDrop {
    font-size: 2rem;
    width: 100%;
    text-align: center;
    margin-top: 4rem;
    font-family: Georgia, serif;
    font-weight: 400;
}

.dragNDropArea {
    width: 80%;
    height: 60vh;
    background-color: #ffffff;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 3rem;
    border-radius: 20px;
    box-shadow: 0px 0px 20px black;
    display: grid;
    grid-template-rows: 4fr 3fr;
    transition: all ease 0.1s;
}

.dragNDropArea:hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px #ffc800;
}

.dragNDropArea:hover .iconDragNDrop {
    color: #ffc80083;
}

.dragNDropArea:hover .dragNDropText {
    color: #3a2e04;
}

.iconDragNDrop {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    color: #464646;
    text-align: center;
    margin: auto;
    margin-bottom: 0px;
    transition: all ease 0.1s;
    -webkit-animation: dragNDropIconAnimation infinite 1s;
            animation: dragNDropIconAnimation infinite 1s;
}

.dragNDropText {
    font-size: 1.6rem;
    color: #141414;
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    transition: all ease 0.1s;
}

.containerViewMessage {
    width: 100%;
    height: calc(100vh - 3rem);
    display: grid;
    grid-template-rows: 2fr 8fr;
}

.containerTitleViewMessage {
    display: grid;
    height: calc(15vh - 3rem);
    grid-template-columns: 1fr 1fr 1fr;
}

.titleViewMessage {
    font-size: 2.4rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-bottom: 0.6rem;
    font-family: Georgia, serif;
    font-weight: 400;
}

.dateViewMessage {
    font-size: 1.4rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 5vw;
    margin-bottom: 0.6rem;
    font-family: Georgia, serif;
    font-weight: 400;
}

.containerDocViewerViewMessage {
    width: 90vw;
    max-width: 90vw;
    margin: auto;
    height: 85vh;
}

.legendFooterItemsStatusDashAdvisor {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.expandedColumnDashAdvisor {
    /* border-top: 1px solid rgb(192, 192, 192); */
    text-align: center;
    white-space: nowrap;
    /* font-weight: 450; 
    font-size: 0.8rem; */
}

.expandedColumnDashAdvisorLeft {
    position: relative;
    /* border-top: 1px solid rgb(250, 204, 21); */
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnDashAdvisorLeft::before {
    content: "";
    position: absolute;
    left: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    height: 40%;
    /* border-left: 1px solid rgb(192, 192, 192); Altere a cor e espessura da borda conforme necessário */
}

.expandedColumnDashAdvisorRight {
    position: relative;
    /* border-top: 1px solid rgb(192, 192, 192); */
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnDashAdvisorRight::before {
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    height: 40%;
    /* border-right: 1px solid rgb(192, 192, 192); Altere a cor e espessura da borda conforme necessário */
}

.subtitleStatusClientOnProductDash {
    /* text-align: center;
    vertical-align: middle; */
}

.permissionsIconDashAdvisor {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

/* Aqui é o CSS da 'dashboardAdvisorStatusFilter */
.containerStatusAbc-Dash {
    padding: 20px 50px;
    background-color: #FFFFFF;
    border-radius: 0.75rem;

    transition: 0.2s ease;
}

.containerStatusConfirm-Dash {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.containerStatusConfirm-Dash {
    display: flex;
    justify-content: space-between;
}

.h2status-Dash {
    color: #D3AA00;
    font-weight: 600;
    font-size: 1.5rem;

    margin-bottom: 10px;
}

.statusConfirm-Dash {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.containerCheckbox-Dash {
    display: flex;
    justify-content: center;
}

.containerSelectAll-Dash {
    display: flex;

    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
}

.btn-primary-Dash {
    background-color: #BBBBBB;
    color: #F6F6F6;
    border: none;
    padding: 10px;
    border-radius: 1rem;

    transition: 0.2s ease;
}

.btn-primary-Dash:hover {
    background-color: #545252;
}

.searchButton {
    margin-top: 0.5rem;
    cursor: pointer;
    padding-right: 1rem;
    transition: 0.2s;
}

.searchButton:hover {
    border-radius: 200px;
    background-color: #F5F5F5;

}

.containerBaterBase{
    display: grid;
    grid-template-columns: 1fr 4fr;
    /* height: 100%; */
    height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    grid-gap: 1rem;
    gap: 1rem;
  }
  .containerBaterBaseLeftSide{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    height: calc(88vh - 1rem);
    grid-gap:1rem;
    gap:1rem;

  }

  .buttonBaterBaseSideLeft{
    border: none;
    padding: 10px;
    border-radius: 1rem;
    transition: 0.2s ease;
    cursor: pointer;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #030303;
    width: calc(100% - 1rem);

  }
  .buttonBaterBaseSideLeft:hover{
    background-color: #f5f5f5;
  }
  .containerBaterBaseRightSide{
    display: grid;
    grid-template-rows: 1fr 4fr;
    align-items: center;
    margin-top: 0.15rem;
    /* padding: 20px; */
    border-radius: 10px;
    height: calc(92vh - 1rem);
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
    /* height: calc(90vh - 1rem); Calc com base no numero que está no gap */
    grid-gap:1rem;
    gap:1rem;
  }
  .containerInfosAboutProduct{
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    background-color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  }
  .containerInfosAboutProductSecond{
    display: grid;
    background-color: #fff;
    /* width: 80vw; */
    /* height: 100%; */
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .infosProductBaterBase{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .infosProductBaterBase span{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
  }

  .switchDash {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switchDash input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .sliderDash {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .switchDash:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .switchDash {
    background-color: #2196F3;
  }
  
  input:focus + .sliderDash {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .sliderDash:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliderDashs */
  .sliderDash.round {
    border-radius: 34px;
  }
  
  .sliderDash.round:before {
    border-radius: 50%;
  }

  .paperBaterBase{
    display: flex;
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: 30vw;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

/* Aqui é o fim do CSS da 'dashboardAdvisorStatusFilter' */
@-webkit-keyframes dragNDropIconAnimation {
    0% {
        margin-bottom: 0px;
    }

    50% {
        margin-bottom: 1rem;
    }

    100% {
        margin-bottom: 0px;
    }
}
@keyframes dragNDropIconAnimation {
    0% {
        margin-bottom: 0px;
    }

    50% {
        margin-bottom: 1rem;
    }

    100% {
        margin-bottom: 0px;
    }
}

@media screen and (max-width:1550px) {
    .actionsDash {
        flex-direction: column;
        justify-content: center;
    }

    .dashButton {
        display: flex;
        flex-direction: row;
        max-width: none;
        /* height: 50px; */
        border-radius: 10px;
        /*         padding: 0.5rem 0rem;
 */
        justify-content: center;
        align-items: center;
    }
}
@-webkit-keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

#tileLoader {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
            animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 3rem;
    position: relative;
    overflow: hidden;
}
.loadingWhiteWOTxt{
    display:inline-block;
    position:relative;
    vertical-align:middle;
    width:  0.9rem;
    height: 0.9rem;
    border:2.85px solid transparent;
    border-top-color:white;
    border-bottom-color:white;
    border-radius:50%;
    -webkit-animation: rotate 3s linear infinite, textIn 2s linear infinite;
            animation: rotate 3s linear infinite, textIn 2s linear infinite;
}


.loadingWhiteWOTxt {
  display:inline-block;
  position:relative;
  vertical-align:middle;
  width:  0.9rem;
  height: 0.9rem;
  border:2.5px solid transparent;
  border-top-color: white;
  border-bottom-color:white;
  border-radius:50%;
  -webkit-animation: rotate 3s linear infinite, textIn 2s linear infinite;
          animation: rotate 3s linear infinite, textIn 2s linear infinite;

}
  
  @-webkit-keyframes rotate{
     to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes rotate{
     to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }

  @-webkit-keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .container {
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content:center
  }

 
  .text {
    font-size: 1.5rem;
    color:rgb(122, 122, 122)
  }

  .textGold {
    font-size: 1rem;
    font-weight: 200;
    color:rgb(204, 204, 204)
  }
.alert_containerGlobalAlert__CBu1d{
  width: 16rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  background-color: #fdfdfd;
  border-radius: 4px;
  z-index: 999;
  top: 3.4rem;
  left: calc(50% + 1.5rem);
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  transition: all ease 0.1s;
  box-shadow: 0px 0px 10px #a5a5a5;
  display: grid;
}
.alert_containerGlobalAlert__CBu1d:hover{
  
}

.alert_containerHeaderAlert__3K6VM{
  display: grid;
  grid-template-columns: 7fr 1fr;
  height: 1rem;
  margin-top: 0.2rem;
}

.alert_alertHeaderText__2sovQ{
  font-size: 0.9rem;
  color: black;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 0.6rem;
}

.alert_alertClose__1XGr9{
  margin: auto;
  height: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.15rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}
.alert_alertClose__1XGr9:hover{
  background-color: #e7e7e7;
  cursor: pointer;
}

.alert_alertbell__gsF_v{
  margin: auto;
  height: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.15rem;
  border-radius: 50%;
  transition: all ease 0.1s;
  -webkit-animation-name: alert_bellRing__1RKSK;
          animation-name: alert_bellRing__1RKSK;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.alert_containerAlertMessage___3o1p{
  width: 14.4rem;
  height: 3.8rem;
  overflow: auto;
  margin: 0.3rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  background-color: #f8f8f8;
  border-left: 2px solid #c49e17;
  border-radius: 4px;
  padding: 0.4rem;
}

.alert_alertMessageText__1X607{
  font-size: 0.8rem;
}

.alert_containerAlertResponseOptions__3AYZV{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
}

.alert_alertConfirmButton__4KyI0{
  border: none;
  background-color: #f1f1f1;
  font-weight: 500;
  border-bottom-right-radius: 4px;
  transition: all ease 0.1s;
  height: 1.6rem;
  padding-bottom: 0.1rem;
}
.alert_alertConfirmButton__4KyI0:hover{
  background-color: #141414;
  color: #f1f1f1;
}

.alert_alertCancelButton__3Tzvh{
  border: none;
  background-color: #f1f1f1;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  transition: all ease 0.1s;
  height: 1.6rem;
  padding-bottom: 0.1rem;
}
.alert_alertCancelButton__3Tzvh:hover{
  background-color: #141414;
  color: #f1f1f1;
}

.alert_alertOkButton__qU4Ti{
  border: none;
  background-color: #f1f1f1;
  font-weight: 500;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all ease 0.1s;
  height: 1.6rem;
  padding-bottom: 0.1rem;
  grid-column-start: 1;
  grid-column-end: 3;
}
.alert_alertOkButton__qU4Ti:hover{
  background-color: #141414;
  color: #f1f1f1;
}

@-webkit-keyframes alert_bellRing__1RKSK {
  0% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  30% {
    -webkit-transform:rotate(15deg);
            transform:rotate(15deg);
  }
  80% {
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }
  100% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
}

@keyframes alert_bellRing__1RKSK {
  0% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  30% {
    -webkit-transform:rotate(15deg);
            transform:rotate(15deg);
  }
  80% {
    -webkit-transform:rotate(-15deg);
            transform:rotate(-15deg);
  }
  100% {
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
}
#dashboardTextEditorMainContainer {
    background: white;
    display: flex;
    flex-direction:column;
    align-items: center;
}

#dashboardTextEditorHeader {
    background-color: var(--headerAllBg);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#dashboardTextEditorHeader p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

#dashboardTextEditorHeader button {
    margin-right: 1rem;
}

.dashboardTextDetailsBody {
    width: 90%;
    overflow: auto;
    margin-top: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    font-size: 1.3rem; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

#dashboardTextEditor {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

#dashboardTextEditorButton {
    background-color: var(--topBarBg);
    color: white;
    font-size: 1rem;
    border: none;
    width: 8rem;
    height: 2rem;
    transition: 0.2s;
    border-radius: 4px;
}

#dashboardTextEditorButton:hover {
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--topBarBg);
    color: var(--topBarBg);
}

#dashboardTextTitleEditor {
    width: 10rem;
    height: 2rem;
    font-size: 1rem;
    padding-left: 0.5rem;
    outline-color: rgb(173, 173, 173);
    border: 1px solid rgb(168, 168, 168);
}
    

#dashboardTextTitleEditor::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.479);
    font-size: 1rem;
}
    

#dashboardTextTitleEditor::placeholder {
    color: rgba(0, 0, 0, 0.479);
    font-size: 1rem;
}

.dashboardTextTitleDiv {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
}

.myReservationTableAndFooterContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

}

.monitoringPanelInformationFiltered {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
}


.myReservationOrdersLoadingPeriods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.myReservationTableContainer {
    flex-direction: column;
    align-items: center;
    background: rgb(255, 255, 255);
    width: 95%;
    overflow-x: auto;
    overflow-y: scroll;
    margin-top: 1rem;
    border-radius: 0.5rem 0 0 0; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myReservationTableContainer tbody td {
    font-size: 1rem;
    cursor: default !important;
}

.myReservationTableContainer tbody td:nth-child(2){
    color: rgb(116, 116, 116);
    font-size: 1rem;
    width: 2rem;
    cursor: pointer !important;
}

.myReservationTableContainer tbody td:nth-child(2):hover {
    font-size: 1.1rem;
}

.myReservationOrdersSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    max-width: 8rem;
}

#myReservationLoadingTd {
    height: 10rem;
}

.reservationOrdersTable{
    border-collapse: collapse;
    max-height: 100%;
}

.reservationOrdersTable thead {
    position: -webkit-sticky;
    position: sticky;
    top:0px
}

.reservationOrdersTable thead tr th{
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
    transition: 0.2s;
}

.myReservationOrdersTheadDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.myReservationOrdersTheadDiv p {
    color: white;
    margin-left: 1rem;
}

.myReservationOrdersTheadRightGroup {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reservationOrdersTable thead tr:first-child th{
    background: var(--topBarBg);
    height: 5rem;
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    border-radius: 0.5rem 0 0 0;
    top: 0px;
    color: white;
    font-size: 1.3rem;
    font-weight: 300;
    transition: 0.2s;
    z-index: 10;
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
    
}


#myReservationOrdersMultipleActions {
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--goldColor);
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
}

#myReservationOrdersMultipleActions:hover {
    background: var(--goldColor);
    color: black;
}

.myReservationOrdersMultipleActionsDiv {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 1.1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: var(--goldColor);
}

.myReservationOrdersMultipleActionsDiv p {
    color: var(--goldColor);
    margin-left: 0;
}

#myReservationOrdersMultipleActions div {
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
}

#myReservationOrdersTheadDateSelection {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.myReservationOrdersSelectionAll {
    display: flex;
    flex-direction: row;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes scroll {
    from {
      -webkit-transform: tranlateY(-100%);
              transform: tranlateY(-100%);
    }
  
    to {
       -webkit-transform: tranlateY(0%);
               transform: tranlateY(0%);
    }
  }

@keyframes scroll {
    from {
      -webkit-transform: tranlateY(-100%);
              transform: tranlateY(-100%);
    }
  
    to {
       -webkit-transform: tranlateY(0%);
               transform: tranlateY(0%);
    }
  }

.reservationOrdersTable thead tr:nth-child(2) th{
    background: #3E3E3E;
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    margin-top: 1rem;
    top: 5rem;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 10;
    transition: 0.9s;
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
}

.reservationOrdersTable tbody {
    overflow: auto;
}

.reservationOrdersTable td:nth-child(1){
    
    width: 3rem !important;

}

.reservationOrdersTable td{
    box-sizing: border-box;
    border: 1px solid var(--tableBorder);
    text-align: center;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    text-overflow: ellipsis;
    cursor: pointer !important;
    overflow: hidden;
    white-space: nowrap;
    max-width: 8rem;
    height: 2rem;
    font-size: 1rem;
}

.reservationOrdersTable td:nth-child(2){
    
    max-width: 20rem !important;

}


.reservationOrdersTable tr{
    transition: 0.1s;
}

.reservationOrdersTable tr {
    background: #FFF;
}

.reservationOrdersTable tr:hover{
    background: var(--tableHover) !important;

}

.reservationOrdersTable tr:nth-child(even){
    background: var(--tableBorderEven)
}

.myReservationOrderDetails label {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.myReservationOrderDetails label a:first-child{
    font-weight: 400;
}

#myReservationOrderDetailsTable {
    margin-top: 1.5rem;
    
}

#myReservationOrderDetailsRow {
    transition: all ease 0.2s;
}

.myReservationOrderDetails { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 1.2rem;
    align-items: center;  
    background: #3e3e3e;
    height: 3rem;
    transition: 0.2s;
}

.myReservationOrderDetails a {
    color: white;
}


.myReservationOrderDetails:hover {
    background: #444444;
}

@media(max-width: 1366px){

    .myReservationOrdersSelectionFilter {
        background: var(--tableSecondTh);
        color: white;
        font-size: 0.8rem;
        max-width: 6rem;
    }

    .myReservationTableContainer tbody td {
        font-size: 0.9rem;
        cursor: default !important;
    }
    
}

::-webkit-scrollbar-corner {
    background: rgb(35, 37, 7);
   }
.estructuredsTabOption360 {
    color: white;
    background: #C8AA20;
    height: 2rem;
    min-width: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 0.5rem;
}

.estructuredsTabOption360 div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.estructuredsInactiveTabOption360:hover {
    color: white;
    background-color: #BBBBBB;
}

.estructuredsInactiveTabOption360 {
    color: #c5c5c5;
    font-weight: 500;
    background: #e8e8e8;
    height: 2rem;
    min-width: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

.buttomGray360 {
    background: #868686;
    color: white;
    height: 2rem;
    font-weight: 500;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem ;
    border: none;
    transition: .1s;
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

.buttomGray360:hover {
    background: #D4AC00;
    color: white;
}

.buttonYellowBorderWhite360 {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.2rem solid white;
    border-radius: 1rem;
    padding: 0.8rem 2rem 0.8rem 2rem;
    background-color: #D4AC00;
    font-size: 1rem;
    font-weight: 500;
    transition: 0.1s;
    color: white;
    z-index: 3;
    box-shadow: -6px 0px 7px 3px rgba(0,0,0,0.2);
}

.buttonYellowBorderWhiteInactive360 {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.2rem solid white;
    border-radius: 1rem;
    padding: 0.8rem 2rem 0.8rem 2rem;
    background-color: #C8C5C5;
    font-size: 1rem;
    font-weight: 500;
    transition: 0.1s;
    color: white;
}

.buttonYellowBorderWhiteInactive360:hover {
    background: #acabab;
    color: white;
}
.clientDetailsConfigContainer {
    width: 95vw;
    max-width: 80rem;
    height: 90vh;
    background: #FBFBFB;
    display: flex;
    flex-direction: column;
}

.clientConfigEditBody{
    margin: 1rem;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
    color: #D4AC00;
    font-weight: 300px;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 80vh;
    overflow-y: hidden;
}

.clientConfigEditBodyCenarios{
    margin: 1rem;
    display: grid;
    color: #D4AC00;
    font-weight: 300px;
    justify-content: center;
    /* gap: 1rem; */
    /* height: 100%; */
    /* overflow-y: hidden; */
}

.boxSettlementProducts{
    color: #D4AC00;
    font-weight: 300px;
    display: grid;
    grid-template-rows: 3rem 1fr;
}

.bodyCalendarBox{
    display: grid;
    
    height: 84vh;
}

.bodyConfigLeftTable{
    display: grid;
    grid-template-rows: 1.5fr 1.5fr 1.5fr 1.5fr;
}
.bodyConfigLeftTable .configBlocBodyItem{
    /* padding: 17px 0px; */   
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    min-height: 86px;
    margin: 0.5rem 0px;
}

.bodyConfigBloc{
    margin-bottom: 1rem;
    display: flex;
    /* flex-direction: column; */
    grid-gap: 1rem;
    gap: 1rem;
    border-radius: 10px;
    background-color: #F4F4F4;

    height: 100%;
 overflow-y: auto;
    
}

.bodyConfigBlocHeader{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    
    display: block;
    width:100%; 
}

.bodyConfigBlocBody {
    /* border: 1px solid rgb(209, 209, 209); */
    border-radius: 10px;
    padding: 1rem;
    /* display: inline-flex;*/
    flex-direction: column;  
    flex-wrap: wrap; 
    justify-content: space-between;
    /* overflow-y: auto;
    overflow-x: hidden; */

}

.bodyConfigBlocCenarios{
    /* margin-bottom: 1rem; */
    /* gap: 1rem; */
    border-radius: 10px;
    background-color: #F5F5F5;

    height: 100%;
 overflow-y: auto;
    
}

.bodyConfigBlocHeaderCenarios{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 1rem;
    /* margin-bottom: 0.5rem; */
    
    display: block;
    width:100%; 
}

.bodyConfigBlocBodyCenarios {
    border-radius: 10px;
    padding: 1rem;
    flex-direction: column;  
    flex-wrap: wrap; 
    justify-content: space-between;

}
.bodyConfigRightTable .configBlocBodyItemProduct {
    text-align: center;
}
.bodyConfigLeftTable .configBlocBodyItem .configBlocBodyHeader{
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: none;
}
.bodyConfigRightTable .configBlocBodyItemProduct .configBlocBodyHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
    
}
.configBlocBodyItem{
    width: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    
}

.configBlocBodyItemProduct{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    /* border: 1px solid #A0A0A0; */
    border-radius: 10px;
    padding: 0.5rem;
    min-width: 8rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-basis: 20%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.configBlocBodyHeader{
    /* text-align: center; */
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 400;
}

.configBlocBodyContent{
    min-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #AEAEAE;
    border-radius: 30px;
}

.configBlocBodyHeaderSub{
    text-align: center;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    
}
.bodyConfigRightTable{
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
}

.saveAndExitButton{
    display: grid;
    grid-template-columns: auto auto;
    margin-right: 2rem;
    width: 10rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid #c49f17;
    color: #c49f17;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
    vertical-align: middle;
}

.saveAndExitButton.FiSave {
    color: #c49f17;
}

.saveAndExitButton:hover{
    background: #c49f17;
    color: white;
}

.saveAndExitButton:hover .FiSave {
    color: white;
}

.modalChoice {
    display: flex;
    flex-direction: column;
    min-height: 10rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 3rem;
    min-width: 20rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    position: absolute;
    z-index: 220;
    background-color: white;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    box-shadow: 0px 0px 1rem 2px var(--goldColor);
}

.modalChoice a {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.8rem;
}

.modalChoice div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalChoice div button {
    width: 100%;
    height: auto;
    background: white;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modalChoice div button:hover {
    color: white;
    background: var(--buttonHover);
}

.modalChoiceBackgroundShow {
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    /* background: rgb(0,0,0,0.5); */
    position: absolute;
    z-index: 219;
}
.dashboardAdvisorCalendar{
  background: white;
  width: 76vw;
  height: 90vh;
  display: grid;
  border-radius: 1rem;
  /* flex-direction: column;
  align-items: center;
  place-content: center; */
}

.titleModalCalendar{
  background: var(--headerAllBg);
  height: 6rem;
  align-items: center;
  font-size: xx-large;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-left: 1rem; */
  margin-right: 1rem;
  width: 60rem;
}


.headProdutos{
  margin: 1rem;
  overflow: auto;
  /* width: 60rem; */
  text-align: -webkit-center;
}

.listCalendar{
  display: grid;
  grid-template-columns: 2fr 20rem 2fr 1fr;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin: 1rem;
}

.listCalendarSettlement{
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin: 1rem;
}

.listCalendar th{
  font-weight: 300;
  font-size: large;
}

.listCalendarSettlement th{
  font-weight: 300;
  font-size: large;
}

.arrowCalendar{
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  border: none; 
}
  
.dashboardAdvisorProductsCalendar{
    background: white;
    width: 80rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
  }
  
  .titleModalProductsCalendar{
    background: var(--headerAllBg);
    height: 6rem;
    align-items: center;
    font-size: xx-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 80rem;
  }
  
  .dashboardAdvisorProductsCalendarTable{
    height: 100rem;
  }

  
  .weekProductsCalendarTable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  .headProductsCalendar{
    /* overflow: auto; */
    width: inherit;
    text-align: -webkit-center;
  }

  .weekDayProductsCalendar{
    background: var(--headerAllBg);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .weekCountProductsCalendar{
    text-align: center;
    background-color: cornflowerblue;
    color: white;
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .headProductsCalendar tr{
    width: inherit;
    display: grid;
    text-align: -webkit-center;
  }

  .inlineProducts{
    display: flex;
    align-items: center;
    place-content: center;
    width: 80rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .inlineProductsAdvisor{
    background-color: orange;
  }

  .inlineProductsMesaRV{
    background-color: #8ED1FC;
  }

  .inlineProductsOffshore{
    background-color: #8BC34A;
  }

  .inlineProductsDay{
    display: flex;
    align-items: center;
    place-content: center;
    width: 80rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: white;
  }

  .inlineProductsDay tr{
    display: flex;
  }

  .inlineProductsDay th{
    display: flex;
    align-items: center;
    place-content: center;
    width: 80rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .inlineProducts th{
    margin: 2px;
    display: flex;
    align-items: center;
    place-content: center;
  }
  
  .listProductsCalendar{
    display: grid;
    grid-template-columns: 2fr 20rem 2fr 1fr;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    margin: 1rem;
  }
  
  .listProductsCalendar th{
    font-weight: 300;
    font-size: large;
  }
    

  .modalCloseButtonDashboard {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 40px;
    border-radius: 50%;
    transition: 0.1s;
    color: #D4AC00
}

.modalCloseButtonDashboard:hover {
    background: var(--buttonHover);
    color: white;
}

.editBrancheBoxHeaderDash{
  /* background: var(--headerAllBg); */
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.editBrancheBoxHeaderDash p {
  font-size: 1.2rem;
  /* border-bottom: 1px solid var(--goldColor); */
  margin-left: 1rem;
  color: rgb(188, 188, 188);
  font-weight: 500;
}

.editBrancheBoxHeaderDash button {
  margin-right: 1rem;
}

.containerModalDashDetails{
  min-width: 95vw;
  max-width: 95vw;
  margin: auto;
  height: 90vh;
  background-color: white;
  border-radius: 1rem;
}

.containerProductAdherence{
  width: 100%;
  height: 3rem;
  background-color: #5f5f5f;
}
.containerInfosUserDetailsModal{
  padding: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 1.6;
  background-color: #F4F4F4;
  border-radius: 10px;
}

.containerProductNameDash{
  display: grid;
  grid-template-columns: auto auto 1fr;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  height: 32px;
  align-items: end;
}

/* .containerProductName p {
  font-weight: 400;
} */

.containerClientMenuDashActions{
  width: 100%;
  height: calc(100vh - 4rem);
  display: grid;
  grid-template-columns: 2fr 1px 3fr 3fr;
  /* background-color: #FBFBFB; */
}
.containerClientMenuAbcActionsCampanhas{
  width: 100%;
  height: calc(100vh - 4rem);
  display: grid;
  grid-template-columns: 3fr 5fr 5fr;
}
.containerClientMenuAbcActionsInfosClient{
  width: 100%;
  height: calc(100vh - 4rem);
  display: grid;
  grid-template-columns: 5fr 5fr;
}

.containerClientMenuDashActionsAbrir{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 5fr 1px auto 1px 6fr;
  color: rgb(188, 188, 188);
}

.containerBoletarAbc{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 3fr 1px 3fr;
}

.clientDetails {
  color: black
}

.referenceDetails {
  color: blue
}

.containerClientMenuOptionsAbcActionsDash{
  width: 100%;
  /* height: 100%; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
  background-color: #F4F4F4;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.containerListproductsAbc{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

.toolbarBoletarAbc{
  /* border: 1px solid var(--headerAllBg); */
  /* border-radius: 5px; */
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 2.5rem;
}


.divisionHeaderCardAbc{
  background-color: #B19942;
  width: 96%;
  height: 2px;
  margin-left: 2%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}
.listOperationProductsAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  height: 36.5rem;
  overflow-x: auto;
}

.productCardAbcInfos{
  margin-left: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.offerCardAbcInfos{
  margin-left: 3rem;
  margin-right: 3rem;
  /* display: grid;
  grid-template-columns: auto auto; */
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.offerItemAbc{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 15rem 1fr;
}

.productCardAbc{
  background-color: #ebebe9;
  min-height: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardActiveAbc{
  background: #ebebe9;
  border: 1px solid #c49e17;
  box-shadow: 0 0 6px 4px #c49e17;
  min-height: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardAbc:hover{
  box-shadow: 0 0px 10px #c49e17;
  cursor: pointer;
}

.cardAbcHeader{
  font-weight: 400;
}

.productCardAbcHeader{ 
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 1fr 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  
}

.containerNameAbcActionsDash{
  display: grid;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* margin: auto; */
  margin-top: 1rem;
}

.containerOperateProductAbc{
  border: 1px solid var(--headerAllBg);
  border-radius: 5px;
  margin: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 6rem;
  /* max-width: 36rem; */
}

.headerOperateProductAbc{
  max-width: 100%;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 400;
  color: #5E4C0B;
}

.bodyOperateProductAbc{
  border: 1px solid #ffffff;
  color: #5E4C0B;
  font-size: 1rem;
  font-weight: 400;
}

.contentBodyOperateProductAbc{
  margin-top: 6rem;
}

.contentBodyOperateOfferAbc{
  margin-top: auto;
  margin-bottom: auto;
}

.boxSubmitOperateProductAbc{
  max-width: 100%;
  margin: auto;
}

.buttonOperateSubmitABC{
  width: 10rem;
  padding: 0.2rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
}

.switchSelector{
  margin:auto;
  text-align: center;
  border-radius: 1.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  border: 1px solid #c49e17;
}

.buttonSwitchSelected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #fff;
  background-color: #c49e17;
  transition: all ease-in-out 0.1s;
}

.buttonSwitchUnselected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #c49e17;
  background-color: transparent;
  transition: all ease-in-out 0.1s;
}

.toolbarSelectOrderBoletarAbc{
  margin-left: auto;
  margin-right: 1.5rem;
}


.itemBodyOperateProductAbc{
  margin-top: 1rem;
}

.itemBodyOperateOfferAbc{
  margin-top: 0rem;
}

.fieldsOperateAbc{
  margin-top: 3rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.fieldsOperateOfferAbc{
  margin-top: 1rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.buttonOperateSubmitABC:hover{
  background-color: #a88812;
  border: 1px solid #a88812;
  box-shadow: 0px 0px 10px #ffc800;
}

.clientInfoAbcActionsDash{
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0.8rem;
  font-weight: 400;
  margin-left: 1rem;
}

.clientInfoAbcActionsDash2{
  font-size: 1.1rem;
  /* text-align: center;
  margin-bottom: 0.8rem;
  font-weight: 400; */
  margin-left: 1rem;
}

.productInfoAbcActions{
  font-size: 1rem;
  text-align: center;
}
.customAutocompleteDashActions{
  height: 2.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  border: 1px solid #c49e17;
  padding-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.divisionNameProductSubcategory{
  width: 100%;
  height: 96%;
  background-color: #c49e17;
  margin: auto;
}

.containerGeneralOptionsAbcActions{
  width: 100%;
  height: 98%;
  display: grid;
  position: relative;
}

.containerOptionsTitleAbcActions{
  z-index: 1;
  background-color: white;
  text-align: center;
  margin-bottom: -20px;
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.optionsTitleAbcActions{
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: white;
}

.containerOptionsAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.containerGeneralChangeStatusAbcActions{
  width: 100%;
  height: 12.5rem;
  display: grid;
  position: relative;
}



.containerChangeStatusTitleAbcActions{
  position: absolute;
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.ChangeStatusTitleAbcActions{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  background-color: white;
  margin: auto;
  font-size: 1.2rem;
}

.containerChangeStatusAbcActionsDash{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  display: grid;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  /* border: 1px solid #e2e2e2; */
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.buttonAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #c49e17;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActions:hover{
  background-color: #e6e2d7;
  color: #c49e17;
  border-left: 1px solid #c49e17;
  border-right: 1px solid #c49e17;
}

.buttonAbcActionsSolicit{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: rgb(241, 170, 63);
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActionsSolicit:hover{
  background-color: #e6e2d7;
  color: rgb(241, 170, 63);
  border-left: 1px solid rgb(241, 170, 63);
  border-right: 1px solid rgb(241, 170, 63);
}

.buttonAbordadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #3177b8;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbordadoAbcActions:hover{
  background-color: #d3dee7;
  color: #3177b8;
  border-left: 1px solid #3177b8;
  border-right: 1px solid #3177b8;
}

.buttonRecusadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #d64747;
  color: white;
  transition: all ease 0.1s;
}
.buttonRecusadoAbcActions:hover{
  background-color: #e6d6d6;
  color: #d64747;
  border-left: 1px solid #d64747;
  border-right: 1px solid #d64747;
}

.buttonNaoAderenteAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #696969;
  color: white;
  transition: all ease 0.1s;
}
.buttonNaoAderenteAbcActions:hover{
  background-color: #e6e6e6;
  color: #2b2b2b;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}



.headerSubcategoryAbcActions{
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3.5rem;
  grid-template-columns: 1fr auto;
}

.headerSubcategoryTitleDashActions{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 1rem;
  /* padding-top: 0.8rem; */
  overflow-y: auto;
}

.containerIconCopyContentAbcActions{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* padding-left: 0.7rem;
  padding-right: 0.7rem; */
  margin: 0.4rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcActions:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.containerIconCopyContentAbcDetails{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcDetails:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.linkMenuModalBoxAbc{
  height: 1rem;
}

.linkMenuModalBoxDescriptionAbc{
  height: 1rem;
}

.searchIconModalAbc{
  border: none;
  background-color: transparent;
}

.containerSubcategoryDescriptionAbcActionsDash{
  /* width: 100%; */
  /* height: 100%; */
  margin-bottom: 1rem;
  grid-row: span 1;
  overflow: auto;
  /* background-color: #e2e2e2; */
  margin: 1rem;
  border: 1px solid rgb(64, 64, 64);
  border-radius: 10px;
}

.containerClientMenuSubcategoryAbcActionsDash{
  /* background: #fbfbfb; */
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: #F4F4F4;
}


.descriptionTextAbc{
  margin-left: 1rem;
  margin-top: 1rem;
}

.iconCopyContentAbcActionsAtive{
  -webkit-animation-name: glowing2;
          animation-name: glowing2;
  -webkit-animation-duration: 1.0s;
          animation-duration: 1.0s;
}

.suggestionMessageDash{
  background-color: rgb(212, 172, 0);
  /* height: 15rem; */
  margin: 1rem;
  color: white
}

.commentMessageDash{
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #737373;
}

.suggestionMessageHeader{
  font-size: 1.2rem;
  padding: 1rem 0.5rem 0rem 0.5rem;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.iconArrowRightAbc{
  float: right;
  margin-right: 1rem;
  margin-top: -0.8rem;
}

.linksBoxAbcDash{
  overflow-x: auto;
  display: grid;
  /* width: 100%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3rem;
  max-height: 9rem;
  grid-template-columns: auto 2rem;
  padding-left: 1rem;
  padding-right: 2rem;
}


.suggestionMessageBody{
  font-size: 1rem;
  padding: 2rem 0.5rem 1rem 0.5rem;
  align-items: center;
  text-align: center;
}

.alertMessageAbc{
  border: 1px solid #c49e17;
  border-radius: 10px;
  -webkit-animation: glowing3 2000ms infinite;
          animation: glowing3 2000ms infinite;
}

@-webkit-keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@-webkit-keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

@keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

.commentBoxAbc{
  position:relative;
}
.timelineNewCommentBoxAbc{
  /* height: 12rem; */
  position: absolute;
  width: 27.5rem;
  /* top: 8%; */
  margin: 1.5rem;
  display: grid;
  grid-template-rows: 1fr 3fr;
}

.timelineNewCommentWriteSendDash{
  display: grid;
  grid-template-columns: 9fr 0px;
  margin-bottom: 1rem;
}

.timelineNewCommentContactTypeAbc{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  height: 3.2rem;
}

.timelineNewCommentContactTypeTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 0.4rem;
}

.timelineNewCommentWriteSendAbc{
  display: grid;
  grid-template-columns: 10fr 0fr;
}


.aderenceAbcActiveIcon{
  color: green
}

.aderenceAbcInativeIcon{
  color: rgb(160, 160, 160)
}

.containerProductNameTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.5rem;
  margin-left: 1rem;
}

.containerProductHeaderAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.containerProductNameAbc{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.modalDetailsIconsAbc{
  margin-left: 2rem;
}

.modalDetailsAderenceAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  border-width: 0px 3px;
  padding: 0 1rem;
  border-style: solid;
  border-radius: 4px;
  background-color: #EDEDED; 
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.productCadastrationHeaderAbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--headerAllBg);
  width: 100%;
  height: 5rem;
  color: var(--headerFontColor);
  font-size: 1.3rem;
}

.productCadastrationHeaderAbc span{
  border-bottom: 0.1rem solid var(--formBorder);
  padding: 1rem;
}

.productCadastrationHeaderAbc button{
  display: flex;
  margin-right: 2rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid var(--formBorder);
  color: var(--formBorder);
}

.productCadastrationUpperUpperGridAbc {
  /* display: grid;
  grid-template-columns: 1fr;   
  grid-template-rows: auto;    */
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  /* gap: 1rem; */
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationUpperGridAbc {
  display: grid;
  grid-template-columns: 1fr 1fr;   
  grid-template-rows: auto auto auto;   
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationCategoriesGridAbc {
  margin-top: 1rem;

}

.productCadastrationCategoriesGridButtonAbc {
  margin: 1rem 2rem;
}

.productCadastrationCategoryRowAbc{
  display: grid;
  margin: 1rem 1rem 0 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 4fr 4fr auto;
  width: 50rem;
}

.productCadastrationCategoryRowAbc .buttonWhiteBg {
  height: auto;
}

.productCadastrationContainerRowItemAbc {
  /* width: 25rem; */
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
}

.productCadastrationSubmitAbc {
  margin-left: 2rem;
}
.modalDetailsAderenceAbcActive{
  border-color: #00801C;
}

.modalDetailsAderenceAbcInative{
  border-color: rgb(160, 160, 160);
}

.conteudoGeralDetailsModalAbc{
  display: grid;
  grid-template-columns: 4fr 7fr 6fr;
  height: 36rem
}

.menuCategoryModalAbc{
  margin-top: 1.5rem;
  position: relative;
}

.absoluteTooltipAbc{
  position: absolute;
  background-color: #F4F4F4;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 450;
  padding: 1rem;
  z-index: 1;
  box-shadow: 0 0 10px #c49e17;
}

.listClientsModalAbcDash{
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  background-color: #F4F4F4;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 450;
  overflow-y: auto;
  padding: 1rem;
  height: calc(-14rem + 100vh);
  /* max-height: 33rem; */
}

.menuCategoryItemAbcActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInative:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcActiveAbrir{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInativeAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInativeAbrir:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcGeralActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.menuCategoryItemAbcGeralInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.listClientsHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
}

.listClientsHeaderModalAbcAbrirDash{
  display: grid;
  grid-template-columns: auto 2rem;
  margin: 0.5rem; 
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
  min-height: 1.5rem;
  background-color: white;
  border-radius: 10px;
  color: rgb(212, 172, 0);
  box-shadow: -7px 7px 10px #d1d1d1;
}

.listClientsBodyModalAbc{
  /* background-color: #00801C; */
  max-height: 29rem;
  overflow-y: auto;
}

.listClientsBodyModalAbcAbrir{
  /* background-color: #00801C; */
  /* max-height: 29.5rem; */
  overflow-y: auto;
}

.menuCategoryListModalAbc{
  max-height: 28rem;
  overflow-y: auto; 
}

.listClientsItemInativeAbc{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemActiveAbc{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemInativeAbc:hover{
  background: #e9e9e9;
}

.listClientsItemInativeAbcAbrirDash{
  background: #D9D9D9;
  border-radius: 10px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemActiveAbcAbrirDash{
  background: #c49e17;
  border-radius: 10px;
  color: white;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemInativeAbcAbrirdash:hover{
  background: #e9e9e9;
}

.categoryDetailsModalAbc{
  margin: 1.5rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  display:grid;
  grid-template-rows: auto 8fr 6rem;
  max-height: 33rem;
}


.clientSpeechAbc{
  background-color:#D9D9D9;
  height: calc(100% - 2rem);
  max-height: 25rem;
  width: calc(100% - 4rem);
  margin: 0 0 0 1rem;
  padding: 1rem;
  border-radius: 4px;
  overflow-y: auto;
}

.clientSpeechHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 3rem;
  margin-left: 1.4rem;
  margin-right: 1rem;
  padding: 0.5rem;
  font-weight: 450;
  max-width: 26rem;
  overflow-x: hidden;
  align-items: center;
}

.productLinkAbc{
  font-size: 1.2rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: auto 4rem;
  font-weight: 400;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 6rem;
  overflow-y: auto;


}

.textAreaSpeechAbcDash{
  /* height: calc(100% - 2rem);
  width: calc(100% - 2rem); */
  overflow-y: auto;
  
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  resize: none;
  background-color: transparent;
  padding: 1rem;
  border: none;
  white-space: pre-wrap;
}

.textAreaSpeechAbcDash:focus{
  outline: none;
  border: none;
}

.listOnlyAbc {
  height: 1.6rem;
  width: 8rem;
  font-weight: 400;
  font-size: 1rem;
}

.timelineTitleAbc{
  margin-top: 1rem;
  height: 5rem;
  font-size: 1.1rem;
  font-weight: 500;
  /* display: grid;
  grid-template-columns:3fr 3fr;  */
  padding-bottom: 0.3rem;
  align-items: center;
}

.timelineTitleAbcAbrirDash{
  margin-top: 1rem;
  margin-left: 1rem;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: 500;
  display: grid;
  grid-template-rows:auto auto;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  padding-bottom: 0.3rem;
  align-items: center;
  width: 70%;
  max-width: 26rem;
}

.timelineFiltersDash {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.commentBoxAbcDash{
  margin: 1rem;
}

.containerTimelineAbcDash{
  overflow: auto;
  height: calc(90vh - 2rem);
  padding: 0px 1rem 0px 1rem;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 6rem 1fr 10rem;
  background-color: #F4F4F4;
  margin: 1rem;
}

.timelineCommentBoxAbcDash{
  /* height: calc(90vh - 22rem); */
  overflow: auto;
  /* border-radius: 2px; */
  /* background-color: #f8f8f8; */
  border: 1px solid #dadada;
  display: grid;
}

.containerTimelineAbcDetails{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  /* width: calc(100% - 4rem); */
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 10rem;
}

.refusalReasonBox{
  background-color: white;
  border: 2px solid #c49e17;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #c49e17;
  width: 15rem;
  height: 21.75rem;
  display: block;
  position: absolute;
  z-index: 10;
  top: 8rem;
  padding: 1rem;
  margin-left: 0.2rem;
  /* margin-left: -5rem; */
  /* margin: 0 auto; */
}

.refuseReasontitleAbc{
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 0.25rem;
  
}

.reasonsRefusalList{
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.refuseReasonHeaderAbc{
  display: grid;
  grid-template-columns: 2rem auto 2rem;
  vertical-align: middle;
}

.InputToFormat_two{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.InputToFormat_two div{
  position: relative;
  flex-grow: 1;
  /* margin: 1rem; */
}

.InputToFormat_two div input{
  border: 0;
  border: 1px solid lightgrey;
  height: 1.9rem;
  border: 1px solid var(--formBorder);
  border-radius: 0.3rem !important;
  outline: none;
  min-width: 15rem;
  /* width: 100%; */
  font-size: 16px;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -webkit-appearance:none;
  border-radius: 0;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.InputToFormat_two div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}

.InputToFormat_two div input::-webkit-input-placeholder{
  color:transparent;
}

.InputToFormat_two div input::placeholder{
  color:transparent;
}

.InputToFormat_two div label{
  color: #7F777F;
  font-size: 0.9rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  color: #8f7f7f;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  font-weight: 400;
}

.InputToFormat_two div input:required:invalid + label:before{
  content: '*';
}
.InputToFormat_two div input:focus + label,
.InputToFormat_two div input:not(:placeholder-shown) + label{
  font-size: 11px;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  color: #c49e17;
  background-color: white;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.carousel-container {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
}

.carousel-menu {
  overflow: hidden;
  white-space: nowrap;
}

#menu-items {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

#menu-items li {
  display: inline-block;
  /* padding: 10px; */
}

.carousel-button {
  border: none;
  color: #5f5f5f;
  background-color: transparent;
}

.carousel-button:hover {
  color: #c49e17;
}
.myReservationDetails {
    background: white;
    width: 50rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.myReservationDetails h4 {
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 0.5rem;

}

.myReservationsHeader {

    height: 4rem;
    width: 100%;
    background: var(--headerAllBg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.myReservationsHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.myReservationsHeader button {
    margin-right: 1rem;
}

.myReservationsBody {
    word-break: break-all;
    overflow-x: hidden;
    max-height: 100%;
    margin-top: 1rem;   
    margin-bottom: 1rem;   
    background: rgb(248, 248, 248); 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    height: 100%;
    width: 90%;
    padding: 1rem;
    border-radius: 10px;
}

.myReservationsBody p {
    font-size: 1rem;
}

.myReservationEmptyBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
}

.containerBackofficeObservation{
    width: 50rem;
    height: 20rem;
    /* box-shadow: 0px 0px 0.5rem 1px var(--goldColor); */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-top: 0.1rem solid #c49f17;
    text-align: center;
}

.textFieldBackofficeObservation{
    margin: 1rem;
    padding: 1rem;
    width: 46rem;
    height: 8rem;
    background: rgb(248, 248, 248);
    border: none; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    border-radius: 10px;
    text-align: left;
}

.saveBackofficeObservation{
    width: 6rem;
    height: 3rem;
    margin: auto;
    font-weight: 400;
    font-size: 1.1rem;
    border-radius: 4px;
    border: none;
    background: var(--headerAllBg);
}

.saveBackofficeObservation:hover{
    background: rgb(214, 214, 214);
}
.myClientsArrowLoader {
    
    height: 30rem;
    
}

#myClientsTableNameAndTotalClients {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#myClientsTableNameAndTotalClients a:first-child {
  font-size: 1.2rem;
  font-weight: 300;
}

#myClientsTableNameAndTotalClients a:nth-child(2){
  font-size: 0.9rem;
  font-weight: 200;
}

#myClientsEmptyRow{
    height: 2rem;
    font-size: 1.2rem !important;
}

.myClientsTableContainer  {
    
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    border-radius: 0.5rem 0 0 0; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myClientsTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.myClientsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.myClientsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.myClientsTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.myClientsTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:first-child th {
    width: 120rem;
}

.myClientsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.myClientsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.myClientsTableContainer tbody tr:hover{
    background: var(--tableHover);
    cursor: pointer;
}

.myClientsTableContainer tbody td {
    padding: 0;
    max-width: 6rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* width: 24rem; */
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
}

.myClientsTableContainer .myClientsTheadFirst{
    display: flex;
    flex-direction: row;
}

.myClientsTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myClientsTheadFirst a {
    margin-left: 2rem;
}

.myClientsTheadFirst button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.myClientsTheadFirst label:nth-child(2){
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap:0.5rem;
  gap:0.5rem;
}

.myClientsTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.myClientsTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.myClientsTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.myClientsTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

#myClientsShowLoadingScroll{
    background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
}

#myClientsHideLoadingScroll {
    display: none;
}

.modalMyClients {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.ldsellipsis {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
  }
  .ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }


@media (max-width: 1368px){
.modalMyClients{
  width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.myClientsArrowLoader {

    height: 20rem;
    
}

#myClientsEmptyRow{
    height: 2rem;
    font-size: 1.2rem !important;
}

.myClientsTableContainer  {
    
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    border-radius: 0.5rem 0 0 0; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myClientsTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.myClientsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.myClientsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.myClientsTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.myClientsTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}


.myClientsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.myClientsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    transition: 0.2s;
}

.myClientsTableContainer tbody tr:hover{
    background: var(--tableHover);
}

.myClientsTableContainer tbody td {
    padding: 0;
    max-width: 6rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
}

.myClientsTableContainer .myClientsTheadFirst{
    display: flex;
    flex-direction: row;
}

.myClientsTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myClientsTheadFirst a {
    margin-left: 2rem;
}

.myClientsTheadFirst button {
    margin-left: 2rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.myClientsTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.myClientsTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.myClientsTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.myClientsTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

#myClientsShowLoadingScroll{
    background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
}

#myClientsHideLoadingScroll {
    display: none;
}

.ldsellipsis {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
  }
  .ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

}
.myClientsBody{
    max-height: 40rem;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.myClientsContainer {
    display: flex;
    flex-direction: column;
    height:100%;    
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.assetsOfferingsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.assetsOfferingsTable {
    width: 95%;
    border-radius: 10px 0 0 0;
    overflow: auto;
    margin-top: 2rem;
    box-shadow: -2px 2px 20px -7px #000000;
}

.assetsOfferingsTable table {
    width: 130rem;
}

.assetsOfferingsTable thead tr:first-child th {
    background: var(--topBarBg);
    color: white;
    font-size: 1rem;
    font-weight: 300;
}

.assetsOfferingsTable thead tr:first-child th p {
    font-size: 1.3rem;
    font-weight: 300;
}

.assetsOfferingsTable thead tr:first-child th:first-child {
    height: 4rem;
    width: 10rem;
    border-radius: 10px 0px 0px 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
}

.assetsOfferingsTable thead tr:first-child th:nth-child(2){
    left:10px
}

.assetsOfferingsTableTheadDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 300;
}

.assetsOfferingsTheadRightDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.assetsOfferingsTable thead tr:first-child th:nth-child(2) {
    height: 3rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 10rem;
}

.assetsOfferingsTableTheadDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.assetsOfferingsTable thead tr:nth-child(2) th {
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    background: var(--tableSecondTh);
    color: white;
    font-size: 1rem;
    font-weight: 300;
}

.assetsOfferingsTable thead tr:nth-child(2) {
    background: var(--tableSecondTh);
}

.assetsOfferingsTable thead tr:nth-child(2) th:first-child {
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    top: 4rem;
    left: 0;
    width: 8rem;
    background: var(--tableSecondTh);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 110;
}

.assetsOfferingsTable thead tr:nth-child(2) th:nth-child(2) {
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    left: 9rem;
    background: var(--tableSecondTh);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 110;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.assetsOfferingsTable tbody tr td {
    font-size: 1rem;
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    height: 2rem;
    transition: 0.2s;
    border-left: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
    border-bottom: 1px solid var(--tableBorder);
}

.assetsOfferingsTable tbody tr {
    background: rgb(255, 255, 255);
    transition: 0.2s;
}

.assetsOfferingsTable tbody tr:hover  {
    background: var(--tableHover) !important;
}

.assetsOfferingsTable tbody tr th {
    cursor: pointer;
    top: 4rem;
    color: white;
    background: var(--tableSecondTh);
    transition: 0.2s;
}

.assetsOfferingsTable tbody tr .companyTh:hover {
    color: var(--goldColor)
}

.assetsOfferingsTable tbody tr .companyTh {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    left: 0;
    width: 8rem;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    z-index: 100;
    font-weight: 400;
}

.assetsOfferingsTable tbody tr .companyThSelected {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    left: 0;
    /* color: var(--goldColor); */
    background:#3e3e3e;
    width: 8rem;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    z-index: 100;
    font-weight: 400;
}

.assetsOfferingsTable tbody tr .companyThSecond {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    left: 9rem;
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    font-weight: 400;
    z-index: 100;

}

.assetsOfferingsTable tbody tr .companyThSecondSelected {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background:#3e3e3e;
    font-size: 0.9rem;
    text-align: center;
    height: 1.5rem;
    /* color: var(--goldColor); */
    left: 9rem;
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    font-weight: 400;
    z-index: 100;

}


.assetsOfferingsShowTr {
    background:#333333;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}
  
.assetsOfferingsHideTr {
    display: none;
}

.assetsOfferingsReservation {
    background-color: var(--tableSelectedOperation);
    display: grid;
    grid-template-columns: 15% 85%;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    width: 100%;
    height: 6rem;
    font-weight: 100;
}
.assetsOfferingsReservationTitle {
    display: flex;
    flex-direction: column;align-items: center;
    justify-content: center;
}

.assetsOfferingsReservationTitle p {
    font-size: 1rem;
    color: white !important;
    border-bottom: 1px solid var(--goldColor);
}

.assetsOfferingsReservationHide {
    display: none;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }

  .assetsOfferingsShowTr th:first-child{
      position: -webkit-sticky;
      position: sticky;
      left: 0;
  }


  .assetsOfferingsReservationsSelection {
      display: flex;
      width: 100%;
      flex-direction: row;
      grid-gap: 0.5rem;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;
  }

  .assetsOfferingsSelectionLabel {
      display: flex;
      width: 25%;
      grid-gap: 1px;
      gap: 1px;
      flex-direction: column;
      justify-content: flex-start;
  }

  .assetsOfferingsSelectionLabel span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

  }

  .assetsOfferingsSelectionLabel span a {
      font-size: 0.9rem;
  }

  .assetsOfferingsSelectionValueLabel {
    display: flex;
    width: 12% !important;
    grid-gap: 1px;
    gap: 1px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .assetsOfferingsSelectionValueLabel span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.assetsOfferingsSelectionValueLabel span a {
    font-size: 0.9rem;
}

.assetsOfferingsSelectionLabelButton {
    display: flex;
    width: 12% !important;
    grid-gap: 1px;
    gap: 1px;
    flex-direction: column;
    justify-content: flex-start;
}

.assetsOfferingsSelectionLabelButton span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.assetsOfferingsSelectionLabelButton span a {
    font-size: 0.9rem;
}

  .assetsOfferingsIndicateButton {
      background: #11639e;
      border: none;
      color: white;
      font-size: 1rem;
      height: 2.1rem;
      transition: 0.2s;
  }

  .assetsOfferingsIndicateButton:hover {
      -webkit-filter:brightness(0.95);
              filter:brightness(0.95);
      box-shadow: -0px 0px 15px 0px #11639e;
  }

  #hideElement {
      opacity: 0;
  }


.assetsOfferingsDetailsContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}

#offeringsDetailsObservation {
    width: 100%;
}

.assetsOfferingsDetailsLoader {
    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.assetsOfferingsDetailsHeader {
    background: var(--headerAllBg);
    height: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assetsOfferingsDetailsHeader p {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1rem;
}

.assetsOfferingsDetailsHeader button {
    margin-right: 1rem;
}

.offeringsButton:hover {
    background: var(--goldColor);
    color: black;
}

.assetsOfferingsDetailsHeaderCompanyName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.assetsOfferingsDetailsHeaderCompanyName p {
    font-size: 1.2rem;
    color: black;
}

#assetsOfferingsEditor {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

#offeringsPdfModel {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.assetsOfferingsDetailsPreviewTextContent {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.assetsOfferingsDetailsBody {

    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    font-size: 1.3rem; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.assetsOfferingsDetailsBody:hover  {
    border: 1px solid rgba(45, 48, 77, 0.3);
}

.assetsOfferingsDetailsBody p {

    font-size: 1.1rem;
}

.assetsOfferingsDetailsFooter {
    transition: 0.2s;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.assetsOfferingsDetailsFooter button:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    background: var(--buttonHover);
    color: white;
    height: 2rem;
    width: 10rem;
    transition: 0.2s;
}

.assetsOfferingsDetailsFooter button:first-child:hover {
    background-color: white;
    color: var(--formButtonFontColor)
}

.assetsOfferingsDetailsFooter .blackButton {
    align-items: center;
    background: var(--topBarBg);
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 10rem;
    transition: 0.1s;
    color: white;
    transition: 0.2s;
}

#errorMsgFields  {
    font-size: 1rem;
}

.assetsOfferingsDetailsFooter .blackButton:hover {
    color: var(--topBarBg);
    background: white;
    border: 1px solid var(--topBarBg);
}

.assetsOfferingsDetailsReservation {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    transition: 0.2s;

}

.assetsOfferingsReservationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    grid-gap: 1rem;
    gap: 1rem;
}

.assetsOfferingsReservationCardHeader {
    display: grid;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
}

.assetsOfferingsDetailsConfirmButton {
    border: none;
    width: 10rem;
    height: 2.5rem;
    background: var(--goldColor);
    font-size: 1rem;
    color: white;
    font-weight: 400;
}

.assetsOfferingsReservationLabel {
    width: 100%;
}



.assetsOfferingsDetailsTextContent,
.assetsOfferingsDetailsTextContent {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    font-size: 1rem !important;
}

.assetsOfferingsDetailsTextContent ul li{
   list-style: inside !important;
   margin-left: 1rem;
}

.assetsOfferingsDetailsTextContent ol li{
    list-style: decimal !important;
    margin-left: 2rem;
}
.assetsOfferingsNewTableContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.assetsOfferingsFilterHeader {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white; 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    z-index: 99;

}

.assetsOfferingsFilterHeader p{
 font-size: 1.2rem;
 font-weight: 600;
 padding: 1rem;
}

.assetsOfferingsCardBodyContainer {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;   
    grid-gap: 1.5rem;   
    gap: 1.5rem;
    width: 80%;
    min-width: 65rem;
}

.assetsOfferingsCard {

    width: 100%;
    /* height: 40rem; */
    border-radius: 4px;
    background: white;
    color: rgb(87, 87, 87) !important;
    display: flex;
    flex-direction: column;
    border: none; 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    transition: 0.3s;
    margin-bottom: 1rem;
}

.assetsOfferingsRecommendationLabel {
    background: rgb(1, 102, 48);
    border-radius: 15px;
    height: 1.3rem;
    width: 5.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.assetsOfferingsButtonLabels {
    display: flex;
    flex-direction: row !important;   
    grid-gap: 0.5rem;   
    gap: 0.5rem;
    justify-content: space-between
}

.assetsOfferingsButtonLabels:button:last-child {
    /* margin-right: 1rem; */
}

.assetsOfferingsCardBody textarea {
    border: 1px solid gray;
    background: #dbdbdb;
    height: 2.1rem;
    outline: none;

}

.assetsOfferingsCard:hover {
    box-shadow: 0px 2px 15px -7px #302d6f;
    
}

.assetsOfferingsCardHeader {
    border-radius: 10px 10px 0px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 2rem;
    height: 4rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assetsOfferingsCardHeader label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;

}

.assetsOfferingsCardHeader label a:first-child {
    font-weight: 400;
    font-size: 1rem;    
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
}

.assetsOfferingsCardHeader label a:nth-child(2){
   
    font-size: 1rem;
    font-weight: 400;
}

.assetsOfferingsCardBody {
    border: 1px solid rgba(0, 0, 0, 0.116);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: grid;
    margin-top: 1rem;
    grid-template-columns: repeat(2,1fr);
    align-items: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.assetsOfferingsCardFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.assetsOfferingsBlueButton {
    border: none;
    height: 2rem;
    width: 14rem;
    margin-right: 4rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.assetsOfferingsBlueButtonTrash {
    border: none;
    height: 2rem;
    width: 5rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.assetsOfferingsCardHeaderOptions {
    display: grid;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    
}

.assetsOfferingsCardBodySelected {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    align-items: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    border-top: 1px solid rgba(128, 128, 128, 0.322);
}


.assetsOfferingsCardBodyDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgb(230, 227, 227);
}

.assetsOfferingsCardBodyDetails a {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.assetsOfferingsCardBodyDetails a:first-child {
    font-weight: 300;
    margin-left: 1rem;
}

.assetsOfferingsCardBodyDetails a:nth-child(2){
    margin-right: 1rem;
    font-weight: 600;
}

.assetsOfferingsCardBodyDetailsLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.assetsOfferingsCardBodyDetailsLine a:first-child {
    font-weight: 400;
    margin-left: 1rem;
    margin-top: 1rem;
}

.assetsOfferingsCardBodyDetailsLine a:nth-child(2){
    margin-right: 1rem;
}


.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(35, 37, 7);
    border-radius: 1px;
}
.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb{
    background:  rgba(225, 225, 226, 0.466);
    border-radius: 2px;
}
.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover{
    background: rgb(156, 84, 84);
}

.assetsOfferingsCardBodyDetailsText {
    background: #f5f5f5;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: flex;
    word-break: break-all;
    width: 100%;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding-bottom: 1rem;
    max-height: 10rem;
    min-height: 10rem;
    flex-direction: column;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar {
    width: 10px;
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(231, 231, 231);
    border-radius: 1px
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 20%;
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover {
    background: rgb(204, 204, 204);
}

.assetsOfferingsCardBodyDetailsText a:first-child {
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    white-space: nowrap;

}

.assetsOfferingsCardBodyDetailsText a:nth-child(2) {
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;

}

.assetsOfferingsCardBodyLeftGrid {
    display: flex;
    flex-direction: column;
}

.assetsOfferingsReservDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.assetsOfferingsReservConfirmation {
    background: rgba(37, 64, 109, 1);
    border: none;
    color: white;
    font-weight: 500;
    height: 2.5rem;
    width: 20%;
    font-size: 1rem;
    transition: 0.2s;
}

#assetsOfferingsReservationLabel {
    width: 100%;
}

.assetsOfferingsReservConfirmation:hover,
.assetsOfferingsReservConfirmation:focus  {
    box-shadow: 0px 0px 12px 0px rgba(37, 64, 109, 1);
}

.assetsOfferingsCompanyName {
    cursor: pointer;
    transition: 0.2s;
}

.assetsOfferingsCompanyName:hover a{
    color: black;
}

#assetsOfferingsButtonLabelsRecomendation {
    display: flex;
    flex-direction: row;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    margin-right: 2rem;
}

#assetsOfferingsButtonsRequest {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.offersValueTag {
    background: #302d6f;
    width: 4rem;
    height: 1.5rem;
    color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    margin-left: 1rem;

}

.offersValueTag a{
    margin: 0 auto;
    padding: 0;
    font-size: 1rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 1368px){

    .assetsOfferingsCardBodyContainer {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        grid-gap: 1.5rem;   
        gap: 1.5rem;
        width: 85%;
    }
}
.offeringsRequestBlueButtonDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.assetsOfferingsEmptyOffers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.assetsOfferingsEmptyOffers p {
    font-size: 1.5rem;
    font-weight: 500;
}

.assetsOfferingsLoader {

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.containerDireitoSobras{
    display: grid;
    grid-template-rows: auto auto;
    height: 30%;
    margin-left: auto;
    margin-right: auto;
}

.checkBoxDireitoSobras{
    margin-right: 2rem;
    width: 1rem;
}

.offeringsRegistrationContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.offeringsRegistrationContainer form {
    grid-gap: 1rem;
    gap: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.offeringsCardsRegistrationRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
}

.offeringsCardsRegistrationRow label {
    width: 100%;
}

.offeringsRegistrationContainer form .offeringsCardsRegistration {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2.5rem;
    gap: 2.5rem;

}

.offeringsCardsRegistrationDates {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    grid-gap: 2.5rem;
    gap: 2.5rem;
}

.offeringsCardsRegistrationDualDiv {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.offeringsDateLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#offeringsRegistrationFlexDiv {
    display: flex;
    flex-direction: row;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    align-items: center;
    justify-content: center;

}

#apportiomentExpectation{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
}

.offeringsRegistrationLoaderContainer {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offeringsRegistrationHeader {
    margin-bottom: 2rem;
    background: var(--headerAllBg);
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.offeringsRegistrationHeader p {

    font-size: 1.2rem;
    margin-left: 1rem;

}

.offeringsRegistrationHeader button {
    margin-right: 1rem;
}

.offeringsRegistrationBody {
    padding: 1rem;
    width: 70%;
    margin-right: 2rem;
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    justify-content: center;
    height: 70%;
    align-items: center;
    grid-template-columns: repeat(3,1fr);
}

.offeringsRegistrationBody label {
    margin-left: 2rem;
}

.offeringsRegistrationBody label a {
    font-size: 0.9rem;
    font-weight: 400;
    margin-left: .5rem;
    
}

.offeringsRegistrationBody label:hover a {
    color: rgb(44, 114, 180)
}

.offeringsMarginTag {
    opacity: 0;
}

.offeringsDataDiv {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem
}

.offeringsDateDiv {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem
}

.offeringsCadastrationButtonDiv {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

#offersRegistrationFooter {
    background-color: rgb(119, 1, 1);
    width: 100%;
    height: 2.4rem;
    position: absolute;
    bottom: 0;
    color: white;
    display: flex;
    font-weight: 400;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    justify-content: center;
}

#offersRegistrationFooter button{
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    font-size: 1.2rem;
    transition: 0.2s;
}
#offersRegistrationFooter button:hover { 
    background: white;
    color: rgb(119, 1, 1)
}

@media (max-width: 1368px){
    .offeringsRegistrationBody {
        width: 90%;
    }
    
}

@media(max-width:710px){

    .offeringsRegistrationContainer {
        width: 400px !important;
        min-width: auto !important;
        margin-top: 5rem;
    }

    .offeringsDateLabel {
        margin-top: -3 !important;
    }

    .offeringsRegistrationBody {
        padding: 1rem;
        width: 90%;
        display: grid;
        justify-content: center;
        height: 90%;
        align-items: center;
        grid-template-columns: repeat(1,1fr);
        grid-gap: 1rem;
        gap: 1rem;
        overflow-x: hidden;
    }
    
}



.fiisOfferingsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.fiisOfferingsNewTableContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.fiisOfferingsFilterHeader {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white; 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    z-index: 99;

}

.fiisOfferingsFilterHeader p{
 font-size: 1.2rem;
 font-weight: 600;
 padding: 1rem;
}

.fiisOfferingsCardBodyContainer {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;   
    grid-gap: 1.5rem;   
    gap: 1.5rem;
    width: 80%;
}

.fiisOfferingsCard {

    width: 100%;
    min-width: 65rem;
    border-radius: 4px;
    background: white;
    color: rgb(87, 87, 87) !important;
    display: flex;
    flex-direction: column;
    border: none; 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    transition: 0.3s;
    margin-bottom: 1rem;
}

.fiisOfferingsRecommendationLabel {
    background: rgb(1, 102, 48);
    border-radius: 15px;
    height: 1.3rem;
    width: 5.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fiisOfferingsButtonLabels {
    display: flex;
    flex-direction: row !important;   
    grid-gap: 0.5rem;   
    gap: 0.5rem;
    justify-content: space-between
}

.fiisOfferingsCardBody textarea {
    border: 1px solid gray;
    background: #dbdbdb;
    height: 2.1rem;
    outline: none;

}

.fiisOfferingsCard:hover {
    box-shadow: 0px 2px 15px -7px #302d6f;
    
}

.fiisOfferingsCardHeader {
    border-radius: 10px 10px 0px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 2rem;
    height: 4rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.fiisOfferingsCardHeader label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;

}

.fiisOfferingsCardHeader label a:first-child {
    font-weight: 400;
    font-size: 1rem;    
    font-weight: 600;
}

.fiisOfferingsCardHeader label a:nth-child(2){
   
    font-size: 1rem;
    font-weight: 400;
}

.fiisOfferingsCardBody {
    border: 1px solid rgba(0, 0, 0, 0.116);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: grid;
    margin-top: 1rem;
    grid-template-columns: repeat(2,1fr);
    align-items: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.fiisOfferingsCardFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.fiisOfferingsBlueButton {
    border: none;
    height: 2rem;
    width: 14rem;
    margin-right: 4rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.fiisOfferingsBlueButtonTrash {
    border: none;
    height: 2rem;
    width: 5rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.fiisOfferingsCardHeaderOptions {
    display: grid;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    
}

.fiisOfferingsCardBodySelected {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    align-items: flex-start;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    border-top: 1px solid rgba(128, 128, 128, 0.322);
}


.fiisOfferingsCardBodyDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgb(230, 227, 227);
}

.fiisOfferingsCardBodyDetails a {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.fiisOfferingsCardBodyDetails a:first-child {
    font-weight: 300;
}

.fiisOfferingsCardBodyDetails a:nth-child(2){
    margin-right: 1rem;
    font-weight: 600;
}

.fiisOfferingsCardBodyDetailsLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.fiisOfferingsCardBodyDetailsLine a:first-child {
    font-weight: 400;
    margin-left: 1rem;
    margin-top: 1rem;
}

.fiisOfferingsCardBodyDetailsLine a:nth-child(2){
    margin-right: 1rem;
}


.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(35, 37, 7);
    border-radius: 1px;
}
.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb{
    background:  rgba(225, 225, 226, 0.466);
    border-radius: 2px;
}
.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover{
    background: rgb(156, 84, 84);
}

.fiisOfferingsCardBodyDetailsText {
    background: #f5f5f5;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: flex;
    word-break: break-all;
    width: 100%;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding-bottom: 1rem;
    max-height: 10rem;
    min-height: 10rem;
    flex-direction: column;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar {
    width: 10px;
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(231, 231, 231);
    border-radius: 1px
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 20%;
}

.fiisOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover {
    background: rgb(204, 204, 204);
}

.fiisOfferingsCardBodyDetailsText a:first-child {
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    white-space: nowrap;

}

.fiisOfferingsCardBodyDetailsText a:nth-child(2) {
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;

}

.fiisOfferingsCardBodyLeftGrid {
    display: flex;
    flex-direction: column;
}

.fiisOfferingsReservDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 4rem;
}

.fiisOfferingsReservConfirmation {
    background: rgba(37, 64, 109, 1);
    border: none;
    color: white;
    font-weight: 500;
    height: 2.5rem;
    width: 20%;
    font-size: 1rem;
    transition: 0.2s;
}

#fiisOfferingsReservationLabel {
    width: 100%;
}

.fiisOfferingsReservConfirmation:hover,
.fiisOfferingsReservConfirmation:focus  {
    box-shadow: 0px 0px 12px 0px rgba(37, 64, 109, 1);
}

.fiisOfferingsCompanyName {
    cursor: pointer;
    transition: 0.2s;
}

.fiisOfferingsCompanyName:hover a{
    color: black;
}

#fiisOfferingsButtonLabelsRecomendation {
    display: flex;
    flex-direction: row;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    margin-right: 2rem;
}

#fiisOfferingsButtonsRequest {

    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.stocksOffersValueTag {
    background: #302d6f;
    width: 4rem;
    height: 1.5rem;
    color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;

}

.stockOffersValueTag a{
    margin: 0 auto;
    padding: 0;
    font-size: 1rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 1368px){

    .fiisOfferingsCardBodyContainer {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        grid-gap: 1.5rem;   
        gap: 1.5rem;
        width: 85%;
    }
}

.fiisOfferingsEmptyOffers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.fiisOfferingsEmptyOffers p {
    font-size: 1.5rem;
    font-weight: 500;
}

.fiisOfferingsLoader {

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}



.fiisOfferingsDetailsContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}

#offeringsDetailsObservation {
    width: 100%;
}

.fiisOfferingsDetailsLoader {
    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.fiisOfferingsDetailsHeader {
    background: var(--headerAllBg);
    height: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.fiisOfferingsDetailsHeader p {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1rem;
}

.fiisOfferingsDetailsHeader button {
    margin-right: 1rem;
}

.offeringsButton:hover {
    background: var(--goldColor);
    color: black;
}

.fiisOfferingsDetailsHeaderCompanyName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fiisOfferingsDetailsHeaderCompanyName p {
    font-size: 1.2rem;
    color: black;
}

#fiisOfferingsEditor {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

#offeringsPdfModel {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.fiisOfferingsDetailsPreviewTextContent {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.fiisOfferingsDetailsBody {

    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    font-size: 1.3rem; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.fiisOfferingsDetailsBody:hover  {
    border: 1px solid rgba(45, 48, 77, 0.3);
}

.fiisOfferingsDetailsBody p {

    font-size: 1.1rem;
}

.fiisOfferingsDetailsFooter {
    transition: 0.2s;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fiisOfferingsDetailsFooter button:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    background: var(--buttonHover);
    color: white;
    height: 2rem;
    width: 10rem;
    transition: 0.2s;
}

.fiisOfferingsDetailsFooter button:first-child:hover {
    background-color: white;
    color: var(--formButtonFontColor)
}

.fiisOfferingsDetailsFooter .blackButton {
    align-items: center;
    background: var(--topBarBg);
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 10rem;
    transition: 0.1s;
    color: white;
    transition: 0.2s;
}

#errorMsgFields  {
    font-size: 1rem;
}

.fiisOfferingsDetailsFooter .blackButton:hover {
    color: var(--topBarBg);
    background: white;
    border: 1px solid var(--topBarBg);
}

.fiisOfferingsDetailsReservation {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    transition: 0.2s;

}

.fiisOfferingsReservationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    grid-gap: 1rem;
    gap: 1rem;
}

.fiisOfferingsReservationCardHeader {
    display: grid;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
}

.fiisOfferingsDetailsConfirmButton {
    border: none;
    width: 10rem;
    height: 2.5rem;
    background: var(--goldColor);
    font-size: 1rem;
    color: white;
    font-weight: 400;
}

.fiisOfferingsReservationLabel {
    width: 100%;
}



.fiisOfferingsDetailsTextContent,
.fiisOfferingsDetailsTextContent {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    font-size: 1rem !important;
}

.fiisOfferingsDetailsTextContent ul li{
   list-style: inside !important;
   margin-left: 1rem;
}

.fiisOfferingsDetailsTextContent ol li{
    list-style: decimal !important;
    margin-left: 2rem;
}
.clientsCadastrationContainer {
    display: flex;
    flex-direction: column;
    width: 80rem;
    height: 45rem;
    background: #FFF;
    overflow-y: auto;
}

.clientsCadastrationLoading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.clientsCadastrationHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--headerAllBg);
    width: 100%;
    height: 5rem;
    color: var(--headerFontColor);
    font-size: 1.3rem;
}

.clientsCadastrationHeader button{
    display: flex;
    margin-right: 2rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid var(--formBorder);
    color: var(--formBorder);
}

.clientsCadastrationHeader p{
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
}

.clientsCadastrationContainer form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
}

.clientsCadastrationContainer form #usersCadastrationFirsDiv{
    display: flex;
    justify-content: flex-start;
    width: 80%;
    align-items: center;
}

.clientsCadastrationContainer form #usersCadastrationFirsDiv label{

    width: 100%;

}

.clientsCadastrationDualMenu {
    display: flex;
    flex-direction: row;    
    width: 80%;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.clientsCadastrationContainer label {
    width: 60%;
    font-weight: bold;
    height: 6rem;
}

.clientsCadastrationContainer span {
    color: var(--formFontColor);
    margin-bottom: 0.5rem;
}
.clientsCadastrationContainer form #clientsCadastrationSendButton {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 7.6rem;
    height: 2.8rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.clientsCadastrationContainer  #clientsCadastrationSendButton:hover {
    background-color: var(--buttonBG);
    border: 2px solid var(--goldColor); 
}

.clientsCadastrationContainer #clientsCadastrationSendButton:focus {
    background-color: var(--buttonBG);
    border: 2px solid var(--goldColor); 
}

.clientsCadastrationBody {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (max-width: 1368px){
     .clientsCadastrationContainer {
        display: flex;
        flex-direction: column;
        width: 65rem;
        height: 35rem;
        background: #FFF;
        overflow-y: auto;
    }

    .clientsCadastrationLoading {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    
    .clientsCadastrationHeader{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--headerAllBg);
        width: 100%;
        height: 5rem;
        color: var(--headerFontColor);
        font-size: 1.3rem;
        
    }
    
    .clientsCadastrationHeader p{
        border-bottom: 0.1rem solid var(--formBorder);
        padding: 1rem;
    }
    
    .clientsCadastrationContainer form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
    }
    
    .clientsCadastrationContainer form #usersCadastrationFirsDiv{
        display: flex;
        justify-content: flex-start;
        width: 80%;
        align-items: center;
    }
    
    .clientsCadastrationContainer form #usersCadastrationFirsDiv label{
    
        width: 100%;
    
    }
    
    .clientsCadastrationDualMenu {
        display: flex;
        flex-direction: row;    
        width: 80%;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }
    
    .clientsCadastrationContainer label {
        width: 60%;
        font-weight: bold;
        height: 5rem;
    }
    
    .clientsCadastrationContainer span {
        color: var(--formFontColor);
        margin-bottom: 0.5rem;
    }
    .clientsCadastrationContainer form #clientsCadastrationSendButton {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        padding: 0.5rem;
        width: 7.6rem;
        height: 2rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.6rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    #clientsCadastrationSendButton:hover {
        background-color: var(--buttonBG);
        border: 2px solid var(--goldColor);
    }
    
    #clientsCadastrationSendButton:focus {
        background-color: var(--buttonBG);
        border: 2px solid var(--goldColor); 
    }
    
    .clientsCadastrationBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    } 
    
}



#clientsArrowLoader {    
  height: 30rem;
  border: none;
}

    #clientsEmptyRow{
        height: 2rem;
        font-size: 1.2rem !important;
    }

    #clientsTableNameAndTotalClients {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      grid-gap: 0.3rem;
      gap: 0.3rem;
    }

    #clientsTableNameAndTotalClients a:nth-child(2){
      font-size: 0.9rem;
      font-weight: 200;
    }
    
    .clientsTableContainer  {
        
        max-height: 40rem;
        overflow-y: auto;
        overflow-x: hidden;
        width: 98%;
        border-radius: 0.5rem 0 0 0; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientsTableContainer table {
    
        width: 100%;
        font-size: 1rem;
    }
    
    .clientsTableContainer thead  {
        background: var(--topBarBg);
        color: white;    
    }
    
    .clientsTableContainer thead tr:first-child {
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 200;
    }
    
    .clientsTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
        background: var(--topBarBg);
    }
    
    .clientsTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 5rem;
        opacity: 1;
        z-index: 100;
    
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:nth-child(2){
        height: 1.5rem;
        font-size: 1rem;
        font-weight: 200;
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:first-child th {
        width: 120rem;
    }
    
    .clientsTableContainer thead tr:nth-child(2) th{
        color: white;
        height: 1.5rem;   
        font-size: 1rem;
        font-weight: 300;
    }
    
    .clientsTableContainer tbody {
        background: white;
        overflow-y: scroll;
        max-height: 35rem;
        transition: 0.2s;
    }
    
    .clientsTableContainer tbody tr:hover{
        background: var(--tableHover);
    }
    
    .clientsTableContainer tbody td {
        cursor: default !important;
        padding: 0;
        width: 20rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 2rem;
        border: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        font-size: 1rem;
    }

    .clientsTableContainer tbody td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6){
      cursor: pointer;
    }

    .clientsTableContainer tbody td:nth-child(1){
      width: 10rem;
    }

    .clientsTableContainer tbody td:nth-child(2){
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(3){
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(4){
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(5){
      padding-left: 0.5rem;
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(6){
      width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:last-child{
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .clientsTableContainer .clientsTheadFirst{
        display: flex;
        flex-direction: row;
    }
    
    .clientsTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .clientsTheadFirst a {
        margin-left: 2rem;
    }
    
    .clientsTheadFirst button {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        width: 8rem;
        height: 1.8rem;
        /* border-radius: 0.6rem; */
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    
    .clientsTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }

    .clientsTheadFirst button:focus {
      background: var(--formBorder);
      color: black;
  }
    
    .clientsTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
        grid-gap: 1rem;
        gap: 1rem;
    }
    
    
    .clientsTheadFirst:first-child a{
    
        font-weight: 300;
        font-size: 1.2rem;
    }
    
    .clientsTheadFirst label input {
        height: 1.8rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
        padding-left: 0.5rem;
    }

    #clientsShowLoadingScroll{
      height: 10rem;
      background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }

    #clientsHideLoadingScroll {
        display: none;
    }

    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        -webkit-animation: lds-ellipsis1 0.6s infinite;
                animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        -webkit-animation: lds-ellipsis3 0.6s infinite;
                animation: lds-ellipsis3 0.6s infinite;
      }
      @-webkit-keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @-webkit-keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @-webkit-keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }


@media (max-width: 1368px){
    #clientsArrowLoader {    
        height: 20rem;
    }

    #clientsEmptyRow{
        height: 2rem;
        font-size: 1.2rem !important;
    }
    
    .clientsTableContainer  {
        
        max-height: 30rem;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        border-radius: 0.5rem 0 0 0; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientsTableContainer table {
    
        width: 100%;
        font-size: 1rem;
    }
    
    .clientsTableContainer thead  {
        background: var(--topBarBg);
        color: white;    
    }
    
    .clientsTableContainer thead tr:first-child {
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 200;
    }
    
    .clientsTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
        background: var(--topBarBg);
    }
    
    .clientsTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 5rem;
        opacity: 1;
        z-index: 100;
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:nth-child(2){
        height: 1.5rem;
        font-size: 1rem;
        font-weight: 200;
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:first-child th {
        width: 120rem;
    }
    
    .clientsTableContainer thead tr:nth-child(2) th{
        color: white;
        height: 1.5rem;   
        font-size: 1rem;
        font-weight: 300;
        top: 4.5rem;
    }
    
    .clientsTableContainer tbody {
        background: white;
        overflow-y: scroll;
        max-height: 35rem;
        transition: 0.2s;
    }
    
    .clientsTableContainer tbody tr:hover{
        background: var(--tableHover);
    }
    
    .clientsTableContainer tbody td {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 20rem;
        height: 2rem;
        border: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        font-size: 1rem;
    }

    .clientsTableContainer tbody td:nth-child(1){
      width: 10rem;
    }

    .clientsTableContainer tbody td:nth-child(2){
      max-width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(3){
      width: 6rem;
    }

    .clientsTableContainer tbody td:nth-child(4){
      width: 6rem;
    }

    .clientsTableContainer tbody td:nth-child(5){
      padding-left: 0.5rem;
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(6){
      width: 15rem;
    }

    .clientsTableContainer tbody td:last-child{
      width: 12rem;
    }
    
    .clientsTableContainer .clientsTheadFirst{
        display: flex;
        flex-direction: row;
    }
    
    .clientsTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .clientsTheadFirst a {
        margin-left: 2rem;
    }
    
    .clientsTheadFirst button {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        width: 8rem;
        height: 1.8rem;
        /* border-radius: 0.6rem; */
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    
    .clientsTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    .clientsTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    
    .clientsTheadFirst:first-child a{
    
        font-weight: 300;
        font-size: 1.2rem;
    }
    
    .clientsTheadFirst label input {
    
        height: 1.8rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    #clientsShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }

    #clientsHideLoadingScroll {
        display: none;
    }

    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        -webkit-animation: lds-ellipsis1 0.6s infinite;
                animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        -webkit-animation: lds-ellipsis3 0.6s infinite;
                animation: lds-ellipsis3 0.6s infinite;
      }
      @-webkit-keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @-webkit-keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @-webkit-keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }

}
.divLoading{
    background-color: white;
    padding: 3rem;
}

.tss-1nmvwsz-MUIDataTableViewCol-title {
    font-weight: 500 !important;
    font-size: 1.2rem !important;
}

.selectLastInteractionType{
    width: 10rem;
    margin: auto;
    border-left: 10px solid white; /* MIGUÉ PRA MOVER UM POUCO PRA DIREITA */
}

.bgSelectInterationGERAL{
    background-color: #ece3d9;
}
.bgSelectInterationLIGACAO{
    background-color: #CBE6FF;
}
.bgSelectInterationWHATSAPP{
    background-color: #A4F097;
}
.bgSelectInterationREUNIAO{
    background-color: #FEFFBC;
}
.bgSelectInterationEMAIL{
    background-color: #FFC8C8;
}

.containerSelectContactTypeHeader{
    font-weight: 500 !important;
    font-size: 0.8rem !important;
    text-align: center;
    display: grid;
}

.textLastInteractionDays{
    width: 100%;
    text-align: center;
    margin-bottom: 0.2rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    border-radius: 6px;
    transition: all ease 0.2s;
    white-space: nowrap;
}
.textLastInteractionDays:hover{
    background-color: rgb(245, 245, 245);
    cursor: grab;
}

.textSumCell{
    width: 100%;
    text-align: center;
    vertical-align: bottom;
    padding-top: 0.2rem;
    border-radius: 6px;
    transition: all ease 0.2s;
    min-width: 6rem;
    /* position: absolute;
    bottom: 0.5rem; */
    margin-top: 1.5rem;
    /* left: 50%;
    transform: translateX(-50%); */
}
.textSumCell:hover{
    background-color: rgb(245, 245, 245);
    cursor: grab;
}

.textSumCell p{
    color: green;
    margin-top: 0.5rem;
}
.tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft{
    /* position: relative !important;
    left: auto !important; */
    background-color: white !important;
}
.containerExportCRM{
  width: 25rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  display: grid;
  grid-template-rows: 1fr auto auto auto auto auto;
}

.containerExportCRM p{
  margin-left: 1rem;
  padding: 0.4rem !important;
}

.buttonExportCRM{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.6rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #c49e17;
  border: 1px solid #c49e17;
  background-color: white;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}
.buttonExportCRM:hover{
  color: #fff;
  border: 1px solid #fff;
  background-color: #c49e17;
}

.exportCRMdivline{
  background-color: #d6d5d580;
  width: 90%;
  height: 2px;
  margin: auto;
}

.inputDateExportCRM{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 8rem;
  padding: 0.4rem;
}

.containerExportDate{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto auto;
}

.exportDateTitle{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-right: 0.4rem;
}
.parent {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    justify-content: space-between
  }
  
.child {
flex: 1 0 21%; /* explanation below */
margin: 5px;
height: 50px;
}

.checkbox{
    margin-right : 0.6rem;
}
.clientDetailsContainer {
    width: 80rem;
    height: 44rem;
    background: white;
    display: flex;
    flex-direction: column;
}

#clientDetailsDeleteClient {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: 1rem;
    white-space: nowrap;
}

#clientDetailsDeleteClient a {
    cursor: pointer;
}

#clientDetailsDeleteClient a:hover {
    font-weight: 700;
}

#clientDetailsAlterStatus {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}


#clientDetailsAlterStatus a {
    font-size: 1rem;
}

#clientDetailsReactivate {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor);
    margin-top: 1rem;
}

#clientDetailsReactivate:hover {
    background: var(--buttonHover);
    color: white;
}


.userDetailsLoadingTable {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#clientDetailsLoader{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientsDetailsHeader {
    width: 100%;
    background: var(--headerAllBg);
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.clientsDetailsHeader p:first-child {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
}

.clientsDetailsHeader p:nth-child(2) {
    font-weight: 400;
    font-size: 1.2rem !important;
}

.clientsDetailsHeader div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    width: 100%;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.clientsDetailsHeader button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-right: 1rem;
    font-size: 1rem;
}

.clientsDetailsHeader button:hover{
    background: var(--buttonHover);
    color: white;
}

.clientDetailsBody {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2.4rem;
    gap: 2.4rem;
    height: 100%;
    max-height: 50rem;
}

.clientDetailsBody #clientDetailsEditButtonDiv {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

#clientDetailsEditButton {
    margin-bottom: 1rem;
    width: 100%;
}

.clientDetailsBody label {
    height: 2rem;   
    width: 100%;
}

.clientDetailsStatusClient {
    display: flex;
    flex-direction: row;
    grid-gap: 0.3rem;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.5rem;
}

.clientDetailsStatusClient a:first-child {
    font-weight: 500;
}

.clientDetailsStatusClient a {
    font-size: 1rem;
}

.clientsBoxHideFields {
    display: none;
}

#clientsDetailLabelElipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    max-width: 100%;
    
}
.clientDetailsBody #relative{
    position: relative;
}

.clientDetailsBody div .dinamic{
    border: 0;
    border: 1px solid var(--formBorder);
    height: 3.4rem;
    outline: none;
    font-weight: 400;
    min-width: 13rem;
    width: calc(100% - 1rem);
    padding-left: 1rem;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0.3rem;
}
  
.clientDetailsBody div .dinamic:focus{
    border: 1px solid #c39e16
}
  
.clientDetailsBody div .dinamic::-webkit-input-placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.clientDetailsBody div .dinamic::placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.clientDetailsBody div #labelDinamic{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
}
  
.clientDetailsBody div .dinamic:required:invalid + #labelDinamic{
    color: red;
}
.clientDetailsBody div .dinamic:focus:required:invalid{
    border-bottom: 2px solid red;
}
.clientDetailsBody div .dinamic:required:invalid + #labelDinamic:before{
    content: '*';
}
.clientDetailsBody div .dinamic:focus + #labelDinamic,
.clientDetailsBody div .dinamic:not(:placeholder-shown) + #labelDinamic{
    font-size: 0.9rem;
    margin-top: -1rem;
    margin-left: 0.5rem;
    width: auto;
    height: auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: #3C3C3C;
    background-color: white;
}

.clientsDetailDualDetail {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.clientsInformationDiv {

    border: 1px solid lightgrey;
    width: 15rem;
    overflow: hidden;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    text-overflow: ellipsis;
    height: 3.4rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    border-radius: 0.3rem;
}

.clientsInformationDiv a {
    font-size: 1rem;
}

.clientsDetailsEditFixedBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.clientsBoxShowStatusField {
    width: 100%
 }
 
.clientsBoxShowFields {
    border: 1px solid lightgrey;
    width: 15rem;
    height: 2rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-radius: 0.3rem;
}

.clientsInformationDiv #clientDetailFirstAElement {
    font-size: 1rem;
    margin-left: 1rem;
}

.clientsInformationDiv #clientDetailFirstAElement {
    font-weight: 600;
}

.usersDetailAddBrokerOption {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
}

.usersDetailAddBrokerOption button {
    margin-bottom: 1rem;
}

.usersDetailAddBrokerOption label a {
    margin-bottom: 0.5rem;
}

.clientDetailsEditLinks {
    max-height: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}


.clientDetailsEditLinks #usersDetailConfirmationButton{
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

.clientDetailsEditLinks #userDetailsAddBroker { 
    width: 6rem;
    height: 1.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    
}

.clientDetailsEditLinks #userDetailsHideLabelBroker {
    display:  none;
}

.clientDetailsEditLinks #userDetailsShowLabelBroker {
    width: 100%;
}

.usersDetailStaticProfileDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#editClientBrokerDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgba(34, 34, 34, 0.294);
    background: white;
    height: 3.4rem;
    align-items: center;
    font-size: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    border-radius: 4px;
}

#editClientBrokerDetail span {
    display: flex;
    flex-direction: row;
    grid-gap:0.5rem;
    gap:0.5rem
}

#labelToEditClientBroker {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: 1rem;
}

#labelToEditClientBroker a {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
}

#alignLabelForEditBroker {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#labelToEditClientBroker svg:nth-child(2){
    padding-top: 3rem;
}

.usersDetailStaticProfileDescription label {
    font-size: 0.8rem;
    font-weight: 400;
    height: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}

.usersDetailStaticProfileDescription a {
    font-size: 1rem;
    font-weight: 500;
    color: #777777;
}

.clientDetailsTableDetails {
    width: 100%;
    height: 100%;   
    margin-bottom: 1rem;
    
}

.clientDetailsTableDetailsGray {
    width: 30rem;
    height: 70%;
    background: var(--headerAllBg);      
    margin-bottom: 1rem;  
}

.clientDetailsTableDetails table {
    margin-top: 2rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.clientDetailsTableDetails thead tr:first-child th{
    background: var(--tableSecondTHGray);
    font-weight: 400;
    height: 1.8rem;
    padding: 0;
    font-size: 1.2rem;
}


.clientDetailsTableDetails thead tr th{
    background: var(--tableSecondTh);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
}

.clientDetailsTableDetails tbody tr td {
    text-align: center;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
}

.clientDetailsSuccessEdit {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.clientDetailsSuccessEdit a {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.8rem;
}

.clientDetailsSuccessEdit button {
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

.clientDetailsSuccessEdit button:hover {
    color: white;
    background: var(--buttonHover);
}

.containerComentEvasion{
    margin-top: 0.4rem;
    width: 86%;
    margin-left: 4%;
    border-left: 2px solid #c49f1759;
}

@media(max-width: 1368px){
    .clientDetailsContainer {
        width: 74rem;
        height: 44rem;
        background: white;
        display: flex;
        flex-direction: column;
    }

    .userDetailsLoadingTable {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    #clientDetailsLoader{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .clientsDetailsHeader {
        width: 100%;
        background: var(--headerAllBg);
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsDetailsHeader p:first-child {
        border-bottom: 1px solid var(--goldColor);
        font-size: 1.2rem !important;
    }

    .clientsDetailsHeader p:nth-child(2) {
        font-weight: 400;
        font-size: 1.2rem !important;
    }
    
    .clientsDetailsHeader div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 1rem;
        width: 100%;
        justify-content: flex-start;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    
    .clientsDetailsHeader button {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
        margin-right: 1rem;
    }
    
    .clientsDetailsHeader button:hover{
        background: var(--buttonHover);
    }
    
    .clientDetailsBody {
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        grid-gap: 0;
        gap: 0;
    }
    
    .clientDetailsBody #clientDetailsEditButtonDiv {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .clientDetailsStatusClient {
        display: flex;
        flex-direction: row;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0.5rem;
    }

    .clientDetailsStatusClient a {
        font-size: 1rem;
        font-weight: 400;
    }
    
    #clientDetailsEditButton {
        margin-bottom: 1rem;
        width: 100%;
    }
    
    .clientDetailsBody label {
        height: 2rem;   
        width: 100%;
    }
    
    .clientsBoxHideFields {
        display: none;
    }
    .clientDetailsBody #relative{
        position: relative;
    }
    
    .clientDetailsBody div .dinamic{
        border: 0;
        border: 1px solid var(--formBorder);
        height: 2rem;
        outline: none;
        min-width: 13rem;
        width: calc(100% - 1rem);
        padding-left: 1rem;
        font-size: 1rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
        -webkit-appearance:none;
        border-radius: 0.3rem;
    }
      
    .clientDetailsBody div .dinamic:focus{
        border: 1px solid #c39e16
    }
      
    .clientDetailsBody div .dinamic::-webkit-input-placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .clientDetailsBody div .dinamic::placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .clientDetailsBody div #labelDinamic{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
    }
      
    .clientDetailsBody div .dinamic:required:invalid + #labelDinamic{
        color: red;
    }
    .clientDetailsBody div .dinamic:focus:required:invalid{
        border-bottom: 2px solid red;
    }
    .clientDetailsBody div .dinamic:required:invalid + #labelDinamic:before{
        content: '*';
    }
    .clientDetailsBody div .dinamic:focus + #labelDinamic,
    .clientDetailsBody div .dinamic:not(:placeholder-shown) + #labelDinamic{
        font-size: 0.7rem;
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        width: auto;
        height: auto;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        color: #3C3C3C;
        background-color: white;
    }
    
    .clientsDetailDualDetail {
        width: 100%;
        display: flex;
        flex-direction: row;
        grid-gap: 1rem;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    
    .clientsInformationDiv {
    
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsDetailsEditFixedBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
    
    .clientsBoxShowFields {
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsBoxShowStatusField {
       width: 100%
    }
    
    .clientsInformationDiv #clientDetailFirstAElement {
        margin-left: 1rem;
    }
    
    .clientsInformationDiv #clientDetailFirstAElement{
        font-weight: 600;
    }

    .usersDetailAddBrokerOption {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        gap: 2rem;
        width: 100%;
    }

    .usersDetailAddBrokerOption label a {
        margin-bottom: 0.5rem;
    }

    .clientDetailsEditLinks {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 2rem;
        gap: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }


    .clientDetailsEditLinks #usersDetailConfirmationButton{
        width: 8rem;
        height: 2rem;
        background: white;
        margin-top: 1rem;
        font-weight: 400;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
    }

    .clientDetailsEditLinks #userDetailsAddBroker { 
        width: 6rem;
        height: 1.5rem;
        font-weight: 400;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
        
    }

    .clientDetailsEditLinks #userDetailsHideLabelBroker {
        display:  none;
    }

    .clientDetailsEditLinks #userDetailsShowLabelBroker {
        width: 100%;
    }

    .usersDetailStaticProfileDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #editClientBrokerDetail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(34, 34, 34, 0.294);
        background: white;
        height: 2.4rem;
        align-items: center;
        padding-left: 0.3rem;
        padding-right: 0.5rem;
        border-radius: 4px;
    }
    
    #editClientBrokerDetail span {
        display: flex;
        flex-direction: row;
        grid-gap:0.5rem;
        gap:0.5rem
    }
    #labelToEditClientBroker {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        font-size: 1rem;
    }

    #labelToEditClientBroker a {
        font-weight: 400;
    }

    #alignLabelForEditBroker {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #alignLabelForEditBroker a {
        margin-bottom: 0.6rem;
    }

    #labelToEditClientBroker svg:nth-child(2){
        padding-top: 2.1rem;
    }
    
    .usersDetailStaticProfileDescription label {
        font-weight: 400;
        height: 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
    }

    .usersDetailStaticProfileDescription a {
        font-size: 1rem;
        font-weight: 500;
        color: #777777;
    }

    .clientDetailsTableDetails {
        width: 100%;
        height: 100%;     
        
    }

    .clientDetailsTableDetailsGray {
        width: 100%;
        height: 10rem;
        background: var(--headerAllBg);       
        
    }
    
    .clientDetailsTableDetails table {
        margin-top: 2rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientDetailsTableDetails thead tr:first-child th{
        background: var(--tableSecondTHGray);
        font-weight: 400;
        height: 1.2rem;
        padding: 0.2rem;
        font-size: 1rem;
    }
    
    
    .clientDetailsTableDetails thead tr th{
        background: var(--tableSecondTh);
        color: white;
        font-weight: 300;
        padding: 0.2rem;
        font-size: 0.9rem;
    }
    
    .clientDetailsTableDetails tbody tr td {
        text-align: center;
        height: 1rem;
        padding: 0.15rem;
        border: 1px solid var(--tableBorder);
    }

    .clientDetailsContainer .MuiInputBase-root {
        height: 2.2rem;
    }
    
}


#clientDetailsDeleteClient {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: 1rem;
    white-space: nowrap;
}

#clientDetailsDeleteClient a {
    cursor: pointer;
}

.clientDetailsContainer{
    max-height: 44rem;
    height: 44rem;
}

#clientDetailsDeleteClient a:hover {
    font-weight: 700;
}

#clientDetailsAlterStatus {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}


#clientDetailsAlterStatus a {
    font-size: 1rem;
}

#clientDetailsReactivate {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor);
    margin-top: 1rem;
}

#clientDetailsReactivate:hover {
    background: var(--buttonHover);
    color: white;
}


.userDetailsLoadingTable {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#clientDetailsLoader{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientsDetailsHeader {
    width: 100%;
    background: var(--headerAllBg);
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.clientsDetailsHeader p:first-child {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
}

.clientsDetailsHeader p:nth-child(2) {
    font-weight: 400;
    font-size: 1.2rem !important;
}

.clientsDetailsHeader div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    width: 100%;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.clientsDetailsHeader button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-right: 1rem;
    font-size: 1rem;
}

.clientDetailsBodycontent{
    display: grid;
    height: 36rem;
    grid-template-columns: 1fr 1px 1fr;
    margin-top: 1rem;
}
.containerDetalhesEspecificos{
    margin-top: 1.2rem;
    margin-left: -1rem;
    height: 95%;
    overflow: auto;
}

.clientsDetailsHeader button:hover{
    background: var(--buttonHover);
    color: white;
}

.subTitle {
    font-weight: 500;
    font-size: 1.1rem;
    margin-left: 3.5rem;
}
.clientDetails {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-bottom: 0.6rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.clientDetails2 {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.itemNull{
    margin-left: 1rem;
}

.selectBroker{
    width: 100%;
    white-space: nowrap;
}
.itemDetail{
    flex: 30% 1;
    display: inline;
    margin-left: 1rem;
}

.containerActionsAvulso{
    display: grid;
    grid-template-columns: auto auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 0.9rem;
    margin-top: 0.4rem;
}

.itemDetail2{
    flex: 60% 1;
    display: inline;
    margin-left: 1rem;
}

#clientDetailsEditButton {
    margin-bottom: 1rem;
    width: 100%;
}

.clientDetails label {
    height: 2rem;   
    width: 100%;
}

.clientDetailsStatusClient {
    display: flex;
    flex-direction: row;
    grid-gap: 0.3rem;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.5rem;
}

.clientDetailsStatusClient a:first-child {
    font-weight: 500;
}

.clientDetailsStatusClient a {
    font-size: 1rem;
}

.clientsBoxHideFields {
    display: none;
}

#clientsDetailLabelElipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    max-width: 100%;
    
}
.clientDetails #relative{
    position: relative;
}

.clientDetails div .dinamic{
    border: 0;
    border: 1px solid var(--formBorder);
    height: 3.4rem;
    outline: none;
    font-weight: 400;
    min-width: 13rem;
    width: calc(100% - 1rem);
    padding-left: 1rem;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0.3rem;
}
  
.clientDetails div .dinamic:focus{
    border: 1px solid #c39e16
}
  
.clientDetails div .dinamic::-webkit-input-placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.clientDetails div .dinamic::placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.clientDetails div #labelDinamic{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
}
  
.clientDetails div .dinamic:required:invalid + #labelDinamic{
    color: red;
}
.clientDetails div .dinamic:focus:required:invalid{
    border-bottom: 2px solid red;
}
.clientDetails div .dinamic:required:invalid + #labelDinamic:before{
    content: '*';
}
.clientDetails div .dinamic:focus + #labelDinamic,
.clientDetails div .dinamic:not(:placeholder-shown) + #labelDinamic{
    font-size: 0.9rem;
    margin-top: -1rem;
    margin-left: 0.5rem;
    width: auto;
    height: auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: #3C3C3C;
    background-color: white;
}

.clientDetailDualDetail {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 20%;
    display: grid;
    grid-template-columns: auto auto;
    flex-direction: column;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    align-items: center;
    min-height: 2rem;
}
.clientDetailDualDetail2{
    width: calc(100% - 1rem);
    height: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: center;
    min-height: 2rem;
    padding-top: 0.4rem;
    padding-right: 0.8rem;
}

.clientsInformationDiv {

    border: 1px solid lightgrey;
    width: 15rem;
    overflow: hidden;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    text-overflow: ellipsis;
    height: 3.4rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    border-radius: 0.3rem;
}

.clientsInformationDiv a {
    font-size: 1rem;
}

.clientsDetailsEditFixedBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.clientsBoxShowStatusField {
    width: 100%
 }
 
.clientsBoxShowFields {
    border: 1px solid lightgrey;
    width: 15rem;
    height: 2rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-radius: 0.3rem;
}

#clientDetailFirstAElementVBA {
    font-size: 1rem;
    overflow-x: hidden;
    font-weight: 600;
}

.usersDetailAddBrokerOption {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
}

.usersDetailAddBrokerOption button {
    margin-bottom: 1rem;
}

.usersDetailAddBrokerOption label a {
    margin-bottom: 0.5rem;
}



.usersDetailStaticProfileDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#editClientBrokerDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgba(34, 34, 34, 0.294);
    background: white;
    height: 3.4rem;
    align-items: center;
    font-size: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    border-radius: 4px;
}

#editClientBrokerDetail span {
    display: flex;
    flex-direction: row;
    grid-gap:0.5rem;
    gap:0.5rem
}

#labelToEditClientBroker {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    font-size: 1rem;
}

#labelToEditClientBroker a {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
}

#alignLabelForEditBroker {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#labelToEditClientBroker svg:nth-child(2){
    padding-top: 3rem;
}

.usersDetailStaticProfileDescription label {
    font-size: 0.8rem;
    font-weight: 400;
    height: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}

.usersDetailStaticProfileDescription a {
    font-size: 1rem;
    font-weight: 500;
    color: #777777;
}

.clientDetailsTableDetails {
    width: 100%;
    height: 100%;   
    margin-bottom: 1rem;
    
}

/* .clientDetailsTableDetailsGray {
    width: 100%;
    height: 100%;
    background: var(--headerAllBg);      
    margin-bottom: 1rem;  
} */

.clientDetailsTableDetails table {
    margin-top: 2rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.clientDetailsTableDetails thead tr:first-child th{
    background: var(--tableSecondTHGray);
    font-weight: 400;
    height: 1.8rem;
    padding: 0;
    font-size: 1.2rem;
}


.clientDetailsTableDetails thead tr th{
    background: var(--tableSecondTh);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
}

.clientDetailsTableDetails tbody tr td {
    text-align: center;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
}

.clientDetailsSuccessEdit {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.clientDetailsSuccessEdit a {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.8rem;
}

.clientDetailsSuccessEdit button {
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

.clientDetailsSuccessEdit button:hover {
    color: white;
    background: var(--buttonHover);
}

.verticalBarVbaClients{
    width: 100%;
    height: 100%;
    background-color: lightgray;
}

.containerTimeline{
    height: 36rem;
    max-height: 36rem;
    width: calc(100% - 4rem);
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
}

.timelineTitle{
    height: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    display: grid;
    grid-template-columns:2fr 3fr;
    padding-bottom: 0.3rem;
    align-items: center;
}

.timelineFilters {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
}

.timelineCommentBox{
    height: 24rem;
    max-height: 24rem;
    overflow: auto;
    border-radius: 2px;
    background-color: #f8f8f8;
    border: 1px solid #dadada;
    display: grid;
}

.timelineNewCommentBox{
    height: 10rem;
    display: grid;
    grid-template-rows: auto 1fr;
}

.timelineNewCommentContactType{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.timelineNewCommentWriteSend{
    display: grid;
    grid-template-columns: 9fr 1fr;
}

.timelineNewCommentContactTypeTitle{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 0.4rem;
}

.timelineComentBoxUserComentRight{
    max-width: 80%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    display: grid;
    margin-bottom: 1.8rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.timelineComentBoxUserComentLeft{
    max-width: 80%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    display: grid;
    margin-bottom: 1.8rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.timelineComentBoxUserComentHeaderRight{
    display: grid;
    grid-template-columns: auto auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.timelineComentBoxUserComentHeaderLeft{
    display: grid;
    grid-template-columns: auto auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.timelineComentBoxUserComentDateRight{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-right: 1rem;
}

.timelineComentBoxUserComentDateLeft{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-left: 1rem;
}

.timelineComentBoxUserComentMessageRight{
    padding: 0.6rem;
    border-top-left-radius: 6px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.timelineComentBoxUserComentIconRight{
    padding-bottom: 0.3rem;
    padding-top: 0.35rem;
    padding-left: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentNameRight{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentDateRight{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin-right: auto;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.timelineComentBoxUserComentMessageLeft{
    padding: 0.6rem;
    border-top-right-radius: 6px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.timelineComentBoxUserComentIconLeft{
    padding-bottom: 0.3rem;
    padding-top: 0.35rem;
    padding-right: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentNameLeft{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentDateLeft{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin-left: auto;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.whatsAppMessageColor{
    background-color: #f3fff1;
    color: #092400;
    border: 2px solid #092400;
}

.whatsAppHeaderColor{
    background-color: #092400;
    color: #f3fff1;
}

.whatsAppDateColor{
    background-color: #092400;
    color: #f3fff1;
}

.phoneMessageColor{
    background-color: #e0f0ff;
    color: #000b33;
    border: 2px solid #000b33;
}

.phoneHeaderColor{
    background-color: #000b33;
    color: #e0f0ff;
}

.phoneDateColor{
    background-color: #000b33;
    color: #e0f0ff;
}

.emailMessageColor{
    background-color: #ffe2e2;
    color: #410000;
    border: 2px solid #410000;
}

.emailHeaderColor{
    background-color: #410000;
    color: #ffe2e2;
}

.emailDateColor{
    background-color: #410000;
    color: #ffe2e2;
}

.meetMessageColor{
    background-color: #ffffe2;
    color: #524400;
    border: 2px solid #524400;
}

.meetHeaderColor{
    background-color: #524400;
    color: #ffffe2;
}

.meetDateColor{
    background-color: #524400;
    color: #ffffe2;
}

.meetMessageColor{
    background-color: #ffffe2;
    color: #524400;
    border: 2px solid #524400;
}

.meetHeaderColor{
    background-color: #524400;
    color: #ffffe2;
}

.meetDateColor{
    background-color: #524400;
    color: #ffffe2;
}

.commentMessageColor{
    background-color: #f3ece1;
    color: #2b1800;
    border: 2px solid #2b1800;
}

.commentHeaderColor{
    background-color: #2b1800;
    color: #f3ece1;
}

.commentDateColor{
    background-color: #2b1800;
    color: #f3ece1;
}

.systemMessageColor{
    background-color: #f1f1f1;
    color: #0e0e0e;
    border: 2px solid #0e0e0e;
}

.systemHeaderColor{
    background-color: #0e0e0e;
    color: #f1f1f1;
}

.systemDateColor{
    background-color: #0e0e0e;
    color: #f1f1f1;
}

.formMessageColor{
    background-color: #f8fffe;
    color: #007761;
    border: 2px solid #007761;
}

.formHeaderColor{
    background-color: #007761;
    color: #f8fffe;
}

.formDateColor{
    background-color: #007761;
    color: #f8fffe;
}

.indicationMessageColor{
    background-color: #ffeedb;
    color: #4b3114;
    border: 2px solid #4b3114;
}

.indicationHeaderColor{
    background-color: #4b3114;
    color: #ffeedb;
}

.indicationDateColor{
    background-color: #4b3114;
    color: #ffeedb;
}

.emptyTimelineMessage{
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    
}

.containerActivateClient{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    display: grid;
}

.containerActivateClientIcon{
    margin: auto;
}

.activateClientText{
    font-size: 1.4rem;
}

.activateClientButton{
    font-size: 1.2rem;
    font-weight: 400;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: auto;
    margin-top: 0.8rem;
    color: #c49e17;
    border: 2px solid #c49e17;
    background-color: white;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
}
.activateClientButton:hover{
    color: #fff;
    border: 2px solid #c49e17;
    background-color: #c49e17;
    box-shadow: 0px 0px 10px #c49e17;
}

@media(max-width: 1368px){
    

    .userDetailsLoadingTable {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    #clientDetailsLoader{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .clientsDetailsHeader {
        width: 100%;
        background: var(--headerAllBg);
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsDetailsHeader p:first-child {
        border-bottom: 1px solid var(--goldColor);
        font-size: 1.2rem !important;
    }

    .clientsDetailsHeader p:nth-child(2) {
        font-weight: 400;
        font-size: 1.2rem !important;
    }
    
    .clientsDetailsHeader div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 1rem;
        width: 100%;
        justify-content: flex-start;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    
    .clientsDetailsHeader button {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
        margin-right: 1rem;
    }
    
    .clientsDetailsHeader button:hover{
        background: var(--buttonHover);
    }
    
    .clientDetails {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 2rem;
        margin-right: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
        grid-gap: 1rem;
        gap: 1rem;
        max-height: 50rem;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding-bottom: 1rem;
        padding-top: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .clientDetails2 {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 2rem;
        margin-right: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 50rem;
        border: 1px solid lightgrey;
        border-radius: 5px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    
.margin {
        margin: 50px !important;
        font-size: 2rem;
}

    .clientDetailsStatusClient {
        display: flex;
        flex-direction: row;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0.5rem;
    }

    .clientDetailsStatusClient a {
        font-size: 1rem;
        font-weight: 400;
    }
    
    #clientDetailsEditButton {
        margin-bottom: 1rem;
        width: 100%;
    }
    
    .clientDetails label {
        height: 2rem;   
        width: 100%;
    }
    
    .clientsBoxHideFields {
        display: none;
    }
    .clientDetails #relative{
        position: relative;
    }
    
    .clientDetails div .dinamic{
        border: 0;
        border: 1px solid var(--formBorder);
        height: 2rem;
        outline: none;
        min-width: 13rem;
        width: calc(100% - 1rem);
        padding-left: 1rem;
        font-size: 1rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
        -webkit-appearance:none;
        border-radius: 0.3rem;
    }
      
    .clientDetails div .dinamic:focus{
        border: 1px solid #c39e16
    }
      
    .clientDetails div .dinamic::-webkit-input-placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .clientDetails div .dinamic::placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .clientDetails div #labelDinamic{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
    }
      
    .clientDetails div .dinamic:required:invalid + #labelDinamic{
        color: red;
    }
    .clientDetails div .dinamic:focus:required:invalid{
        border-bottom: 2px solid red;
    }
    .clientDetails div .dinamic:required:invalid + #labelDinamic:before{
        content: '*';
    }
    .clientDetails div .dinamic:focus + #labelDinamic,
    .clientDetails div .dinamic:not(:placeholder-shown) + #labelDinamic{
        font-size: 0.7rem;
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        width: auto;
        height: auto;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        color: #3C3C3C;
        background-color: white;
    }
    
    .clientDetailDualDetail {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 20%;
        display: grid;
        grid-template-columns: auto auto;
        flex-direction: column;
        grid-gap: 0.8rem;
        gap: 0.8rem;
        align-items: center;
        min-height: 2rem;
    }
    .clientDetailDualDetail2{
        width: calc(100% - 1rem);
        height: 20%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        align-items: center;
        min-height: 2rem;
        padding-top: 0.4rem;
        padding-right: 0.8rem;
    }

    .clientsInformationDiv {
    
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsDetailsEditFixedBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
    
    .clientsBoxShowFields {
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsBoxShowStatusField {
       width: 100%
    }
    
     #clientDetailFirstAElementVBA {
        overflow-x: hidden;
    }
    
    #clientDetailFirstAElementVBA{
        overflow-x: hidden;
        font-weight: 600;
    }

    .usersDetailAddBrokerOption {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        gap: 2rem;
        width: 100%;
    }

    .usersDetailAddBrokerOption label a {
        margin-bottom: 0.5rem;
    }

    .usersDetailStaticProfileDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #editClientBrokerDetail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(34, 34, 34, 0.294);
        background: white;
        height: 2.4rem;
        align-items: center;
        padding-left: 0.3rem;
        padding-right: 0.5rem;
        border-radius: 4px;
    }
    
    #editClientBrokerDetail span {
        display: flex;
        flex-direction: row;
        grid-gap:0.5rem;
        gap:0.5rem
    }
    #labelToEditClientBroker {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        font-size: 1rem;
    }

    #labelToEditClientBroker a {
        font-weight: 400;
    }

    #alignLabelForEditBroker {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #alignLabelForEditBroker a {
        margin-bottom: 0.6rem;
    }

    #labelToEditClientBroker svg:nth-child(2){
        padding-top: 2.1rem;
    }
    
    .usersDetailStaticProfileDescription label {
        font-weight: 400;
        height: 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
    }

    .usersDetailStaticProfileDescription a {
        font-size: 1rem;
        font-weight: 500;
        color: #777777;
    }

    .clientDetailsTableDetails {
        width: 100%;
        height: 100%;     
        
    }

    /* .clientDetailsTableDetailsGray {
        width: 100%;
        height: 100%;
        background: var(--headerAllBg);       
        
    } */
    
    .clientDetailsTableDetails table {
        margin-top: 2rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientDetailsTableDetails thead tr:first-child th{
        background: var(--tableSecondTHGray);
        font-weight: 400;
        height: 1.2rem;
        padding: 0.2rem;
        font-size: 1rem;
    }
    
    
    .clientDetailsTableDetails thead tr th{
        background: var(--tableSecondTh);
        color: white;
        font-weight: 300;
        padding: 0.2rem;
        font-size: 0.9rem;
    }
    
    .clientDetailsTableDetails tbody tr td {
        text-align: center;
        height: 1rem;
        padding: 0.15rem;
        border: 1px solid var(--tableBorder);
    }
    
}


.botaoSolicitarVBA{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSolicitarVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.botaoSolicitarExcecaoVBA{
    width: 25rem;
    height: 2rem;
    margin: auto;
    margin-bottom: max(auto,0.2rem);
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSolicitarExcecaoVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.botaoSolicitarExcecaoCancelVBA{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSolicitarExcecaoCancelVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}
    
.getClient{
    height: 3rem;
    }

.checkBoxVBA{
margin-right: 1rem;
width: 1.3rem;
height: 1.3rem;
vertical-align: sub;
}


.botaoEvasaoVBA{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* margin-left : 0.6rem; */
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoEvasaoVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.buttonShowFinishedForm{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    font-size: 1.2rem;
    border-radius: 3px;
    background-color: #cee4e0;
    color: #003a2f;
    transition: all ease-in-out 0.1s;
}
.buttonShowFinishedForm:hover{
    color: #f8fffe;
    background-color: #007761;
}

.selectClientDetails{
    width: 10rem;
    padding: 0.1rem;
    border: 1px solid #c49e17;
    font-weight: 500;
    border-radius: 3px;
    background-color: #c49e17;
    color: white;
    transition: all ease-in-out 0.1s;
}
.selectClientDetails:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}
.selectClientDetails:focus{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
    outline: none;
}

.selectClientDetailsButton{
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
}
.selectClientDetailsButton:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.selectClientDetailsButton:focus{
    outline: none;
}

.selectClientDetailsButtonDISABLED{
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    opacity: 0.8 !important;
}

.checkboxVBAExceptionClient{
    width : 1.3rem;
    height: 1.3rem;
    margin-bottom: 0.4rem;
    display: table-cell;
    vertical-align: middle;
    margin-right: 1rem;
}

.botaoSubmitExcecaoVBA{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.10rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSubmitExcecaoVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.defaultBroker{
    background-color: #c49e17;
    color: white;
    border-radius: 4px;
    padding: 0.2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.campoTextoAtivoAdm{
    background-color: white;
    font-size: 1.2rem;
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 4px;
    border: 1px solid #c49e17;
    margin: auto;
    padding: 0.2rem;
  }
  .campoTextoAtivoAdm:focus{
    border: 1px solid #ffc800;
    outline: none;
  }
  .campoTextoAtivoAdm:hover{
    border: 1px solid #ffc800;
    outline: none;
  }
.buttonSetToBase{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2rem;
    margin-bottom: max(auto,0.2rem);
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
    margin-top: 1rem;
}

.buttonSetToBase:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.buttonSetToBaseConfirm{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2rem;
    margin-bottom: max(auto,0.2rem);
    margin-left: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.2rem;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.buttonSetToBaseConfirm:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.itemButton{
    display: flex;
    justify-content: center;
}

.clientSetToBaseInformationsBox{
    width: 70%;
    height: 20%;
    display: grid;
    grid-template-columns: auto auto;
    flex-direction: column;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    align-items: center;
    min-height: 2rem;
}

.titleSetToBase {
    font-size: 1rem;
    margin-left: 1rem;
    font-weight: 600;
}

.clientSetToBaseBodycontent{
    display: grid;
    height: 36rem;
    margin-top: 1rem;
}

.clientDetailsBox {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.clientDetailsBox2 {
    display: grid;
    grid-template-columns: 2fr 7fr ;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.selectClientSetToBase{
    width: 25rem;
}

.titleSelectClient{
    margin-top: 1.0rem;
}

.subTitleSetToBase {
    font-weight: 500;
    font-size: 1.1rem;
    margin-left: 3.5rem;
    margin-top: 1rem;
}

.clientSetToBaseContainer{
    width: 60rem;
    height: 37rem;
    background: white;
    display: flex;
    flex-direction: column;
}

.containerCabecalhoVBACLIENTSACTIVATION{
  /* display: flex;
  justify-content: space-between; */
  /* min-height: 4rem; */
  display: inline-block;
  display: grid;
  grid-template-columns: 30vh auto 30vh;
  width: 100%;
  background-color: #d8e0e7;
  padding-top: 1rem;
  border-bottom: 1rem solid #d8e0e7;
}

.boxAnswersVBACLIENTSACTIVATION{
  max-height: 34rem;
  height: 34rem;
  overflow-y: auto;
}

.containerCabecalhoPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
}

.boxTituloPerfilVBACLIENTSACTIVATION{
  display: flex;
  margin-right: 5rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  right: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto
}

.backArrowVBACLIENTSACTIVATION{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 2rem;
}

.buttonOpenCloseSectionVBACLIENTSACTIVATION{
  right: 2rem;
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  color: rgb(0, 0, 0);
}

.buttonOpenCloseSection:hover VBACLIENTSACTIVATION{
  color: rgb(97, 97, 97);
  cursor: pointer;
}

.sectionTitleVBACLIENTSACTIVATION{
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
}

.questionDescriptionVBACLIENTSACTIVATION{
  margin-left: 2rem;
}

.checkboxVBACLIENTSACTIVATION{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.radioboxVBACLIENTSACTIVATION{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.gridtesteVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 4rem auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.opcoesDaPerguntaVBACLIENTSACTIVATION{
  display: grid;
}

.finishButtonVBACLIENTSACTIVATION{
  margin-top: 2rem;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.finishButton:hoverVBACLIENTSACTIVATION{
  background-color: #cecece;
  color: #141414;
}

.boxFinishVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #d8e0e7;
  height: 6rem;
}

.saveButtonVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto;
  width: 13rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  border: none;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.2rem;
  background: transparent;
  transition: all ease-in-out 0.1s;
}

.saveButton:hoverVBACLIENTSACTIVATION{
  background: #b8b8b8;
}

.saveProgressTitleVBACLIENTSACTIVATION{
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.backArrowVBACLIENTSACTIVATION{
  border: none;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 0.2rem;
  background: transparent;
  color: black;
  transition: all ease-in-out 0.1s;
}

.backArrow:hoverVBACLIENTSACTIVATION{
  background: #d4d4d4;
}

.containerSavingVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto;
}

.savingText VBACLIENTSACTIVATION{
  font-size: 1.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 2.4rem;
  color: #494949;
}

.containerDotsSavingVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 0.6rem;
}

.containerDotsSaving > div:nth-child(2) VBACLIENTSACTIVATION{
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.containerDotsSaving > div:nth-child(3) VBACLIENTSACTIVATION{
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.dotSavingVBACLIENTSACTIVATION{
  width: 0.2rem;
  height: 0.2rem;
  background-color: #242424;
  border-radius: 50%;
  margin: auto;
  margin-left: 0.4rem;
  -webkit-animation: dotAnimationSaving 0.6s infinite alternate;
          animation: dotAnimationSaving 0.6s infinite alternate;
}

@-webkit-keyframes dotAnimationSaving {
  to {
    opacity: 0;
  }
}

@keyframes dotAnimationSaving {
  to {
    opacity: 0;
  }
}

.savedTextVBACLIENTSACTIVATION{
  color: #186800;
  font-size: 1.6rem;
  margin: auto;
  margin-left: 2.4rem;
}

.observationVBACLIENTSACTIVATION{
  margin-left: 3rem;
}

.observationTextVBACLIENTSACTIVATION{
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 1.5rem;
}

.containerGeralCreateFormStructureVBACLIENTSACTIVATION{
  width: 100%;
  margin-left: auto;
  height: 34rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.containerSecoesPerguntasVBACLIENTSACTIVATION{
  overflow: auto;
  width: 98%;
  padding-left: 2%;
  max-height: calc(100vh - 18rem);
}

.containerTituloFormVBACLIENTSACTIVATION{
  display: grid;
  height: 5rem;
  padding-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.tituloTituloVBACLIENTSACTIVATION{
  font-size: 1.8rem;
  font-weight: 400;
  color: #11639E;
  text-align: center;
}

.inputTituloTituloVBACLIENTSACTIVATION{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.6rem;
  width: 14rem;
  padding: 0.2rem;
  text-align: center;
  margin: auto;
  margin-top: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloTitulo:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.tituloSecaoVBACLIENTSACTIVATION{
  font-size: 1.4rem;
  font-weight: 400;
  color: #11639E;
  margin-left: 0.4rem;
}

.inputTituloSecaoVBACLIENTSACTIVATION{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.2rem;
  width: 14rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-top: 0.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloSecao:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.perguntaVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  width: 92%;
  border-top: 2px solid #11639E;
  margin: auto;
  margin-top: 0.6rem;
  padding: 0.6rem;
  background: white;
  box-shadow: -1px 3px 6px gray;
}

.barraLateralVBACLIENTSACTIVATION{
  width: 0.2rem;
  height: 100%;
  background-color: #11639E;
  margin: auto;
  margin-right: 0.6rem;
}

.inputTituloPerguntaVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloPergunta:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.opcaoPerguntaVBACLIENTSACTIVATION{
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

.tituloOpcaoPerguntaVBACLIENTSACTIVATION{
  color: #11639E;
  margin-bottom: 0.1rem;
}

.selectTipoPerguntaVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.selectTipoPergunta:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tipoPerguntaTextoVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-top: 1rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaTexto:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerRadioOptionVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0.6rem;
}

.containerTipoPerguntaOpcaoMultiplaVBACLIENTSACTIVATION{
  margin-top: 1rem;
}

.inputOptionNameVBACLIENTSACTIVATION{
  border: none;
  border: 1px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  min-width: 4rem;
  width: -webkit-min-content;
  width: min-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.inputOptionName:focusVBACLIENTSACTIVATION{
  outline: none;
  border: 1px solid #11639E;
}

.addSubSectionVBACLIENTSACTIVATION{
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
  border: none;
  background: transparent;
  color: rgb(97, 97, 97);
  transition: all ease-in-out 0.1s;
  white-space: nowrap;
}

.addSubSection:hoverVBACLIENTSACTIVATION{
  color: black;
}

.addRadioOptionVBACLIENTSACTIVATION{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addRadioOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.deleteRadioOptionVBACLIENTSACTIVATION{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteRadioOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.botaoNovaPerguntaVBACLIENTSACTIVATION{
  width: 30%;
  margin-top: 0.8rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaPergunta:hoverVBACLIENTSACTIVATION{
  background-color: #064d80;
}

.botaoNovaSecaoVBACLIENTSACTIVATION{
  width: 20%;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaSecao:hoverVBACLIENTSACTIVATION{
  background-color: #064d80;
}

.habilitarObservacaoVBACLIENTSACTIVATION{
  font-weight: 500;
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
}

.containerTipoPerguntaPorcentagemVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 1fr auto;
  width: 20%;
}

.tipoPerguntaPorcentagemVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 1rem !important;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaPorcentagem:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.porcentagemVBACLIENTSACTIVATION{
  margin-top: auto;
  padding: 0.05rem;
  padding-left: 0.4rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.tipoPerguntaMonetarioVBACLIENTSACTIVATION{
  margin-top: 1rem !important;
  width: 20%;
}

.containerTipoPerguntaCaixaSelecaoVBACLIENTSACTIVATION{
  margin-top: 1rem;
}

.containerCheckBoxOptionVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0.6rem;
}

.addCheckBoxOptionVBACLIENTSACTIVATION{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addCheckBoxOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.deleteCheckBoxOptionVBACLIENTSACTIVATION{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteCheckBoxOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.containerCabecalhoPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
  max-height: 6rem;
}

.descricaoPortfolioVBACLIENTSACTIVATION{
  width: 90%;
  margin: auto;
  margin-top: 0.4rem;
  height: 2.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  box-shadow: -1px 3px 6px gray;
}

.descricaoPortfolio:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerDescricaoPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto 1fr;
}

.containerSelectTipoPortfolioVBACLIENTSACTIVATION{
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

.selectTipoPortfolioVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  margin-left: 1.2rem;
  height: 2.4rem;
}

.selectTipoPortfolio:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.inputNameFilterVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  height: 1.95rem;
}

.inputNameFilter:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.secaoVBACLIENTSACTIVATION{
  margin-top: 1rem;
}

.containerTagsVBACLIENTSACTIVATION{
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-left: 2px solid #11639E;
  padding-left: 0.6rem;
}

.inputNameTagVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.6rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputNameTag:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tagTitleVBACLIENTSACTIVATION{
  font-size: 1.5rem;
  font-weight: 400;
}

.addTagButtonVBACLIENTSACTIVATION{
  border: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  font-size: 1rem;
  background: #e6e6e6;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.addTagButton:hoverVBACLIENTSACTIVATION{
  background: #c9c9c9;
  cursor: pointer;
}

.containerInfoTagsVBACLIENTSACTIVATION{
  margin: auto;
}

.deleteTagButtonVBACLIENTSACTIVATION{
  border: none;
  border-right: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1rem;
  background: #c4e6fd;
  font-weight: 500;
}

.deleteTagButton:hoverVBACLIENTSACTIVATION{
  background: #a4c6dd;
  cursor: pointer;
}

.nomeTagVBACLIENTSACTIVATION{
  font-size: 1.2rem;
  background: #c4e6fd;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin: auto;
  border-left: none;
}

.containerTagVBACLIENTSACTIVATION{
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-template-columns: auto auto;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  border-radius: 10px;
}

.containerDisplayTagsVBACLIENTSACTIVATION{
  overflow: hidden;
  border: 1px solid #dadada;
  margin-left: 0.4rem;
  max-width: 10rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 4px;
}

.formTagsVBACLIENTSACTIVATION{
  display: flex;
}

.tituloSelectPortfolioVBACLIENTSACTIVATION{
  font-size: 1.8rem;
  color: #11639E;
  height: 3rem;
}

.containerHeaderSelectPortfolioVBACLIENTSACTIVATION{
  text-align: center;
  height: 8rem;
  padding-top: 2rem;
  background-color: #d8e0e7;
}

.containerPortfoliosExistentesVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 18rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow: auto;
}

.itemPortfolioAgressivoVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.itemPortfolioModeradoVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioModerado:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.itemPortfolioConservadorVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioConservador:hoverVBACLIENTSACTIVATION{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.itemPortfolioGeralVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioGeral:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #ebebeb;
  cursor: pointer;
}

.tituloPortfolioVBACLIENTSACTIVATION{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.separacaoItemPortfolioAgressivoVBACLIENTSACTIVATION{
  background-color: #ff0000;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioModeradoVBACLIENTSACTIVATION{
  background-color: #ffae00;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioConservadorVBACLIENTSACTIVATION{
  background-color: #00ccff;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioGeralVBACLIENTSACTIVATION{
  background-color: #ebebeb;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.divDescricaoVBACLIENTSACTIVATION{
  display: table;
}

.descricaoItemPortfolioVBACLIENTSACTIVATION{
  color: white;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.tipoPerfilPortfolioAgressivoVBACLIENTSACTIVATION{
  color: red;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioModeradoVBACLIENTSACTIVATION{
  color: #ffae00;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioConservadorVBACLIENTSACTIVATION{
  color: #00ccff;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioGeralVBACLIENTSACTIVATION{
  color: #ebebeb;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.containerOverlayPreviaPortfolioVBACLIENTSACTIVATION{
  position: absolute;
  width: 100%;
  height: calc(100% - 3rem);
  margin-top: 3rem;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 2fr 3fr;
  background-color: #00000063;
}

.overlayPreviaPortfolioVBACLIENTSACTIVATION{
  width: 100%;
  height: 100%;
}

.containerDetalhesPortfolioModeradoVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #302700;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioConservadorVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #00323f;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioGeralVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #3a3a3a;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioAgressivoVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #270000;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}


.containerHeaderDetalhesPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.closeDetailButtonVBACLIENTSACTIVATION{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.closeDetailButton:hoverVBACLIENTSACTIVATION{
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.titleHeaderDetailVBACLIENTSACTIVATION{
  color: white;
  font-size: 1.8rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  text-align: center;
}

.showInfoDetailVBACLIENTSACTIVATION{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.containerSubHeaderDetalhesPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 10rem auto 10rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.infoSubHeaderLVBACLIENTSACTIVATION{
  color: white;
  font-size: 1.4rem;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.infoSubHeaderRVBACLIENTSACTIVATION{
  color: white;
  font-size: 1.4rem;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.divisaoSubHeaderConservadorVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: #00ccff;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderGeralVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: #ebebeb;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderModeradoVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: #ffae00;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderAgressivoVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: red;
  margin-left: 2rem;
  margin-right: 2rem;
}

.containerSecoesPortfolioVBACLIENTSACTIVATION{
  width: 90%;
  margin-left: auto;
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.selectPortfolioAtualVBACLIENTSACTIVATION{
  margin-top: auto;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
}

.selectPortfolioAtual:hoverVBACLIENTSACTIVATION{
  background-color: #e6e6e6;
  color: #141414;
}

.secaoDetalhePortfolioConservadorVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioConservador:hoverVBACLIENTSACTIVATION{
  color: #00ccff;
  cursor: pointer;
}

.secaoDetalhePortfolioGeralVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioGeral:hoverVBACLIENTSACTIVATION{
  color: #ebebeb;
  cursor: pointer;
}

.secaoDetalhePortfolioModeradoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioModerado:hoverVBACLIENTSACTIVATION{
  color: #ffae00;
  cursor: pointer;
}

.secaoDetalhePortfolioAgressivoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  color: #ff0000;
  cursor: pointer;
}

.perguntaDetalhePortfolioConservadorVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioConservador:hoverVBACLIENTSACTIVATION{
  color: #00ccff;
  cursor: default;
}

.perguntaDetalhePortfolioGeralVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioGeral:hoverVBACLIENTSACTIVATION{
  color: #ebebeb;
  cursor: default;
}

.perguntaDetalhePortfolioModeradoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioModerado:hoverVBACLIENTSACTIVATION{
  color: #ffae00;
  cursor: default;
}

.perguntaDetalhePortfolioAgressivoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  color: #ff0000;
  cursor: default;
}

.loaderAllFormsVBACLIENTSACTIVATION{
  margin-top: 5rem !important;
}

.loaderSpecificFormVBACLIENTSACTIVATION{
  margin-top: 42vh;
}

.resetFilterVBACLIENTSACTIVATION{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 2.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background: transparent;
  background-color: rgb(218, 218, 218);
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
  box-shadow: -1px 3px 6px gray;
}

.resetFilter:hoverVBACLIENTSACTIVATION{
  background-color: rgb(218, 218, 218);
  box-shadow: -1px 2px 2px gray;
}

.containerButtonCreateFormVBACLIENTSACTIVATION{
  display: grid;
  height: 6rem;
  margin-top: auto;
  background-color: #d8e0e7;
}

.createFormButtonVBACLIENTSACTIVATION{
  font-size: 2rem;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.createFormButton:hoverVBACLIENTSACTIVATION{
  background-color: #cecece;
  color: #141414;
}

.containerOverlayVBACLIENTSACTIVATION{
  position: absolute;
  background-color: #d8e0e7;
  width: 100%;
  height: calc(100vh - 3rem);
}

.containerOverlayVBACLIENTSACTIVATION{
  display: grid;
}

.containerEscolhaTituloVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto auto;
  width: 50%;
  margin: auto;
}

.tituloEscolhaTituloVBACLIENTSACTIVATION{
  font-size: 1.8rem;
  color: #11639E;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.inputReplyTitleVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 2rem;
  height: 1.95rem;
}

.inputReplyTitle:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.confirmReplyTitleVBACLIENTSACTIVATION{
  width: 30%;
  margin: auto;
  margin-top: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.confirmReplyTitle:hoverVBACLIENTSACTIVATION{
  background-color: #064d80;
}

.containerMeusPortfoliosExistentesVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 15rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow: auto;
}

.MeuItemPortfolioAgressivoVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.MeuItemPortfolioModeradoVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioModerado:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.MeuItemPortfolioConservadorVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioConservador:hoverVBACLIENTSACTIVATION{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.MeuItemPortfolioGeralVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioGeral:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #c2c1c1;
  cursor: pointer;
}

.tituloMeuPortfolioVBACLIENTSACTIVATION{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detalheMeuItemPortfolioVBACLIENTSACTIVATION{
  color: white;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nomeAssessorPortfolioConservadorVBACLIENTSACTIVATION{
  color: #001318;
  background-color: #33d6ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioModeradoVBACLIENTSACTIVATION{
  color: #1f1900;
  background-color: #ffb923;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioAgressivoVBACLIENTSACTIVATION{
  color: #160000;
  background-color: #ff2d2d;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioGeralVBACLIENTSACTIVATION{
  color: #141414;
  background-color: #c2c2c2;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.containerButtonCreateFormVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto 1fr;
}

.modoEdicaoTextVBACLIENTSACTIVATION{
  font-size: 1.6rem;
  font-weight: 500;
  height: auto;
  width: auto;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #188f00;
}

.containerSendFormClientActivationVBACLIENTSACTIVATION{
  height: 6rem;
  background-color: #d6d5d580;
  border-top: 1px solid #f3e3a9;
  display: grid;
  grid-template-columns: 1fr 1fr
}

.activateClientButtonVBACLIENTSACTIVATION{
  font-size: 1.4rem;
  font-weight: 400;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  margin-right: 2rem;
  color: #c49e17;
  border: 2px solid #c49e17;
  background-color: white;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
}
.activateClientButtonVBACLIENTSACTIVATION:hover{
  color: #fff;
  border: 2px solid #c49e17;
  background-color: #c49e17;
  box-shadow: 0px 0px 10px #c49e17;
}

.activateClientButtonVBACLIENTSACTIVATIONINACTIVE{
  font-size: 1.4rem;
  font-weight: 400;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  margin-right: 2rem;
  color: #c49e17;
  border: 2px solid #c49e17;
  background-color: white;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  opacity: 0.4;
  cursor: default;
}

.obsVBACLIENTSACTIVATION{
  width: 60%;
  margin: auto;
  margin-left: 2rem;
  font-size: 1.1rem;
}

.containerShowFinishedFormVBACLIENTSACTIVATION{
  height: 6rem;
  width: 100%;
  background-color: #d6d5d580;
  border-top: 1px solid #f3e3a9;
  display: grid;
}

.dateFinishedFormVBACLIENTSACTIVATION{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1.2rem;
}

.containerWarningEmptyStrutureJsonVBACLIENTSACTIVATION{
  width: 100%;
  text-align: center;
  margin-top: 1.4rem;
  font-size: 1.2rem;
}

.clientStandByContainer {
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -20rem;
  top: 50%;
  margin-top: -10rem;
  z-index: 250;
  box-shadow: 0px 0px 0.5rem 1px var(--goldColor);
  width: 40rem;
  height: 20rem;
  background: white;
  display: flex;
  flex-direction: column;

}

.standByStatusBody{
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: grid;
  grid-template-rows: auto 7rem;
}

.messageAlertVba{
  font-weight: 400;
  font-size: 1.1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.buttonsStandByAlert{
  margin: auto;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.modalBackgroundShow2{
  width: 100%;
  height: 100%;
  background: rgb(0,0,0,0.5);
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 241;
}
.brokersTableContainer{
    height: 30rem;
    width: 60rem;
    background: white;
    display: flex;
    flex-direction: column;
}

.brokersTableLoader{
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.brokersTableHead {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background: var(--headerAllBg);
}

.brokersTableHead p {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--goldColor);
}

.brokersTableHead div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.brokersTableHead div p:last-child{
    font-weight: 500;
}

.brokersTableHead button {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.brokersTableHead button:hover{
    background-color: var(--buttonBG);
}

.brokersTableDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(100% - 10rem);
}

.brokersTableBody {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-height: 20rem !important; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.brokersTableBody table {
    height: auto !important;
    
}

.brokersTableBody thead tr th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--tableSecondTHGray);
    color: white;
    font-weight: 300;
    font-size: 1.2rem !important;
}

.brokersTableBody tbody tr {
    height: 2rem !important;
}

.brokersTableBody tbody td {
    font-size: 1.2rem;
    text-align: center;
    height: 2rem !important;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
}

.brokersTableDiv button {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.brokersTableDiv button:hover {
    background: var(--buttonBG);
}

@media (max-width:1368px){
    .brokersTableContainer{
        height: 30rem;
        width: 60rem;
        background: white;
        display: flex;
        flex-direction: column;
    }
    
    .brokersTableLoader{
        height: 100%;
        width: 100%;
        display: flex;
        font-size: 1.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .brokersTableHead {
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 4rem;
        background: var(--headerAllBg);
    }
    
    .brokersTableHead p {
        border-bottom: 1px solid var(--goldColor);
    }

    .brokersTableHead div p {
        font-size: 1.1rem !important;
    }
    
    .brokersTableHead div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    
    .brokersTableHead div p:last-child{
        font-weight: 500;
    }
    
    .brokersTableHead button {
        outline: none;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1.2rem;
        padding: 0.5rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        border-radius: 50%;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    .brokersTableHead button:hover{
        background-color: var(--buttonBG);
    }
    
    .brokersTableDiv {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: calc(100% - 10rem);
    }
    
    .brokersTableBody {
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        max-height: 20rem !important; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .brokersTableBody table {
        height: auto !important;
        
    }
    
    .brokersTableBody thead tr th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--tableSecondTHGray);
        color: white;
        font-weight: 300;
        font-size: 1rem !important;
    }
    
    .brokersTableBody tbody tr {
        height: 1.8rem !important;
    }
    
    .brokersTableBody tbody td {
        font-size: 1rem;
        text-align: center;
        height: 1.8rem !important;
        border-bottom: 1px solid var(--tableBorder);
        border-right: 1px solid var(--tableBorder);
    }
    
    .brokersTableDiv button {
        outline: none;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem;
        width: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    .brokersTableDiv button:hover {
        background: var(--buttonBG);
    }
}
.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

#clientsImportButtons {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#tableForImportReccords tbody td,
#tableForImportReccords thead th {
    white-space: nowrap;

}

#importClientsProgressBarContainer {

    width: 100%;
    height: 1.5rem;
    border: 1px solid rgb(190, 190, 190);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#progressBarStatus {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(rgb(2, 148, 51), rgb(32, 187, 83));
    color: white;
    font-size: 1rem;
    font-weight: bold;
    transition: 0.4s;
}

#progressBarStatus a {
    position: absolute;
    margin-right: 0.5rem;
}

.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

#clientsImportButtons {
    display: flex;
    flex-direction: column;
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

.fileInput #getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #stepperRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#stepperRowDiv a{
    font-size: 1.8rem !important;
}


.tableErrorsImport { 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImport thead{ 

    color: white;
}

.tableErrorsImport table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    white-space: nowrap;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImport thead tr {
  
    height: 1.5rem;
}

.tableErrorsImport tbody tr{ 
    height: 2rem;
}

.tableErrorsImport tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    white-space: nowrap;
    padding-left: 0.5rem;
}

.tableForImportSectionClients {
    max-width: 90rem;
    max-height: 30rem;
    overflow: auto; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSectionClients table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSectionClients table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSectionClients table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableErrorsImport table thead tr th:first-child{
    width: 10rem;
}

.tableErrorsImport table thead tr th:nth-child(2){
    width: 10rem;
}


.tableForImportSectionClients table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

@media(max-width: 1368px){

    #clientsImportButtons {
        display: flex;
        flex-direction: row;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 35rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #stepperRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #stepperRowDiv a{
        font-size: 1.6rem !important;
    }
    

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }
    
    .tableForImportSectionClients {
        max-width: 75rem;
        max-height: 20rem;
        overflow: auto; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSectionClients table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSectionClients table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSectionClients table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        white-space: nowrap;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImport table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImport table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSectionClients table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}

.clientsBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    /* width: 95%; */
}

.clientsContainer {
    display: flex;
    flex-direction: column;
    height:100%;   
}

.clientsHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--headerAllBg);
    width: 100%;
    height:6rem;
    color: var(--headerFontColor);
    font-size: 1.3rem;
}

.clientsHeader p{
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
}

.clientsBlockedArea {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    background: rgba(73, 73, 73, 0.4);
    position: absolute;
}

.clientsModalButton {
    background: var(--headerAllBg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;
}

.clientsModalButton button {
    right:0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    background: var(--formBorder);
    color: white;
}




.clientsButtons {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
#structuredLoader {
    height: 20rem;
}

#myStructuredNotifyBox {
    position: absolute;
    bottom:55px;
    right: 30px;
    background: var(--goldColor);
    height: 1.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.5rem;
    font-weight: 500;
    transition: 0.5s ;
    border-radius: 0 1rem 0 1rem;
    -webkit-animation: notify 1s ease;
            animation: notify 1s ease;
    z-index: 100;
    box-shadow: 0px 7px 20px -7px #093459;

}

#myStructuredNotifyBox p {
    color: rgb(49, 49, 49);
}

#myStructuredNotifyBox button {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background: #f0f6fb;
    border:none;
}

@-webkit-keyframes notify {

    0% {
        opacity: 0;
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    
}

@keyframes notify {

    0% {
        opacity: 0;
        -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    
}

.myStructuredTableArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.myStructuredTableContainer {
 display: flex;
 flex-direction: column;
 align-items: center;
 background: rgb(255, 255, 255);
 width: 96%;
 border-radius: 0.5rem 0 0 0;
 margin-top: 1rem;
 overflow-x: hidden; 
 box-shadow: -2px 2px 20px -7px #000000;

}

.mainTableContainer thead tr th{
    
    -webkit-animation: scroll .5s ease-out;
    
            animation: scroll .5s ease-out;
    transition: 0.2s;
}

.myStructuredLeftDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 6rem;
    gap: 6rem
}

.myStruturedTheadDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.myStruturedTheadDiv p {
    color: white;
    margin-left: 1rem;
}

.myStruturedTheadDiv div:first-child {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.myStruturedTheadDiv div:first-child a {
    font-size: 1rem;
}

.hideClearOptionButton, .showClearOptionButton {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: white;
    background: rgb(105, 105, 105);
    width: 1rem;
    border-radius: 5px;
    border: none;
}

.showClearOptionButton {
   opacity: 1;
   cursor: pointer;
   transition: 0.2s;
}

.showClearOptionButton:hover {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.hideClearOptionButton { 
    opacity: 0;
    display: none;
}

.myStruturedTheadRightGroup {
    margin-right: 1rem;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: row;
    justify-content: center;
}

.myStructuredSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    max-width: 8rem;
}

.mainTableContainer thead tr:first-child th{
    background: var(--topBarBg);
    height: 5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    overflow: hidden;
    color: white;
    font-size: 1.28rem;
    font-weight: 300;
    transition: 0.2s;
    z-index: 10;
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;  
}

.myStructuredMultipleOtions {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
    color: var(--goldColor);
}

.myStructuredMultipleOtions p {
    font-size: 1.1rem;
    color: var(--goldColor)
}

#blankSpace {
    background: var(--topBarBg);
    height: 1.8rem;
    width: 8rem;
}

.buttonTableTheadMyStructured {
    border-radius: 4px;
    font-weight: 400;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    background-color: var(--topBarBg);
    font-size: 1rem;
    width: 8rem;
    height: 2rem;
    transition: 0.2s;
    color: var(--formButtonFontColor)
}

.buttonTableTheadMyStructuredExcelButton {
    border-radius: 4px;
    font-weight: 400;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    background-color: var(--topBarBg);
    font-size: 1rem;
    padding-left:0.2rem;
    padding-right:0.2rem;
    height: 2rem;
    transition: 0.2s;
    color: var(--formButtonFontColor)
}

.buttonTableTheadMyStructuredExcelButton:hover{
    background: var(--goldColor);
    color: black;
}

.tableCheckBox {
    cursor: pointer;
}

.buttonTableTheadMyStructured:hover {
    background: var(--goldColor);
    color: black;
}

#myStructuredTheadFirstTh {
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

#myStructuredTheadFirstTh a {
    font-size: 1rem;
}

#myStructuredMultipleActions {
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid var(--goldColor);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
}

#myStructuredMultipleActions:hover {
    background: var(--goldColor);
    color: black
}

#myStruturedTheadDateSelection {
    display:  flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
}

#myStruturedTheadDateSelection .myStructuredTheadDateSelectionPeriodsLoader {
    width: 15rem;
}

@-webkit-keyframes scroll {
    from {
      -webkit-transform: tranlateY(-100%);
              transform: tranlateY(-100%);
    }
  
    to {
       -webkit-transform: tranlateY(0%);
               transform: tranlateY(0%);
    }
  }

@keyframes scroll {
    from {
      -webkit-transform: tranlateY(-100%);
              transform: tranlateY(-100%);
    }
  
    to {
       -webkit-transform: tranlateY(0%);
               transform: tranlateY(0%);
    }
  }

.mainTableContainer thead tr:nth-child(2) th{
    background: #3E3E3E;
    height: 1.8rem;
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    top: 5rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    z-index: 10;
    transition: 0.9s;
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
}

.mainTableContainer tbody {
    overflow: auto;
}

.mainTableContainer td:first-child {
    width: 1rem;
}

.mainTableContainer td:nth-child(2) {
    width: 2rem;
    cursor:  pointer !important;
    color: rgb(97, 97, 97);
    font-size: 1rem;
}

/* .mainTableContainer td:nth-child(8) {
    width: 1rem !important;
    cursor:  pointer !important;
    font-size: 1rem;
} */


.mainTableContainer td:nth-child(2):hover {
    font-size: 1.1rem;
}


.mainTableContainer tr:hover td{
    background: var(--tableHover);
}


.mainTableContainer td{
    border: 1px solid var(--tableBorder);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: default !important;
    background: white;
    max-width: 5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    height: 2rem;
    font-size: 1rem;
    
}
.mainTableContainer tr{
    transition: 0.1s;
}


.mainTableContainer tr:hover{
    background: var(--tableHover) !important;

}

.mainTableContainer tr:nth-child(even){
    background: var(--tableBorderEven)
}


.myStructuredDetails label {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.myStructuredDetails label a:first-child{
    font-weight: 400;
}

#myStructuredDetailsTable {
    margin-top: 1.5rem;
    
}

#myStructuredDetailsRow {
    transition: all ease 0.2s;
}

.myStructuredDetails { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 1.2rem;
    align-items: center;
    background: #3e3e3e;
    height: 3rem;
    transition: 0.2s;
    margin: 0 auto;
}

.myStructuredDetails a {
    color: white;
}

.myStructuredDetails a:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
}


.myStructuredDetails:hover {
    background: #444444;
}

@media(max-width: 1368px){
    #myStructuredNotifyBox {
        position: absolute;
        bottom: 60px;
        right: 20px;
        background: var(--goldColor);
        height: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        justify-content: center;
        padding: 0.3rem;
        font-weight: 500;
        transition: 0.5s ;
        border-radius: 0 1rem 0 1rem;
        -webkit-animation: notify 1s ease;
                animation: notify 1s ease;
        z-index: 100;
        box-shadow: 0px 7px 20px -7px #093459;
    
    }
    
    #myStructuredNotifyBox p {
        font-size: 0.9rem;
        color: rgb(49, 49, 49);
    }
    
    #myStructuredNotifyBox button {
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
        width: 0.9rem;
        height: 0.9rem;
        background: #f0f6fb;
        border:none;
    }

    .mainTableContainer th {
        font-size: 0.9rem;
    }

    .mainTableContainer td {
        font-size: 0.9rem;
    }

    .myStructuredSelectionFilter {
        background: var(--tableSecondTh);
        color: white;
        font-size: 0.8rem;
        max-width: 6rem;
    }
    
}

.loadingWOTxt{
    display:inline-block;
    position:relative;
    vertical-align:middle;
    width:  1rem;
    height: 1rem;
    border:0.1rem solid transparent;
    border-top-color:var(--goldColor);
    border-bottom-color:var(--goldColor);
    border-radius:50%;
    -webkit-animation: rotate 3s linear infinite, textIn 2s linear infinite;
            animation: rotate 3s linear infinite, textIn 2s linear infinite;
}


.loadingGoldWOTxt {
  display:inline-block;
  position:relative;
  vertical-align:middle;
  width:  1rem;
  height: 1rem;
  border:0.1rem solid transparent;
  border-top-color: var(--goldColor);
  border-bottom-color:var(--goldColor);
  border-radius:50%;
  -webkit-animation: rotate 3s linear infinite, textIn 2s linear infinite;
          animation: rotate 3s linear infinite, textIn 2s linear infinite;

}
  
  @-webkit-keyframes rotate{
     to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }
  
  @keyframes rotate{
     to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }

  @-webkit-keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .container {
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content:center
  }

  .container span {
      margin-bottom: 0.5rem;
  }

  .text {
    font-size: 1.5rem;
    color:rgb(122, 122, 122)
  }

  .textGold {
    font-size: 1rem;
    font-weight: 200;
    color:rgb(204, 204, 204)
  }
.myStructuredMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    transition: .2s;
}

.myScructuredTableArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.MuiAutocomplete-inputFocused{
    background: transparent !important;
}
.myStructuredEditContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   background: white;
}

.myScructuredEditHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    background: var(--headerAllBg);
}

.myScructuredEditHead  p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.myScructuredEditHead  button {
    margin-right: 1rem;
}

.myStructuredEditBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
}

.mySctructuredDefaultView {
    display: flex;
    flex-direction: column;
    height: 80%;
}

.myStructuredMergedLabel {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1rem;
    gap: 1rem;
}

.myStructuredMergedLabel label {
    font-size: 1rem;
    border: 1px solid rgb(189, 189, 189);
    height: 2.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    border-radius: 0.3rem;
}

.myStructuredMergedLabel label a:first-child {
    font-weight: 500;
    margin-left: 0.5rem;
}

.tableBookArea {
    margin-top: 2rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

#tableBookAreaFirstDiv {

    height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.tableBookArea thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableBookArea thead tr:nth-child(2) th{
    background: var(--tableSecondTh);
    color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
    z-index: 100;
    font-size: 1rem;
}

#tableBookAreaFirstDiv p{
    font-size: 1.1rem;
    color: white;
}

.tableBookArea tr td{
    border: 1px solid var(--tableBorder);
    text-align: center;
    font-size: 0.9rem;
    height: 1.8rem;
    cursor: default;
}

.myStructuredEditLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.assetClassCadastrationContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetClassCadastrationLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.assetClassCadastrationHeader {
    width: 100%;
    height: 3rem;
    background: var(--headerAllBg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.assetClassCadastrationHeader p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.assetClassCadastrationHeader button {
    margin-right: 1rem;
}

.assetCadastrationBody {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.assetCadastrationBodyDescription  p{ 
    font-size: 1rem;
}

.assetCadastrationSelectClass {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;

}

.assetCadastrationSelectClass a {
    font-size: 1rem;
}

.assetCadastrationSelectClass label:first-child {
    width: 20rem;
}

.assetClassCadastrationTypeButton {
    width: 2rem;
    height: 2rem;
    outline: none;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.411);
    font-size: 1.5rem;
    transition: all ease 0.2s;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.assetClassCadastrationTypeButton:focus {
    border: 2px solid var(--goldColor);
}
.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

.fileInput #getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #stepperRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#stepperRowDiv a{
    font-size: 1.8rem !important;
}


.tableErrorsImport { 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImport thead{ 

    color: white;
}

.tableErrorsImport table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImport thead tr {
  
    height: 1.5rem;
}

.tableErrorsImport tbody tr{ 
    height: 2rem;
}

.tableErrorsImport tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.tableForImportSection {
    max-width: 80rem;
    max-height: 30rem;
    overflow: auto; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSection table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSection table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSection table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableErrorsImport table thead tr th:first-child{
    width: 10rem;
}

.tableErrorsImport table thead tr th:nth-child(2){
    width: 10rem;
}


.tableForImportSection table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

@media(max-width: 1368px){

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 35rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #stepperRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #stepperRowDiv a{
        font-size: 1.6rem !important;
    }
    

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }
    
    .tableForImportSection {
        max-width: 80rem;
        max-height: 20rem;
        overflow: auto; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSection table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSection table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSection table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImport table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImport table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSection table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}

.tooltipSelect {
    padding: 0.5rem;
    margin: 0rem 0.2rem 0rem 0.2rem;
}

.tooltipSelectOptions {
    width: 10rem;
    height: 1.7rem;
    padding: 0.1rem;
    border: 1px solid #c49e17;
    font-weight: 500;
    border-radius: 3px;
    background-color: #c49e17;
    color: white;
    transition: all ease-in-out 0.1s;
}

.tooltipSelectOptions:hover {
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.tooltipSelectOptions:focus {
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
    outline: none;
}

.tooltipButton {
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.tooltipButtonDelete{
    background-color: #ec7a7a;
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.4rem;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.tooltipButtonDelete:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.tooltipButtonDelete:focus{
    outline: none;
}

.tooltipButton:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.tooltipButton:focus{
    outline: none;
}

.boxUpdateStatusOffer{
}

.boxDeleteOffer{
    margin-right: 2rem;
}

.boxGeralToolbarMenu{
    display: grid;
    grid-template-columns: 40rem auto;
}
.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
   }

.react-calendar__tile--active {
    background: #C49E17;
}


   .react-calendar__navigation button {
    color: #c49e17;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #c49e17;
    border-radius: 6px;
   }
   .react-calendar__tile--now {
    background: #c49f1736;
    border-radius: 6px;
    font-weight: bold;
    color: #c49e17;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: #c49f1736;
    border-radius: 6px;
    font-weight: bold;
    color: #c49e17;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
   }
   .react-calendar__tile--active {
    background: #c49e17;
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: #C49E17;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
    background: #f8f8fa;
    color: #c49e17;
    border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #c49e17;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #c49f1736;
    color: #c49e17;
   }
.brokerPayrollTable {
    display: flex;  
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.brokerPayrollTableArea {
    margin-top: 1rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    overflow-x: auto;
    width: 95%;
}

.brokerPayrollTableLoader {
    height: 20rem;
}

.brokerPayrollTable thead tr:first-child th{
    border-radius: 0.5rem 0 0;
}

.brokerPayrollTable thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg) !important;
    z-index: 99;
}

.brokerPayrollTable thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 3.9rem;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
}

.brokerPayrollTable table thead tr th div {
    display: flex;
    flex: row 1;
    align-items: center;
    justify-content: space-between;
}

.brokerPayrollTable table thead tr th div p{
    font-size: 1.2rem;
    color: white;
    font-weight: 300;
    padding-left: 1rem;
}

.payrollHeaderRight {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.brokerPayrollTable tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
    white-space: nowrap;
}

.brokerPayrollTable tbody tr:hover td {
    background: var(--tableHover);
}

.containerTabelaExtratoMensal tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
    white-space: nowrap;
}

.containerTabelaExtratoMensal tbody tr:hover td {
    background: var(--tableHover);
}

tbody{
    overflow: auto;
}

.containerTabelaExtratoMensal{
    overflow: auto;

}

.containerTabelaExtratoMensal table tr th{
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    border-radius: 0px !important;
    white-space: nowrap;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    align-items: center;
}

.containerTabelaExtratoMensal td{
    padding-left: 1rem;
    padding-right: 1rem;
}

.buttonsBrokerPayroll > * {
    margin-right: 1rem;
}

.tdSum{
    background: #c0c0c0 !important;
    border-left: 1px solid #c0c0c0 !important;
    border-right: 1px solid #c0c0c0 !important;
    border-top: 1px solid #c0c0c0 !important;
}

.tdSumEmpty{
    background: #d1d1d1 !important;
    border-left: 1px solid #d1d1d1 !important;
    border-right: 1px solid #d1d1d1 !important;
    border-top: 1px solid #d1d1d1 !important;
}

.unsuitedStructuredContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}


.estructuredsBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    /* height: 100%; */
}

.estructuredsTabOption {
    color: white;
    background: var(--topBarBg);
    height: 2rem;
    width: 15rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.estructuredsTabOption div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.estructuredsInactiveTabOption:hover {
    color: white;
}

.estructuredsInactiveTabOption {
    color: white;
    background: var(--topBarBg);
    color: rgba(255, 255, 255, 0.534);
    height: 2rem;
    width: 15rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.estructuredsTab {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

/* .tss-1qtl85h-MUIDataTableBodyCell-root{
    text-align: center !important;
} */

/* .css-1w1rijm-MuiButtonBase-root-MuiButton-root{ */
    /* width: 100% !important;
    max-height: 4rem !important;
    white-space: nowrap !important; */
    /* display: table-cell !important; */
    
/* } */

/* .tss-178gktx-MUIDataTableHeadCell-contentWrapper{
    text-align: center !important
} */
/* .css-1ygcj2i-MuiTableCell-root{
    padding: 0 !important;
} */

.usersTableContainer {
    width: 100%;
    overflow: auto;
    max-height: 45rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersTableNameAndTotalUsers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap:0.3rem;
    gap:0.3rem;
}

#usersTableNameAndTotalUsers a:first-child{
    font-size: 1.2rem;
    font-weight: 300;
}

#usersTableNameAndTotalUsers a:nth-child(2) {
    font-size: 0.9rem;
}

#userCadastration:focus{
    background: var(--goldColor);
    color: black;
}

.theadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.theadFirst a {
    margin-left: 2rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.theadFirst button{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}
.theadFirst button:hover {
    background: var(--formBorder);
    color: black;
}


.theadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.theadFirst label a{
    margin-right: 1rem;
    font-weight: 200;
}

.theadFirst label input {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.theadFirst label input::-webkit-input-placeholder{
    font-size: 0.9rem;
}

.theadFirst label input::placeholder{
    font-size: 0.9rem;
}

.usersTableContainer table{
    width: 100%;
    font-size: 1rem;
    
}

.usersTableContainer table tbody {
    max-height: 35rem;
    background: white;
}

.usersTableContainer table tbody tr {
    cursor: pointer;
}

.usersMyUser {
    font-weight: 400;
    
}

.usersActiveTrLoader {
        
    height: 30rem;
    
}   
.usersTableContainer table thead tr:first-child th{
    height: 5rem;
    border-left: none;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
    width: 90rem;
}


.usersTableContainer table thead tr:nth-child(2) > th  {
    height: 1.5rem;
    font-weight: 300 !important;
}

.usersTableContainer table thead tr:nth-child(2) th {
    background: var(--tableSecondTHGray);
    color: white;        
}


.usersTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
}

.usersTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 100;
}

.usersTableContainer table thead tr:first-child th{
    height: 4rem;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
}


.usersTableContainer table tbody tr {
    border-bottom: 1px solid var(--tableBorder);
}

.usersTableContainer table tbody tr:last-child{
    border-bottom: none;
}

.usersTableContainer table tbody td {
    padding: 0;
    height: 2.5rem;
    border-right: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    width: 10rem;
    font-size: 1rem;
}
.usersTableContainer table tbody td:nth-child(1){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(4){
    width: 5rem;
}


.usersTableContainer table tbody td:nth-child(6){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(7){
    width: 4rem;
}

.usersTableContainer table tbody td:nth-child(8){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(9){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(10){
    width: 2rem;
}


.usersTableContainer table tbody td:last-child{
    border-right: none;
}

.ellipsisTd {
    position: relative;
    max-width: 10rem;
    overflow: hidden;
    margin-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

.usersTableContainer table tbody td input{
  height: 1.5rem;
  padding: 0;
  padding-left: 0.3rem;
  width: 6rem;
  margin-right: 0.5rem;
}

.usersTableContainer table tbody td a {
    margin-right: 0.5rem;
}

.usersTableContainer table tbody tr:hover {
    background: var(--tableHover) !important;
}

.usersEditShow td {
   
    color: white;
}

.usersEditShow th {
    background: var(--tableSecondTh);

}

.usersEditShow .divForShowItemn {
    display: flex;
    width: 100%;
    height: 4rem;
}

.editDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.editDiv label {
    height: 2.5rem;
    width: auto;
}

.iconsOptions {
    width: 5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
}

.editIcon{
    width: 3rem;
}

.userInactiveAction {
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3)
}    


@media(max-width:1368px){
    
    .usersTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .theadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .theadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .theadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .theadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .theadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .theadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .theadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .theadFirst label input::-webkit-input-placeholder{
        font-size: 0.9rem;
    }

    .theadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersTableContainer table tbody {
        max-height: 35rem;
        background: white;
    }
    
    .usersTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersActiveTrLoader {
        
        height: 20rem;
        
    }

    .usersTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }    
    
    .usersTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }

    #usersHideLoadingScroll {
        display: none;
    }

    #usersShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        -webkit-filter: opacity(0.3);
                filter: opacity(0.3)
    }  
    
    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        -webkit-animation: lds-ellipsis1 0.6s infinite;
                animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        -webkit-animation: lds-ellipsis3 0.6s infinite;
                animation: lds-ellipsis3 0.6s infinite;
      }
      @-webkit-keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @-webkit-keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @-webkit-keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }

}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.tooltipSelect {
    padding: 0.5rem;
    margin: 0rem 0.2rem 0rem 0.2rem;
}

.tooltipSelectOptions {
    width: 10rem;
    height: 1.7rem;
    padding: 0.1rem;
    border: 1px solid #c49e17;
    font-weight: 500;
    border-radius: 3px;
    background-color: #c49e17;
    color: white;
    transition: all ease-in-out 0.1s;
}

.tooltipSelectOptions:hover {
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.tooltipSelectOptions:focus {
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
    outline: none;
}

.tooltipButton {
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.tooltipButtonDelete{
    background-color: #ec7a7a;
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.4rem;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
    transition: all ease-in-out 0.1s;
}

.tooltipButtonDelete:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.tooltipButtonDelete:focus{
    outline: none;
}

.tooltipButton:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.tooltipButton:focus{
    outline: none;
}

.boxUpdateStatusOffer{
}

.boxDeleteOffer{
    margin-right: 2rem;
}

.boxGeralToolbarMenu{
    display: grid;
    grid-template-columns: 40rem auto;
}
#recommendationsImportConfirm {
    font-size: 1.2rem;
    font-weight: 400;
}
.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

.fileInput a:first-child {
    font-size: 1rem;
    font-weight: 400;
}

#booksImportCheckInformation {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem
}

#booksImportCheckInformation a {
    font-size: 1rem;
    font-weight: 500;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

#getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #flexRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.fileInput #bookImportSelectEffectiveDate {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.fileInput #bookImportSelectEffectiveDate p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

/*Calendário*/
.fileInput .react-calendar {
    width: 40rem;
}

.fileInput .react-date-picker  {
    width: 16rem;
    display: flex;
    font-size: 1.2rem;
    height: 2.4rem;
    flex-direction: column;
    align-items: center; 
}

.fileInput .react-date-picker__wrapper {
    height: 2.4rem;
}

.fileInput .react-date-picker__calendar {
    width: 40rem;
}

.fileInput .react-date-picker__inputGroup__day {
    width: 22px;
}

.fileInput .react-calendar__navigation button {
    font-size: 1.1rem;
    font-weight: 400;
}

.fileInput .react-date-picker .react-date-picker--open .react-date-picker--enabled {
    display: flex;
    flex-direction: column;
}
.fileInput .react-date-picker__calendar--open {
    position:   -webkit-sticky !important;
    position:   sticky !important; 
    box-shadow: 0px 0px 18px -8px #000000;

}

.fileInput .react-calendar__tile--now {
    background: rgb(218, 218, 218);

}

.tableErrorsImportOperation { 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImportOperation thead{ 

    color: white;
}

.tableErrorsImportOperation table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImportOperation thead tr {
  
    height: 1.5rem;
}

.tableErrorsImportOperation tbody tr{ 
    height: 2rem;
}

.tableErrorsImportOperation tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.tableForImportSection {
    max-width: 80rem;
    max-height: 30rem;
    overflow: auto; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSection table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSection table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSection table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableForImportSection table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

@media(max-width: 1368px){

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 33rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #flexRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }

    .fileInput #bookImportSelectEffectiveDate {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 0;
    }
    
    .fileInput #bookImportSelectEffectiveDate p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    /*Calendário*/
.fileInput .react-calendar {
    width: 40rem;
}

.fileInput .react-date-picker  {
    width: 16rem;
    display: flex;
    font-size: 1.2rem;
    height: 1.4rem !important;
    flex-direction: column;
    align-items: center; 
}

.fileInput .react-date-picker__wrapper {
    height: 1.4rem;
}

.fileInput .react-date-picker__calendar {
    width: 40rem;
}

.fileInput .react-date-picker__inputGroup__day {
    width: 22px;
}

.fileInput .react-calendar__navigation button {
    font-size: 1.1rem;
    font-weight: 400;
}

.fileInput .react-date-picker .react-date-picker--open .react-date-picker--enabled {
    display: flex;
    flex-direction: column;
}
.fileInput .react-date-picker__calendar--open {
    position:   -webkit-sticky !important;
    position:   sticky !important; 
    box-shadow: 0px 0px 18px -8px #000000;

}

.fileInput .react-calendar__tile--now {
    background: rgb(218, 218, 218);

}

    
    .tableForImportSection {
        max-width: 80rem;
        max-height: 20rem;
        overflow: auto; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSection table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSection table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSection table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImportOperation table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImportOperation table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSection table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}

.containerSwitch{
    text-align: center;
    margin-top: 2rem;
    border-radius: 0.2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.activeOptionButton{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0.5rem;
    background-color: #ffc800;
    border: 1px solid #c49e17;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px #c49e17;
    transition: all ease-in-out 0.2s;
}

.inactiveOptionButton{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    transition: all ease-in-out 0.2s;
    border: 1px solid #e7ca61;
}
.inactiveOptionButton:hover{
    background-color: #f3c10a;
    border: 1px solid #c49e17;
    box-shadow: 0px 0px 10px #c49e17;
}


.switch{
    margin: auto;
}
.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
   }

.react-calendar__tile--active {
    background: #C49E17;
}


   .react-calendar__navigation button {
    color: #c49e17;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #c49e17;
    border-radius: 6px;
   }
   .react-calendar__tile--now {
    background: #c49f1736;
    border-radius: 6px;
    font-weight: bold;
    color: #c49e17;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: #c49f1736;
    border-radius: 6px;
    font-weight: bold;
    color: #c49e17;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
   }
   .react-calendar__tile--active {
    background: #c49e17;
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: #C49E17;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
    background: #f8f8fa;
    color: #c49e17;
    border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #c49e17;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #c49f1736;
    color: #c49e17;
   }
.myReservationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    transition: .2s;
}

.myReservationsContainer table { 
    box-shadow: 1px 3px 20px -9px #000000;
}
.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.buttonTableThead{
    height: auto !important;
}
.myPushOrdersTableMainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.myPushOrdersLoadingPeriods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.myPushOrdersSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    max-width: 8rem;
}

.monitoringTableExcelButton {
   display: none;
}

#myStructuredLoadingTd {
    height: 10rem;
}

.mainPushOrdersTableContainerOverflow {
    overflow-x: hidden;
    width: 95%;
    margin-top: 1rem; 
    box-shadow: 0px 6px 12px -9px #000000;
}

.mainPushOrdersTableContainer{
    border-collapse: collapse;
    max-height: 100%;
}

.mainPushOrdersTableContainer thead tr th{
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
    transition: 0.2s;
}

.myPushOrdersTheadDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.myPushOrdersTheadDiv p {
    color: white;
    margin-left: 1rem;
}

.myPushOrdersTheadRightGroup {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainPushOrdersTableContainer thead tr:first-child th{
    background: var(--topBarBg);
    height: 5rem;
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    border-radius: 0.5rem 0 0 0;
    top: 0px;
    color: white;
    font-size: 1.3rem;
    font-weight: 300;
    transition: 0.2s;
    z-index: 10;
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
    
}


#myPushOrdersMultipleActions {
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
    border: 1px solid var(--goldColor);
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
}

#myPushOrdersMultipleActions:hover {
    background: var(--goldColor);
    color: black;
}

.myPushOrdersMultipleActionsDiv {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 1.1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: var(--goldColor);
}

.myPushOrdersMultipleActionsDiv p {
    color: var(--goldColor);
    margin-left: 0;
}

#myPushOrdersMultipleActions div {
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
}

#myPushOrdersTheadDateSelection {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.myPushOrdersSelectionAll {
    display: flex;
    flex-direction: row;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes scroll {
    from {
      -webkit-transform: tranlateY(-100%);
              transform: tranlateY(-100%);
    }
  
    to {
       -webkit-transform: tranlateY(0%);
               transform: tranlateY(0%);
    }
  }

@keyframes scroll {
    from {
      -webkit-transform: tranlateY(-100%);
              transform: tranlateY(-100%);
    }
  
    to {
       -webkit-transform: tranlateY(0%);
               transform: tranlateY(0%);
    }
  }

.mainPushOrdersTableContainer thead tr:nth-child(2) th{
    background: #3E3E3E;
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    margin-top: 1rem;
    top: 5rem;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    z-index: 10;
    transition: 0.9s;
    -webkit-animation: scroll .5s ease-out;
            animation: scroll .5s ease-out;
}

.mainPushOrdersTableContainer tbody {
    overflow: auto;
}

.mainPushOrdersTableContainer td:nth-child(1){
    
    width: 1rem !important;

}

.mainPushOrdersTableContainer td:nth-child(3){
    
    max-width: 8rem !important;

}

.mainPushOrdersTableContainer td:nth-child(7){
    
    max-width: 4rem !important;

}

.mainPushOrdersTableContainer td:nth-child(9){
    
    max-width: 9rem !important;

}



.mainPushOrdersTableContainer td{
    border: 1px solid var(--tableBorder);
    text-align: center;
    text-overflow: ellipsis;
    cursor: default !important;
    overflow: hidden;
    white-space: nowrap;
    /* max-width: 11rem; */
    height: 2rem;
    font-size: 1rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.mainPushOrdersTableContainer tr{
    transition: 0.1s;
}

.mainPushOrdersTableContainer tr {
    background: #FFF;
}

.mainPushOrdersTableContainer tr:hover{
    background: var(--tableHover) !important;

}

.myPushOrderDetails label {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.myPushOrderDetails label a:first-child{
    font-weight: 400;
}

#myPushOrderDetailsTable {
    margin-top: 1.5rem;
    
}

#myPushOrderDetailsRow {
    transition: all ease 0.2s;
}

.myPushOrderDetails { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.2rem;
    align-items: center;
    width: 100%;
    background: #3e3e3e;
    height: 3rem;
    transition: 0.2s;
}

.myPushOrderDetails a {
    color: white;
}


.myPushOrderDetails:hover {
    background: #444444;
}


#myStructuredNotifyBox {
    position: absolute;
    bottom: 60px;
    right: 20px;
    background: var(--goldColor);
    height: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.3rem;
    font-weight: 500;
    transition: 0.5s ;
    border-radius: 0 1rem 0 1rem;
    -webkit-animation: notify 1s ease;
            animation: notify 1s ease;
    z-index: 100;
    box-shadow: 0px 7px 20px -7px #093459;

}

#myStructuredNotifyBox p {
    font-size: 0.9rem;
    color: rgb(49, 49, 49);
}

#myStructuredNotifyBox button {
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    width: 0.9rem;
    height: 0.9rem;
    background: #f0f6fb;
    border:none;
}

@media(max-width:1366px){
    .myPushOrdersSelectionFilter {
        background: var(--tableSecondTh);
        color: white;
        font-size: 0.8rem;
        max-width: 6rem;
    }

    .mainPushOrdersTableContainer td{
        border: 1px solid var(--tableBorder);
        text-align: center;
        text-overflow: ellipsis;
        cursor: default !important;
        overflow: hidden;
        white-space: nowrap;
        /* max-width: 11rem; */
        height: 2rem;
        font-size:0.9rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }
}
.myPushOrderErrorsTable {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.myPushOrderErrorsTable table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
    color: white;
    font-size: 1.2rem;
    font-size: 300;
    height: 3rem;
}

.myPushOrderErrorsTable table thead th div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.myPushOrderErrorsTable table thead th div button {
    margin-right: 1rem;
}

.myPushOrderErrorsTable table thead th div p {
    margin-left: 1rem;
}

.myPushOrderErrorsTable table tbody td {
    background: white;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
} 
.myPushOrdersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    transition: .2s;
}

.myPushOrdersContainer table { 
    box-shadow: 1px 3px 20px -9px #000000;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.usersCadastrationContainer{
    display: grid;
    grid-template-rows: 10% 90%;
    overflow-y: auto;
    background: white;
    width: 60rem;
    height: 40rem;
}

#errorField {
    -webkit-filter: hue-rotate(325deg);
            filter: hue-rotate(325deg);
    background: rgba(151, 151, 151, 0.144);
}

#cliensCadastrationSendButton:focus {
    border: 2px solid var(--formBorder);
    font-weight: 400;
}

#userCadastrationFormDiv {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    width: 100%;
    justify-content: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
}

.usersCadastrationBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: calc(100% - 5rem);
}

.usersCadastrationBody form{
    
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.usersCadastrationBody form label {
    width: 100%;
}


.userCadastrationHeader {
    top: 0;
    padding: 1rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    background: var(--headerAllBg);
}

.userCadastrationHeader p {
    border-bottom: 0.1rem solid var(--formBorder);
}

.userCadastrationHeader button {
    background: transparent;
    color: var(--formBorder);
    border: 0.1rem solid var(--formBorder);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1rem;
    background: white;
}

.usersCadastrationBrokerSelectionProfile {
    width: 100%;
}


.usersCadastrationAutocompleteHide {
    display: none;
}

@media(max-width:1368px){

    .usersCadastrationContainer{

        display: grid;
        grid-template-rows: 15% 85%;
        overflow-y: auto;
        height: 35rem;
        width: 55rem;
        background: white;
    }
   
    .usersCadastrationBody form label {
        width: 100%;
        display: flex;
        flex-direction: column;      
    }
    
}
   
    .usersInactiveTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 45rem;
        background: white; 
        box-shadow: -2px 2px 20px -7px #000000;
    }

    .usersInactiveEmptyList {
        height: 2rem;
        font-size: 1.2rem;

    }
    
    .usersInactiveTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .usersInactiveTheadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .usersInactiveTheadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .usersInactiveTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .usersInactiveTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .usersInactiveTheadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .usersInactiveTheadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .usersInactiveTheadFirst label input::-webkit-input-placeholder{
        font-size: 0.9rem;
    }

    .usersInactiveTheadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersInactiveTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersInactiveTableContainer table tbody {
        max-height: 35rem;
    }
    
    .usersInactiveTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersInactiveTrLoader {
        
        height: 30rem;
        
    }

    .usersInactiveTableContainer table thead tr:first-child th{
        height: 5rem;
        border-left: 1px solid var(--tableBorder);
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersInactiveTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersInactiveTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersInactiveTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersInactiveTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersInactiveTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersInactiveTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersInactiveTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersInactiveTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersInactiveTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersInactiveTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersInactiveTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersInactiveTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;
    }    
    
    .usersInactiveTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
        
    .usersEditShow td {
       
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        -webkit-filter: opacity(0.3);
                filter: opacity(0.3)
    }    


@media(max-width:1368px){
    
    .usersInactiveTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem;
        background: white; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .usersInactiveTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .usersInactiveTheadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .usersInactiveTheadFirst button{
        margin-left: 2rem;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .usersInactiveTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .usersInactiveTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .usersInactiveTheadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .usersInactiveTheadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .usersInactiveTheadFirst label input::-webkit-input-placeholder{
        font-size: 0.9rem;
    }

    .usersInactiveTheadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersInactiveTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersInactiveTableContainer table tbody {
        max-height: 35rem;
    }
    
    .usersInactiveTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersInactiveTrLoader {
        
        height: 20rem;
        
    }

    .usersInactiveTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersInactiveTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersInactiveTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersInactiveTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersInactiveTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersInactiveTableContainer table thead tr:first-child th{
        height: 4rem;
        border: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersInactiveTableContainer table tbody tr {
        cursor: default !important;
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersInactiveTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersInactiveTableContainer table tbody td {
        cursor: default !important;
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
        font-size: 1rem;
    }
    .usersInactiveTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersInactiveTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersInactiveTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersInactiveTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;
    }    
    
    .usersInactiveTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
       
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        -webkit-filter: opacity(0.3);
                filter: opacity(0.3)
    }    

}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.usersTableContainer {
    width: 100%;
    overflow: auto;
    max-height: 45rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersTableNameAndTotalUsers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap:0.3rem;
    gap:0.3rem;
}

#usersTableNameAndTotalUsers a:first-child{
    font-size: 1.2rem;
    font-weight: 300;
}

#usersTableNameAndTotalUsers a:nth-child(2) {
    font-size: 0.9rem;
}

#userCadastration:focus{
    background: var(--goldColor);
    color: black;
}

.theadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.theadFirst a {
    margin-left: 2rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.theadFirst button{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}
.theadFirst button:hover {
    background: var(--formBorder);
    color: black;
}


.theadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.theadFirst label a{
    margin-right: 1rem;
    font-weight: 200;
}

.theadFirst label input {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.theadFirst label input::-webkit-input-placeholder{
    font-size: 0.9rem;
}

.theadFirst label input::placeholder{
    font-size: 0.9rem;
}

.usersTableContainer table{
    width: 100%;
    font-size: 1rem;
    
}

.usersTableContainer table tbody {
    max-height: 35rem;
    background: white;
}

.usersTableContainer table tbody tr {
    cursor: pointer;
}

.usersMyUser {
    font-weight: 400;
    
}

.usersActiveTrLoader {
        
    height: 30rem;
    
}   
.usersTableContainer table thead tr:first-child th{
    height: 5rem;
    border-left: none;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
    width: 90rem;
}


.usersTableContainer table thead tr:nth-child(2) > th  {
    height: 1.5rem;
    font-weight: 300 !important;
}

.usersTableContainer table thead tr:nth-child(2) th {
    background: var(--tableSecondTHGray);
    color: white;        
}


.usersTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
}

.usersTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 100;
}

.usersTableContainer table thead tr:first-child th{
    height: 4rem;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
}


.usersTableContainer table tbody tr {
    border-bottom: 1px solid var(--tableBorder);
}

.usersTableContainer table tbody tr:last-child{
    border-bottom: none;
}

.usersTableContainer table tbody td {
    padding: 0;
    height: 2.5rem;
    border-right: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    width: 10rem;
    font-size: 1rem;
}
.usersTableContainer table tbody td:nth-child(1){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(4){
    width: 5rem;
}


.usersTableContainer table tbody td:nth-child(6){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(7){
    width: 4rem;
}

.usersTableContainer table tbody td:nth-child(8){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(9){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(10){
    width: 2rem;
}


.usersTableContainer table tbody td:last-child{
    border-right: none;
}

.ellipsisTd {
    position: relative;
    max-width: 10rem;
    overflow: hidden;
    margin-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

.usersTableContainer table tbody td input{
  height: 1.5rem;
  padding: 0;
  padding-left: 0.3rem;
  width: 6rem;
  margin-right: 0.5rem;
}

.usersTableContainer table tbody td a {
    margin-right: 0.5rem;
}

.usersTableContainer table tbody tr:hover {
    background: var(--tableHover) !important;
}

.usersEditShow td {
   
    color: white;
}

.usersEditShow th {
    background: var(--tableSecondTh);

}

.usersEditShow .divForShowItemn {
    display: flex;
    width: 100%;
    height: 4rem;
}

.editDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.editDiv label {
    height: 2.5rem;
    width: auto;
}

.iconsOptions {
    width: 5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
}

.editIcon{
    width: 3rem;
}

.userInactiveAction {
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3)
}    


@media(max-width:1368px){
    
    .usersTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .theadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .theadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .theadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .theadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .theadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .theadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .theadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .theadFirst label input::-webkit-input-placeholder{
        font-size: 0.9rem;
    }

    .theadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersTableContainer table tbody {
        max-height: 35rem;
        background: white;
    }
    
    .usersTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersActiveTrLoader {
        
        height: 20rem;
        
    }

    .usersTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }    
    
    .usersTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }

    #usersHideLoadingScroll {
        display: none;
    }

    #usersShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        -webkit-filter: opacity(0.3);
                filter: opacity(0.3)
    }  
    
    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        -webkit-animation: lds-ellipsis1 0.6s infinite;
                animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        -webkit-animation: lds-ellipsis3 0.6s infinite;
                animation: lds-ellipsis3 0.6s infinite;
      }
      @-webkit-keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @-webkit-keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @-webkit-keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }

}





.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.usersTableContainer {
    width: 100%;
    overflow: auto;
    max-height: 45rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersTableNameAndTotalUsers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap:0.3rem;
    gap:0.3rem;
}

#usersTableNameAndTotalUsers a:first-child{
    font-size: 1.2rem;
    font-weight: 300;
}

#usersTableNameAndTotalUsers a:nth-child(2) {
    font-size: 0.9rem;
}

#userCadastration:focus{
    background: var(--goldColor);
    color: black;
}

.theadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.theadFirst a {
    margin-left: 2rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.theadFirst button{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}
.theadFirst button:hover {
    background: var(--formBorder);
    color: black;
}


.theadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.theadFirst label a{
    margin-right: 1rem;
    font-weight: 200;
}

.theadFirst label input {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.theadFirst label input::-webkit-input-placeholder{
    font-size: 0.9rem;
}

.theadFirst label input::placeholder{
    font-size: 0.9rem;
}

.usersTableContainer table{
    width: 100%;
    font-size: 1rem;
    
}

.usersTableContainer table tbody {
    max-height: 35rem;
    background: white;
}

.usersTableContainer table tbody tr {
    cursor: pointer;
}

.usersMyUser {
    font-weight: 400;
    
}

.usersActiveTrLoader {
        
    height: 30rem;
    
}   
.usersTableContainer table thead tr:first-child th{
    height: 5rem;
    border-left: none;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
    width: 90rem;
}


.usersTableContainer table thead tr:nth-child(2) > th  {
    height: 1.5rem;
    font-weight: 300 !important;
}

.usersTableContainer table thead tr:nth-child(2) th {
    background: var(--tableSecondTHGray);
    color: white;        
}


.usersTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
}

.usersTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 100;
}

.usersTableContainer table thead tr:first-child th{
    height: 4rem;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
}


.usersTableContainer table tbody tr {
    border-bottom: 1px solid var(--tableBorder);
}

.usersTableContainer table tbody tr:last-child{
    border-bottom: none;
}

.usersTableContainer table tbody td {
    padding: 0;
    height: 2.5rem;
    border-right: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    width: 10rem;
    font-size: 1rem;
}
.usersTableContainer table tbody td:nth-child(1){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(4){
    width: 5rem;
}


.usersTableContainer table tbody td:nth-child(6){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(7){
    width: 4rem;
}

.usersTableContainer table tbody td:nth-child(8){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(9){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(10){
    width: 2rem;
}


.usersTableContainer table tbody td:last-child{
    border-right: none;
}

.ellipsisTd {
    position: relative;
    max-width: 10rem;
    overflow: hidden;
    margin-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

.usersTableContainer table tbody td input{
  height: 1.5rem;
  padding: 0;
  padding-left: 0.3rem;
  width: 6rem;
  margin-right: 0.5rem;
}

.usersTableContainer table tbody td a {
    margin-right: 0.5rem;
}

.usersTableContainer table tbody tr:hover {
    background: var(--tableHover) !important;
}

.usersEditShow td {
   
    color: white;
}

.usersEditShow th {
    background: var(--tableSecondTh);

}

.usersEditShow .divForShowItemn {
    display: flex;
    width: 100%;
    height: 4rem;
}

.editDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.editDiv label {
    height: 2.5rem;
    width: auto;
}

.iconsOptions {
    width: 5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
}

.editIcon{
    width: 3rem;
}

.userInactiveAction {
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3)
}    


@media(max-width:1368px){
    
    .usersTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .theadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .theadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .theadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .theadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .theadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .theadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .theadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .theadFirst label input::-webkit-input-placeholder{
        font-size: 0.9rem;
    }

    .theadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersTableContainer table tbody {
        max-height: 35rem;
        background: white;
    }
    
    .usersTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersActiveTrLoader {
        
        height: 20rem;
        
    }

    .usersTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }    
    
    .usersTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }

    #usersHideLoadingScroll {
        display: none;
    }

    #usersShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        -webkit-filter: opacity(0.3);
                filter: opacity(0.3)
    }  
    
    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        -webkit-animation: lds-ellipsis1 0.6s infinite;
                animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        -webkit-animation: lds-ellipsis3 0.6s infinite;
                animation: lds-ellipsis3 0.6s infinite;
      }
      @-webkit-keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @-webkit-keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @-webkit-keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }

}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.textSumCell p{
    margin-top: 0.25rem;
}
.usersToForLoader {
    width: 80rem;
    height: 50rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.usersToForContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80rem;
    height: 50rem;
    background: white;
}

.usersToForHead {
    height: 4rem;
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
}

.usersToForHead p {
    border-bottom:  1px solid var(--goldColor);
    width: 30%;
    padding-left: 2rem;
    font-size: 1.2rem;
}

.usersToForHead button {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-right: 1rem;
}

.usersToForHead button:hover {
    background: var(--goldColor);
    color: white;
}

.usersToForBody {  
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: calc(100% - 10rem);
    width: 100%;
}

.usersToForBody b {
    font-size: 1rem;
}

.usersToForBody a {
    font-size: 1.2rem;
}

.usersToForBodySuccessMsg {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.usersToForBodySuccessMsg a {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--goldColor);
}

.usersToForOptions {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    height: 10rem;
}


#usersToForTableContainer {
    max-height: 24rem;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersToForTable thead th{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background: var(--topBarBg);
    color: white;
    height: 1.8rem;
    font-size: 1rem;
}

#userToForClientsSelectionAllInput {
    margin-right: 0.5rem;
}

#usersToForTable tbody td {
    height: 1.6rem;
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
    transition: 0.2s;
}

#usersToForTable tbody tr:hover {
    background: var(--tableHover);
}

#usersToForTable tbody td:first-child {
    width: 8rem;
}

#usersToForTable tbody td:first-child input:checked tr {
    background: red !important;
}

.usersToForOptions label {
    width: 50%;
}

.usersToForContainer button[type='submit'] {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    padding: 0.5rem;
    width: 6.8rem;
    font-weight: 400;
    height: 2.5rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
    
}

.usersToForContainer button[type='submit']:hover {
    background-color: var(--goldColor);
    color: white;
}

@media(max-width: 1368px){
    .usersToForLoader {
        width: 60rem;
        height: 40rem;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .usersToForContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60rem;
        height: 35rem;
        background: white;
    }
    
    .usersToForHead {
        height: 4rem;
        background: var(--headerAllBg);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
    }
    
    .usersToForHead p {
        border-bottom:  1px solid var(--goldColor);
        width: 30%;
        padding-left: 2rem;
    }

    .usersToForHead button {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
    }

    .usersToForHead button:hover {
        background: var(--buttonHover);
    }
    
    .usersToForBody {  
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        height: calc(100% - 10rem);
        width: 100%;
    }
    
    .usersToForBody b {
        font-size: 1rem;
    }
    
    .usersToForBody a {
        font-size: 1.2rem;
    }

    .usersToForOptions {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        height: 8rem;
    }

    .usersToForOptions label {
        width: 50%;
    }

    .usersToForContainer button[type='submit'] {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        padding: 0.5rem;
        width: 6.8rem;
        font-weight: 400;
        height: 2.5rem;
        border-radius: 0.6rem;
        transition: 0.2s;
        color: var(--formButtonFontColor);
        
    }
    
    .usersToForContainer button[type='submit']:hover {
        background-color: var(--goldColor);
        color: white;
    }

    @media(max-width: 1368px){
        #usersToForTableContainer {
            max-height: 14rem;
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-x: hidden; 
            box-shadow: -2px 2px 20px -7px #000000;
        }
    }
}
.userBoxArrow{
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
}
.userEditBox {
    background: white;
}

.headerBoxUser{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10rem;
}

.headerBoxUser h1:nth-child(2) {
    font-size: 1.5rem;
    font-weight: 300;
}

.headerBoxUser h1:first-child {
    font-size: 1.4rem;
    font-weight: 500;
}

.headerBoxUser div:first-child{
    margin-left: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
.headerBoxUser div:last-child{
    margin-right: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.headerBoxUser div a:first-child{
    font-weight: 500;
}

.headerBoxUser a {
    font-size: 1.4rem;
}

.formBoxUser{
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    /* flex-wrap: wrap; */
}

.formBoxUser #relative{
    position: relative;
}

.formBoxUser div #dinamic{
    border: 0;
    border: 1px solid var(--formBorder);
    height: 2.8rem;
    outline: none;
    min-width: 13rem;
    width: calc(100% - 1rem);
    padding-left: 1rem;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0.3rem;
}
  
.formBoxUser div #dinamic:focus{
    border: 1px solid #c39e16
}
  
.formBoxUser div #dinamic::-webkit-input-placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.formBoxUser div #dinamic::placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.formBoxUser div #labelDinamic{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
}
  
.formBoxUser div #dinamic:required:invalid + #labelDinamic{
    color: red;
}
.formBoxUser div #dinamic:focus:required:invalid{
    border-bottom: 2px solid red;
}
.formBoxUser div #dinamic:required:invalid + #labelDinamic:before{
    content: '*';
}
.formBoxUser div #dinamic:focus + #labelDinamic,
.formBoxUser div #dinamic:not(:placeholder-shown) + #labelDinamic{
    font-size: 0.7rem;
    margin-top: -0.5rem;
    margin-left: 0.5rem;
    color: #3C3C3C;
    background-color: white;
}

.usersInformationDiv {
    border: 1px solid lightgrey;
    width: 15rem;
    height: 2.8rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-radius: 0.3rem;
}

.usersInformationDiv a {
    font-size: 0.9rem;
    margin-left: 1rem;
}


.usersInformationDiv a:first-child {
    font-weight: 600;
}

.userBoxHideFields {
    display: none;
}

.userBoxShowFields {
    width: 100%;
}


.formBoxUser #userBoxButtonShow {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.formBoxUser #userBoxButtonShow:hover {
    background: var(--buttonHover);
    color: white;
}
.formBoxUser #userBoxButtonHide {
    display: none;
}

.selectBrokers {
    width: 40rem;
}

@media(max-width: 1368px){
    .userEditBox {
        background: white;
    }

    .userBoxArrow{ 
        height: 30rem;
    }
    
    .headerBoxUser{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 5rem;
    }
    
    .headerBoxUser h1:nth-child(2) {
        font-size: 1rem;
        font-weight: 300;
    }
    
    .headerBoxUser h1:first-child {
        font-size: 1rem;
        font-weight: 500;
    }
    
    .headerBoxUser div{
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    
    .headerBoxUser div a:first-child{
        font-weight: 500;
    }
    
    .headerBoxUser a {
        font-size: 1rem;
    }
    
    .formBoxUser{
        padding-left: 4rem;
        padding-right: 4rem;
        padding-bottom: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        /* flex-wrap: wrap; */
    }
    
    .formBoxUser #relative{
        position: relative;
    }
    
    .formBoxUser div #dinamic{
        border: 0;
        border: 1px solid var(--formBorder);
        height: 2.4rem;
        outline: none;
        min-width: 13rem;
        width: calc(100% - 1rem);
        padding-left: 1rem;
        font-size: 1rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
        -webkit-appearance:none;
        border-radius: 0.3rem;
    }
      
    .formBoxUser div #dinamic:focus{
        border: 1px solid #c39e16
    }
      
    .formBoxUser div #dinamic::-webkit-input-placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .formBoxUser div #dinamic::placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .formBoxUser div #labelDinamic{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
    }
      
    .formBoxUser div #dinamic:required:invalid + #labelDinamic{
        color: red;
    }
    .formBoxUser div #dinamic:focus:required:invalid{
        border-bottom: 2px solid red;
    }
    .formBoxUser div #dinamic:required:invalid + #labelDinamic:before{
        content: '*';
    }
    .formBoxUser div #dinamic:focus + #labelDinamic,
    .formBoxUser div #dinamic:not(:placeholder-shown) + #labelDinamic{
        font-size: 0.7rem;
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        color: #3C3C3C;
        background-color: white;
    }
    
    .usersInformationDiv {
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2.4rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        font-size: .9rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }
    
    .usersInformationDiv a {
        font-size: 0.9rem;
        margin-left: 1rem;
    }
    
    
    .usersInformationDiv a:first-child {
        font-weight: 600;
    }
    
    .userBoxHideFields {
        display: none;
    }
    
    .userBoxShowFields {
        width: 100%;
    }
    
    
    .formBoxUser #userBoxButtonShow {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        font-size: 1rem;
        font-weight: 400;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        background-color: white;
        color: var(--formButtonFontColor)
    }
    
    .formBoxUser #userBoxButtonShow:hover {
        background: var(--buttonHover);
        color: white;
    }
    
    .formBoxUser #userBoxButtonHide {
        display: none;
    }
    
    
}
.usersModal {
    background: white;
    display: flex;
    flex-direction: column;
    height: 40rem;
    width: 65rem;
    overflow: hidden;
}

.usersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.usersTab {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    
}

.usersTabOption {
    color: white;
    background: var(--topBarBg);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.usersTabOption div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.usersInactiveTabOption:hover {
    color: white;
}

.usersInactiveTabOption {
    color: white;
    background: var(--topBarBg);
    color: rgba(255, 255, 255, 0.534);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.usersInactiveTable{
    display: none;
}

.usersTable {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    width: 100%;
}

.usersCadastrationModalBG{
    width: calc(100% - 49px);
    height: calc(100% - 49px);
    background: rgb(0,0,0,0.5);
    position: absolute;
}

.usersCadastrationBGHide {
    display: none;
}

.usersBody {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 95%;
}

.usersTableDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 95%;
}

.usersLoading {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usersChangePassContainer {
    height: 30rem;
    width: 50rem;
    background: white;
    display: flex;
    flex-direction: column;
}

.usersChangePassContainer form {
    grid-gap: 1rem;
    gap: 1rem;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
}

.usersChangePassContainer form label:first-child {
    width: 80%;
}

.usersChangePassContainer form  label:first-child a{
    font-size: 1rem;
}

.usersChangePassContainer form a:nth-child(2) {
    font-size: 1.2rem;
}


.usersChangePassContainer form input {
    width: 20rem;
}



.usersChangePassHeader {
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--headerAllBg);
}


.usersChangePassHeader button {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);        
}

.usersChangePassHeader button:hover {
    background: var(--buttonHover);
    color: white;
}

.usersChangePassContainer .usersChangePassHeader p {
    font-size: 1.2rem !important;
    border-bottom: 1px solid var(--goldColor);
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}  

.usersSuccessPassword{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}



.usersChangePassContainer h2{
    font-size: 1.5rem;
    font-weight: 400;
}

@media (max-width: 1368px){

    .usersModal {
        background: white;
        display: flex;
        border-radius: 2rem; 
        box-shadow: 0px 0px 0.5rem 1px var(--goldColor);
        flex-direction: column;
        height: 30rem;
        width: 50rem;
        overflow: hidden;
    }

    .usersLoading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .usersChangePassContainer {
        height: 30rem;
        width: 50rem;
        display: flex;
        flex-direction: column;
    }

    .usersChangePassContainer form {
        grid-gap: 1rem;
        gap: 1rem;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center ;
    }


    .usersChangePassContainer form a {
        font-size: 1.2rem;
    }

    .usersChangePassContainer form a:first-child {
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }

    .usersChangePassContainer form input {
        width: 20rem;
    }


    .usersChangePassHeader {
        
        height: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: var(--headerAllBg);
    }

    .usersChangePassContainer .usersChangePassHeader p {
        font-size: 1.2rem !important;
        border-bottom: 1px solid var(--goldColor);
        margin-bottom: 0.5rem;
        margin-left: 1rem;
    }  

    .usersChangePassContainer .usersChangePassHeader a {
        font-size: 1rem;
    }

    .usersSuccessPassword{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
    }


    .usersChangePassContainer h2{
        font-size: 1.5rem;
        font-weight: 400;
    }

    
}
.companiesContainer {
    
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companiesTable {
    padding: 2rem;
    max-height: calc(100% - 98px);
}

.companiesModal {
    width: 80rem;
    height: 40rem;
    background: white;
    overflow-y: auto;
}

@media(max-width: 1368px){
    .companiesModal {
        width: 50rem;
        height: 35rem;
        background: white;
        overflow-y: auto;
    }
}

#companiesArrowLoader  {
    
    height: 30rem;
    
}

#companiesTableEmptyRow {
    height: 2.5rem;
    font-size: 1.2rem;
}

.companiesTableContainer  {
    
    max-height: 40rem;
    overflow: auto;
    width: 100%;
    border-radius: 0.5rem 0 0 0; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.companiesTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.companiesTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.companiesTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
    border-left: 1px solid  black;
    border-right: 1px solid black;   
}

.companiesTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.companiesTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.companiesTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
    border-left: 1px solid var(--tableSecondTHGray);
    border-right: 1px solid var(--tableSecondTHGray);
}

.companiesTableContainer thead tr:first-child th {
    width: 120rem;
}

.companiesTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    width: 24rem;
    font-size: 1rem;
    font-weight: 300;
}

.companiesTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.companiesTableContainer tbody tr:hover{
    background: var(--tableHover);
}

.companiesTableContainer tbody td {
    padding: 0;
    width: 24rem;
    height: 2.5rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    cursor: pointer;
}

.companiesTableContainer .companiesTheadFirst{
    display: flex;
    flex-direction: row;
}

.companiesTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.companiesTheadFirst a {
    margin-left: 2rem;
}

.companiesTheadFirst button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.companiesTheadFirst button:focus {
    background: var(--formBorder);
    color: black; 
}

.companiesTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.companiesTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.companiesTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.companiesTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}


.companiesCadastrationModalBG{
    width: calc(100% - 49px);
    height: calc(100% - 49px);
    background: rgb(0,0,0,0.5);
    position: absolute;
}

.companiesCadastrationBGHide {
    display: none;
}

@media(max-width: 1368px){

    .companiesTableContainer  {
    
        max-height: 35rem;
        overflow: auto;
        width: 100%;
        border-radius: 0.5rem 0 0 0; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    .companiesTableContainer thead tr:first-child {
        height: 4rem;
    }

    .companiesTableContainer thead tr:nth-child(2) th {
        top: 4rem;
        height: 1.5rem;
    }

    .companiesTheadFirst label input {
        height: 2rem;
        width: 12rem;
        padding-left: 0.6rem;
    }

    .companiesTheadFirst button {
        font-size: 1rem;
        height: 2rem;
        width: 8rem;
     }

    
}



.companiesCadastrationLoader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40rem;
    width: 80rem;
    background: white;
}

.companiesCadastrationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.companiesHeader {
    width: 100%;
    color: var(--headerFontColor);
    font-size: 1.3rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    background: var(--headerAllBg);
}

.companiesHeader button{
    background: transparent;
    color: var(--formBorder);
    border: 0.1rem solid var(--formBorder);
    width: 2rem;
    height: 1.8rem;
    border-radius: 50%;
    font-size: 1rem;
    margin-right: 1rem;
}

.companiesHeader p {
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
    
}

.companiesBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
   
}

.companiesBody form {
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 49px);
}

.companiesBody form label {
    width: 70%;
}

.companiesBody form .labelLocation {

    align-items: flex-start;
    justify-content: space-between;
    font-weight: bold;  
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem

}

.buttonDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.companiesBody button:focus {
    background: var(--buttonHover);
    font-weight: 400;
    border: 2px solid var(--goldColor);
    color: white;
}

.companiesBody .MuiInputBase-root {
    width: auto !important;
}

.companiesBody .MuiFormGroup-root {
    display: flex !important;
    flex-wrap: nowrap !important;
}

.companiesBody .MuiRadio-colorSecondary.Mui-checked {
    color: #c39e16 !important;
}

.companiesCadastrationHide { 

    display: none;
}

@media (max-width: 1368px){

    .companiesCadastrationLoader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 35rem;
        width: 50rem;
        background: white;
    }
    
    .companiesCadastrationContainer {
        display: flex;
        flex-direction: column;
        background: white;
    }

    .companiesHeader {
        width: 100%;
        color: var(--headerFontColor);
        font-size: 1.2rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 4rem;
        background: var(--headerAllBg);
    }

    .companiesBody {
        width: auto;
    }

    .companiesBody form {
        width: 100%;
        margin-top: 2rem;
    }

    .companiesBody form label {
        width: 70%;
    }

    .companiesBody button:focus {
        background: var(--buttonHover);
        font-weight: 400;
        border: 2px solid var(--goldColor);
        color: white;
    }

    .companiesBody .MuiInputBase-root {
        width: auto !important;
    }

    .companiesBody .MuiFormGroup-root {
        display: flex !important;
        flex-wrap: nowrap !important;
    }

    .companiesBody .MuiRadio-colorSecondary.Mui-checked {
        color: #c39e16 !important;
    }
}

.editBrancheBoxContainer {
    width: 70rem;
    height: 40rem;
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.editBrancheBoxLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editBrancheBoxHeader {
    background: var(--headerAllBg);
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.editBrancheBoxHeader p {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--goldColor);
    margin-left: 1rem;
}

.editBrancheBoxHeader button {
    margin-right: 1rem;
}

.editBrancheBoxBody {
    height: 100%;
    width: 95%;
    overflow-x: hidden;
    display: flex;
    grid-gap:1rem;
    gap:1rem;
    flex-direction: column;
    align-items: center;
}

.editBrancheBoxButtonEdit {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

#editBrancheForm {
    height: 100%;
    width: 100%;
    display: flex;
    grid-gap:1rem;
    gap:1rem;
    flex-direction: column;
    align-items: center;
}

.editBranchesBoxDualDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    align-items: center;

}

.editBranchesBoxLabelDiv {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    height: 2.4rem;
    border-radius: 5px;
    border: 1px solid rgba(34, 34, 34, 0.294);
}

.editBranchesBoxLabelDiv a {
    font-size: 1rem;
}

.editBranchesBoxHideLabel {
    display: none;
}
.editBrancheBoxShowFields {
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(34, 34, 34, 0.294);
}

#editBrancheBoxCNPJ {
    height: 2.4rem;
    width: calc(100% - 8px);
    border: none;
    outline: none;
    font-size: 1rem;
    transition: 0.2s;
    padding-left: 0.5rem;
    
}

#editBrancheBoxCNPJ:focus {
    border: 1px solid var(--goldColor);
    transition: 0.2s;
}

#editBrancheBoxCNPJ text {
    color: blue;
}

#editBrancheBoxCNPJ::-webkit-input-placeholder {
    color:rgba(0, 0, 0, 0.815);
    padding-left: 0.5rem;
    font-size: 1rem;
}

#editBrancheBoxCNPJ::placeholder {
    color:rgba(0, 0, 0, 0.815);
    padding-left: 0.5rem;
    font-size: 1rem;
}
.editBranchesBoxLabelDiv a:first-child {
    font-weight: 400;
    margin-left: 0.5rem;
}


@media (max-width: 1368px){
    .editBrancheBoxContainer {
        width: 60rem;
        height: 35rem;
        background: white;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
}
.faq_faqHeader__UQ-5K{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--headerAllBg);
  width: 100%;
  height:6rem;
  color: var(--headerFontColor);
  font-size: 1.3rem;
  box-shadow: 0px 0px 20px -8px #000;
}

.faq_faqHeader__UQ-5K div{
  padding-right: 2rem;
}

.faq_faqHeader__UQ-5K div button{
  background-color: var(--formBorder);
  border: 1px solid var(--formBorder);
  width: 8rem;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  grid-gap: 0.3rem;
  gap: 0.3rem
}

.faq_faqHeader__UQ-5K div button:hover{
  background-color: var(--headerAllBg);
  border: 1px solid var(--formBorder);
  color: var(--formBorder);
}

.faq_faqHeader__UQ-5K p{
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
}

.faq_faqContainer__23PNs{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.faq_accordion__1U9fg{
  width: 100%;
  height: calc(100vh - 12rem);
  overflow-y: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.faq_tab__2Haf5 {
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 60%;
  box-shadow: 0px 0px 5px 0px var(--headerFontColor);
  margin-bottom: 1rem;
}


/* (B) HIDE CHECKBOX */
.faq_tab__2Haf5 input { display: none; }

/* (C) TAB LABEL */
.faq_tab__2Haf5 label {
  display: block;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  background: var(--headerFontColor);
  cursor: pointer;
  font-size: 1rem;
  transition: .2s;
}
/* (D) TAB CONTENT */
.faq_tab__2Haf5 .faq_tabContent__up3Jp {
  background: var(--headerAllBg);
  overflow: hidden;
  transition: all 0s;
  max-height: 0;
  font-size: 1rem;
}

.faq_tab__2Haf5 label:hover{
  background-color: #646464;
}

.faq_tab__2Haf5 .faq_tabContent__up3Jp p { padding: 10px; }

/* (E) OPEN TAB ON CHECKED */
.faq_tab__2Haf5 input:checked ~ .faq_tabContent__up3Jp { max-height: 100vh; }

/* (F) EXTRA - ADD ARROW INDICATOR */
.faq_tab__2Haf5 label::after {
  position: relative;
  right: 10px;
  top: 10px;
  display: block;
  transition: all 0.2s;
}

.faq_tab__2Haf5 input:checked ~ label::after { -webkit-transform: rotate(90deg); transform: rotate(90deg); }

.faq_tab__2Haf5 input:link { background-color: pink !important; color: red }

.faq_FaqAlignCenter__1lJHw{
 
  margin-top: 5rem;
  text-align: center;
}
.faq_accordion__1U9fg {
  font-size: 1.2rem !important;
  background: white;
}

.faq_tabContent__up3Jp{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq_tabContent__up3Jp a {
  transition: .2s;
  cursor: pointer;
}
.faq_tabContent__up3Jp a:hover{
  color: var(--headerFontColor)
}

.faq_tabContent__up3Jp div{
  margin-right: 1rem;
  width: 4rem;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.faq_faqIconsEffect__1U1Fo{
  transition: .2s;
  cursor: pointer;
}

.faq_faqIconsEffect__1U1Fo:hover{
  color: var(--headerFontColor)
}

.faq_ldsellipsis__1bIPZ {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}
.faq_ldsellipsis__1bIPZ div {
  position: absolute;
  top: 0.4rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--headerFontColor);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.faq_ldsellipsis__1bIPZ div:nth-child(1) {
  left: 8px;
  -webkit-animation: faq_lds-ellipsis1__3qsgM 0.6s infinite;
          animation: faq_lds-ellipsis1__3qsgM 0.6s infinite;
}
.faq_ldsellipsis__1bIPZ div:nth-child(2) {
  left: 8px;
  -webkit-animation: faq_lds-ellipsis2__3UfVb 0.6s infinite;
          animation: faq_lds-ellipsis2__3UfVb 0.6s infinite;
}
.faq_ldsellipsis__1bIPZ div:nth-child(3) {
  left: 32px;
  -webkit-animation: faq_lds-ellipsis2__3UfVb 0.6s infinite;
          animation: faq_lds-ellipsis2__3UfVb 0.6s infinite;
}
.faq_ldsellipsis__1bIPZ div:nth-child(4) {
  left: 56px;
  -webkit-animation: faq_lds-ellipsis3__6obzo 0.6s infinite;
          animation: faq_lds-ellipsis3__6obzo 0.6s infinite;
}
@-webkit-keyframes faq_lds-ellipsis1__3qsgM {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes faq_lds-ellipsis1__3qsgM {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes faq_lds-ellipsis3__6obzo {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes faq_lds-ellipsis3__6obzo {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes faq_lds-ellipsis2__3UfVb {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes faq_lds-ellipsis2__3UfVb {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
.faqEducationCadastration_faqCadastration__kPcLp{
    width: 50rem;
    height: 31em;
    background-color: white;
}
@media(max-width:1368px){
    .faqEducationCadastration_faqCadastration__kPcLp{
        width: 50rem;
        height: 37em;
        background-color: white;
    }
}

.faqEducationCadastration_faqCadastration__kPcLp form{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.faqEducationCadastration_faqCadastration__kPcLp legend{
    font-size: 1rem !important;
}


.faqEducationCadastration_faqCadastrationHeader__1OYB5 {
    top: 0;
    padding: 1rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    background: var(--headerAllBg);
}

.faqEducationCadastration_faqCadastrationHeader__1OYB5 p {
    border-bottom: 0.1rem solid var(--formBorder);
}

.faqEducationCadastration_faqCadastrationHeader__1OYB5 button{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--formBorder);
    color:  var(--formBorder);
    padding-bottom: 0.2rem;
    font-size: 18px;
}

.faqEducationCadastration_radioSelectContainer__G6j3r{
    grid-gap: 2rem;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.95rem;
    font-family: 'Roboto';
    color: #7F777F;
    font-weight: 500;
}

.faqEducationCadastration_radioSelectContainer__G6j3r div{
    grid-gap: 0.2rem;
    gap: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faqEducationCadastration_radioSelectContainer__G6j3r div input{
    width: 1rem;
    height: 1rem;
}

.faqEducationCadastration_radioSelectContainer__G6j3r div input:checked ~ label{
    color: var(--formBorder)
}

.faqEducationCadastration_formFormat__170qS .faqEducationCadastration_buttonFormFaq__2wZav{
    width: 7rem;
    font-size: 1rem;
    height: 2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.2rem;
    background-color: var(--formBorder);
    color: #fff;
    font-weight: 500;
    transition: .2s;
}


.faqEducationCadastration_formFormat__170qS .faqEducationCadastration_buttonFormFaq__2wZav:hover{
    background-color: #fff;
    color: var(--formBorder);
}


.faqEducationCadastration_hrStyle__taZYK{
    width: 20rem;
}

.faqEducationCadastration_radioSelectContainerColumn__234AD{
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;  
    font-size: 0.95rem;
    font-family: 'Roboto';
    color: #7F777F;
    font-weight: 500;
    width: 80%;
}

.faqEducationCadastration_radioSelectContainerColumnLine__1j-Kv{
    grid-gap: 0.4rem;
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}

.faqEducationCadastration_radioSelectContainerColumnLine__1j-Kv div{
    grid-gap: 0.3rem;
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

.faqEducationCadastration_fileInputAdjust__YjCia input[type='file'] {
    display: none !important;
    height: 2.2rem;
}
  
  /* Aparência que terá o seletor de arquivo */
.faqEducationCadastration_fileInputAdjust__YjCia label {
    background-color: #3498db;
    border: 1px solid #3498db;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    font-size: 1rem;
    padding: 6px 20px;
    font-weight: 400;
    transition: .2s;
}

  /* Aparência que terá o seletor de arquivo */
  .faqEducationCadastration_fileInputAdjust__YjCia label:hover {
    background-color: #fff;
    height: 2.2rem;
    border-radius: 5px;
    color: #3498db;
    cursor: pointer;
    margin: 5px;
    padding: 6px 20px
}

.faqEducationCadastration_InputToFormat__3cQoC{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.faqEducationCadastration_InputToFormat__3cQoC div{
    position: relative;
    flex-grow: 1;
    /* margin: 1rem; */
}

.faqEducationCadastration_InputToFormat__3cQoC div input{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    min-width: 7rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
  
.faqEducationCadastration_InputToFormat__3cQoC div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.faqEducationCadastration_InputToFormat__3cQoC div input::-webkit-input-placeholder{
    color:transparent;
}
  
.faqEducationCadastration_InputToFormat__3cQoC div input::placeholder{
    color:transparent;
}
  
.faqEducationCadastration_InputToFormat__3cQoC div label{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.faqEducationCadastration_InputToFormat__3cQoC div input:required:invalid + label:before{
    content: '*';
}
.faqEducationCadastration_InputToFormat__3cQoC div input:focus + label,
.faqEducationCadastration_InputToFormat__3cQoC div input:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}



.faqEducationCadastration_InputToFormat_two__31kJO{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.faqEducationCadastration_InputToFormat_two__31kJO div{
    position: relative;
    flex-grow: 1;
    /* margin: 1rem; */
}

.faqEducationCadastration_InputToFormat_two__31kJO div input{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    min-width: 18.6rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
  
.faqEducationCadastration_InputToFormat_two__31kJO div input:focus{
      box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.faqEducationCadastration_InputToFormat_two__31kJO div input::-webkit-input-placeholder{
    color:transparent;
}
  
.faqEducationCadastration_InputToFormat_two__31kJO div input::placeholder{
    color:transparent;
}
  
.faqEducationCadastration_InputToFormat_two__31kJO div label{
    color: #7F777F;
    font-size: 0.9rem;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.faqEducationCadastration_InputToFormat_two__31kJO div input:required:invalid + label:before{
    content: '*';
}
.faqEducationCadastration_InputToFormat_two__31kJO div input:focus + label,
.faqEducationCadastration_InputToFormat_two__31kJO div input:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
#reportLoadingTd {
    height: 10rem;
}

.reportsTableContainer  {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    border-radius: 0.5rem 0 0 0;
}

.reportsTableContent {
    width: 100%;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.reportsTableContainer table {
    width: 100%;margin: 0 auto;
    font-size: 1rem;
}

.reportsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
    font-size: 1rem;
}

.reportsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.reportsTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    font-size: 1rem;
    z-index: 100;
    background: var(--topBarBg);
}

.reportsTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    opacity: 1;
    font-size: 1rem;
    z-index: 100;
    background: var(--tableSecondTHGray);
}

.reportsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}

.reportsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.reportsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.reportsTableContainer tbody tr:hover{
    background: var(--tableHover);
}


.reportsTableContainer tbody td {
    padding: 0;
    max-width: 5rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    cursor: default;
}

.reportsTableContainer tbody td:nth-child(8){
  cursor: pointer;
  max-width: 3rem;
  width: 3rem;
}

.reportsTableContainer tbody td:nth-child(1) svg {
    color: rgb(110, 110, 110)
}

.reportsTableContainer tbody td:nth-child(1):hover svg {
    font-size: 1.1rem;
}

/* Overload: O botão padrão possui 8rem */
button#userCadastration {
    width: 12rem;
}

.modalReportsDetail{
    width: 50rem;
    height: 35rem;
    background: #fff;
}

.modalReportsHeader{
    background-color: #f1f1f1;
    width: 100%;
    height: 4rem;
}

.disclaimerEditReport{
    background-color: #c49e17;
    text-align: center;
    padding: 0.2rem;
}

.buttonMuiDatatable:hover{
    background-color: #c49e17;
}

.reports_cadastration_formReportsCadastration__1YrpG{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 1.2rem;
}

.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O{
    position: relative;
    flex-grow: 1;
    margin: 0.6rem;
}

.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    width: 20rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
  
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']::-webkit-input-placeholder{
    color:transparent;
}
  
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']::placeholder{
    color:transparent;
}
  
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O label{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']:required:invalid + label:before{
    content: '*';
}
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']:focus + label,
.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_divInputsReportsCadastration__3hT4O input[type='text']:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}


.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_ReportCadastrationsButton__1rpD1 {
    margin-top: 1.2rem;
    background-color: var(--formBorder);
    border: 1px solid var(--formBorder);
    color: #fff;
    padding: 0.4rem;
    font-size: 1rem;
    transition: .2s;
    font-weight: 400;
    border-radius: 0.3rem;

}

.reports_cadastration_periodicReportLabel__3wzX3 {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    
    
}

.reports_cadastration_periodicReportLabel__3wzX3 label {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.reports_cadastration_formReportsCadastration__1YrpG .reports_cadastration_ReportCadastrationsButton__1rpD1:hover {
    background-color: #fff;
    color: var(--formBorder);
    border: 1px solid var(--formBorder);
}



.reports_cadastration_radioSelectContainerColumn__3-NSG{
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;  
    font-size: 0.95rem;
    font-family: 'Roboto';
    color: #7F777F;
    font-weight: 500;
    margin-top: 0.6rem;
    width: 100%;
    margin-bottom: 0.6rem;
}

.reports_cadastration_radioSelectContainerColumnLine__1R9bX{
    grid-gap: 0.4rem;
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}

.reports_cadastration_radioSelectContainerColumnLine__1R9bX div{
    grid-gap: 0.3rem;
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

.reports_cadastration_fileInputAdjust__3-RX0 input[type='file'] {
    display: none !important;
    height: 2.2rem;
}
  
  /* Aparência que terá o seletor de arquivo */
.reports_cadastration_fileInputAdjust__3-RX0 label {
    background-color: #3498db;
    border: 1px solid #3498db;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    font-size: 1rem;
    padding: 6px 20px;
    font-weight: 400;
    transition: .2s;
}

  /* Aparência que terá o seletor de arquivo */
  .reports_cadastration_fileInputAdjust__3-RX0 label:hover {
    background-color: #fff;
    height: 2.2rem;
    border-radius: 5px;
    color: #3498db;
    cursor: pointer;
    margin: 5px;
    padding: 6px 20px
}


.reports_cadastration_InputToFormat__1Y6br{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.reports_cadastration_InputToFormat__1Y6br div{
    position: relative;
    flex-grow: 1;
    /* margin: 1rem; */
}

.reports_cadastration_InputToFormat__1Y6br div input{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    min-width: 7rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
  
.reports_cadastration_InputToFormat__1Y6br div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.reports_cadastration_InputToFormat__1Y6br div input::-webkit-input-placeholder{
    color:transparent;
}
  
.reports_cadastration_InputToFormat__1Y6br div input::placeholder{
    color:transparent;
}
  
.reports_cadastration_InputToFormat__1Y6br div label{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.reports_cadastration_InputToFormat__1Y6br div input:required:invalid + label:before{
    content: '*';
}
.reports_cadastration_InputToFormat__1Y6br div input:focus + label,
.reports_cadastration_InputToFormat__1Y6br div input:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}

.reports_divContainerReports__1cOaN{
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reports_modalContainerReports__2XA7D{
  width: 50rem;
  height: 35rem;
  background: white;
}

.reports_modalContainerReportsHeader__3YTDT {
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--headerAllBg);
  top: 0;
}

.reports_modalContainerReportsHeader__3YTDT p{
  font-size: 1.2rem !important;
  margin-left: 1rem;
  border-bottom: 1px solid var(--formBorder);
}

.reports_modalContainerReportsHeader__3YTDT button {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 1px solid var(--goldColor);
  color: var(--goldColor);         
  display: flex;
  align-items: center;
  justify-content: center;
}

.reports_modalFormReports__2EQFh{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bookMainPage {
    display: flex;
    flex-direction: column;
    width:100%;
    height: 90%;
    transition: .2s;  
}
.bookPageBtn div:hover {
    -webkit-filter: invert(0.1);
            filter: invert(0.1)
}

.bookRubiMainContainer {
    
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height: 100%;
    transition: .2s;   
}
.bookRubiOperationContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s;  
    align-items: center;
}

.bookRubiOperationContainerAdm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s;  
    align-items: center;
}

.bookRubiMainContainerShow{
    width: 100%;
}

.bookRubiMainContainerHide {
    left: 50px;
    background-color: rgba(255, 255, 255, 0.6);
    flex-direction: row;
    width: calc(100% - 50px);
    position: fixed;
    z-index: 30;
    height: calc(100% - 50px);
    transition: .3s;
}

.bookRubiMainContainerHourHide {
    margin-left: 1rem;
    padding-left: 2rem;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: row;
    width: 80%;
    position: absolute;
    z-index: 150;
    height: 90vh;
    transition: .3s;
}

.bookRubiWarning {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    height: 100%;
    width: 100%;
}

.bookRubiOrdersContainer {   
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 1;
    background-color: white;
    width: 100%; 
    box-shadow: -2px 0px 20px -7px #000000;
}


.bookPageBtn div:hover {

    color: white;
}

.bookRubiButtonOrder {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 11rem
}

.bookRubiButtonOrder div {
    border-radius: 0.3rem 0 0rem 0.3rem;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 5rem;
    font-size: 1rem;
    font-weight: 600;
    background: #333333;
    color: #a5a5a5;
    cursor: pointer;
    transition: .2s;
}

.bookRubiButtonOrder a {
    margin-top: 0.5rem;
}

.bookRubiButtonOrder div:hover{
    color: white;
}

.bookRubiOrderHide {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    height: 100%;
    width: 0px;
    max-width: 0;
    overflow: hidden;
    transition: .15s;
}

.bookRubiOrderShow{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 300;
    opacity: 1;
    width: 60%;   
    height: 100%; 
    transition: .3s;
    flex-direction: row;
    overflow: hidden;

    
}

.bookRubiOrderShow button {
  
    margin-top: 8rem;
    height: 2.7rem;
    width: 3rem;
    border-radius: 50%;
    border: 0.1rem solid rgb(168, 168, 168);
    color: solid rgb(168, 168, 168);
    opacity: 1;
    cursor: pointer;
    transition: .2s;
    font-size: 1rem;
    background: rgb(253, 253, 253);
}

.bookRubiOrderShow button:hover {
    color: var(--formBorder);
    border: 0.2rem solid var(--formBorder);
}

@media (max-width: 1368px){

    .bookRubiOrderShow button {
        margin-top: 8rem;
        height: 2rem;
        width: 2.2rem;
        border-radius: 50%;
        border: 0.1rem solid rgb(168, 168, 168);
        color: solid rgb(168, 168, 168);
        opacity: 1;
        cursor: pointer;
        transition: .2s;
        font-size: 1rem;
        background: rgb(253, 253, 253);
    }
}

@media (max-width: 1020px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90%;
   }

   .bookRubiOperationContainer {
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    transition: .2s;   
}
   

   .bookRubiOrderShow {
       width: 95%;
   }

   .bookRubiMainContainer table {
    overflow: auto;
    border-collapse: collapse;
   }

   .bookRubiMainContainer td {
       padding-left: 1.75rem;
       padding-right: 1.75rem;
   }

   
}

@media(max-width: 750px){
    .bookRubiOperationContainer { overflow: scroll;}
    
    .bookRubiMainContainerHide {
        width: 93%;
    }
}

@media (max-width: 550px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }

   .bookRubiOrderShow button {
       border-radius: 0;
       width: 1.5rem;
       height: 1.5rem;
   }

   .bookRubiMainContainerHide {
    width: 92%;
}
}

@media (max-width: 400px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }

   .bookRubiMainContainerHide {
    width: 89%;
}
}

@media (max-width: 350px)
{
  .bookRubiMainContainer
   {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
   }
}
.booksOperationTable {
  max-height: 45rem;
  width: 95%;
  height: 100%;
  margin-top: 2rem;
}

#booksOperationLegend {
  background: #2c2c2c;
  border-radius: 5px;
  color:rgb(224, 224, 224);
  height: 5rem;
  width: 17rem;
  padding: 0.3rem;
  padding-bottom: 0.3rem;
  margin-top: 12rem;
  /* position: absolute; */
  z-index: 99;
  transition: 2s; 
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.77);
}

#booksOperationLegend p{
  font-size: 0.8rem;
}

#successButonClose {
  background: rgb(20, 20, 20);
  outline: none;
  color: white;
  border: none;
  margin-right: 0.5rem;
  cursor: pointer;
  width: 2rem;
  border-left: 1px solid rgb(197, 197, 197);
}

#showAlert {
  margin-top: 1rem;
  position: absolute;
  right:0;
  height: 2.5rem;
  left:  49px;
  bottom: 0;
  transition: 0.6s;
  color: #f0f0f0;
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: center; 
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.77);
  width: calc(100% -49px);

}

#showAlert a{
  margin-left: 0.5rem;
}

#hideAlert {
  margin-top: 1rem;
  position: absolute;
  right:0;
  height: 2.2rem;
  opacity: 0;
  left:  49px;
  bottom: 0;
  transition: 0.6s;
  color: #f0f0f0;
  font-weight: 400;
  font-size: 1.2rem;
  background: rgb(20, 20, 20);
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: center; 
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.77);
  width: calc(100% -49px);

}

#booksOperationTdLoading {
  height: 20rem !important;
}

#tabLoading {
  display:  flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

#firsTHTable {
  height: 2rem;
  background: #E5E5E5;
  position: -webkit-sticky;
  position: sticky;
  top: 5.5rem;
}

#firsTdBG {
  background: #E5E5E5;
  width: 2rem !important;
}

#booksOperationTableLoader {
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.booksOperationTableContent {
  width: 100%;
  min-height: 8rem;
  max-height: 95%;
  overflow-x: hidden;
  background: white; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.booksTabs {
  
  overflow-y: hidden;
  height: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

#booksOperationMenuHeader {
  display: grid;
  align-items: center;
  
}
#booksOperationMenuHeaderRightDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}


#booksOperationMenuHeaderRightDiv #booksOperationTheadButton {

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  outline: none;
  padding-right: 0.5rem;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  width: 8rem;
  height: 1.8rem;
  transition: 0.1s;
  color: var(--formButtonFontColor)
}

#booksOperationMenuHeaderRightDiv #booksOperationTheadButton:hover {
    background: #9e8013;
    color: black;
}

.booksOperationMenuHeaderOrderDiv a{
  font-size: 1rem;
  /* color: var(--goldColor); */
  /* transition: 0.2s; */
  font-weight: 300;
}

.pendingOrders{
  -webkit-animation: glowing 2000ms infinite;
          animation: glowing 2000ms infinite;
}

.pendingOrders:hover {
  -webkit-animation: paused !important;
          animation: paused !important;
}

@-webkit-keyframes glowing {
  0% { background-color: var(--goldColor); box-shadow: 0 0 3px;color: black;}
  50% { background-color: black; box-shadow: 0 0 3px black; color: var(--goldColor);}
  100% { background-color: var(--goldColor); box-shadow: 0 0 3px;color: black;}
}

@keyframes glowing {
  0% { background-color: var(--goldColor); box-shadow: 0 0 3px;color: black;}
  50% { background-color: black; box-shadow: 0 0 3px black; color: var(--goldColor);}
  100% { background-color: var(--goldColor); box-shadow: 0 0 3px;color: black;}
}

.notificationOrders{
  background-color: red;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  font-weight: 400;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
}

.booksOperationMenuHeaderOrderDiv svg {
  color: var(--goldColor);
  font-size: 1.3rem;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
  transition: 0.2s;
  
}

.booksOperationMenuHeaderOrderDiv {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  outline: none;
  padding-right: 0.5rem;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  width: 8rem;
  height: 1.553rem;
  transition: 0.1s;
  color: var(--formButtonFontColor)
}

.booksOperationMenuHeaderOrderDiv:hover {
  background: var(--goldColor);
  color: black;
}

.booksOperationMenuHeaderOrderDiv:hover svg {
  color: black;
  -webkit-filter: brightness(1);
          filter: brightness(1);
  transition: 0.2s;
}

.booksOperationMenuHeaderOrderDiv:hover a {
  color:black;
  -webkit-filter: brightness(1);
          filter: brightness(1);
  transition: 0.2s;
}

#booksTabsLabel {
  overflow: hidden;
  white-space: nowrap;
  border-radius: 8px 8px 0 0 ;
  height: 100%;
  width: 7rem;
  max-width: 7rem;
  min-width: 7rem;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

#booksTabsLabel a {
  color: rgb(211, 210, 210);
  font-size: 0.9rem;
  -webkit-filter: brightness(0.80);
          filter: brightness(0.80);
  font-weight: 400;
}


#booksTabsLabel:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3)
}

#booksTabsLabelActive {
  overflow: hidden;
  white-space: nowrap; 
  border-radius: 8px 8px 0 0 ; 
  height: 100%;
  width: 7rem;
  max-width: 7rem;
  min-width: 7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

#booksOperationInputFilter {
  padding-left: 0.5rem;
  height: 1.8rem;
  width: 10rem;
  background: #313131;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  margin-right: 1rem;
}

#booksOperationInputFilter::-webkit-input-placeholder{
  font-size: 1rem;
}

#booksOperationInputFilter::placeholder{
  font-size: 1rem;
}

#booksTabsLabelActive a {
  color: rgb(246, 246, 246);
  font-size: 0.9rem;
  border-bottom: 1px solid var(--goldColor);
}

#booksThead tr:first-child th {
  position: -webkit-sticky;
  position: sticky;
  top:0;
  z-index: 20;
}

#booksTheadDiv {
  height: 4rem;
  background: var(--topBarBg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.booksOperationBookName {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;

}

#booksThead tr:nth-child(2) th {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
}

#booksOperationTheadLastTH th {
  background: #E5E5E5;
  height: 1.8rem;
  position: -webkit-sticky;
  position: sticky;
  top: 5.2rem;
  z-index: 20;
}

#booksTheadDiv p:first-child {
  margin-left: 30px;
}

#booksTheadDiv p {
  
  color: white;
  font-size: 1.2rem !important;
  font-weight: 300;
}

#booksThead tr:nth-child(2) th {
  color: white;
  background: var(--tableSecondTh);
  font-weight: 300;
  font-size: 1rem;
  height: 2rem;
  z-index: 20;
}

#booksOperationActiveTh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#booksOperationActiveThName {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

#booksOperationActiveThValue {
  border-left: 1px solid #dfdfdf46;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

#booksTheadButtonsGroup {
  display: flex;
  flex-direction: row;
}

#booksTheadButtonsGroup input {
  margin-right: 1rem;
}

.booksOperationTableEmpty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  box-shadow: -2px 2px 20px -7px #000000;
  
}

#booksOperationTableEmptyTable {
  height: 20rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  align-items: center; 
  box-shadow: -2px 2px 20px -7px #000000;
}

#booksOperationTableEmptyTable  a {
  font-size: 1.5rem;
  font-weight: 400;
}

.booksOperationTableEmpty a {
  font-size: 1rem;
  
}

#BooksOperationTableTbody td:nth-child(1) {
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
 }
 

#BooksOperationTableTbody td{
  transition: 0.2s;
  text-align: center;
  height: 2.5rem;
  border-bottom: 1px solid #E5E5E5;
  border-right: 1px solid var(--tableBorder);
}


.booksOperationClosedTr:hover {
  background: #caddf170;
  color: black;
}

.booksOperationActiveNameAndValue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto !important;
  overflow-x: hidden;
  
}


#booksOperationActiveName {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

#booksOperationActiveName div:first-child {
  margin-left: 1.5rem;
}

#booksOperationActiveName div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
}

.booksOperationAssetCardButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  cursor: pointer;
  transition: 0.2s;
}

.booksOperationAssetCardButton:hover {
  border-bottom: 1px solid black;
}

.booksOperationAssetNameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.booksOperationStar {
  font-size: 1.2rem;
  color:#9e8013;
  stroke: #9e8013;
}

#booksOperationActiveValue {
  border-left: 1px solid #dfdfdf;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
}


#booksOperationActiveValue label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: 1rem;
}


#BooksOperationTableTbody tr:hover #booksOperationPlusIcon {
  display: contents;
}

#BooksOperationTableTbody tr td:first-child {
  width: 25rem;
}


#booksOperationTrActive td {
  border-bottom: none;
  border-left: #5f5f5f;
}

#booksOperationActiveValue #downActive {
  color: rgb(160, 4, 4);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  font-size: 1rem;
}

#booksOperationActiveValue #downActive i {
  font-weight: 300;
  font-size: 0.7rem;
}

#booksOperationActiveValue #upActive {
  color: rgb(66, 146, 66);
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  font-size: 1rem;
}
#booksOperationActiveValue #upActive i {
  font-weight: 300;
}

#booksOperationBuyBoxOpen {
  background:#333333;
}

#booksOperationBuyBoxClose {
  display: none;
}

.booksBuySell {
  display: flex;
  flex-direction: row;
  width: 90%;  
}
.heightOn {
  height: 90%;
}

.heightOff {
  height:none;
}

.booksOperationTrActive td{
 
 background: var(--topBarBg);
 color: white;
 text-align: center;
 transition: .2s;
 border: 1px solid #2c2c2c !important;
}

#booksOrdersErrorsTr {
  background: rgba(255, 255, 255, 0.219) !important;
  color:rgb(160, 4, 4);
  font-weight: 500;
}


.booksOperationTrActive tr:hover{
  background: var(--topBarBg) !important;
  color: var(--goldColor);
}


#booksOperationBuyBoxClose {
  display: none;
  height: 0;
}

#booksOperationBuyBoxOpen {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.booksBuySell {
  background-color: var(--tableSelectedOperation);
  display: grid;
  grid-template-columns: 15% 85%;
  justify-content: center;
  transition: 0.2s;
  width: 100%;
  height: 6rem;
  font-weight: 100;
}

.booksBuySellFalse {
  display: none;
}

.booksBuySellBtn {
  padding-left: 1rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;    
}

.booksBuySellBtn div:nth-child(2) input[type=checkbox]:before {
  background-color: #CB1111;
}

.booksBuySellOpt{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
}

.booksBuySellOpt input[type=checkbox] {
  position: relative;
  cursor: pointer;
  width: 1rem;
  height: 1.2rem;
}
.booksBuySellOpt input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}

.booksBuySellOpt input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}
.booksBuySellOpt input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 0.2rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.booksBuySellOpt a {
color: white;
margin-left: 0.8rem;
font-size: 1rem;
} 


/* Novos campos envio de orden */
.booksBuySellBtnOptions {
  padding-left: 1rem;
  /* width: 10rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;    
}

.booksBuySellBtnOptions div:nth-child(2) input[type=checkbox]:before {
  background-color: #CB1111;
}

.booksBuySellOptOptions{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
}

.booksBuySellOptOptions input[type=checkbox] {
  position: relative;
  cursor: pointer;
  width: 1rem;
  height: 1.2rem;
}
.booksBuySellOptOptions input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}

.booksBuySellOptOptions input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}
.booksBuySellOptOptions input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 0.2rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.booksBuySellOptOptions a {
color: white;
margin-left: 0.8rem;
font-size: 1rem;
} 

/* --- */

.booksClientData {
display: flex;
flex-direction: row;

}

.booksAlignItemsClient {
display: flex;
flex-direction: row;
align-items: center;
grid-gap: 1.5rem;
gap: 1.5rem;
width: 100%;
}


.booksAlignItemsClient:nth-child(4) div a{
background: red !important;
color: red !important;
}

input[type=number]::-webkit-inner-spin-button { 
-webkit-appearance: none;

}
input[type=number] {
-webkit-appearance: textfield;
        appearance: textfield;

}

.booksLabel {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
width: 8rem;
}

.booksLabel a {
font-size: 1rem;
margin-bottom: 0.2rem;
color: white;
}


.booksLabelQtd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  }
  
  .booksLabelQtd a {
  font-size: 1rem;
  margin-bottom: 0.2rem;
  color: white;
  }

  #booksOperationLabelCalculatedQtd {
    border: none;
    height: 2.2rem;
    padding-left: 0.5rem;
    display: flex;
    color: white;
    cursor: not-allowed;
    background: #535355;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 6rem;
  }

  #booksOperationLabelCalculatedValue {
    border: none;
    height: 2.2rem;
    display: flex;
    color: white;
    cursor: not-allowed;
    background: #535355;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 8rem;
  }

  .booksLabelComission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 10rem;
  }
    
  .booksLabelComission a {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    color: white;
  }
  

#booksLabelClientSelection {
width: 12rem;
display: flex;
flex-direction: column;
justify-content: center;
}

#booksLabelClientSelection a {
font-size: 1rem;
margin-bottom: 0.2rem;
color: white;
}

#booksLabelClientSelection label{
  display:  flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#booksLabelClientSelection .MuiInputBase-root {
  color: white;
  font-weight: 300;
  background: #535355;
}

.booksANone {
opacity: 0;
}

.booksLabel select {
width:10rem;
height: 2rem;
outline: none;
border: none;
background-color: rgb(83, 83, 85);
color: white;
font-size: 1rem;
}

.booksLabel .MuiTypography-colorTextSecondary {
  color: white;
  font-weight: 300;
}

.booksLabelQtd input {
padding-left: 0.5rem;
border: none;
width: 6rem;
color: white;
height: 2.2rem;
font-size: 1rem;
font-weight: 300;
background-color: rgb(83, 83, 85);
}

.booksLabelAuto input {
color: white;
margin-left: 0.5rem;
}


.booksLabelButton {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
height: 6rem;
}

.booksLabelButton button {
background-color: #1E80EF;
border:none;
color: white;
height: 2.2rem;
width: 10rem;
margin-top: 0.8rem;
transition: 0.2s;
font-size: 1rem;
cursor: pointer;
font-weight: 400;

}

.booksLabelButton button:hover{
background-color: #1c6bc5;
transition: .2s;
}

.booksLabelAndAskIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  font-size: 1rem;
  color: #a0a0a0;
}



.booksOperationButtonOrderSend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booksOperationButtonOrderSend:focus {
  background: #CB1111;
}

.booksSendDataTrue {
display: flex;
flex-direction: column;
color: white;
font-size: 1rem;
justify-content: center;
align-items: center;
width: 100%;
height: 6rem;
background-color: #1E80EF;
transition: .2s;
}


.booksSendDataTrue button {
margin-top: 0.5rem;
outline: none;
border: 0.1rem solid white;
width: 5rem;
height: 1.5rem;
background: none;
cursor: pointer;
color: white;
transition: .2s;
}

.booksSendDataTrue button:hover{
background: white;
color:#1c6bc5;
}

.booksSendDataFalse {
display: none;
transition: .2s;
}
#booksShowLoadingScroll{
  background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
  cursor: default !important;
}

#booksShowLoadingScroll:hover {
  cursor: default !important;
  background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));

}

#booksHideLoadingScroll {
  display: none;
}


.ldsellipsis {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}
.ldsellipsis div {
  position: absolute;
  top: 0.4rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--headerFontColor);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.ldsellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 1368px){

  .booksOperationTable {
    max-height: 35rem;
    width: 95%;
    height: 100%;
    margin-top: 1rem;
  }

  .booksOperationTableContent {
    width: 100%;
    min-height: 8rem;
    max-height: 85%;
    overflow-x: hidden;
    background: white; 
    box-shadow: -2px 2px 20px -7px #000000;
  }
  
}
#rubiOrderContainer {
    font-size: 1rem;
    height: calc(100% - 50px);
}

.rubiOrderHeader div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

#bookOrdersTr {
    cursor: default !important;
}

#bookOrdersTr td{
    cursor: default !important;
}

#bookOrdersButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    padding-left: 0.5rem;
}

#bookOrdersMsgSendOrDelete {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    font-size: 1rem;
}

#bookOrdersMsgHideMsg {
    display: hidden;
    opacity: 0;
    width: 0;
    height: 0;
}

#bookOrdersMsgShowSuccess {
    display: flex;
    opacity: 1;
    transition: 0.2s;
    width: auto;
    height: auto;
}

.orderSendCleanTD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 18px;
}


.headerTh input[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.headerTh input[type=checkbox]:hover:before {
    border: 1px solid var(--goldColor);
    transition: 0.2s;

}

.headerTh input[type=checkbox]:before{
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    transition: 0.2s;
    height: 1rem;
    top: 0;
    border: 1px solid rgb(85, 85, 85);
    left: 0;
    background-color:#353535;
    margin-right: 0.2rem;
}

.headerTh input[type=checkbox]:checked:before{
    content: "";
    display: block;
    position: absolute;
    border: 1px solid var(--goldColor);
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    background-color:#333333;
}

.headerTh input[type=checkbox]:checked:after{
    content: "";
    display: block;
    width: 0.2rem;
    height: 0.6rem;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

.headerTh a {
    margin-left: 0.5rem;
    font-size: 0.9rem;
}

#bookOrdersTr input[type=checkbox]:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    top: 0;
    border: 1px solid rgb(221, 221, 221);
    left: 0;
    background-color:white;
}

#bookOrdersTotalTr input[type=checkbox]:before{
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    top: 0;
    border: 1px solid rgb(168, 168, 168);
    left: 0;
    background-color:white;
}

#bookOrdersTr input[type=checkbox]:checked:before,
#bookOrdersTotalTr input[type=checkbox]:checked:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    border: 1px solid rgb(221, 221, 221);
    top: 0;
    left: 0;
    background-color:white;
}


#bookOrdersTr input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.4rem;
    height: 0.8rem;
    border: solid rgb(0, 126, 27);
    border-width: 0px 2px 2px 0px;
    margin-top: -1.2rem;
    margin-left: 0.3rem;
    
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#bookOrdersTotalTr input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 0.4rem;
    height: 0.8rem;
    border: solid rgb(146, 146, 146);
    border-width: 0px 2px 2px 0px;
    margin-top: -1.2rem;
    margin-left: 0.3rem;
    
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rubiOrderHeader div section:nth-child(1){
    width: 50%;
}

.rubiOrderHeader div section:nth-child(1) h1{
    font-size: 1.8rem;
    font-weight: 400;
    
}

.rubiOrderHeader div section:nth-child(2){
    
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    font-weight: 200;
    width: 50%;

}

.rubiOrderHeader div section:nth-child(2) p:nth-child(1){
    margin-right: .8rem;
}

#rubiOrderContainer {
    max-height: 100%;
    height: 100%;
    overflow-x: hidden;
}

#rubiOrderContainer table thead tr:first-child th {
    background: var(--formBorder) !important ;
    height: 6rem;
    font-size: 1.8rem;
    font-weight: 400;
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    top:0;
}


#rubiOrderContainer table thead tr:nth-child(2) th {

    background-color: #3E3E3E;
    height: 2rem;
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
}


#rubiOrderContainer table thead tr:nth-child(3) th:first-child{
    width: 3rem;
}

#rubiOrderContainer table thead tr:nth-child(3) th {
   
    background-color: #3E3E3E;
    height: 2rem;
    z-index: 100;
    position: -webkit-sticky;
    position: sticky;
    top: 9rem;
    font-weight: 500;
    font-size: 0.9rem;
}

#rubiOrderContainer table tr {
    width: 100%;
}

#rubiOrderContainer tbody {
    overflow-y: auto;
    height: calc(100% - 50px);
}

#rubiOrderContainer tbody tr {
    cursor: pointer;
    table-layout: fixed;
    height: 2rem;
    background: #FFF;
}

#bookOrdersTotalTr td {
    border: none !important;
    background: rgb(235, 235, 235);
    color: rgb(92, 92, 92);
    font-weight: 600;
    font-size: 2rem !important;

}

#bookOrdersTotalLabel {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    align-items: center;
    justify-content: flex-start;
}
#bookOrdersTotalLabel a {
    font-size: 0.9rem;
}

#rubiOrderContainer table tbody tr th{
    width: 70rem;
    height: 3rem;
    font-size: 1.2rem;
}


#rubiOrderContainer table thead tr {
    color: white;   
    border: none;
    font-size: 1rem;
    text-align: center;
}



#rubiOrderContainer table tbody tr:hover{
    background-color: var(--tableHover);
}

#rubiOrderContainer table tbody td:first-child{
    width: 4rem;
}

#rubiOrderContainer table tbody td {
    border: 1px solid rgb(224, 224, 224);
    height: 2rem;
    text-align: center;
    font-size: 0.9rem !important;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#bookOrdersLoadingTr {
    height: 8rem !important;
    border: none !important;
}

#bookOrdersLoadingTr:hover{
    background: white !important;
}

.rubiOrderButton {
    
    height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    background: #333333  !important;
    color: #a5a5a5 !important;
    border-radius: 0 !important;
    width: 100px;
    font-size: 0.9rem;
    font-weight: 400;

}

.rubiOrderButton:hover{
    color: white !important;
}

.rubiOrderButton a {
    margin-left: 0.3rem;
}

.sendOrdersHide {
    display: none;
}

.fadeIn {

    transition: opacity 1s ease;
}
.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

.fileInput a:first-child {
    font-size: 1rem;
    font-weight: 400;
}

#booksImportCheckInformation {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem
}

#booksImportCheckInformation a {
    font-size: 1rem;
    font-weight: 500;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

#getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #flexRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.fileInput #bookImportSelectEffectiveDate {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.fileInput #bookImportSelectEffectiveDate p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

/*Calendário*/
.fileInput .react-calendar {
    width: 40rem;
}

.fileInput .react-date-picker  {
    width: 16rem;
    display: flex;
    font-size: 1.2rem;
    height: 2.4rem;
    flex-direction: column;
    align-items: center; 
}

.fileInput .react-date-picker__wrapper {
    height: 2.4rem;
}

.fileInput .react-date-picker__calendar {
    width: 40rem;
}

.fileInput .react-date-picker__inputGroup__day {
    width: 22px;
}

.fileInput .react-calendar__navigation button {
    font-size: 1.1rem;
    font-weight: 400;
}

.fileInput .react-date-picker .react-date-picker--open .react-date-picker--enabled {
    display: flex;
    flex-direction: column;
}
.fileInput .react-date-picker__calendar--open {
    position:   -webkit-sticky !important;
    position:   sticky !important; 
    box-shadow: 0px 0px 18px -8px #000000;

}

.fileInput .react-calendar__tile--now {
    background: rgb(218, 218, 218);

}

.tableErrorsImportOperation { 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImportOperation thead{ 

    color: white;
}

.tableErrorsImportOperation table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImportOperation thead tr {
  
    height: 1.5rem;
}

.tableErrorsImportOperation tbody tr{ 
    height: 2rem;
}

.tableErrorsImportOperation tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.tableForImportSection {
    max-width: 80rem;
    max-height: 30rem;
    overflow: auto; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSection table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSection table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSection table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableForImportSection table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

@media(max-width: 1368px){

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 33rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #flexRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }

    .fileInput #bookImportSelectEffectiveDate {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 0;
    }
    
    .fileInput #bookImportSelectEffectiveDate p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    /*Calendário*/
.fileInput .react-calendar {
    width: 40rem;
}

.fileInput .react-date-picker  {
    width: 16rem;
    display: flex;
    font-size: 1.2rem;
    height: 1.4rem !important;
    flex-direction: column;
    align-items: center; 
}

.fileInput .react-date-picker__wrapper {
    height: 1.4rem;
}

.fileInput .react-date-picker__calendar {
    width: 40rem;
}

.fileInput .react-date-picker__inputGroup__day {
    width: 22px;
}

.fileInput .react-calendar__navigation button {
    font-size: 1.1rem;
    font-weight: 400;
}

.fileInput .react-date-picker .react-date-picker--open .react-date-picker--enabled {
    display: flex;
    flex-direction: column;
}
.fileInput .react-date-picker__calendar--open {
    position:   -webkit-sticky !important;
    position:   sticky !important; 
    box-shadow: 0px 0px 18px -8px #000000;

}

.fileInput .react-calendar__tile--now {
    background: rgb(218, 218, 218);

}

    
    .tableForImportSection {
        max-width: 80rem;
        max-height: 20rem;
        overflow: auto; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSection table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSection table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSection table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImportOperation table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImportOperation table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSection table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}

#showAssetCardContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* overflow: scroll; */
}
.textEditorLoadingAssetCard {
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#assetCardPDF {
    height: 100%;
    width: 100%;
}

#assetCardPdfButtons {
    background: rgb(59 59 59);
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#assetCardPdfButtons a {
    color: white;
}

#assetCardPdfButtons input {
    display: none;
}

#assetCardPdfButtons button {
    background: rgb(114, 114, 114);
    border: none;
    width: 10rem;
    height: 2rem;
    margin-left: 0.5rem;
    font-size: 1rem;
    color: rgb(248, 248, 248);
    border-radius: 4px;
}

#assetCardPdfButtons button:hover {
    background: gray;
}

#assetCardPdfButtons button:last-child {
    margin-right: 0.5rem;
    background: rgb(3, 3, 97);
    font-size: 1rem;
    width: 10rem;
    border-radius: 4px;
}
#assetCardPdfButtons button:last-child:hover {
    background: rgb(5, 5, 117);
}


#showAssetCardHeader {
    background-color: var(--topBarBg);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#showAssetCardHeader p {
    color: white;
    font-size: 1.2rem;
    margin-left: 1rem;
}

#showAssetCardHeader button {

    margin-right: 1rem;
}

#showAssetCardFooter {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
}

#showAssetCardFooter  button{

    width: 10rem;
    height: 2rem;
    margin-top: 1rem;
    background-color: var(--topBarBg);
    color: white;
    border: none;
    font-size: 1rem;
    transition: 0.2s;
}

#showAssetCardFooter button:hover {
    background: white;
    color: var(--topBarBg);
    border: 1px solid var(--topBarBg);
}

#showAssetCardEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
    grid-gap: 1rem;
    gap: 1rem;
}

#showAssetCardEmpty a {
    font-size: 1rem;
}

#showAssetCardEmpty button {
    
    margin-right: 0.5rem;
    background: rgb(3, 3, 97);
    font-size: 1rem;
    width: 10rem;
    height: 2rem;
    margin-top: 1rem;
    color: white;
    border: none;
    font-size: 1rem;
    transition: 0.2s;
}

#showAssetCardEmpty button:hover {
    background: rgb(5, 5, 117);

}

#assetCardPdfButtonsEmpty input {
    display: none;
}


#assetCardNewFileReceveidMsg {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    margin-top: 0.2rem;
    right: 0;
    text-align: center;
    color: rgb(211, 211, 211);
    font-size: 1.5rem;
    font-weight: 500;
    
}
.pushOrdersContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1000px;
}
#pushOrdersTable td {
    text-align: center;
    height: 1.8rem;
    margin: 0 !important;
    padding: 0;
    background: white;
    font-size: 1rem;
    z-index: 1;
    border: 2px solid var(--tableBorder);
}

#pushOrdersTable td {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}


#pushOrdersTable td:first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15rem;
}

.pushOrdersTableLoader {
    display: flex;
    height: 100%;
}

#pushOrdersTable td:first-child {
    text-align: initial;
}

#pushOrdersTable td:first-child a {
    padding-left: 16px;
}

#pushOrdersTable td input {
    height: 1.6rem;
    border-radius: 0;
    border: none;
    background: #00000000;
    width: calc(100% - 16px);
    padding-left: 16px;
    font-size: 1rem;
    transition: 0.3s;
    overflow-x: hidden;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    outline: none;
}

#pushOrdersTable .MuiInputBase-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
#pushOrdersTable .pushOrdersButtonTd {
    transition: 0.2s;
}

#pushOrdersTable .pushOrdersButtonTd:hover {
    cursor: pointer;
    background: rgb(195, 195, 195);
}

#pushOrdersTable .pushOrdersButtonTd:hover label a {
    cursor: pointer;
}

.pushOrdersCheckBoxInactive {
    background: rgb(202, 202, 202);
    width: 1.8rem;
    height: 1.8rem;
    border: none;
    transition: 0.2s;
}

.pushOrdersCheckBoxInactive:hover {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.pushOrdersCheckBoxActive {
    background: #1E80EF;
    width: 1.8rem;
    height: 1.8rem;
    border: none;
    transition: 0.2s;
}

.pushOrdersCheckBoxActive:hover {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.pushOrdersCheckBoxActive a {
    color: white;
}

.errorThisRow { 
    box-shadow: inset 0px 0px 4px 1px #d10000;
}
.pushOrdersTable .pushOrdersSimpleInput:hover{
    border-bottom: 2px solid #070808 !important;
    margin-bottom: 0px;
}
.pushOrdersTable .pushOrdersSimpleInput:focus {
    border-bottom: 2px solid #07080800 !important;
}

#pushOrdersTable td:last-child {
    width: 6rem;
}
#pushOrdersTable td:last-child #ordensPushLastTd {
    width: 6rem !important;
}

#pushOrdersCV {
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#pushOrdersCV a {
    display: flex;
    align-items: center;
    justify-content: center;
}

#pushOrderFooter {
    width: calc(100% - 50px);
    position: absolute;
    bottom: 0;
    left: 49px;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    background: rgb(119, 1, 1);
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    transition: all ease 0.3s;
}

#pushOrderFooter a:nth-child(2) {
    transition: all ease 0.2s;
}

#pushOrderFooter a:nth-child(2):hover{
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.pushOrdersSelecionWithErrors {
    font-weight: 300;
    cursor: pointer;
    height: 2.4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    transition: all ease 1.6s;
    color: #FFF;
    border: 2px solid #ef1e1ead;
}

.pushOrdersTableContainer { 
    box-shadow: 0px 1px 20px -5px #000000;
    overflow-x: hidden;
    width: 95%;
    margin-top: 2rem;
}

.pushOrdersBasketTr {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    width: inherit;
    background: var(--topBarBg);
    height: 6rem;
    justify-content: space-between;
    align-items: center;
}

.pushOrdersBasketTable {
    display: flex;
    flex-direction: row;
}

.pushOrdersTable thead {
    border-left: 2px solid var(--topBarBg);
    border-right: 2px solid var(--topBarBg);
    font-size: 1rem;
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
}

.pushOrdersTable thead tr:first-child th {
    top: 0;
    background: var(--topBarBg);
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
    opacity: 1;
    
}

.pushOrdersTable thead tr:nth-child(2) th {
    opacity: 1;
    top: 6rem;
    position: -webkit-sticky;
    position: sticky;
    background: var(--tableSecondTh);
    height: 1.5rem;
    color: rgba(255, 255, 255, 0.856);
    font-weight: 300;
    z-index: 99;
}

.pushOrdersBasketLabel {
    display: flex;
    width: 300px;
    flex-direction: row;
    justify-content: center;
}

.pushOrdersBasketLabel label:nth-child(2) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    transition: all ease 0.2s;
    padding-right: 1rem;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    align-items: center;
    font-size: 1rem;
}

.pushOrdersBasketLabel label:first-child{
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    flex-direction: row;
    display: flex;
    width: 50%;
    color: #FFF;
    font-size: 1rem;
    font-weight: 300;
}

.pushOrdersSelection {
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 2.4rem;
    width: 6rem;
    transition: all ease 0.3s;
    color: rgb(102, 102, 102);
}

.pushOrdersSelection:hover {
    color: rgb(158, 158, 158);
    background:  #1e80ef1f;
}

.pushOrdersSelectionSelected {
    font-weight: 300;
    cursor: pointer;
    height: 2.4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    transition: all ease 1.6s;
    color: #FFF;
    background:  #1e80ef69;
}

.pushOrdersBasketButton {
    height: 2rem;
    width: 8rem;
    border: 1px solid var(--goldColor);
    background: var(--topBarBg);
    color: var(--goldColor);
    font-size: 1rem;
    transition: 0.2s;
    cursor: pointer;
}

.pushOrdersBasketButton:hover {
    background: var(--goldColor);
    color: black;
}


.pushOrdersOperationTypeBuy {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.3s;
    background: #1e80ef69;
    color: white;
}

.pushOrdersOperationTypeBuy:hover {
    background: #1e80ef;
}

.pushOrdersOperationTypeBuySelected {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.3s;
    background: #1e80ef;
    color: white;
}

.pushOrdersOperationTypeSell {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.6s;
    color: #FFF;
    background: #cb111142;
}

.pushOrdersOperationTypeSell:hover {
    background: #CB1111;
}

.pushOrdersOperationTypeSellSelected {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.6s;
    color: #FFF;
    background: #CB1111;
}

.pushOrdersBasketLabel .MuiInputBase-root {
    color: #FFF;
}

.pushOrdersBasketLabel .MuiInputBase-root  svg {
    color: #FFF;
}

.clientsIndicationMainContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}
 

.clientsIndicationTableTabs {
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
}

#toIndicate {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#toIndicate p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#toIndicate p:hover{
    color: white;
}

#pendingIndicationToApproval {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#pendingIndicationToApproval p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#pendingIndicationToApproval p:hover{
    color: white;
}

#pendingIndicationToApprovalTime {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#pendingIndicationToApprovalTime p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#pendingIndicationToApprovalTime p:hover{
    color: white;
}

#pendingIndicationToApprovalAdmin {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#pendingIndicationToApprovalAdmin p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#pendingIndicationToApprovalAdmin p:hover{
    color: white;
}

#indicationsFinished {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#indicationsFinished p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#indicationsFinished p:hover {
 color: white;
}

#myIndicationsHomeLoader {
    height: 15rem;
}

#myApprovalList td {
    cursor: pointer;
    font-size: 0.9rem;
}

#myClientsInputFilter {
    margin-right: 1rem;
}

#tabInactive {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
    transition: 0.1s;
}

#tabInactive p{
    color: #959595;
    font-size: 0.9rem !important;
}

#tabInactive:hover p{
    color: white;
}

.clientsIndicationTableIndicated {
    padding-top: 1.5rem;
    width:  95%;

}

.clientsIndicationTableIndicated table {
    width: 100%;
}

.clientsIndicationTableIndicated table thead tr:nth-child(2) {
    color: white;
    height: 1.5rem;
}

.clientsIndicationTableIndicated table thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.clientsIndicationTableIndicated table thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    font-size: 1rem !important; 
    font-weight: 300 !important;
    top: 4rem;
    opacity: 1;
    z-index: 100;
    background: var(--tableSecondTh);
}

.clientsIndicationTableIndicated tbody td {
    background: white;
    cursor: default;
    height: 2rem;
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--tableBorder);
}

.clientsIndicationTableIndicated tbody tr:hover {
    background: var(--tableHover);
}

.clientsIndicationBoxModal {
    width: 65rem;
    height: 18rem;
}

.clientAcceptMainContainerBox {
    width: 35rem;
    height: 26rem;
}

.indicatedProfileList {
    overflow: auto;
    max-width: 50rem;
    max-height: 25rem;
}

#theadProfileList {
    width: 100%;
}

#theadProfileList label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#theadProfileList label a {
    font-size: 1rem;
    margin-left: 0.5rem;
}

#closeProfileList {
    align-items: center;
    display: flex;
    right: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    transition: 0.1s;
    margin-right: 0.5rem;
    color: var(--formButtonFontColor)
}

#closeProfileList:hover {
    background: var(--buttonHover);

}

.indicatedProfileList table {
    width: 50rem;
    background: white;
}

.indicatedProfileList table thead tr th{
    background: var(--topBarBg);
    color: white;
    height: 2rem;
    font-size: 1rem;
}

.indicatedProfileList table td {
    border-left: 1px solid var(--tableBorder);
    border-bottom: 1px solid var(--tableBorder);
    font-size: 1rem;
    background: white;
    padding: 0.5rem;
    width: 2rem;
}

.tituloContatoRealizado{
    margin-top: 4rem;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
}

.listaClientes{
    width: 60%;
    text-align: center;
    margin: auto;
    margin-top: 3rem;
}

@media (max-width: 1368px){
    .clientsIndicationMainContainer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
    }
    .clientsIndicationBoxModal {
        width: 55rem;
        height: 18rem;
    }
    .tituloBrokerSegmento{
        position: absolute;
        text-align: center;
        width: 42rem !important;
    }
    .clientsIndicationTableTabs {
        width: 100%;
        height: 2rem;
        display: flex;
        flex-direction: row;
    }

    #pendingIndicationToApproval {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #pendingIndicationToApproval p {
        font-size: 0.9rem !important;
        color: #959595;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #pendingIndicationToApproval p:hover{
        color: white;
    }

    #pendingIndicationToApprovalTime {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #pendingIndicationToApprovalTime p {
        font-size: 0.9rem !important;
        color: #959595;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #pendingIndicationToApprovalTime p:hover{
        color: white;
    }

    #pendingIndicationToApprovalAdmin {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #pendingIndicationToApprovalAdmin p {
        font-size: 0.9rem !important;
        color: #959595;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #pendingIndicationToApprovalAdmin p:hover{
        color: white;
    }

    #indicationsFinished {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #indicationsFinished p {
        font-size: 0.9rem !important;
        color: white;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #indicationsFinished p:hover {
     color: white;
    }

    #myIndicationsHomeLoader {
        height: 15rem;
    }

    #myApprovalList td {
        cursor: pointer;
        font-size: 0.9rem;
    }

    #myClientsInputFilter {
        margin-right: 1rem;
    }

    #tabInactive {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
        transition: 0.1s;
    }

    #tabInactive p{
        color: #959595;
        font-size: 0.9rem !important;
    }

    #tabInactive:hover p{
        color: white;
    }
    
    .clientsIndicationTableIndicated {
        padding-top: 1.5rem;
        width:  95%;

    }

    .clientsIndicationTableIndicated div:nth-child(2) { 
        max-height: 25rem;
        overflow: auto;
        max-height: 25rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientsIndicationTableIndicated table {
        width: 100%;
    }
    
    .clientsIndicationTableIndicated table thead tr:nth-child(2) {
        color: white;
        height: 1.5rem;
    }
    
    .clientsIndicationTableIndicated table thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
        background: var(--topBarBg);
    }
    
    .clientsIndicationTableIndicated table thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        font-size: 1rem !important; 
        font-weight: 300 !important;
        top: 4rem;
        font-size: 0.9rem !important;
        opacity: 1;
        z-index: 100;
        background: var(--tableSecondTh);
    }

    .clientsIndicationTableIndicated tbody td {
        cursor: default;
        height: 2rem;
        font-size: 1rem;
        text-align: center;
        background: white;
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .clientsIndicationTableIndicated tbody tr:hover {
        background: var(--tableHover);
    }

    .clientsIndicationTableIndicated tbody tr:hover {
        background: var(--tableHover);
    }
    
    .clientsIndicationBoxModal {
        width: 55rem;
        height: 18rem;
    }

    .clientsIndicationSucessBoxModal {
        width: 55rem;
        height: 24rem;
    }
    
    .indicatedProfileList {
        overflow: auto;
        max-width: 50rem;
        max-height: 25rem;
    }
    
    #theadProfileList {
        width: 100%;
    }
    
    #theadProfileList label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    #theadProfileList label a {
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    
    #closeProfileList {
        align-items: center;
        display: flex;
        right: 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        transition: 0.1s;
        margin-right: 0.5rem;
        color: var(--formButtonFontColor)
    }
    
    #closeProfileList:hover {
        background: var(--buttonHover);
    
    }
    
    .indicatedProfileList table {
        width: 50rem;
        background: white;
    }
    
    .indicatedProfileList table thead tr th{
        background: var(--topBarBg);
        color: white;
        height: 2rem;
        font-size: 1rem;
    }
    
    .indicatedProfileList table td {
        border-left: 1px solid var(--tableBorder);
        border-bottom: 1px solid var(--tableBorder);
        font-size: 1rem;
        padding: 0.5rem;
        width: 2rem;
    }
        
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.clientsIndicationMainContainerBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF;
}

#clientsIndicationErrorFieldsMsg{
    color: red;
    font-weight: 400;
    margin-bottom: 0.3rem;
    font-size: 1rem;
}

.clientsIndicationBoxRequiredFields {
    color: red;
    font-size: 0.9rem;
    font-weight: 400;
    position: fixed;
    -webkit-transform: translate(-28rem, 3.6rem);
            transform: translate(-28rem, 3.6rem);
    
}

.clientsIndicationBoxRequiredFieldsTextArea {
    color: red;
    font-weight: 400;
    font-size: 0.9rem;
    position: fixed;
    -webkit-transform: translate(-58rem, 7.3rem);
            transform: translate(-58rem, 7.3rem); 
}


.clientsIndicationLoader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientsIndicationMainContainerBox a {
    font-size: 1.5rem;
    font-weight: 500;
}

.clientsIndicationsHeader {
    height: 5rem;
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientsIndicationsHeader button {
    margin-right: 2rem;
}

.clientsIndicationsHeader p {
    font-size: 1.2rem !important;
    margin-left: 2rem;
    border-bottom: 1px solid var(--goldColor);

}

.clientsIndicationsBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: #FFF;
}

.clientsIndicationsBody form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
}

.clientsIndicationCategory {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.clientsIndicationProfileList{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}

.clientsIndicationCategory .MuiFormLabel-root {
    color: black;
    border-bottom: 1px solid var(--goldColor);
}

.clientsIndicationCategory .MuiFormGroup-root{
    display: grid;
    grid-template-columns: repeat(3,1fr);
}

.clientsIndicationCategory .MuiRadio-colorSecondary.Mui-checked {
    color: blue;
}

.clientsIndicationDualGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    width: 100%;
}

.clientsIndicationOneGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.clientsIndicationOneGrid label {
    width: 100%;
}

#submitButton {
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

#submitButton:hover {
    background: var(--buttonHover);
}

@media(max-width:1368px){
    .clientsIndicationMainContainerBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFF;
    }
    
    .clientsIndicationDualGrid .MuiInputBase-root {
        height: 2rem;
    }


.clientsIndicationBoxRequiredFields {
    color: red;
    font-size: 0.8rem;
    font-weight: 400;
    position: fixed;
    -webkit-transform: translate(-320px, 35px);
            transform: translate(-320px, 35px);
    
}

.clientsIndicationBoxRequiredFieldsTextArea {
    color: red;
    font-weight: 400;
    font-size: 0.8rem;
    position: fixed;
    -webkit-transform: translate(-680px,115px);
            transform: translate(-680px,115px); 
}


.clientsIndicationsBody form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  
    
    .clientsIndicationLoader {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .clientsIndicationMainContainerBox a {
        font-size: 1.5rem;
        font-weight: 500;
    }
    
    .clientsIndicationsHeader {
        height: 5rem;
        background: var(--headerAllBg);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsIndicationsHeader button {
        margin-right: 2rem;
    }
    
    .clientsIndicationsHeader p {
        font-size: 1.2rem !important;
        margin-left: 2rem;
        border-bottom: 1px solid var(--goldColor);
    
    }
    
    .clientsIndicationsBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    
    .clientsIndicationsBody form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
    }
    
    .clientsIndicationCategory {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }
    
    .clientsIndicationProfileList{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
    }
    
    .clientsIndicationCategory .MuiFormLabel-root {
        color: black;
        border-bottom: 1px solid var(--goldColor);
    }
    
    .clientsIndicationCategory .MuiFormGroup-root{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    
    .clientsIndicationCategory .MuiRadio-colorSecondary.Mui-checked {
        color: blue;
    }
    
    .clientsIndicationDualGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        grid-gap: 2rem;
        gap: 2rem;
    }
    
    .clientsIndicationOneGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    
    .clientsIndicationOneGrid label {
        width: 100%;
    }
    
    #submitButton {
        width: 8rem;
        height: 2rem;
        background: white;
        font-size: 0.9rem;
        margin-top: 1rem;
        font-weight: 400;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
    }
    
    #submitButton:hover {
        background: var(--buttonHover);
    }
    
    
}

.idNomeCliente{
    font-size: 1.4rem;
    padding: 0.3rem;
    border-bottom: 1px solid #c8c8c8;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
}

.containerNetRv{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.containerField{
    display: grid;
    grid-template-rows: auto auto;
}

.containerOp{
    padding-left: 2rem;
    padding-right: 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.opcional{
    margin-bottom: 0.4rem;
}

.netRvAtual{
    font-size: 1.2rem;
    font-weight: 400;
}

.netRvAtualValor{
    font-size: 1.2rem;
    color: #a57e00;
}

.netRvPotencialValor{
    font-size: 1.2rem;
    color: #1976d2;
}

.brokerSegmentoBox{
    padding-top: 0.65rem;
}

.infoBrokerSegmento{
    background-color: #c8c8c850;
    padding: 1.6rem;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.5rem;
    margin: auto;
}

.tituloBrokerSegmento{
    position: absolute;
    text-align: center;
    width: 64rem;
}

.tituloBrokerSegmentoSUBS{
    text-align: center;
}

.obervationsIndication{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.containerSubmitButton{
    text-align: center;
    margin-bottom: 2rem;
}

.infoBoxIndication{
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #dfdfdf;
    padding: 0.6rem;
    font-weight: 400;
    box-shadow: 0px 0px 0.3rem 1px rgb(158, 158, 158);
}

.tituloSucessoIndicacao{
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    padding-top: 3rem;
}

.infoClienteSucesso{
    text-align: center;
    font-size: 0.9rem;
    padding-top: 2rem;
    font-weight: 500;
}

.aporteClienteSucesso{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}

.disclaimerContatoBroker{
    text-align: center;
    padding-top: 2rem;
    font-weight: 400;
}

.containerOpcoesSucessoIndicacao{
    /* display: grid; */
    grid-template-columns: 1fr 2px 1fr;
    width: 100%;
    margin: auto;
    text-align: center;
    padding-top: 2rem;
}

.linhaDivisoria{
    background-color: rgba(37, 64, 109, 1);
    width: 100%;
    height: 100%;
}

.textoOpcaoSucesso{
    font-size: 1.1rem;
    font-weight: 400;
}

.closeIndicationSuccess{
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-top: 3rem;
}

.closeIndicationSuccess:hover{
    background: var(--buttonHover);
    color: white;
}

.containerBrokerSelection{
    display: grid;
    width: 90%;
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0.6rem;
}

.brokerSelectionOption{
    width: 94%;
    max-width: 94%;
    margin: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5.5rem;
    font-size: 1rem;
    background: #F9F9F9;
    border-radius: 8px;
    border: 1px solid rgb(216, 216, 216);
    color: rgb(63, 63, 63);
    transition: all ease-in-out 0.1s;
}
.brokerSelectionOption:hover{
    background: #fcfcfc;
    border: 1px solid #c49f17bb;
    color: #5c4800d5;
    box-shadow: 0px 0px 14px #c49f17bb;
}
.brokerSelectionOptionActive{
    width: 94%;
    max-width: 94%;
    margin: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5.5rem;
    font-size: 1rem;
    background: #fcfcfc;
    border-radius: 8px;
    border: 1px solid #c49e17;
    color: #5c4800;
    transition: all ease-in-out 0.1s;
    box-shadow: 0px 0px 14px #c49e17;
}

.containerClientIndicationForm{
    width: 100%;
    display: grid;
}

.brokerSelectionType{
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.brokerSelectionName{
    font-size: 1rem;
}

.brokerSelectionId{
    margin-top: 0.2rem;
    font-size: 0.9rem;
}

.warningNoDefaultBroker{
    background-color: rgb(156, 0, 0);
    color: white;
    font-size: 1.2rem;
    padding: 0.4rem;
    text-align: center;
}
.clientsIndicationToApprovalContainer {
    background: white;
    width: 80rem;
    height: 35rem;
    display: flex;
    flex-direction: column;   
}

.clientsIndicationLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label {
    display: flex;
    width: 90%;

}

.clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label a{
    font-size: 1rem;
    font-weight: 300;
    border-bottom: 1px solid var(--goldColor);
    margin-bottom: 0.5rem;
}

.clientsToApprovalBodyContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.clientsIndicationToApprovalHeader {
    height: 4rem;
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    flex-direction: row ;
    align-items: center;
    justify-content: space-between;
}

.clientsIndicationToApprovalHeader p {
    font-size: 1.2rem !important;
    border-bottom: 1px solid var(--goldColor);
    margin-left: 1rem;    
}

.clientsIndicationToApprovalHeader button {
    margin-right: 1rem;
}

.clientsIndicationToAprrovalUppper a {
    font-weight: 300;
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.clientsIndicationToAprrovalUppper {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.clientsIndicationLabelInfo {
    height: 3.4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid #d3d3d3;
}

.clientsIndicationLabelInfo a:first-child {
    font-weight: 500;
}

.clientsIndicationToApprovalProfileListIndicated {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem
}

.clientsIndicationToApprovalLower {
    border: 1px solid #d3d3d3;
    width: 76.7rem;
    height: 6rem;
    max-width: 76.7rem;
    max-height: 6rem;
    overflow: auto;

    padding: 0.5rem;
}

.clientsIndicationToApprovalLower a {
    font-size: 1rem;
    font-weight: 300;
}

.clientsIndicationToAprrovalProfilesIndicated {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid #d3d3d3;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background:  var(--tableSecondTh);
    color: white;
}

.clientsIndicationToAprrovalProfilesIndicated a{
    font-size: 1rem;
    font-weight: 400;
}

.clientIndicationsApprovalRejectButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.clientsIndicationProfilesToChooseDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.clientsIndicationProfilesToChooseDiv .MuiInputBase-root {
    width: 17.8rem !important;
}

.clientsIndicationHide {
    display: none;
}

.handleSelectedStatus{
    background: #ffffff;
    color: var(--goldColor);
    height: 2.4rem;
    outline: none;
    font-size: 1rem;
    font-weight: 700;
    border: 1px solid var(--goldColor);
    width: 25rem;
}

/* @media(max-width:1368px){ */

    


    .clientsIndicationToApprovalContainer {
        background: white;
        width: 70rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;    
    }
    
    .clientsIndicationLoader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
    .clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label {
        display: flex;
        width: 90%;
    
    }
    
    .clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label a{
        font-size: 1rem;
        font-weight: 300;
        border-bottom: 1px solid var(--goldColor);
        margin-bottom: 0.5rem;
    }
    
    .clientsToApprovalBodyContainer {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-bottom: 0rem;
    }
    
    .clientsIndicationToApprovalHeader {
        height: 4rem;
        background: var(--headerAllBg);
        width: 100%;
        display: flex;
        flex-direction: row ;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsIndicationToApprovalHeader p {
        font-size: 1rem !important;
        border-bottom: 1px solid var(--goldColor);
        margin-left: 1rem;    
    }
    
    .clientsIndicationToApprovalHeader button {
        margin-right: 1rem;
    }
    
    .clientsIndicationToAprrovalUppper a {
        font-weight: 300;
        font-size: 0.9rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    
    .clientsIndicationToAprrovalUppper {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 1rem;
        gap: 1rem;
        margin-bottom: 1rem;
    }
    
    .clientsIndicationLabelInfo {
        height: 2rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 1px solid #d3d3d3;
    }
    
    .clientsIndicationLabelInfo a:first-child {
        font-weight: 500;
    }
    
    .clientsIndicationToApprovalProfileListIndicated {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem
    }
    
    .clientsIndicationToApprovalLower {
        border: 1px solid #d3d3d3;
        width: calc(100% - 1rem);
        height: 6rem;
        max-width: calc(100% - 1rem);
        max-height: 6rem;
        overflow: auto;
        padding: 0.5rem;
    }
    
    .clientsIndicationToApprovalLower a {
        font-size: 0.9rem;
        font-weight: 300;
    }
    
    .clientsIndicationToAprrovalProfilesIndicated {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border: 1px solid #d3d3d3;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        background:  var(--tableSecondTh);
        color: white;
    }
    
    .clientsIndicationToAprrovalProfilesIndicated a{
        font-size: 0.9rem;
        font-weight: 400;
    }
    
    .clientIndicationsApprovalRejectButtons {
        display: flex;
        flex-direction: row;
        width: 100%;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
    }
    
    .clientsIndicationProfilesToChooseDiv {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 1rem;
    }
    
    .clientsIndicationProfilesToChooseDiv .MuiInputBase-root {
        width: 17.8rem !important;
    }
    
    .clientsIndicationHide {
        display: none;
    }
/* } */
#myClientsPendingsInputFilter {
    margin-right: 1rem;
}

#myIndicationsPendingsHomeLoader {
    height: 15rem;
}

.clientsIndicationPendingsTableContainer tr:hover td {
    background: rgb(204, 204, 204) !important;
}

#myPendingsApprovalList td {
    background: white;
    cursor: pointer;
    font-size: 0.9rem;
}

#myPendingsApprovalList24 td {
    background: rgb(255, 197, 73);
    cursor: pointer;
    font-size: 0.9rem;
}

#myPendingsApprovalList48 td {
    background: rgb(255, 59, 59);
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: underline;
}



.clientsIndicationPendingsTableContainer {
    overflow: auto;
    width: 100%;
    max-height: 40rem; 
    box-shadow: -2px 2px 20px -7px #000000;

}

.clientsIndicationPendingsTableFirstThead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 4rem;
    border-radius: 0.5rem 0 0 0;
    background: var(--topBarBg);
}


.clientsIndicationPendingsCursorDefault {
    cursor: default !important;
}

.clientsIndicationTableFirstThead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 4rem;
    border-radius: 0.5rem 0 0 0;
    background: var(--topBarBg);
}

.clientsIndicationPendingsTableFirstThead label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-right: 1rem;
}

.clientsIndicationPendingsTableFirstThead label input {
    width: 10rem;
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 1rem;
}


.clientsIndicationPendingsTableFirstThead p {
    font-size: 1.2rem !important;
    margin-left: 2rem;
    font-weight: 300;
    white-space: nowrap;
}

.totalPendings{
    color: var(--goldColor);
    font-weight: 700;
    font-size: larger;
    margin-left: 1rem;
}
.myClientsArrowLoader {
    
    height: 30rem;
    
}

#myClientsTableNameAndTotalClients {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#myClientsTableNameAndTotalClients a:first-child {
  font-size: 1.2rem;
  font-weight: 300;
}

#myClientsTableNameAndTotalClients a:nth-child(2){
  font-size: 0.9rem;
  font-weight: 200;
}

#myClientsEmptyRow{
    height: 2rem;
    font-size: 1.2rem !important;
}

.myClientsTableContainer  {
    
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    border-radius: 0.5rem 0 0 0; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myClientsTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.myClientsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.myClientsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.myClientsTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.myClientsTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:first-child th {
    width: 120rem;
}

.myClientsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.myClientsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.myClientsTableContainer tbody tr:hover{
    background: var(--tableHover);
    cursor: pointer;
}

.myClientsTableContainer tbody td {
    padding: 0;
    max-width: 6rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* width: 24rem; */
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
}

.myClientsTableContainer .myClientsTheadFirst{
    display: flex;
    flex-direction: row;
}

.myClientsTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myClientsTheadFirst a {
    margin-left: 2rem;
}

.myClientsTheadFirst button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.myClientsTheadFirst label:nth-child(2){
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap:0.5rem;
  gap:0.5rem;
}

.myClientsTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.myClientsTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.myClientsTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.myClientsTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.css-ubpweo-MuiPaper-root-MuiPopover-paper{
  min-width: 30vw !important;
}

#myClientsShowLoadingScroll{
    background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
}

#myClientsHideLoadingScroll {
    display: none;
}

.modalMyClients {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.ldsellipsis {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
  }
  .ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }


@media (max-width: 1368px){
.modalMyClients{
  width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.myClientsArrowLoader {

    height: 20rem;
    
}

#myClientsEmptyRow{
    height: 2rem;
    font-size: 1.2rem !important;
}

.myClientsTableContainer  {
    
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    border-radius: 0.5rem 0 0 0; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myClientsTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.myClientsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.myClientsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.myClientsTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.myClientsTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}


.myClientsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.myClientsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    transition: 0.2s;
}

.myClientsTableContainer tbody tr:hover{
    background: var(--tableHover);
}

.myClientsTableContainer tbody td {
    padding: 0;
    max-width: 6rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
}

.myClientsTableContainer .myClientsTheadFirst{
    display: flex;
    flex-direction: row;
}

.myClientsTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myClientsTheadFirst a {
    margin-left: 2rem;
}

.myClientsTheadFirst button {
    margin-left: 2rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.myClientsTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.myClientsTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.myClientsTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.myClientsTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

#myClientsShowLoadingScroll{
    background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
}

#myClientsHideLoadingScroll {
    display: none;
}

.ldsellipsis {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
  }
  .ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

}

  .brokerProfilesMainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  #newBrokerProfile:focus {
    background: var(--goldColor);
    color: black;
  }

  #newProfileSubmitButton:focus {
    border: 2px solid var(--formBorder);
    font-size: 400;
  }
  
  #brokerProfilesLoader {
    height: 15rem;
  }

  .brokerProfilesBody {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .brokerProfilesTableContainer {
    overflow-x: hidden;
    max-height: 30rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    border-radius: 0.5rem 0 0 0;
  }
    
  
  .brokerProfilesTableContainer table {
    width: 100%;
    
  }

  .theadTitle{
    width: 100%;
    display: flex;  
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background: var(--topBarBg) !important;
  }

  .brokerProfilesBody table thead tr:first-child th div p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1.5rem;
  }

  .brokerProfilesBody table thead tr:first-child th div button {
    margin-left: 2rem;
    margin-right: 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
  }
  .brokerProfilesBody table thead tr:first-child th div button:hover{
    background: var(--formBorder);
    color: black;
  }

  .brokerProfilesBody table thead th {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    background: var(--tableSecondTHGray);
    height: 1.5rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
  }
  
  .brokerProfilesBody table tbody td {
    background: white;
    cursor: default;
    text-align: center;
    height: 2rem;
    font-size: 1rem;
    transition: 0.1s;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
  }
  
  .brokerProfilesBody table #lastTd {
    width: 7rem;
  }
  
  .brokerProfilesBody table tbody tr:hover {
    background: var(--tableHover);
  }
  
  .brokerProfilesCadastration {
    width: 65rem;
    height: 35rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .brokerProfilesCenterItens{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .brokerProfilesCenterItens button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    font-weight: 500;
    width: 7rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .brokerProfilesCenterItens p {
    font-size: 1.5rem !important;
    font-weight: 400;
    border-bottom: 1px solid var(--goldColor);
  }
  
  .brokerProfilesCenterItens button:hover{
    background-color: var(--buttonBG);
  }
  
  .borkerProfilesCadastrationHeader {
    background: var(--headerAllBg);
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .borkerProfilesCadastrationHeader p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
    margin-left: 1rem;
  }
  
  .borkerProfilesCadastrationHeader button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.8rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .borkerProfilesCadastrationHeader button:hover{
    background-color: var(--buttonBG);
  }
  
  .brokerProfileCadastrationBody {
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 4rem);
  }
  
  .brokerProfileCadastrationBody form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .brokerProfileCadastrationBody form label {
  
    width: 70%;
  
  }
  
  


@media(max-width:1368px){


  .brokerProfilesMainContainer {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  
  #brokerProfilesLoader {
    height: 15rem;
  }

  .brokerProfilesBody {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .brokerProfilesTableContainer {
    overflow-x: hidden;
    max-height: 30rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    border-radius: 0.5rem 0 0 0;
  }
    
  
  .brokerProfilesTableContainer table {
    width: 100%;
    
  }

  .theadTitle{
    width: 100%;
    display: flex;  
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background: var(--topBarBg) !important;
  }

  .brokerProfilesBody table thead tr:first-child th div p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1.5rem;
  }

  .brokerProfilesBody table thead tr:first-child th div button {
    margin-left: 2rem;
    margin-right: 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
  }
  .brokerProfilesBody table thead tr:first-child th div button:hover{
    background: var(--formBorder);
    color: black;
  }

  .brokerProfilesBody table thead th {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    background: var(--tableSecondTHGray);
    height: 1.5rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
  }
  
  .brokerProfilesBody table tbody td {
    cursor: default;
    text-align: center;
    height: 1.8rem;
    font-size: 1rem;
    transition: 0.1s;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
  }
  
  .brokerProfilesBody table #lastTd {
    width: 7rem;
  }
  
  .brokerProfilesBody table tbody tr:hover {
    background: var(--tableHover);
  }
  
  .brokerProfilesCadastration {
    width: 50rem;
    height: 25rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .brokerProfilesCenterItens{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .brokerProfilesCenterItens button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    font-weight: 500;
    width: 7rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .brokerProfilesCenterItens p {
    font-size: 1.5rem !important;
    font-weight: 400;
    border-bottom: 1px solid var(--goldColor);
  }
  
  .brokerProfilesCenterItens button:hover{
    background-color: var(--buttonBG);
  }
  
  .borkerProfilesCadastrationHeader {
    background: var(--headerAllBg);
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .borkerProfilesCadastrationHeader p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
    margin-left: 1rem;
  }
  
  .borkerProfilesCadastrationHeader button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.8rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .borkerProfilesCadastrationHeader button:hover{
    background-color: var(--buttonBG);
  }
  
  .brokerProfileCadastrationBody {
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 4rem);
  }
  
  .brokerProfileCadastrationBody form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .brokerProfileCadastrationBody form label {
  
    width: 70%;
  
  }

}
.cropperImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    /* flex-direction: column-reverse; */
    background: #2f2f2f;
    justify-content: center;
    /* align-items: center; */

}

#userEdittedImageLoader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cropperImageContainer #imgCropped {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
}

.cropperButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 10rem;
}

.cropperButton button {
  outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        width: 6.6rem;
        height: 1.8rem;
        border-radius: 0.6rem;
        transition: 0.1s;
        margin-top: 2rem;
        color: var(--formButtonFontColor);  
}

.cropArea {

  background: #5c5c5c;
  width: 100%;
  height: 100%;
  
}

.cropArea #setCroppedButton {
  z-index: 1;
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;


  outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        width: 6.6rem;
        height: 1.8rem;
        border-radius: 0.6rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);  
}
.reactEasyCrop_Contain {
  max-width: 50% !important;
  max-height: 50% !important;
  margin: auto;
}

.reactEasyCrop_CropArea {
  border-radius: 50%;
  border: 2px solid gray !important;
}

#croppedImage {
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid gray;
  width: 500px;
  position: absolute;
}

#croppedImageConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 90px);
  position: absolute;
  top: 85%;
  z-index: 1;
}

#croppedImageEdition {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: calc(100% - 90px);
  position: absolute;
  top: 90%;
  z-index: 1;
}


#croppedImageEdition button {
  outline: none;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 6.6rem;
  height: 1.8rem;
  border-radius: 0.6rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);  
}

#croppedImageEdition button:hover {
  background: var(--goldColor);
  color: #2f2f2f
}

#croppedImageConfirmation a {
  font-size: 1.4rem;
  color: white;
}

#croppedImageConfirmation div {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 2.5rem;

}

#croppedImageConfirmation button {
  outline: none;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 6.6rem;
  height: 1.8rem;
  border-radius: 0.6rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);  
}

#croppedImageConfirmation button:hover {
  background: var(--goldColor);
  color: #2f2f2f
}

#uploadSuccessMsg {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: calc(50% - 90px);
  grid-gap: 1rem;
  gap: 1rem;
}

#uploadSuccessMsg a {
  font-size: 1.2rem;
  color: white;
}

#uploadSuccessMsg button {
  outline: none;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 6.6rem;
  height: 1.8rem;
  border-radius: 0.6rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);  
}

#uploadSuccessMsg button:hover {
  background: var(--goldColor);
  color: #2f2f2f
}

@media(max-width:1366px){

  #croppedImage {
    top: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid gray;
    width: 350px;
    position: absolute;
  }

  #croppedImageConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 90px);
    position: absolute;
    top: 80%;
    z-index: 1;
  }
}
.booksConfigurationContainer {
    height: calc(100% - 6px);
    width: calc(100% - 1px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booksTab {
    display: flex;
    flex-direction: row;
    width: 95%;
    cursor: pointer;
    margin-top: 2rem;
    
}

.booksTabOption {
   
    color: white;
    background: var(--topBarBg);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.booksTabOption div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.booksInactiveTabOption:hover {
    color: white;
}

.booksInactiveTabOption {
    color: white;
    background: var(--topBarBg);
    color: rgba(255, 255, 255, 0.534);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.booksInactiveTable{
    display: none;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.booksConfigurationTableBooksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-height: 35rem;
    overflow-x: hidden;
    background: white; 
    box-shadow: -2px 2px 20px -7px #000000;
    
}

.tableBookRegisteredLoader {
    height: 10rem;
}


#tableBooksRegistered {
    width: 100%;
}

#tableBooksRegistered thead tr:first-child th{
    background: var(--topBarBg);
    position: -webkit-sticky;
    position: sticky;
    top:0;
}

#tableBooksRegistered thead tr:first-child th div {
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#tableBooksRegistered thead tr:first-child th div a {
    font-size: 1.2rem;
    font-weight: 300;
    color: white;
    margin-left: 2rem;
}

#tableBooksRegistered thead tr:first-child th div label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
}

#tableBooksRegistered thead tr:nth-child(2) th {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 3.5rem;
}

#tableBooksRegistered tbody tr td {
    border: 1px solid var(--tableBorder);
    height: 2rem;
    text-align: center;
    font-size: 1rem;
}

#tableBooksRegistered tr td:last-child {
    border-right: none !important ;
}

#tableBooksRegistered tbody tr {
    transition: 0.2s;
}

#tableBooksRegistered tbody tr:hover {
    background: var(--tableHover);
}

#booksEmptyRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2rem;
}

#booksEmptyRow a {
    font-size: 1rem !important;
    font-weight: 400;
}

#bookLegend {
    opacity: 0;
    position: absolute;
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
    color: white;
    padding: 0.3rem;
    border-radius: 5px;
    font-size: 0.9rem;
}

#tableBooksRegistered tbody tr td:hover #bookLegend {
    opacity: 1;
    transition: all 0.3s ease;  
    transition-delay: 0.65s;
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
    background: var(--topBarBg);
}

#booksRegisteredAlterOrder {
    width: 2rem;
    border: 1px solid rgb(200, 200, 200);
    height: 1.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0.3rem;
    border-radius: 3px;
    /* outline: 1px solid rgb(146, 145, 145); */
}

#booksRegisteredAlterOrder:focus {
    outline: none;
    border:1px solid rgb(136, 136, 136);
}

.tableBooksRegisteredOrderBookDiv {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}


@media(max-width: 1368px){
    .booksConfigurationTableBooksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-height: 30rem;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
        
    }
}

.booksConfigurationTableBooksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-height: 35rem;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
    
}

.tableBookInactiveLoader {
    height: 10rem;
}


#tableBooksInactive {
    width: 100%;
}

#tableBooksInactive thead tr:first-child th{
    background: var(--topBarBg);
    position: -webkit-sticky;
    position: sticky;
    top:0;
}

#tableBooksInactive thead tr:first-child th div {
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#tableBooksInactive thead tr:first-child th div a {
    font-size: 1.2rem;
    font-weight: 300;
    color: white;
    margin-left: 2rem;
}

#tableBooksInactive thead tr:first-child th div label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}

#tableBooksInactive thead tr:nth-child(2) th {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 3.5rem;
}

#tableBooksInactive tbody tr td {
    border: 1px solid var(--tableBorder);
    height: 2rem;
    text-align: center;
    font-size: 0.9rem;
}
#tableBooksInactive tbody tr td:nth-child(5){
    width: 9rem;
}

#tableBooksInactive tbody tr td:nth-child(6){
    width: 5rem;
}
#tableBooksInactive tbody tr td:nth-child(7){
    width: 5rem;
}

#tableBooksInactive tr td:last-child {
    border-right: none !important ;
}

#tableBooksInactive tbody tr {
    transition: 0.2s;
}

#tableBooksInactive tbody tr:hover {
    background: var(--tableHover);
}

#booksEmptyRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2rem;
}

#booksEmptyRow a {
    font-size: 1rem !important;
    font-weight: 400;
}

#bookLegend {
    opacity: 0;
    position: absolute;
    -webkit-transform: translate(10px, 0px);
            transform: translate(10px, 0px);
    color: white;
    padding: 0.3rem;
    border-radius: 5px;
    font-size: 0.9rem;
}

#tableBooksInactive tbody tr td:hover #bookLegend {
    opacity: 1;
    transition: all 0.3s ease;  
    transition-delay: 0.65s;
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
    background: var(--topBarBg);
}


@media(max-width: 1368px){
    .booksConfigurationTableBooksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-height: 30rem;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
        
    }
}

/* BooksRegisterTable */
#bookRegisterPlusTd {
    cursor: pointer !important;
}

#bookRegisterTypeOperationDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#booksRegisterEditNameDiv {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
    height: 2rem;
}

#bookRegisterEditNameButton {
    font-size: 1.2rem;
    cursor: pointer;
}


#bookRegisterTypeOperationDiv a {
    font-size: .9rem;
    font-weight: 400;
}

#bookRegisterLabelInstructions button {
    width: 1.5rem;
    height: 1.2rem;
    display: inline-block;
    justify-content: center;
    transition: 0.2s;
    outline: none;
}


#bookRegisterEditNameConfirmButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

#bookRegisterEditNameConfirmButton:hover {

    background: var(--goldColor);
    color: white;
}

#bookRegisterLabelInstructions button:focus {
    border: 1px solid black !important;
}

#bookRegisterLabelInstructions button span {
    border-top: 1px solid rgb(255, 255, 255);
    border-left: 1px solid rgb(255, 255, 255);
    width: 0.4rem;
    height: 0.6rem;
    -webkit-transform: rotate(220deg) translate(-1px);
            transform: rotate(220deg) translate(-1px);
    
    display: inline-block;
    transition: 0.2s;
}

#bookRegisterTypeOperation span a {
    opacity: 0;
}

.bookRegisterFixedFields td {
    cursor: default !important;
    color: rgb(80, 80, 80);
}


.booksRegisterTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.bookRegisterLoader {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booksRegisterHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.booksRegisterHead  p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1rem !important;
    font-weight: 300;
    margin-left: 2rem;
    
}

.booksRegisterHead .modalCloseButton {
    margin-right: 2rem;
    margin-top: 1rem;
}

.bookRegisterTableContent {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    width: 85rem;
    height: 75%;
    max-height: 75%;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.bookRegisterTableContent p {
    font-size: 0.9rem !important;
}

.bookRegisterTableContent label:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bookRegisterInputName {
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

#bookRegisterLabelInstructions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#bookRegisterLabelInstructions input {
    height: 1.5rem;
    width: 15rem;
    font-size: 1.1rem;
    font-weight: 400;
    padding-left: 0.5rem;
}

#bookRegisterLabelInstructions input::-webkit-input-placeholder{
    color: rgb(78, 78, 78);
    font-size: 1.1rem;
}

#bookRegisterLabelInstructions input::placeholder{
    color: rgb(78, 78, 78);
    font-size: 1.1rem;
}

#bookRegisterLabelInstructions input:focus {
    border-bottom: 1px solid var(--goldColor);   
}

#bookRegisterEditName{
    font-size: 1.2rem !important;
}

.bookRegisterTableContent label p{
    font-size: 1rem !important;
    font-weight: 400;
}

#bookRegisterPreviewText {
    font-size: 1.1rem !important;
}


.bookRegisterInstructions {
    display: flex;
    width: 95%;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}

.bookRegisterInstructions p{
    font-size: 1rem !important;
}

.bookRegisterInstructions label {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
.bookRegisterInstructions label div input {
    height: 1.5rem;
}

/*Tabela preview */

.booksRegisterTablePreview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booksRegisterTablePreview div {
    height: 3rem;
}

.booksRegisterTablePreview div p{
    font-size: 1rem !important;
}

.booksRegisterTablePreview 

#bookRegisterPreviewTableTitle {
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}


#bookRegisterPreviewTableTitle p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1rem;
}

.booksRegisterTablePreview #booksRegisterTableToPreview { 
    box-shadow: -2px 2px 20px -7px #000000;
}

#booksRegisterTableToPreview thead th {
    font-size: 1rem;
}

#booksRegisterTableToPreview thead tr:first-child {
    background: var(--topBarBg);
    color: white;
    height: 4rem;
}

#booksRegisterTableToPreview thead tr:first-child th:first-child  {
    border-radius: 5px 0px 0px 0px;
}

#booksRegisterTableToPreview thead tr:nth-child(2) {
    color: white;
    background: var(--tableSecondTh);
} 

#booksRegisterTableToPreview thead tr:nth-child(2) th{
    font-weight: 300 !important;
    font-size: 1rem !important;
    padding: 0.3rem;
}

#booksRegisterTableToPreview tbody td {
    height: 2rem;
    text-align: center;
    width: 8rem;
    cursor: default !important;
}

#bookRegisterConfirmationBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#bookRegisterConfirmationBox a {
    font-size: 1rem !important;
    margin-bottom: 0.8rem;
}

#bookRegisterConfirmationBoxButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 4rem;
}
.booksRegisterTablePreview tbody td {
    font-size: 1rem;
}

/*Tabela para configuração do Book*/

#bookRegisterNewFieldsTable{
    box-shadow: 0px 2px 19px -8px black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable{
    background-color: #ececec;
    border-radius: 0.3rem;
    width: 100%;
    font-size: 1rem !important;
  }

#bookRegisterNewFieldsTable tr:last-child td:hover {
    font-weight: 300 !important;
}

#bookRegisterNewFieldsTable ::-webkit-scrollbar {
    width: 10px;
}
  
#bookRegisterNewFieldsTable ::-webkit-scrollbar-track {
    background: #ebebeb;
}
  
#bookRegisterNewFieldsTable ::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

#bookRegisterNewFieldsTable ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}


#bookRegisterFixedFields {
    height: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bookRegisterFixedFields a {
    font-size: 1rem;
}

#bookRegisterFixedFields input:hover::-webkit-input-placeholder {
    font-weight: 400;
}

#bookRegisterFixedFields input:hover::placeholder {
    font-weight: 400;
}

#bookRegisterFixedFields input {
    width: 13.5rem;
    height: 2.4rem;
    padding-left: 4px;
    text-align: center;
    border: none;
    font-size: 1rem;
}

#bookRegisterFixedFields input::-webkit-input-placeholder {
    color: rgb(68, 68, 68);
    font-size: 1rem;
}

#bookRegisterFixedFields input::placeholder {
    color: rgb(68, 68, 68);
    font-size: 1rem;
}

#bookRegisterNewFieldButton {
    background: white;
    border: none;
    font-size: 1rem;
    width: 7rem;
    transition: 0.2s;
}

#bookRegisterNewFieldButton:hover {
    background: var(--goldColor);
    color: black;
}
#bookRegisterNewFieldButton:focus {
    background: var(--goldColor);
    color: black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable  #bookRegisterTableThead{
    text-align: center;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableThead {
    background: var(--topBarBg);
    color: white;
    height: 2.5rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody{
    background-color: #fff;  
    text-align: center;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableBody {
    display: block;
    overflow-x: hidden;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead, #bookRegisterTableBody tr {
    padding-top: 0.35rem;
    display: table;
    width: 85rem;
    table-layout: fixed;
  }
  #bookRegisterNewFieldsTable  #bookRegisterTableThead {
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid var(--goldColor) !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(1) td{
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #005f9e36 !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(2) td{
      padding-top: 0.5rem;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody tr {
    height: 2rem;
    font-size: 1rem !important;
}
    

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody td {
      border: 1px solid var(--tableBorder);
      height: 2.5rem;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody tr:nth-last-child(2) td:nth-child(6):hover {
    background-color: rgba(128, 128, 128, 0.267);
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(1){
      width: 4rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(2){
      width: 8rem;
  }
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(3){
      width: 8rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(4){
      width: 8rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(5){
      width: 6rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(6){
      width: 4rem;
      transition: 0.2s;
  }
  
  #bookRegisterSelectDiv {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      background: white;
      position: relative;
      width: 13.5rem;
      height: 2.4rem;

  }

  #bookRegisterSelectDiv svg {
     cursor: pointer !important;
     -webkit-transform: translate(-10px, 0px);
             transform: translate(-10px, 0px);
     z-index: 100;
     position: absolute;
     right: 0;
  }

  #bookRegisterNewFieldsTable #bookRegisterSelectType { 
    width: 13.5rem;
    border-style: none;
    font-family: 'Roboto';
    text-align: center;
    text-align-last: center;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    z-index: 1000;
    background: rgba(177, 177, 177, 0.013);
    position: absolute;
    
  }

  #bookRegisterNewFieldsTable #bookRegisterSelectType:hover {
      font-weight: 400;
  }

  #bookRegisterButtonWhiteBg {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 500;
    width: 11rem;
    height: 2.2rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
  }

  #bookRegisterButtonWhiteBg:hover {
    color: white;
    background: var(--goldColor);
  }

  #bookEditLoaderDiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bookRegisterLoaderDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}


.booksRegisterFixedFields {
    color: gray;
}

.booksRegisterFixedFields:hover {
    color: gray;
    cursor: default;
}

.booksRegisterFixedFields td:hover {
    color: gray;
    cursor: default;
}


@media(max-width: 1368px){
/* BooksRegisterTable */
.booksRegisterTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.bookRegisterLoader {
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.booksRegisterHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.booksRegisterHead  p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1rem !important;
    font-weight: 300;
    margin-left: 2rem;
    
}

.booksRegisterHead .modalCloseButton {
    margin-right: 2rem;
    margin-top: 1rem;
}

.bookRegisterTableContent {
    display: flex;
    flex-direction: column;
    max-width: 95%;
    height: 70%;
    max-height: 70%;
    width: auto;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.bookRegisterTableContent p {
    font-size: 0.9rem !important;
}

.bookRegisterTableContent label:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bookRegisterInputName {
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

#bookRegisterLabelInstructions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#bookRegisterLabelInstructions input {
    height: 1.5rem;
    width: 15rem;
    font-size: 1.1rem;
    font-weight: 400;
    padding-left: 0.5rem;
}

#bookRegisterLabelInstructions input::-webkit-input-placeholder{
    color: rgb(78, 78, 78);
    font-size: 1rem;
}

#bookRegisterLabelInstructions input::placeholder{
    color: rgb(78, 78, 78);
    font-size: 1rem;
}

#bookRegisterLabelInstructions input:focus {
    border-bottom: 1px solid var(--goldColor);   
}

#bookRegisterEditName{
    font-size: 1.2rem !important;
    border: none;
    
}

.bookRegisterTableContent label p{
    font-size: 1rem !important;
    font-weight: 400;
}

#bookRegisterPreviewText {
    font-size: 1.1rem !important;
}

.bookRegisterInstructions {
    display: flex;
    width: 95%;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}

.bookRegisterInstructions p{
    font-size: 0.9rem !important;
}

.bookRegisterInstructions label {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}
.bookRegisterInstructions label div input {
    height: 1.5rem;
}

/*Tabela preview */
.booksRegisterTablePreview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booksRegisterTablePreview div {
    height: 3rem;
}

.booksRegisterTablePreview div p{
    font-size: 0.9rem !important;
}

#bookRegisterPreviewTableTitle {
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}


#bookRegisterPreviewTableTitle p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1rem;
}

.booksRegisterTablePreview #booksRegisterTableToPreview { 
    box-shadow: -2px 2px 20px -7px #000000;
}

#booksRegisterTableToPreview thead th {
    font-size: 0.9rem;
}

#booksRegisterTableToPreview thead tr:first-child {
    background: var(--topBarBg);
    color: white;
    height: 4rem;
}

#booksRegisterTableToPreview thead tr:first-child th:first-child  {
    border-radius: 5px 0px 0px 0px;
}

#booksRegisterTableToPreview thead tr:nth-child(2) {
    color: white;
    background: var(--tableSecondTh);
} 

#booksRegisterTableToPreview thead tr:nth-child(2) th{
    font-weight: 300 !important;
    font-size: 0.9rem !important;
}

#booksRegisterTableToPreview tbody td {
    height: 2rem;
    text-align: center;
    width: 8rem;
}

#bookRegisterConfirmationBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#bookRegisterConfirmationBox a {
    font-size: 0.9rem !important;
    margin-bottom: 0.8rem;
}

#bookRegisterConfirmationBoxButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}
.booksRegisterTablePreview tbody td {
    font-size: 0.9rem;
}

/*Tabela para configuração do Book*/

#bookRegisterNewFieldsTable{
    box-shadow: 0px 2px 19px -8px black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable{
    background-color: #ececec;
    border-radius: 0.3rem;
    width: 100%;
    font-size: 0.9rem !important;
  }


#bookRegisterFixedFields {
    height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#bookRegisterFixedFields a {
    font-size: 0.9rem;
}

#bookRegisterFixedFields input {
    width: 10rem;
    height: 1.9rem;
    padding-left: 4px;
    text-align: center;
    border: none;
    font-size: 0.9rem;
}

#bookRegisterFixedFields input::-webkit-input-placeholder {
    color: rgb(68, 68, 68);
    font-size: 0.9rem;
}

#bookRegisterFixedFields input::placeholder {
    color: rgb(68, 68, 68);
    font-size: 0.9rem;
}

#bookRegisterNewFieldButton {
    background: white;
    border: none;
    font-size: 0.9rem;
    width: 7rem;
    transition: 0.2s;
}

#bookRegisterNewFieldButton:hover {
    background: var(--goldColor);
    color: black;
}
#bookRegisterNewFieldButton:focus {
    background: var(--goldColor);
    color: black;
}
  
  #bookRegisterNewFieldsTable #bookRegisterTable  #bookRegisterTableThead{
    text-align: center;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableThead {
    background: var(--topBarBg);
    color: white;
    height: 2.3rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody{
    background-color: #fff;  
    text-align: center;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableBody {
    display: block;
    overflow-x: hidden;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead, #bookRegisterTableBody tr {
    padding-top: 0.35rem;
    display: table;
    width: 65rem;
    table-layout: fixed;
  }
  #bookRegisterNewFieldsTable  #bookRegisterTableThead {
    padding: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid var(--goldColor) !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(1) td{
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #005f9e36 !important;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTableThead tr:nth-child(2) td{
      padding-top: 0.5rem;
  }

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody tr {
    height: 2rem;
    font-size: 0.9rem !important;
}
    

  #bookRegisterNewFieldsTable #bookRegisterTable #bookRegisterTableBody td {
      cursor: default;
  }
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(1){
      width: 2rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(2){
      width: 4rem;
  }
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(3){
      width: 5rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(4){
      width: 5rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(5){
      width: 3rem;
  }
  
  #bookRegisterNewFieldsTable #bookRegisterTable td:nth-child(6){
      width: 2rem;
      transition: 0.2s;
  }
  
  #bookRegisterSelectDiv {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      background: white;
      position: relative;
      width: 10rem;
      height: 1.9rem;

  }

  #bookRegisterSelectDiv svg {
     cursor: pointer !important;
     -webkit-transform: translate(-10px, 0px);
             transform: translate(-10px, 0px);
     z-index: 100;
     position: absolute;
     right: 0;
  }

  #bookRegisterNewFieldsTable #bookRegisterSelectType { 
    width: 10rem;
    border-style: none;
    border:0px;
    font-family: 'Roboto';
    text-align: center;
    text-align-last: center;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    border: none;
    font-size: 0.9rem;
    font-weight: 300;
    z-index: 1000;
    background: rgba(177, 177, 177, 0.013);
    position: absolute;
    
  }

  #bookRegisterButtonWhiteBg {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    width: 11rem;
    height: 2.2rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
  }

  #bookRegisterButtonWhiteBg:hover {
    background: var(--buttonHover) !important;
    color: white;
  }

  #bookEditLoaderDiv {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }

  #bookRegisterLoaderDiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
  }
}

.assetsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#assetsTableLoaderTd {
    height: 8rem;
    text-align: center;
    align-items: center;
}

#buttonTableThead{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

#buttonTableThead:hover {
    color: black;
    background: var(--goldColor);
}

.asstesTableContainer {
    width: 95%;
    overflow-x: hidden;
    max-height: 32rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    margin-top: 2rem;
}

.asstesTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg);
    width: 100%;
    /* border-radius: 10px 0 0 0; */
}

.asstesTableContainer thead tr:first-child th div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
}

.asstesTableContainer thead tr:first-child th div p {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
}

.asstesTableContainer thead tr:first-child th div input {
    background-color: #313131;
    height: 1.8rem;
    font-size: 0.9rem;
    border: none;
    padding-left: 0.5rem;
    color: white;
}

.asstesTableContainer thead tr:first-child th div .assetsTableThDivLeft {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.asstesTableContainer thead tr:nth-child(2) th{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 99;
    background: var(--tableSecondTh);
    height: 1.8rem;
    color: white;
}

.asstesTableContainer tbody tr {
    background: white;
    transition: 0.2s;
}

.asstesTableContainer tbody tr:hover {
    background: var(--tableHover);
    transition: 0.2s;
}

.assetsTableSecondTheadTr th {
    position: -webkit-sticky;
    position: sticky;
    top: 3rem;
    font-size: 0.9rem;
    font-weight: 300;
}

.asstesTableContainer tbody td{
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    border: 1px solid var(--tableBorder);
    height: 2rem;
    transition: all ease 0.2s;
}


.assetsEditContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetsEditHeader {
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
}

.assetsEditHeader p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.assetsEditHeader button {

    margin-right: 1rem;
}

.assetsEditBody {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
    margin-top: 5rem;
}

.assetEditCentralComponent {
    display: flex;
    flex-direction: column;
    height: 80%;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.assetEditDualLabel {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: repeat(2,1fr);
}

.assetEditDualLabel .assetEditDefaultInput {
    padding-left: 1rem;
    font-size: 1rem;
    border: 1px solid var(--goldColor);
}

.assetEditDualLabel .assetEditDefaultInput:focus {
    border: 2px solid var(--goldColor);
    outline: none;
}

.assetEditDualLabel input::-webkit-input-placeholder {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
}

.assetEditDualLabel input::placeholder {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
}

.assetEditNameLabel {
    border: 1px solid rgb(212, 212, 212);
    height: 3.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.assetEditNameLabel:nth-child(1) a {
    
    margin-left: 0.5rem;
    font-weight: 400;
}

#assetEditNameFirst {
    margin-left: 1rem;
    font-weight: 600;
}

.assetsEditBody footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1368px){
    .assetEditNameLabel {
        border: 1px solid rgb(212, 212, 212);
        height: 2.4rem;
        font-size: 1rem;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
#editAssetBackButton {
    margin-top: -8rem;
}

.assetsCadastrationContainer {
    overflow: hidden;
    width: 50rem;
    height: 30rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.assetsCadastrationHeader {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--headerAllBg);
    height: 3rem;
    width: 100%;
}

.assetsCadastrationHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.assetsCadastrationHeader button {
    margin-right: 1rem;
}

.editClassAssets {
    width: 50rem;
    height: inherit;
    background: transparent;
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;
}

.editClassAssetsModal {
    transition: 0.3s;
    height: inherit;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 10;
}


.editClassAssetsBody {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: inherit;
    align-items: center;
}

.editAssetsTable {
    display: flex;
    flex-direction: column;
    width: inherit;
    margin-top: 2rem;
    align-items: center;
    overflow-x: hidden;
    max-height: 18rem; 
    box-shadow: -2px 2px 20px -7px #000000; 
}

.editClassAssetsBody thead tr th {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    background: var(--topBarBg);
    height: 2rem;
    color: white;
    font-size: .9rem;
    font-weight: 300;
}

.editClassAssetsBody thead tr th:first-child {
    border-radius:  0.5rem 0 0;
}

.editClassAssetsBody tbody tr td {
    height: 1.4rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    font-size: 0.9rem;
}

.editClassAssetsBody tbody tr td:last-child {
    width: 5rem;
    transition: 0.2s;
}

.editClassAssetsBody tbody tr td:last-child:hover {
    background: rgb(192, 192, 192);
}

.editClassAssetsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: initial;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    height: 3rem;
    font-size: 1.2rem;
    background: var(--headerAllBg);
}

.editClassAssetInput {
    border:none;
    width: 100%;
    height: 1.4rem;
    text-align: center;
    font-size: 0.9rem;
    background: rgb(216, 215, 215);
}

.editClassAssetInput::-webkit-input-placeholder {
    color: rgb(7, 7, 7);
}

.editClassAssetInput::placeholder {
    color: rgb(7, 7, 7);
}

.editClassAssetsHeader svg {
    margin-left: 1rem;
    transition: 0.2s;
}

.editClassAssetsHeader svg:hover {
    color: var(--goldColor);
}

.editClassAssetsHeader p {
    font-size: 1.2rem;
}

.assetsCadastrationBody {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 3rem);
    /* transition: 0.2s; */
}

.assetCadastrationDualDiv {
    width: 80%;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: repeat(2,1fr);
}

.assetCadastrationDualDiv .assetCadastrationInput {
    width: 100%;
    height: 2.4rem;
    border: 1px solid var(--goldColor);
}

.assetCadastrationDualDiv .assetCadastrationInput:focus {
    border: 2px solid var(--goldColor);
    outline: none;
}

.assetCadastrationTreeDiv {
    width: 80%;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: repeat(3,1fr);

}

#assetCadastrationButton {
    margin-top: 2rem;
}

.assetsCadastrationBody span {
    width: 80%;
}

.assetsCadastrationBody span p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}


.assetClassesContainer { 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}
.assetClassesTable {
    width: 95%;
    overflow-x: hidden;
    max-height: 35rem;
    background: white; 
    box-shadow: 5px 1px 15px -7px #000000;
}

.assetClassesTable th { 
    font-size: 1rem;
    font-weight: 300;
    background: var(--tableSecondTh);
    color: white;
    height: 1.5rem;
}

.assetClassesTable tr:first-child th {
    background: var(--topBarBg);
}

#assetClassesTableTheadDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    margin-right: 1rem;
}

#assetClassesTableTheadDiv label{
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#assetClassesTableTheadDiv p {
    font-size: 1.2rem;
    color: white !important;
    font-weight: 300;
    margin-left: 1rem;
}

.assetClassesTable tbody td {
    height: 2rem;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
    transition: all ease 0.2s;
}

.assetClassesTable tbody tr:hover {
    background: var(--tableHover);
}

#assetClassTableLoader {
    height: 20rem;
}


.assetClassesEditBoxContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetClassesEditBoxHeader {
    background: var(--headerAllBg);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.assetClassesEditBoxHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.assetClassesEditBoxHeader button {
    margin-right: 1rem;
}

.assetClassesEditBoxBody {

    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    align-items: center;
}

.assetClassesEditBoxEdition {
    height: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.assetClassesEditBoxEdition p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.assetClassesEditBoxLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.recomendationsContainer {
 display: flex;
 flex-direction: column;
 align-items: center;
 height: 100%;
 min-width: 1100px;
}

.recommendationsTableContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
}
.recomendationsTable {
    display: flex;  
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.recomendationsTableArea {
    margin-top: 1rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    overflow-x: hidden;
    width: 95%;
}
.recommendationsTableLoader {
    height: 20rem;
}

.recomendationsTable thead tr:first-child th{
    border-radius: 0.5rem 0 0;
}

.recomendationsTable thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg);
    z-index: 99;
}

.recomendationsTable thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 3.9rem;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
}

.recomendationsTable table thead tr th div {
    display: flex;
    flex: row 1;
    align-items: center;
    justify-content: space-between;
}

.recomendationsTable table thead tr th div p{
    font-size: 1.2rem;
    color: white;
    font-weight: 300;
    padding-left: 1rem;
}

.recomendationsHeaderRight {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.recomendationsTable tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
}

.recomendationsTable tbody tr:hover td {
    background: var(--tableHover);
}

#recommendationsImportConfirm {
    font-size: 1.2rem;
    font-weight: 400;
}
.recomendationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-width: 1100px;
   }
   
   .recommendationsTableContainer {
       width: 95%;
       display: flex;
       flex-direction: column;
       align-items: center;
       overflow-x: hidden; 
       box-shadow: -2px 2px 20px -7px #000000;
   }
   
   .containerAddNewAsset{
       width: 60%;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       background: var(--tableSecondTHGray);
       border-radius: 4px; 
       box-shadow: -2px 2px 20px -7px #000000;
       border: 1px solid var(--topBarBg);
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr;
       margin: auto;
       margin-top: 1rem;
   }
   
   .selectAsset{
       width: calc(100% - 0.8rem) !important;
       margin-left: 0.4rem !important;
       margin-right: 0.4rem !important;
       background-color: white !important;
       border-radius: 4px !important;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
   }
   
   .selectAsset .MuiOutlinedInput-input{
       padding: 0 !important;
   }
   
   .inputTextTitle{
       color: white;
       text-align: center;
       padding: 0.2rem;
   }
   
   .inputTextTitleEdit{
       color: black;
       text-align: center;
       padding: 0.2rem;
   }
   
   .inputInfo{
       font-size: 1rem;
       padding: 0.4rem;
       margin-left: 0.4rem;
       margin-right: 0.4rem;
       margin-bottom: 0.3rem;
       border-radius: 4px;
       border: none;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
   }
   
   .inputInfo:focus{
       outline: none;
       background-color: rgb(241, 241, 241);
   }
   
   .inputInfoEdit{
       font-size: 1rem;
       padding: 0.4rem;
       margin-left: auto;
       margin-right: auto;
       margin-bottom: 0.3rem;
       border-radius: 4px;
       border: none;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       width: 80%;
       border: 1px solid rgb(66, 66, 66);
   }
   
   .inputInfoEdit:focus{
       outline: none;
       background-color: rgb(255, 255, 255);
   }
   
   .MuiOutlinedInput-input{
       padding: 0.4rem !important;
   }
   
   .MuiFormControl-root{
       width: calc(100% - 0.4rem);
   }
   
   .currencyTextFieldCustom{
       background-color: white !important;
       border-radius: 4px !important;
       margin: auto !important;
       margin-top: 0 !important;
       margin-bottom: 0.3rem !important;
       width: 96%;
   }
   
   .createRow{
       grid-row-start: 1;
       grid-row-end: 3;
       grid-column-start: 4;
       margin: 0.5rem 0.6rem 0.5rem 0.2rem;
       background: var(--topBarBg);
       border: 0.13rem solid var(--formBorder);
       color: var(--formButtonFontColor);
       font-size: 1.2rem;
       font-weight: 400;
   }
   
   .createRow:hover{
       background: var(--formButtonFontColor);
       color: var(--topBarBg);
   }
   
   .containerClientControl{
       width: 40rem;
   }
   
   .formEditRow{
       width: 30rem;
       margin-left: auto;
       margin-right: auto;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr;
       grid-template-rows: 1fr 1fr;
       grid-row-gap: 0.5rem;
       row-gap: 0.5rem;
       grid-column-gap: 1rem;
       -webkit-column-gap: 1rem;
               column-gap: 1rem;
   }
   
   .assetsOfferingsReservConfirmation{
       width: 25%;
       /* width: 50%; */
       grid-row-start: 4;
       margin: auto;
   }
   
   .containerPesoPreco{
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: auto 1fr;
       grid-column-start: 1;
       grid-column-end: 3;
       grid-row-start: 2;
   }
   
   .containerCompraVenda{
       width: 96%;
       height: 15rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 1fr auto 2fr;
       text-align: center;
       margin: 2%;
       margin-top: 1rem;
   }
   
   .containerCompraVendaUpdate{
       width: 96%;
       height: 28rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 1fr auto 2fr;
       text-align: center;
       margin: 2%;
       margin-top: 1rem;
   }
   
   .containerCompraVendaAporte{
       /* position: absolute;
       transform: translate(-20rem,8rem);
       width: 66rem; */
       height: 15rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 1fr;
       text-align: center;
   }
   
   .containerCompraVendaResgate{
       height: 15rem;
       border: 1px solid lightgray;
       border-radius: 4px;
       display: grid;
       grid-template-columns: 3fr auto 3fr;
       text-align: center;
   }
   
   .containerCompraVenda th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVenda td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .containerCompraVendaUpdate th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVendaUpdate td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .containerCompraVendaAporte th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVendaAporte td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .containerCompraVendaResgate th{
       font-size: 1rem;
       font-weight: 500;
   }
   
   .containerCompraVendaResgate td{
       font-size: 0.9rem;
       font-weight: 400;
   }
   
   .divisaoTabelas{
       width: 1px;
       height: 90%;
       background-color: #e9e9e9;
       margin: auto;
   }
   
   .tituloTabelaCompraVenda{
       font-size: 1.2rem;
       width: 90%;
       margin: auto;
       margin-top: 0.5rem;
       margin-bottom: 0.8rem;
       border-bottom: 1px solid #e9e9e9;
       height: 2rem
   }
   
   .tabelaVenda{
       height: 7.8rem;
   }
   
   .containerTabelaVenda tbody {
       display:block;
       height: 6.4rem;
       overflow:auto;
   }
   
   .containerTabelaVendaUpdate tbody {
       display:block;
       height: 19rem;
       margin-bottom: 0.6rem;
       overflow:auto;
   }
   
   .containerTabelaVenda thead, .containerTabelaVenda tbody tr {
       display:table;
       width: 100%;
       table-layout:fixed;
   }
   
   .containerTabelaVendaUpdate thead, .containerTabelaVendaUpdate tbody tr {
       display:table;
       width: 100%;
       table-layout:fixed;
   }
   
   .containerTabelaCompra tbody {
       display:block;
       height: 9.6rem;
       overflow:auto;
   }
   
   .containerTabelaCompra thead, .containerTabelaCompra tbody tr {
       display:table;
       width: 100%;
       table-layout:fixed;
   }
   
   .containerAddVenda{
       width: 99%;
       border: 1px solid #dfdfdf;
       margin: auto;
       margin-top: 0.6rem;
       border-radius: 4px;
   }
   
   .containerAddVenda{
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
   }
   
   .inputAddVenda{
       width: 90%;
       margin: 5%;
       font-size: 1rem;
       border-radius: 4px;
       border: 1px solid #c2c2c2;
   }
   
   .inputAddVenda:hover{
       border: 1px solid #2b2b2b;
   }
   
   .labelCurrencyTextField{
       width: 100%;
       text-align: center;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       margin: auto !important;
   }
   
   .inputNumberVenda{
       margin: auto !important;
   }
   
   .addVenda{
       margin: 0.4rem 0.3rem 0.4rem 0.2rem;
       font-size: 1rem;
       border: none;
       background-color: #dddddd;
       border-radius: 2px;
       transition: all ease-in-out 0.1s;
   }
   
   .addVenda:hover{
       background-color: #bbbbbb;
   }
   
   .substitutoAutocomplete{
       margin: 5%;
       width: 90%;
       height: 1.8rem;
       line-height: 1.8rem;
       border: 2px solid #c2c2c2;
       border-radius: 4px;
   }
   
   .tableTrackingWisir td{
       width: 0px;
       font-size: 1rem !important;
   }
   
   .tableTrackingWisir tr{
       width: 0px;
       font-size: 1rem !important;
   }
   
   .containerHistoryList{
       width: 100%;
       height: 20rem;
       background-color: #f3f3f3;
       border-left: 1px solid #dbdbdb;
       border-right: 1px solid #dbdbdb;
   }
   
   .containerAssetsHistory{
       width: 100%;
       height: 14rem;
       margin: auto;
       background-color: #e4e4e4;
       border-left: 1px solid #c2c2c2;
       border-right: 1px solid #c2c2c2;
       border-radius: 4px;
   }
   
   .historyListTitle{
       font-size: 1.1rem;
       padding-top: 0.8rem;
       text-align: center;
   }
   
   .headerHistoryList{
       margin-top: 1rem;
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
       text-align: center;
   }
   
   .headerOperationAssets{
       margin: auto;
       margin-top: 1rem;
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr;
       text-align: center;
   }
   
   .headerOption{
       font-size: 1rem;
       width: 80%;
       margin: auto;
       padding-bottom: 0.4rem;
       border-bottom: 1px solid #dfdfdf;
   }
   
   .loadingOperationList{
       width: 100%;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       margin-top: 5rem;
       text-align: center;
   }
   
   .loadingOperationAssetsList{
       width: 100%;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       margin-top: 4.6rem;
       text-align: center;
   }
   
   .dataHistoryList{
       padding-top: 0.6rem;
       padding-bottom: 0.6rem;
       margin-top: 0.1rem;
       margin-bottom: 0.1rem;
       width: 100%;
       display: grid;
       grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
       text-align: center;
       background-color: #ececec;
   }
   .dataHistoryList:hover{
       background-color: #e4e4e4;
       cursor: pointer;
   }
   
   .dataOption{
       font-size: 0.9rem;
       width: 90%;
       margin: auto;
       font-weight: 500;
   }
   
   .containerDataHistoryList{
       overflow: auto;
       height: 15rem;
   }
   
   .disclaimerSelectOperation{
       width: 100%;
       height: -webkit-fit-content;
       height: -moz-fit-content;
       height: fit-content;
       margin-top: 6rem;
       text-align: center;
       font-weight: 500;
       font-size: 1.2rem;
   }
   
   .containerCarteiras{
       display: grid;
       grid-template-columns: 1fr 2px 1fr;
       height: 100%;
   }
   
   .divisaoAnteriorPosterior{
       width: 100%;
       height: 84%;
       margin: auto;
       background-color: #d3d3d3;
   }
   
   .dataOperationAssets{
       display: grid;
       grid-template-columns: 1fr 1fr;
       text-align: center;
   }
   .dataOperationAssets:hover{
       background-color: #d8d8d8;
   }
   
   .containerDataOperationAssets{
       max-height: 8.4rem;
       overflow: auto;
   }
   
   .disclaimerEmptyWallet{
       text-align: center;
       padding-top: 4.4rem;
       font-size: 1.2rem;
       font-weight: 500;
   }
   
   .selectWisirTable{
       width: 100%;
       border: 1px solid #bdbdbd;
   }
   
   .selectWisirTable:focus {
       outline: none;
   }

.containerComponent{
    height: 100%;
}

.inputItemQtd{
    width: 40%;
    color: black;
    padding-left: 0.1rem;
}
.carteirasSelect {
    width: 75%
}
.wisir2DetailsContainer {
    display: grid;
    height: 36rem;
    grid-template-columns: 1fr 1px 1fr;
    margin-top: 1rem;
}

.timelineCommentBoxWisir{
    height: 22rem;
    max-height: 22rem;
    overflow: auto;
    border-radius: 2px;
    border: 1px solid #dadada;
    background-color: #f8f8f8;
    display: grid;
  }

  .timelineTitleWisir{
    /* margin-top: 1rem; */
    height: 3rem;
    font-size: 1.1rem;
    font-weight: 500;
    display: grid;
    grid-template-columns:3fr 3fr; 
    padding-bottom: 0.3rem;
    align-items: center;
  }


.wisir2DetailsSide {
    max-height: 35rem;
    /* border: 1px solid #dadada;
    border-radius: 2px;
    margin: 2 rem; */
    margin: 0.5rem;
    margin-top: 0.75rem;
    display: grid;
    grid-template-rows: 2rem auto;
}

.wisir2DetailsSideBody {
    overflow: auto;
}

.wisir2CustodyItem {
    border-radius: 2px;
    border: 1px solid #dadada;
    margin: 1rem 2rem;
    padding: 0.5rem;
}

.wisir2CustodyItemLineLabel {
    font-weight: 500;
}

.mappingContainer {
    display: grid;
    grid-template-rows: auto auto 5rem;
    width: 80rem;
    background: #FFF;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
}

.mappingContainerUpperGrid {
    /* display: grid;
  grid-template-columns: 1fr;   
  grid-template-rows: auto;    */
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  /* gap: 1rem; */
  margin: 1rem 2rem 0 2rem;
}

.mappingSubmitBtn {
    margin-left: 2rem;
}
.assetSectorMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetSectorTableDiv {
    display: flex;
    width: 95%;
    flex-direction: column;
    overflow-x: hidden;
    margin-top: 2rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}
#assetSectorTable tr:first-child th {
    background: var(--topBarBg);
    height: 3.8rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    border-radius:  10px 0 0 0;
}

#assetSectorTable tr:nth-child(2) th {
    background: var(--tableSecondTh);
    height: 1.5rem;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    position: -webkit-sticky;
    position: sticky;
    top: 3.8rem;
    z-index: 99

}

.assetSectorTableFirstDivTH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assetSectorTableFirstDivTH p {
    color: white;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.assetSectorTableFirstDivButtons {
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    margin-right: 1rem;
}

#assetSectorTable tbody {
    font-size: 1rem;
}


#assetSectorTable td {
    background: white;
    border:  1px solid var(--tableBorder);
    text-align: center;
    height: 2rem;
}

#assetSectorTable tr {
    transition: 0.2s;
}

#assetSectorTable tr:hover {
    background: var(--tableHover);
}

#assetSectorsLoading {
    height: 10rem;
}
.assetSectorCadastration {

    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.assetSectorCadastrationHeader {

    background: var(--headerAllBg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2.8rem;
    width: 100%;
    
}

.assetSectorCadastrationHeader p {

    font-size: 1.2rem;
    margin-left: 1rem;

}

.assetSectorCadastrationHeader button {
    margin-right: 1rem;
}

.assetSectorCadastrationBody {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 50%;
}

.assetSectorCadastrationBody  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.assetSectorCadastrationBody label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.brokerPanelLoaderDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#borkerPanelLoading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brokerPanelMainContainer {
    min-width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

#brokerPanelLeftSide { 
    box-shadow: 3px 4px 10px -10px #000000;
    border-right: 1px solid rgba(100, 100, 100, 0.274);
    min-width: 300px;
    width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgb(255, 255, 255);
    height: 100%;
}



#brokerPanelLeftSide ::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelLeftSide ::-webkit-scrollbar-track {
    background: #ebebeb !important;
}
  
#brokerPanelLeftSide ::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194) !important;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

#brokerPanelLeftSide ::-webkit-scrollbar-thumb:hover {    
    background: rgb(170, 170, 170) !important;
}
   

.brokerPanelLeftMenu {
    
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid rgb(218, 218, 218);
    width: 100%;
}

.brokerPanelLeftMenuLoading {
    margin-top: 1rem;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid rgb(218, 218, 218);
    width: 100%;
}

.brokerPanelLeftMenuLoading label {
    cursor: pointer;
    height: 1.8rem;
    width: 100%;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
}


.brokerPanelLeftMenu h2 {
    font-size: 1.2rem;
    margin-left: 1.5rem;
    font-weight: 500;
}
.brokerPanelLeftMenu a {
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    margin-left: 1.5rem;

}

.brokerPanelLeftTitle {
    font-weight: 400 !important;
    margin-bottom: 0.5rem;
}
.brokerPanelLeftMenu label svg {
    margin-right: 1rem;
}

.brokerPanelLeftMenu label {
    cursor: pointer;
    height: 1.8rem;
    width: 100%;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.brokerPanelLeftMenu label:hover a {
    font-weight: 400;
    width: 100%;
}

.brokerPanelLeftMenu label svg {
    font-size: 1rem;
    
}

.brokerPanelLeftMenu label:hover svg {
    color: rgb(145, 0, 0);
    
}

.brokerPanelLeftMenu label a {
    font-size: 0.9rem !important;
}

.brokerPanelLeftMenu div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

#brokerPanelRightSide {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

#brokerPanelUpperLeftSide {
    box-shadow: 3px 4px 10px -10px #000000;
    background: white;
    border: 1px solid rgba(100, 100, 100, 0.274);
}

#brokerPanelRightSide #brokerPanelUpper {
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1.3rem;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    min-height: 12rem;
}

#brokerPanelObjectives {
    padding: 1rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem; 
}

#brokerPanelObjectives a {
    border-bottom: 1px solid rgb(207, 207, 207);
    font-size: 1rem;
}

#brokerPanelObjectives a:last-child {
    color: black;
    font-size: 1rem;
    font-weight: 500;
}



#brokerPanelObjectives p {
    font-size: 1.2rem;
    font-weight: 400;
}

#brokerPanelObjectives button {
    width: 8rem;
    height: 1.8rem;
    border-radius: 4px;
    background: var(--topBarBg);
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.2s;
}

#brokerPanelObjectives button:hover {

    color: gray;
}

#brokerPanelObjectives label {

    height: 1.5rem;
    border-bottom: 1px rgb(218, 218, 218);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#brokerPanelCenterButtons {
    margin-bottom: 1rem;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#brokerPanelCenterButtons button {
    font-size: 1rem;
    background: black;
    height: 1.8rem;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 12rem;
    color: white;
    transition: 0.2s;
}

#brokerPanelCenterButtons button a {
    margin-left: 0.5rem;
}

#brokerPanelCenterButtons button svg {
    margin-right: 0.5rem;
}

#brokerPanelCenterButtons button:hover{
    color: gray;
}

#brokerPanelUpperRightSide {
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
    border: 1px solid rgba(100, 100, 100, 0.274);
    padding: 1rem;
}

.brokerPanelDivButtons {
    margin-left: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#brokerPanelbottom {
    height: 38rem;
    background: white;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 90%; 
    box-shadow: 3px 4px 10px -10px #000000;
    border: 1px solid rgba(100, 100, 100, 0.274);
}

#brokerPanelbottom h2 {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

#brokerPanelbottonTradeIdeaHeader h2 {
    margin-left: 1rem;
    font-weight: 400;
}

#brokerPanelbottonTradeIdeaHeader button {
    margin-right: 1rem;
    width: 8rem;
    height: 2rem;
    background: var(--topBarBg);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    transition: 0.2s;
}

#brokerPanelbottonTradeIdeaHeader button:hover {
    color: gray;
}

#brokerPanelbottonTradeIdeaHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#brokerPanelTextGroupDiv {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#brokerPanelTextGroupDiv label {
    padding: 0.5rem;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#brokerPanelTextGroupDiv label a:first-child {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0.8rem;
}

.brokerPanelTextDiv {
    
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    overflow-x: hidden;
    word-break: break-all;
    max-width: 100%;
    max-height: 150px;
    width: 100%;
}

.brokerPanelTextDiv::-webkit-scrollbar {
    width: 10px;
}
  
.brokerPanelTextDiv::-webkit-scrollbar-track {
    background: #d3d3d3;
}
  
.brokerPanelTextDiv::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

.brokerPanelTextDiv::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

#brokerPanelTextGroupDiv {
    display: grid;
    width: 95%;

}

#brokerPanelTextGroupDiv::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelTextGroupDiv::-webkit-scrollbar-track {
    background: #d3d3d3;
}
  
#brokerPanelTextGroupDiv::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

#brokerPanelTextGroupDiv::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

.brokerPanelTradeIdeaContainerDiv {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(214, 214, 214);
    transition: 0.2s;
    padding: 0.5rem;
}

.brokerPanelTradeIdeaContainerDiv:hover {
    border: 1px solid rgb(177, 177, 177);
}

.brokerPanelTradeIdeaContainerDiv svg {
    transition: 0.2s;
}

.brokerPanelTradeIdeaContainerDiv svg:hover {
    color: rgb(168, 2, 2);
}

.brokerPanelTradeIdeaContainerDiv label {
    width: 100%;
}


#brokerPanelReports {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid rgba(100, 100, 100, 0.274);
    border-top: 1px solid rgba(100, 100, 100, 0.274);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

#brokerPanelReportsCotent {
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 4rem;
    overflow-x: hidden;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#brokerPanelReports ::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelReports ::-webkit-scrollbar-track {
    background: #e0e0e0;
}
  
#brokerPanelReports ::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

#brokerPanelReports ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}



#brokerPanelReports label {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#brokerPanelReports h3 {
    margin: 0;
    color: rgb(194, 174, 1);
    font-size: 1rem;
    white-space: nowrap;
}

#brokerPanelReports table td {
    transition: 0.2s;
    text-align: center;
    height: 1.2rem;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
}


#brokerPanelReports table tr:hover {
    color: rgb(189, 189, 189)
}

#brokerPanelBottonOvergflownedPanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}


#brokerPanelbottom ::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelbottom ::-webkit-scrollbar-track {
    background: #e0e0e0;
}
  
#brokerPanelbottom ::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

#brokerPanelbottom ::-webkit-scrollbar-thumb:hover {
    background: rgb(190, 190, 190);
}

#brokerPanelRecommendedWallet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#brokerPanelRecommendedWallet a {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: 1px solid gray;
}

#brokerPanelRecommendedWallet a:hover {
    color: rgb(189, 189, 189);
    cursor: pointer;
}

#brokerPanelRecommendedWallet h3 {
    margin: 0;
    color: rgb(194, 174, 1);
    font-size: 1rem;
    white-space: nowrap;
}

#brokerPanelLeftSide::-webkit-scrollbar-track {

    background: #e0e0e0;
}

#brokerPanelLeftSide::-webkit-scrollbar-thumb {

    background: rgb(206, 206, 206);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

#brokerPanelLeftSide::-webkit-scrollbar-thumb:hover {

    background: rgb(190, 190, 190);
}

#brokerPanelNewLinkButtonDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

#brokerPanelNewLinkButtonDiv button {

    width: 100%;
    color: white;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border: none;
    height: 2rem;
    background-color: var(--topBarBg);
    transition: 0.2s;
}

#brokerPanelNewLinkButtonDiv button:hover{

    color: gray;
}

.tileLoaderGroup {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 90%;
}

#tradeIdeasLoaderGroup {
    width: 100%;
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brokerPanelUpdateViaImport {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerTopButtons{
    width: 100%;
    height: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.buttonRecadosGraficos{
    border-radius: 0px;
    font-size: 1.6rem;
    border: none;
    background-color: #c8c8c8;
    transition: all ease-in-out 0.2s;
    border-bottom: 2px solid #c8c8c8
}

.buttonRecadosGraficos:hover{
    background-color: #a7a7a7;
    border: none;
    border-bottom: 3px solid #a7a7a7
}

.buttonRecadosGraficosACTIVE{
    border-radius: 0px;
    font-size: 1.6rem;
    border: none;
    background-color: #b3b3b3;
    transition: all ease-in-out 0.1s;
    border-bottom: 3px solid #ffd900
}

.switchButtonsRecadosGraficos{
    border-radius: 0px;
    font-size: 1.6rem;
    border: none;
    background-color: #b3b3b3;
    transition: all ease-in-out 0.1s;
    border-bottom: 3px solid #ffd900;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    cursor: pointer;
}

.conatinerRecadosTrades{
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 90%;
    margin-top: 1rem;
}

.containerTextoRecadosTrades{
    background-color: #fff;
    border-radius: 2px;
    overflow: auto;
    overflow-x: hidden;
}

.containerRecados{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerTrades{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tituloRecadosTrades{
    font-size: 1.2rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 400;
    text-align: center;
}

.itemRecados{
    text-align: center;
    font-size: 0.9rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #fff;
    font-weight: 300;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 3px 6px #b3b3b3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itemRecados:hover{
    background-color: #d8d8d8;
    box-shadow: 0px 3px 6px #747474;
}

.itemTrades{
    text-align: center;
    font-size: 0.9rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #fff;
    font-weight: 300;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 3px 6px #b3b3b3;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.itemTrades:hover{
    background-color: #d8d8d8;
    box-shadow: 0px 3px 6px #747474;
}

.ql-toolbar{
    height: 3rem;
}

.quill{
    height: calc(100% - 7.4rem);
}

.ql-tooltip{
    top: -10px !important;
    left: 0px !important;
    right: 0px !important;
    text-align: center;
}

.itemPlus{
    padding: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border: none;
    background: transparent;
}
.itemPlus:hover{
    color: #000000;
}

.containerTituloEditFalse{
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    height: 4.4rem;
    overflow: hidden;
}

.containerTituloEditTrue{
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    height: 4.4rem;
    overflow: hidden;
}

.tituloRecadoTrades{
    font-size: 1.6rem;
    text-align: center;
    height: 3rem;
    margin-top: 1.4rem;
}

.editTitleButton{
    padding: 0.4rem;
    border: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 1rem;
    border-radius: 8px;
    background-color: #fff;
}

.editTitleButton:hover{
    background-color: #e7e7e7;
}

.inputEditTitle{
    height: 2rem;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    text-align: center;
    font-size: 1.5rem;
}
#tradeIdeasTextContainer {
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#tradeIdeasTextHeader {

    height: 3rem;
    background-color: var(--headerAllBg);
    display: flex;
    flex: row 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#tradeIdeasTextHeader p { 
    margin-left: 1rem;
    font-size: 1.2rem;
}

#tradeIdeasTextHeader button {
    margin-right: 1rem;
}

#tradeIdeasBody {
    overflow-x: hidden;
    width: 95%;
    height: 85%;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    font-size: 1.3rem; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
}

#tradeIdeasFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#tradeIdeasFooter button {
    margin-top: 1rem;
    background: var(--topBarBg);
    color: white;
    width: 10rem;
    height: 2.4rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    transition: 0.2s;
}

#tradeIdeasFooter button:hover {
    background: white;
    color: var(--topBarBg);
    border: 1px solid var(--topBarBg);
}

#tradeIdeaLoading {

    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#tradeIdeasTitleHead {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

#tradeIdeasTitleHead p {
    font-size: 1rem;
}

#tradeIdeasTitleHead input {
    padding-left: 0.3rem;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid rgb(202, 202, 202);
    background: rgb(236, 236, 236);
}

#tradeIdeasTitleHead input::-webkit-input-placeholder {
    
    color: black !important;
}

#tradeIdeasTitleHead input::placeholder {
    
    color: black !important;
}

#tradeIdeasTextContainer ::-webkit-scrollbar {
    width: 10px;
}
  
#tradeIdeasTextContainer ::-webkit-scrollbar-track {
    background: #d3d3d3;
}
  
#tradeIdeasTextContainer ::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
  

#tradeIdeasTextContainer ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

@media (max-height: 750px){
    #tradeIdeasBody {
        height: 70%;
        overflow-x: hidden;
    }
}

#yearImport {
    border: 1px solid rgb(187, 187, 187);
    outline-color: 1px solid rgb(190, 190, 190);
    height: 1.8rem;
    width: 6rem;
    font-size: 1rem;
    padding-left: 10px;
}

.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

.fileInput #getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #stepperRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#stepperRowDiv a{
    font-size: 1.8rem !important;
}


.tableErrorsImport { 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImport thead{ 

    color: white;
}

.tableErrorsImport table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImport thead tr {
  
    height: 1.5rem;
}

.tableErrorsImport tbody tr{ 
    height: 2rem;
}

.tableErrorsImport tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.tableForImportSection {
    max-width: 80rem;
    max-height: 30rem;
    overflow: auto; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSection table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSection table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSection table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableErrorsImport table thead tr th:first-child{
    width: 10rem;
}

.tableErrorsImport table thead tr th:nth-child(2){
    width: 10rem;
}


.tableForImportSection table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

.brokerPanelErrorsExcelTable th, td{
    width: auto;
}

@media(max-width: 1368px){

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 35rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #stepperRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #stepperRowDiv a{
        font-size: 1.6rem !important;
    }
    

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }
    
    .tableForImportSection {
        max-width: 80rem;
        max-height: 20rem;
        overflow: auto; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSection table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSection table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSection table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImport table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImport table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSection table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}

#borkerPanelLinkRegisterContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#brokerPanelLinkRegisterHeader {
    background: var(--headerAllBg);
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

#brokerPanelLinkRegisterHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}


#brokerPanelLinkRegisterHeader button {
    margin-right: 1rem;
}

#brokerPanelLinkRegisterBody {
    display: flex;   
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

#brokerPanelLinkRegisterBody form {
    display: flex;
    width: 50%;
    height: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#brokerPanelLinkRegistrerFormDiv {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;

}

#brokerPanelLinkRegistrerFormDiv label {
    width: 100%;
}

#brokerPanelCategoryButton {
    background: rgb(255, 255, 255);
    height: 2rem;
    width: 8rem;
    border: 1px solid var(--goldColor);
    border-radius: 4px;
    font-size: 1rem;
    color: var(--goldColor);
    font-weight: 400;
    transition: 0.2s;
}

#brokerPanelCategoryButton:hover {
    background: var(--goldColor);
    color: white;
}

.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

.dateImportGoals {
    border: 1px solid rgb(187, 187, 187);
    outline-color: 1px solid rgb(190, 190, 190);
    height: 1.8rem;
    width: 6rem;
    font-size: 1rem;
    margin-left: 0.5rem;

}

.brokerResultsDateSelectContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
}

#resultsBrokerDateSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem
}

.dateImportGoals::-webkit-input-placeholder {
    color: gray;
}

.dateImportGoals::placeholder {
    color: gray;
}


.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

.fileInput #getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #stepperRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#stepperRowDiv a{
    font-size: 1.8rem !important;
}


.tableErrorsImport { 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImport thead{ 

    color: white;
}

.tableErrorsImport table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImport thead tr {
  
    height: 1.5rem;
}

.tableErrorsImport tbody tr{ 
    height: 2rem;
}

.tableErrorsImport tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.tableForImportSection {
    max-width: 80rem;
    max-height: 30rem;
    overflow: auto; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSection table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSection table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSection table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableErrorsImport table thead tr th:first-child{
    width: 10rem;
}

.tableErrorsImport table thead tr th:nth-child(2){
    width: 10rem;
}


.tableForImportSection table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

.brokerPanelErrorsExcelTable th, td{
    width: auto;
}

@media(max-width: 1368px){

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 35rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        grid-gap: 1rem;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #stepperRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #stepperRowDiv a{
        font-size: 1.6rem !important;
    }
    

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }
    
    .tableForImportSection {
        max-width: 80rem;
        max-height: 20rem;
        overflow: auto; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSection table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSection table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSection table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImport table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImport table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSection table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}

.brokerPayrollContainer {
 display: flex;
 flex-direction: column;
 align-items: center;
 height: 100%;
 min-width: 1100px;
}

.recommendationsTableContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden; 
    box-shadow: -2px 2px 20px -7px #000000;
}
.brokerPayrollTable {
    display: flex;  
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.brokerPayrollTableArea {
    margin-top: 1rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    overflow-x: hidden;
    width: 95%;
}

.brokerPayrollTableLoader {
    height: 20rem;
}

.brokerPayrollTable thead tr:first-child th{
    border-radius: 0.5rem 0 0;
}

.brokerPayrollTable thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg);
    z-index: 99;
}

.brokerPayrollTable thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 3.9rem;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
}

.brokerPayrollTable table thead tr th div {
    display: flex;
    flex: row 1;
    align-items: center;
    justify-content: space-between;
}

.brokerPayrollTable table thead tr th div p{
    font-size: 1.2rem;
    color: white;
    font-weight: 300;
    padding-left: 1rem;
}

.payrollHeaderRight {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.brokerPayrollTable tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
    white-space: nowrap;
}

.brokerPayrollTable tbody tr:hover td {
    background: var(--tableHover);
}

tbody{
    overflow: auto;
}

.containerTabelaExtratoMensal{
    overflow: auto;

}

.containerTabelaExtratoMensal table tr th{
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    background: var(--tableSecondTh) !important;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    border-radius: 0px !important;
    white-space: nowrap;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.containerTabelaExtratoMensal td{
    padding-left: 1rem;
    padding-right: 1rem;
}

.buttonsBrokerPayroll > * {
    margin-right: 1rem;
}

.tdSum{
    background: #c0c0c0 !important;
    border-left: 1px solid #c0c0c0 !important;
    border-right: 1px solid #c0c0c0 !important;
    border-top: 1px solid #c0c0c0 !important;
}

.tdSumEmpty{
    background: #d1d1d1 !important;
    border-left: 1px solid #d1d1d1 !important;
    border-right: 1px solid #d1d1d1 !important;
    border-top: 1px solid #d1d1d1 !important;
}

.tdSumMui{
    background: #c0c0c0 !important;
    border-left: 1px solid #c0c0c0 !important;
    border-right: 1px solid #c0c0c0 !important;
    border-top: 1px solid #c0c0c0 !important;
    border-bottom: 1px solid #c0c0c0 !important;
    padding: 0px !important;
    height: 30px !important;
    width: 100vw !important;
    text-align: center !important;
}

.tdSumMuiEmpty {
    background: #ffffff !important;
    border-left: 1px solid #ffffff !important;
    border-right: 1px solid #ffffff !important;
    border-top: 1px solid #ffffff !important;
    border-bottom: 1px solid #ffffff !important;
    padding: 0px !important;
    height: 30px !important;
    width: 100vw !important;
}
.white_space {
    white-space: nowrap;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    max-height: 20vh !important;
}

.tss-qbo1l6-MUIDataTableToolbar-actions{
    display: flex  !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}

.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(1) { order: 1; }
.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(2) { order: 2; }
.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(3) { order: 2; }
.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(4) { order: 1; }
#recommendationsImportConfirm {
    font-size: 1.2rem;
    font-weight: 400;
}
.usersTableContainer {
    width: 100%;
    overflow: auto;
    max-height: 45rem; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersTableNameAndTotalUsers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap:0.3rem;
    gap:0.3rem;
}

#usersTableNameAndTotalUsers a:first-child{
    font-size: 1.2rem;
    font-weight: 300;
}

#usersTableNameAndTotalUsers a:nth-child(2) {
    font-size: 0.9rem;
}

#userCadastration:focus{
    background: var(--goldColor);
    color: black;
}

.theadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.theadFirst a {
    margin-left: 2rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.theadFirst button{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}
.theadFirst button:hover {
    background: var(--formBorder);
    color: black;
}


.theadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.theadFirst label a{
    margin-right: 1rem;
    font-weight: 200;
}

.theadFirst label input {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.theadFirst label input::-webkit-input-placeholder{
    font-size: 0.9rem;
}

.theadFirst label input::placeholder{
    font-size: 0.9rem;
}

.usersTableContainer table{
    width: 100%;
    font-size: 1rem;
    
}

.usersTableContainer table tbody {
    max-height: 35rem;
    background: white;
}

.usersTableContainer table tbody tr {
    cursor: pointer;
}

.usersMyUser {
    font-weight: 400;
    
}

.usersActiveTrLoader {
        
    height: 30rem;
    
}   
.usersTableContainer table thead tr:first-child th{
    height: 5rem;
    border-left: none;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
    width: 90rem;
}


.usersTableContainer table thead tr:nth-child(2) > th  {
    height: 1.5rem;
    font-weight: 300 !important;
}

.usersTableContainer table thead tr:nth-child(2) th {
    background: var(--tableSecondTHGray);
    color: white;        
}


.usersTableContainer thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
}

.usersTableContainer thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 100;
}

.usersTableContainer table thead tr:first-child th{
    height: 4rem;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
}


.usersTableContainer table tbody tr {
    border-bottom: 1px solid var(--tableBorder);
}

.usersTableContainer table tbody tr:last-child{
    border-bottom: none;
}

.usersTableContainer table tbody td {
    padding: 0;
    height: 2.5rem;
    border-right: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    width: 10rem;
    font-size: 1rem;
}
.usersTableContainer table tbody td:nth-child(1){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(4){
    width: 5rem;
}


.usersTableContainer table tbody td:nth-child(6){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(7){
    width: 4rem;
}

.usersTableContainer table tbody td:nth-child(8){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(9){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(10){
    width: 2rem;
}


.usersTableContainer table tbody td:last-child{
    border-right: none;
}

.ellipsisTd {
    position: relative;
    max-width: 10rem;
    overflow: hidden;
    margin-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

.usersTableContainer table tbody td input{
  height: 1.5rem;
  padding: 0;
  padding-left: 0.3rem;
  width: 6rem;
  margin-right: 0.5rem;
}

.usersTableContainer table tbody td a {
    margin-right: 0.5rem;
}

.usersTableContainer table tbody tr:hover {
    background: var(--tableHover) !important;
}

.usersEditShow td {
   
    color: white;
}

.usersEditShow th {
    background: var(--tableSecondTh);

}

.usersEditShow .divForShowItemn {
    display: flex;
    width: 100%;
    height: 4rem;
}

.editDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.editDiv label {
    height: 2.5rem;
    width: auto;
}

.iconsOptions {
    width: 5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
}

.editIcon{
    width: 3rem;
}

.userInactiveAction {
    -webkit-filter: opacity(0.3);
            filter: opacity(0.3)
}    


@media(max-width:1368px){
    
    .usersTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .theadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .theadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .theadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        grid-gap: 0.3rem;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .theadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .theadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .theadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .theadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .theadFirst label input::-webkit-input-placeholder{
        font-size: 0.9rem;
    }

    .theadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersTableContainer table tbody {
        max-height: 35rem;
        background: white;
    }
    
    .usersTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersActiveTrLoader {
        
        height: 20rem;
        
    }

    .usersTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersTableContainer thead tr:first-child th {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersTableContainer thead tr:nth-child(2) th {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }    
    
    .usersTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }

    #usersHideLoadingScroll {
        display: none;
    }

    #usersShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        -webkit-filter: opacity(0.3);
                filter: opacity(0.3)
    }  
    
    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        -webkit-animation: lds-ellipsis1 0.6s infinite;
                animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        -webkit-animation: lds-ellipsis2 0.6s infinite;
                animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        -webkit-animation: lds-ellipsis3 0.6s infinite;
                animation: lds-ellipsis3 0.6s infinite;
      }
      @-webkit-keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes lds-ellipsis1 {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @-webkit-keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0);
                  transform: scale(0);
        }
      }
      @-webkit-keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0);
        }
        100% {
          -webkit-transform: translate(24px, 0);
                  transform: translate(24px, 0);
        }
      }

}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}
.performanceTabs {
  overflow-y: hidden;
  height: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.performanceTabsTable {
  max-height: 45rem;
  width: 95%;
  height: 100%;
  margin-top: 2rem;
}

.performanceTabsLabelActive {
  overflow: hidden;
  white-space: nowrap; 
  border-radius: 8px 8px 0 0 ; 
  height: 100%;
  width: auto;
  max-width: 15rem;
  min-width: 7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

.performanceTabsLabelActive a {
  color: rgb(246, 246, 246);
  font-size: 0.9rem;
  border-bottom: 1px solid var(--goldColor);
}

.performanceTabsLabel {
  overflow: hidden;
  white-space: nowrap;
  border-radius: 8px 8px 0 0 ;
  height: 100%;
  width: auto;
  max-width: 15rem;
  min-width: 7rem;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

.performanceTabsLabel a {
  color: rgb(211, 210, 210);
  font-size: 0.9rem;
  -webkit-filter: brightness(0.80);
          filter: brightness(0.80);
  font-weight: 400;
}

.performanceTabsLabel:hover {
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3)
}

.performanceTableContainer  {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.performanceTableContent {
  width: 100%;
  overflow-x: hidden; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.performanceTableContainer table {
  width: 100%;margin: 0 auto;
  font-size: 1rem;
}

.performanceTableContainer thead  {
  background: var(--topBarBg);
  color: white;    
  font-size: 1rem;
}

.performanceTableContainer thead tr:first-child {
  height: 5rem;
  font-size: 1.8rem;
  font-weight: 200;
}

.performanceTableContainer thead tr:first-child th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  opacity: 1;
  font-size: 1rem;
  z-index: 100;
  background: var(--topBarBg);
}

.performanceTableContainer thead tr:nth-child(2) th {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  opacity: 1;
  font-size: 1rem;
  z-index: 100;
  background: var(--tableSecondTHGray);
}

.performanceTableContainer thead tr:nth-child(2){
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 200;
  background: var(--tableSecondTHGray);
}

.performanceTableContainer thead tr:nth-child(2) th{
  color: white;
  height: 1.5rem;   
  font-size: 1rem;
  font-weight: 300;
}

.performanceTableContainer tbody {
  background: white;
  overflow-y: scroll;
  max-height: 35rem;
  transition: 0.2s;
}

.performanceTableContainer tbody tr:hover{
  background: var(--tableHover);
}


.performanceTableContainer tbody td {
  padding: 0;
  max-width: 5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  height: 2rem;
  border: 1px solid var(--tableBorder);
  text-align: center;
  transition: 0.1s;
  cursor: default;
}

.performanceTableContainer tbody td:nth-child(8){
cursor: pointer;
max-width: 3rem;
width: 3rem;
}

.performanceTableContainer tbody td:nth-child(1) svg {
  color: rgb(110, 110, 110)
}

.performanceTableContainer tbody td:nth-child(1):hover svg {
  font-size: 1.1rem;
}

.reportsTableContent {
  width: 100%;
  overflow-x: hidden; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.reportsTableContainer table {
  width: 100%;margin: 0 auto;
  font-size: 1rem;
}

.performanceTableContainer  {
  text-align: center;
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.performanceTableContainer table {

  width: 100%;
  font-size: 1rem;
}

.performanceTableContainer thead  {
  background: var(--topBarBg);
  color: white;    
}

.performanceTableContainer thead tr:first-child {
  height: 5rem;
  font-size: 1.8rem;
  font-weight: 200;
}

.performanceTableContainer thead tr:first-child th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 100;
  background: var(--topBarBg);
}

.performanceTableContainer thead tr:nth-child(2) th {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  opacity: 1;
  z-index: 100;

  background: var(--tableSecondTHGray);
}

.performanceTableContainer thead tr:nth-child(2){
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 200;
  background: var(--tableSecondTHGray);
}


.performanceTableContainer thead tr:nth-child(2) th{
  color: white;
  height: 1.5rem;   
  font-size: 1rem;
  font-weight: 300;
}

.performanceTableContainer tbody {
  background: white;
  overflow-y: scroll;
  transition: 0.2s;
}

.performanceTableContainer tbody tr:hover{
  background: var(--tableHover);
}

.performanceTableContainer tbody td {
  padding: 0;
  max-width: 6rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 2rem;
  border: 1px solid var(--tableBorder);
  text-align: center;
  transition: 0.1s;
}

.substitutoLabelDisplay{
  display: flex;
}

.substitutoLabelDisplay > *{
  margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
  height: 1.8rem;
  background: #313131;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  padding-left: 0.5rem;
}
.brokerPayrollTable {
    display: flex;  
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.brokerPayrollTableArea {
    margin-top: 1rem; 
    box-shadow: -2px 2px 20px -7px #000000;
    overflow-x: hidden;
    width: 95%;
}

.brokerPayrollTableLoader {
    height: 20rem;
}

.brokerPayrollTable thead tr:first-child th{
    border-radius: 0.5rem 0 0;
}

.brokerPayrollTable thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg);
    z-index: 99;
}

.brokerPayrollTable thead tr:nth-child(2) th {
    position: -webkit-sticky;
    position: sticky;
    top: 3.9rem;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
}

.brokerPayrollTable table thead tr th div {
    display: flex;
    flex: row 1;
    align-items: center;
    justify-content: space-between;
}

.brokerPayrollTable table thead tr th div p{
    font-size: 1.2rem;
    color: white;
    font-weight: 300;
    padding-left: 1rem;
}

.payrollHeaderRight {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.brokerPayrollTable tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
    white-space: nowrap;
}

.brokerPayrollTable tbody tr:hover td {
    background: var(--tableHover);
}

tbody{
    overflow: auto;
}

.containerTabelaExtratoMensal{
    overflow: auto;

}

.containerTabelaExtratoMensal table tr th{
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    background: var(--tableSecondTh) !important;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    border-radius: 0px !important;
    white-space: nowrap;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.containerTabelaExtratoMensal td{
    padding-left: 1rem;
    padding-right: 1rem;
}

.buttonsBrokerPayroll > * {
    margin-right: 1rem;
}

.tdSum{
    background: #c0c0c0 !important;
    border-left: 1px solid #c0c0c0 !important;
    border-right: 1px solid #c0c0c0 !important;
    border-top: 1px solid #c0c0c0 !important;
}

.tdSumEmpty{
    background: #d1d1d1 !important;
    border-left: 1px solid #d1d1d1 !important;
    border-right: 1px solid #d1d1d1 !important;
    border-top: 1px solid #d1d1d1 !important;
}

.tdSumMui{
    background: #c0c0c0 !important;
    border-left: 1px solid #c0c0c0 !important;
    border-right: 1px solid #c0c0c0 !important;
    border-top: 1px solid #c0c0c0 !important;
    border-bottom: 1px solid #c0c0c0 !important;
    padding: 0px !important;
    height: 30px !important;
    width: 100vw !important;
    text-align: center !important;
}

.tdSumMuiEmpty {
    background: #ffffff !important;
    border-left: 1px solid #ffffff !important;
    border-right: 1px solid #ffffff !important;
    border-top: 1px solid #ffffff !important;
    border-bottom: 1px solid #ffffff !important;
    padding: 0px !important;
    height: 30px !important;
    width: 100vw !important;
}

.tss-1q014d6-MUIDataTableHeadCell-dragCursor{
    white-space :normal !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
    max-height: 20vh !important;
}

.tss-qbo1l6-MUIDataTableToolbar-actions{
    display: flex  !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}

.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(1) { order: 1; }
.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(2) { order: 2; }
.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(3) { order: 2; }
.tss-qbo1l6-MUIDataTableToolbar-actions :nth-child(4) { order: 1; }
.containerGeralCreateFormStructure{
  width: 100%;
  margin-left: auto;
  height: calc(100vh - 3rem);
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.containerSecoesPerguntas{
  overflow: auto;
  width: 98%;
  padding-left: 2%;
  max-height: calc(100vh - 18rem);
}

.containerTituloForm{
  display: grid;
  height: 5rem;
  padding-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.tituloTitulo{
  font-size: 1.8rem;
  font-weight: 400;
  color: #11639E;
  text-align: center;
}

.inputTituloTitulo{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.6rem;
  width: 14rem;
  padding: 0.2rem;
  text-align: center;
  margin: auto;
  margin-top: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloTitulo:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.tituloSecao{
  font-size: 1.4rem;
  font-weight: 400;
  color: #11639E;
  margin-left: 0.4rem;
}

.inputTituloSecao{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.2rem;
  width: 14rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-top: 0.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloSecao:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.pergunta{
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  width: 92%;
  border-top: 2px solid #11639E;
  margin: auto;
  margin-top: 0.6rem;
  padding: 0.6rem;
  background: white;
  box-shadow: -1px 3px 6px gray;
}

.barraLateral{
  width: 0.2rem;
  height: 100%;
  background-color: #11639E;
  margin: auto;
  margin-right: 0.6rem;
}

.inputTituloPergunta{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloPergunta:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.opcaoPergunta{
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

.tituloOpcaoPergunta{
  color: #11639E;
  margin-bottom: 0.1rem;
}

.selectTipoPergunta{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.selectTipoPergunta:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tipoPerguntaTexto{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-top: 1rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaTexto:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerRadioOption{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0.6rem;
}

.containerTipoPerguntaOpcaoMultipla{
  margin-top: 1rem;
}

.inputOptionName{
  border: none;
  border: 1px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  min-width: 4rem;
  width: -webkit-min-content;
  width: min-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.inputOptionName:focus{
  outline: none;
  border: 1px solid #11639E;
}

.addSubSection{
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
  border: none;
  background: transparent;
  color: rgb(97, 97, 97);
  transition: all ease-in-out 0.1s;
  white-space: nowrap;
}

.addSubSection:hover{
  color: black;
}

.addRadioOption{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addRadioOption:hover{
  background: #dbdbdb;
}

.deleteRadioOption{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteRadioOption:hover{
  background: #dbdbdb;
}

.botaoNovaPergunta{
  width: 30%;
  margin-top: 0.8rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaPergunta:hover{
  background-color: #064d80;
}

.botaoNovaSecao{
  width: 20%;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaSecao:hover{
  background-color: #064d80;
}

.habilitarObservacao{
  font-weight: 500;
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
}

.containerTipoPerguntaPorcentagem{
  display: grid;
  grid-template-columns: 1fr auto;
  width: 20%;
}

.tipoPerguntaPorcentagem{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 1rem !important;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaPorcentagem:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.porcentagem{
  margin-top: auto;
  padding: 0.05rem;
  padding-left: 0.4rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.tipoPerguntaMonetario{
  margin-top: 1rem !important;
  width: 20%;
}

.containerTipoPerguntaCaixaSelecao{
  margin-top: 1rem;
}

.containerCheckBoxOption{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0.6rem;
}

.addCheckBoxOption{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addCheckBoxOption:hover{
  background: #dbdbdb;
}

.deleteCheckBoxOption{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteCheckBoxOption:hover{
  background: #dbdbdb;
}

.containerCabecalhoPortfolio{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
  max-height: 6rem;
}

.descricaoPortfolio{
  width: 90%;
  margin: auto;
  margin-top: 0.4rem;
  height: 2.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  box-shadow: -1px 3px 6px gray;
}

.descricaoPortfolio:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerDescricaoPortfolio{
  display: grid;
  grid-template-rows: auto 1fr;
}

.containerSelectTipoPortfolio{
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

.selectTipoPortfolio{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  margin-left: 1.2rem;
  height: 2.4rem;
}

.selectTipoPortfolio:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.inputNameFilter{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  height: 1.95rem;
}

.inputNameFilter:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.secao{
  margin-top: 1rem;
}

.containerTags{
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-left: 2px solid #11639E;
  padding-left: 0.6rem;
}

.inputNameTag{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.6rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputNameTag:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tagTitle{
  font-size: 1.5rem;
  font-weight: 400;
}

.addTagButton{
  border: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  font-size: 1rem;
  background: #e6e6e6;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.addTagButton:hover{
  background: #c9c9c9;
  cursor: pointer;
}

.containerInfoTags{
  margin: auto;
}

.deleteTagButton{
  border: none;
  border-right: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1rem;
  background: #c4e6fd;
  font-weight: 500;
}

.deleteTagButton:hover{
  background: #a4c6dd;
  cursor: pointer;
}

.nomeTag{
  font-size: 1.2rem;
  background: #c4e6fd;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin: auto;
  border-left: none;
}

.containerTag{
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-template-columns: auto auto;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  border-radius: 10px;
}

.containerDisplayTags{
  overflow: hidden;
  border: 1px solid #dadada;
  margin-left: 0.4rem;
  max-width: 10rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 4px;
}

.formTags{
  display: flex;
}

.tituloSelectPortfolio{
  font-size: 1.8rem;
  color: #11639E;
  height: 3rem;
}

.containerHeaderSelectPortfolio{
  text-align: center;
  height: 8rem;
  padding-top: 2rem;
  background-color: #d8e0e7;
}

.containerPortfoliosExistentes{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 18rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow: auto;
}

.itemPortfolioAgressivo{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioAgressivo:hover{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.itemPortfolioModerado{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioModerado:hover{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.itemPortfolioConservador{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioConservador:hover{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.itemPortfolioGeral{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioGeral:hover{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #ebebeb;
  cursor: pointer;
}

.tituloPortfolio{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.separacaoItemPortfolioAgressivo{
  background-color: #ff0000;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioModerado{
  background-color: #ffae00;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioConservador{
  background-color: #00ccff;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioGeral{
  background-color: #ebebeb;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.divDescricao{
  display: table;
}

.descricaoItemPortfolio{
  color: white;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.tipoPerfilPortfolioAgressivo{
  color: red;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioModerado{
  color: #ffae00;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioConservador{
  color: #00ccff;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioGeral{
  color: #ebebeb;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.containerOverlayPreviaPortfolio{
  position: absolute;
  width: 100%;
  height: calc(100% - 3rem);
  margin-top: 3rem;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 2fr 3fr;
  background-color: #00000063;
}

.overlayPreviaPortfolio{
  width: 100%;
  height: 100%;
}

.containerDetalhesPortfolioModerado{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #302700;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioConservador{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #00323f;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioGeral{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #3a3a3a;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioAgressivo{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #270000;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}


.containerHeaderDetalhesPortfolio{
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.closeDetailButton{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.closeDetailButton:hover{
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.titleHeaderDetail{
  color: white;
  font-size: 1.8rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  text-align: center;
}

.showInfoDetail{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.containerSubHeaderDetalhesPortfolio{
  display: grid;
  grid-template-columns: 10rem auto 10rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.infoSubHeaderL{
  color: white;
  font-size: 1.4rem;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.infoSubHeaderR{
  color: white;
  font-size: 1.4rem;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.divisaoSubHeaderConservador{
  width: 2px;
  height: 100%;
  background-color: #00ccff;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderGeral{
  width: 2px;
  height: 100%;
  background-color: #ebebeb;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderModerado{
  width: 2px;
  height: 100%;
  background-color: #ffae00;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderAgressivo{
  width: 2px;
  height: 100%;
  background-color: red;
  margin-left: 2rem;
  margin-right: 2rem;
}

.containerSecoesPortfolio{
  width: 90%;
  margin-left: auto;
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.selectPortfolioAtual{
  margin-top: auto;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
}

.selectPortfolioAtual:hover{
  background-color: #e6e6e6;
  color: #141414;
}

.secaoDetalhePortfolioConservador{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioConservador:hover{
  color: #00ccff;
  cursor: pointer;
}

.secaoDetalhePortfolioGeral{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioGeral:hover{
  color: #ebebeb;
  cursor: pointer;
}

.secaoDetalhePortfolioModerado{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioModerado:hover{
  color: #ffae00;
  cursor: pointer;
}

.secaoDetalhePortfolioAgressivo{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioAgressivo:hover{
  color: #ff0000;
  cursor: pointer;
}

.perguntaDetalhePortfolioConservador{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioConservador:hover{
  color: #00ccff;
  cursor: default;
}

.perguntaDetalhePortfolioGeral{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioGeral:hover{
  color: #ebebeb;
  cursor: default;
}

.perguntaDetalhePortfolioModerado{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioModerado:hover{
  color: #ffae00;
  cursor: default;
}

.perguntaDetalhePortfolioAgressivo{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioAgressivo:hover{
  color: #ff0000;
  cursor: default;
}

.loaderAllForms{
  margin-top: 5rem !important;
}

.loaderSpecificForm{
  margin-top: 42vh;
}

.resetFilter{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 2.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background: transparent;
  background-color: rgb(218, 218, 218);
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
  box-shadow: -1px 3px 6px gray;
}

.resetFilter:hover{
  background-color: rgb(218, 218, 218);
  box-shadow: -1px 2px 2px gray;
}

.containerButtonCreateForm{
  display: grid;
  height: 6rem;
  margin-top: auto;
  background-color: #d8e0e7;
}

.createFormButton{
  font-size: 2rem;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.createFormButton:hover{
  background-color: #cecece;
  color: #141414;
}

.containerOverlay{
  position: absolute;
  background-color: #d8e0e7;
  width: 100%;
  height: calc(100vh - 3rem);
}

.containerOverlay{
  display: grid;
}

.containerEscolhaTitulo{
  display: grid;
  grid-template-rows: auto auto auto;
  width: 50%;
  margin: auto;
}

.tituloEscolhaTitulo{
  font-size: 1.8rem;
  color: #11639E;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.inputReplyTitle{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 2rem;
  height: 1.95rem;
}

.inputReplyTitle:focus{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.confirmReplyTitle{
  width: 30%;
  margin: auto;
  margin-top: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.confirmReplyTitle:hover{
  background-color: #064d80;
}

.containerMeusPortfoliosExistentes{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 15rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 17rem);
  max-height: calc(100vh - 17rem);
  overflow: auto;
}

.MeuItemPortfolioAgressivo{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioAgressivo:hover{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.MeuItemPortfolioModerado{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioModerado:hover{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.MeuItemPortfolioConservador{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioConservador:hover{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.MeuItemPortfolioGeral{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioGeral:hover{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #c2c1c1;
  cursor: pointer;
}

.tituloMeuPortfolio{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detalheMeuItemPortfolio{
  color: white;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nomeAssessorPortfolioConservador{
  color: #001318;
  background-color: #33d6ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioModerado{
  color: #1f1900;
  background-color: #ffb923;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioAgressivo{
  color: #160000;
  background-color: #ff2d2d;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioGeral{
  color: #141414;
  background-color: #c2c2c2;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dataPortfolioGeral{
  color: #141414;
  background-color: #c2c2c2;
  position: absolute;
  bottom: 97%;
  left: 50%;
  -webkit-transform: translate(-50%, 30%);
          transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.containerButtonCreateForm{
  display: grid;
  grid-template-columns: auto 1fr;
}

.modoEdicaoText{
  font-size: 1.6rem;
  font-weight: 500;
  height: auto;
  width: auto;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #188f00;
}
.containerCabecalho{
  /* display: flex;
  justify-content: space-between; */
  /* min-height: 4rem; */
  display: inline-block;
  display: grid;
  grid-template-columns: 30vh auto 30vh;
  width: 100%;
  background-color: #d8e0e7;
  padding-top: 1rem;
  border-bottom: 1rem solid #d8e0e7;
}

.boxAnswers{
  max-height: calc(100vh - 17rem);
  height: calc(100vh - 15rem);
  overflow-y: auto;
}

.containerCabecalhoPortfolio{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
}

.boxTituloPerfil{
  display: flex;
  margin-right: 5rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  right: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto
}

.backArrow{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 2rem;
}

.buttonOpenCloseSection{
  right: 2rem;
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  color: rgb(0, 0, 0);
}

.buttonOpenCloseSection:hover {
  color: rgb(97, 97, 97);
  cursor: pointer;
}

.sectionTitle{
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
}

.questionDescription{
  margin-left: 2rem;
}

.checkbox{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.radiobox{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.gridteste{
  display: grid;
  grid-template-columns: 4rem auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.opcoesDaPergunta{
  display: grid;
}

.finishButton{
  margin-top: 2rem;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.finishButton:hover{
  background-color: #cecece;
  color: #141414;
}

.boxFinish{
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #d8e0e7;
  height: 6rem;
}

.saveButton{
  display: grid;
  grid-template-columns: auto auto;
  width: 13rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  border: none;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.2rem;
  background: transparent;
  transition: all ease-in-out 0.1s;
}

.saveButton:hover{
  background: #b8b8b8;
}

.saveProgressTitle{
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.backArrow{
  border: none;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 0.2rem;
  background: transparent;
  color: black;
  transition: all ease-in-out 0.1s;
}

.backArrow:hover{
  background: #d4d4d4;
}

.containerSaving{
  display: grid;
  grid-template-columns: auto auto;
}

.savingText {
  font-size: 1.6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 2.4rem;
  color: #494949;
}

.containerDotsSaving{
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 0.6rem;
}

.containerDotsSaving > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.containerDotsSaving > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.dotSaving{
  width: 0.2rem;
  height: 0.2rem;
  background-color: #242424;
  border-radius: 50%;
  margin: auto;
  margin-left: 0.4rem;
  -webkit-animation: dotAnimationSaving 0.6s infinite alternate;
          animation: dotAnimationSaving 0.6s infinite alternate;
}

@-webkit-keyframes dotAnimationSaving {
  to {
    opacity: 0;
  }
}

@keyframes dotAnimationSaving {
  to {
    opacity: 0;
  }
}

.savedText{
  color: #186800;
  font-size: 1.6rem;
  margin: auto;
  margin-left: 2.4rem;
}

.observation{
  margin-left: 3rem;
}

.observationText{
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 1.5rem;
}
.containerSimulacaoTermoCaixa{
  width: calc(100vw - 50px);
  height: calc(100vh - 3rem);
  display: grid;
  grid-template-rows: auto auto auto auto auto auto 1fr auto;
}

.tituloSimulacaoTermoCaixa{
  width: calc(100% - 4rem);
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 20fr auto 20fr;
}

.containerDivisaoInformacoesGerais{
  display: grid;
  grid-template-columns: 1fr auto 20fr;
  height: 1.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.linhaHorizontal{
  background-color: #838282;
  height: 2px;
  width: 100%;
  margin: auto;
}

.tituloDivisao{
  font-size: 1.2rem;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  font-weight: 400;
}

.containerInformacoesGerais{
  display: grid;
  grid-template-columns: 5fr auto 5fr;
  height: 6rem;
  /* width: 100%;
  margin: auto; */
  padding-left: 1rem;
  /* padding-right: 2rem; */
}

.containerInformacoesGeraisDados{
  display: grid;
  grid-template-columns: auto 15rem 8rem auto 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  grid-column-gap: 1.0rem;
  -webkit-column-gap: 1.0rem;
          column-gap: 1.0rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 4rem;
  margin-right: 4rem;
}

.divisaoContainerInformacoesGerais{
  background-color: #838282;
  width: 1px;
  height: 100%;
  margin: auto;
}

.nomeDado{
  font-size: 1.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 0;
  white-space: nowrap;
}

.dado{
  font-size: 1.4rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  white-space: nowrap;
}

.nomeDadoValores{
  font-size: 1.2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 0;
  white-space: nowrap;
  grid-column-gap: 1.0rem;
  -webkit-column-gap: 1.0rem;
          column-gap: 1.0rem;
}

.selectCliente{
  width: 100%;
  /* height: fit-content; */
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1rem;
  border: 1px solid #c49e17 !important;
  border-radius: 4px;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.selectCliente:focus{
  border: 1px solid #ffc800 !important;
  outline: none !important;
}
.selectCliente:hover{
  border: 1px solid #ffc800 !important;
  outline: none !important;
}

.inputPrazo{
  width: 3rem;
  height: 1.2rem;
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c49e17;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}
.inputPrazo:focus{
  border: 1px solid #ffc800;
  outline: none;
}
.inputPrazo:hover{
  border: 1px solid #ffc800;
  outline: none;
}

.inputPorcentagem{
  width: 7rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c49e17;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}

.diasCorridos{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: auto;
  margin-left: 0.3rem;
  white-space: nowrap;
}

.containerInputPrazo{
  display: grid;
  grid-template-columns: auto 1fr;
}

.inputData{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c49e17;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}
.inputData:focus{
  border: 1px solid #ffc800;
  outline: none;
}
.inputData:hover{
  border: 1px solid #ffc800;
  outline: none;
}

.divisaoContainerInformacoesGeraisDados{
  width: 3rem;
}

.containerInformacoesGeraisValores{
  display: grid;
  grid-template-columns: 1fr auto ;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 0.6rem;
  -webkit-column-gap: 0.6rem;
          column-gap: 0.6rem;
  grid-row-gap: 0.1rem;
  row-gap: 0.1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  margin-left: 4rem;
}

.divisaoContainerInformacoesGeraisGridValores{
  display: grid;
  grid-template-columns: auto auto;
}

.porcentagemTermoCaixa{
  margin: auto;
  font-size: 1.2rem;
}

.containerOperacaoEmBolsa{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-template-rows: auto 1fr;
  height: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.colunaOperacaoEmBolsa{
  font-size: 1.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 0.6rem;
  white-space: nowrap;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.dadoOperacaoEmBolsa{
  font-size: 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 0.6rem;
}

.campoMonetarioProps{
  border-color: #c49e17 !important;
}


.campoMonetario{
  background-color: white;
  width: 68%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
}

.campoTextoAtivo{
  background-color: white;
  font-size: 1.2rem;
  width: 60%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #c49e17;
  margin: auto;
  padding: 0.2rem;
}
.campoTextoAtivo:focus{
  border: 1px solid #ffc800;
  outline: none;
}
.campoTextoAtivo:hover{
  border: 1px solid #ffc800;
  outline: none;
}

.containerDivisaoFluxoCustos{
  display: grid;
  grid-template-columns: 2fr auto 2fr auto 1fr;
  height: 1.6rem;
  margin-top: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.containerFluxoCustos{
  display: grid;
  grid-template-columns: 5fr auto 3fr;
  height: 100%;
  width: calc(98% - 4rem);
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.divisaoContainerFluxoCustos{
  background-color: #838282;
  width: 1px;
  height: 90%;
  margin: auto;
}

.containerSolicitar{
  height: 5rem;
  width: 100%;
  background-color: white;
  display: grid;
}

.containerFluxo{
  display: grid;
  grid-template-rows: 3fr 4fr;
  grid-row-gap: 0.6rem;
  row-gap: 0.6rem;
  padding-bottom: 1rem;
}

.containerCustos{
  padding-bottom: 1rem;
}

.containerInternoFluxoDeCaixa{
  display: grid;
  grid-template-rows: auto 1fr;
}

.nomeTabelaFluxoDeCaixa{
  background: linear-gradient(0deg, #cfcfcf 50%, transparent 50%);
  font-weight: 500;
  padding-left: 1rem;
  font-size: 1rem;
  height: 1.4rem;
}

.nomeTabelaFluxoDeCaixaVazio{
  background: linear-gradient(0deg, #cfcfcf 50%, transparent 50%);
  height: 1.4rem;
  width: 100%;
}

.containerTabelaInicio{
  background-color: #cfcfcf;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.containerTabelaFim{
  background-color: #cfcfcf;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.tabelaInicio{
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-template-rows: auto auto auto auto auto;
  height: 100%;
}

.tabelaFim{
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-template-rows: auto auto auto auto auto;
  height: 100%;
}

.nomeColunaTabela{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}

.dadoTabela{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
}

.linhaHorizontalTabela{
  height: 2px;
  width: 80%;
  margin: auto;
  background-color: #838282;
}

.dadoTabelaTotal{
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: #775D01;
}

.linhaHorizontalTabelaTotal{
  height: 2px;
  width: 80%;
  margin: auto;
  background-color: #775D01;
}

.containerTabelasCustos{
  display: grid;
  grid-template-rows: 3fr auto 1fr;
  width: 100%;
  height: calc(100% - 1.4rem);
}

.containerTabelaDescricao{
  background-color: #cfcfcf;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto auto auto auto auto;
  padding-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.containerTabelaTaxaEspecifica{
  background-color: #D3C48F;
}

.tituloTaxaEspecifica{
  background: linear-gradient(0deg, #D3C48F 50%, #cfcfcf 50%);
  font-weight: 400;
  font-size: 1.2rem;
  padding-left: 0.9rem;
  padding-top: 0.2rem;
  color: #7a7a7a;
}

.containerItensTabelaTaxaEspecifica{
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr 1fr;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: calc(100% - 4rem);
  height: 90%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.1rem;
}

.barraLateralTaxaEspecifica{
  width: 2px;
  height: 96%;
  background-color: #c49e17;
  grid-row-start: 1;
  grid-row-end: 3;
}

.dadoTaxaEspecifica{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1rem;
  font-weight: 500;
  color: #775D01;
  margin-left: 0.8rem;
}

.porcentagemTaxaEspecifica{
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.2rem;
  -webkit-column-gap: 0.2rem;
          column-gap: 0.2rem;
  margin-left: 0.6rem;
}

.dadoTaxaEspecificaSemInput{
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.6rem;
  -webkit-column-gap: 0.6rem;
          column-gap: 0.6rem;
  width: 100%;
}

.botaoSolicitar{
  width: 16%;
  height: 2.4rem;
  margin: auto;
  margin-bottom: max(auto,0.2rem);
  font-size: 1.2rem;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 2px;
  transition: all ease-in-out 0.1s;
}

.botaoSolicitar:hover{
  background-color: #a88812;
  border: 1px solid #a88812;
  box-shadow: 0px 0px 10px #ffc800;
}

.botaoSolicitar2{
  width: 16%;
  height: 2.4rem;
  margin: auto;
  margin-bottom: max(auto,0.2rem);
  font-size: 1.2rem;
  border: 1px solid #53910c;
  color: #fff;
  background-color: #138840;
  border-radius: 2px;
  transition: all ease-in-out 0.1s;
}

.botaoSolicitar2:hover{
  background-color: #19af52;;
  border: 1px solid #64a51a;
  box-shadow: 0px 0px 10px #498109;
}

.campoPorcentagemProps{
  border-color: #c49e17 !important;
  font-size: 0.8rem !important;

}

/* .MuiOutlinedInput-input{
  font-size: 0.8rem !important;

} */

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  /* text-align: center; */
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 6px;
  margin-top: -2rem;
  margin-left: -5rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Tooltip text 2 (menos margin-left)*/
.tooltip .tooltiptext2 {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  border-radius: 6px;
  margin-top: -1.6rem;
  margin-left: -0.8rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptext2 {
  visibility: visible;
}

.gapPorcentagem{
  margin-right: 1rem;
}

.textConfirmation{
  color: red;
  font-size: 1.5rem;
  text-align: center;
}

.divButtonImport{
  display: flex;
  justify-content: flex-end;
  align-self: center ;
}

.informationsImportButton{
  width: 15rem;
  align-items: center;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  height: 1.8rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);
}

.informationsDownloadLayoutButton{
  width: 10rem;
  align-items: center;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  height: 1.8rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);
  margin-right: 3rem;

}
.tdTaxas{
    padding: 0px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
    text-align: center !important;
    border-bottom: 0px !important;
}

.thTaxas{
    text-align: center  !important;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.tr2Taxas{
    display: flex !important;
    justify-content: space-evenly !important;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.th2Taxas{
    border-bottom: 0px !important;
    text-align: center  !important;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    padding: 0 !important;

}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root  css-i4bv87-MuiSvgIcon-root{
    width: 50px !important;
    height: 50px !important;

}

.css-1m13d3u-MuiTableCell-root{
    font-size: 0.8rem !important;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
    font-size: 0.8rem !important;
}
.abcMainTableAbc{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  /* margin-top: 2rem; */
}

.abcManagementMainTableAbc{
  margin-left: 2rem;
  margin-right: 2rem;
  /* margin-top: 2rem; */
}

.abcManagementMainTableBoxAbc {
  padding-top: 2rem;
}
body{
  background-color: #FDFDFD;
}

.abcMainTableBox{
  padding-top: 2rem;
}

.headerProductCellBoxAbc{
  background-color: #fffcef;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-right: 0.5rem;
  /* margin-left: 0.5rem; */
  /* border-left: 1px solid black !important; */
  padding: 0.6rem;
}
.headerProductCellBoxAbc:hover{
  cursor: pointer;
}

.titleCellBoxAbc{
  display: grid;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap;;
  grid-template-columns: auto 1fr auto;
}

.containerAderenceIconsAbc{
  display: grid;
  background-color: #fffcef;
  padding-top: 0.4rem;
  margin-right: 0.5rem;
  height: 2rem;
}

.aderenceIconsAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.IconsAbc{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.aderenceIconsAbcActive{
  color: #00801C
}

.aderenceIconsAbcInactive{
  color: #797979
}

.productTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: table;
  text-align: center;
  white-space: nowrap;
  margin: auto;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.modalActionsIconsAbc{
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  margin-left: auto;
  padding-right: 0.6rem;
}

.statusDivAbc{
  background-color: #fffdf9;
  color: #141414;
  border-radius: 4px;
  vertical-align: middle;
  display: grid;
  grid-template-columns: auto auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 0.3rem;
  margin-left: 0.2rem;
  margin-right: 0.4rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 4px #c0b091;
}

.statusDivAbc:hover{
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
}

.containerIconStatusAbc{
  width: 1.3rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  display: grid;
}

.textStatusAbc{
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.iconStatusAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.subtitleCellBoxAbc {
  display: grid;
  padding-top: 0.4rem;
}

.subtitleObjectiveAbc{
  display: table;
  text-align: center;
}

.subtitleActionsAbc{
  display: table;
  text-align: center;
}

.arrowSortAbc{
  display: table;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin: auto;
}

.lineProductsAbc{
  display: grid;
  grid-template-columns: 4fr 3fr;
  background-color:#fffcef;
}

.lineProductsActionsAbc{
  display: grid;
}

.statusColorDivAbc{
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 5px;
  position: relative;
  /* z-index: 10; */
}

.actionDivAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 14px;
  margin: auto;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  background-color: #fffdf9;
  color: #141414;
  border-radius: 2px;
  font-size: 0.7rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 2px #c0b091;
}

.actionDivAbc:hover{
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
}

.leftTesteAbc{
  background: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0rem !important;
  border-left: 0.5rem solid white !important;
  position: -webkit-sticky;
  position: sticky; 
  top: 0; 
  z-index: 1
}

.rightTesteAbc{
  background: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0rem !important;
  border-right: 0.5rem solid white !important;
  position: -webkit-sticky;
  position: sticky; 
  top: 0; 
  z-index: 1
  /* margin-left: 1px solid black !important; */
}


.testeAbc{
  background: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0rem !important;
  /* white-space: nowrap !important;
  display: table-cell !important;
  text-align: center !important; */
  /* margin-left: 1px solid black !important; */
  position: -webkit-sticky;
  position: sticky; 
  top: 0; 
  z-index: 1
}

/* .testedeHeaderAbc{
  background-color: #00801C;
  white-space: nowrap;
  display: table-cell;
  text-align: center;
} */

.lineProductsAbcNetContainer{
  display: grid;
  grid-template-columns: 1fr auto;
}

.marginValueObjetivo{
  background-color: #fffcef;
  padding-right: 0.4rem;
  padding-left: 0.6rem;
}

.legendsFooterAbcBox{
  display: grid;
  grid-template-columns: auto auto auto;
}

.legendFooterAbc{
  margin-left: 1rem;
}

.legendFooterItemsGeralAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.legendFooterItemsStatusAbc{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.legendFooterItemsAderenciaAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.legendFooterItemsAbc{
  display: grid;
  grid-template-columns: auto 1fr;
  padding-right: 0.3rem;
}

.statusColorFooterDivAbc{
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 5px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  border: 2px solid #E9E9E9;
}

.aderenceIconsFooterAbcActive{
  color: #00801C
}

.aderenceIconsFooterAbc{
  padding-top: 0.15rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.cellHoverAbc {
  background-color: #BBD2E3;
  border-bottom: "1px solid rgba(224, 224, 224, 1)";
  border-top: "1px solid rgba(224, 224, 224, 1)";
}

.btnsBoxAbc {
  display: grid;
  grid-template-columns: auto auto;
  /* justify-items: center; */
  /* padding-right: 2rem; */
}

.btnMngAbc {
  width: 3rem;
  height: 1rem;
  margin: auto;
  padding: 0.3rem;
  background-color: #fffdf9;
  color: #141414;
  border-radius: 2px;
  font-size: 1rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 2px #c0b091;
}

.btnMngAbc:hover {
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
}


/* .actionDivAbc{
  width: fit-content;
  height: 14px;
  margin: auto;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  background-color: #fffdf9;
  color: #141414;
  border-radius: 2px;
  font-size: 0.7rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 2px #c0b091;
}

.actionDivAbc:hover{
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
} */

.containerStatusAbc{
    width: 20rem;
    height: 20%;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

.containerStatusConfirm{
    width: 20rem;
    height: 5rem;
    background-color: #D9D9D9;
}

.statusConfirm{
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 10%;
    justify-items: center;
}

.containerStatusList{
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.containerStatusList > h3{
    display: flex;
    justify-content: center;
}

.containerStatusName{
    display: grid;
    grid-template-columns: auto auto 1fr;
  }

.containerSelectAll{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 10%;
    margin-bottom: 5%;
}

.containerCheckbox{
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.h2status{
    font-size: 24px;
}

.observationFilterAbc{
    /* color: red; */
    padding: 1rem 1rem 0 1rem;
    font-size: 0.7rem;
    font-weight: 400;
}
.editBrancheBoxHeaderAbc{
  background: var(--headerAllBg);
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.editBrancheBoxHeaderAbc p {
  font-size: 1.4rem;
  border-bottom: 1px solid var(--goldColor);
  margin-left: 1rem;
}

.editBrancheBoxHeaderAbc button {
  margin-right: 1rem;
}

.containerModalAbcDetails{
  width: 90vw;
  margin: auto;
  height: 90vh;
  background-color: white;
}

.containerProductAdherence{
  width: 100%;
  height: 3rem;
  background-color: #5f5f5f;
}

.containerProductName{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

/* .containerProductName p {
  font-weight: 400;
} */

.containerClientMenuAbcActions{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 4fr 1px 4fr 1px 3fr 1px 4fr;
}

.containerClientMenuAbcActionsAbrir{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 5fr 1px 3fr 1px 6fr;
}

.containerBoletarAbc{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 3fr 1px 3fr;
}

.clientDetails {
  color: black
}

.referenceDetails {
  color: blue
}

.containerClientMenuOptionsAbcActions{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
}

.containerListproductsAbc{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

.toolbarBoletarAbc{
  /* border: 1px solid var(--headerAllBg); */
  /* border-radius: 5px; */
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 2.5rem;
}


.divisionHeaderCardAbc{
  background-color: #B19942;
  width: 96%;
  height: 2px;
  margin-left: 2%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}
.listOperationProductsAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  height: 36.5rem;
  overflow-x: auto;
}

.productCardAbcInfos{
  margin-left: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.offerCardAbcInfos{
  margin-left: 3rem;
  margin-right: 3rem;
  /* display: grid;
  grid-template-columns: auto auto; */
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.offerItemAbc{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 15rem 1fr;
}

.productCardAbc{
  background-color: #ebebe9;
  min-height: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardActiveAbc{
  background: #ebebe9;
  border: 1px solid #c49e17;
  box-shadow: 0 0 6px 4px #c49e17;
  min-height: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardAbc:hover{
  box-shadow: 0 0px 10px #c49e17;
  cursor: pointer;
}

.cardAbcHeader{
  font-weight: 400;
}

.containerClientMenuDashActionsAbas{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 1rem;
}
.campanhaSelecionada{
  background-color: #c49e17;
  color: white;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5rem;
  font-weight: 400;
  cursor: pointer;
  width: 170px;
  text-align: center;
}
.campanhasNaoSelecionada{
  background-color: #e2e2e2;
  color: #c49e17;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5rem;
  font-weight: 400;
  cursor: pointer;
  width: 170px;
  text-align: center;
}

.productCardAbcHeader{ 
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 1fr 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  
}

.containerNameAbcActions{
  display: grid;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 1.2rem;
}

.containerOperateProductAbc{
  border: 1px solid var(--headerAllBg);
  border-radius: 5px;
  margin: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 6rem;
  /* max-width: 36rem; */
}

.headerOperateProductAbc{
  max-width: 100%;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 400;
  color: #5E4C0B;
}

.bodyOperateProductAbc{
  border: 1px solid #ffffff;
  color: #5E4C0B;
  font-size: 1rem;
  font-weight: 400;
}

.contentBodyOperateProductAbc{
  margin-top: 6rem;
}

.contentBodyOperateOfferAbc{
  margin-top: auto;
  margin-bottom: auto;
}

.boxSubmitOperateProductAbc{
  max-width: 100%;
  margin: auto;
}

.buttonOperateSubmitABC{
  width: 10rem;
  padding: 0.2rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
}

.switchSelector{
  margin:auto;
  text-align: center;
  border-radius: 1.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  border: 1px solid #c49e17;
}

.buttonSwitchSelected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #fff;
  background-color: #c49e17;
  transition: all ease-in-out 0.1s;
}

.buttonSwitchUnselected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #c49e17;
  background-color: transparent;
  transition: all ease-in-out 0.1s;
}

.toolbarSelectOrderBoletarAbc{
  margin-left: auto;
  margin-right: 1.5rem;
}


.itemBodyOperateProductAbc{
  margin-top: 1rem;
}

.itemBodyOperateOfferAbc{
  margin-top: 0rem;
}

.fieldsOperateAbc{
  margin-top: 3rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.fieldsOperateOfferAbc{
  margin-top: 1rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.buttonOperateSubmitABC:hover{
  background-color: #a88812;
  border: 1px solid #a88812;
  box-shadow: 0px 0px 10px #ffc800;
}

.clientInfoAbcActions{
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0.8rem;
  font-weight: 400;
}

.productInfoAbcActions{
  font-size: 1rem;
  text-align: center;
}

.divisionNameProductSubcategory{
  width: 100%;
  height: 96%;
  background-color: #c49e17;
  margin: auto;
}

.containerGeneralOptionsAbcActions{
  width: 100%;
  height: 98%;
  display: grid;
  position: relative;
}

.containerOptionsTitleAbcActions{
  z-index: 1;
  background-color: white;
  text-align: center;
  margin-bottom: -20px;
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.optionsTitleAbcActions{
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: white;
}

.containerOptionsAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.containerGeneralChangeStatusAbcActions{
  width: 100%;
  height: 12.5rem;
  display: grid;
  position: relative;
}



.containerChangeStatusTitleAbcActions{
  position: absolute;
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.ChangeStatusTitleAbcActions{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  background-color: white;
  margin: auto;
  font-size: 1.2rem;
}

.containerChangeStatusAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.buttonAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #c49e17;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActions:hover{
  background-color: #e6e2d7;
  color: #c49e17;
  border-left: 1px solid #c49e17;
  border-right: 1px solid #c49e17;
}

.buttonAbcActionsSolicit{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: rgb(241, 170, 63);
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActionsSolicit:hover{
  background-color: #e6e2d7;
  color: rgb(241, 170, 63);
  border-left: 1px solid rgb(241, 170, 63);
  border-right: 1px solid rgb(241, 170, 63);
}

.buttonAbordadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #3177b8;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbordadoAbcActions:hover{
  background-color: #d3dee7;
  color: #3177b8;
  border-left: 1px solid #3177b8;
  border-right: 1px solid #3177b8;
}

.buttonRecusadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #d64747;
  color: white;
  transition: all ease 0.1s;
}
.buttonRecusadoAbcActions:hover{
  background-color: #e6d6d6;
  color: #d64747;
  border-left: 1px solid #d64747;
  border-right: 1px solid #d64747;
}

.buttonNaoAderenteAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #696969;
  color: white;
  transition: all ease 0.1s;
}
.buttonNaoAderenteAbcActions:hover{
  background-color: #e6e6e6;
  color: #2b2b2b;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

.containerClientMenuSubcategoryAbcActions{
  margin: 1.5rem 1rem 1.5rem 1rem;
  border: 1px solid #e2e2e2;
  /* background: #fbfbfb; */
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.headerSubcategoryAbcActions{
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3.5rem;
  grid-template-columns: 1fr auto;
}

.headerSubcategoryTitleAbcActions{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 1rem;
  padding-top: 0.8rem;
  overflow-y: auto;
}

.containerIconCopyContentAbcActions{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* padding-left: 0.7rem;
  padding-right: 0.7rem; */
  margin: 0.4rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcActions:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.containerIconCopyContentAbcDetails{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcDetails:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.linkMenuModalBoxAbc{
  height: 1rem;
}

.linkMenuModalBoxDescriptionAbc{
  height: 1rem;
}

.searchIconModalAbc{
  border: none;
  background-color: transparent;
}

.containerSubcategoryDescriptionAbcActions{
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  background-color: #e2e2e2;
}

.descriptionTextAbc{
  margin-left: 1rem;
  margin-top: 1rem;
}

.iconCopyContentAbcActionsAtive{
  -webkit-animation-name: glowing2;
          animation-name: glowing2;
  -webkit-animation-duration: 1.0s;
          animation-duration: 1.0s;
}

.suggestionMessage{
  background-color: #c49e17;
  height: 13rem;
  margin: 1rem;
  color: white
}

.suggestionMessageHeader{
  font-size: 1.2rem;
  padding: 1rem 0.5rem 0rem 0.5rem;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.iconArrowRightAbc{
  float: right;
  margin-right: 1rem;
  margin-top: -0.8rem;
}

.linksBoxAbc{
  overflow-x: auto;
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3rem;
  max-height: 9rem;
  grid-template-columns: auto 2rem;
}


.suggestionMessageBody{
  font-size: 1rem;
  padding: 2rem 0.5rem 1rem 0.5rem;
  align-items: center;
  text-align: center;
}

.alertMessageAbc{
  border: 1px solid #c49e17;
  border-radius: 10px;
  -webkit-animation: glowing3 2000ms infinite;
          animation: glowing3 2000ms infinite;
}

@-webkit-keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@-webkit-keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

@keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

.commentBoxAbc{
  position:relative;
}
.timelineNewCommentBoxAbc{
  /* height: 12rem; */
  position: absolute;
  width: 27.5rem;
  /* top: 8%; */
  margin: 1.5rem;
  display: grid;
  grid-template-rows: 1fr 3fr;
}

.timelineNewCommentContactTypeAbc{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.timelineNewCommentContactTypeTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 0.4rem;
}

.timelineNewCommentWriteSendAbc{
  display: grid;
  grid-template-columns: 10fr 0fr;
}
.productCadastrationContainerAbcCopyWithoutTemplate {
  display: grid;
  grid-template-rows: none;
  width: 85rem;
  background: #FFF;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.productCadastrationContainerAbc {
  display: grid;
  grid-template-rows: auto auto auto 5rem;
  width: 85rem;
  background: #FFF;
  justify-content: center;
  align-items: center;
}
.aderenceAbcActiveIcon{
  color: green
}

.aderenceAbcInativeIcon{
  color: rgb(160, 160, 160)
}

.containerProductNameTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.5rem;
  margin-left: 1rem;
}

.containerProductHeaderAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.containerProductNameAbc{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.modalDetailsIconsAbc{
  margin-left: 2rem;
}

.modalDetailsAderenceAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  border-width: 0px 3px;
  padding: 0 1rem;
  border-style: solid;
  border-radius: 4px;
  background-color: #EDEDED; 
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.productCadastrationHeaderAbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--headerAllBg);
  width: 100%;
  height: 5rem;
  color: var(--headerFontColor);
  font-size: 1.3rem;
}

.productCadastrationHeaderAbc span{
  border-bottom: 0.1rem solid var(--formBorder);
  padding: 1rem;
}

.productCadastrationHeaderAbc button{
  display: flex;
  margin-right: 2rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid var(--formBorder);
  color: var(--formBorder);
}

.productCadastrationUpperUpperGridAbc {
  /* display: grid;
  grid-template-columns: 1fr;   
  grid-template-rows: auto;    */
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  /* gap: 1rem; */
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationUpperGridAbc {
  display: grid;
  grid-template-columns: 1fr 1fr;   
  grid-template-rows: auto auto auto;   
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationCategoriesGridAbc {
  margin-top: 1rem;

}

.productCadastrationCategoriesGridButtonAbc {
  margin: 1rem 2rem;
}

.productCadastrationCategoryRowAbc{
  display: grid;
  margin: 1rem 1rem 0 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 4fr 4fr auto;
  width: 50rem;
}

.productCadastrationCategoryRowAbc .buttonWhiteBg {
  height: auto;
}

.productCadastrationContainerRowItemAbc {
  /* width: 25rem; */
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
}

.productCadastrationSubmitAbc {
  margin-left: 2rem;
}
.modalDetailsAderenceAbcActive{
  border-color: #00801C;
}

.modalDetailsAderenceAbcInative{
  border-color: rgb(160, 160, 160);
}

.conteudoGeralDetailsModalAbc{
  display: grid;
  grid-template-columns: 4fr 7fr 6fr;
  height: 36rem
}

.menuCategoryModalAbc{
  margin-top: 1.5rem;
  position: relative;
}

.listClientsModalAbc{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 450;
  overflow-y: auto;
  /* max-height: 33rem; */
}

.menuCategoryItemAbcActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInative:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcActiveAbrir{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInativeAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInativeAbrir:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcGeralActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.menuCategoryItemAbcGeralInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.listClientsHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
}

.listClientsHeaderModalAbcAbrir{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem; 
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
  min-height: 2.1rem;
}

.listClientsBodyModalAbc{
  /* background-color: #00801C; */
  max-height: 29rem;
  overflow-y: auto;
}

.listClientsBodyModalAbcAbrir{
  /* background-color: #00801C; */
  /* max-height: 29.5rem; */
  overflow-y: auto;
}

.menuCategoryListModalAbc{
  max-height: 28rem;
  overflow-y: auto; 
}

.listClientsItemInativeAbc{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemActiveAbc{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemInativeAbc:hover{
  background: #e9e9e9;
}

.listClientsItemInativeAbcAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemActiveAbcAbrir{
  background: #c49e17;
  border-radius: 2px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemInativeAbcAbrir:hover{
  background: #e9e9e9;
}

.categoryDetailsModalAbc{
  margin: 1.5rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  display:grid;
  grid-template-rows: auto 8fr 6rem;
  max-height: 33rem;
}


.clientSpeechAbc{
  background-color:#D9D9D9;
  height: calc(100% - 2rem);
  max-height: 25rem;
  width: calc(100% - 4rem);
  margin: 0 0 0 1rem;
  padding: 1rem;
  border-radius: 4px;
  overflow-y: auto;
}

.clientSpeechHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 3rem;
  margin-left: 1.4rem;
  margin-right: 1rem;
  padding: 0.5rem;
  font-weight: 450;
  max-width: 26rem;
  overflow-x: hidden;
  align-items: center;
}

.productLinkAbc{
  font-size: 1.2rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: auto 4rem;
  font-weight: 400;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 6rem;
  overflow-y: auto;


}

.textAreaSpeechAbc{
  background-color: #e2e2e2;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  font-size: 1rem;
  resize: none;
  padding: 1rem;
  border: none;
}

.textAreaSpeechAbc:focus{
  outline: none;
  border: none;
}

.listOnlyAbc {
  height: 1.6rem;
  width: 8rem;
  font-weight: 400;
  font-size: 1rem;
}

.timelineTitleAbc{
  margin-top: 1rem;
  height: 5rem;
  font-size: 1.1rem;
  font-weight: 500;
  /* display: grid;
  grid-template-columns:3fr 3fr;  */
  padding-bottom: 0.3rem;
  align-items: center;
}

.timelineTitleAbcAbrir{
  margin-top: 1rem;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: 500;
  display: grid;
  grid-template-columns:3fr 3fr; 
  padding-bottom: 0.3rem;
  align-items: center;
}

.containerTimelineAbc{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  width: calc(100% - 4rem);
  margin: auto;
  display: grid;
  grid-template-rows: 4rem auto 10rem;
}

.containerTimelineAbcDetails{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  /* width: calc(100% - 4rem); */
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 10rem;
}

.timelineCommentBoxAbc{
  height: 100%;
  overflow: auto;
  border-radius: 2px;
  background-color: #f8f8f8;
  border: 1px solid #dadada;
  display: grid;
}

.refusalReasonBox{
  background-color: white;
  border: 2px solid #c49e17;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #c49e17;
  width: 15rem;
  height: 21.75rem;
  display: block;
  position: absolute;
  z-index: 10;
  top: 8rem;
  padding: 1rem;
  margin-left: 0.2rem;
  /* margin-left: -5rem; */
  /* margin: 0 auto; */
}

.refuseReasontitleAbc{
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 0.25rem;
  
}

.reasonsRefusalList{
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.refuseReasonHeaderAbc{
  display: grid;
  grid-template-columns: 2rem auto 2rem;
  vertical-align: middle;
}

.InputToFormat_two{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.InputToFormat_two div{
  position: relative;
  flex-grow: 1;
  /* margin: 1rem; */
}

.InputToFormat_two div input{
  border: 0;
  border: 1px solid lightgrey;
  height: 1.9rem;
  border: 1px solid var(--formBorder);
  border-radius: 0.3rem !important;
  outline: none;
  min-width: 15rem;
  /* width: 100%; */
  font-size: 16px;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -webkit-appearance:none;
  border-radius: 0;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.InputToFormat_two div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}

.InputToFormat_two div input::-webkit-input-placeholder{
  color:transparent;
}

.InputToFormat_two div input::placeholder{
  color:transparent;
}

.InputToFormat_two div label{
  color: #7F777F;
  font-size: 0.9rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  color: #8f7f7f;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  font-weight: 400;
}

.InputToFormat_two div input:required:invalid + label:before{
  content: '*';
}
.InputToFormat_two div input:focus + label,
.InputToFormat_two div input:not(:placeholder-shown) + label{
  font-size: 11px;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  color: #c49e17;
  background-color: white;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.carousel-container {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
}

.carousel-menu {
  overflow: hidden;
  white-space: nowrap;
}

#menu-items {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

#menu-items li {
  display: inline-block;
  /* padding: 10px; */
}

.carousel-button {
  border: none;
  color: #5f5f5f;
  background-color: transparent;
}

.carousel-button:hover {
  color: #c49e17;
}

.buttonExcludeBg{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  border: 1px solid red;
  font-size: 1rem;
  font-weight: 400;
  height: 1.6rem;
  width: 8rem;
  transition: 0.1s;
  background-color: white;
  color: red;
  margin: auto;
}

.buttonExcludeBg:hover {
  background: var(--buttonHover);
  color: white;
  border-color: #c49e17;
}
.optionsSelectComment{
  padding: 10px;
}
.noWrapAbc{
    white-space: nowrap;
    padding: 0 0.5rem 0 0.5rem !important;
    align-items: center;
    display: table-cell;
    text-align: center;
    font-size: 0.8rem;
}

.noWrapAbcTeam{
    white-space: nowrap;
    align-items: center;
    display: table-cell;
    text-align: center;
    background-color: rgb(255, 243, 217);
    padding: 0px !important;
}

.expandableIconDashAbc{
    white-space: nowrap;
    align-items: center;
    display: table-cell;
    text-align: center;
    background-color: white;
    padding: 0px !important;
}

.iconToExpandleRowsAbc{
    background-color: white;
    border: none;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.8rem;
}

.iconToExpandleRowsAbc:hover{
    background-color: #eeeeee
}

.expandableRowDashAbc{
    background-color: rgb(255, 243, 217);
}

.expandablesRowsDashAbc:hover{
    background-color: #eeeeee
}

.containerLongShort{
    height: calc(100% - 4rem);
  }

  .longShortCardInfos{
    margin-left: 2rem;
    margin-right: 2rem;
    min-height: 10rem;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    font-size: 1.1rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .editReportLongShort{
    min-height: 10rem;
    font-size: 1.1rem;
    display: grid;
    grid-template-rows: 2rem auto 2rem;
    text-align: center;
  }

  .itemlongShortCardInfos{
    display: grid;
    grid-template-columns: 20rem auto;
  }
  
  .buttonChangeReport{
    margin-left: auto;
    margin-right: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .buttonChangeReportCancel{
    margin-left: auto;
    margin-right: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: #fff;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #e9655b;
    border: 1px solid #e9655b;
  }

  .buttonChangeReportCancel:hover{
    background-color: #ee7971;
    border: 1px solid #ee7971;
    box-shadow: 0px 0px 10px #ee7971;
  }
  

  .buttonChangeReport:hover{
    background-color: #c7a836;
    border: 1px solid #c7a836;
    box-shadow: 0px 0px 10px #c7a836;
  }

  .productCardLongShort{
    background-color: #ebebe9;
    min-height: 6rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;
    margin: 0.5rem;
    margin-right: 1rem;
    border-radius: 5px;
    padding: 0.5rem;
  }

  .radioSelectContainerColumnLineLongShort{
    grid-gap: 0.4rem;
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}

.radioSelectContainerColumnLineLongShort div{
    grid-gap: 0.3rem;
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

.editReportLongShortBody{
  display: grid;
  grid-template-columns: 10rem 10rem 20rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.InputToFormat{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.InputToFormat div{
  position: relative;
  flex-grow: 1;
  /* margin: 1rem; */
}

.InputToFormat div input{
  border: 0;
  border: 1px solid lightgrey;
  height: 2.2rem;
  border: 1px solid var(--formBorder);
  border-radius: 0.3rem !important;
  outline: none;
  min-width: 7rem;
  /* width: 100%; */
  font-size: 16px;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -webkit-appearance:none;
  border-radius: 0;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.InputToFormatLS div input:focus{
  box-shadow: inset 0 0 0 1px var(--formBorder);
}

.InputToFormatLS div input::-webkit-input-placeholder{
  color:transparent;
}

.InputToFormatLS div input::placeholder{
  color:transparent;
}

.InputToFormatLS div label{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.6rem;
  margin-left: 0.3rem;
  color: #8f7f7f;
  font-size: 1rem;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  font-weight: 400;
}

.InputToFormatLS div input:required:invalid + label:before{
  content: '*';
}
.InputToFormatLS div input:focus + label,
.InputToFormatLS div input:not(:placeholder-shown) + label{
  font-size: 11px;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  color: #3c7adb;
  background-color: white;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.fileInputAdjustLS input[type='file'] {
  display: none !important;
  height: 2.2rem;
}

/* Aparência que terá o seletor de arquivo */
.fileInputAdjustLS label {
  background-color: #3498db;
  border: 1px solid #3498db;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 5px;
  font-size: 1rem;
  padding: 6px 20px;
  font-weight: 400;
  transition: .2s;
}

/* Aparência que terá o seletor de arquivo */
.fileInputAdjustLS label:hover {
  background-color: #fff;
  height: 2.2rem;
  border-radius: 5px;
  color: #3498db;
  cursor: pointer;
  margin: 5px;
  padding: 6px 20px
}

.inputLinkReport{
  height: 1.8rem;
  width: 11rem;
  border: 1px solid #3498db;
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.buttonEditReportBox{
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  margin-left: auto;
  margin-right: auto;
}

.link-like {
  cursor: pointer;
  /* text-decoration: underline; */
  color: rgb(34, 34, 233);
}

.buttonActivateLongShort{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.buttonActivateLongShort:hover{
  background-color: #c7a836;
  border: 1px solid #c7a836;
  box-shadow: 0px 0px 10px #c7a836;
}

.buttonDeactivateLongShort{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  border: 1px solid #e9655b;
  color: #fff;
  background-color: #e9655b;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.buttonDeactivateLongShort:hover{
  background-color: #ee7971;
  border: 1px solid #ee7971;
  box-shadow: 0px 0px 10px #ee7971;
}

.buttonEditLongShort{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  border: 1px solid #3eb992;
  color: #fff;
  background-color: #3eb992;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.buttonEditLongShort:hover{
  background-color: #4fbc99;
  border: 1px solid #4fbc99;
  box-shadow: 0px 0px 10px #4fbc99;
}

.listOperationLongShort{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  height: 80vh;
  overflow-x: auto;
}

.containerListLongShort{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

.containerLongShorts{
  background-color: white;
}

.containerGeralLongShorts{
  width: 70vw;
  margin: auto;
  height: 95vh;
}

.boxTablesAndButtons{
  display: grid;
  grid-template-columns: auto 20rem;
}

.BoxButtonsLongShort{
  margin: auto;
}
#recommendationsImportConfirm {
    font-size: 1.2rem;
    font-weight: 400;
}

.columnExpandableLs{
    text-align: center;
    display: grid !important;
    grid-template-columns: auto 1rem;
    height: 2.2rem;
    white-space: nowrap;
}

.expandleLsIcon {
    padding-top: 0.2rem;
}

.expandedColumnLs{
    border-top: 1px solid rgb(192, 192, 192);
    text-align: center;
    white-space: nowrap;
    /* font-weight: 450; 
    font-size: 0.8rem; */
}

.expandedColumnLsLeft{
    position: relative;
    border-top: 1px solid rgb(192, 192, 192);
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnLsLeft::before {
    content: "";
    position: absolute;
    left: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    height: 40%;
    border-left: 1px solid rgb(192, 192, 192); /* Altere a cor e espessura da borda conforme necessário */
}

.expandedColumnLsRight{
    position: relative;
    border-top: 1px solid rgb(192, 192, 192);
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnLsRight::before {
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    height: 40%;
    border-right: 1px solid rgb(192, 192, 192); /* Altere a cor e espessura da borda conforme necessário */
  }

.buttonEditCostLs{
    background-color: transparent;
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.buttonEditCostLs:hover{
    background-color: #c4f5bd;
    border-radius: 5px;
}

  .btnValidationOk {
    width: 5rem;
    height: 1rem;
    margin: auto;
    padding: 0.3rem;
    color: #141414;
    /* border-radius: 2px; */
    font-size: 1rem;
    font-weight: 500;
    /* transition: all ease 0.1s; */
    /* box-shadow: 0px 0px 2px #c0b091; */
}


.btnValidationValidate {
    width: 5rem;
    height: 1rem;
    margin: auto;
    padding: 0.3rem;
    background-color: rgb(253, 253, 122);
    color: #141414;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 500;
    transition: all ease 0.1s;
    box-shadow: 0px 0px 2px #c0b091;
}

.btnValidationValidate:hover {
    background-color: #141414;
    color: rgb(253, 253, 122);
    cursor: pointer;
}

.btnValidationValidated {
    width: 5rem;
    height: 1rem;
    margin: auto;
    padding: 0.3rem;
    background-color: #5bd068;
    color: #141414;
    border-radius: 2px;
    font-size: 1rem;
    font-weight: 500;
    transition: all ease 0.1s;
    box-shadow: 0px 0px 2px #c0b091;
}

.btnValidationValidated:hover {
    background-color: #141414;
    color: #5bd068;
    cursor: pointer;
}

.productCardLSHeader{ 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
  }

.containerIconCopyContentLsCio{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 50%;
    transition: all ease 0.1s;
    margin-left: auto;
  }
  
.containerIconCopyContentLsCio:hover{
    color: #464444;
    cursor: pointer;
}

.productCardLsInfos{
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr 2rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    min-height: 4rem;
    text-align: center;
}

.toolbarCioLs{
    margin-top: 1rem;
    margin-left: auto;
    margin-right: 2rem;
    height: 2.5rem;
  }

  .buttonOperateSubmitCioLs{
    height: 1.6rem;
    width: 7rem;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
  }

  .buttonOperateSubmitCioLs:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
  }

  .tblLs td{
    border: 1px solid black;
    text-align: center;
  }

  .tblLs th{
    border: 1px solid black;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
  
  .containerOperateProductLs{
    border: 1px solid var(--headerAllBg);
    border-radius: 5px;
    margin: 1rem;
    display: grid;
    grid-template-rows: 6rem auto 20rem;
    /* max-width: 36rem; */
  }
  
.boxAlertLSOperation{
    margin-top: 1rem;
    text-align: center;
}

.divAlertsLongShorts{
    margin-top: 1rem;
}

.boxJustifyBrokerLs{
    height: 15rem;
    width: 80%;
    margin-top: 2rem;
}

.boxJustifyBrokerLsText{
    margin: 1rem;
    background-color: rgb(226, 226, 226);
    padding: 1rem;
    min-height: 5rem;
    max-height: 10rem;
    overflow: auto;
    border-radius: 5px;
}

.fieldsOperateLsOrder{
    margin-top: 3rem;
    margin-left: auto ;
    margin-right: auto ;
}

.buttonOperateLs{
    width: 10rem;
    padding: 0.2rem;
    font-size: 1.2rem;
    font-weight: 400;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonOperateLs:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}
.mainLongShortInfos {
    width: 60rem;
    height: 50rem;
    background: white;
}

.buttonEditCostLs2{
    background-color: transparent;
    border: none;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 5px;
}

.buttonEditCostLs2:hover{
    background-color: #c4f5bd;
    border-radius: 5px;
}

.titleModalLongShortInfos{
    background: var(--headerAllBg);
    height: 6rem;
    align-items: center;
    text-align: center;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60rem;
  }

.modalCloseButtonDashboard {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid rgb(212, 172, 0);
    background-color: rgba(255, 255, 255, 0);
    font-size: 40px;
    border-radius: 50%;
    transition: 0.1s;
    color: #D4AC00
}

.modalCloseButtonDashboard:hover {
    background: var(--buttonHover);
    color: white;
}

.longShortInfoAcompanhamento {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-top: 2rem;
}

.longShortTable1 {
    margin-top: 2rem;
}

.longShortTable2 th{
    padding: 5px;
}

.longShortInfoParameters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
}

th {
    font-weight: 300;
}

.longShortTable2Th1 {
    text-align: right;
    font-weight: 600;
    padding: 3px;
}

.longShortTable2Th2 {
    text-align: left;
    padding: 3px;
}

.btnLongShortInfosModal.AiFillMessage {
    width: 3rem;
    height: 3rem;
    margin: auto;
    padding: 0.3rem;
    background-color: #fffdf9;
    color: #141414;
    border-radius: 2px;
    font-size: 1rem;
    transition: all ease 0.3s;
    box-shadow: none;
}

.btnLongShortInfosModal:hover {
    color: #C49E17;
    cursor: pointer;
  }

.longShortParamsOperations{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}
.mainLongShortHistorico {
    width: 20rem;
    height: 25rem;
    background-color: white;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
}

.mainLongShortHistoricoTitle{
    padding: 1rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
}

.mainLongShortHistoricoBody{
    padding: 1rem;
    margin-top: 1rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
}

.mainLongShortHistoricoSubmit{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataHistoricoLS{
    margin-top: 1rem;
}

.cubastarFundsTableAndFooterContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.cubastarFundsInformationFiltered {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

.cubastarFundsOrdersLoadingPeriods {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
}
.cubastar-table{
  border-radius: 10px;
}
.cubastarFundsTableContainer {
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  width: 95%;
  overflow-x: auto;
  overflow-y: scroll;
  margin-top: 1rem;
  border-radius: 0.5rem 0 0 0;
  box-shadow: -2px 2px 20px -7px #000000;
}

.cubastarFundsTableContainer tbody td {
  font-size: 1rem;
  cursor: default !important;
}

.cubastarFundsTableContainer tbody td:nth-child(2) {
  color: rgb(116, 116, 116);
  font-size: 1rem;
  width: 2rem;
}

.cubastarFundsTableContainer tbody td:nth-child(2):hover {
  font-size: 1.1rem;
}

.cubastarFundsOrdersSelectionFilter {
  background: var(--tableSecondTh);
  color: white;
  font-size: 0.9rem;
  max-width: 8rem;
}

#cubastarFundsLoadingTd {
  height: 10rem;
}

.reservationOrdersTable {
  border-collapse: collapse;
  max-height: 100%;
}

.reservationOrdersTable thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.reservationOrdersTable thead tr th {
  -webkit-animation: scroll 0.5s ease-out;
          animation: scroll 0.5s ease-out;
  transition: 0.2s;
}

.cubastarFundsOrdersTheadDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cubastarFundsOrdersTheadDiv p {
  color: white;
  margin-left: 1rem;
}

.cubastarFundsOrdersTheadRightGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reservationOrdersTable thead tr:first-child th {
  background: var(--topBarBg);
  height: 5rem;
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
  border-radius: 0.5rem 0 0 0;
  top: 0px;
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
  transition: 0.2s;
  z-index: 10;
  -webkit-animation: scroll 0.5s ease-out;
          animation: scroll 0.5s ease-out;
}

#cubastarFundsOrdersMultipleActions {
  height: 1.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--goldColor);
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;
}

#cubastarFundsOrdersMultipleActions:hover {
  background: var(--goldColor);
  color: black;
}

.cubastarFundsOrdersMultipleActionsDiv {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 1.1rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: var(--goldColor);
}

.cubastarFundsOrdersMultipleActionsDiv p {
  color: var(--goldColor);
  margin-left: 0;
}

#cubastarFundsOrdersMultipleActions div {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

#cubastarFundsOrdersTheadDateSelection {
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.cubastarFundsOrdersSelectionAll {
  display: flex;
  flex-direction: row;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes scroll {
  from {
    -webkit-transform: tranlateY(-100%);
            transform: tranlateY(-100%);
  }

  to {
    -webkit-transform: tranlateY(0%);
            transform: tranlateY(0%);
  }
}

@keyframes scroll {
  from {
    -webkit-transform: tranlateY(-100%);
            transform: tranlateY(-100%);
  }

  to {
    -webkit-transform: tranlateY(0%);
            transform: tranlateY(0%);
  }
}

.reservationOrdersTable thead tr:nth-child(2) th {
  background: #3E3E3E;
  height: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
  margin-top: 1rem;
  top: 5rem;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  z-index: 10;
  transition: 0.9s;
  -webkit-animation: scroll 0.5s ease-out;
          animation: scroll 0.5s ease-out;
}

.reservationOrdersTable tbody {
  overflow: auto;
}

.reservationOrdersTable td:nth-child(1) {
  width: 3rem !important;
}

.reservationOrdersTable td {
  box-sizing: border-box;
  border: 1px solid var(--tableBorder);
  text-align: center;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  text-overflow: ellipsis;
  cursor: pointer !important;
  overflow: hidden;
  white-space: nowrap;
  max-width: 8rem;
  height: 2rem;
  font-size: 1rem;
}

.reservationOrdersTable td:nth-child(2) {
  max-width: 20rem !important;
}

.reservationOrdersTable tr {
  transition: 0.1s;
}

.reservationOrdersTable tr {
  background: #FFF;
}

.reservationOrdersTable tr:hover {
  background: var(--tableHover) !important;
}

.reservationOrdersTable tr:nth-child(even) {
  background: var(--tableBorderEven);
}

.cubastarFundsOrderDetails label {
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.cubastarFundsOrderDetails label a:first-child {
  font-weight: 400;
}

#cubastarFundsOrderDetailsTable {
  margin-top: 1.5rem;
}

#cubastarFundsOrderDetailsRow {
  transition: all ease 0.2s;
}

.cubastarFundsOrderDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  font-size: 1.2rem;
  align-items: center;
  background: #3e3e3e;
  height: 3rem;
  transition: 0.2s;
}

.cubastarFundsOrderDetails a {
  color: white;
}

.cubastarFundsOrderDetails:hover {
  background: #444444;
}


@media (max-width: 1366px) {
  .cubastarFundsOrdersSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.8rem;
    max-width: 6rem;
  }

  .cubastarFundsTableContainer tbody td {
    font-size: 0.9rem;
    cursor: default !important;
  }
}

::-webkit-scrollbar-corner {
  background: rgb(35, 37, 7);
}

.cubastar-table-label {
  text-align: center;
  background-color: rgb(9 3 59);
  color: #c49e17;
  font-weight: 600px;
  padding: 0.3rem;
  border-radius: 10px 10px 0px 0px;
  cursor: initial !important;
}
.boxCSGeral {
    /* display: flex;
  flex-direction: column; */
    color: rgb(9 3 59);
    background: #eaeff4;
    height: 90vh;
    overflow-x: auto;
    margin: -2rem;
    padding: 2rem;
    box-shadow: none;
}

.boxCenarioCS {
  height: 0px;  
  transition: height 0.3s ease;
  background: #FFFFFF;
  padding-left: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;

  /* border: 1px solid #F8F9FA; */
  border-radius: 30px;
  /* box-shadow: -5px 5px 5px 1px  #D0D5D8; */

  
  align-items: center;
  
}
.buttonGraphsCubastar:hover{
  border: none;
  font-weight: bold;
  color: #c49e17;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: 0.1s ease-in-out;
}

.saveButtonClass{
  background-color: #c49e17;
  border: none;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 10rem;
  display: grid;

  transition: 0.2s ease-in-out;
}
.saveButtonClass:hover{
  background-color: #d4ac00;
}
.cancelButtonClass{
  background-color: #bbbbbb;
  border: none;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 10rem;
  display: grid;

  transition: 0.2s ease-in-out;

}
.cancelButtonClass:hover{
  background-color: #d4ac00;
}
.editButtonClass{
  background-color: #c49e17;
  border: none;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 10rem;
  display: grid;
}
.editButtonClass:hover{
  background-color: #d4ac00;
}

.textareaCubastar{
  resize: none;
  /* width: 96%; */
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;

  background-color: #f6f6f6;
  padding: 0.5rem;
  border: #494946 1px solid;
  border-radius: 10px;
}

.transition {
  transition: height 0.2s ease;
}

.boxCenarioCSExpand {
  /* height: 300px; */
  transition: height 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;
}

.boxTaticasCS {
  height: 0PX;
  transition: height 0.3s ease;
  background: #FFFFFF;
  /* padding-left: 1rem; */
  margin-bottom: 1rem;
  overflow: hidden;

  border-radius: 30px;
 

    display: flex;
    align-items: center;
}

.boxTaticasCSExpand {
    height: 300px;
    transition: height 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.buttonExpandableDiv {
  background-color: transparent;
  border: none;
  outline: none;
  /* height: 1.5rem; */
  /* display: grid; */
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  transition: 0.2s ease-in-out;
}
.selectedMenu button {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.selectedMenu button {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: 10rem;
}
.boxTablesCS {
    display: grid;
    grid-template-rows: auto auto;
    background-color: white;
    border-radius: 30px;
    padding-top: 1rem;
}

.boxTablesCSHeader {
    position: relative;
    top: 10;
    z-index: 3;
    display: inline-flex;
    align-items: center;
    grid-template-columns: 2fr 3fr;
    margin-left: 1.5rem;
}

.cubastarTableGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.boxTablePrincipalMenu {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    font-size: 13.33px;
    height: 50px;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: calc(min(30vw, 15.5rem) - 1rem);

    display: flex;
    justify-content: center;
    align-items: center;
}
.TacticalTdStyleLeft {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    /* border-top-right-radius: 30px;
  border-bottom-right-radius: 30px; */
}
.TacticalTdStyleRight {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.teste * {
    margin-bottom: 1rem;
}
.boxTablesCSHeaderLeftNone {
    display: none;
}

.boxTablesCSHeaderLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0.5rem; */
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 99%;
    justify-content: space-between;
}

.boxTablesCSHeaderRight {
    /* position: absolute;
  z-index: 3; */
    display: flex;
    /* margin-left: 170px; */
    flex-direction: row;
    margin-top: 1rem;
    align-items: space-around;
    /* padding: 0.5rem; */
    grid-gap: 0.5rem;
    gap: 0.5rem;
    overflow-x: auto;
    min-width: 10rem;
}

.boxTablesCSBody {
    display: grid;
    /* grid-template-columns: auto 1fr; */
    min-height: 90vh;
    margin-top: 0.5rem;
}

.bodyLeftCSMenu {
    /*   position: absolute;
 */
    display: flex;
    flex-direction: row;
    z-index: 3;
    /* padding-left: 0.5rem; */
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
    /* margin-top: 0.5rem; */
    grid-gap: 0.5rem;
    gap: 0.5rem;
    /* height: 42rem; */
    overflow-x: auto;
}
.bodyRightCSMenu {
    /* position: absolute;
  margin-left: 340px; */
    display: flex;
    flex-direction: row;
    z-index: 3;
    /* padding-left: 0.5rem; */
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
    /* margin-top: 0.5rem; */
    grid-gap: 0.5rem;
    gap: 0.5rem;
    /* height: 42rem; */
    overflow-x: auto;
}

.bodyCSContent {
    /* background-color: #c4c4c4; */
    margin: 0.5rem;
    margin-left: 0px;
    padding: 0.5rem;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.itemMenuCS {
    background-color: #c4c4c4;
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: rgb(9 3 59);
    min-width: 10rem;
    /* min-width: calc(min(30vw, 15.5rem) - 1rem); */
    /* overflow: hidden; */
}

.itemMenuSelectedCS {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: 10rem;
    /* min-width: calc(min(30vw, 15.5rem) - 1rem); */
}

.itemMenuCS2 {
    background-color: #c4c4c4;
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: rgb(9 3 59);
    overflow: hidden;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.itemMenuSelectedCS2 {
    background-color: rgb(9 3 59);
    border: none;
    font-weight: bold;
    padding: 1rem;
    border-radius: 10px;
    color: #c49e17;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.boxCenarioCSContent {
    /* background-color: #c49e17; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    justify-items: center;
}

.boxTaticasCSContent {
    margin-top: 1.5rem;
    width: 100%;
    /* overflow: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.contentCenarioCsItem {
    width: 95%;
    display: grid;
    grid-template-columns: 5fr 1fr;
    /* border-radius: calc(2.75rem + 2px); */
    border-radius: 10px;
    overflow: hidden;
    /*  border: 1px solid rgb(9 3 59); */

    background-color: #f0f1f1;
}

.cenarioItemCSTextHeader {
    font-weight: bold;
    padding-left: 2rem;
    padding-top: 0.5rem;

    color: #d7b32e;
}
.cenarioItemCSTextCopy {
    background-color: #fffef3;

    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #ebd580;
    padding: 0.5rem;
    grid-gap: 0.25rem;
    gap: 0.25rem;
}

.cenarioItemCSText {
    padding: 1rem 0rem 1rem 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    /* display: flex; */
    flex-wrap: nowrap;
    padding-left: 2rem;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    color: #494946;
    max-height: 15rem;
    overflow: auto;
}

.cenarioItemCSAvatar {
  min-height: 6rem;
  align-items: center;
  /* display: grid; */
  /* grid-template-rows: 4fr 1fr 1fr; */
  /* align-items: center; */
  flex-direction: column;
  font-weight: bold;
  margin-right: 0.5rem;
  /* align-content: center; */
  margin-top: 0.5rem;
  justify-content: center;

}
.tacticalTdStyleAl *l {
    margin-bottom: 1rem;
}

.csAvatar {
  background-color: #c4c4c4;
  border-radius: 10px;
  height: 6.25rem;
  width: 6.25rem;
  margin-right: 10px;
  /* margin-top: 0.5rem; */
  overflow: hidden; 
  float: right;
}
.csName {
  float: right;
    color: #dcc04f;
    font-size: 1rem;
  width: 110px;
  min-height: 2.5rem;
  padding:'0.5rem 0rem';
  display: flex;
  align-items: center;
  /* margin-top: -20px; */
}

.cubastarTacticalContent {
    background: white;
    width: 80vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.cubastarTacticalDivGrid {
    display: grid;
    grid-template-columns: 49.5% 1% 49.5%;
    height: 26rem;
}

.cubastarTacticalContainer {
    margin: 10px;
    border-radius: 10px;
    background-color: #eff0f1;
    /* padding: 5px; */
}

.cubastarTacticalInternalUse {
    text-align: center;
    font-size: 20px;
    background-color: #bbbbbb;
    font-weight: bold;
    border-radius: 10px;
    padding: 1rem;
    align-items: center;
}

.bodyCSContentCarteiras {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 100%;
}

.bodyCSContentCarteirasTabelas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 99%;
}

.bodyCSContentCarteirasAlocacao {
    /* background-color: aquamarine; */
    /*border: 1px solid rgb(9 3 59);*/
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyCSContentCarteirasExemplo {
    /* background-color: bisque; */
    /*border: 1px solid rgb(9 3 59);*/
    padding: 0.5rem;
}

.bodyCSContentCarteirasImagens {
    /* background-color: cadetblue; */
    border: 1px solid rgb(9 3 59);
    padding: 0.5rem;
}

.bodyCSContentFundos {
    display: grid;
    /* grid-template-rows: 20rem 21rem; */
    height: 100%;
}

.bodyCSContentFundosGrafico {
    /* border: 1px solid rgb(9 3 59); */
    padding-right: 0.5rem;
    background-color: white;
    padding: 0.5rem;
    display: grid;
}

.vertical-line {
    width: 1px; /* Largura da linha vertical */
    background-color: #000000; /* Cor da linha vertical */
    height: 100%; /* Define a altura da linha vertical para que ela ocupe todo o espaço vertical entre as tabelas */
    margin: 0 10px; /* Espaçamento horizontal entre as tabelas e a linha vertical */
}

.cubastar-rentability-wrapper {
    display: flex;
    justify-content: space-around;
    grid-gap: 1rem;
    gap: 1rem;
}

.bodyCSContentCarteirasGraph {
    /* width: 100%; */
    padding: 0.5rem;
}
.bodyCSContentRentabilityTabelas {
    width: 100%;
    padding: 0.5rem 0px 0px 9px;
}

@media (max-width: 1920px) {
    .boxTablesCSBody {
        display: flex;
        flex-direction: column;
    }

    .bodyCSContentCarteirasExemplo {
        overflow-x: auto;
    }
    .bodyCSContentCarteirasTabelas {
        width: 100%;
        display: flex;
    }
}

@media only screen and (max-width: 1600px) {
    .cubastar-rentability-wrapper {
        display: grid;
        justify-content: space-around;
    }
}
/* @media (max-width: 1447px) {
  .bodyCSContent {

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    overflow-x: hidden;
  }

  .bodyCSContent .bodyCSContentCarteirasAlocacao,
  .bodyCSContentCarteirasExemplo {
    margin-top: 10px;
    overflow-x: hidden;
  }
  .bodyCSContentCarteirasTabelas {
    display: flex;
    flex-direction: column;      
    width: 100%;

    display:flex ;


  }

  .bodyCSContentCarteirasExemplo {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }

  .bodyCSContentCarteirasGraph {
    width: 100%;
  }
} */

.buttonCopyAllCS {
    background-color: #bbbbbb;
    border: none;
    font-weight: bold;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 10rem;
    display: grid;
    grid-template-columns: auto 2rem;
}

.buttonCopyAllCS:hover {
    background-color: #d4ac00;
}

.menuDivParent{
  position: relative;
  display: inline-block;
  cursor: pointer;
/*   margin-left: 8px;
  margin-bottom: 12px; */
}

.menuDivCS{
  position: absolute;
  top: 0px;
  left: 0;
  /* display: none; */
  /* padding: 8px;
  border: 1px solid black; */
  border-radius: 1rem;
  background-color: rgba(212, 212, 212, 0.923);
  z-index: 3;
}

.menuDivParent:hover .menuDivCS {
  display: block;
}

.itemMenuNewCS{
  padding: 1rem;
  color: #d4ac00;
  font-weight: 500;
  background-color: #f5f5f559;
  white-space: nowrap;
}

.itemMenuNewCSInative{
  color: black;
  padding: 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.itemMenuNewCSInative:hover{
  background-color: #77777759;
  color: black;
}

.itemMenuNewCS2{
  padding: 0.7rem;
  color: #d4ac00;
  font-weight: 500;
  background-color: #f5f5f559;
  white-space: nowrap;
}

.itemMenuNewCSInative2{
  color: black;
  padding: 0.7rem;
  font-weight: 500;
  white-space: nowrap;
}

.itemMenuNewCSInative2:hover{
  background-color: #77777759;
  color: black;
}

.menuCSRight2{
  /* border-left: 3px solid black; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* borda tem só 40% de altura */
.menuCSRight2::before{
  content: '';
  position: absolute;
  width: 3px;
  height: 60%;
  background-color: #d4ac00;
}
.cenariosAlocacaoTable thead tr th {
    background-color: #f5f5f5;
    color: #D4AC00;;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    height: 2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    /* border: 1px solid #c0c0c0; */
}

.cenariosAlocacaoTable{
    border-collapse: separate; /* Separa as bordas das células */
    border-spacing: 0 10px; 
    padding-left: 1rem;
    padding-right: 1rem;
}



.cenariosAlocacaoTable tbody tr td:last-child{
    cursor: default;
    position: -webkit-sticky;
    position: sticky;
    border-right: none;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.cenariosAlocacaoTable tbody tr td:first-child{
    cursor: default;
    position: -webkit-sticky;
    position: sticky;
    border-left: none;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.cenariosAlocacaoTable thead {
    color: #D4AC00;
}


.cenariosAlocacaoTable tbody tr td {
    cursor: default;
    color: rgb(188, 188, 188);
    font-size: 14px;
    vertical-align: middle;
    /* border: 1px solid #ddd; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 5rem;
}

.cenariosAlocacaoTable td{
    border-right: 1px solid #D4AC00;
}

.cenariosAlocacaoTable td::before{
    content: '';
    position: absolute;
    top: 20%; /* Ajuste o valor para posicionar a borda corretamente em 60% da altura da célula */
    left: -1px; /* Ajuste o valor para alinhar a borda corretamente no início da célula */
    height: 60%; /* Define a altura da borda como 60% da altura da célula */
    width: 1px;
}

.cenariosAlocacaoTable tr{
    background-color: white;
}


.divRowCenarios{
    background-color: #ffffff;
    border-radius: 1rem;
}


.boxCenariosAlocacaoTable {
    overflow: auto;
    max-height: 34rem;
}

.buttonTableAlocacaoTrash {
    text-align: "center";
    
}

.buttonTableAlocacaoTrash svg {
    text-align: "center";
    color: '#B3B3B3'
}

.buttonTableAlocacaoTrash:hover {
    color: #ffffff;
    background-color: #b81313f8;
}

.buttonTableAlocacaoTrash:hover svg {
    color: #ffffff;
}

.buttonTableAlocacaoAdd {
    background-color: #D4AC00;
    color: #ffffff;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    
}

.buttonTableAlocacaoAdd:hover {
    background-color: #e4bf3a;
    color: #ffffff;
}

.buttonTableAlocacaoEdit {
    /* background-color: #ffffff; */
    
}

.buttonTableAlocacaoEdit svg {
    color: '#B3B3B3'
}

.buttonTableAlocacaoEdit:hover {
    background-color: #c49e17;
}

.buttonTableAlocacaoEdit:hover svg {
    color: #ffffff;
}

.custom-textarea-alocacao {
    resize: none;
    height: calc(1.5em * 4);
    max-height: calc(1.5em * 4); 
    overflow-y: auto; 
    border: 1px solid #c49e17; 
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 90%;
    font-size: 1rem;
    /* padding: 0.4rem; */
    border-radius: 5px;
}

.custom-textarea-alocacao:focus{
    border: none;
    outline: none;
    border: 2px solid #c49e17; 
}

.itemNewCenarioAlocacao{
    /* margin-top: auto; */
    margin-bottom: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    align-items: center;
    text-align: center;
}

.titileNewCenarioALocacao{
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}

.newCenarioAlocacao{
    display: grid;
    grid-template-columns: 3fr 6fr 2fr 3fr 1fr;
    grid-template-rows: 2rem auto;
}

.newSettlementBox{
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-template-rows: 2rem auto;
}

.boxNewCenario{
    display: grid;
    grid-template-columns: auto 15rem;
    /* border: 1px solid #ddd; */
    padding: 0.5rem;
    border-radius: 10px;
}

.boxAvatarAutorCenario{
    width: 4rem;
    height: 4rem;
    background-color: #c0c0c0;
    border-radius: 50%;
    margin: auto;
}

.titleModalTacticalAlocations{
    background: #dbdbdb;
    height: 6rem;
    align-items: center;
    text-align: center;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 60rem; */
  }


  .tacticalEditCheck {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.2rem solid white;
    border-radius: 10px;
    padding: 0.2rem 1rem 0.2rem 1rem;
    background-color: #D4AC00;
    font-size: 20px;
    transition: 0.1s;
    color: white;
    margin: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: -6px 0px 7px 3px rgba(0,0,0,0.2);
}

.tacticalEditCheck:hover {
    background: #c49e17;
    color: white;
}

.selectTacticalAlocationsCategory {
    /* width: 100%; */
    height: 2.5rem;
    border-radius: 10px;
    border: 2px solid #A0A0A0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: auto;
    font-size: 1rem;
    color: #A0A0A0;
}

.file-input-wrapper {
    position: relative;
  }
  
  .custom-file-input {
    display: inline-block;
    padding: 8px 12px;
    background-color: #D4AC00;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .customFileInputCenarios {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-name {
    margin-top: 10px;
    display: block; /* Para colocar o texto em uma linha abaixo */
  }

 /* Esconda o checkbox padrão */
.checkboxNewCenario {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #D4AC00; /* Define a borda vermelha para o checkbox */
    border-radius: 4px; /* Define um leve arredondamento nas bordas */
    outline: none; /* Remove o contorno padrão quando o checkbox é focado */
    cursor: pointer;
  }
  
  /* Estilize o checkbox quando estiver marcado */
  .checkboxNewCenario:checked {
    background-color:  #D4AC00/* Define a cor de fundo vermelha quando o checkbox estiver marcado */
  }
.fixedIncomeProductsContainer {
    background-color: white;
    padding-bottom: 1rem;
}

.fixedIncomeDataTextBoxes {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.fixedIncomeDataTextBoxesChild {
    margin: 1rem;

    border-radius: 10px;
    background-color: #f0f1f1;
}
.fixedIncomeDataTextBoxesChild h2 {
    color: #d5b01f;
}
.fixedIncomeProductsSeparator {
    margin: 2rem 50px;
}
.investimentcards {
    align-self: center;
    cursor: pointer;
    background-color: #bbbbbb;
    align-items: center;
    display: flex;
    padding: 10px;
    color: #fdfdfd;
    font-weight: bold;
    border-radius: 10px;
}
.investimentcards:hover {
    background-color: #d5b01f;
}

.investmentEditScenariosWindow {
    background: white;
    width: 90vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleModalInvestmentEditScenarios{
    background: var(--headerAllBg);
    height: 6rem;
    align-items: center;
    text-align: center;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
.fixedIncomeProductsAddInformation{
    background-color: #F5F5F5;
    margin: 20px;
    border-radius: 10px;
}
.investmentsEditBox:focus{
    border-color: #BFBFBF ;
}
.investmentsEditBox{   
    padding: 0.5rem;
    padding-left: 1rem;
}
.fixedIncomeProductsContainer .fixedIncomeProductSubContainer{
    background-color: white;
    display: flex;
    flex-direction: row;
}

.fixedIncomeDataTextBoxes{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: 1rem;
}
.fixedIncomeDataTextBoxesEditScenarios{
    
    padding: 20px;
    /* display: grid; */
    grid-template-columns: 100%;
    width: 100%;
    height: 65vh;
    border-radius: 10px;
    margin-top: 1rem;
    background-color: #F4F4F4;
    overflow: auto;
}

.fixedIncomeDataTextBoxesChild{
    margin: 1rem;
    /* height: 15vh; */
}

.fixedIncomeProductsSeparator{
    margin: 2rem 50px
}
.editScenariosHover{
    transition: color 0.2s ease;
    height: 100%;
}
.editScenariosHover:hover{
    color: #D4AC00;
    
}
.editBrancheBoxHeaderAbc{
  background: var(--headerAllBg);
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.editBrancheBoxHeaderAbc p {
  font-size: 1.4rem;
  border-bottom: 1px solid var(--goldColor);
  margin-left: 1rem;
}

.editBrancheBoxHeaderAbc button {
  margin-right: 1rem;
}

.containerModalAbcDetails{
  width: 90vw;
  margin: auto;
  height: 90vh;
  background-color: white;
}

.containerProductAdherence{
  width: 100%;
  height: 3rem;
  background-color: #5f5f5f;
}

.containerProductName{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

/* .containerProductName p {
  font-weight: 400;
} */

.containerClientMenuAbcActions{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 4fr 1px 4fr 1px 3fr 1px 4fr;
}

.containerClientMenuAbcActionsAbrir{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 5fr 1px 3fr 1px 6fr;
}

.containerBoletarAbc{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 3fr 1px 3fr;
}

.clientDetails {
  color: black
}

.referenceDetails {
  color: blue
}

.containerClientMenuOptionsAbcActions{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
}

.containerListproductsAbc{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

.toolbarBoletarAbc{
  /* border: 1px solid var(--headerAllBg); */
  /* border-radius: 5px; */
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 2.5rem;
}


.divisionHeaderCardAbc{
  background-color: #B19942;
  width: 96%;
  height: 2px;
  margin-left: 2%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}
.listOperationProductsAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  height: 36.5rem;
  overflow-x: auto;
}

.productCardAbcInfos{
  margin-left: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.offerCardAbcInfos{
  margin-left: 3rem;
  margin-right: 3rem;
  /* display: grid;
  grid-template-columns: auto auto; */
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.offerItemAbc{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 15rem 1fr;
}

.productCardAbc{
  background-color: #ebebe9;
  min-height: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardActiveAbc{
  background: #ebebe9;
  border: 1px solid #c49e17;
  box-shadow: 0 0 6px 4px #c49e17;
  min-height: 6rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardAbc:hover{
  box-shadow: 0 0px 10px #c49e17;
  cursor: pointer;
}

.cardAbcHeader{
  font-weight: 400;
}

.productCardAbcHeader{ 
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 1fr 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  
}

.containerNameAbcActions{
  display: grid;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 1.2rem;
}

.containerOperateProductAbc{
  border: 1px solid var(--headerAllBg);
  border-radius: 5px;
  margin: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 6rem;
  /* max-width: 36rem; */
}

.headerOperateProductAbc{
  max-width: 100%;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 400;
  color: #5E4C0B;
}

.bodyOperateProductAbc{
  border: 1px solid #ffffff;
  color: #5E4C0B;
  font-size: 1rem;
  font-weight: 400;
}

.contentBodyOperateProductAbc{
  margin-top: 6rem;
}

.contentBodyOperateOfferAbc{
  margin-top: auto;
  margin-bottom: auto;
}

.boxSubmitOperateProductAbc{
  max-width: 100%;
  margin: auto;
}

.buttonOperateSubmitABC{
  width: 10rem;
  padding: 0.2rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
}

.switchSelector{
  margin:auto;
  text-align: center;
  border-radius: 1.4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  border: 1px solid #c49e17;
}

.buttonSwitchSelected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #fff;
  background-color: #c49e17;
  transition: all ease-in-out 0.1s;
}

.buttonSwitchUnselected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #c49e17;
  background-color: transparent;
  transition: all ease-in-out 0.1s;
}

.toolbarSelectOrderBoletarAbc{
  margin-left: auto;
  margin-right: 1.5rem;
}


.itemBodyOperateProductAbc{
  margin-top: 1rem;
}

.itemBodyOperateOfferAbc{
  margin-top: 0rem;
}

.fieldsOperateAbc{
  margin-top: 3rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.fieldsOperateOfferAbc{
  margin-top: 1rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.buttonOperateSubmitABC:hover{
  background-color: #a88812;
  border: 1px solid #a88812;
  box-shadow: 0px 0px 10px #ffc800;
}

.clientInfoAbcActions{
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0.8rem;
  font-weight: 400;
}

.productInfoAbcActions{
  font-size: 1rem;
  text-align: center;
}

.divisionNameProductSubcategory{
  width: 100%;
  height: 96%;
  background-color: #c49e17;
  margin: auto;
}

.containerGeneralOptionsAbcActions{
  width: 100%;
  height: 98%;
  display: grid;
  position: relative;
}

.containerOptionsTitleAbcActions{
  z-index: 1;
  background-color: white;
  text-align: center;
  margin-bottom: -20px;
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.optionsTitleAbcActions{
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: white;
}

.containerOptionsAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.containerGeneralChangeStatusAbcActions{
  width: 100%;
  height: 12.5rem;
  display: grid;
  position: relative;
}



.containerChangeStatusTitleAbcActions{
  position: absolute;
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.ChangeStatusTitleAbcActions{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  background-color: white;
  margin: auto;
  font-size: 1.2rem;
}

.containerChangeStatusAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.buttonAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #c49e17;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActions:hover{
  background-color: #e6e2d7;
  color: #c49e17;
  border-left: 1px solid #c49e17;
  border-right: 1px solid #c49e17;
}

.buttonAbcActionsSolicit{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: rgb(241, 170, 63);
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActionsSolicit:hover{
  background-color: #e6e2d7;
  color: rgb(241, 170, 63);
  border-left: 1px solid rgb(241, 170, 63);
  border-right: 1px solid rgb(241, 170, 63);
}

.buttonAbordadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #3177b8;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbordadoAbcActions:hover{
  background-color: #d3dee7;
  color: #3177b8;
  border-left: 1px solid #3177b8;
  border-right: 1px solid #3177b8;
}

.buttonRecusadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #d64747;
  color: white;
  transition: all ease 0.1s;
}
.buttonRecusadoAbcActions:hover{
  background-color: #e6d6d6;
  color: #d64747;
  border-left: 1px solid #d64747;
  border-right: 1px solid #d64747;
}

.buttonNaoAderenteAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #696969;
  color: white;
  transition: all ease 0.1s;
}
.buttonNaoAderenteAbcActions:hover{
  background-color: #e6e6e6;
  color: #2b2b2b;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

.containerClientMenuSubcategoryAbcActions{
  margin: 1.5rem 1rem 1.5rem 1rem;
  border: 1px solid #e2e2e2;
  /* background: #fbfbfb; */
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.headerSubcategoryAbcActions{
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3.5rem;
  grid-template-columns: 1fr auto;
}

.headerSubcategoryTitleAbcActions{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 1rem;
  padding-top: 0.8rem;
  overflow-y: auto;
}

.containerIconCopyContentAbcActions{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* padding-left: 0.7rem;
  padding-right: 0.7rem; */
  margin: 0.4rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcActions:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.containerIconCopyContentAbcDetails{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcDetails:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.linkMenuModalBoxAbc{
  height: 1rem;
}

.linkMenuModalBoxDescriptionAbc{
  height: 1rem;
}

.searchIconModalAbc{
  border: none;
  background-color: transparent;
}

.containerSubcategoryDescriptionAbcActions{
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  background-color: #e2e2e2;
}

.descriptionTextAbc{
  margin-left: 1rem;
  margin-top: 1rem;
}

.iconCopyContentAbcActionsAtive{
  -webkit-animation-name: glowing2;
          animation-name: glowing2;
  -webkit-animation-duration: 1.0s;
          animation-duration: 1.0s;
}

.suggestionMessage{
  background-color: #c49e17;
  height: 13rem;
  margin: 1rem;
  color: white
}

.suggestionMessageHeader{
  font-size: 1.2rem;
  padding: 1rem 0.5rem 0rem 0.5rem;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.iconArrowRightAbc{
  float: right;
  margin-right: 1rem;
  margin-top: -0.8rem;
}

.linksBoxAbc{
  overflow-x: auto;
  display: grid;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3rem;
  max-height: 9rem;
  grid-template-columns: auto 2rem;
}


.suggestionMessageBody{
  font-size: 1rem;
  padding: 2rem 0.5rem 1rem 0.5rem;
  align-items: center;
  text-align: center;
}

.alertMessageAbc{
  border: 1px solid #c49e17;
  border-radius: 10px;
  -webkit-animation: glowing3 2000ms infinite;
          animation: glowing3 2000ms infinite;
}

@-webkit-keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@-webkit-keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

@keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

.commentBoxAbc{
  position:relative;
}
.timelineNewCommentBoxAbc{
  /* height: 12rem; */
  position: absolute;
  width: 27.5rem;
  /* top: 8%; */
  margin: 1.5rem;
  display: grid;
  grid-template-rows: 1fr 3fr;
}

.timelineNewCommentContactTypeAbc{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.timelineNewCommentContactTypeTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 0.4rem;
}

.timelineNewCommentWriteSendAbc{
  display: grid;
  grid-template-columns: 10fr 0fr;
}


.aderenceAbcActiveIcon{
  color: green
}

.aderenceAbcInativeIcon{
  color: rgb(160, 160, 160)
}

.containerProductNameTitleAbc{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.5rem;
  margin-left: 1rem;
}

.containerProductHeaderAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.containerProductNameAbc{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.modalDetailsIconsAbc{
  margin-left: 2rem;
}

.modalDetailsAderenceAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  border-width: 0px 3px;
  padding: 0 1rem;
  border-style: solid;
  border-radius: 4px;
  background-color: #EDEDED; 
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.productCadastrationHeaderAbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--headerAllBg);
  width: 100%;
  height: 5rem;
  color: var(--headerFontColor);
  font-size: 1.3rem;
}

.productCadastrationHeaderAbc span{
  border-bottom: 0.1rem solid var(--formBorder);
  padding: 1rem;
}

.productCadastrationHeaderAbc button{
  display: flex;
  margin-right: 2rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid var(--formBorder);
  color: var(--formBorder);
}

.productCadastrationUpperUpperGridAbc {
  /* display: grid;
  grid-template-columns: 1fr;   
  grid-template-rows: auto;    */
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  /* gap: 1rem; */
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationUpperGridAbc {
  display: grid;
  grid-template-columns: 1fr 1fr;   
  grid-template-rows: auto auto auto;   
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationCategoriesGridAbc {
  margin-top: 1rem;

}

.productCadastrationCategoriesGridButtonAbc {
  margin: 1rem 2rem;
}

.productCadastrationCategoryRowAbc{
  display: grid;
  margin: 1rem 1rem 0 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 4fr 4fr auto;
  width: 50rem;
}

.productCadastrationCategoryRowAbc .buttonWhiteBg {
  height: auto;
}

.productCadastrationContainerRowItemAbc {
  /* width: 25rem; */
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
}

.productCadastrationSubmitAbc {
  margin-left: 2rem;
}
.modalDetailsAderenceAbcActive{
  border-color: #00801C;
}

.modalDetailsAderenceAbcInative{
  border-color: rgb(160, 160, 160);
}

.conteudoGeralDetailsModalAbc{
  display: grid;
  grid-template-columns: 4fr 7fr 6fr;
  height: 36rem
}

.menuCategoryModalAbc{
  margin-top: 1.5rem;
  position: relative;
}

.listClientsModalAbc{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 450;
  overflow-y: auto;
  /* max-height: 33rem; */
}

.menuCategoryItemAbcActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInative:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcActiveAbrir{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInativeAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInativeAbrir:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcGeralActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.menuCategoryItemAbcGeralInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.listClientsHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
}

.listClientsHeaderModalAbcAbrir{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem; 
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
  min-height: 2.1rem;
}

.listClientsBodyModalAbc{
  /* background-color: #00801C; */
  max-height: 29rem;
  overflow-y: auto;
}

.listClientsBodyModalAbcAbrir{
  /* background-color: #00801C; */
  /* max-height: 29.5rem; */
  overflow-y: auto;
}

.menuCategoryListModalAbc{
  max-height: 28rem;
  overflow-y: auto; 
}

.listClientsItemInativeAbc{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemActiveAbc{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemInativeAbc:hover{
  background: #e9e9e9;
}

.listClientsItemInativeAbcAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemActiveAbcAbrir{
  background: #c49e17;
  border-radius: 2px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemInativeAbcAbrir:hover{
  background: #e9e9e9;
}

.categoryDetailsModalAbc{
  margin: 1.5rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  display:grid;
  grid-template-rows: auto 8fr 6rem;
  max-height: 33rem;
}


.clientSpeechAbc{
  background-color:#D9D9D9;
  height: calc(100% - 2rem);
  max-height: 25rem;
  width: calc(100% - 4rem);
  margin: 0 0 0 1rem;
  padding: 1rem;
  border-radius: 4px;
  overflow-y: auto;
}

.clientSpeechHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 3rem;
  margin-left: 1.4rem;
  margin-right: 1rem;
  padding: 0.5rem;
  font-weight: 450;
  max-width: 26rem;
  overflow-x: hidden;
  align-items: center;
}

.productLinkAbc{
  font-size: 1.2rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: auto 4rem;
  font-weight: 400;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 6rem;
  overflow-y: auto;


}

.textAreaSpeechAbc{
  background-color: #e2e2e2;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  font-size: 1rem;
  resize: none;
  padding: 1rem;
  border: none;
}

.textAreaSpeechAbc:focus{
  outline: none;
  border: none;
}

.listOnlyAbc {
  height: 1.6rem;
  width: 8rem;
  font-weight: 400;
  font-size: 1rem;
}

.timelineTitleAbc{
  margin-top: 1rem;
  height: 5rem;
  font-size: 1.1rem;
  font-weight: 500;
  /* display: grid;
  grid-template-columns:3fr 3fr;  */
  padding-bottom: 0.3rem;
  align-items: center;
}

.timelineTitleAbcAbrir{
  margin-top: 1rem;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: 500;
  display: grid;
  grid-template-columns:3fr 3fr; 
  padding-bottom: 0.3rem;
  align-items: center;
}

.containerTimelineAbc{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  width: calc(100% - 4rem);
  margin: auto;
  display: grid;
  grid-template-rows: 4rem auto 10rem;
}

.containerTimelineAbcDetails{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  /* width: calc(100% - 4rem); */
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 10rem;
}

.timelineCommentBoxAbc{
  height: 100%;
  overflow: auto;
  border-radius: 2px;
  background-color: #f8f8f8;
  border: 1px solid #dadada;
  display: grid;
}

.refusalReasonBox{
  background-color: white;
  border: 2px solid #c49e17;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #c49e17;
  width: 15rem;
  height: 21.75rem;
  display: block;
  position: absolute;
  z-index: 10;
  top: 8rem;
  padding: 1rem;
  margin-left: 0.2rem;
  /* margin-left: -5rem; */
  /* margin: 0 auto; */
}

.refuseReasontitleAbc{
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 0.25rem;
  
}

.reasonsRefusalList{
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.refuseReasonHeaderAbc{
  display: grid;
  grid-template-columns: 2rem auto 2rem;
  vertical-align: middle;
}

.InputToFormat_two{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.InputToFormat_two div{
  position: relative;
  flex-grow: 1;
  /* margin: 1rem; */
}

.InputToFormat_two div input{
  border: 0;
  border: 1px solid lightgrey;
  height: 1.9rem;
  border: 1px solid var(--formBorder);
  border-radius: 0.3rem !important;
  outline: none;
  min-width: 15rem;
  /* width: 100%; */
  font-size: 16px;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -webkit-appearance:none;
  border-radius: 0;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.InputToFormat_two div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}

.InputToFormat_two div input::-webkit-input-placeholder{
  color:transparent;
}

.InputToFormat_two div input::placeholder{
  color:transparent;
}

.InputToFormat_two div label{
  color: #7F777F;
  font-size: 0.9rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  color: #8f7f7f;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  font-weight: 400;
}

.InputToFormat_two div input:required:invalid + label:before{
  content: '*';
}
.InputToFormat_two div input:focus + label,
.InputToFormat_two div input:not(:placeholder-shown) + label{
  font-size: 11px;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  color: #c49e17;
  background-color: white;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.carousel-container {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
}

.carousel-menu {
  overflow: hidden;
  white-space: nowrap;
}

#menu-items {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

#menu-items li {
  display: inline-block;
  /* padding: 10px; */
}

.carousel-button {
  border: none;
  color: #5f5f5f;
  background-color: transparent;
}

.carousel-button:hover {
  color: #c49e17;
}
*{
    margin: 0;
    padding: 0;
}

#root{
    height: 100vh;
}

body{
    margin: 0;
    padding: 0;
    background:#EBEFF3;
}

body a, p {
    text-decoration: none;
}

body, input, button, textarea {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

table {
    width: 100%;
    border-collapse: collapse;   
}

#errorMsgFields {
    color: #e60909
}

.indentationRigth{
    margin-left: 200px;
    transition: .2s;
    align-items: center;
    justify-content: center;
    height: calc(100% - 48px);
    position: relative;
    overflow-y: auto;
    
}

.selectFilterTable {
    background: #313131;
    color: white;
    height: 1.8rem;
    outline: none;
    font-size: 0.9rem;
    border: none;
    width: 8rem;
}

.tableAllInputThead {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.tableAllInputThead::-webkit-input-placeholder{
    font-size: 0.9rem;
}

.tableAllInputThead::placeholder{
    font-size: 0.9rem;
}

.buttonWhiteBg{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #c49e17;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: #bea032;
    color: var(--formButtonFontColor)
}

.buttonWhiteBg:hover {
    background: #c3980e;
    background: var(--buttonHover);
    color: white;
}

.buttonTableThead {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid #c49e17;
    border: 0.13rem solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: #bea032;
    color: var(--formButtonFontColor)
}

.buttonTableThead:hover {
    background: #c49e17;
    background: var(--goldColor);
    color: black;
}

.buttonTableThead:focus {
    background: #c49e17;
    background: var(--goldColor);
    color: black;
    outline:none
}

.modalCloseButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid #c49e17;
    border: 0.13rem solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: 0.1s;
    color: #bea032;
    color: var(--formButtonFontColor)
}

.modalCloseButton:hover {
    background: #c3980e;
    background: var(--buttonHover);
    color: white;
}

.indentationRigthTop{
    margin-left: 200px;
    transition: .2s;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
}

.indentationImgRight img {
    display: none;
}

.indentationNormal {
    margin-left: 0;
    justify-content: center;
    align-items: center;
    height: calc(100% - 48px);
    width: calc(100% - 48px);
}

.indentationNormalTop{
    
    margin-left: 0;
    justify-content: center;
    align-items: center;    

}

.indentationNormalTop h2 {
    font-size: 1.2rem;
}

.body{
    padding-top: 3rem;
    padding-left: 3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

}

.body h2{
    margin-right: 3.2rem;    
}

.body h3{
    margin-right: 3.2rem;    
}

.body h4{
    margin-right: 3.2rem;
}

button {
    cursor: pointer;
}

[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
}

.preloaderHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.notificationAlertFooter {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.textEditorLoading {
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.monitoringTableNameDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.monitoringTableDownloadTitle {
    opacity: 0;
    /* max-width: 0; */
    overflow: hidden;
    right: 0;
    display: flex;
    flex-direction: row;
    grid-gap:0.5rem;
    gap:0.5rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.monitoringTableDowloadButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap:0.5rem;
    gap:0.5rem;
}

.monitoringTableDowloadButton svg {
    cursor: pointer;
    transition: 0.2s;
}

.monitoringTableDowloadButton svg:hover {
    color: gray;
}

.monitoringTableDowloadButton:hover .monitoringTableDownloadTitle {
    cursor: pointer;
    opacity: 1;
    width: auto;
    max-width: auto;    
}

.monitoringTableDownloadTitleAbsolute {
    background: rgb(78, 78, 78);
    right: inherit;
    padding: 0.3rem;
    cursor: default;
    font-size: 1rem;
    border-radius: 5px;
}

::-webkit-scrollbar{
    width:13px;
    height: 13px;
}
::-webkit-scrollbar-track{
    background-color: rgb(35, 37, 7);
    border-radius: 1px;
}
::-webkit-scrollbar-thumb{
    background:  #c49e17;
    background:  var(--goldColor);
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover{
    background: #daad1c;
}

@media only screen and (max-width: 600px) {
    .indentationRigth{
        margin-left: 0px;
    }
}

@media (max-width: 1368px){

    .indentationRigth{
       max-height: calc(100% - 46px);
    }

    .indentationNormal {
        margin-left: 0;
        justify-content: center;
        align-items: center;
        max-height: calc(100% - 47px);
        max-width: calc(100% - 47px);
    }

    .buttonWhiteBg{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #c49e17;
        border: 1px solid var(--goldColor);
        font-size: 1rem;
        font-weight: 400;
        height: 1.6rem;
        width: 8rem;
        transition: 0.1s;
        background-color: white;
        color: #bea032;
        color: var(--formButtonFontColor)
    }
    
    .buttonWhiteBg:hover {
        background: #c3980e;
        background: var(--buttonHover);
        color: white;
    }
}



 @-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
  }

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #4d4d4d;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.containerLoading{
    position: absolute;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #EBEBEB;
}

.alertLoad{
    margin-top: 0.3rem;
    color: #4d4d4d;
}

.largerBorder {
    border: 0.2rem solid #c49e17;
    border: 0.2rem solid var(--goldColor);
    background-color: aqua;
 }

 input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

/* Variaveis */

:root {
   --loginContainerBG: #ececec44;
   --loginBoxBG: #1c2833;
   --calendarBorder: #c8c8c8;
   --calendarDPevent: #CDD945;
   --calendarDPeventHover: #e6f170;
   --calendarBgColor: #eeeeee;
   --calendarBodyTdBg: #f1f1f1;
   --calendarBodyTdBgHover: #e7e7e7;
   --calendarSobraEvent: black;
   --calendarSobraEventHover: rgb(63, 63, 63);
   --calendarOfertaEvent: #3DA460;
   --calendarOfertaEventHover: #59bd7a;
   --menuBackgroundSelected: #202d3a;;
   --menuSelectedItem: #2f2f2f;
   --menuSelectedOpen: #141414;
   --topBarBg: rgb(20, 20, 20);
   --menuBorderColor: #292929;
   --formFontColor: #11639e;
   --formBorder: #c49e17;
   --goldColor: #c49e17;
   --bookRubiHeader: #27292c;
   --bookRubiBDRHeader: #d6a908f8;
   --bookRubiBIDIHeader: #447431f8;
   --bookBoosterHeader: #202020;
   --formButtonFontColor: #bea032;
   --buttonBG: rgb(248, 240, 240);
   --buttonHover: #c3980e;
   --headerAllBg: rgba(214,213,213,0.5);
   --headerFontColor: #505050;
   --tableBorderEven: #ffffff70;
   --tableSecondTHGray: #3E3E3E;
   --tableOrderBgEven: #e6e6e770;
   --tableBorder: #bbbbbb70;
   --tableHover: #cecfd170;
   --tableSelectedOperation: #3E3E3E;
   --tableEstruturadasHeader: #11639e;
   --tableSecondTh: #4e4e4e;
   --errorFields: #ab0000;
}


