#borkerPanelLinkRegisterContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#brokerPanelLinkRegisterHeader {
    background: var(--headerAllBg);
    width: 100%;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

#brokerPanelLinkRegisterHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}


#brokerPanelLinkRegisterHeader button {
    margin-right: 1rem;
}

#brokerPanelLinkRegisterBody {
    display: flex;   
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

#brokerPanelLinkRegisterBody form {
    display: flex;
    width: 50%;
    height: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#brokerPanelLinkRegistrerFormDiv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

}

#brokerPanelLinkRegistrerFormDiv label {
    width: 100%;
}

#brokerPanelCategoryButton {
    background: rgb(255, 255, 255);
    height: 2rem;
    width: 8rem;
    border: 1px solid var(--goldColor);
    border-radius: 4px;
    font-size: 1rem;
    color: var(--goldColor);
    font-weight: 400;
    transition: 0.2s;
}

#brokerPanelCategoryButton:hover {
    background: var(--goldColor);
    color: white;
}
