.divContainerReports{
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContainerReports{
  width: 50rem;
  height: 35rem;
  background: white;
}

.modalContainerReportsHeader {
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--headerAllBg);
  top: 0;
}

.modalContainerReportsHeader p{
  font-size: 1.2rem !important;
  margin-left: 1rem;
  border-bottom: 1px solid var(--formBorder);
}

.modalContainerReportsHeader button {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: 1px solid var(--goldColor);
  color: var(--goldColor);         
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalFormReports{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}