.noWrapAbc{
    white-space: nowrap;
    padding: 0 0.5rem 0 0.5rem !important;
    align-items: center;
    display: table-cell;
    text-align: center;
    font-size: 0.8rem;
}

.noWrapAbcTeam{
    white-space: nowrap;
    align-items: center;
    display: table-cell;
    text-align: center;
    background-color: rgb(255, 243, 217);
    padding: 0px !important;
}

.expandableIconDashAbc{
    white-space: nowrap;
    align-items: center;
    display: table-cell;
    text-align: center;
    background-color: white;
    padding: 0px !important;
}

.iconToExpandleRowsAbc{
    background-color: white;
    border: none;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.8rem;
}

.iconToExpandleRowsAbc:hover{
    background-color: #eeeeee
}

.expandableRowDashAbc{
    background-color: rgb(255, 243, 217);
}

.expandablesRowsDashAbc:hover{
    background-color: #eeeeee
}
