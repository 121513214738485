.parent {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    justify-content: space-between
  }
  
.child {
flex: 1 0 21%; /* explanation below */
margin: 5px;
height: 50px;
}

.checkbox{
    margin-right : 0.6rem;
}