/* SIDE BAR */

#checkMenu{
    display: none;
}

#icon{
    cursor: pointer;
    position: absolute;
    z-index: 1;
    color: #fff;
    margin-left: 200px;
    background-color: var(--topBarBg);
    margin-top: 30px;
    border-radius: 50%;
}

.menuIconsOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

#menuNotificationBellArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    transition: 0.2s;
}

#menuNotificationBellArea:hover {
    filter: brightness(0.8)
}

#menuNotificationBellArea label {
    width: 1rem;
    height: 1rem;
    background: red;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: white;
    font-weight: 400;
    margin-top: -0.9rem;
    margin-left: -0.6rem;
}

.bar{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: auto;
    background-color: var(--topBarBg);
    height: calc(100% - 45px);
    width: 250px;
    position: fixed;
    transition: all 0.1s linear;
    left: -200px;
    border-right: 1px solid var(--menuBorderColor);
    display: flex;
    padding: 0;
    justify-content: center;
    top: 45px;
    z-index: 250;

}

.barOverflow {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--topBarBg);
    height: calc(100% - 45px);
    width: 250px;
    position: fixed;
    transition: all 0.1s linear;
    left: -200px;
    border-right: 1px solid var(--menuBorderColor);
    display: flex;
    padding: 0;
    justify-content: center;
    top: 45px;
    z-index: 250;
}

.bar nav{
    width: 100%;
    position: absolute;
    top: 15px;
    color: white;
    
}


#checkMenu:checked ~ .bar{
    /* width: 15%; */
    z-index: 250;
    top: 45px;
    transform: translateX(200px);
}

#divLogoMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sideBarClose{
    z-index: 1;
    width: 100%;
    float: right;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 70px;
    overflow: hidden;
}

/* LEGENDAS DO MENU FECHADO */
.selectedItem .toolTipList {
    transform: translate(36px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: var(--topBarBg);
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s ;
}

.selectedItem:hover .toolTipList {
    display: flex;
    max-width: 10rem;
    white-space: nowrap;
    flex-direction: column;
    padding: 0.5rem !important;
    gap: 0.8rem;
    width: auto !important;
    position: absolute;
    /* background: var(--menuSelectedItem) !important;  */
    transform: translate(36px, -25px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.2s ease;  
}

.selectedItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList div:hover {
    /* background: var(--menuSelectedItem) !important;  */
}

.selectedItem .toolTip a {
    margin: 0 auto !important;
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.toolTipList {
    transform: translate(36px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s ;
}

.menuItem:hover .toolTipList {
    display: flex;
    max-width: 15rem;
    white-space: nowrap;
    flex-direction: column;
    padding: 0.5rem !important;
    gap: 0.8rem;
    width: auto !important;
    position: absolute;
    background: var(--topBarBg) !important; 
    transform: translate(36px, -25px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.4s ease;
}

.menuItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList div:hover {
    background: var(--topBarBg) !important; 
}

.selectedItem div {
    display: flex;
}

.menuItemNotList {
    display: flex;
}

.toolTipNormal {
    transform: translate(20px, -10px);
    max-width: 0px !important;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    color: white;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;    
} 

.menuItemNotList:hover .toolTipNormal a {
    margin: 0 !important;
    padding: 0 !important;
    transition: 0.1s;
    font-size: 0.9rem !important;
    font-weight: 400;
}

.menuItemNotList .toolTipNormal a:hover {
    color: rgb(207, 207, 207);
    font-size: 0.9rem;
    margin: 0 !important;
    padding: 0 !important;
}


.menuItemNotList:hover .toolTipNormal{
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
    width: 100%;
    position: absolute;
    padding: 0.5rem !important;
    max-width: 10rem !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    width: auto !important;
    font-size: 0.9rem;
    opacity: 1;
    background: var(--topBarBg) !important;      
    transform: translate(32px, -10px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    transition: all 0.4s ease;
}

.linkMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#sideBarClose #firstMenuDiv{
    width: 10%;
    height: 25px;
    padding: 0.5rem;
    padding-right: 0.7rem;
    padding-left: 0.9rem;
    transition: .2s;
    cursor: pointer;
    margin: 0;
}

#sideBarClose a{
    margin-right: 0.88rem;
}

#sideBarClose a div{    
    width: 100%;
    display: flex;
    padding-right: 0.6rem;
    padding-left: 0.3rem;
    align-items: center;
}

#sideBarClose div:hover{
    background-color: var(--menuBorderColor);
}

.setNone{
    display: none;
}

.setFlex{

    display: flex;
}

.setShow {
    padding: 0;
}

.chevronIcon{
    margin-left: 0.2rem;
}

/* SIDE BAR ACCORDION AND SIMPLE ITEMS BAR*/

.accordions{
    transition: .2s;
    width: 100%;
    position: relative;
    padding: 0;
    overflow-x: hidden;
}

.accordion-item{
    position: relative;
    width: 100%;
}

.accordion-item input{
	display:none;
    transition: .2s;
}

.accordion-item label{
    position: relative;
    display: flex;
    cursor:pointer;
    width: 100%;
    transition: .2s;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    align-items: center;
}

.accordion-item label p{
    margin-left: 0.5rem;
    font-size: 0.9rem;
}

.accordion-item label div{
    margin-left: 0.9rem;
}


.accordion-item label:hover{
    background-color: var(--menuBorderColor);
    transition: .2s;
}

.accordion-content{
    transition: .3s;
    font-size: 0.9rem;
    height: 0px;
    overflow: hidden;
    background-color: var(--menuSelectedOpen);
    -webkit-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    -ms-transition: height 0.3s ease-in-out;
    cursor: pointer;
 }

.accordion-content a{
    text-decoration: none;
    color: white;
    width: 100%;
}

.accordion-content:hover{
    background-color: var(--menuBorderColor);
}
 
[id^=option]:checked ~ .accordion-content {
    transition: .2s;
    height: auto;
    padding: 0.5rem 0.2rem 0.5rem 2.7rem;
}

.simple-item{
    width: 100%;
    text-decoration: none;
    font-size: 1rem;
}

.simple-item a{
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.simple-item div{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    padding-left: 0.8rem;
    transition: 0.2s;
}

.simple-item div p{
    padding-left: 0.6rem;
    font-size: 0.9rem;
}

.simple-item:hover{
    background-color: var(--menuBorderColor);
}


/* TOP BAR */

.topBar{
    z-index: 210;
    background-color: var(--topBarBg);
    border-bottom: 1px solid var(--menuBorderColor);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
}

.topBar img{
    color: white;
    font-style: normal;
    margin: 0;
    float: left;
    justify-content: center;
    margin-left: 0.4rem;
}

.topBar img:hover {
    filter: brightness(0.90);
    cursor: pointer;
}

.topBar h2{
    color: white;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.userIcon{
    margin-right: 10px;
    cursor: pointer;
}

.backgroundSelected{
    background-color: var(--menuBackgroundSelected) !important;
}

.selectedItem{
    background-color: var(--menuSelectedItem);
    color: var(--formBorder) !important;
    
}

.formatLabel {
    font-size: 0.9rem;
}


@media(max-width:1368px){
    /* LEGENDAS DO MENU FECHADO */
    .selectedItem .toolTipList {
        transform: translate(35px, -25px);
        max-width: 0px;
        z-index: 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        opacity: 0;
        position: absolute;
        font-size: 0.9rem;
        display: flex !important;
        justify-content: space-between !important;
        transition: all ease 0.2s;
        transition-delay: 0.15s ;
    }
    
    .selectedItem:hover .toolTipList {
        display: flex;
        max-width: 10rem;
        white-space: nowrap;
        flex-direction: column;
        padding: 0.5rem !important;
        gap: 0.8rem;
        width: auto !important;
        position: absolute;
        background: var(--topBarBg) !important; 
        transform: translate(35px, -25px);
        -webkit-box-shadow: 5px 8px 15px -7px #000000; 
        box-shadow: 5px 8px 15px -7px #000000;
        color: white;
        opacity: 1;
        border-radius: 0 5px 5px 0;
        font-size: 0.9rem;
        font-weight: 400;
        transition: all 0.2s ease;  
    }
.selectedItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.selectedItem .toolTipList div:hover {
    background: var(--topBarBg) !important; 
}

.selectedItem .toolTip a {
    margin: 0 auto !important;
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.toolTipList {
    transform: translate(35px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s ;
}

.menuItem:hover .toolTipList {
    display: flex;
    max-width: 10rem;
    white-space: nowrap;
    flex-direction: column;
    padding: 0.5rem !important;
    gap: 0.8rem;
    width: auto !important;
    position: absolute;
    background: var(--topBarBg) !important; 
    transform: translate(35px, -25px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.4s ease;
}


.menuItem .toolTipList a{
    text-decoration: none;
    color: white;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList a:hover{
    color: gray;
    text-decoration: none;
    padding: 0 !important;
    margin: 0 !important
}

.menuItem .toolTipList div:hover {
    background: var(--topBarBg) !important; 
}

.selectedItem div {
    display: flex;
}

.menuItemNotList {
    display: flex;
}


.toolTipNormal {
    transform: translate(20px, -10px);
    max-width: 0px !important;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    color: white;
    position: absolute;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;    
} 

.menuItemNotList:hover .toolTipNormal a {
    margin: 0 !important;
    padding: 0 !important;
    transition: 0.1s;
    font-size: 0.8rem !important;
    font-weight: 400;
}

.menuItemNotList .toolTipNormal a:hover {
    color: rgb(207, 207, 207);
    font-size: 0.9rem;
    margin: 0 !important;
    padding: 0 !important;
    
}


.menuItemNotList:hover .toolTipNormal{
    border-radius: 0 5px 5px 0;
    white-space: nowrap;
    width: 100%;
    position: absolute;
    padding: 0.5rem !important;
    max-width: 10rem !important;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    width: auto !important;
    font-size: 0.9rem;
    opacity: 1;
    background: var(--topBarBg) !important;      
    transform: translate(32px, -10px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    transition: all 0.4s ease;
}

/*MENU FECHADO PARA TELA PEQUENA*/
}

.multMenu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background: rgb(32, 32, 32); */
    font-size: 1.5rem;
    color: white;
}

.multMenu svg {
    margin-right: 0.3rem;
}

.optionMultMenuItem {

    transform: translate(35px, -25px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s 
}

.multMenu:hover .optionMultMenuItem {
    display: flex;
    max-width: 160rem;
    min-width: 168px;
    white-space: nowrap;
    overflow: initial;
    flex-direction: column;
    padding: 0.5rem !important;
    background: var(--topBarBg);
    gap: 0.8rem;
    transform: translate(122px, 0px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.2s ease;  
}

.optionMultMenuItem a {
    text-decoration: none;
    color: white;
}

.mulMenuFatherItemsToolTip {
    transform: translate(35px, 0px);
    max-width: 0px;
    z-index: 0;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    font-size: 0.9rem;
    display: flex !important;
    justify-content: space-between !important;
    transition: all ease 0.2s;
    transition-delay: 0.15s 
}

.multMenuFatherItems:hover .mulMenuFatherItemsToolTip  {
    display: flex;
    max-width: 800px;
    white-space: nowrap;
    flex-direction: row;
    padding: 0.5rem !important;
    gap: 0.8rem;
    position: absolute;
    background: var(--topBarBg) !important; 
    transform: translate(185px, -25px);
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 8px 15px -7px #000000;
    color: white;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    font-size: 0.9rem;
    font-weight: 400;
    transition: all 0.2s ease;  
}

.containerItemMenu{
    display: grid;
    grid-template-columns: auto auto;
}

.subNotch{
    width: fit-content;
    background-color: red;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 0.4rem;
}

.notch{
    width: fit-content;
    background-color: red;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
}

.notchClosedMenu{
    width: fit-content;
    background-color: red;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    color: white;
    transform: translate(-0.8rem, -0.5rem);
}
.notchClosedMenu:hover{
    background-color: red !important;
}