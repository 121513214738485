.faqCadastration{
    width: 50rem;
    height: 31em;
    background-color: white;
}
@media(max-width:1368px){
    .faqCadastration{
        width: 50rem;
        height: 37em;
        background-color: white;
    }
}

.faqCadastration form{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.faqCadastration legend{
    font-size: 1rem !important;
}


.faqCadastrationHeader {
    top: 0;
    padding: 1rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    background: var(--headerAllBg);
}

.faqCadastrationHeader p {
    border-bottom: 0.1rem solid var(--formBorder);
}

.faqCadastrationHeader button{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--formBorder);
    color:  var(--formBorder);
    padding-bottom: 0.2rem;
    font-size: 18px;
}

.radioSelectContainer{
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.95rem;
    font-family: 'Roboto';
    color: #7F777F;
    font-weight: 500;
}

.radioSelectContainer div{
    gap: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radioSelectContainer div input{
    width: 1rem;
    height: 1rem;
}

.radioSelectContainer div input:checked ~ label{
    color: var(--formBorder)
}

.formFormat .buttonFormFaq{
    width: 7rem;
    font-size: 1rem;
    height: 2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.2rem;
    background-color: var(--formBorder);
    color: #fff;
    font-weight: 500;
    transition: .2s;
}


.formFormat .buttonFormFaq:hover{
    background-color: #fff;
    color: var(--formBorder);
}


.hrStyle{
    width: 20rem;
}

.radioSelectContainerColumn{
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;  
    font-size: 0.95rem;
    font-family: 'Roboto';
    color: #7F777F;
    font-weight: 500;
    width: 80%;
}

.radioSelectContainerColumnLine{
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}

.radioSelectContainerColumnLine div{
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

.fileInputAdjust input[type='file'] {
    display: none !important;
    height: 2.2rem;
}
  
  /* Aparência que terá o seletor de arquivo */
.fileInputAdjust label {
    background-color: #3498db;
    border: 1px solid #3498db;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    font-size: 1rem;
    padding: 6px 20px;
    font-weight: 400;
    transition: .2s;
}

  /* Aparência que terá o seletor de arquivo */
  .fileInputAdjust label:hover {
    background-color: #fff;
    height: 2.2rem;
    border-radius: 5px;
    color: #3498db;
    cursor: pointer;
    margin: 5px;
    padding: 6px 20px
}

.InputToFormat{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.InputToFormat div{
    position: relative;
    flex-grow: 1;
    /* margin: 1rem; */
}

.InputToFormat div input{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    min-width: 7rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}
  
.InputToFormat div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.InputToFormat div input::placeholder{
    color:transparent;
}
  
.InputToFormat div label{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.InputToFormat div input:required:invalid + label:before{
    content: '*';
}
.InputToFormat div input:focus + label,
.InputToFormat div input:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}



.InputToFormat_two{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.InputToFormat_two div{
    position: relative;
    flex-grow: 1;
    /* margin: 1rem; */
}

.InputToFormat_two div input{
    border: 0;
    border: 1px solid lightgrey;
    height: 2.2rem;
    border: 1px solid var(--formBorder);
    border-radius: 0.3rem !important;
    outline: none;
    min-width: 18.6rem;
    /* width: 100%; */
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
  
.InputToFormat_two div input:focus{
      box-shadow: inset 0 0 0 1px var(--formBorder);
}
  
.InputToFormat_two div input::placeholder{
    color:transparent;
}
  
.InputToFormat_two div label{
    color: #7F777F;
    font-size: 0.9rem;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    margin-top: 0.6rem;
    margin-left: 0.3rem;
    color: #8f7f7f;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    font-weight: 400;
}
 
.InputToFormat_two div input:required:invalid + label:before{
    content: '*';
}
.InputToFormat_two div input:focus + label,
.InputToFormat_two div input:not(:placeholder-shown) + label{
    font-size: 11px;
    margin-top: -0.5rem;
    margin-left: 0.8rem;
    color: #3c7adb;
    background-color: white;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}