.booksConfigurationContainer {
    height: calc(100% - 6px);
    width: calc(100% - 1px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booksTab {
    display: flex;
    flex-direction: row;
    width: 95%;
    cursor: pointer;
    margin-top: 2rem;
    
}

.booksTabOption {
   
    color: white;
    background: var(--topBarBg);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.booksTabOption div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.booksInactiveTabOption:hover {
    color: white;
}

.booksInactiveTabOption {
    color: white;
    background: var(--topBarBg);
    color: rgba(255, 255, 255, 0.534);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.booksInactiveTable{
    display: none;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}