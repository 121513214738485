.containerCabecalho{
  /* display: flex;
  justify-content: space-between; */
  /* min-height: 4rem; */
  display: inline-block;
  display: grid;
  grid-template-columns: 30vh auto 30vh;
  width: 100%;
  background-color: #d8e0e7;
  padding-top: 1rem;
  border-bottom: 1rem solid #d8e0e7;
}

.boxAnswers{
  max-height: calc(100vh - 17rem);
  height: calc(100vh - 15rem);
  overflow-y: auto;
}

.containerCabecalhoPortfolio{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
}

.boxTituloPerfil{
  display: flex;
  margin-right: 5rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  right: 2rem;
  height: fit-content;
  width: fit-content;
  margin: auto
}

.backArrow{
  height: fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 2rem;
}

.buttonOpenCloseSection{
  right: 2rem;
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  color: rgb(0, 0, 0);
}

.buttonOpenCloseSection:hover {
  color: rgb(97, 97, 97);
  cursor: pointer;
}

.sectionTitle{
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
}

.questionDescription{
  margin-left: 2rem;
}

.checkbox{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.radiobox{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.gridteste{
  display: grid;
  grid-template-columns: 4rem auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.opcoesDaPergunta{
  display: grid;
}

.finishButton{
  margin-top: 2rem;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.finishButton:hover{
  background-color: #cecece;
  color: #141414;
}

.boxFinish{
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #d8e0e7;
  height: 6rem;
}

.saveButton{
  display: grid;
  grid-template-columns: auto auto;
  width: 13rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  border: none;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.2rem;
  background: transparent;
  transition: all ease-in-out 0.1s;
}

.saveButton:hover{
  background: #b8b8b8;
}

.saveProgressTitle{
  margin: auto;
  height: fit-content;
  width: fit-content;
}

.backArrow{
  border: none;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 0.2rem;
  background: transparent;
  color: black;
  transition: all ease-in-out 0.1s;
}

.backArrow:hover{
  background: #d4d4d4;
}

.containerSaving{
  display: grid;
  grid-template-columns: auto auto;
}

.savingText {
  font-size: 1.6rem;
  height: fit-content;
  margin: auto;
  margin-left: 2.4rem;
  color: #494949;
}

.containerDotsSaving{
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 0.6rem;
}

.containerDotsSaving > div:nth-child(2) {
  animation-delay: 0.2s;
}

.containerDotsSaving > div:nth-child(3) {
  animation-delay: 0.4s;
}

.dotSaving{
  width: 0.2rem;
  height: 0.2rem;
  background-color: #242424;
  border-radius: 50%;
  margin: auto;
  margin-left: 0.4rem;
  animation: dotAnimationSaving 0.6s infinite alternate;
}

@keyframes dotAnimationSaving {
  to {
    opacity: 0;
  }
}

.savedText{
  color: #186800;
  font-size: 1.6rem;
  margin: auto;
  margin-left: 2.4rem;
}

.observation{
  margin-left: 3rem;
}

.observationText{
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 1.5rem;
}