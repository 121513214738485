.dashboardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 900px;
    overflow-x: auto;
    background-color: white;
}

.retracaoCarrouselAbc{
    transition: all 0.2s;
}

.retracaoCarrouselAbc:hover{
    cursor: pointer;
    color: #c29c00;
}

.tileLoaderSm {
    width: 15rem;
}

.tileLoaderFullW {
    width: 100%;
}

.tileLoaderLg {
    width: 35rem;
}

#dashBoardLoader {
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dashboardContainer ::-webkit-scrollbar {
    width: 0.8rem;
}

.dashboardContainer ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(197, 197, 197);
    background: rgb(238, 238, 238);
}

.dashboardContainer ::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
    border-radius: 10px;
    transition: 0.2s;
}

.dashboardContainer ::-webkit-scrollbar-thumb:hover {
    background: rgb(192, 192, 192);
}

#dashboardIndicationButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background: rgba(37, 64, 109, 1);
    color: white;
    transition: 0.2s;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 1.8rem;
}

#dashboardIndicationButton svg {
    margin-right: 1.5rem;
    color: white;
}

#dashboardIndicationButton:hover {
    background: rgb(238, 238, 238);
    color: black;
}

#dashboardIndicationButton a {
    font-size: 1rem;
    font-weight: 400;
}

.dashboardLeftSide {
    border: 1px solid rgba(0, 0, 0, 0.178);
    height: calc(100vh - 49px);
    width: 25rem;
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    -webkit-box-shadow: 7px 4px 18px -10px #000000;
    box-shadow: 3px 4px 10px -10px #000000;
}

.dashboardUpSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    width: 100%;
}

.dashboardUpSide label {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dashboardUpSide label a {
    margin-left: 1.5rem;
}

#dashboardMyclientsText {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.dashboardCustomButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background: white;
    transition: 0.2s;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 1.8rem;
}

.dashboardCustomButton svg {
    margin-right: 1.5rem;
    color: rgba(37, 64, 109, 1);
}

.dashboardCustomButton:hover {
    background: rgba(37, 63, 109, 0.123);
}

.dashboardCustomButton a {
    font-size: 0.9rem !important;
}

.dashboardUpSide label {
    border-bottom: 1px solid rgb(214, 214, 214);
}

.dashboardRightSide {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: hidden; */
}

.expandleLsIcon2 {
    /* padding-top: 2rem; */
}

.dashboardRightSideContent {
    width: 98%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

#dashboardWelcomeMsg {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboardRightSideContentAdvisor {
    width: 98%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    /* gap:0.5rem; */
    padding-bottom: 0.5rem;
}

.headerProductCellBoxAdvisor {
    background-color: #fffcef;
    display: grid;
    grid-template-columns: 1fr auto;
    margin-right: 0.5rem;
    /* margin-left: 0.5rem; */
    /* border-left: 1px solid black !important; */
    /* padding: 0.2rem; */
}

.headerProductCellBoAdvisor:hover {
    cursor: pointer;
}

#dashboardWelcomeMsgAdvisor {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.dashboardContainer h2 {
    font-size: 1.5rem;
    font-weight: 300;
}

.dashboardContainer a {
    font-size: 1rem;
}

.dashboardBoxContainer {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background: white;
    -webkit-box-shadow: 7px 4px 18px -10px #000000;
    box-shadow: 3px 4px 10px -10px #000000;
    min-height: 6rem;
}
.dashboardBoxContainerBaterBase {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    flex-direction: column;
    align-items: center;
    width: 80vw;
    overflow-x: hidden;
    background: white;
    -webkit-box-shadow: 7px 4px 18px -10px #000000;
    box-shadow: 3px 4px 10px -10px #000000;
    min-height: 6rem;
}

.wisirContainer {
    display: grid;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* max-height: 100%; */
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
}

.campaignsContainer {
    display: grid;
    flex-direction: column;
    align-items: center;
    /*     height: 330px; */

    width: 100%;
    /* padding: 10px 0px; */
    grid-template-columns: 4fr 1fr;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
}

.carouselBox {
    /* background-color: #47e26e; */
    height: 20rem;
}

.carousel-container-dash {
    /* margin-top: 0.5rem;
    margin-bottom: 0.5rem; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: grid;
    grid-template-columns: 2rem calc(100% - 4rem) 2rem;
}

.carousel-menu-dash {
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
}

#menu-items-dash {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    transition: transform 0.3s ease-in-out;
}

.itemCarrouselDash {
    /* background-color: #e9e8e8; */
    border-radius: 10px;
    margin-right: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    font-size: 1.2rem;
    height: 14.5rem;
    width: 20rem;
    opacity: 100%;
}

.boxInfosItemCarrousel {
    padding: inherit;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows:0.7rem 3rem 2.8rem 6rem 2rem;
}

.boxInfosItemCarrouselHeader {
    padding-top: 0.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    max-width: 20rem;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: grid;
    align-items: center;
    grid-template-columns: 2rem auto 2rem;
}

.boxInfosItemCarrouselSubheader {
    margin-top: 0.2rem;
    font-size: 1rem;
    font-weight: 400;
    max-width: 20rem;
    max-height: 2.4rem;
    overflow-y: hidden;
    white-space: pre-line;
    text-overflow: ellipsis;
}

.boxInfosItemCarrouselBody {
    /* margin-top: 0.5rem; */
    /* text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem; */
    font-size: 1rem;
    overflow: auto;
    overflow-y: hidden  ;
    position: relative;
    top: -15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.containerCardsData {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: space-between;
    align-items: center;
    gap: .3rem;
    border-radius: 10px;
    /*     background-color: #525252;
 */
    font-weight: 600;
    padding: 10px;
}


.boxInfosItemCarrouselMaterial {
    border-radius: 10px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    color: white;

    font-weight: bold;
}

.boxInfosItemCarrouselMaterial a {
    color: inherit;
    font-size: 1.2rem;
}

/* .itemCarrouselDash:hover{
    background: #e9e9e9;
  } */

.columnExpandableLsDash {
    text-align: center;
    display: grid !important;
    grid-template-columns: auto 1rem;
    height: 5.7rem;
    white-space: nowrap;
}

.columnExpandableLsDash2 {
    text-align: center;
    /* display: grid !important; */
    grid-template-columns: auto 1rem;
    height: 5.7rem;
    margin-top: -1rem;
    white-space: nowrap;
}

.containerCellProductDash {
    display: grid;
    background-color: #fffcef;
    margin-right: 0.5rem;
    /* align-items: center; */
}

.containerCellNameProductDash {
    display: flex;
    justify-content: center;
    align-items: center;

    /* display: grid; */
    /* background-color: #fffcef; */
    margin-right: 0.5rem;
    /* position: absolute;
    width: 200%; */
}

.subtitleCellBoxDash {
    display: grid;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    grid-template-rows: 1rem 1rem;
    padding-bottom: 0.4rem;
}

.subtitleCellBoxDashSum {
    display: table;
    text-align: center;
    color: green;
}

.statusColorFooterDivAbcDash {
    width: 14px;
    height: 14px;
    margin: auto;
    border-radius: 5px;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    border: 2px solid #ffffff;
}


.productNameDash {
    /* position: absolute;
    width: 15rem; */
    font-size: 1rem;

}

.subtitleObjectiveAbcDash {
    display: table;
    text-align: center;
}

.statusDivDash {
    background-color: #EFEDED;
    color: #595858;
    border-radius: 4px;
    vertical-align: middle;
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    /* margin-left: 0.2rem; */
    /* margin-right: 0.4rem; */
    transition: all ease 0.1s;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
}

.statusDivDash:hover {
    background-color: #141414;
    color: #fffdf9;
    cursor: pointer;
}
.statusDivDashCopy:hover {
    color: #fffdf9;
    cursor: pointer;
}

.detailscampangeDash {
    background:'#D5AF01';
    border-radius:'10px';
    padding:'6px'; 
    transition:'0.2s';
}
.detailscampangeDash:hover{
    background-color: #C29C00;
}

#menu-items-dash li {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    /* padding: 10px; */
}

.carousel-button-dash {
    border: none;
    color: #5f5f5f;
    background-color: transparent;
}

.carousel-button-dash:hover {
    color: #c49e17;
}

.buttond0{
    color: white;
    background-color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}
.buttond1{
    background-color: #C8AA20;    
    color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
    color: white;


}

.buttond2{
    color: white;
    background-color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}

.buttond3{
    background: #C8AA20;
    color: white;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}

.buttond3Plus{
    color: white;
    background-color: #C8AA20;
    border:2px solid white;
    border-radius:10px ;
    font-weight:bold; 
    padding:12px 5px ;
    font-size:0.8rem; 
    margin:0.25rem 0.25rem; 
    align-items:center; 
    max-width:80px;
    min-width:70px;
    transition:0.2s;
}

.actionsDash {
    position: relative;
    content: "";
    height: 14rem;
    /* border-left: 1px solid rgb(170, 170, 170); */
    align-items: center;
    display: grid;
    top: -10px;
    grid-template-rows: 1fr 1fr 1fr ;
    transition: 0.3s;
    /* flex-direction: column; */
    /* flex-direction: row; */

}

.actionsDash::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "";
    position: absolute;
    left: 0;
    transform: translateY(4%);
    height: 92%;
    border-left: 1px solid rgb(167, 167, 167);
    /* Altere a cor e espessura da borda conforme necessário */
}

.dashButton {
    font-size: 1.2rem;
    font-weight: 400;
    width: 90%;
    /* height: 135px; */
    max-width: 165.75px;
    padding: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    /* margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.8rem; */
    margin: 0.25rem 1rem;

    color: #c49e17;
    border: 2px solid white;
    outline: 1px solid #bababa;
    border-radius: 10px;
    background-color: white;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;

    border-radius: 1.75rem;
    box-shadow: #bababa 0px 0px 5px 1px;


    display: grid;
    grid-template-columns: 1fr 4fr;
    max-width: none;
    /* height: 50px; */
    border-radius: 10px;
    /*  padding: 0.5rem 0rem;
 */
    justify-content: center;
    justify-items: start;
    align-items: center;
}

.dashButton:hover {
    color: #fff;
    /* border: 2px solid #c49e17; */
    background-color: #c49e17;
    order: 5px solid white;
    outline: 1px solid #bababa;
    /* box-shadow: 0px 0px 10px #c49e17; */
}

#dashboardWisir {
    flex: 0rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    padding: 5px;
    align-items: center;
    width: 90%;
    height: 96%;
    align-items: center;
    overflow-x: hidden;
    background: white;
    -webkit-box-shadow: 7px 4px 18px -10px #000000;
    box-shadow: 3px 4px 10px -10px #000000;
}

/* .dashboardWisirTable{
    border: none;
    box-shadow: 3px 4px 10px -10px #000000;
} */
.dashWisirItensBox {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0%;
    width: 100%;
    align-content: space-evenly;
    padding-left: 1rem;
    padding-right: 1rem;
}

.dashWisirItens {
    display: flex;
    flex-wrap: wrap;
    padding: 0%;
    width: 100%;
    justify-content: space-between;
}

.dashWisirItensBox2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0%;
    width: 100%;
    justify-content: space-between;
    min-height: 6rem;
}

#dashboardWisir h2 {
    font-size: 1rem;
    color: rgb(109, 109, 109);
    font-weight: 500;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
}

#dashboardWisir h4 {
    font-size: 1.1rem;
    color: rgb(131, 131, 131);
    font-weight: 400;
    margin: 0;
    margin-bottom: 0.5rem;
}

#dashboardWisir {
    width: 94%;
    /* height: fit-content; */
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 20rem;
    overflow-x: hidden;
    margin: auto;
}

.dashboardBoxContainer h2 {
    font-size: 1.1rem;
    color: rgb(109, 109, 109);
    font-weight: 500;
    margin: 0;
    margin-bottom: 0.5rem;
}

#dasboardTopTradersContent {
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 20rem;
    overflow-x: hidden;
}

#dasboardTopTradersContent .dashboardList {
    cursor: default;
}

.dashboardListTitle {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}

.dashboardListTitle2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}

.body h2 {
    margin-right: 0rem !important;
}

.cardBrokerTitle {
    margin-top: 1rem;
}

.containerCardsBrokers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 50%;
    height: 18rem;
    margin: auto;
    margin-bottom: 0.8rem;
    padding-top: 0.8rem;
    padding-left: 5rem;
    padding-right: 5rem;
    border-radius: 10px;
    font-weight: 400;
}

.labelCardBroker {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.cardBrokerImgframe {
    width: 12rem;
    height: 12rem;
    background: white;
    margin: 0.8rem;
    border-radius: 100%;
    border: 0.2rem solid #fff;
    box-shadow: -3px 4px 10px -10px #000000;
    overflow: hidden;
    text-align: center;
    z-index: 90;
}

.cardBrokerImg {
    width: auto;
    max-height: 12.6rem;
    height: 12.6rem;
    transform: translate(-0.3rem, -0.3rem);
    border-radius: 100%;
}

.infoBrokerDash {
    text-align: center;
    padding-top: 3.2rem;
    line-height: 1.8;
    height: 100%;
    background: #d1d8df;
    font-weight: 500;
}

.hoverAreaBrokerDash {
    background: transparent;
    width: 12rem;
    height: 12rem;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

.infoBoxContainer {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background: #f1f1f1;
    z-index: 110;
    transform: translateX(-50%);
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid white;
    white-space: nowrap;
}

.titleInfoBoxDash {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.8rem;
}

.TableCell {
    position: sticky;
    top: 0px;
    justify-content: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: center;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    white-space: nowrap;
    -webkit-backgroun-color: white;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #fff;

    /*     min-width: 10rem;
 */
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.containerTableCellButton {
    min-width: 5rem;
    padding: 20px;

    display: flex;
    justify-content: center;
    /* }
.TableCell div{
    
    transition: 0.2s;
    display: inline-block;
*/
}
.containerTableCellButtonBaterBase {
    min-width: 5rem;    
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.containerTableCellButtonBaterBase div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.containerTableCellButton div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.TableCell div:hover {
    cursor: pointer;
    /* 
    padding: 0.8rem; */
    border-radius: 10px;
    background: #efefef;
}

.textInfoBoxDash {
    font-size: 1rem;
    font-weight: 400;
    margin: 0.4rem;
    padding: 0.4rem;
    padding-right: 0.8rem;
    padding-right: 0.8rem;
}

.containerContato {
    display: flex;
    margin: 0.4rem;
}

.logoWPP {
    background-color: #47e26e;
    padding: 0.3rem;
    border-bottom-left-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
}

.buttonWPP {
    background-color: #47e26e;
    border: none;
    border-bottom-right-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    border-left: 4px solid #5df784;
}

.buttonWPP:hover {
    background-color: #5df784;
}

.logoEMAIL {
    background-color: #71ace2;
    padding: 0.3rem;
    border-bottom-left-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
}

.buttonEMAIL {
    background-color: #71ace2;
    border: none;
    border-bottom-right-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    border-left: 4px solid #85bdf1;
}

.buttonEMAIL:hover {
    background-color: #85bdf1;
}

.overlayBrokerImgInfoFixed {
    width: 12.4rem;
    height: 12.4rem;
    background-color: white;
    opacity: 0.5;
    position: absolute;
    border-radius: 100%;
    z-index: 80;
    margin: auto;
}

.overlayLiderImgInfoFixed {
    width: 5.3rem;
    height: 5.3rem;
    background-color: white;
    opacity: 0.5;
    position: absolute;
    border-radius: 100%;
    z-index: 80;
    margin: auto;
}

.textoFixoOverlayImg {
    width: fit-content;
    margin: auto;
    padding-top: 5.2rem;
    font-size: 1.8rem;
    color: #a7a7a7;
}

.tituloContainerLiderPorBroker {
    text-align: center;
    font-size: 1.6rem;
    display: grid;
    grid-template-columns: 3rem 1fr auto auto 1fr;
}

.tituloLiderPorBroker {
    height: fit-content;
    margin: auto;
    grid-column-start: 3;
}

.containerLiderPorBroker {
    width: 80%;
    margin: auto;
    height: 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.labelCardLider {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.cardLiderImgframe {
    width: 5rem;
    height: 5rem;
    background: white;
    margin: 0.8rem;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: -3px 4px 10px -10px #000000;
    overflow: hidden;
    text-align: center;
    z-index: 80;
}

.cardLiderImg {
    width: auto;
    max-height: 5.6rem;
    height: 5.6rem;
    transform: translate(-0.3rem, -0.3rem);
    border-radius: 100%;
}

.filterDashboardButtonOk {
    width: 100%;
    margin-top: 0.75rem;
    background-color: #525252;

    background: #D4AC00;
    color: white;
    height: 2rem;
    font-weight: 500;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    transition: .1s;
    font-size: 0.9rem;
    margin-right: 0.5rem;
    transition: 0.2s ease;
}

.filterDashboardButtonOk:hover {
    background: #E6CE74;
    color: white;
}

.containerButtons-Dash {
    width: 90%;
    display: grid;
    justify-items: center;
    justify-content: center;
}

.expandLideresPorBroker {
    width: 3rem;
    height: fit-content;
    background: transparent;
    border: none;
    color: gray;
    grid-column-start: 4;
}

.expandLideresPorBroker:hover {
    color: black;
}

.hoverAreaLiderDash {
    background: transparent;
    width: 5rem;
    height: 5rem;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

#dashboardTextEditor {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#dasboardTextEditor div div div div div div div div span:nth-child(1) {
    word-break: break-all;
}

#dashboardTextTitle {
    outline-color: rgb(173, 173, 173);
    font-size: 1rem;
    border: 1px solid rgb(189, 189, 189);
    padding-left: 0.5rem;
    height: 2rem;
}

#dashboardTextTitle::placeholder {
    color: rgb(143, 143, 143);
    font-size: 1rem;
}

#dashboardTextEditorContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 30rem;
}

#dashboardNewTextButton {
    background: rgba(37, 64, 109, 1);
    border: none;
    height: 1.2rem;
    border-radius: 4px;
    width: 2rem;
    color: white;
    font-size: 1rem;
    transition: 0.2s;
    border: 1px solid rgba(37, 64, 109, 1);
}

#dashboardNewTextButton:hover {
    cursor: pointer;
    background: white;
    color: rgba(37, 64, 109, 1);
    border: 1px solid rgba(37, 64, 109, 1);
}

.dasboardListContent {
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.dashboardList {
    border-radius: 5px;
    border-bottom: 1px solid rgba(207, 207, 207, 0);
    border-top: 1px solid rgba(207, 207, 207, 0);
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    padding: 0.4rem;
}

.dashboardListToday {
    border-radius: 5px;
    color: rgb(0, 0, 34);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-top: 1px solid rgb(255, 255, 255);
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dashboardListToday:hover {
    cursor: pointer;
    background: rgb(231, 231, 231);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-top: 1px solid rgb(207, 207, 207);
}

.dashboardList:hover a {
    color: black;
}

.dashboardList:hover {
    cursor: pointer;
    background: rgb(231, 231, 231);
    border-bottom: 1px solid rgb(207, 207, 207);
    border-top: 1px solid rgb(207, 207, 207);
}

/* HOVER PARA INDICAÇÃO */

.dashboardListIndicate {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 96%;
    margin: auto;
    padding: 0.5rem;
    border: outset;
    background: #ffd900;
    border: none;
    transition: all ease 0.1s;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

#dashboardWisir .dashboardListIndicate h4 {
    color: #000000;
    margin-bottom: 0;
    font-size: 1.2rem;
}

.dashboardListIndicate:hover {
    cursor: pointer;
    background: #ebc700;
}

/* FIM HOVER PARA INDICAÇÃO */

#dashboardListRigthSide {
    margin-left: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    max-width: 220px;
    min-width: 80px;
}

#dashboardLeftSide {
    margin-right: 1rem;
    max-width: 550px;
    text-overflow: ellipsis;
    width: 50%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

#dashboardMidlleSide {
    margin-right: 1rem;
    max-width: 550px;
    text-overflow: ellipsis;
    width: 30%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.dashboardList a {
    transition: 0.2s;
    font-size: 1rem;
    font-weight: 300;
    color: rgb(109, 109, 109);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dashboardListResult {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dashboardListResult a {
    font-size: 1rem;
    font-weight: 400;
    color: rgb(109, 109, 109);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#dasboardLastUpdatesContent {
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 20rem;
    overflow-x: hidden;
}

.dashboardListLeftSide {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 550px;
    margin-right: 2rem;
    width: 50%;
    white-space: nowrap;
}

.dashboardListLeftSide p {
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dashboardDeleteTopTraderTextButton {
    width: 5rem;
}

#dashboardTopTradeButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

#dashboardTopTradeButtons svg:first-child {
    transform: translate(-0.5rem, 0);
    color: rgb(117, 1, 1);
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
}

#dashboardTopTradeButtons svg:last-child {
    transform: translate(-0.5rem, 0);
    color: rgb(7, 0, 104);
    opacity: 0;
    transition: 0.2s;
    cursor: pointer;
}

#dashboardTopTradesContainer:hover #dashboardTopTradeButtons svg {
    opacity: 1;
}

#dashboardTopTradesContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: flex-start;
}

body {
    overflow: hidden;
}

.tableReceita {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2rem;
}

.containerDocViewerRecadosDash {
    height: calc(100vh - 6rem);
}

.containerButtonRecadosDashLeft {
    width: fit-content;
    height: 80%;
    padding-left: 0.6rem;
    padding-right: 0.8rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-left: 0.5rem;
    color: #141414;
    background-color: white;
    border-radius: 4px;
}

.containerButtonRecadosDashLeft:hover {
    color: #fff;
    background-color: #383838;
}

.containerButtonRecadosDashRight {
    width: fit-content;
    height: 80%;
    padding-left: 0.8rem;
    padding-right: 0.6rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-right: 0.3rem;
    color: #141414;
    background-color: white;
    border-radius: 4px;
}

.containerButtonRecadosDashRight:hover {
    color: #fff;
    background-color: #383838;
}

.containerButtonChangeMessage {
    width: fit-content;
    height: fit-content;
    padding: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.8rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-left: 5vw;
    margin-bottom: 0.6rem;
    color: #141414;
    background-color: white;
    border-radius: 4px;
}

.containerButtonChangeMessage:hover {
    color: #fff;
    background-color: #383838;
}

.containerButtonChangeViewMessage {
    width: fit-content;
    height: fit-content;
    padding: 0.4rem;
    padding-left: 0.6rem;
    padding-right: 0.8rem;
    border: none;
    transition: all ease 0.1s;
    display: grid;
    grid-template-columns: auto 1fr;
    margin: auto;
    margin-left: 5vw;
    margin-bottom: 0.6rem;
    color: #141414;
    background-color: white;
    border: 1px solid #141414;
    border-radius: 4px;
    position: absolute;
    left: 2rem;
    bottom: 1rem;
    z-index: 1;
}

.containerButtonChangeViewMessage:hover {
    color: #fff;
    background-color: #383838;
}

.containerHeaderRecadosDash {
    height: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    background-color: #dbdbdb;
}

.textReloadFile {
    width: fit-content;
    height: fit-content;
    margin: auto;
    font-size: 1.2rem;
    font-weight: 400;
}

.iconReloadFileLeft {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
}

.iconChangeView {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 0.3rem;
    margin-top: 0.1rem;
}

.iconReloadFileRight {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-left: 0.3rem;
    margin-bottom: 0.3rem;
}

.iconChangeMessage {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 0.3rem;
}

.fileNameRecadosDash {
    width: fit-content;
    height: fit-content;
    margin: auto;
    color: #383838;
    font-weight: 400;
    font-size: 1.4rem;
    white-space: nowrap;
}

.containerFileNameRecadosDash {
    max-width: 90%;
    overflow: auto;
    margin: auto;
}

.containerDragNDrop {
    display: grid;
}

.titleDragNDrop {
    font-size: 2rem;
    width: 100%;
    text-align: center;
    margin-top: 4rem;
    font-family: Georgia, serif;
    font-weight: 400;
}

.dragNDropArea {
    width: 80%;
    height: 60vh;
    background-color: #ffffff;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 3rem;
    border-radius: 20px;
    box-shadow: 0px 0px 20px black;
    display: grid;
    grid-template-rows: 4fr 3fr;
    transition: all ease 0.1s;
}

.dragNDropArea:hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px #ffc800;
}

.dragNDropArea:hover .iconDragNDrop {
    color: #ffc80083;
}

.dragNDropArea:hover .dragNDropText {
    color: #3a2e04;
}

.iconDragNDrop {
    width: 100%;
    height: fit-content;
    color: #464646;
    text-align: center;
    margin: auto;
    margin-bottom: 0px;
    transition: all ease 0.1s;
    animation: dragNDropIconAnimation infinite 1s;
}

.dragNDropText {
    font-size: 1.6rem;
    color: #141414;
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    transition: all ease 0.1s;
}

.containerViewMessage {
    width: 100%;
    height: calc(100vh - 3rem);
    display: grid;
    grid-template-rows: 2fr 8fr;
}

.containerTitleViewMessage {
    display: grid;
    height: calc(15vh - 3rem);
    grid-template-columns: 1fr 1fr 1fr;
}

.titleViewMessage {
    font-size: 2.4rem;
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-bottom: 0.6rem;
    font-family: Georgia, serif;
    font-weight: 400;
}

.dateViewMessage {
    font-size: 1.4rem;
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 5vw;
    margin-bottom: 0.6rem;
    font-family: Georgia, serif;
    font-weight: 400;
}

.containerDocViewerViewMessage {
    width: 90vw;
    max-width: 90vw;
    margin: auto;
    height: 85vh;
}

.legendFooterItemsStatusDashAdvisor {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.expandedColumnDashAdvisor {
    /* border-top: 1px solid rgb(192, 192, 192); */
    text-align: center;
    white-space: nowrap;
    /* font-weight: 450; 
    font-size: 0.8rem; */
}

.expandedColumnDashAdvisorLeft {
    position: relative;
    /* border-top: 1px solid rgb(250, 204, 21); */
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnDashAdvisorLeft::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateY(-100%);
    height: 40%;
    /* border-left: 1px solid rgb(192, 192, 192); Altere a cor e espessura da borda conforme necessário */
}

.expandedColumnDashAdvisorRight {
    position: relative;
    /* border-top: 1px solid rgb(192, 192, 192); */
    text-align: center;
    height: 1.5rem;
    white-space: nowrap;
}

.expandedColumnDashAdvisorRight::before {
    content: "";
    position: absolute;
    right: 0;
    transform: translateY(-100%);
    height: 40%;
    /* border-right: 1px solid rgb(192, 192, 192); Altere a cor e espessura da borda conforme necessário */
}

.subtitleStatusClientOnProductDash {
    /* text-align: center;
    vertical-align: middle; */
}

.permissionsIconDashAdvisor {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

/* Aqui é o CSS da 'dashboardAdvisorStatusFilter */
.containerStatusAbc-Dash {
    padding: 20px 50px;
    background-color: #FFFFFF;
    border-radius: 0.75rem;

    transition: 0.2s ease;
}

.containerStatusConfirm-Dash {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.containerStatusConfirm-Dash {
    display: flex;
    justify-content: space-between;
}

.h2status-Dash {
    color: #D3AA00;
    font-weight: 600;
    font-size: 1.5rem;

    margin-bottom: 10px;
}

.statusConfirm-Dash {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.containerCheckbox-Dash {
    display: flex;
    justify-content: center;
}

.containerSelectAll-Dash {
    display: flex;

    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
}

.btn-primary-Dash {
    background-color: #BBBBBB;
    color: #F6F6F6;
    border: none;
    padding: 10px;
    border-radius: 1rem;

    transition: 0.2s ease;
}

.btn-primary-Dash:hover {
    background-color: #545252;
}

.searchButton {
    margin-top: 0.5rem;
    cursor: pointer;
    padding-right: 1rem;
    transition: 0.2s;
}

.searchButton:hover {
    border-radius: 200px;
    background-color: #F5F5F5;

}

.containerBaterBase{
    display: grid;
    grid-template-columns: 1fr 4fr;
    /* height: 100%; */
    height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    gap: 1rem;
  }
  .containerBaterBaseLeftSide{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    height: calc(88vh - 1rem);
    gap:1rem;

  }

  .buttonBaterBaseSideLeft{
    border: none;
    padding: 10px;
    border-radius: 1rem;
    transition: 0.2s ease;
    cursor: pointer;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #030303;
    width: calc(100% - 1rem);

  }
  .buttonBaterBaseSideLeft:hover{
    background-color: #f5f5f5;
  }
  .containerBaterBaseRightSide{
    display: grid;
    grid-template-rows: 1fr 4fr;
    align-items: center;
    margin-top: 0.15rem;
    /* padding: 20px; */
    border-radius: 10px;
    height: calc(92vh - 1rem);
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
    /* height: calc(90vh - 1rem); Calc com base no numero que está no gap */
    gap:1rem;
  }
  .containerInfosAboutProduct{
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    background-color: #fff;
    height: fit-content;
    padding: 10px;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  }
  .containerInfosAboutProductSecond{
    display: grid;
    background-color: #fff;
    /* width: 80vw; */
    /* height: 100%; */
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .infosProductBaterBase{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .infosProductBaterBase span{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
  }

  .switchDash {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switchDash input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .sliderDash {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .switchDash:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .switchDash {
    background-color: #2196F3;
  }
  
  input:focus + .sliderDash {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .sliderDash:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliderDashs */
  .sliderDash.round {
    border-radius: 34px;
  }
  
  .sliderDash.round:before {
    border-radius: 50%;
  }

  .paperBaterBase{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    width: 30vw;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

/* Aqui é o fim do CSS da 'dashboardAdvisorStatusFilter' */
@keyframes dragNDropIconAnimation {
    0% {
        margin-bottom: 0px;
    }

    50% {
        margin-bottom: 1rem;
    }

    100% {
        margin-bottom: 0px;
    }
}

@media screen and (max-width:1550px) {
    .actionsDash {
        flex-direction: column;
        justify-content: center;
    }

    .dashButton {
        display: flex;
        flex-direction: row;
        max-width: none;
        /* height: 50px; */
        border-radius: 10px;
        /*         padding: 0.5rem 0rem;
 */
        justify-content: center;
        align-items: center;
    }
}