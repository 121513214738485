.clientsIndicationMainContainer {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}
 

.clientsIndicationTableTabs {
    width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: row;
}

#toIndicate {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#toIndicate p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#toIndicate p:hover{
    color: white;
}

#pendingIndicationToApproval {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#pendingIndicationToApproval p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#pendingIndicationToApproval p:hover{
    color: white;
}

#pendingIndicationToApprovalTime {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#pendingIndicationToApprovalTime p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#pendingIndicationToApprovalTime p:hover{
    color: white;
}

#pendingIndicationToApprovalAdmin {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#pendingIndicationToApprovalAdmin p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#pendingIndicationToApprovalAdmin p:hover{
    color: white;
}

#indicationsFinished {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
}

#indicationsFinished p {
    font-size: 0.9rem !important;
    color: white;
    transition: 0.1s;
    border-bottom: 1px solid var(--goldColor);
}

#indicationsFinished p:hover {
 color: white;
}

#myIndicationsHomeLoader {
    height: 15rem;
}

#myApprovalList td {
    cursor: pointer;
    font-size: 0.9rem;
}

#myClientsInputFilter {
    margin-right: 1rem;
}

#tabInactive {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    width: 10rem;
    height: 2rem;
    background: var(--topBarBg);
    transition: 0.1s;
}

#tabInactive p{
    color: #959595;
    font-size: 0.9rem !important;
}

#tabInactive:hover p{
    color: white;
}

.clientsIndicationTableIndicated {
    padding-top: 1.5rem;
    width:  95%;

}

.clientsIndicationTableIndicated table {
    width: 100%;
}

.clientsIndicationTableIndicated table thead tr:nth-child(2) {
    color: white;
    height: 1.5rem;
}

.clientsIndicationTableIndicated table thead tr:first-child th {
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.clientsIndicationTableIndicated table thead tr:nth-child(2) th {
    position: sticky;
    font-size: 1rem !important; 
    font-weight: 300 !important;
    top: 4rem;
    opacity: 1;
    z-index: 100;
    background: var(--tableSecondTh);
}

.clientsIndicationTableIndicated tbody td {
    background: white;
    cursor: default;
    height: 2rem;
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--tableBorder);
}

.clientsIndicationTableIndicated tbody tr:hover {
    background: var(--tableHover);
}

.clientsIndicationBoxModal {
    width: 65rem;
    height: 18rem;
}

.clientAcceptMainContainerBox {
    width: 35rem;
    height: 26rem;
}

.indicatedProfileList {
    overflow: auto;
    max-width: 50rem;
    max-height: 25rem;
}

#theadProfileList {
    width: 100%;
}

#theadProfileList label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#theadProfileList label a {
    font-size: 1rem;
    margin-left: 0.5rem;
}

#closeProfileList {
    align-items: center;
    display: flex;
    right: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    transition: 0.1s;
    margin-right: 0.5rem;
    color: var(--formButtonFontColor)
}

#closeProfileList:hover {
    background: var(--buttonHover);

}

.indicatedProfileList table {
    width: 50rem;
    background: white;
}

.indicatedProfileList table thead tr th{
    background: var(--topBarBg);
    color: white;
    height: 2rem;
    font-size: 1rem;
}

.indicatedProfileList table td {
    border-left: 1px solid var(--tableBorder);
    border-bottom: 1px solid var(--tableBorder);
    font-size: 1rem;
    background: white;
    padding: 0.5rem;
    width: 2rem;
}

.tituloContatoRealizado{
    margin-top: 4rem;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
}

.listaClientes{
    width: 60%;
    text-align: center;
    margin: auto;
    margin-top: 3rem;
}

@media (max-width: 1368px){
    .clientsIndicationMainContainer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
    }
    .clientsIndicationBoxModal {
        width: 55rem;
        height: 18rem;
    }
    .tituloBrokerSegmento{
        position: absolute;
        text-align: center;
        width: 42rem !important;
    }
    .clientsIndicationTableTabs {
        width: 100%;
        height: 2rem;
        display: flex;
        flex-direction: row;
    }

    #pendingIndicationToApproval {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #pendingIndicationToApproval p {
        font-size: 0.9rem !important;
        color: #959595;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #pendingIndicationToApproval p:hover{
        color: white;
    }

    #pendingIndicationToApprovalTime {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #pendingIndicationToApprovalTime p {
        font-size: 0.9rem !important;
        color: #959595;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #pendingIndicationToApprovalTime p:hover{
        color: white;
    }

    #pendingIndicationToApprovalAdmin {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #pendingIndicationToApprovalAdmin p {
        font-size: 0.9rem !important;
        color: #959595;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #pendingIndicationToApprovalAdmin p:hover{
        color: white;
    }

    #indicationsFinished {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
    }

    #indicationsFinished p {
        font-size: 0.9rem !important;
        color: white;
        transition: 0.1s;
        border-bottom: 1px solid var(--goldColor);
    }

    #indicationsFinished p:hover {
     color: white;
    }

    #myIndicationsHomeLoader {
        height: 15rem;
    }

    #myApprovalList td {
        cursor: pointer;
        font-size: 0.9rem;
    }

    #myClientsInputFilter {
        margin-right: 1rem;
    }

    #tabInactive {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem 0.5rem 0 0;
        width: 10rem;
        height: 2rem;
        background: var(--topBarBg);
        transition: 0.1s;
    }

    #tabInactive p{
        color: #959595;
        font-size: 0.9rem !important;
    }

    #tabInactive:hover p{
        color: white;
    }
    
    .clientsIndicationTableIndicated {
        padding-top: 1.5rem;
        width:  95%;

    }

    .clientsIndicationTableIndicated div:nth-child(2) { 
        max-height: 25rem;
        overflow: auto;
        max-height: 25rem;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientsIndicationTableIndicated table {
        width: 100%;
    }
    
    .clientsIndicationTableIndicated table thead tr:nth-child(2) {
        color: white;
        height: 1.5rem;
    }
    
    .clientsIndicationTableIndicated table thead tr:first-child th {
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
        background: var(--topBarBg);
    }
    
    .clientsIndicationTableIndicated table thead tr:nth-child(2) th {
        position: sticky;
        font-size: 1rem !important; 
        font-weight: 300 !important;
        top: 4rem;
        font-size: 0.9rem !important;
        opacity: 1;
        z-index: 100;
        background: var(--tableSecondTh);
    }

    .clientsIndicationTableIndicated tbody td {
        cursor: default;
        height: 2rem;
        font-size: 1rem;
        text-align: center;
        background: white;
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .clientsIndicationTableIndicated tbody tr:hover {
        background: var(--tableHover);
    }

    .clientsIndicationTableIndicated tbody tr:hover {
        background: var(--tableHover);
    }
    
    .clientsIndicationBoxModal {
        width: 55rem;
        height: 18rem;
    }

    .clientsIndicationSucessBoxModal {
        width: 55rem;
        height: 24rem;
    }
    
    .indicatedProfileList {
        overflow: auto;
        max-width: 50rem;
        max-height: 25rem;
    }
    
    #theadProfileList {
        width: 100%;
    }
    
    #theadProfileList label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    #theadProfileList label a {
        font-size: 1rem;
        margin-left: 0.5rem;
    }
    
    #closeProfileList {
        align-items: center;
        display: flex;
        right: 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        transition: 0.1s;
        margin-right: 0.5rem;
        color: var(--formButtonFontColor)
    }
    
    #closeProfileList:hover {
        background: var(--buttonHover);
    
    }
    
    .indicatedProfileList table {
        width: 50rem;
        background: white;
    }
    
    .indicatedProfileList table thead tr th{
        background: var(--topBarBg);
        color: white;
        height: 2rem;
        font-size: 1rem;
    }
    
    .indicatedProfileList table td {
        border-left: 1px solid var(--tableBorder);
        border-bottom: 1px solid var(--tableBorder);
        font-size: 1rem;
        padding: 0.5rem;
        width: 2rem;
    }
        
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}