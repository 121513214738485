.booksConfigurationTableBooksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-height: 35rem;
    overflow-x: hidden;
    background: white;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    
}

.tableBookRegisteredLoader {
    height: 10rem;
}


#tableBooksRegistered {
    width: 100%;
}

#tableBooksRegistered thead tr:first-child th{
    background: var(--topBarBg);
    position: sticky;
    top:0;
}

#tableBooksRegistered thead tr:first-child th div {
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#tableBooksRegistered thead tr:first-child th div a {
    font-size: 1.2rem;
    font-weight: 300;
    color: white;
    margin-left: 2rem;
}

#tableBooksRegistered thead tr:first-child th div label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    gap: 1rem;
}

#tableBooksRegistered thead tr:nth-child(2) th {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    position: sticky;
    top: 3.5rem;
}

#tableBooksRegistered tbody tr td {
    border: 1px solid var(--tableBorder);
    height: 2rem;
    text-align: center;
    font-size: 1rem;
}

#tableBooksRegistered tr td:last-child {
    border-right: none !important ;
}

#tableBooksRegistered tbody tr {
    transition: 0.2s;
}

#tableBooksRegistered tbody tr:hover {
    background: var(--tableHover);
}

#booksEmptyRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 2rem;
}

#booksEmptyRow a {
    font-size: 1rem !important;
    font-weight: 400;
}

#bookLegend {
    opacity: 0;
    position: absolute;
    transform: translate(10px, 0px);
    color: white;
    padding: 0.3rem;
    border-radius: 5px;
    font-size: 0.9rem;
}

#tableBooksRegistered tbody tr td:hover #bookLegend {
    opacity: 1;
    transition: all 0.3s ease;  
    transition-delay: 0.65s;
    transform: translate(10px, 10px);
    background: var(--topBarBg);
}

#booksRegisteredAlterOrder {
    width: 2rem;
    border: 1px solid rgb(200, 200, 200);
    height: 1.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0.3rem;
    border-radius: 3px;
    /* outline: 1px solid rgb(146, 145, 145); */
}

#booksRegisteredAlterOrder:focus {
    outline: none;
    border:1px solid rgb(136, 136, 136);
}

.tableBooksRegisteredOrderBookDiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}


@media(max-width: 1368px){
    .booksConfigurationTableBooksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-height: 30rem;
    overflow-x: hidden;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
        
    }
}
