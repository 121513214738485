.assetSectorCadastration {

    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.assetSectorCadastrationHeader {

    background: var(--headerAllBg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2.8rem;
    width: 100%;
    
}

.assetSectorCadastrationHeader p {

    font-size: 1.2rem;
    margin-left: 1rem;

}

.assetSectorCadastrationHeader button {
    margin-right: 1rem;
}

.assetSectorCadastrationBody {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 50%;
}

.assetSectorCadastrationBody  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.assetSectorCadastrationBody label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}