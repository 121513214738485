.containerSimulacaoTermoCaixa{
  width: calc(100vw - 50px);
  height: calc(100vh - 3rem);
  display: grid;
  grid-template-rows: auto auto auto auto auto auto 1fr auto;
}

.tituloSimulacaoTermoCaixa{
  width: calc(100% - 4rem);
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: 20fr auto 20fr;
}

.containerDivisaoInformacoesGerais{
  display: grid;
  grid-template-columns: 1fr auto 20fr;
  height: 1.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.linhaHorizontal{
  background-color: #838282;
  height: 2px;
  width: 100%;
  margin: auto;
}

.tituloDivisao{
  font-size: 1.2rem;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  font-weight: 400;
}

.containerInformacoesGerais{
  display: grid;
  grid-template-columns: 5fr auto 5fr;
  height: 6rem;
  /* width: 100%;
  margin: auto; */
  padding-left: 1rem;
  /* padding-right: 2rem; */
}

.containerInformacoesGeraisDados{
  display: grid;
  grid-template-columns: auto 15rem 8rem auto 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 0.8rem;
  column-gap: 1.0rem;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 4rem;
  margin-right: 4rem;
}

.divisaoContainerInformacoesGerais{
  background-color: #838282;
  width: 1px;
  height: 100%;
  margin: auto;
}

.nomeDado{
  font-size: 1.4rem;
  height: fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 0;
  white-space: nowrap;
}

.dado{
  font-size: 1.4rem;
  height: fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  white-space: nowrap;
}

.nomeDadoValores{
  font-size: 1.2rem;
  height: fit-content;
  width: fit-content;
  margin: auto;
  margin-right: 0;
  white-space: nowrap;
  column-gap: 1.0rem;
}

.selectCliente{
  width: 100%;
  /* height: fit-content; */
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1rem;
  border: 1px solid #c49e17 !important;
  border-radius: 4px;
  height: fit-content !important;
}
.selectCliente:focus{
  border: 1px solid #ffc800 !important;
  outline: none !important;
}
.selectCliente:hover{
  border: 1px solid #ffc800 !important;
  outline: none !important;
}

.inputPrazo{
  width: 3rem;
  height: 1.2rem;
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c49e17;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}
.inputPrazo:focus{
  border: 1px solid #ffc800;
  outline: none;
}
.inputPrazo:hover{
  border: 1px solid #ffc800;
  outline: none;
}

.inputPorcentagem{
  width: 7rem;
  height: fit-content;
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c49e17;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}

.diasCorridos{
  height: fit-content;
  width: auto;
  margin: auto;
  margin-left: 0.3rem;
  white-space: nowrap;
}

.containerInputPrazo{
  display: grid;
  grid-template-columns: auto 1fr;
}

.inputData{
  height: fit-content;
  width: fit-content;
  padding: 0.2rem;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid #c49e17;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
}
.inputData:focus{
  border: 1px solid #ffc800;
  outline: none;
}
.inputData:hover{
  border: 1px solid #ffc800;
  outline: none;
}

.divisaoContainerInformacoesGeraisDados{
  width: 3rem;
}

.containerInformacoesGeraisValores{
  display: grid;
  grid-template-columns: 1fr auto ;
  grid-template-rows: 1fr 1fr;
  column-gap: 0.6rem;
  row-gap: 0.1rem;
  width: fit-content;
  margin-right: auto;
  margin-left: 4rem;
}

.divisaoContainerInformacoesGeraisGridValores{
  display: grid;
  grid-template-columns: auto auto;
}

.porcentagemTermoCaixa{
  margin: auto;
  font-size: 1.2rem;
}

.containerOperacaoEmBolsa{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-template-rows: auto 1fr;
  height: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.colunaOperacaoEmBolsa{
  font-size: 1.4rem;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 0.6rem;
  white-space: nowrap;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.dadoOperacaoEmBolsa{
  font-size: 1.2rem;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 0.6rem;
}

.campoMonetarioProps{
  border-color: #c49e17 !important;
}


.campoMonetario{
  background-color: white;
  width: 68%;
  height: fit-content;
  border-radius: 4px;
}

.campoTextoAtivo{
  background-color: white;
  font-size: 1.2rem;
  width: 60%;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #c49e17;
  margin: auto;
  padding: 0.2rem;
}
.campoTextoAtivo:focus{
  border: 1px solid #ffc800;
  outline: none;
}
.campoTextoAtivo:hover{
  border: 1px solid #ffc800;
  outline: none;
}

.containerDivisaoFluxoCustos{
  display: grid;
  grid-template-columns: 2fr auto 2fr auto 1fr;
  height: 1.6rem;
  margin-top: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.containerFluxoCustos{
  display: grid;
  grid-template-columns: 5fr auto 3fr;
  height: 100%;
  width: calc(98% - 4rem);
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  column-gap: 1rem;
}

.divisaoContainerFluxoCustos{
  background-color: #838282;
  width: 1px;
  height: 90%;
  margin: auto;
}

.containerSolicitar{
  height: 5rem;
  width: 100%;
  background-color: white;
  display: grid;
}

.containerFluxo{
  display: grid;
  grid-template-rows: 3fr 4fr;
  row-gap: 0.6rem;
  padding-bottom: 1rem;
}

.containerCustos{
  padding-bottom: 1rem;
}

.containerInternoFluxoDeCaixa{
  display: grid;
  grid-template-rows: auto 1fr;
}

.nomeTabelaFluxoDeCaixa{
  background: linear-gradient(0deg, #cfcfcf 50%, transparent 50%);
  font-weight: 500;
  padding-left: 1rem;
  font-size: 1rem;
  height: 1.4rem;
}

.nomeTabelaFluxoDeCaixaVazio{
  background: linear-gradient(0deg, #cfcfcf 50%, transparent 50%);
  height: 1.4rem;
  width: 100%;
}

.containerTabelaInicio{
  background-color: #cfcfcf;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.containerTabelaFim{
  background-color: #cfcfcf;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.tabelaInicio{
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-template-rows: auto auto auto auto auto;
  height: 100%;
}

.tabelaFim{
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-template-rows: auto auto auto auto auto;
  height: 100%;
}

.nomeColunaTabela{
  width: 100%;
  height: fit-content;
  margin: auto;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}

.dadoTabela{
  width: 100%;
  height: fit-content;
  margin: auto;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
}

.linhaHorizontalTabela{
  height: 2px;
  width: 80%;
  margin: auto;
  background-color: #838282;
}

.dadoTabelaTotal{
  width: 100%;
  height: fit-content;
  margin: auto;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: #775D01;
}

.linhaHorizontalTabelaTotal{
  height: 2px;
  width: 80%;
  margin: auto;
  background-color: #775D01;
}

.containerTabelasCustos{
  display: grid;
  grid-template-rows: 3fr auto 1fr;
  width: 100%;
  height: calc(100% - 1.4rem);
}

.containerTabelaDescricao{
  background-color: #cfcfcf;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto auto auto auto auto;
  padding-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.containerTabelaTaxaEspecifica{
  background-color: #D3C48F;
}

.tituloTaxaEspecifica{
  background: linear-gradient(0deg, #D3C48F 50%, #cfcfcf 50%);
  font-weight: 400;
  font-size: 1.2rem;
  padding-left: 0.9rem;
  padding-top: 0.2rem;
  color: #7a7a7a;
}

.containerItensTabelaTaxaEspecifica{
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr 1fr;
  width: fit-content;
  max-width: calc(100% - 4rem);
  height: 90%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.1rem;
}

.barraLateralTaxaEspecifica{
  width: 2px;
  height: 96%;
  background-color: #c49e17;
  grid-row-start: 1;
  grid-row-end: 3;
}

.dadoTaxaEspecifica{
  width: fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1rem;
  font-weight: 500;
  color: #775D01;
  margin-left: 0.8rem;
}

.porcentagemTaxaEspecifica{
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.2rem;
  margin-left: 0.6rem;
}

.dadoTaxaEspecificaSemInput{
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.6rem;
  width: 100%;
}

.botaoSolicitar{
  width: 16%;
  height: 2.4rem;
  margin: auto;
  margin-bottom: max(auto,0.2rem);
  font-size: 1.2rem;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 2px;
  transition: all ease-in-out 0.1s;
}

.botaoSolicitar:hover{
  background-color: #a88812;
  border: 1px solid #a88812;
  box-shadow: 0px 0px 10px #ffc800;
}

.botaoSolicitar2{
  width: 16%;
  height: 2.4rem;
  margin: auto;
  margin-bottom: max(auto,0.2rem);
  font-size: 1.2rem;
  border: 1px solid #53910c;
  color: #fff;
  background-color: #138840;
  border-radius: 2px;
  transition: all ease-in-out 0.1s;
}

.botaoSolicitar2:hover{
  background-color: #19af52;;
  border: 1px solid #64a51a;
  box-shadow: 0px 0px 10px #498109;
}

.campoPorcentagemProps{
  border-color: #c49e17 !important;
  font-size: 0.8rem !important;

}

/* .MuiOutlinedInput-input{
  font-size: 0.8rem !important;

} */

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  /* text-align: center; */
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 6px;
  margin-top: -2rem;
  margin-left: -5rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Tooltip text 2 (menos margin-left)*/
.tooltip .tooltiptext2 {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  height: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  border-radius: 6px;
  margin-top: -1.6rem;
  margin-left: -0.8rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptext2 {
  visibility: visible;
}

.gapPorcentagem{
  margin-right: 1rem;
}

.textConfirmation{
  color: red;
  font-size: 1.5rem;
  text-align: center;
}

.divButtonImport{
  display: flex;
  justify-content: flex-end;
  align-self: center ;
}

.informationsImportButton{
  width: 15rem;
  align-items: center;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  height: 1.8rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);
}

.informationsDownloadLayoutButton{
  width: 10rem;
  align-items: center;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  height: 1.8rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);
  margin-right: 3rem;

}