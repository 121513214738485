.companiesCadastrationLoader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40rem;
    width: 80rem;
    background: white;
}

.companiesCadastrationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.companiesHeader {
    width: 100%;
    color: var(--headerFontColor);
    font-size: 1.3rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    background: var(--headerAllBg);
}

.companiesHeader button{
    background: transparent;
    color: var(--formBorder);
    border: 0.1rem solid var(--formBorder);
    width: 2rem;
    height: 1.8rem;
    border-radius: 50%;
    font-size: 1rem;
    margin-right: 1rem;
}

.companiesHeader p {
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
    
}

.companiesBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
   
}

.companiesBody form {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 49px);
}

.companiesBody form label {
    width: 70%;
}

.companiesBody form .labelLocation {

    align-items: flex-start;
    justify-content: space-between;
    font-weight: bold;  
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem

}

.buttonDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.companiesBody button:focus {
    background: var(--buttonHover);
    font-weight: 400;
    border: 2px solid var(--goldColor);
    color: white;
}

.companiesBody .MuiInputBase-root {
    width: auto !important;
}

.companiesBody .MuiFormGroup-root {
    display: flex !important;
    flex-wrap: nowrap !important;
}

.companiesBody .MuiRadio-colorSecondary.Mui-checked {
    color: #c39e16 !important;
}

.companiesCadastrationHide { 

    display: none;
}

@media (max-width: 1368px){

    .companiesCadastrationLoader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 35rem;
        width: 50rem;
        background: white;
    }
    
    .companiesCadastrationContainer {
        display: flex;
        flex-direction: column;
        background: white;
    }

    .companiesHeader {
        width: 100%;
        color: var(--headerFontColor);
        font-size: 1.2rem;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 4rem;
        background: var(--headerAllBg);
    }

    .companiesBody {
        width: auto;
    }

    .companiesBody form {
        width: 100%;
        margin-top: 2rem;
    }

    .companiesBody form label {
        width: 70%;
    }

    .companiesBody button:focus {
        background: var(--buttonHover);
        font-weight: 400;
        border: 2px solid var(--goldColor);
        color: white;
    }

    .companiesBody .MuiInputBase-root {
        width: auto !important;
    }

    .companiesBody .MuiFormGroup-root {
        display: flex !important;
        flex-wrap: nowrap !important;
    }

    .companiesBody .MuiRadio-colorSecondary.Mui-checked {
        color: #c39e16 !important;
    }
}
