.faqHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--headerAllBg);
  width: 100%;
  height:6rem;
  color: var(--headerFontColor);
  font-size: 1.3rem;
  box-shadow: 0px 0px 20px -8px #000;
}

.faqHeader div{
  padding-right: 2rem;
}

.faqHeader div button{
  background-color: var(--formBorder);
  border: 1px solid var(--formBorder);
  width: 8rem;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  gap: 0.3rem
}

.faqHeader div button:hover{
  background-color: var(--headerAllBg);
  border: 1px solid var(--formBorder);
  color: var(--formBorder);
}

.faqHeader p{
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
}

.faqContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}

.accordion{
  width: 100%;
  height: calc(100vh - 12rem);
  overflow-y: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tab {
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 60%;
  box-shadow: 0px 0px 5px 0px var(--headerFontColor);
  margin-bottom: 1rem;
}


/* (B) HIDE CHECKBOX */
.tab input { display: none; }

/* (C) TAB LABEL */
.tab label {
  display: block;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  background: var(--headerFontColor);
  cursor: pointer;
  font-size: 1rem;
  transition: .2s;
}
/* (D) TAB CONTENT */
.tab .tabContent {
  background: var(--headerAllBg);
  overflow: hidden;
  transition: all 0s;
  max-height: 0;
  font-size: 1rem;
}

.tab label:hover{
  background-color: #646464;
}

.tab .tabContent p { padding: 10px; }

/* (E) OPEN TAB ON CHECKED */
.tab input:checked ~ .tabContent { max-height: 100vh; }

/* (F) EXTRA - ADD ARROW INDICATOR */
.tab label::after {
  position: relative;
  right: 10px;
  top: 10px;
  display: block;
  transition: all 0.2s;
}

.tab input:checked ~ label::after { transform: rotate(90deg); }

.tab input:link { background-color: pink !important; color: red }

.FaqAlignCenter{
 
  margin-top: 5rem;
  text-align: center;
}
.accordion {
  font-size: 1.2rem !important;
  background: white;
}

.tabContent{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabContent a {
  transition: .2s;
  cursor: pointer;
}
.tabContent a:hover{
  color: var(--headerFontColor)
}

.tabContent div{
  margin-right: 1rem;
  width: 4rem;
  display: flex;
  gap: 10px;
}

.faqIconsEffect{
  transition: .2s;
  cursor: pointer;
}

.faqIconsEffect:hover{
  color: var(--headerFontColor)
}

.ldsellipsis {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}
.ldsellipsis div {
  position: absolute;
  top: 0.4rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--headerFontColor);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}