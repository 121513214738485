.investmentEditScenariosWindow {
    background: white;
    width: 90vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleModalInvestmentEditScenarios{
    background: var(--headerAllBg);
    height: 6rem;
    align-items: center;
    text-align: center;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
.fixedIncomeProductsAddInformation{
    background-color: #F5F5F5;
    margin: 20px;
    border-radius: 10px;
}
.investmentsEditBox:focus{
    border-color: #BFBFBF ;
}
.investmentsEditBox{   
    padding: 0.5rem;
    padding-left: 1rem;
}
.fixedIncomeProductsContainer .fixedIncomeProductSubContainer{
    background-color: white;
    display: flex;
    flex-direction: row;
}

.fixedIncomeDataTextBoxes{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: 1rem;
}
.fixedIncomeDataTextBoxesEditScenarios{
    
    padding: 20px;
    /* display: grid; */
    grid-template-columns: 100%;
    width: 100%;
    height: 65vh;
    border-radius: 10px;
    margin-top: 1rem;
    background-color: #F4F4F4;
    overflow: auto;
}

.fixedIncomeDataTextBoxesChild{
    margin: 1rem;
    /* height: 15vh; */
}

.fixedIncomeProductsSeparator{
    margin: 2rem 50px
}
.editScenariosHover{
    transition: color 0.2s ease;
    height: 100%;
}
.editScenariosHover:hover{
    color: #D4AC00;
    
}