.assetClassesEditBoxContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetClassesEditBoxHeader {
    background: var(--headerAllBg);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.assetClassesEditBoxHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.assetClassesEditBoxHeader button {
    margin-right: 1rem;
}

.assetClassesEditBoxBody {

    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    align-items: center;
}

.assetClassesEditBoxEdition {
    height: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.assetClassesEditBoxEdition p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.assetClassesEditBoxLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}