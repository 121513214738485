.usersTableContainer {
    width: 100%;
    overflow: auto;
    max-height: 45rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

#usersTableNameAndTotalUsers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap:0.3rem;
}

#usersTableNameAndTotalUsers a:first-child{
    font-size: 1.2rem;
    font-weight: 300;
}

#usersTableNameAndTotalUsers a:nth-child(2) {
    font-size: 0.9rem;
}

#userCadastration:focus{
    background: var(--goldColor);
    color: black;
}

.theadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.theadFirst a {
    margin-left: 2rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.theadFirst button{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
}
.theadFirst button:hover {
    background: var(--formBorder);
    color: black;
}


.theadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
    gap: 1rem;
}

.theadFirst label a{
    margin-right: 1rem;
    font-weight: 200;
}

.theadFirst label input {
    padding-left: 0.5rem;
    height: 1.8rem;
    width: 10rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

.theadFirst label input::placeholder{
    font-size: 0.9rem;
}

.usersTableContainer table{
    width: 100%;
    font-size: 1rem;
    
}

.usersTableContainer table tbody {
    max-height: 35rem;
    background: white;
}

.usersTableContainer table tbody tr {
    cursor: pointer;
}

.usersMyUser {
    font-weight: 400;
    
}

.usersActiveTrLoader {
        
    height: 30rem;
    
}   
.usersTableContainer table thead tr:first-child th{
    height: 5rem;
    border-left: none;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
    width: 90rem;
}


.usersTableContainer table thead tr:nth-child(2) > th  {
    height: 1.5rem;
    font-weight: 300 !important;
}

.usersTableContainer table thead tr:nth-child(2) th {
    background: var(--tableSecondTHGray);
    color: white;        
}


.usersTableContainer thead tr:first-child th {
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
}

.usersTableContainer thead tr:nth-child(2) th {
    position: sticky;
    top: 4rem;
    z-index: 100;
}

.usersTableContainer table thead tr:first-child th{
    height: 4rem;
    border-right: none;
    background: var(--topBarBg);
    color: white;
    font-size: 1.8rem;
    font-weight: 200;
}


.usersTableContainer table tbody tr {
    border-bottom: 1px solid var(--tableBorder);
}

.usersTableContainer table tbody tr:last-child{
    border-bottom: none;
}

.usersTableContainer table tbody td {
    padding: 0;
    height: 2.5rem;
    border-right: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    width: 10rem;
    font-size: 1rem;
}
.usersTableContainer table tbody td:nth-child(1){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(4){
    width: 5rem;
}


.usersTableContainer table tbody td:nth-child(6){
    width: 5rem;
}

.usersTableContainer table tbody td:nth-child(7){
    width: 4rem;
}

.usersTableContainer table tbody td:nth-child(8){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(9){
    width: 2rem;
}

.usersTableContainer table tbody td:nth-child(10){
    width: 2rem;
}


.usersTableContainer table tbody td:last-child{
    border-right: none;
}

.ellipsisTd {
    position: relative;
    max-width: 10rem;
    overflow: hidden;
    margin-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

.usersTableContainer table tbody td input{
  height: 1.5rem;
  padding: 0;
  padding-left: 0.3rem;
  width: 6rem;
  margin-right: 0.5rem;
}

.usersTableContainer table tbody td a {
    margin-right: 0.5rem;
}

.usersTableContainer table tbody tr:hover {
    background: var(--tableHover) !important;
}

.usersEditShow td {
   
    color: white;
}

.usersEditShow th {
    background: var(--tableSecondTh);

}

.usersEditShow .divForShowItemn {
    display: flex;
    width: 100%;
    height: 4rem;
}

.editDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.editDiv label {
    height: 2.5rem;
    width: auto;
}

.iconsOptions {
    width: 5rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
}

.editIcon{
    width: 3rem;
}

.userInactiveAction {
    filter: opacity(0.3)
}    


@media(max-width:1368px){
    
    .usersTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .theadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .theadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .theadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .theadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .theadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .theadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .theadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .theadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersTableContainer table tbody {
        max-height: 35rem;
        background: white;
    }
    
    .usersTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersActiveTrLoader {
        
        height: 20rem;
        
    }

    .usersTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersTableContainer thead tr:first-child th {
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersTableContainer thead tr:nth-child(2) th {
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }    
    
    .usersTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }

    #usersHideLoadingScroll {
        display: none;
    }

    #usersShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        filter: opacity(0.3)
    }  
    
    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }

}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}