

.fiisOfferingsDetailsContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}

#offeringsDetailsObservation {
    width: 100%;
}

.fiisOfferingsDetailsLoader {
    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.fiisOfferingsDetailsHeader {
    background: var(--headerAllBg);
    height: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.fiisOfferingsDetailsHeader p {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1rem;
}

.fiisOfferingsDetailsHeader button {
    margin-right: 1rem;
}

.offeringsButton:hover {
    background: var(--goldColor);
    color: black;
}

.fiisOfferingsDetailsHeaderCompanyName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fiisOfferingsDetailsHeaderCompanyName p {
    font-size: 1.2rem;
    color: black;
}

#fiisOfferingsEditor {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

#offeringsPdfModel {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.fiisOfferingsDetailsPreviewTextContent {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.fiisOfferingsDetailsBody {

    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    font-size: 1.3rem;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.fiisOfferingsDetailsBody:hover  {
    border: 1px solid rgba(45, 48, 77, 0.3);
}

.fiisOfferingsDetailsBody p {

    font-size: 1.1rem;
}

.fiisOfferingsDetailsFooter {
    transition: 0.2s;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fiisOfferingsDetailsFooter button:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    background: var(--buttonHover);
    color: white;
    height: 2rem;
    width: 10rem;
    transition: 0.2s;
}

.fiisOfferingsDetailsFooter button:first-child:hover {
    background-color: white;
    color: var(--formButtonFontColor)
}

.fiisOfferingsDetailsFooter .blackButton {
    align-items: center;
    background: var(--topBarBg);
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 10rem;
    transition: 0.1s;
    color: white;
    transition: 0.2s;
}

#errorMsgFields  {
    font-size: 1rem;
}

.fiisOfferingsDetailsFooter .blackButton:hover {
    color: var(--topBarBg);
    background: white;
    border: 1px solid var(--topBarBg);
}

.fiisOfferingsDetailsReservation {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transition: 0.2s;

}

.fiisOfferingsReservationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    gap: 1rem;
}

.fiisOfferingsReservationCardHeader {
    display: grid;
    width: 100%;
    gap: 1rem;
}

.fiisOfferingsDetailsConfirmButton {
    border: none;
    width: 10rem;
    height: 2.5rem;
    background: var(--goldColor);
    font-size: 1rem;
    color: white;
    font-weight: 400;
}

.fiisOfferingsReservationLabel {
    width: 100%;
}



.fiisOfferingsDetailsTextContent,
.fiisOfferingsDetailsTextContent {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    font-size: 1rem !important;
}

.fiisOfferingsDetailsTextContent ul li{
   list-style: inside !important;
   margin-left: 1rem;
}

.fiisOfferingsDetailsTextContent ol li{
    list-style: decimal !important;
    margin-left: 2rem;
}