.containerLongShort{
    height: calc(100% - 4rem);
  }

  .longShortCardInfos{
    margin-left: 2rem;
    margin-right: 2rem;
    min-height: 10rem;
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.2rem;
    font-size: 1.1rem;
    gap: 0.5rem;
  }

  .editReportLongShort{
    min-height: 10rem;
    font-size: 1.1rem;
    display: grid;
    grid-template-rows: 2rem auto 2rem;
    text-align: center;
  }

  .itemlongShortCardInfos{
    display: grid;
    grid-template-columns: 20rem auto;
  }
  
  .buttonChangeReport{
    margin-left: auto;
    margin-right: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    width: fit-content;
  }

  .buttonChangeReportCancel{
    margin-left: auto;
    margin-right: auto;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    color: #fff;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    width: fit-content;
    background-color: #e9655b;
    border: 1px solid #e9655b;
  }

  .buttonChangeReportCancel:hover{
    background-color: #ee7971;
    border: 1px solid #ee7971;
    box-shadow: 0px 0px 10px #ee7971;
  }
  

  .buttonChangeReport:hover{
    background-color: #c7a836;
    border: 1px solid #c7a836;
    box-shadow: 0px 0px 10px #c7a836;
  }

  .productCardLongShort{
    background-color: #ebebe9;
    min-height: 6rem;
    height: fit-content;
    width: auto;
    margin: 0.5rem;
    margin-right: 1rem;
    border-radius: 5px;
    padding: 0.5rem;
  }

  .radioSelectContainerColumnLineLongShort{
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}

.radioSelectContainerColumnLineLongShort div{
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

.editReportLongShortBody{
  display: grid;
  grid-template-columns: 10rem 10rem 20rem;
  width: fit-content;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.InputToFormat{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.InputToFormat div{
  position: relative;
  flex-grow: 1;
  /* margin: 1rem; */
}

.InputToFormat div input{
  border: 0;
  border: 1px solid lightgrey;
  height: 2.2rem;
  border: 1px solid var(--formBorder);
  border-radius: 0.3rem !important;
  outline: none;
  min-width: 7rem;
  /* width: 100%; */
  font-size: 16px;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -webkit-appearance:none;
  border-radius: 0;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.InputToFormatLS div input:focus{
  box-shadow: inset 0 0 0 1px var(--formBorder);
}

.InputToFormatLS div input::placeholder{
  color:transparent;
}

.InputToFormatLS div label{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.6rem;
  margin-left: 0.3rem;
  color: #8f7f7f;
  font-size: 1rem;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  font-weight: 400;
}

.InputToFormatLS div input:required:invalid + label:before{
  content: '*';
}
.InputToFormatLS div input:focus + label,
.InputToFormatLS div input:not(:placeholder-shown) + label{
  font-size: 11px;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  color: #3c7adb;
  background-color: white;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.fileInputAdjustLS input[type='file'] {
  display: none !important;
  height: 2.2rem;
}

/* Aparência que terá o seletor de arquivo */
.fileInputAdjustLS label {
  background-color: #3498db;
  border: 1px solid #3498db;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 5px;
  font-size: 1rem;
  padding: 6px 20px;
  font-weight: 400;
  transition: .2s;
}

/* Aparência que terá o seletor de arquivo */
.fileInputAdjustLS label:hover {
  background-color: #fff;
  height: 2.2rem;
  border-radius: 5px;
  color: #3498db;
  cursor: pointer;
  margin: 5px;
  padding: 6px 20px
}

.inputLinkReport{
  height: 1.8rem;
  width: 11rem;
  border: 1px solid #3498db;
  border-radius: 5px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.buttonEditReportBox{
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;
  margin-left: auto;
  margin-right: auto;
}

.link-like {
  cursor: pointer;
  /* text-decoration: underline; */
  color: rgb(34, 34, 233);
}

.buttonActivateLongShort{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  width: fit-content;
}

.buttonActivateLongShort:hover{
  background-color: #c7a836;
  border: 1px solid #c7a836;
  box-shadow: 0px 0px 10px #c7a836;
}

.buttonDeactivateLongShort{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  border: 1px solid #e9655b;
  color: #fff;
  background-color: #e9655b;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  width: fit-content;
}

.buttonDeactivateLongShort:hover{
  background-color: #ee7971;
  border: 1px solid #ee7971;
  box-shadow: 0px 0px 10px #ee7971;
}

.buttonEditLongShort{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  border: 1px solid #3eb992;
  color: #fff;
  background-color: #3eb992;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  width: fit-content;
}

.buttonEditLongShort:hover{
  background-color: #4fbc99;
  border: 1px solid #4fbc99;
  box-shadow: 0px 0px 10px #4fbc99;
}

.listOperationLongShort{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  height: 80vh;
  overflow-x: auto;
}

.containerListLongShort{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

.containerLongShorts{
  background-color: white;
}

.containerGeralLongShorts{
  width: 70vw;
  margin: auto;
  height: 95vh;
}

.boxTablesAndButtons{
  display: grid;
  grid-template-columns: auto 20rem;
}

.BoxButtonsLongShort{
  margin: auto;
}