.assetSectorMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetSectorTableDiv {
    display: flex;
    width: 95%;
    flex-direction: column;
    overflow-x: hidden;
    margin-top: 2rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}