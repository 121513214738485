.cubastarFundsTableAndFooterContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.cubastarFundsInformationFiltered {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.cubastarFundsOrdersLoadingPeriods {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
}
.cubastar-table{
  border-radius: 10px;
}
.cubastarFundsTableContainer {
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  width: 95%;
  overflow-x: auto;
  overflow-y: scroll;
  margin-top: 1rem;
  border-radius: 0.5rem 0 0 0;
  -webkit-box-shadow: -7px 0px 9px -7px #000000;
  box-shadow: -2px 2px 20px -7px #000000;
}

.cubastarFundsTableContainer tbody td {
  font-size: 1rem;
  cursor: default !important;
}

.cubastarFundsTableContainer tbody td:nth-child(2) {
  color: rgb(116, 116, 116);
  font-size: 1rem;
  width: 2rem;
}

.cubastarFundsTableContainer tbody td:nth-child(2):hover {
  font-size: 1.1rem;
}

.cubastarFundsOrdersSelectionFilter {
  background: var(--tableSecondTh);
  color: white;
  font-size: 0.9rem;
  max-width: 8rem;
}

#cubastarFundsLoadingTd {
  height: 10rem;
}

.reservationOrdersTable {
  border-collapse: collapse;
  max-height: 100%;
}

.reservationOrdersTable thead {
  position: sticky;
  top: 0px;
}

.reservationOrdersTable thead tr th {
  animation: scroll 0.5s ease-out;
  transition: 0.2s;
}

.cubastarFundsOrdersTheadDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cubastarFundsOrdersTheadDiv p {
  color: white;
  margin-left: 1rem;
}

.cubastarFundsOrdersTheadRightGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reservationOrdersTable thead tr:first-child th {
  background: var(--topBarBg);
  height: 5rem;
  position: sticky;
  overflow: hidden;
  border-radius: 0.5rem 0 0 0;
  top: 0px;
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
  transition: 0.2s;
  z-index: 10;
  animation: scroll 0.5s ease-out;
}

#cubastarFundsOrdersMultipleActions {
  height: 1.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--goldColor);
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;
}

#cubastarFundsOrdersMultipleActions:hover {
  background: var(--goldColor);
  color: black;
}

.cubastarFundsOrdersMultipleActionsDiv {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 1.1rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  color: var(--goldColor);
}

.cubastarFundsOrdersMultipleActionsDiv p {
  color: var(--goldColor);
  margin-left: 0;
}

#cubastarFundsOrdersMultipleActions div {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

#cubastarFundsOrdersTheadDateSelection {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.cubastarFundsOrdersSelectionAll {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
}

@keyframes scroll {
  from {
    transform: tranlateY(-100%);
  }

  to {
    transform: tranlateY(0%);
  }
}

.reservationOrdersTable thead tr:nth-child(2) th {
  background: #3E3E3E;
  height: 1.5rem;
  position: sticky;
  overflow: hidden;
  margin-top: 1rem;
  top: 5rem;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  z-index: 10;
  transition: 0.9s;
  animation: scroll 0.5s ease-out;
}

.reservationOrdersTable tbody {
  overflow: auto;
}

.reservationOrdersTable td:nth-child(1) {
  width: 3rem !important;
}

.reservationOrdersTable td {
  box-sizing: border-box;
  border: 1px solid var(--tableBorder);
  text-align: center;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  text-overflow: ellipsis;
  cursor: pointer !important;
  overflow: hidden;
  white-space: nowrap;
  max-width: 8rem;
  height: 2rem;
  font-size: 1rem;
}

.reservationOrdersTable td:nth-child(2) {
  max-width: 20rem !important;
}

.reservationOrdersTable tr {
  transition: 0.1s;
}

.reservationOrdersTable tr {
  background: #FFF;
}

.reservationOrdersTable tr:hover {
  background: var(--tableHover) !important;
}

.reservationOrdersTable tr:nth-child(even) {
  background: var(--tableBorderEven);
}

.cubastarFundsOrderDetails label {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.cubastarFundsOrderDetails label a:first-child {
  font-weight: 400;
}

#cubastarFundsOrderDetailsTable {
  margin-top: 1.5rem;
}

#cubastarFundsOrderDetailsRow {
  transition: all ease 0.2s;
}

.cubastarFundsOrderDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  font-size: 1.2rem;
  align-items: center;
  background: #3e3e3e;
  height: 3rem;
  transition: 0.2s;
}

.cubastarFundsOrderDetails a {
  color: white;
}

.cubastarFundsOrderDetails:hover {
  background: #444444;
}


@media (max-width: 1366px) {
  .cubastarFundsOrdersSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.8rem;
    max-width: 6rem;
  }

  .cubastarFundsTableContainer tbody td {
    font-size: 0.9rem;
    cursor: default !important;
  }
}

::-webkit-scrollbar-corner {
  background: rgb(35, 37, 7);
}

.cubastar-table-label {
  text-align: center;
  background-color: rgb(9 3 59);
  color: #c49e17;
  font-weight: 600px;
  padding: 0.3rem;
  border-radius: 10px 10px 0px 0px;
  cursor: initial !important;
}