.dashboardAdvisorCalendar{
  background: white;
  width: 76vw;
  height: 90vh;
  display: grid;
  border-radius: 1rem;
  /* flex-direction: column;
  align-items: center;
  place-content: center; */
}

.titleModalCalendar{
  background: var(--headerAllBg);
  height: 6rem;
  align-items: center;
  font-size: xx-large;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-left: 1rem; */
  margin-right: 1rem;
  width: 60rem;
}


.headProdutos{
  margin: 1rem;
  overflow: auto;
  /* width: 60rem; */
  text-align: -webkit-center;
}

.listCalendar{
  display: grid;
  grid-template-columns: 2fr 20rem 2fr 1fr;
  column-gap: 1rem;
  margin: 1rem;
}

.listCalendarSettlement{
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1rem;
}

.listCalendar th{
  font-weight: 300;
  font-size: large;
}

.listCalendarSettlement th{
  font-weight: 300;
  font-size: large;
}

.arrowCalendar{
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: transparent;
  border: none; 
}
  