   
    .usersInactiveTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 45rem;
        background: white;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }

    .usersInactiveEmptyList {
        height: 2rem;
        font-size: 1.2rem;

    }
    
    .usersInactiveTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .usersInactiveTheadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .usersInactiveTheadFirst button{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .usersInactiveTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .usersInactiveTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .usersInactiveTheadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .usersInactiveTheadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .usersInactiveTheadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersInactiveTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersInactiveTableContainer table tbody {
        max-height: 35rem;
    }
    
    .usersInactiveTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersInactiveTrLoader {
        
        height: 30rem;
        
    }

    .usersInactiveTableContainer table thead tr:first-child th{
        height: 5rem;
        border-left: 1px solid var(--tableBorder);
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersInactiveTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersInactiveTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersInactiveTableContainer thead tr:first-child th {
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersInactiveTableContainer thead tr:nth-child(2) th {
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersInactiveTableContainer table thead tr:first-child th{
        height: 4rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersInactiveTableContainer table tbody tr {
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersInactiveTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersInactiveTableContainer table tbody td {
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
    }
    .usersInactiveTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersInactiveTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersInactiveTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersInactiveTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;
    }    
    
    .usersInactiveTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
        
    .usersEditShow td {
       
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        filter: opacity(0.3)
    }    


@media(max-width:1368px){
    
    .usersInactiveTableContainer {
        width: 100%;
        overflow: auto;
        max-height: 30rem;
        background: white;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .usersInactiveTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }
    
    .usersInactiveTheadFirst a {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 300;
    }
    
    .usersInactiveTheadFirst button{
        margin-left: 2rem;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 0.9rem;
        width: 8rem;
        height: 1.8rem;
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    .usersInactiveTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    
    .usersInactiveTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    .usersInactiveTheadFirst label a{
        margin-right: 1rem;
        font-weight: 200;
    }
    
    .usersInactiveTheadFirst label input {
        padding-left: 0.5rem;
        height: 1.8rem;
        width: 10rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    .usersInactiveTheadFirst label input::placeholder{
        font-size: 0.9rem;
    }
    
    .usersInactiveTableContainer table{
        width: 100%;
        font-size: 1rem;
        
    }
    
    .usersInactiveTableContainer table tbody {
        max-height: 35rem;
    }
    
    .usersInactiveTableContainer table tbody tr {
        cursor: pointer;
    }

    .usersInactiveTrLoader {
        
        height: 20rem;
        
    }

    .usersInactiveTableContainer table thead tr:first-child th{
        height: 5rem;
        border-right: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
        width: 90rem;
    }


    .usersInactiveTableContainer table thead tr:nth-child(2) > th  {
        height: 1.5rem;
        font-weight: 300 !important;
    }
    
    .usersInactiveTableContainer table thead tr:nth-child(2) th {
        background: var(--tableSecondTHGray);
        color: white;        
    }
    

    .usersInactiveTableContainer thead tr:first-child th {
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
    }
    
    .usersInactiveTableContainer thead tr:nth-child(2) th {
        position: sticky;
        top: 4rem;
        z-index: 100;
    }
    
    .usersInactiveTableContainer table thead tr:first-child th{
        height: 4rem;
        border: none;
        background: var(--topBarBg);
        color: white;
        font-size: 1.8rem;
        font-weight: 200;
    }

    
    .usersInactiveTableContainer table tbody tr {
        cursor: default !important;
        border-bottom: 1px solid var(--tableBorder);
    }
    
    .usersInactiveTableContainer table tbody tr:last-child{
        border-bottom: none;
    }
    
    .usersInactiveTableContainer table tbody td {
        cursor: default !important;
        padding: 0;
        height: 2.5rem;
        border-right: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        width: 10rem;
        font-size: 1rem;
    }
    .usersInactiveTableContainer table tbody td:nth-child(1){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(4){
        width: 5rem;
    }


    .usersInactiveTableContainer table tbody td:nth-child(6){
        width: 5rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(7){
        width: 4rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(8){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(9){
        width: 2rem;
    }

    .usersInactiveTableContainer table tbody td:nth-child(10){
        width: 2rem;
    }
   

    .usersInactiveTableContainer table tbody td:last-child{
        border-right: none;
    }
    
    .ellipsisTd {
        position: relative;
        max-width: 10rem;
        overflow: hidden;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .usersInactiveTableContainer .ellipsisTd span {     
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;
    }    
    
    .usersInactiveTableContainer table tbody td input{
      height: 1.5rem;
      padding: 0;
      padding-left: 0.3rem;
      width: 6rem;
      margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody td a {
        margin-right: 0.5rem;
    }
    
    .usersInactiveTableContainer table tbody tr:hover {
        background: var(--tableHover) !important;
    }
    
    .usersEditShow td {
       
        color: white;
    }
    
    .usersEditShow th {
        background: var(--tableSecondTh);
    
    }
    
    .usersEditShow .divForShowItemn {
        display: flex;
        width: 100%;
        height: 4rem;
    }
    
    .editDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .editDiv label {
        height: 2.5rem;
        width: auto;
    }
    
    .iconsOptions {
        width: 5rem;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
    }
    
    .editIcon{
        width: 3rem;
    }
    
    .userInactiveAction {
        filter: opacity(0.3)
    }    

}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}