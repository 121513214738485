#tradeIdeasTextContainer {
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#tradeIdeasTextHeader {

    height: 3rem;
    background-color: var(--headerAllBg);
    display: flex;
    flex: row  ;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#tradeIdeasTextHeader p { 
    margin-left: 1rem;
    font-size: 1.2rem;
}

#tradeIdeasTextHeader button {
    margin-right: 1rem;
}

#tradeIdeasBody {
    overflow-x: hidden;
    width: 95%;
    height: 85%;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    font-size: 1.3rem;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
}

#tradeIdeasFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#tradeIdeasFooter button {
    margin-top: 1rem;
    background: var(--topBarBg);
    color: white;
    width: 10rem;
    height: 2.4rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    transition: 0.2s;
}

#tradeIdeasFooter button:hover {
    background: white;
    color: var(--topBarBg);
    border: 1px solid var(--topBarBg);
}

#tradeIdeaLoading {

    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#tradeIdeasTitleHead {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    gap: 0.5rem;
}

#tradeIdeasTitleHead p {
    font-size: 1rem;
}

#tradeIdeasTitleHead input {
    padding-left: 0.3rem;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid rgb(202, 202, 202);
    background: rgb(236, 236, 236);
}

#tradeIdeasTitleHead input::placeholder {
    
    color: black !important;
}

#tradeIdeasTextContainer ::-webkit-scrollbar {
    width: 10px;
}
  
#tradeIdeasTextContainer ::-webkit-scrollbar-track {
    background: #d3d3d3;
}
  
#tradeIdeasTextContainer ::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    transition: 0.2s;
}
  

#tradeIdeasTextContainer ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

@media (max-height: 750px){
    #tradeIdeasBody {
        height: 70%;
        overflow-x: hidden;
    }
}
