#reportLoadingTd {
    height: 10rem;
}

.reportsTableContainer  {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    border-radius: 0.5rem 0 0 0;
}

.reportsTableContent {
    width: 100%;
    overflow-x: hidden;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.reportsTableContainer table {
    width: 100%;margin: 0 auto;
    font-size: 1rem;
}

.reportsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
    font-size: 1rem;
}

.reportsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.reportsTableContainer thead tr:first-child th {
    position: sticky;
    top: 0;
    opacity: 1;
    font-size: 1rem;
    z-index: 100;
    background: var(--topBarBg);
}

.reportsTableContainer thead tr:nth-child(2) th {
    position: sticky;
    top: 5rem;
    opacity: 1;
    font-size: 1rem;
    z-index: 100;
    background: var(--tableSecondTHGray);
}

.reportsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}

.reportsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.reportsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.reportsTableContainer tbody tr:hover{
    background: var(--tableHover);
}


.reportsTableContainer tbody td {
    padding: 0;
    max-width: 5rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    cursor: default;
}

.reportsTableContainer tbody td:nth-child(8){
  cursor: pointer;
  max-width: 3rem;
  width: 3rem;
}

.reportsTableContainer tbody td:nth-child(1) svg {
    color: rgb(110, 110, 110)
}

.reportsTableContainer tbody td:nth-child(1):hover svg {
    font-size: 1.1rem;
}

/* Overload: O botão padrão possui 8rem */
button#userCadastration {
    width: 12rem;
}

.modalReportsDetail{
    width: 50rem;
    height: 35rem;
    background: #fff;
}

.modalReportsHeader{
    background-color: #f1f1f1;
    width: 100%;
    height: 4rem;
}

.disclaimerEditReport{
    background-color: #c49e17;
    text-align: center;
    padding: 0.2rem;
}

.buttonMuiDatatable:hover{
    background-color: #c49e17;
}