.performanceTabs {
  overflow-y: hidden;
  height: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.performanceTabsTable {
  max-height: 45rem;
  width: 95%;
  height: 100%;
  margin-top: 2rem;
}

.performanceTabsLabelActive {
  overflow: hidden;
  white-space: nowrap; 
  border-radius: 8px 8px 0 0 ; 
  height: 100%;
  width: auto;
  max-width: 15rem;
  min-width: 7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

.performanceTabsLabelActive a {
  color: rgb(246, 246, 246);
  font-size: 0.9rem;
  border-bottom: 1px solid var(--goldColor);
}

.performanceTabsLabel {
  overflow: hidden;
  white-space: nowrap;
  border-radius: 8px 8px 0 0 ;
  height: 100%;
  width: auto;
  max-width: 15rem;
  min-width: 7rem;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

.performanceTabsLabel a {
  color: rgb(211, 210, 210);
  font-size: 0.9rem;
  filter: brightness(0.80);
  font-weight: 400;
}

.performanceTabsLabel:hover {
  filter: brightness(1.3)
}

.performanceTableContainer  {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.performanceTableContent {
  width: 100%;
  overflow-x: hidden;
  -webkit-box-shadow: -7px 0px 9px -7px #000000; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.performanceTableContainer table {
  width: 100%;margin: 0 auto;
  font-size: 1rem;
}

.performanceTableContainer thead  {
  background: var(--topBarBg);
  color: white;    
  font-size: 1rem;
}

.performanceTableContainer thead tr:first-child {
  height: 5rem;
  font-size: 1.8rem;
  font-weight: 200;
}

.performanceTableContainer thead tr:first-child th {
  position: sticky;
  top: 0;
  opacity: 1;
  font-size: 1rem;
  z-index: 100;
  background: var(--topBarBg);
}

.performanceTableContainer thead tr:nth-child(2) th {
  position: sticky;
  top: 5rem;
  opacity: 1;
  font-size: 1rem;
  z-index: 100;
  background: var(--tableSecondTHGray);
}

.performanceTableContainer thead tr:nth-child(2){
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 200;
  background: var(--tableSecondTHGray);
}

.performanceTableContainer thead tr:nth-child(2) th{
  color: white;
  height: 1.5rem;   
  font-size: 1rem;
  font-weight: 300;
}

.performanceTableContainer tbody {
  background: white;
  overflow-y: scroll;
  max-height: 35rem;
  transition: 0.2s;
}

.performanceTableContainer tbody tr:hover{
  background: var(--tableHover);
}


.performanceTableContainer tbody td {
  padding: 0;
  max-width: 5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  height: 2rem;
  border: 1px solid var(--tableBorder);
  text-align: center;
  transition: 0.1s;
  cursor: default;
}

.performanceTableContainer tbody td:nth-child(8){
cursor: pointer;
max-width: 3rem;
width: 3rem;
}

.performanceTableContainer tbody td:nth-child(1) svg {
  color: rgb(110, 110, 110)
}

.performanceTableContainer tbody td:nth-child(1):hover svg {
  font-size: 1.1rem;
}

.reportsTableContent {
  width: 100%;
  overflow-x: hidden;
  -webkit-box-shadow: -7px 0px 9px -7px #000000; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.reportsTableContainer table {
  width: 100%;margin: 0 auto;
  font-size: 1rem;
}

.performanceTableContainer  {
  text-align: center;
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  -webkit-box-shadow: -7px 0px 9px -7px #000000; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.performanceTableContainer table {

  width: 100%;
  font-size: 1rem;
}

.performanceTableContainer thead  {
  background: var(--topBarBg);
  color: white;    
}

.performanceTableContainer thead tr:first-child {
  height: 5rem;
  font-size: 1.8rem;
  font-weight: 200;
}

.performanceTableContainer thead tr:first-child th {
  position: sticky;
  top: 0;
  opacity: 1;
  z-index: 100;
  background: var(--topBarBg);
}

.performanceTableContainer thead tr:nth-child(2) th {
  position: sticky;
  top: 5rem;
  opacity: 1;
  z-index: 100;

  background: var(--tableSecondTHGray);
}

.performanceTableContainer thead tr:nth-child(2){
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 200;
  background: var(--tableSecondTHGray);
}


.performanceTableContainer thead tr:nth-child(2) th{
  color: white;
  height: 1.5rem;   
  font-size: 1rem;
  font-weight: 300;
}

.performanceTableContainer tbody {
  background: white;
  overflow-y: scroll;
  transition: 0.2s;
}

.performanceTableContainer tbody tr:hover{
  background: var(--tableHover);
}

.performanceTableContainer tbody td {
  padding: 0;
  max-width: 6rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 2rem;
  border: 1px solid var(--tableBorder);
  text-align: center;
  transition: 0.1s;
}

.substitutoLabelDisplay{
  display: flex;
}

.substitutoLabelDisplay > *{
  margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
  height: 1.8rem;
  background: #313131;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  padding-left: 0.5rem;
}