.cropperImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    /* flex-direction: column-reverse; */
    background: #2f2f2f;
    justify-content: center;
    /* align-items: center; */

}

#userEdittedImageLoader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cropperImageContainer #imgCropped {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
}

.cropperButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 10rem;
}

.cropperButton button {
  outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        width: 6.6rem;
        height: 1.8rem;
        border-radius: 0.6rem;
        transition: 0.1s;
        margin-top: 2rem;
        color: var(--formButtonFontColor);  
}

.cropArea {

  background: #5c5c5c;
  width: 100%;
  height: 100%;
  
}

.cropArea #setCroppedButton {
  z-index: 1;
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  margin: 0 auto;


  outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        width: 6.6rem;
        height: 1.8rem;
        border-radius: 0.6rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);  
}
.reactEasyCrop_Contain {
  max-width: 50% !important;
  max-height: 50% !important;
  margin: auto;
}

.reactEasyCrop_CropArea {
  border-radius: 50%;
  border: 2px solid gray !important;
}

#croppedImage {
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid gray;
  width: 500px;
  position: absolute;
}

#croppedImageConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 90px);
  position: absolute;
  top: 85%;
  z-index: 1;
}

#croppedImageEdition {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: calc(100% - 90px);
  position: absolute;
  top: 90%;
  z-index: 1;
}


#croppedImageEdition button {
  outline: none;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 6.6rem;
  height: 1.8rem;
  border-radius: 0.6rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);  
}

#croppedImageEdition button:hover {
  background: var(--goldColor);
  color: #2f2f2f
}

#croppedImageConfirmation a {
  font-size: 1.4rem;
  color: white;
}

#croppedImageConfirmation div {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2.5rem;

}

#croppedImageConfirmation button {
  outline: none;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 6.6rem;
  height: 1.8rem;
  border-radius: 0.6rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);  
}

#croppedImageConfirmation button:hover {
  background: var(--goldColor);
  color: #2f2f2f
}

#uploadSuccessMsg {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: calc(50% - 90px);
  gap: 1rem;
}

#uploadSuccessMsg a {
  font-size: 1.2rem;
  color: white;
}

#uploadSuccessMsg button {
  outline: none;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  width: 6.6rem;
  height: 1.8rem;
  border-radius: 0.6rem;
  transition: 0.1s;
  color: var(--formButtonFontColor);  
}

#uploadSuccessMsg button:hover {
  background: var(--goldColor);
  color: #2f2f2f
}

@media(max-width:1366px){

  #croppedImage {
    top: 15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid gray;
    width: 350px;
    position: absolute;
  }

  #croppedImageConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 90px);
    position: absolute;
    top: 80%;
    z-index: 1;
  }
}