.clientsIndicationToApprovalContainer {
    background: white;
    width: 80rem;
    height: 35rem;
    display: flex;
    flex-direction: column;   
}

.clientsIndicationLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label {
    display: flex;
    width: 90%;

}

.clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label a{
    font-size: 1rem;
    font-weight: 300;
    border-bottom: 1px solid var(--goldColor);
    margin-bottom: 0.5rem;
}

.clientsToApprovalBodyContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.clientsIndicationToApprovalHeader {
    height: 4rem;
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    flex-direction: row ;
    align-items: center;
    justify-content: space-between;
}

.clientsIndicationToApprovalHeader p {
    font-size: 1.2rem !important;
    border-bottom: 1px solid var(--goldColor);
    margin-left: 1rem;    
}

.clientsIndicationToApprovalHeader button {
    margin-right: 1rem;
}

.clientsIndicationToAprrovalUppper a {
    font-weight: 300;
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.clientsIndicationToAprrovalUppper {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
    margin-bottom: 1rem;
}

.clientsIndicationLabelInfo {
    height: 3.4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border: 1px solid #d3d3d3;
}

.clientsIndicationLabelInfo a:first-child {
    font-weight: 500;
}

.clientsIndicationToApprovalProfileListIndicated {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem
}

.clientsIndicationToApprovalLower {
    border: 1px solid #d3d3d3;
    width: 76.7rem;
    height: 6rem;
    max-width: 76.7rem;
    max-height: 6rem;
    overflow: auto;

    padding: 0.5rem;
}

.clientsIndicationToApprovalLower a {
    font-size: 1rem;
    font-weight: 300;
}

.clientsIndicationToAprrovalProfilesIndicated {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 1px solid #d3d3d3;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background:  var(--tableSecondTh);
    color: white;
}

.clientsIndicationToAprrovalProfilesIndicated a{
    font-size: 1rem;
    font-weight: 400;
}

.clientIndicationsApprovalRejectButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.clientsIndicationProfilesToChooseDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}

.clientsIndicationProfilesToChooseDiv .MuiInputBase-root {
    width: 17.8rem !important;
}

.clientsIndicationHide {
    display: none;
}

.handleSelectedStatus{
    background: #ffffff;
    color: var(--goldColor);
    height: 2.4rem;
    outline: none;
    font-size: 1rem;
    font-weight: 700;
    border: 1px solid var(--goldColor);
    width: 25rem;
}

/* @media(max-width:1368px){ */

    


    .clientsIndicationToApprovalContainer {
        background: white;
        width: 70rem;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;    
    }
    
    .clientsIndicationLoader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    
    .clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label {
        display: flex;
        width: 90%;
    
    }
    
    .clientsIndicationToApprovalContainer .clientsToApprovalBodyContainer label a{
        font-size: 1rem;
        font-weight: 300;
        border-bottom: 1px solid var(--goldColor);
        margin-bottom: 0.5rem;
    }
    
    .clientsToApprovalBodyContainer {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-bottom: 0rem;
    }
    
    .clientsIndicationToApprovalHeader {
        height: 4rem;
        background: var(--headerAllBg);
        width: 100%;
        display: flex;
        flex-direction: row ;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsIndicationToApprovalHeader p {
        font-size: 1rem !important;
        border-bottom: 1px solid var(--goldColor);
        margin-left: 1rem;    
    }
    
    .clientsIndicationToApprovalHeader button {
        margin-right: 1rem;
    }
    
    .clientsIndicationToAprrovalUppper a {
        font-weight: 300;
        font-size: 0.9rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    
    .clientsIndicationToAprrovalUppper {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 1rem;
        margin-bottom: 1rem;
    }
    
    .clientsIndicationLabelInfo {
        height: 2rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 1px solid #d3d3d3;
    }
    
    .clientsIndicationLabelInfo a:first-child {
        font-weight: 500;
    }
    
    .clientsIndicationToApprovalProfileListIndicated {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem
    }
    
    .clientsIndicationToApprovalLower {
        border: 1px solid #d3d3d3;
        width: calc(100% - 1rem);
        height: 6rem;
        max-width: calc(100% - 1rem);
        max-height: 6rem;
        overflow: auto;
        padding: 0.5rem;
    }
    
    .clientsIndicationToApprovalLower a {
        font-size: 0.9rem;
        font-weight: 300;
    }
    
    .clientsIndicationToAprrovalProfilesIndicated {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border: 1px solid #d3d3d3;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        background:  var(--tableSecondTh);
        color: white;
    }
    
    .clientsIndicationToAprrovalProfilesIndicated a{
        font-size: 0.9rem;
        font-weight: 400;
    }
    
    .clientIndicationsApprovalRejectButtons {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
    }
    
    .clientsIndicationProfilesToChooseDiv {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 1rem;
    }
    
    .clientsIndicationProfilesToChooseDiv .MuiInputBase-root {
        width: 17.8rem !important;
    }
    
    .clientsIndicationHide {
        display: none;
    }
/* } */