#clientsArrowLoader {    
  height: 30rem;
  border: none;
}

    #clientsEmptyRow{
        height: 2rem;
        font-size: 1.2rem !important;
    }

    #clientsTableNameAndTotalClients {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 0.3rem;
    }

    #clientsTableNameAndTotalClients a:nth-child(2){
      font-size: 0.9rem;
      font-weight: 200;
    }
    
    .clientsTableContainer  {
        
        max-height: 40rem;
        overflow-y: auto;
        overflow-x: hidden;
        width: 98%;
        border-radius: 0.5rem 0 0 0;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientsTableContainer table {
    
        width: 100%;
        font-size: 1rem;
    }
    
    .clientsTableContainer thead  {
        background: var(--topBarBg);
        color: white;    
    }
    
    .clientsTableContainer thead tr:first-child {
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 200;
    }
    
    .clientsTableContainer thead tr:first-child th {
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
        background: var(--topBarBg);
    }
    
    .clientsTableContainer thead tr:nth-child(2) th {
        position: sticky;
        top: 5rem;
        opacity: 1;
        z-index: 100;
    
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:nth-child(2){
        height: 1.5rem;
        font-size: 1rem;
        font-weight: 200;
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:first-child th {
        width: 120rem;
    }
    
    .clientsTableContainer thead tr:nth-child(2) th{
        color: white;
        height: 1.5rem;   
        font-size: 1rem;
        font-weight: 300;
    }
    
    .clientsTableContainer tbody {
        background: white;
        overflow-y: scroll;
        max-height: 35rem;
        transition: 0.2s;
    }
    
    .clientsTableContainer tbody tr:hover{
        background: var(--tableHover);
    }
    
    .clientsTableContainer tbody td {
        cursor: default !important;
        padding: 0;
        width: 20rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 2rem;
        border: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        font-size: 1rem;
    }

    .clientsTableContainer tbody td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6){
      cursor: pointer;
    }

    .clientsTableContainer tbody td:nth-child(1){
      width: 10rem;
    }

    .clientsTableContainer tbody td:nth-child(2){
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(3){
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(4){
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(5){
      padding-left: 0.5rem;
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(6){
      width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:last-child{
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .clientsTableContainer .clientsTheadFirst{
        display: flex;
        flex-direction: row;
    }
    
    .clientsTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .clientsTheadFirst a {
        margin-left: 2rem;
    }
    
    .clientsTheadFirst button {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        width: 8rem;
        height: 1.8rem;
        /* border-radius: 0.6rem; */
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    
    .clientsTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }

    .clientsTheadFirst button:focus {
      background: var(--formBorder);
      color: black;
  }
    
    .clientsTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
        gap: 1rem;
    }
    
    
    .clientsTheadFirst:first-child a{
    
        font-weight: 300;
        font-size: 1.2rem;
    }
    
    .clientsTheadFirst label input {
        height: 1.8rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
        padding-left: 0.5rem;
    }

    #clientsShowLoadingScroll{
      height: 10rem;
      background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }

    #clientsHideLoadingScroll {
        display: none;
    }

    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }


@media (max-width: 1368px){
    #clientsArrowLoader {    
        height: 20rem;
    }

    #clientsEmptyRow{
        height: 2rem;
        font-size: 1.2rem !important;
    }
    
    .clientsTableContainer  {
        
        max-height: 30rem;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        border-radius: 0.5rem 0 0 0;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientsTableContainer table {
    
        width: 100%;
        font-size: 1rem;
    }
    
    .clientsTableContainer thead  {
        background: var(--topBarBg);
        color: white;    
    }
    
    .clientsTableContainer thead tr:first-child {
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 200;
    }
    
    .clientsTableContainer thead tr:first-child th {
        position: sticky;
        top: 0;
        opacity: 1;
        z-index: 100;
        background: var(--topBarBg);
    }
    
    .clientsTableContainer thead tr:nth-child(2) th {
        position: sticky;
        top: 5rem;
        opacity: 1;
        z-index: 100;
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:nth-child(2){
        height: 1.5rem;
        font-size: 1rem;
        font-weight: 200;
        background: var(--tableSecondTHGray);
    }
    
    .clientsTableContainer thead tr:first-child th {
        width: 120rem;
    }
    
    .clientsTableContainer thead tr:nth-child(2) th{
        color: white;
        height: 1.5rem;   
        font-size: 1rem;
        font-weight: 300;
        top: 4.5rem;
    }
    
    .clientsTableContainer tbody {
        background: white;
        overflow-y: scroll;
        max-height: 35rem;
        transition: 0.2s;
    }
    
    .clientsTableContainer tbody tr:hover{
        background: var(--tableHover);
    }
    
    .clientsTableContainer tbody td {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 20rem;
        height: 2rem;
        border: 1px solid var(--tableBorder);
        text-align: center;
        transition: 0.1s;
        font-size: 1rem;
    }

    .clientsTableContainer tbody td:nth-child(1){
      width: 10rem;
    }

    .clientsTableContainer tbody td:nth-child(2){
      max-width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(3){
      width: 6rem;
    }

    .clientsTableContainer tbody td:nth-child(4){
      width: 6rem;
    }

    .clientsTableContainer tbody td:nth-child(5){
      padding-left: 0.5rem;
      max-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .clientsTableContainer tbody td:nth-child(6){
      width: 15rem;
    }

    .clientsTableContainer tbody td:last-child{
      width: 12rem;
    }
    
    .clientsTableContainer .clientsTheadFirst{
        display: flex;
        flex-direction: row;
    }
    
    .clientsTheadFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .clientsTheadFirst a {
        margin-left: 2rem;
    }
    
    .clientsTheadFirst button {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
        outline: none;
        padding-right: 0.5rem;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        width: 8rem;
        height: 1.8rem;
        /* border-radius: 0.6rem; */
        transition: 0.1s;
        color: var(--formButtonFontColor)
    }
    
    .clientsTheadFirst button:hover {
        background: var(--formBorder);
        color: black;
    }
    
    .clientsTheadFirst label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
    }
    
    
    .clientsTheadFirst:first-child a{
    
        font-weight: 300;
        font-size: 1.2rem;
    }
    
    .clientsTheadFirst label input {
    
        height: 1.8rem;
        background: #313131;
        border: none;
        outline: none;
        color: white;
        font-size: 1rem;
    }

    #clientsShowLoadingScroll{
        background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
    }

    #clientsHideLoadingScroll {
        display: none;
    }

    .ldsellipsis {
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
      }
      .ldsellipsis div {
        position: absolute;
        top: 0.4rem;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--headerFontColor);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .ldsellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .ldsellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .ldsellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }

}