

#clientDetailsDeleteClient {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 1rem;
    white-space: nowrap;
}

#clientDetailsDeleteClient a {
    cursor: pointer;
}

.clientDetailsContainer{
    max-height: 44rem;
    height: 44rem;
}

#clientDetailsDeleteClient a:hover {
    font-weight: 700;
}

#clientDetailsAlterStatus {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}


#clientDetailsAlterStatus a {
    font-size: 1rem;
}

#clientDetailsReactivate {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor);
    margin-top: 1rem;
}

#clientDetailsReactivate:hover {
    background: var(--buttonHover);
    color: white;
}


.userDetailsLoadingTable {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#clientDetailsLoader{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientsDetailsHeader {
    width: 100%;
    background: var(--headerAllBg);
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.clientsDetailsHeader p:first-child {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
}

.clientsDetailsHeader p:nth-child(2) {
    font-weight: 400;
    font-size: 1.2rem !important;
}

.clientsDetailsHeader div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    width: 100%;
    justify-content: flex-start;
    gap: 0.5rem;
}

.clientsDetailsHeader button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-right: 1rem;
    font-size: 1rem;
}

.clientDetailsBodycontent{
    display: grid;
    height: 36rem;
    grid-template-columns: 1fr 1px 1fr;
    margin-top: 1rem;
}
.containerDetalhesEspecificos{
    margin-top: 1.2rem;
    margin-left: -1rem;
    height: 95%;
    overflow: auto;
}

.clientsDetailsHeader button:hover{
    background: var(--buttonHover);
    color: white;
}

.subTitle {
    font-weight: 500;
    font-size: 1.1rem;
    margin-left: 3.5rem;
}
.clientDetails {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0.8rem;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-bottom: 0.6rem;
    padding-top: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.clientDetails2 {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 2rem;
    margin-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50rem;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.itemNull{
    margin-left: 1rem;
}

.selectBroker{
    width: 100%;
    white-space: nowrap;
}
.itemDetail{
    flex: 30%;
    display: inline;
    margin-left: 1rem;
}

.containerActionsAvulso{
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    margin-left: 0.9rem;
    margin-top: 0.4rem;
}

.itemDetail2{
    flex: 60%;
    display: inline;
    margin-left: 1rem;
}

#clientDetailsEditButton {
    margin-bottom: 1rem;
    width: 100%;
}

.clientDetails label {
    height: 2rem;   
    width: 100%;
}

.clientDetailsStatusClient {
    display: flex;
    flex-direction: row;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.5rem;
}

.clientDetailsStatusClient a:first-child {
    font-weight: 500;
}

.clientDetailsStatusClient a {
    font-size: 1rem;
}

.clientsBoxHideFields {
    display: none;
}

#clientsDetailLabelElipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    max-width: 100%;
    
}
.clientDetails #relative{
    position: relative;
}

.clientDetails div .dinamic{
    border: 0;
    border: 1px solid var(--formBorder);
    height: 3.4rem;
    outline: none;
    font-weight: 400;
    min-width: 13rem;
    width: calc(100% - 1rem);
    padding-left: 1rem;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0.3rem;
}
  
.clientDetails div .dinamic:focus{
    border: 1px solid #c39e16
}
  
.clientDetails div .dinamic::placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.clientDetails div #labelDinamic{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
}
  
.clientDetails div .dinamic:required:invalid + #labelDinamic{
    color: red;
}
.clientDetails div .dinamic:focus:required:invalid{
    border-bottom: 2px solid red;
}
.clientDetails div .dinamic:required:invalid + #labelDinamic:before{
    content: '*';
}
.clientDetails div .dinamic:focus + #labelDinamic,
.clientDetails div .dinamic:not(:placeholder-shown) + #labelDinamic{
    font-size: 0.9rem;
    margin-top: -1rem;
    margin-left: 0.5rem;
    width: auto;
    height: auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: #3C3C3C;
    background-color: white;
}

.clientDetailDualDetail {
    width: fit-content;
    height: 20%;
    display: grid;
    grid-template-columns: auto auto;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    min-height: 2rem;
}
.clientDetailDualDetail2{
    width: calc(100% - 1rem);
    height: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: center;
    min-height: 2rem;
    padding-top: 0.4rem;
    padding-right: 0.8rem;
}

.clientsInformationDiv {

    border: 1px solid lightgrey;
    width: 15rem;
    overflow: hidden;
    gap: 0.3rem;
    text-overflow: ellipsis;
    height: 3.4rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    border-radius: 0.3rem;
}

.clientsInformationDiv a {
    font-size: 1rem;
}

.clientsDetailsEditFixedBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.clientsBoxShowStatusField {
    width: 100%
 }
 
.clientsBoxShowFields {
    border: 1px solid lightgrey;
    width: 15rem;
    height: 2rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    gap: 0.5rem;
    border-radius: 0.3rem;
}

#clientDetailFirstAElementVBA {
    font-size: 1rem;
    overflow-x: hidden;
    font-weight: 600;
}

.usersDetailAddBrokerOption {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
}

.usersDetailAddBrokerOption button {
    margin-bottom: 1rem;
}

.usersDetailAddBrokerOption label a {
    margin-bottom: 0.5rem;
}



.usersDetailStaticProfileDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#editClientBrokerDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgba(34, 34, 34, 0.294);
    background: white;
    height: 3.4rem;
    align-items: center;
    font-size: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    border-radius: 4px;
}

#editClientBrokerDetail span {
    display: flex;
    flex-direction: row;
    gap:0.5rem
}

#labelToEditClientBroker {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
    font-size: 1rem;
}

#labelToEditClientBroker a {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
}

#alignLabelForEditBroker {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#labelToEditClientBroker svg:nth-child(2){
    padding-top: 3rem;
}

.usersDetailStaticProfileDescription label {
    font-size: 0.8rem;
    font-weight: 400;
    height: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}

.usersDetailStaticProfileDescription a {
    font-size: 1rem;
    font-weight: 500;
    color: #777777;
}

.clientDetailsTableDetails {
    width: 100%;
    height: 100%;   
    margin-bottom: 1rem;
    
}

/* .clientDetailsTableDetailsGray {
    width: 100%;
    height: 100%;
    background: var(--headerAllBg);      
    margin-bottom: 1rem;  
} */

.clientDetailsTableDetails table {
    margin-top: 2rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.clientDetailsTableDetails thead tr:first-child th{
    background: var(--tableSecondTHGray);
    font-weight: 400;
    height: 1.8rem;
    padding: 0;
    font-size: 1.2rem;
}


.clientDetailsTableDetails thead tr th{
    background: var(--tableSecondTh);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
}

.clientDetailsTableDetails tbody tr td {
    text-align: center;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
}

.clientDetailsSuccessEdit {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.clientDetailsSuccessEdit a {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.8rem;
}

.clientDetailsSuccessEdit button {
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

.clientDetailsSuccessEdit button:hover {
    color: white;
    background: var(--buttonHover);
}

.verticalBarVbaClients{
    width: 100%;
    height: 100%;
    background-color: lightgray;
}

.containerTimeline{
    height: 36rem;
    max-height: 36rem;
    width: calc(100% - 4rem);
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
}

.timelineTitle{
    height: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    display: grid;
    grid-template-columns:2fr 3fr;
    padding-bottom: 0.3rem;
    align-items: center;
}

.timelineFilters {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
}

.timelineCommentBox{
    height: 24rem;
    max-height: 24rem;
    overflow: auto;
    border-radius: 2px;
    background-color: #f8f8f8;
    border: 1px solid #dadada;
    display: grid;
}

.timelineNewCommentBox{
    height: 10rem;
    display: grid;
    grid-template-rows: auto 1fr;
}

.timelineNewCommentContactType{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    width: fit-content;
}

.timelineNewCommentWriteSend{
    display: grid;
    grid-template-columns: 9fr 1fr;
}

.timelineNewCommentContactTypeTitle{
    width: fit-content;
    height: fit-content;
    margin: auto;
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 0.4rem;
}

.timelineComentBoxUserComentRight{
    max-width: 80%;
    width: fit-content;
    margin-left: auto;
    display: grid;
    margin-bottom: 1.8rem;
    height: fit-content;
}

.timelineComentBoxUserComentLeft{
    max-width: 80%;
    width: fit-content;
    margin-right: auto;
    display: grid;
    margin-bottom: 1.8rem;
    height: fit-content;
}

.timelineComentBoxUserComentHeaderRight{
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    margin-left: auto;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.timelineComentBoxUserComentHeaderLeft{
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    margin-right: auto;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.timelineComentBoxUserComentDateRight{
    width: fit-content;
    margin-right: auto;
    margin-right: 1rem;
}

.timelineComentBoxUserComentDateLeft{
    width: fit-content;
    margin-left: auto;
    margin-left: 1rem;
}

.timelineComentBoxUserComentMessageRight{
    padding: 0.6rem;
    border-top-left-radius: 6px;
    height: fit-content;
}

.timelineComentBoxUserComentIconRight{
    padding-bottom: 0.3rem;
    padding-top: 0.35rem;
    padding-left: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentNameRight{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentDateRight{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin-right: auto;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.timelineComentBoxUserComentMessageLeft{
    padding: 0.6rem;
    border-top-right-radius: 6px;
    height: fit-content;
}

.timelineComentBoxUserComentIconLeft{
    padding-bottom: 0.3rem;
    padding-top: 0.35rem;
    padding-right: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentNameLeft{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin: auto;
}

.timelineComentBoxUserComentDateLeft{
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    display: grid;
    margin-left: auto;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.whatsAppMessageColor{
    background-color: #f3fff1;
    color: #092400;
    border: 2px solid #092400;
}

.whatsAppHeaderColor{
    background-color: #092400;
    color: #f3fff1;
}

.whatsAppDateColor{
    background-color: #092400;
    color: #f3fff1;
}

.phoneMessageColor{
    background-color: #e0f0ff;
    color: #000b33;
    border: 2px solid #000b33;
}

.phoneHeaderColor{
    background-color: #000b33;
    color: #e0f0ff;
}

.phoneDateColor{
    background-color: #000b33;
    color: #e0f0ff;
}

.emailMessageColor{
    background-color: #ffe2e2;
    color: #410000;
    border: 2px solid #410000;
}

.emailHeaderColor{
    background-color: #410000;
    color: #ffe2e2;
}

.emailDateColor{
    background-color: #410000;
    color: #ffe2e2;
}

.meetMessageColor{
    background-color: #ffffe2;
    color: #524400;
    border: 2px solid #524400;
}

.meetHeaderColor{
    background-color: #524400;
    color: #ffffe2;
}

.meetDateColor{
    background-color: #524400;
    color: #ffffe2;
}

.meetMessageColor{
    background-color: #ffffe2;
    color: #524400;
    border: 2px solid #524400;
}

.meetHeaderColor{
    background-color: #524400;
    color: #ffffe2;
}

.meetDateColor{
    background-color: #524400;
    color: #ffffe2;
}

.commentMessageColor{
    background-color: #f3ece1;
    color: #2b1800;
    border: 2px solid #2b1800;
}

.commentHeaderColor{
    background-color: #2b1800;
    color: #f3ece1;
}

.commentDateColor{
    background-color: #2b1800;
    color: #f3ece1;
}

.systemMessageColor{
    background-color: #f1f1f1;
    color: #0e0e0e;
    border: 2px solid #0e0e0e;
}

.systemHeaderColor{
    background-color: #0e0e0e;
    color: #f1f1f1;
}

.systemDateColor{
    background-color: #0e0e0e;
    color: #f1f1f1;
}

.formMessageColor{
    background-color: #f8fffe;
    color: #007761;
    border: 2px solid #007761;
}

.formHeaderColor{
    background-color: #007761;
    color: #f8fffe;
}

.formDateColor{
    background-color: #007761;
    color: #f8fffe;
}

.indicationMessageColor{
    background-color: #ffeedb;
    color: #4b3114;
    border: 2px solid #4b3114;
}

.indicationHeaderColor{
    background-color: #4b3114;
    color: #ffeedb;
}

.indicationDateColor{
    background-color: #4b3114;
    color: #ffeedb;
}

.emptyTimelineMessage{
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    
}

.containerActivateClient{
    width: fit-content;
    height: fit-content;
    margin: auto;
    display: grid;
}

.containerActivateClientIcon{
    margin: auto;
}

.activateClientText{
    font-size: 1.4rem;
}

.activateClientButton{
    font-size: 1.2rem;
    font-weight: 400;
    width: fit-content;
    padding: 0.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: auto;
    margin-top: 0.8rem;
    color: #c49e17;
    border: 2px solid #c49e17;
    background-color: white;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
}
.activateClientButton:hover{
    color: #fff;
    border: 2px solid #c49e17;
    background-color: #c49e17;
    box-shadow: 0px 0px 10px #c49e17;
}

@media(max-width: 1368px){
    

    .userDetailsLoadingTable {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    #clientDetailsLoader{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .clientsDetailsHeader {
        width: 100%;
        background: var(--headerAllBg);
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsDetailsHeader p:first-child {
        border-bottom: 1px solid var(--goldColor);
        font-size: 1.2rem !important;
    }

    .clientsDetailsHeader p:nth-child(2) {
        font-weight: 400;
        font-size: 1.2rem !important;
    }
    
    .clientsDetailsHeader div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 1rem;
        width: 100%;
        justify-content: flex-start;
        gap: 0.5rem;
    }
    
    .clientsDetailsHeader button {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
        margin-right: 1rem;
    }
    
    .clientsDetailsHeader button:hover{
        background: var(--buttonHover);
    }
    
    .clientDetails {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 2rem;
        margin-right: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
        gap: 1rem;
        max-height: 50rem;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding-bottom: 1rem;
        padding-top: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    .clientDetails2 {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 2rem;
        margin-right: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 50rem;
        border: 1px solid lightgrey;
        border-radius: 5px;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    
.margin {
        margin: 50px !important;
        font-size: 2rem;
}

    .clientDetailsStatusClient {
        display: flex;
        flex-direction: row;
        gap: 0.3rem;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0.5rem;
    }

    .clientDetailsStatusClient a {
        font-size: 1rem;
        font-weight: 400;
    }
    
    #clientDetailsEditButton {
        margin-bottom: 1rem;
        width: 100%;
    }
    
    .clientDetails label {
        height: 2rem;   
        width: 100%;
    }
    
    .clientsBoxHideFields {
        display: none;
    }
    .clientDetails #relative{
        position: relative;
    }
    
    .clientDetails div .dinamic{
        border: 0;
        border: 1px solid var(--formBorder);
        height: 2rem;
        outline: none;
        min-width: 13rem;
        width: calc(100% - 1rem);
        padding-left: 1rem;
        font-size: 1rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
        -webkit-appearance:none;
        border-radius: 0.3rem;
    }
      
    .clientDetails div .dinamic:focus{
        border: 1px solid #c39e16
    }
      
    .clientDetails div .dinamic::placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .clientDetails div #labelDinamic{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
    }
      
    .clientDetails div .dinamic:required:invalid + #labelDinamic{
        color: red;
    }
    .clientDetails div .dinamic:focus:required:invalid{
        border-bottom: 2px solid red;
    }
    .clientDetails div .dinamic:required:invalid + #labelDinamic:before{
        content: '*';
    }
    .clientDetails div .dinamic:focus + #labelDinamic,
    .clientDetails div .dinamic:not(:placeholder-shown) + #labelDinamic{
        font-size: 0.7rem;
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        width: auto;
        height: auto;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        color: #3C3C3C;
        background-color: white;
    }
    
    .clientDetailDualDetail {
        width: fit-content;
        height: 20%;
        display: grid;
        grid-template-columns: auto auto;
        flex-direction: column;
        gap: 0.8rem;
        align-items: center;
        min-height: 2rem;
    }
    .clientDetailDualDetail2{
        width: calc(100% - 1rem);
        height: 20%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        align-items: center;
        min-height: 2rem;
        padding-top: 0.4rem;
        padding-right: 0.8rem;
    }

    .clientsInformationDiv {
    
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsDetailsEditFixedBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    
    .clientsBoxShowFields {
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsBoxShowStatusField {
       width: 100%
    }
    
     #clientDetailFirstAElementVBA {
        overflow-x: hidden;
    }
    
    #clientDetailFirstAElementVBA{
        overflow-x: hidden;
        font-weight: 600;
    }

    .usersDetailAddBrokerOption {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        width: 100%;
    }

    .usersDetailAddBrokerOption label a {
        margin-bottom: 0.5rem;
    }

    .usersDetailStaticProfileDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #editClientBrokerDetail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(34, 34, 34, 0.294);
        background: white;
        height: 2.4rem;
        align-items: center;
        padding-left: 0.3rem;
        padding-right: 0.5rem;
        border-radius: 4px;
    }
    
    #editClientBrokerDetail span {
        display: flex;
        flex-direction: row;
        gap:0.5rem
    }
    #labelToEditClientBroker {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        gap: 0.5rem;
        font-size: 1rem;
    }

    #labelToEditClientBroker a {
        font-weight: 400;
    }

    #alignLabelForEditBroker {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #alignLabelForEditBroker a {
        margin-bottom: 0.6rem;
    }

    #labelToEditClientBroker svg:nth-child(2){
        padding-top: 2.1rem;
    }
    
    .usersDetailStaticProfileDescription label {
        font-weight: 400;
        height: 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
    }

    .usersDetailStaticProfileDescription a {
        font-size: 1rem;
        font-weight: 500;
        color: #777777;
    }

    .clientDetailsTableDetails {
        width: 100%;
        height: 100%;     
        
    }

    /* .clientDetailsTableDetailsGray {
        width: 100%;
        height: 100%;
        background: var(--headerAllBg);       
        
    } */
    
    .clientDetailsTableDetails table {
        margin-top: 2rem;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientDetailsTableDetails thead tr:first-child th{
        background: var(--tableSecondTHGray);
        font-weight: 400;
        height: 1.2rem;
        padding: 0.2rem;
        font-size: 1rem;
    }
    
    
    .clientDetailsTableDetails thead tr th{
        background: var(--tableSecondTh);
        color: white;
        font-weight: 300;
        padding: 0.2rem;
        font-size: 0.9rem;
    }
    
    .clientDetailsTableDetails tbody tr td {
        text-align: center;
        height: 1rem;
        padding: 0.15rem;
        border: 1px solid var(--tableBorder);
    }
    
}


.botaoSolicitarVBA{
    width: fit-content;
    margin-left: auto;
    margin-right: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSolicitarVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.botaoSolicitarExcecaoVBA{
    width: 25rem;
    height: 2rem;
    margin: auto;
    margin-bottom: max(auto,0.2rem);
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSolicitarExcecaoVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.botaoSolicitarExcecaoCancelVBA{
    width: fit-content;
    margin-right: auto;
    margin-left: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSolicitarExcecaoCancelVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}
    
.getClient{
    height: 3rem;
    }

.checkBoxVBA{
margin-right: 1rem;
width: 1.3rem;
height: 1.3rem;
vertical-align: sub;
}


.botaoEvasaoVBA{
    width: fit-content;
    /* margin-left : 0.6rem; */
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoEvasaoVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.buttonShowFinishedForm{
    height: fit-content;
    width: fit-content;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none;
    font-size: 1.2rem;
    border-radius: 3px;
    background-color: #cee4e0;
    color: #003a2f;
    transition: all ease-in-out 0.1s;
}
.buttonShowFinishedForm:hover{
    color: #f8fffe;
    background-color: #007761;
}

.selectClientDetails{
    width: 10rem;
    padding: 0.1rem;
    border: 1px solid #c49e17;
    font-weight: 500;
    border-radius: 3px;
    background-color: #c49e17;
    color: white;
    transition: all ease-in-out 0.1s;
}
.selectClientDetails:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}
.selectClientDetails:focus{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
    outline: none;
}

.selectClientDetailsButton{
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
}
.selectClientDetailsButton:hover{
    box-shadow: 0px 0px 10px #7a7a7a;
}
.selectClientDetailsButton:focus{
    outline: none;
}

.selectClientDetailsButtonDISABLED{
    padding-bottom: 0.2rem;
    padding-top: 0.15rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    margin-left: 0.4rem;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    opacity: 0.8 !important;
}

.checkboxVBAExceptionClient{
    width : 1.3rem;
    height: 1.3rem;
    margin-bottom: 0.4rem;
    display: table-cell;
    vertical-align: middle;
    margin-right: 1rem;
}

.botaoSubmitExcecaoVBA{
    width: fit-content;
    margin-left: auto;
    margin-right: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.10rem;
    padding-bottom: 0.2rem;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 2px;
    transition: all ease-in-out 0.1s;
}

.botaoSubmitExcecaoVBA:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}

.defaultBroker{
    background-color: #c49e17;
    color: white;
    border-radius: 4px;
    padding: 0.2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.campoTextoAtivoAdm{
    background-color: white;
    font-size: 1.2rem;
    width: 40%;
    height: fit-content;
    border-radius: 4px;
    border: 1px solid #c49e17;
    margin: auto;
    padding: 0.2rem;
  }
  .campoTextoAtivoAdm:focus{
    border: 1px solid #ffc800;
    outline: none;
  }
  .campoTextoAtivoAdm:hover{
    border: 1px solid #ffc800;
    outline: none;
  }