.clientDetailsContainer {
    width: 80rem;
    height: 44rem;
    background: white;
    display: flex;
    flex-direction: column;
}

#clientDetailsDeleteClient {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 1rem;
    white-space: nowrap;
}

#clientDetailsDeleteClient a {
    cursor: pointer;
}

#clientDetailsDeleteClient a:hover {
    font-weight: 700;
}

#clientDetailsAlterStatus {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}


#clientDetailsAlterStatus a {
    font-size: 1rem;
}

#clientDetailsReactivate {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor);
    margin-top: 1rem;
}

#clientDetailsReactivate:hover {
    background: var(--buttonHover);
    color: white;
}


.userDetailsLoadingTable {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#clientDetailsLoader{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clientsDetailsHeader {
    width: 100%;
    background: var(--headerAllBg);
    height: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.clientsDetailsHeader p:first-child {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
}

.clientsDetailsHeader p:nth-child(2) {
    font-weight: 400;
    font-size: 1.2rem !important;
}

.clientsDetailsHeader div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    width: 100%;
    justify-content: flex-start;
    gap: 0.5rem;
}

.clientsDetailsHeader button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    margin-right: 1rem;
    font-size: 1rem;
}

.clientsDetailsHeader button:hover{
    background: var(--buttonHover);
    color: white;
}

.clientDetailsBody {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    height: 100%;
    max-height: 50rem;
}

.clientDetailsBody #clientDetailsEditButtonDiv {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

#clientDetailsEditButton {
    margin-bottom: 1rem;
    width: 100%;
}

.clientDetailsBody label {
    height: 2rem;   
    width: 100%;
}

.clientDetailsStatusClient {
    display: flex;
    flex-direction: row;
    grid-gap: 0.3rem;
    gap: 0.3rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.5rem;
}

.clientDetailsStatusClient a:first-child {
    font-weight: 500;
}

.clientDetailsStatusClient a {
    font-size: 1rem;
}

.clientsBoxHideFields {
    display: none;
}

#clientsDetailLabelElipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    max-width: 100%;
    
}
.clientDetailsBody #relative{
    position: relative;
}

.clientDetailsBody div .dinamic{
    border: 0;
    border: 1px solid var(--formBorder);
    height: 3.4rem;
    outline: none;
    font-weight: 400;
    min-width: 13rem;
    width: calc(100% - 1rem);
    padding-left: 1rem;
    font-size: 1rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -webkit-appearance:none;
    border-radius: 0.3rem;
}
  
.clientDetailsBody div .dinamic:focus{
    border: 1px solid #c39e16
}
  
.clientDetailsBody div .dinamic::placeholder{
    color:transparent;
    font-size: 0.9rem;
}
  
.clientDetailsBody div #labelDinamic{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-left: 0.5rem;
    transition: all .3s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
}
  
.clientDetailsBody div .dinamic:required:invalid + #labelDinamic{
    color: red;
}
.clientDetailsBody div .dinamic:focus:required:invalid{
    border-bottom: 2px solid red;
}
.clientDetailsBody div .dinamic:required:invalid + #labelDinamic:before{
    content: '*';
}
.clientDetailsBody div .dinamic:focus + #labelDinamic,
.clientDetailsBody div .dinamic:not(:placeholder-shown) + #labelDinamic{
    font-size: 0.9rem;
    margin-top: -1rem;
    margin-left: 0.5rem;
    width: auto;
    height: auto;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    color: #3C3C3C;
    background-color: white;
}

.clientsDetailDualDetail {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.clientsInformationDiv {

    border: 1px solid lightgrey;
    width: 15rem;
    overflow: hidden;
    gap: 0.3rem;
    text-overflow: ellipsis;
    height: 3.4rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    border-radius: 0.3rem;
}

.clientsInformationDiv a {
    font-size: 1rem;
}

.clientsDetailsEditFixedBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.clientsBoxShowStatusField {
    width: 100%
 }
 
.clientsBoxShowFields {
    border: 1px solid lightgrey;
    width: 15rem;
    height: 2rem;
    min-width: 13rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: .9rem;
    gap: 0.5rem;
    border-radius: 0.3rem;
}

.clientsInformationDiv #clientDetailFirstAElement {
    font-size: 1rem;
    margin-left: 1rem;
}

.clientsInformationDiv #clientDetailFirstAElement {
    font-weight: 600;
}

.usersDetailAddBrokerOption {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
}

.usersDetailAddBrokerOption button {
    margin-bottom: 1rem;
}

.usersDetailAddBrokerOption label a {
    margin-bottom: 0.5rem;
}

.clientDetailsEditLinks {
    max-height: 17rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}


.clientDetailsEditLinks #usersDetailConfirmationButton{
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

.clientDetailsEditLinks #userDetailsAddBroker { 
    width: 6rem;
    height: 1.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    
}

.clientDetailsEditLinks #userDetailsHideLabelBroker {
    display:  none;
}

.clientDetailsEditLinks #userDetailsShowLabelBroker {
    width: 100%;
}

.usersDetailStaticProfileDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#editClientBrokerDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgba(34, 34, 34, 0.294);
    background: white;
    height: 3.4rem;
    align-items: center;
    font-size: 1rem;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    border-radius: 4px;
}

#editClientBrokerDetail span {
    display: flex;
    flex-direction: row;
    gap:0.5rem
}

#labelToEditClientBroker {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 0.5rem;
    font-size: 1rem;
}

#labelToEditClientBroker a {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
}

#alignLabelForEditBroker {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#labelToEditClientBroker svg:nth-child(2){
    padding-top: 3rem;
}

.usersDetailStaticProfileDescription label {
    font-size: 0.8rem;
    font-weight: 400;
    height: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
}

.usersDetailStaticProfileDescription a {
    font-size: 1rem;
    font-weight: 500;
    color: #777777;
}

.clientDetailsTableDetails {
    width: 100%;
    height: 100%;   
    margin-bottom: 1rem;
    
}

.clientDetailsTableDetailsGray {
    width: 30rem;
    height: 70%;
    background: var(--headerAllBg);      
    margin-bottom: 1rem;  
}

.clientDetailsTableDetails table {
    margin-top: 2rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.clientDetailsTableDetails thead tr:first-child th{
    background: var(--tableSecondTHGray);
    font-weight: 400;
    height: 1.8rem;
    padding: 0;
    font-size: 1.2rem;
}


.clientDetailsTableDetails thead tr th{
    background: var(--tableSecondTh);
    color: white;
    font-weight: 300;
    font-size: 1.2rem;
}

.clientDetailsTableDetails tbody tr td {
    text-align: center;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
}

.clientDetailsSuccessEdit {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.clientDetailsSuccessEdit a {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.8rem;
}

.clientDetailsSuccessEdit button {
    width: 8rem;
    height: 2rem;
    background: white;
    font-size: 0.9rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
}

.clientDetailsSuccessEdit button:hover {
    color: white;
    background: var(--buttonHover);
}

.containerComentEvasion{
    margin-top: 0.4rem;
    width: 86%;
    margin-left: 4%;
    border-left: 2px solid #c49f1759;
}

@media(max-width: 1368px){
    .clientDetailsContainer {
        width: 74rem;
        height: 44rem;
        background: white;
        display: flex;
        flex-direction: column;
    }

    .userDetailsLoadingTable {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    #clientDetailsLoader{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .clientsDetailsHeader {
        width: 100%;
        background: var(--headerAllBg);
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .clientsDetailsHeader p:first-child {
        border-bottom: 1px solid var(--goldColor);
        font-size: 1.2rem !important;
    }

    .clientsDetailsHeader p:nth-child(2) {
        font-weight: 400;
        font-size: 1.2rem !important;
    }
    
    .clientsDetailsHeader div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 1rem;
        width: 100%;
        justify-content: flex-start;
        gap: 0.5rem;
    }
    
    .clientsDetailsHeader button {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
        margin-right: 1rem;
    }
    
    .clientsDetailsHeader button:hover{
        background: var(--buttonHover);
    }
    
    .clientDetailsBody {
        flex-direction: column;
        padding-left: 3rem;
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        gap: 0;
    }
    
    .clientDetailsBody #clientDetailsEditButtonDiv {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .clientDetailsStatusClient {
        display: flex;
        flex-direction: row;
        gap: 0.3rem;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0.5rem;
    }

    .clientDetailsStatusClient a {
        font-size: 1rem;
        font-weight: 400;
    }
    
    #clientDetailsEditButton {
        margin-bottom: 1rem;
        width: 100%;
    }
    
    .clientDetailsBody label {
        height: 2rem;   
        width: 100%;
    }
    
    .clientsBoxHideFields {
        display: none;
    }
    .clientDetailsBody #relative{
        position: relative;
    }
    
    .clientDetailsBody div .dinamic{
        border: 0;
        border: 1px solid var(--formBorder);
        height: 2rem;
        outline: none;
        min-width: 13rem;
        width: calc(100% - 1rem);
        padding-left: 1rem;
        font-size: 1rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
        -webkit-appearance:none;
        border-radius: 0.3rem;
    }
      
    .clientDetailsBody div .dinamic:focus{
        border: 1px solid #c39e16
    }
      
    .clientDetailsBody div .dinamic::placeholder{
        color:transparent;
        font-size: 0.9rem;
    }
      
    .clientDetailsBody div #labelDinamic{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.9rem;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
        transition: all .3s ease-out;
        -webkit-transition: all .1s ease-out;
        -moz-transition: all .1s ease-out;
    }
      
    .clientDetailsBody div .dinamic:required:invalid + #labelDinamic{
        color: red;
    }
    .clientDetailsBody div .dinamic:focus:required:invalid{
        border-bottom: 2px solid red;
    }
    .clientDetailsBody div .dinamic:required:invalid + #labelDinamic:before{
        content: '*';
    }
    .clientDetailsBody div .dinamic:focus + #labelDinamic,
    .clientDetailsBody div .dinamic:not(:placeholder-shown) + #labelDinamic{
        font-size: 0.7rem;
        margin-top: -0.5rem;
        margin-left: 0.5rem;
        width: auto;
        height: auto;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        color: #3C3C3C;
        background-color: white;
    }
    
    .clientsDetailDualDetail {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    
    .clientsInformationDiv {
    
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsDetailsEditFixedBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    
    .clientsBoxShowFields {
        border: 1px solid lightgrey;
        width: 15rem;
        height: 2rem;
        min-width: 13rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        gap: 0.5rem;
        border-radius: 0.3rem;
    }

    .clientsBoxShowStatusField {
       width: 100%
    }
    
    .clientsInformationDiv #clientDetailFirstAElement {
        margin-left: 1rem;
    }
    
    .clientsInformationDiv #clientDetailFirstAElement{
        font-weight: 600;
    }

    .usersDetailAddBrokerOption {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        width: 100%;
    }

    .usersDetailAddBrokerOption label a {
        margin-bottom: 0.5rem;
    }

    .clientDetailsEditLinks {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }


    .clientDetailsEditLinks #usersDetailConfirmationButton{
        width: 8rem;
        height: 2rem;
        background: white;
        margin-top: 1rem;
        font-weight: 400;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
    }

    .clientDetailsEditLinks #userDetailsAddBroker { 
        width: 6rem;
        height: 1.5rem;
        font-weight: 400;
        border: 1px solid var(--goldColor);
        color: var(--goldColor);
        transition: 0.2s;
        
    }

    .clientDetailsEditLinks #userDetailsHideLabelBroker {
        display:  none;
    }

    .clientDetailsEditLinks #userDetailsShowLabelBroker {
        width: 100%;
    }

    .usersDetailStaticProfileDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #editClientBrokerDetail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(34, 34, 34, 0.294);
        background: white;
        height: 2.4rem;
        align-items: center;
        padding-left: 0.3rem;
        padding-right: 0.5rem;
        border-radius: 4px;
    }
    
    #editClientBrokerDetail span {
        display: flex;
        flex-direction: row;
        gap:0.5rem
    }
    #labelToEditClientBroker {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        gap: 0.5rem;
        font-size: 1rem;
    }

    #labelToEditClientBroker a {
        font-weight: 400;
    }

    #alignLabelForEditBroker {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #alignLabelForEditBroker a {
        margin-bottom: 0.6rem;
    }

    #labelToEditClientBroker svg:nth-child(2){
        padding-top: 2.1rem;
    }
    
    .usersDetailStaticProfileDescription label {
        font-weight: 400;
        height: 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
    }

    .usersDetailStaticProfileDescription a {
        font-size: 1rem;
        font-weight: 500;
        color: #777777;
    }

    .clientDetailsTableDetails {
        width: 100%;
        height: 100%;     
        
    }

    .clientDetailsTableDetailsGray {
        width: 100%;
        height: 10rem;
        background: var(--headerAllBg);       
        
    }
    
    .clientDetailsTableDetails table {
        margin-top: 2rem;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .clientDetailsTableDetails thead tr:first-child th{
        background: var(--tableSecondTHGray);
        font-weight: 400;
        height: 1.2rem;
        padding: 0.2rem;
        font-size: 1rem;
    }
    
    
    .clientDetailsTableDetails thead tr th{
        background: var(--tableSecondTh);
        color: white;
        font-weight: 300;
        padding: 0.2rem;
        font-size: 0.9rem;
    }
    
    .clientDetailsTableDetails tbody tr td {
        text-align: center;
        height: 1rem;
        padding: 0.15rem;
        border: 1px solid var(--tableBorder);
    }

    .clientDetailsContainer .MuiInputBase-root {
        height: 2.2rem;
    }
    
}