.containerDireitoSobras{
    display: grid;
    grid-template-rows: auto auto;
    height: 30%;
    margin-left: auto;
    margin-right: auto;
}

.checkBoxDireitoSobras{
    margin-right: 2rem;
    width: 1rem;
}

.offeringsRegistrationContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.offeringsRegistrationContainer form {
    gap: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.offeringsCardsRegistrationRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.offeringsCardsRegistrationRow label {
    width: 100%;
}

.offeringsRegistrationContainer form .offeringsCardsRegistration {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;

}

.offeringsCardsRegistrationDates {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    gap: 2.5rem;
}

.offeringsCardsRegistrationDualDiv {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.offeringsDateLabel {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#offeringsRegistrationFlexDiv {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    align-items: center;
    justify-content: center;

}

#apportiomentExpectation{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1rem;
}

.offeringsRegistrationLoaderContainer {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offeringsRegistrationHeader {
    margin-bottom: 2rem;
    background: var(--headerAllBg);
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.offeringsRegistrationHeader p {

    font-size: 1.2rem;
    margin-left: 1rem;

}

.offeringsRegistrationHeader button {
    margin-right: 1rem;
}

.offeringsRegistrationBody {
    padding: 1rem;
    width: 70%;
    margin-right: 2rem;
    display: grid;
    gap: 1.5rem;
    justify-content: center;
    height: 70%;
    align-items: center;
    grid-template-columns: repeat(3,1fr);
}

.offeringsRegistrationBody label {
    margin-left: 2rem;
}

.offeringsRegistrationBody label a {
    font-size: 0.9rem;
    font-weight: 400;
    margin-left: .5rem;
    
}

.offeringsRegistrationBody label:hover a {
    color: rgb(44, 114, 180)
}

.offeringsMarginTag {
    opacity: 0;
}

.offeringsDataDiv {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-content: center;
    gap: 1rem
}

.offeringsDateDiv {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    align-items: center;
    gap: 1rem
}

.offeringsCadastrationButtonDiv {

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

#offersRegistrationFooter {
    background-color: rgb(119, 1, 1);
    width: 100%;
    height: 2.4rem;
    position: absolute;
    bottom: 0;
    color: white;
    display: flex;
    font-weight: 400;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    justify-content: center;
}

#offersRegistrationFooter button{
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    font-size: 1.2rem;
    transition: 0.2s;
}
#offersRegistrationFooter button:hover { 
    background: white;
    color: rgb(119, 1, 1)
}

@media (max-width: 1368px){
    .offeringsRegistrationBody {
        width: 90%;
    }
    
}

@media(max-width:710px){

    .offeringsRegistrationContainer {
        width: 400px !important;
        min-width: auto !important;
        margin-top: 5rem;
    }

    .offeringsDateLabel {
        margin-top: -3 !important;
    }

    .offeringsRegistrationBody {
        padding: 1rem;
        width: 90%;
        display: grid;
        justify-content: center;
        height: 90%;
        align-items: center;
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;
        overflow-x: hidden;
    }
    
}


