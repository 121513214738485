.fixedIncomeProductsContainer {
    background-color: white;
    padding-bottom: 1rem;
}

.fixedIncomeDataTextBoxes {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.fixedIncomeDataTextBoxesChild {
    margin: 1rem;

    border-radius: 10px;
    background-color: #f0f1f1;
}
.fixedIncomeDataTextBoxesChild h2 {
    color: #d5b01f;
}
.fixedIncomeProductsSeparator {
    margin: 2rem 50px;
}
.investimentcards {
    align-self: center;
    cursor: pointer;
    background-color: #bbbbbb;
    align-items: center;
    display: flex;
    padding: 10px;
    color: #fdfdfd;
    font-weight: bold;
    border-radius: 10px;
}
.investimentcards:hover {
    background-color: #d5b01f;
}
