.assetsEditContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetsEditHeader {
    background: var(--headerAllBg);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
}

.assetsEditHeader p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.assetsEditHeader button {

    margin-right: 1rem;
}

.assetsEditBody {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
    margin-top: 5rem;
}

.assetEditCentralComponent {
    display: flex;
    flex-direction: column;
    height: 80%;
    gap: 1.5rem;
}

.assetEditDualLabel {
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2,1fr);
}

.assetEditDualLabel .assetEditDefaultInput {
    padding-left: 1rem;
    font-size: 1rem;
    border: 1px solid var(--goldColor);
}

.assetEditDualLabel .assetEditDefaultInput:focus {
    border: 2px solid var(--goldColor);
    outline: none;
}

.assetEditDualLabel input::placeholder {
    color: gray;
    font-size: 1rem;
    font-weight: 400;
}

.assetEditNameLabel {
    border: 1px solid rgb(212, 212, 212);
    height: 3.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.assetEditNameLabel:nth-child(1) a {
    
    margin-left: 0.5rem;
    font-weight: 400;
}

#assetEditNameFirst {
    margin-left: 1rem;
    font-weight: 600;
}

.assetsEditBody footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1368px){
    .assetEditNameLabel {
        border: 1px solid rgb(212, 212, 212);
        height: 2.4rem;
        font-size: 1rem;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}