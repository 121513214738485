#assetSectorTable tr:first-child th {
    background: var(--topBarBg);
    height: 3.8rem;
    position: sticky;
    top: 0;
    z-index: 99;
    border-radius:  10px 0 0 0;
}

#assetSectorTable tr:nth-child(2) th {
    background: var(--tableSecondTh);
    height: 1.5rem;
    color: white;
    font-size: 1rem;
    font-weight: 300;
    position: sticky;
    top: 3.8rem;
    z-index: 99

}

.assetSectorTableFirstDivTH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assetSectorTableFirstDivTH p {
    color: white;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.assetSectorTableFirstDivButtons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-right: 1rem;
}

#assetSectorTable tbody {
    font-size: 1rem;
}


#assetSectorTable td {
    background: white;
    border:  1px solid var(--tableBorder);
    text-align: center;
    height: 2rem;
}

#assetSectorTable tr {
    transition: 0.2s;
}

#assetSectorTable tr:hover {
    background: var(--tableHover);
}

#assetSectorsLoading {
    height: 10rem;
}