.clientsCadastrationContainer {
    display: flex;
    flex-direction: column;
    width: 80rem;
    height: 45rem;
    background: #FFF;
    overflow-y: auto;
}

.clientsCadastrationLoading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.clientsCadastrationHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--headerAllBg);
    width: 100%;
    height: 5rem;
    color: var(--headerFontColor);
    font-size: 1.3rem;
}

.clientsCadastrationHeader button{
    display: flex;
    margin-right: 2rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid var(--formBorder);
    color: var(--formBorder);
}

.clientsCadastrationHeader p{
    border-bottom: 0.1rem solid var(--formBorder);
    padding: 1rem;
}

.clientsCadastrationContainer form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
}

.clientsCadastrationContainer form #usersCadastrationFirsDiv{
    display: flex;
    justify-content: flex-start;
    width: 80%;
    align-items: center;
}

.clientsCadastrationContainer form #usersCadastrationFirsDiv label{

    width: 100%;

}

.clientsCadastrationDualMenu {
    display: flex;
    flex-direction: row;    
    width: 80%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.clientsCadastrationContainer label {
    width: 60%;
    font-weight: bold;
    height: 6rem;
}

.clientsCadastrationContainer span {
    color: var(--formFontColor);
    margin-bottom: 0.5rem;
}
.clientsCadastrationContainer form #clientsCadastrationSendButton {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 7.6rem;
    height: 2.8rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.clientsCadastrationContainer  #clientsCadastrationSendButton:hover {
    background-color: var(--buttonBG);
    border: 2px solid var(--goldColor); 
}

.clientsCadastrationContainer #clientsCadastrationSendButton:focus {
    background-color: var(--buttonBG);
    border: 2px solid var(--goldColor); 
}

.clientsCadastrationBody {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (max-width: 1368px){
     .clientsCadastrationContainer {
        display: flex;
        flex-direction: column;
        width: 65rem;
        height: 35rem;
        background: #FFF;
        overflow-y: auto;
    }

    .clientsCadastrationLoading {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    
    .clientsCadastrationHeader{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--headerAllBg);
        width: 100%;
        height: 5rem;
        color: var(--headerFontColor);
        font-size: 1.3rem;
        
    }
    
    .clientsCadastrationHeader p{
        border-bottom: 0.1rem solid var(--formBorder);
        padding: 1rem;
    }
    
    .clientsCadastrationContainer form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
    }
    
    .clientsCadastrationContainer form #usersCadastrationFirsDiv{
        display: flex;
        justify-content: flex-start;
        width: 80%;
        align-items: center;
    }
    
    .clientsCadastrationContainer form #usersCadastrationFirsDiv label{
    
        width: 100%;
    
    }
    
    .clientsCadastrationDualMenu {
        display: flex;
        flex-direction: row;    
        width: 80%;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    
    .clientsCadastrationContainer label {
        width: 60%;
        font-weight: bold;
        height: 5rem;
    }
    
    .clientsCadastrationContainer span {
        color: var(--formFontColor);
        margin-bottom: 0.5rem;
    }
    .clientsCadastrationContainer form #clientsCadastrationSendButton {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        padding: 0.5rem;
        width: 7.6rem;
        height: 2rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.6rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    #clientsCadastrationSendButton:hover {
        background-color: var(--buttonBG);
        border: 2px solid var(--goldColor);
    }
    
    #clientsCadastrationSendButton:focus {
        background-color: var(--buttonBG);
        border: 2px solid var(--goldColor); 
    }
    
    .clientsCadastrationBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    } 
    
}


