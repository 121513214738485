.stepMainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45rem;
    width: 90rem;
    background: white;
    padding: 1rem;
}

#clientsImportButtons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#tableForImportReccords tbody td,
#tableForImportReccords thead th {
    white-space: nowrap;

}

#importClientsProgressBarContainer {

    width: 100%;
    height: 1.5rem;
    border: 1px solid rgb(190, 190, 190);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#progressBarStatus {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: linear-gradient(rgb(2, 148, 51), rgb(32, 187, 83));
    color: white;
    font-size: 1rem;
    font-weight: bold;
    transition: 0.4s;
}

#progressBarStatus a {
    position: absolute;
    margin-right: 0.5rem;
}

.stepperLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.5rem;

}

.fileInput {
    margin-top:1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
    overflow: hidden;
}

#customScroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
#customScroll::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223, 0);
    border-radius: 1px;
}
#customScroll::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent, #909090);
    border-radius: 2px;
}
#customScroll::-webkit-scrollbar-thumb:hover{
    background: linear-gradient(transparent, rgb(100, 100, 100));
}

#clientsImportButtons {
    display: flex;
    flex-direction: column;
}

.stepperDiv {
    width: 100%;  
    display: flex;
    flex-direction: row-reverse;
}

.fileInput #getFile {
    display: none;

}

.fileInput .customButton {

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 1rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
}

.fileInput .customButton:hover {
    background: var(--buttonHover);
    color: white;
}

.fileInput #stepperRowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#stepperRowDiv a{
    font-size: 1.8rem !important;
}


.tableErrorsImport {
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    width: 95%;
    max-height: 30rem;
    overflow: auto;
}

.tableErrorsImport thead{ 

    color: white;
}

.tableErrorsImport table thead tr th {
    text-align: start;
    font-size: 0.8rem;
    white-space: nowrap;
    border: 1px solid rgb(80, 80, 80);
    padding: 0.5rem;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}


.tableErrorsImport thead tr {
  
    height: 1.5rem;
}

.tableErrorsImport tbody tr{ 
    height: 2rem;
}

.tableErrorsImport tbody td {
    border:1px solid var(--tableBorder);
    text-align: start;
    font-size: 1rem;
    white-space: nowrap;
    padding-left: 0.5rem;
}

.tableForImportSectionClients {
    max-width: 90rem;
    max-height: 30rem;
    overflow: auto;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.tableForImportSectionClients table {
    font-size:0.9rem;
    width: 100%;
    height: 100%;
}
    
.tableForImportSectionClients table thead tr{
    
    color: white;
    font-size: 0.9rem;
    height: 1.5rem;
}

.tableForImportSectionClients table thead tr th {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    position: sticky;
    top: 0;
    background: var(--topBarBg);
}

.tableErrorsImport table thead tr th:first-child{
    width: 10rem;
}

.tableErrorsImport table thead tr th:nth-child(2){
    width: 10rem;
}


.tableForImportSectionClients table td {
    padding: 0.5rem;
    width: 10rem !important;
    text-align: center;
    border-top: 0.1rem solid var(--calendarBorder);        
    border-left: 0.1rem solid var(--calendarBorder);        
    border-right: 0.1rem solid var(--calendarBorder);        
}

.stepperImportWithErrors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 90%;
}

.stepperImportWithErrors button {
    background: none !important;
    color: var(--formBorder) !important;
    font-size: 1rem;
    border: 1px solid var(--formBorder) !important;
    width:10rem;
    height:1.8rem;
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 0.2rem;
    border: none;
    margin-right: 0.5rem;
    margin-top: 1rem;
}


.stepperImportWithErrors a {
    font-size: 1.5rem;
    font-weight: 400;
}

.stepperImportWithoutErrors {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepperImportWithoutErrors a {
    font-size: 1.8rem;
    border-bottom: 1px solid var(--goldColor);
}

@media(max-width: 1368px){

    #clientsImportButtons {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    .stepMainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 35rem;
        width: 80rem;
        background: white;
        padding: 1rem;
    }

    .stepperLoader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        font-size: 1.5rem;

    }
    
    .fileInput {
        margin-top:1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        gap: 1rem;
        overflow: hidden;
    }

    .fileInput #stepperRowDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #stepperRowDiv a{
        font-size: 1.6rem !important;
    }
    

    .stepperImportWithErrors button {
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:10rem;
        height:1.8rem;
        font-size: 0.9rem;
        font-weight: 400;
        border-radius: 0.2rem;
        border: none;
        margin-right: 0.5rem;
    }

    
    .stepperImportWithoutErrors {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70%;
    }

    .stepperImportWithoutErrors a {
        font-size: 1.5rem;
        border-bottom: 1px solid var(--goldColor);
    }

    #customScroll::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    #customScroll::-webkit-scrollbar-track{
        background-color: rgb(223, 223, 223, 0);
        border-radius: 1px;
    }
    #customScroll::-webkit-scrollbar-thumb{
        background: linear-gradient(transparent, #909090);
        border-radius: 2px;
    }
    #customScroll::-webkit-scrollbar-thumb:hover{
        background: linear-gradient(transparent, rgb(100, 100, 100));
    }
    
    .stepperDiv {
        width: 100%;  
    }
    
    .fileInput #getFile {
        display: none;
    
    }
    
    .fileInput .customButton {
    
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    font-size: 0.9rem;
    font-weight: 400;
    width: 10rem;
    height: 1.8rem;
    transition: 0.1s;
    background-color: white;
    color: var(--formButtonFontColor)
    }
    
    .customButton:hover {
        background:var(--buttonHover) !important;
        color: white !important;
    }
    
    .customButton {
    
        background: none !important;
        color: var(--formBorder) !important;
        font-size: 1rem;
        border: 1px solid var(--formBorder) !important;
        width:6rem;
        height:2rem;
        border-radius: 0.2rem;
        border: none; 
    }
    
    .tableForImportSectionClients {
        max-width: 75rem;
        max-height: 20rem;
        overflow: auto;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .tableForImportSectionClients table {
        font-size:1rem;
        width: 100%;
        height: 100%;
    }
        
    .tableForImportSectionClients table thead tr{
        
        color: white;
        font-size: 0.9rem;
        height: 1.5rem;
    }

    .tableForImportSectionClients table thead tr th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        position: sticky;
        white-space: nowrap;
        top: 0;
        background: var(--topBarBg);
    }

    .tableErrorsImport table thead tr th:first-child{
        width: 8rem;
    }
    
    .tableErrorsImport table thead tr th:nth-child(2){
        width: 8rem;
    }
    
    .tableForImportSectionClients table td {
        padding: 0.5rem;
        width: 10rem !important;
        text-align: center;
        border-top: 0.1rem solid var(--calendarBorder);        
        border-left: 0.1rem solid var(--calendarBorder);        
        border-right: 0.1rem solid var(--calendarBorder);        
    }

}
