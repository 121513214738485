

.assetsOfferingsDetailsContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
}

#offeringsDetailsObservation {
    width: 100%;
}

.assetsOfferingsDetailsLoader {
    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.assetsOfferingsDetailsHeader {
    background: var(--headerAllBg);
    height: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assetsOfferingsDetailsHeader p {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1rem;
}

.assetsOfferingsDetailsHeader button {
    margin-right: 1rem;
}

.offeringsButton:hover {
    background: var(--goldColor);
    color: black;
}

.assetsOfferingsDetailsHeaderCompanyName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.assetsOfferingsDetailsHeaderCompanyName p {
    font-size: 1.2rem;
    color: black;
}

#assetsOfferingsEditor {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

#offeringsPdfModel {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.assetsOfferingsDetailsPreviewTextContent {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

.assetsOfferingsDetailsBody {

    overflow: auto;
    margin-top: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    font-size: 1.3rem;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

.assetsOfferingsDetailsBody:hover  {
    border: 1px solid rgba(45, 48, 77, 0.3);
}

.assetsOfferingsDetailsBody p {

    font-size: 1.1rem;
}

.assetsOfferingsDetailsFooter {
    transition: 0.2s;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.assetsOfferingsDetailsFooter button:first-child {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    font-size: 1rem;
    font-weight: 400;
    background: var(--buttonHover);
    color: white;
    height: 2rem;
    width: 10rem;
    transition: 0.2s;
}

.assetsOfferingsDetailsFooter button:first-child:hover {
    background-color: white;
    color: var(--formButtonFontColor)
}

.assetsOfferingsDetailsFooter .blackButton {
    align-items: center;
    background: var(--topBarBg);
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    height: 2rem;
    width: 10rem;
    transition: 0.1s;
    color: white;
    transition: 0.2s;
}

#errorMsgFields  {
    font-size: 1rem;
}

.assetsOfferingsDetailsFooter .blackButton:hover {
    color: var(--topBarBg);
    background: white;
    border: 1px solid var(--topBarBg);
}

.assetsOfferingsDetailsReservation {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transition: 0.2s;

}

.assetsOfferingsReservationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    gap: 1rem;
}

.assetsOfferingsReservationCardHeader {
    display: grid;
    width: 100%;
    gap: 1rem;
}

.assetsOfferingsDetailsConfirmButton {
    border: none;
    width: 10rem;
    height: 2.5rem;
    background: var(--goldColor);
    font-size: 1rem;
    color: white;
    font-weight: 400;
}

.assetsOfferingsReservationLabel {
    width: 100%;
}



.assetsOfferingsDetailsTextContent,
.assetsOfferingsDetailsTextContent {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    font-size: 1rem !important;
}

.assetsOfferingsDetailsTextContent ul li{
   list-style: inside !important;
   margin-left: 1rem;
}

.assetsOfferingsDetailsTextContent ol li{
    list-style: decimal !important;
    margin-left: 2rem;
}