.loadingWhiteWOTxt{
    display:inline-block;
    position:relative;
    vertical-align:middle;
    width:  0.9rem;
    height: 0.9rem;
    border:2.85px solid transparent;
    border-top-color:white;
    border-bottom-color:white;
    border-radius:50%;
    animation: rotate 3s linear infinite, textIn 2s linear infinite;
}


.loadingWhiteWOTxt {
  display:inline-block;
  position:relative;
  vertical-align:middle;
  width:  0.9rem;
  height: 0.9rem;
  border:2.5px solid transparent;
  border-top-color: white;
  border-bottom-color:white;
  border-radius:50%;
  animation: rotate 3s linear infinite, textIn 2s linear infinite;

}
  
  @keyframes rotate{
     to { transform: rotate(360deg); }
  }

  @keyframes textIn{
    30%   { opacity: 0; }
    100% { opacity: 1; }
  }

  .container {
      display:flex;
      flex-direction: column;
      align-items:center;
      justify-content:center
  }

 
  .text {
    font-size: 1.5rem;
    color:rgb(122, 122, 122)
  }

  .textGold {
    font-size: 1rem;
    font-weight: 200;
    color:rgb(204, 204, 204)
  }