.brokerPanelLoaderDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#borkerPanelLoading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brokerPanelMainContainer {
    min-width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

#brokerPanelLeftSide {
    -webkit-box-shadow: 7px 4px 18px -10px #000000; 
    box-shadow: 3px 4px 10px -10px #000000;
    border-right: 1px solid rgba(100, 100, 100, 0.274);
    min-width: 300px;
    width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgb(255, 255, 255);
    height: 100%;
}



#brokerPanelLeftSide ::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelLeftSide ::-webkit-scrollbar-track {
    background: #ebebeb !important;
}
  
#brokerPanelLeftSide ::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194) !important;
    transition: 0.2s;
}
  

#brokerPanelLeftSide ::-webkit-scrollbar-thumb:hover {    
    background: rgb(170, 170, 170) !important;
}
   

.brokerPanelLeftMenu {
    
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid rgb(218, 218, 218);
    width: 100%;
}

.brokerPanelLeftMenuLoading {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid rgb(218, 218, 218);
    width: 100%;
}

.brokerPanelLeftMenuLoading label {
    cursor: pointer;
    height: 1.8rem;
    width: 100%;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
}


.brokerPanelLeftMenu h2 {
    font-size: 1.2rem;
    margin-left: 1.5rem;
    font-weight: 500;
}
.brokerPanelLeftMenu a {
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    margin-left: 1.5rem;

}

.brokerPanelLeftTitle {
    font-weight: 400 !important;
    margin-bottom: 0.5rem;
}
.brokerPanelLeftMenu label svg {
    margin-right: 1rem;
}

.brokerPanelLeftMenu label {
    cursor: pointer;
    height: 1.8rem;
    width: 100%;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.brokerPanelLeftMenu label:hover a {
    font-weight: 400;
    width: 100%;
}

.brokerPanelLeftMenu label svg {
    font-size: 1rem;
    
}

.brokerPanelLeftMenu label:hover svg {
    color: rgb(145, 0, 0);
    
}

.brokerPanelLeftMenu label a {
    font-size: 0.9rem !important;
}

.brokerPanelLeftMenu div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

#brokerPanelRightSide {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

#brokerPanelUpperLeftSide {
    box-shadow: 3px 4px 10px -10px #000000;
    background: white;
    border: 1px solid rgba(100, 100, 100, 0.274);
}

#brokerPanelRightSide #brokerPanelUpper {
    gap: 1rem;
    margin-top: 1.3rem;
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    min-height: 12rem;
}

#brokerPanelObjectives {
    padding: 1rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; 
}

#brokerPanelObjectives a {
    border-bottom: 1px solid rgb(207, 207, 207);
    font-size: 1rem;
}

#brokerPanelObjectives a:last-child {
    color: black;
    font-size: 1rem;
    font-weight: 500;
}



#brokerPanelObjectives p {
    font-size: 1.2rem;
    font-weight: 400;
}

#brokerPanelObjectives button {
    width: 8rem;
    height: 1.8rem;
    border-radius: 4px;
    background: var(--topBarBg);
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.2s;
}

#brokerPanelObjectives button:hover {

    color: gray;
}

#brokerPanelObjectives label {

    height: 1.5rem;
    border-bottom: 1px rgb(218, 218, 218);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#brokerPanelCenterButtons {
    margin-bottom: 1rem;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#brokerPanelCenterButtons button {
    font-size: 1rem;
    background: black;
    height: 1.8rem;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 12rem;
    color: white;
    transition: 0.2s;
}

#brokerPanelCenterButtons button a {
    margin-left: 0.5rem;
}

#brokerPanelCenterButtons button svg {
    margin-right: 0.5rem;
}

#brokerPanelCenterButtons button:hover{
    color: gray;
}

#brokerPanelUpperRightSide {
    background: white;
    box-shadow: 3px 4px 10px -10px #000000;
    border: 1px solid rgba(100, 100, 100, 0.274);
    padding: 1rem;
}

.brokerPanelDivButtons {
    margin-left: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#brokerPanelbottom {
    height: 38rem;
    background: white;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 90%;
    -webkit-box-shadow: 7px 4px 18px -10px #000000; 
    box-shadow: 3px 4px 10px -10px #000000;
    border: 1px solid rgba(100, 100, 100, 0.274);
}

#brokerPanelbottom h2 {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

#brokerPanelbottonTradeIdeaHeader h2 {
    margin-left: 1rem;
    font-weight: 400;
}

#brokerPanelbottonTradeIdeaHeader button {
    margin-right: 1rem;
    width: 8rem;
    height: 2rem;
    background: var(--topBarBg);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    transition: 0.2s;
}

#brokerPanelbottonTradeIdeaHeader button:hover {
    color: gray;
}

#brokerPanelbottonTradeIdeaHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#brokerPanelTextGroupDiv {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}

#brokerPanelTextGroupDiv label {
    padding: 0.5rem;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#brokerPanelTextGroupDiv label a:first-child {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0.8rem;
}

.brokerPanelTextDiv {
    
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    overflow-x: hidden;
    word-break: break-all;
    max-width: 100%;
    max-height: 150px;
    width: 100%;
}

.brokerPanelTextDiv::-webkit-scrollbar {
    width: 10px;
}
  
.brokerPanelTextDiv::-webkit-scrollbar-track {
    background: #d3d3d3;
}
  
.brokerPanelTextDiv::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    transition: 0.2s;
}
  

.brokerPanelTextDiv::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

#brokerPanelTextGroupDiv {
    display: grid;
    width: 95%;

}

#brokerPanelTextGroupDiv::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelTextGroupDiv::-webkit-scrollbar-track {
    background: #d3d3d3;
}
  
#brokerPanelTextGroupDiv::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    transition: 0.2s;
}
  

#brokerPanelTextGroupDiv::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}

.brokerPanelTradeIdeaContainerDiv {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(214, 214, 214);
    transition: 0.2s;
    padding: 0.5rem;
}

.brokerPanelTradeIdeaContainerDiv:hover {
    border: 1px solid rgb(177, 177, 177);
}

.brokerPanelTradeIdeaContainerDiv svg {
    transition: 0.2s;
}

.brokerPanelTradeIdeaContainerDiv svg:hover {
    color: rgb(168, 2, 2);
}

.brokerPanelTradeIdeaContainerDiv label {
    width: 100%;
}


#brokerPanelReports {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid rgba(100, 100, 100, 0.274);
    border-top: 1px solid rgba(100, 100, 100, 0.274);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

#brokerPanelReportsCotent {
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 4rem;
    overflow-x: hidden;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#brokerPanelReports ::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelReports ::-webkit-scrollbar-track {
    background: #e0e0e0;
}
  
#brokerPanelReports ::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
    transition: 0.2s;
}
  

#brokerPanelReports ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}



#brokerPanelReports label {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

#brokerPanelReports h3 {
    margin: 0;
    color: rgb(194, 174, 1);
    font-size: 1rem;
    white-space: nowrap;
}

#brokerPanelReports table td {
    transition: 0.2s;
    text-align: center;
    height: 1.2rem;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
}


#brokerPanelReports table tr:hover {
    color: rgb(189, 189, 189)
}

#brokerPanelBottonOvergflownedPanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}


#brokerPanelbottom ::-webkit-scrollbar {
    width: 10px;
}
  
#brokerPanelbottom ::-webkit-scrollbar-track {
    background: #e0e0e0;
}
  
#brokerPanelbottom ::-webkit-scrollbar-thumb {
    background: rgb(206, 206, 206);
    transition: 0.2s;
}
  

#brokerPanelbottom ::-webkit-scrollbar-thumb:hover {
    background: rgb(190, 190, 190);
}

#brokerPanelRecommendedWallet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#brokerPanelRecommendedWallet a {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: 1px solid gray;
}

#brokerPanelRecommendedWallet a:hover {
    color: rgb(189, 189, 189);
    cursor: pointer;
}

#brokerPanelRecommendedWallet h3 {
    margin: 0;
    color: rgb(194, 174, 1);
    font-size: 1rem;
    white-space: nowrap;
}

#brokerPanelLeftSide::-webkit-scrollbar-track {

    background: #e0e0e0;
}

#brokerPanelLeftSide::-webkit-scrollbar-thumb {

    background: rgb(206, 206, 206);
    transition: 0.2s;
}

#brokerPanelLeftSide::-webkit-scrollbar-thumb:hover {

    background: rgb(190, 190, 190);
}

#brokerPanelNewLinkButtonDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

#brokerPanelNewLinkButtonDiv button {

    width: 100%;
    color: white;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    border: none;
    height: 2rem;
    background-color: var(--topBarBg);
    transition: 0.2s;
}

#brokerPanelNewLinkButtonDiv button:hover{

    color: gray;
}

.tileLoaderGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 90%;
}

#tradeIdeasLoaderGroup {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brokerPanelUpdateViaImport {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.containerTopButtons{
    width: 100%;
    height: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.buttonRecadosGraficos{
    border-radius: 0px;
    font-size: 1.6rem;
    border: none;
    background-color: #c8c8c8;
    transition: all ease-in-out 0.2s;
    border-bottom: 2px solid #c8c8c8
}

.buttonRecadosGraficos:hover{
    background-color: #a7a7a7;
    border: none;
    border-bottom: 3px solid #a7a7a7
}

.buttonRecadosGraficosACTIVE{
    border-radius: 0px;
    font-size: 1.6rem;
    border: none;
    background-color: #b3b3b3;
    transition: all ease-in-out 0.1s;
    border-bottom: 3px solid #ffd900
}

.switchButtonsRecadosGraficos{
    border-radius: 0px;
    font-size: 1.6rem;
    border: none;
    background-color: #b3b3b3;
    transition: all ease-in-out 0.1s;
    border-bottom: 3px solid #ffd900;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    cursor: pointer;
}

.conatinerRecadosTrades{
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 90%;
    margin-top: 1rem;
}

.containerTextoRecadosTrades{
    background-color: #fff;
    border-radius: 2px;
    overflow: auto;
    overflow-x: hidden;
}

.containerRecados{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerTrades{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tituloRecadosTrades{
    font-size: 1.2rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-weight: 400;
    text-align: center;
}

.itemRecados{
    text-align: center;
    font-size: 0.9rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #fff;
    font-weight: 300;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 3px 6px #b3b3b3;
    width: fit-content;
}

.itemRecados:hover{
    background-color: #d8d8d8;
    box-shadow: 0px 3px 6px #747474;
}

.itemTrades{
    text-align: center;
    font-size: 0.9rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #fff;
    font-weight: 300;
    border-radius: 3px;
    border: none;
    box-shadow: 0px 3px 6px #b3b3b3;
    width: fit-content;
}

.itemTrades:hover{
    background-color: #d8d8d8;
    box-shadow: 0px 3px 6px #747474;
}

.ql-toolbar{
    height: 3rem;
}

.quill{
    height: calc(100% - 7.4rem);
}

.ql-tooltip{
    top: -10px !important;
    left: 0px !important;
    right: 0px !important;
    text-align: center;
}

.itemPlus{
    padding: 0.2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border: none;
    background: transparent;
}
.itemPlus:hover{
    color: #000000;
}

.containerTituloEditFalse{
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    height: 4.4rem;
    overflow: hidden;
}

.containerTituloEditTrue{
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    height: 4.4rem;
    overflow: hidden;
}

.tituloRecadoTrades{
    font-size: 1.6rem;
    text-align: center;
    height: 3rem;
    margin-top: 1.4rem;
}

.editTitleButton{
    padding: 0.4rem;
    border: none;
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-right: 1rem;
    border-radius: 8px;
    background-color: #fff;
}

.editTitleButton:hover{
    background-color: #e7e7e7;
}

.inputEditTitle{
    height: 2rem;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
    text-align: center;
    font-size: 1.5rem;
}