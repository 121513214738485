.abcMainTableAbc{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  /* margin-top: 2rem; */
}

.abcManagementMainTableAbc{
  margin-left: 2rem;
  margin-right: 2rem;
  /* margin-top: 2rem; */
}

.abcManagementMainTableBoxAbc {
  padding-top: 2rem;
}
body{
  background-color: #FDFDFD;
}

.abcMainTableBox{
  padding-top: 2rem;
}

.headerProductCellBoxAbc{
  background-color: #fffcef;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-right: 0.5rem;
  /* margin-left: 0.5rem; */
  /* border-left: 1px solid black !important; */
  padding: 0.6rem;
}
.headerProductCellBoxAbc:hover{
  cursor: pointer;
}

.titleCellBoxAbc{
  display: grid;
  min-width: fit-content;
  white-space: nowrap;;
  grid-template-columns: auto 1fr auto;
}

.containerAderenceIconsAbc{
  display: grid;
  background-color: #fffcef;
  padding-top: 0.4rem;
  margin-right: 0.5rem;
  height: 2rem;
}

.aderenceIconsAbc{
  width: fit-content;
  height: fit-content;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.IconsAbc{
  height: fit-content;
}

.aderenceIconsAbcActive{
  color: #00801C
}

.aderenceIconsAbcInactive{
  color: #797979
}

.productTitleAbc{
  width: fit-content;
  height: fit-content;
  display: table;
  text-align: center;
  white-space: nowrap;
  margin: auto;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.modalActionsIconsAbc{
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  margin-left: auto;
  padding-right: 0.6rem;
}

.statusDivAbc{
  background-color: #fffdf9;
  color: #141414;
  border-radius: 4px;
  vertical-align: middle;
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  padding-right: 0.3rem;
  margin-left: 0.2rem;
  margin-right: 0.4rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 4px #c0b091;
}

.statusDivAbc:hover{
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
}

.containerIconStatusAbc{
  width: 1.3rem;
  height: fit-content;
  margin: auto;
  display: grid;
}

.textStatusAbc{
  margin: auto;
  width: fit-content;
  height: fit-content;
}

.iconStatusAbc{
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.subtitleCellBoxAbc {
  display: grid;
  padding-top: 0.4rem;
}

.subtitleObjectiveAbc{
  display: table;
  text-align: center;
}

.subtitleActionsAbc{
  display: table;
  text-align: center;
}

.arrowSortAbc{
  display: table;
  text-align: center;
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin: auto;
}

.lineProductsAbc{
  display: grid;
  grid-template-columns: 4fr 3fr;
  background-color:#fffcef;
}

.lineProductsActionsAbc{
  display: grid;
}

.statusColorDivAbc{
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 5px;
  position: relative;
  /* z-index: 10; */
}

.actionDivAbc{
  width: fit-content;
  height: 14px;
  margin: auto;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  background-color: #fffdf9;
  color: #141414;
  border-radius: 2px;
  font-size: 0.7rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 2px #c0b091;
}

.actionDivAbc:hover{
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
}

.leftTesteAbc{
  background: white;
  width: fit-content;
  padding: 0rem !important;
  border-left: 0.5rem solid white !important;
  position: sticky; 
  top: 0; 
  z-index: 1
}

.rightTesteAbc{
  background: white;
  width: fit-content;
  padding: 0rem !important;
  border-right: 0.5rem solid white !important;
  position: sticky; 
  top: 0; 
  z-index: 1
  /* margin-left: 1px solid black !important; */
}


.testeAbc{
  background: white;
  width: fit-content;
  padding: 0rem !important;
  /* white-space: nowrap !important;
  display: table-cell !important;
  text-align: center !important; */
  /* margin-left: 1px solid black !important; */
  position: sticky; 
  top: 0; 
  z-index: 1
}

/* .testedeHeaderAbc{
  background-color: #00801C;
  white-space: nowrap;
  display: table-cell;
  text-align: center;
} */

.lineProductsAbcNetContainer{
  display: grid;
  grid-template-columns: 1fr auto;
}

.marginValueObjetivo{
  background-color: #fffcef;
  padding-right: 0.4rem;
  padding-left: 0.6rem;
}

.legendsFooterAbcBox{
  display: grid;
  grid-template-columns: auto auto auto;
}

.legendFooterAbc{
  margin-left: 1rem;
}

.legendFooterItemsGeralAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.legendFooterItemsStatusAbc{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.legendFooterItemsAderenciaAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.legendFooterItemsAbc{
  display: grid;
  grid-template-columns: auto 1fr;
  padding-right: 0.3rem;
}

.statusColorFooterDivAbc{
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 5px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  border: 2px solid #E9E9E9;
}

.aderenceIconsFooterAbcActive{
  color: #00801C
}

.aderenceIconsFooterAbc{
  padding-top: 0.15rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.cellHoverAbc {
  background-color: #BBD2E3;
  border-bottom: "1px solid rgba(224, 224, 224, 1)";
  border-top: "1px solid rgba(224, 224, 224, 1)";
}

.btnsBoxAbc {
  display: grid;
  grid-template-columns: auto auto;
  /* justify-items: center; */
  /* padding-right: 2rem; */
}

.btnMngAbc {
  width: 3rem;
  height: 1rem;
  margin: auto;
  padding: 0.3rem;
  background-color: #fffdf9;
  color: #141414;
  border-radius: 2px;
  font-size: 1rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 2px #c0b091;
}

.btnMngAbc:hover {
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
}


/* .actionDivAbc{
  width: fit-content;
  height: 14px;
  margin: auto;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  background-color: #fffdf9;
  color: #141414;
  border-radius: 2px;
  font-size: 0.7rem;
  transition: all ease 0.1s;
  box-shadow: 0px 0px 2px #c0b091;
}

.actionDivAbc:hover{
  background-color: #141414;
  color: #fffdf9;
  cursor: pointer;
} */
