.brokersTableContainer{
    height: 30rem;
    width: 60rem;
    background: white;
    display: flex;
    flex-direction: column;
}

.brokersTableLoader{
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.brokersTableHead {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    background: var(--headerAllBg);
}

.brokersTableHead p {
    font-size: 1.2rem;
    border-bottom: 1px solid var(--goldColor);
}

.brokersTableHead div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.brokersTableHead div p:last-child{
    font-weight: 500;
}

.brokersTableHead button {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.brokersTableHead button:hover{
    background-color: var(--buttonBG);
}

.brokersTableDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(100% - 10rem);
}

.brokersTableBody {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-height: 20rem !important;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.brokersTableBody table {
    height: auto !important;
    
}

.brokersTableBody thead tr th {
    position: sticky;
    top: 0;
    background: var(--tableSecondTHGray);
    color: white;
    font-weight: 300;
    font-size: 1.2rem !important;
}

.brokersTableBody tbody tr {
    height: 2rem !important;
}

.brokersTableBody tbody td {
    font-size: 1.2rem;
    text-align: center;
    height: 2rem !important;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
}

.brokersTableDiv button {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.brokersTableDiv button:hover {
    background: var(--buttonBG);
}

@media (max-width:1368px){
    .brokersTableContainer{
        height: 30rem;
        width: 60rem;
        background: white;
        display: flex;
        flex-direction: column;
    }
    
    .brokersTableLoader{
        height: 100%;
        width: 100%;
        display: flex;
        font-size: 1.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .brokersTableHead {
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 4rem;
        background: var(--headerAllBg);
    }
    
    .brokersTableHead p {
        border-bottom: 1px solid var(--goldColor);
    }

    .brokersTableHead div p {
        font-size: 1.1rem !important;
    }
    
    .brokersTableHead div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }
    
    .brokersTableHead div p:last-child{
        font-weight: 500;
    }
    
    .brokersTableHead button {
        outline: none;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1.2rem;
        padding: 0.5rem;
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        border-radius: 50%;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    .brokersTableHead button:hover{
        background-color: var(--buttonBG);
    }
    
    .brokersTableDiv {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: calc(100% - 10rem);
    }
    
    .brokersTableBody {
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        max-height: 20rem !important;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    
    .brokersTableBody table {
        height: auto !important;
        
    }
    
    .brokersTableBody thead tr th {
        position: sticky;
        top: 0;
        background: var(--tableSecondTHGray);
        color: white;
        font-weight: 300;
        font-size: 1rem !important;
    }
    
    .brokersTableBody tbody tr {
        height: 1.8rem !important;
    }
    
    .brokersTableBody tbody td {
        font-size: 1rem;
        text-align: center;
        height: 1.8rem !important;
        border-bottom: 1px solid var(--tableBorder);
        border-right: 1px solid var(--tableBorder);
    }
    
    .brokersTableDiv button {
        outline: none;
        cursor: pointer;
        border: 1px solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem;
        width: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    .brokersTableDiv button:hover {
        background: var(--buttonBG);
    }
}