
  .brokerProfilesMainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  #newBrokerProfile:focus {
    background: var(--goldColor);
    color: black;
  }

  #newProfileSubmitButton:focus {
    border: 2px solid var(--formBorder);
    font-size: 400;
  }
  
  #brokerProfilesLoader {
    height: 15rem;
  }

  .brokerProfilesBody {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .brokerProfilesTableContainer {
    overflow-x: hidden;
    max-height: 30rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    border-radius: 0.5rem 0 0 0;
  }
    
  
  .brokerProfilesTableContainer table {
    width: 100%;
    
  }

  .theadTitle{
    width: 100%;
    display: flex;  
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background: var(--topBarBg) !important;
  }

  .brokerProfilesBody table thead tr:first-child th div p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1.5rem;
  }

  .brokerProfilesBody table thead tr:first-child th div button {
    margin-left: 2rem;
    margin-right: 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
  }
  .brokerProfilesBody table thead tr:first-child th div button:hover{
    background: var(--formBorder);
    color: black;
  }

  .brokerProfilesBody table thead th {
    position: sticky;
    top:0;
    background: var(--tableSecondTHGray);
    height: 1.5rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
  }
  
  .brokerProfilesBody table tbody td {
    background: white;
    cursor: default;
    text-align: center;
    height: 2rem;
    font-size: 1rem;
    transition: 0.1s;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
  }
  
  .brokerProfilesBody table #lastTd {
    width: 7rem;
  }
  
  .brokerProfilesBody table tbody tr:hover {
    background: var(--tableHover);
  }
  
  .brokerProfilesCadastration {
    width: 65rem;
    height: 35rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .brokerProfilesCenterItens{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  .brokerProfilesCenterItens button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    font-weight: 500;
    width: 7rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .brokerProfilesCenterItens p {
    font-size: 1.5rem !important;
    font-weight: 400;
    border-bottom: 1px solid var(--goldColor);
  }
  
  .brokerProfilesCenterItens button:hover{
    background-color: var(--buttonBG);
  }
  
  .borkerProfilesCadastrationHeader {
    background: var(--headerAllBg);
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .borkerProfilesCadastrationHeader p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
    margin-left: 1rem;
  }
  
  .borkerProfilesCadastrationHeader button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.8rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .borkerProfilesCadastrationHeader button:hover{
    background-color: var(--buttonBG);
  }
  
  .brokerProfileCadastrationBody {
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 4rem);
  }
  
  .brokerProfileCadastrationBody form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 2rem;
  }
  
  .brokerProfileCadastrationBody form label {
  
    width: 70%;
  
  }
  
  


@media(max-width:1368px){


  .brokerProfilesMainContainer {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  
  #brokerProfilesLoader {
    height: 15rem;
  }

  .brokerProfilesBody {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .brokerProfilesTableContainer {
    overflow-x: hidden;
    max-height: 30rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    border-radius: 0.5rem 0 0 0;
  }
    
  
  .brokerProfilesTableContainer table {
    width: 100%;
    
  }

  .theadTitle{
    width: 100%;
    display: flex;  
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background: var(--topBarBg) !important;
  }

  .brokerProfilesBody table thead tr:first-child th div p {
    font-size: 1.2rem !important;
    font-weight: 300;
    margin-left: 1.5rem;
  }

  .brokerProfilesBody table thead tr:first-child th div button {
    margin-left: 2rem;
    margin-right: 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    width: 8rem;
    height: 1.8rem;
    transition: 0.1s;
    color: var(--formButtonFontColor)
  }
  .brokerProfilesBody table thead tr:first-child th div button:hover{
    background: var(--formBorder);
    color: black;
  }

  .brokerProfilesBody table thead th {
    position: sticky;
    top:0;
    background: var(--tableSecondTHGray);
    height: 1.5rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
  }
  
  .brokerProfilesBody table tbody td {
    cursor: default;
    text-align: center;
    height: 1.8rem;
    font-size: 1rem;
    transition: 0.1s;
    border-bottom: 1px solid var(--tableBorder);
    border-right: 1px solid var(--tableBorder);
  }
  
  .brokerProfilesBody table #lastTd {
    width: 7rem;
  }
  
  .brokerProfilesBody table tbody tr:hover {
    background: var(--tableHover);
  }
  
  .brokerProfilesCadastration {
    width: 50rem;
    height: 25rem;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .brokerProfilesCenterItens{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  .brokerProfilesCenterItens button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.9rem;
    font-weight: 500;
    width: 7rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .brokerProfilesCenterItens p {
    font-size: 1.5rem !important;
    font-weight: 400;
    border-bottom: 1px solid var(--goldColor);
  }
  
  .brokerProfilesCenterItens button:hover{
    background-color: var(--buttonBG);
  }
  
  .borkerProfilesCadastrationHeader {
    background: var(--headerAllBg);
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .borkerProfilesCadastrationHeader p {
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.2rem !important;
    margin-left: 1rem;
  }
  
  .borkerProfilesCadastrationHeader button {
    margin-right: 1rem;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 0.8rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.1s;
    color: var(--formButtonFontColor);
  }
  
  .borkerProfilesCadastrationHeader button:hover{
    background-color: var(--buttonBG);
  }
  
  .brokerProfileCadastrationBody {
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 4rem);
  }
  
  .brokerProfileCadastrationBody form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 2rem;
  }
  
  .brokerProfileCadastrationBody form label {
  
    width: 70%;
  
  }

}