.tdTaxas{
    padding: 0px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    min-width: fit-content !important;
    text-align: center !important;
    border-bottom: 0px !important;
}

.thTaxas{
    text-align: center  !important;
    min-width: fit-content !important;
}

.tr2Taxas{
    display: flex !important;
    justify-content: space-evenly !important;
    min-width: fit-content !important;
}

.th2Taxas{
    border-bottom: 0px !important;
    text-align: center  !important;
    min-width: fit-content !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
    padding: 0 !important;

}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root  css-i4bv87-MuiSvgIcon-root{
    width: 50px !important;
    height: 50px !important;

}

.css-1m13d3u-MuiTableCell-root{
    font-size: 0.8rem !important;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root{
    font-size: 0.8rem !important;
}