.companiesContainer {
    
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companiesTable {
    padding: 2rem;
    max-height: calc(100% - 98px);
}

.companiesModal {
    width: 80rem;
    height: 40rem;
    background: white;
    overflow-y: auto;
}

@media(max-width: 1368px){
    .companiesModal {
        width: 50rem;
        height: 35rem;
        background: white;
        overflow-y: auto;
    }
}
