.myPushOrdersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    transition: .2s;
}

.myPushOrdersContainer table {
    -webkit-box-shadow: 0px 6px 20px -9px #000000; 
    box-shadow: 1px 3px 20px -9px #000000;
}

.substitutoLabelDisplay{
    display: flex;
}

.substitutoLabelDisplay > *{
    margin-right: 0.6rem;
}

.substitutoLabelDisplay input{
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}