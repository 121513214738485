.myClientsArrowLoader {
    
    height: 30rem;
    
}

#myClientsTableNameAndTotalClients {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#myClientsTableNameAndTotalClients a:first-child {
  font-size: 1.2rem;
  font-weight: 300;
}

#myClientsTableNameAndTotalClients a:nth-child(2){
  font-size: 0.9rem;
  font-weight: 200;
}

#myClientsEmptyRow{
    height: 2rem;
    font-size: 1.2rem !important;
}

.myClientsTableContainer  {
    
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    border-radius: 0.5rem 0 0 0;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myClientsTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.myClientsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.myClientsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.myClientsTableContainer thead tr:first-child th {
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.myClientsTableContainer thead tr:nth-child(2) th {
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:first-child th {
    width: 120rem;
}

.myClientsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.myClientsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.myClientsTableContainer tbody tr:hover{
    background: var(--tableHover);
    cursor: pointer;
}

.myClientsTableContainer tbody td {
    padding: 0;
    max-width: 6rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* width: 24rem; */
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
}

.myClientsTableContainer .myClientsTheadFirst{
    display: flex;
    flex-direction: row;
}

.myClientsTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myClientsTheadFirst a {
    margin-left: 2rem;
}

.myClientsTheadFirst button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.myClientsTheadFirst label:nth-child(2){
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap:0.5rem;
}

.myClientsTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.myClientsTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.myClientsTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.myClientsTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 0.5rem;
}

.css-ubpweo-MuiPaper-root-MuiPopover-paper{
  min-width: 30vw !important;
}

#myClientsShowLoadingScroll{
    background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
}

#myClientsHideLoadingScroll {
    display: none;
}

.modalMyClients {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.ldsellipsis {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
  }
  .ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }


@media (max-width: 1368px){
.modalMyClients{
  width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.myClientsArrowLoader {

    height: 20rem;
    
}

#myClientsEmptyRow{
    height: 2rem;
    font-size: 1.2rem !important;
}

.myClientsTableContainer  {
    
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: 95%;
    border-radius: 0.5rem 0 0 0;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.myClientsTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.myClientsTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.myClientsTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
}

.myClientsTableContainer thead tr:first-child th {
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.myClientsTableContainer thead tr:nth-child(2) th {
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.myClientsTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
}


.myClientsTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    font-size: 1rem;
    font-weight: 300;
}

.myClientsTableContainer tbody {
    background: white;
    overflow-y: scroll;
    transition: 0.2s;
}

.myClientsTableContainer tbody tr:hover{
    background: var(--tableHover);
}

.myClientsTableContainer tbody td {
    padding: 0;
    max-width: 6rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    height: 2rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
}

.myClientsTableContainer .myClientsTheadFirst{
    display: flex;
    flex-direction: row;
}

.myClientsTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.myClientsTheadFirst a {
    margin-left: 2rem;
}

.myClientsTheadFirst button {
    margin-left: 2rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.myClientsTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.myClientsTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.myClientsTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.myClientsTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}

#myClientsShowLoadingScroll{
    background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
}

#myClientsHideLoadingScroll {
    display: none;
}

.ldsellipsis {
    display: inline-block;
    position: relative;
    width: 1rem;
    height: 1rem;
  }
  .ldsellipsis div {
    position: absolute;
    top: 0.4rem;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--headerFontColor);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .ldsellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .ldsellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

}