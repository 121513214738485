.assetsOfferingsNewTableContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.assetsOfferingsFilterHeader {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.83); 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    z-index: 99;

}

.assetsOfferingsFilterHeader p{
 font-size: 1.2rem;
 font-weight: 600;
 padding: 1rem;
}

.assetsOfferingsCardBodyContainer {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;   
    gap: 1.5rem;
    width: 80%;
    min-width: 65rem;
}

.assetsOfferingsCard {

    width: 100%;
    /* height: 40rem; */
    border-radius: 4px;
    background: white;
    color: rgb(87, 87, 87) !important;
    display: flex;
    flex-direction: column;
    border: none;
    -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.83); 
    box-shadow: 0px 2px 10px -7px rgba(0,0,0,0.83);
    transition: 0.3s;
    margin-bottom: 1rem;
}

.assetsOfferingsRecommendationLabel {
    background: rgb(1, 102, 48);
    border-radius: 15px;
    height: 1.3rem;
    width: 5.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.assetsOfferingsButtonLabels {
    display: flex;
    flex-direction: row !important;   
    gap: 0.5rem;
    justify-content: space-between
}

.assetsOfferingsButtonLabels:button:last-child {
    /* margin-right: 1rem; */
}

.assetsOfferingsCardBody textarea {
    border: 1px solid gray;
    background: #dbdbdb;
    height: 2.1rem;
    outline: none;

}

.assetsOfferingsCard:hover {
    -webkit-box-shadow: 0px 2px 25px -13px #302d6f;
    box-shadow: 0px 2px 15px -7px #302d6f;
    
}

.assetsOfferingsCardHeader {
    border-radius: 10px 10px 0px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 2rem;
    height: 4rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.assetsOfferingsCardHeader label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;

}

.assetsOfferingsCardHeader label a:first-child {
    font-weight: 400;
    font-size: 1rem;    
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
}

.assetsOfferingsCardHeader label a:nth-child(2){
   
    font-size: 1rem;
    font-weight: 400;
}

.assetsOfferingsCardBody {
    border: 1px solid rgba(0, 0, 0, 0.116);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: grid;
    margin-top: 1rem;
    grid-template-columns: repeat(2,1fr);
    align-items: flex-start;
    gap: 1.5rem;
}

.assetsOfferingsCardFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.assetsOfferingsBlueButton {
    border: none;
    height: 2rem;
    width: 14rem;
    margin-right: 4rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.assetsOfferingsBlueButtonTrash {
    border: none;
    height: 2rem;
    width: 5rem;
    background: rgba(37, 64, 109, 1);
    color: white;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 2px;
    height: 2.5rem;
    font-weight: 500;
    transition: 0.2s;
}

.assetsOfferingsCardHeaderOptions {
    display: grid;
    width: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    
}

.assetsOfferingsCardBodySelected {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    align-items: flex-start;
    gap: 1.5rem;
    border-top: 1px solid rgba(128, 128, 128, 0.322);
}


.assetsOfferingsCardBodyDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgb(230, 227, 227);
}

.assetsOfferingsCardBodyDetails a {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.assetsOfferingsCardBodyDetails a:first-child {
    font-weight: 300;
    margin-left: 1rem;
}

.assetsOfferingsCardBodyDetails a:nth-child(2){
    margin-right: 1rem;
    font-weight: 600;
}

.assetsOfferingsCardBodyDetailsLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.assetsOfferingsCardBodyDetailsLine a:first-child {
    font-weight: 400;
    margin-left: 1rem;
    margin-top: 1rem;
}

.assetsOfferingsCardBodyDetailsLine a:nth-child(2){
    margin-right: 1rem;
}


.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(35, 37, 7);
    border-radius: 1px;
}
.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb{
    background:  rgba(225, 225, 226, 0.466);
    border-radius: 2px;
}
.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover{
    background: rgb(156, 84, 84);
}

.assetsOfferingsCardBodyDetailsText {
    background: #f5f5f5;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: flex;
    word-break: break-all;
    width: 100%;
    gap: 0.3rem;
    padding-bottom: 1rem;
    max-height: 10rem;
    min-height: 10rem;
    flex-direction: column;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar {
    width: 10px;
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-track{
    background-color: rgb(231, 231, 231);
    border-radius: 1px
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 20%;
}

.assetsOfferingsCardBodyDetailsText::-webkit-scrollbar-thumb:hover {
    background: rgb(204, 204, 204);
}

.assetsOfferingsCardBodyDetailsText a:first-child {
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    white-space: nowrap;

}

.assetsOfferingsCardBodyDetailsText a:nth-child(2) {
    font-size: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;

}

.assetsOfferingsCardBodyLeftGrid {
    display: flex;
    flex-direction: column;
}

.assetsOfferingsReservDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}

.assetsOfferingsReservConfirmation {
    background: rgba(37, 64, 109, 1);
    border: none;
    color: white;
    font-weight: 500;
    height: 2.5rem;
    width: 20%;
    font-size: 1rem;
    transition: 0.2s;
}

#assetsOfferingsReservationLabel {
    width: 100%;
}

.assetsOfferingsReservConfirmation:hover,
.assetsOfferingsReservConfirmation:focus  {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(37, 64, 109, 1);
    box-shadow: 0px 0px 12px 0px rgba(37, 64, 109, 1);
}

.assetsOfferingsCompanyName {
    cursor: pointer;
    transition: 0.2s;
}

.assetsOfferingsCompanyName:hover a{
    color: black;
}

#assetsOfferingsButtonLabelsRecomendation {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    margin-right: 2rem;
}

#assetsOfferingsButtonsRequest {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.offersValueTag {
    background: #302d6f;
    width: 4rem;
    height: 1.5rem;
    color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    margin-left: 1rem;

}

.offersValueTag a{
    margin: 0 auto;
    padding: 0;
    font-size: 1rem;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 1368px){

    .assetsOfferingsCardBodyContainer {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        gap: 1.5rem;
        width: 85%;
    }
}
.offeringsRequestBlueButtonDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.assetsOfferingsEmptyOffers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.assetsOfferingsEmptyOffers p {
    font-size: 1.5rem;
    font-weight: 500;
}

.assetsOfferingsLoader {

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}
