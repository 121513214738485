.confirmationBoxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    width: 100vh;
    gap: 4rem;
    background: white;
}

.confirmationBoxMsgContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: center;
    padding: 1rem;
}
.confirmationBoxContainer .confirmationBoxMsgContainer #info {
    font-size: 1.5rem !important;
    font-weight: 400;
    border-bottom: 1px solid var(--goldColor);
}

.confirmationBoxContainer h2{
    font-size: 1.5rem;
    margin: 0 !important;
}


.confirmationBoxContainer p{
    font-size: 1.2rem;
}

.confirmationBoxButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 2rem;

}

.confirmationBoxButtons button {
    outline: none;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    padding: 0.5rem;
    width: 6.8rem;
    height: 2.4rem;
    transition: 0.1s;
    color: var(--formButtonFontColor);
}

.confirmationBoxButtons button:hover {
    background: var(--buttonHover);
}

.confirmationBoxButtonHide {
    display: none;
}

@media(max-width: 1368px){

    .confirmationBoxContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 40vh;
        width: 40vw;
        gap: 2rem;
        background: white;
    }
    
    .confirmationBoxMsgContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        justify-content: center;
        padding: 1rem;
    }
    .confirmationBoxContainer .confirmationBoxMsgContainer #info {
        font-size: 1.2rem !important;
        font-weight: 300;
    }
    
    .confirmationBoxContainer h2{
        font-size: 1.5rem;
    }
    
    
    .confirmationBoxContainer p{
        font-size: 1.2rem;
    }
    
    .confirmationBoxButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 2rem;
    
    }
    
    .confirmationBoxButtons button {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        padding: 0.5rem;
        width: 6.8rem;
        height: 2.4rem;
        transition: 0.1s;
        color: var(--formButtonFontColor);
    }
    
    .confirmationBoxButtonHide {
        display: none;
    }
    
    .confirmationBoxContainer p{
        font-size: 1rem !important;
    }
}