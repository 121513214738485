#pushOrdersTable td {
    text-align: center;
    height: 1.8rem;
    margin: 0 !important;
    padding: 0;
    background: white;
    font-size: 1rem;
    z-index: 1;
    border: 2px solid var(--tableBorder);
}

#pushOrdersTable td {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}


#pushOrdersTable td:first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15rem;
}

.pushOrdersTableLoader {
    display: flex;
    height: 100%;
}

#pushOrdersTable td:first-child {
    text-align: initial;
}

#pushOrdersTable td:first-child a {
    padding-left: 16px;
}

#pushOrdersTable td input {
    height: 1.6rem;
    border-radius: 0;
    border: none;
    background: #00000000;
    width: calc(100% - 16px);
    padding-left: 16px;
    font-size: 1rem;
    transition: 0.3s;
    overflow-x: hidden;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    outline: none;
}

#pushOrdersTable .MuiInputBase-input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
#pushOrdersTable .pushOrdersButtonTd {
    transition: 0.2s;
}

#pushOrdersTable .pushOrdersButtonTd:hover {
    cursor: pointer;
    background: rgb(195, 195, 195);
}

#pushOrdersTable .pushOrdersButtonTd:hover label a {
    cursor: pointer;
}

.pushOrdersCheckBoxInactive {
    background: rgb(202, 202, 202);
    width: 1.8rem;
    height: 1.8rem;
    border: none;
    transition: 0.2s;
}

.pushOrdersCheckBoxInactive:hover {
    filter: brightness(0.9);
}

.pushOrdersCheckBoxActive {
    background: #1E80EF;
    width: 1.8rem;
    height: 1.8rem;
    border: none;
    transition: 0.2s;
}

.pushOrdersCheckBoxActive:hover {
    filter: brightness(0.9);
}

.pushOrdersCheckBoxActive a {
    color: white;
}

.errorThisRow {
    -webkit-box-shadow: inset -1px 0px 24px 0px #ffffff; 
    box-shadow: inset 0px 0px 4px 1px #d10000;
}
.pushOrdersTable .pushOrdersSimpleInput:hover{
    border-bottom: 2px solid #070808 !important;
    margin-bottom: 0px;
}
.pushOrdersTable .pushOrdersSimpleInput:focus {
    border-bottom: 2px solid #07080800 !important;
}

#pushOrdersTable td:last-child {
    width: 6rem;
}
#pushOrdersTable td:last-child #ordensPushLastTd {
    width: 6rem !important;
}

#pushOrdersCV {
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

#pushOrdersCV a {
    display: flex;
    align-items: center;
    justify-content: center;
}

#pushOrderFooter {
    width: calc(100% - 50px);
    position: absolute;
    bottom: 0;
    left: 49px;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    background: rgb(119, 1, 1);
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
    gap: 0.3rem;
    transition: all ease 0.3s;
}

#pushOrderFooter a:nth-child(2) {
    transition: all ease 0.2s;
}

#pushOrderFooter a:nth-child(2):hover{
    filter: brightness(0.5);
}

.pushOrdersSelecionWithErrors {
    font-weight: 300;
    cursor: pointer;
    height: 2.4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    transition: all ease 1.6s;
    color: #FFF;
    border: 2px solid #ef1e1ead;
}

.pushOrdersTableContainer {
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 0px 1px 20px -5px #000000;
    overflow-x: hidden;
    width: 95%;
    margin-top: 2rem;
}

.pushOrdersBasketTr {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    width: inherit;
    background: var(--topBarBg);
    height: 6rem;
    justify-content: space-between;
    align-items: center;
}

.pushOrdersBasketTable {
    display: flex;
    flex-direction: row;
}

.pushOrdersTable thead {
    border-left: 2px solid var(--topBarBg);
    border-right: 2px solid var(--topBarBg);
    font-size: 1rem;
    position: sticky;
    z-index: 100;
}

.pushOrdersTable thead tr:first-child th {
    top: 0;
    background: var(--topBarBg);
    position: sticky;
    z-index: 100;
    opacity: 1;
    
}

.pushOrdersTable thead tr:nth-child(2) th {
    opacity: 1;
    top: 6rem;
    position: sticky;
    background: var(--tableSecondTh);
    height: 1.5rem;
    color: rgba(255, 255, 255, 0.856);
    font-weight: 300;
    z-index: 99;
}

.pushOrdersBasketLabel {
    display: flex;
    width: 300px;
    flex-direction: row;
    justify-content: center;
}

.pushOrdersBasketLabel label:nth-child(2) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    transition: all ease 0.2s;
    padding-right: 1rem;
    gap: 0.2rem;
    align-items: center;
    font-size: 1rem;
}

.pushOrdersBasketLabel label:first-child{
    align-items: center;
    justify-content: flex-start;
    padding-left: 1rem;
    flex-direction: row;
    display: flex;
    width: 50%;
    color: #FFF;
    font-size: 1rem;
    font-weight: 300;
}

.pushOrdersSelection {
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 2.4rem;
    width: 6rem;
    transition: all ease 0.3s;
    color: rgb(102, 102, 102);
}

.pushOrdersSelection:hover {
    color: rgb(158, 158, 158);
    background:  #1e80ef1f;
}

.pushOrdersSelectionSelected {
    font-weight: 300;
    cursor: pointer;
    height: 2.4rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    transition: all ease 1.6s;
    color: #FFF;
    background:  #1e80ef69;
}

.pushOrdersBasketButton {
    height: 2rem;
    width: 8rem;
    border: 1px solid var(--goldColor);
    background: var(--topBarBg);
    color: var(--goldColor);
    font-size: 1rem;
    transition: 0.2s;
    cursor: pointer;
}

.pushOrdersBasketButton:hover {
    background: var(--goldColor);
    color: black;
}


.pushOrdersOperationTypeBuy {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.3s;
    background: #1e80ef69;
    color: white;
}

.pushOrdersOperationTypeBuy:hover {
    background: #1e80ef;
}

.pushOrdersOperationTypeBuySelected {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.3s;
    background: #1e80ef;
    color: white;
}

.pushOrdersOperationTypeSell {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.6s;
    color: #FFF;
    background: #cb111142;
}

.pushOrdersOperationTypeSell:hover {
    background: #CB1111;
}

.pushOrdersOperationTypeSellSelected {
    font-weight: 300;
    cursor: pointer;
    border-radius: 5px;
    height: 1.8rem;
    width: 5rem;
    transition: all ease 0.6s;
    color: #FFF;
    background: #CB1111;
}

.pushOrdersBasketLabel .MuiInputBase-root {
    color: #FFF;
}

.pushOrdersBasketLabel .MuiInputBase-root  svg {
    color: #FFF;
}
