.actionMin {

    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.actionMin a {
    font-size: 1rem;
}

.actionMin a:nth-child(2) {
    color: var(--goldColor);
    font-size: 1.2rem;
    cursor: default;
}



.actionMin a:nth-child(3):hover {
    filter: brightness(0.85)
}

.actionMin a:nth-child(4):hover {
    filter: brightness(0.85)
}

.actionMinHide {

    display: none;
}

.userActions {
    
  top: 45px;
  position: fixed;
  right: 0;
  background: #262626;
  color: white;
  width: 13rem;
  padding: 1rem;;
  transition: 0.2s;
  display: flex;
  /* right: 0; */
  flex-direction:column;
  justify-content: center;
  align-items: center;
  border-radius:  0 0 0 0.5rem;
  /* gap: 0.5rem; */
  -webkit-user-select: none;  
  -moz-user-select: none;     
  -ms-user-select: none;      
  user-select: none; 
  -webkit-box-shadow: -3px 4px 15px -5px #000000; 
  box-shadow: -3px 4px 15px -5px #000000;
  
}

.userActions a {
    font-size: 1rem; 
    cursor: pointer; 
    margin-bottom: 0.5rem;
}

.userActions img {
    border: 0.2rem solid rgba(175, 175, 175, 0.801);
    margin-bottom: 1rem !important;
    width: 100px;
    border-radius: 50%;
}

.userActions .LoaderMyProfil {
    border: 0.2rem solid rgba(175, 175, 175, 0.801);
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    padding-top: 0;
    justify-content: center;
}

.userActionsWide {
  overflow: auto;
  top: 45px;
  position: absolute;
  background: #262626;
  right: 0;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  align-items: center;
  height: 100vh;
  z-index: 400;
  
}

.userChangePassSuccess {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userChangePassSuccess a {
    font-size: 1.4rem;
}

.userActionsWide a {
    font-size: 1.4rem; 
}

.userChangePassSuccess button {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    width: 6.6rem;
    height: 1.8rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    margin-top: 2rem;
    color: var(--formButtonFontColor);
}

.userChangePassSuccess button:hover {
    background-color: var(--formButtonFontColor);
    color: rgba(47, 47, 47, 1);
}

.usersActionsWideLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.userActionsWide img {
    border: 0.2rem solid rgba(175, 175, 175, 0.801);
    margin-bottom: 1rem !important;
    width: 100px;
    margin-left: 0 !important;
    border-radius: 50%;
}

.usersActionWideShowOptions {
    padding-left: 40px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    height: 100%;
    width: 100%;
}


.usersActionWideShowOptions div:nth-child(2) form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0;
    
}

.profileUserAvatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    width: 100%;
}
   

.profileUserAvatar div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 8rem;
} 

.profileUserAvatar div span:first-child{
    display: flex;
    flex-direction: row;
    gap:0.5rem;
}

.profileUserAvatar div span:first-child a {
    font-size: 1rem !important;
}

.profileUserAvatar div span:nth-child(2) a{
    cursor: pointer;
    transition: 0.2s;
}
.profileUserAvatar div span:nth-child(2) a:hover{
    filter: brightness(0.70)
}
.profileUserAvatar div #spanEdit{
    display: flex;
    width: 100%;
    flex-direction: column; 
    height: 3rem;
}


.profileUserAvatar div span {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profileUserAvatar:nth-child(1) a:nth-child(1) {
    font-size: 1.4rem;
    cursor: default;
}

.profileUserAvatar:nth-child(1) a:nth-child(2) {
    color: var(--goldColor);
    transition: 0.2s;
}

.profileUserAvatar:nth-child(1) a:nth-child(2):hover {
    filter:  brightness(0.8);
}

.formDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 2rem;
    margin-top: 1rem;
}
#fileUserInput {
    display: none;
}

.bodyDataUser {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-top: 2rem;
}

.formLeftDiv {
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;
}

.formLeftDiv #leftLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50% !important;
    height: 100%;
}

.profileSeparator{
    height: 100%;
    border: 1px solid var(--goldColor);
    border-radius: 50%;
    height: 15rem;
    filter: brightness(0.8);
}


.profileSeparator:after{
    content: '';
    height: 100%;
    border-right: 1px solid var(--goldColor);
    display: block;
    width: 0px;
    border-radius: 50%;
}


.formRightDiv {
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 50%;

}

.formRightDiv #rightLabel{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50% !important;
    height: 100%;
}

.profileAlterProperty {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1.2rem;
    align-items: center;
    width: 100%;

}

.profileAlterProperty label {
    width: 100%;
    
}

#passwordLabel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.profileAlterProperty a:first-child {
    color: var(--goldColor);
    font-size: 1rem;
}

.profileAlterProperty a {
    font-size: 1rem;
}

.userActionsHide{
    max-width: 0;
    max-height: 0;
    height: 0;
    width: 0rem;
    position: absolute;
    opacity: 0;
    z-index: 0;
}

.userActionsHide img {
    display: none;
}

.profileHideLabel {
    display: none;
}

.userAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 110px;
    height: 110px;
}

.inEditAvatar {
    display: flex;
    flex-direction: column;
    background: white;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 0;
    overflow: hidden;

}

.ImgCropper {

    position: relative;
}

#myProfileButtonHide {
   display: none;

}

#myProfileSubmit {
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    width: 6.6rem;
    height: 1.8rem;
    border-radius: 0.6rem;
    transition: 0.1s;
    margin-top: 2rem;
    color: var(--formButtonFontColor);
}

#myProfileSubmit:hover {
    background-color: var(--formButtonFontColor);
    color: rgba(47, 47, 47, 1);
}

.formDiv button:hover {
    background-color: var(--formButtonFontColor);
    color: rgba(47, 47, 47, 1);
}

#profileCloseButton {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #262626;
    color: rgb(233, 233, 233);
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--goldColor);
    font-weight: bold;
    position: absolute;
    right: 1rem;
    top: 1rem;
}

#profileCloseButton:hover {
    background-color: #4d4d4d;
    color: var(--goldColor);
}


@media(max-width: 1366px) {
    .actionMin {

        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
    
    .actionMin a {
        font-size: 1rem;
    }
    
    .actionMin a:nth-child(2) {
        color: var(--goldColor);
        font-size: 1.2rem;
        cursor: default;
    }
    
    
    
    .actionMin a:nth-child(3):hover {
        filter: brightness(0.85)
    }
    
    .actionMin a:nth-child(4):hover {
        filter: brightness(0.85)
    }
    
    .actionMinHide {
    
        display: none;
    }
    
    .userActions {
        
      top: 45px;
      position: fixed;
      right: 0;
      background: #262626;
      color: white;
      width: 13rem;
      padding: 1rem;;
      transition: 0.2s;
      display: flex;
      /* right: 0; */
      flex-direction:column;
      justify-content: center;
      align-items: center;
      border-radius:  0 0 0 0.5rem;
      /* gap: 0.5rem; */
      -webkit-user-select: none;  
      -moz-user-select: none;     
      -ms-user-select: none;      
      user-select: none; 
      -webkit-box-shadow: -3px 4px 15px -5px #000000; 
      box-shadow: -3px 4px 15px -5px #000000;
      
    }
    
    .userActions a {
        font-size: 1rem; 
        cursor: pointer; 
        margin-bottom: 0.5rem;
    }
    
    .userActions img {
        border: 0.2rem solid rgba(175, 175, 175, 0.801);
        margin-bottom: 1rem !important;
        width: 100px;
        border-radius: 50%;
    }
    
    .userActions .LoaderMyProfil {
        border: 0.2rem solid rgba(175, 175, 175, 0.801);
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-bottom: 1rem !important;
        display: flex;
        align-items: center;
        padding-top: 0;
        justify-content: center;
    }

    .userActionsWide {
      overflow: auto;
      top: 45px;
      position: absolute;
      background: #262626;
      right: 0;
      color: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      transition: 0.2s;
      align-items: center;
      height: 100vh;
      z-index: 400;
      
    }
    
    .userActionsWide a {
        font-size: 1rem; 
    }
    
    
    .userActionsWide img {
        border: 0.2rem solid rgba(175, 175, 175, 0.801);
        margin-bottom: 1rem !important;
        width: 100px;
        margin-left: 0 !important;
        border-radius: 50%;
    }
    
    .usersActionWideShowOptions {
        padding-left: 40px;
        display: grid;
        grid-template-rows: 1fr 2fr;
        height: 100%;
        width: 100%;
    }
    
    
    .usersActionWideShowOptions div:nth-child(2) form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 0;
    }
    
    .profileUserAvatar {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
        width: 100%;
    }
       
    
    .profileUserAvatar div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: 8rem;
    } 
    
    .profileUserAvatar div span:first-child{
        display: flex;
        flex-direction: row;
        gap:0.5rem;
    }
    
    .profileUserAvatar div span:first-child a {
        font-size: 1rem !important;
    }

    .profileUserAvatar div span:nth-child(2) a{
        cursor: pointer;
        transition: 0.2s;
    }
    .profileUserAvatar div span:nth-child(2) a:hover{
        filter: brightness(0.70)
    }
    .profileUserAvatar div #spanEdit{
        display: flex;
        width: 100%;
        flex-direction: column; 
        height: 3rem;
    }
    
    
    .profileUserAvatar div span {
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .profileUserAvatar:nth-child(1) a:nth-child(1) {
        font-size: 1.4rem;
        cursor: default;
    }
    
    .profileUserAvatar:nth-child(1) a:nth-child(2) {
        color: var(--goldColor);
        transition: 0.2s;
    }
    
    .profileUserAvatar:nth-child(1) a:nth-child(2):hover {
        filter:  brightness(0.8);
    }
    
    .formDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 2rem;
        margin-top: 1rem;
    }
    #fileUserInput {
        display: none;
    }
    
    .bodyDataUser {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 1rem;
        margin-top: 2rem;
    }
    
    .formLeftDiv {
        border-radius: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    
    .formLeftDiv #leftLabel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50% !important;
        height: 100%;
    }
    
    .profileSeparator{
        height: 100%;
        border: 1px solid var(--goldColor);
        border-radius: 50%;
        height: 15rem;
        filter: brightness(0.8);
    }
    
    
    .profileSeparator:after{
        content: '';
        height: 100%;
        border-right: 1px solid var(--goldColor);
        display: block;
        width: 0px;
        border-radius: 50%;
    }
    
    
    .formRightDiv {
        border-radius: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50%;
    
    }
    
    .formRightDiv #rightLabel{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50% !important;
        height: 100%;
    }
    
    .profileAlterProperty {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 1.2rem;
        align-items: center;
        width: 100%;
    
    }
    
    .profileAlterProperty label {
        width: 100%;
        
    }
    
    #passwordLabel {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .profileAlterProperty a:first-child {
        color: var(--goldColor);
        font-size: 1rem;
    }
    
    .profileAlterProperty a {
        font-size: 1rem;
    }
    
    .userActionsHide{
        max-width: 0;
        max-height: 0;
        height: 0;
        width: 0rem;
        position: absolute;
        opacity: 0;
        z-index: 0;
    }
    
    .userActionsHide img {
        display: none;
    }
    
    .profileHideLabel {
        display: none;
    }

    .userAvatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 110px;
        height: 110px;
    }

    .inEditAvatar {
        display: flex;
        flex-direction: column;
        background: white;
        width: 100vw;
        height: 100vh;
        position: relative;
        z-index: 0;
        overflow: hidden;

    }

    .ImgCropper {

     
        position: relative;
    }
    
    #myProfileButtonHide {
       display: none;
    
    }
    
    #myProfileSubmit {
        outline: none;
        cursor: pointer;
        border: 0.13rem solid var(--formBorder);
        background-color: rgba(255, 255, 255, 0);
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem;
        width: 6.6rem;
        height: 1.8rem;
        border-radius: 0.6rem;
        transition: 0.1s;
        margin-top: 2rem;
        color: var(--formButtonFontColor);
    }

    #myProfileSubmit:hover {
        background-color: var(--formButtonFontColor);
        color: rgba(47, 47, 47, 1);
    }
    
    .formDiv button:hover {
        background-color: var(--formButtonFontColor);
        color: rgba(47, 47, 47, 1);
    }
    
}

@media (max-width: 480px){

    .usersActionWideShowOptions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profileUserAvatar div:first-child {
        display: flex;
        flex-direction: column;
    }

    .formDiv {
        margin-top: 7rem;
    }

    .formDiv form:first-child {
        margin-left: 5rem;
    }
    
    .formLeftDiv {
        width: 100%;
    }

    .formRightDiv {
        width: 100%;
    }

    .formLeftDiv label:first-child {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .formRightDiv label:first-child {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .formLeftDiv #leftLabel {
        width: 100% !important;
    }

    .profileSeparator {
        display: none;
    }

    .bodyDataUser {
        display: flex;
        flex-direction: column;
    }
}