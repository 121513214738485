.editBrancheBoxHeaderAbc{
  background: var(--headerAllBg);
  width: 100%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.editBrancheBoxHeaderAbc p {
  font-size: 1.4rem;
  border-bottom: 1px solid var(--goldColor);
  margin-left: 1rem;
}

.editBrancheBoxHeaderAbc button {
  margin-right: 1rem;
}

.containerModalAbcDetails{
  width: 90vw;
  margin: auto;
  height: 90vh;
  background-color: white;
}

.containerProductAdherence{
  width: 100%;
  height: 3rem;
  background-color: #5f5f5f;
}

.containerProductName{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

/* .containerProductName p {
  font-weight: 400;
} */

.containerClientMenuAbcActions{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 4fr 1px 4fr 1px 3fr 1px 4fr;
}

.containerClientMenuAbcActionsAbrir{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 5fr 1px 3fr 1px 6fr;
}

.containerBoletarAbc{
  width: 100%;
  height: calc(100% - 4rem);
  display: grid;
  grid-template-columns: 3fr 1px 3fr;
}

.clientDetails {
  color: black
}

.referenceDetails {
  color: blue
}

.containerClientMenuOptionsAbcActions{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
}

.containerListproductsAbc{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem auto;
}

.toolbarBoletarAbc{
  /* border: 1px solid var(--headerAllBg); */
  /* border-radius: 5px; */
  margin-top: 1rem;
  margin-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 2.5rem;
}


.divisionHeaderCardAbc{
  background-color: #B19942;
  width: 96%;
  height: 2px;
  margin-left: 2%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}
.listOperationProductsAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  height: 36.5rem;
  overflow-x: auto;
}

.productCardAbcInfos{
  margin-left: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.2rem;
}

.offerCardAbcInfos{
  margin-left: 3rem;
  margin-right: 3rem;
  /* display: grid;
  grid-template-columns: auto auto; */
  gap: 0.2rem;
}

.offerItemAbc{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 15rem 1fr;
}

.productCardAbc{
  background-color: #ebebe9;
  min-height: 6rem;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardActiveAbc{
  background: #ebebe9;
  border: 1px solid #c49e17;
  box-shadow: 0 0 6px 4px #c49e17;
  min-height: 6rem;
  height: fit-content;
  width: auto;
  margin: 0.5rem;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
}

.productCardAbc:hover{
  box-shadow: 0 0px 10px #c49e17;
  cursor: pointer;
}

.cardAbcHeader{
  font-weight: 400;
}

.productCardAbcHeader{ 
  display: grid;
  grid-template-columns: 2rem 1fr 1fr 1fr 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  
}

.containerNameAbcActions{
  display: grid;
  height: fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 1.2rem;
}

.containerOperateProductAbc{
  border: 1px solid var(--headerAllBg);
  border-radius: 5px;
  margin: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 6rem;
  /* max-width: 36rem; */
}

.headerOperateProductAbc{
  max-width: 100%;
  margin: auto;
  font-size: 1.2rem;
  font-weight: 400;
  color: #5E4C0B;
}

.bodyOperateProductAbc{
  border: 1px solid #ffffff;
  color: #5E4C0B;
  font-size: 1rem;
  font-weight: 400;
}

.contentBodyOperateProductAbc{
  margin-top: 6rem;
}

.contentBodyOperateOfferAbc{
  margin-top: auto;
  margin-bottom: auto;
}

.boxSubmitOperateProductAbc{
  max-width: 100%;
  margin: auto;
}

.buttonOperateSubmitABC{
  width: 10rem;
  padding: 0.2rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: 1px solid #c49e17;
  color: #fff;
  background-color: #c49e17;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
}

.switchSelector{
  margin:auto;
  text-align: center;
  border-radius: 1.4rem;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  border: 1px solid #c49e17;
}

.buttonSwitchSelected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #fff;
  background-color: #c49e17;
  transition: all ease-in-out 0.1s;
}

.buttonSwitchUnselected{
  text-align: center;
  border-radius: 1rem;
  margin: 0.2rem;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  border: none;
  color: #c49e17;
  background-color: transparent;
  transition: all ease-in-out 0.1s;
}

.toolbarSelectOrderBoletarAbc{
  margin-left: auto;
  margin-right: 1.5rem;
}


.itemBodyOperateProductAbc{
  margin-top: 1rem;
}

.itemBodyOperateOfferAbc{
  margin-top: 0rem;
}

.fieldsOperateAbc{
  margin-top: 3rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.fieldsOperateOfferAbc{
  margin-top: 1rem;
  margin-left: 20% ;
  margin-right: 20% ;
}

.buttonOperateSubmitABC:hover{
  background-color: #a88812;
  border: 1px solid #a88812;
  box-shadow: 0px 0px 10px #ffc800;
}

.clientInfoAbcActions{
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0.8rem;
  font-weight: 400;
}

.productInfoAbcActions{
  font-size: 1rem;
  text-align: center;
}

.divisionNameProductSubcategory{
  width: 100%;
  height: 96%;
  background-color: #c49e17;
  margin: auto;
}

.containerGeneralOptionsAbcActions{
  width: 100%;
  height: 98%;
  display: grid;
  position: relative;
}

.containerOptionsTitleAbcActions{
  z-index: 1;
  background-color: white;
  text-align: center;
  margin-bottom: -20px;
  display: grid;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.optionsTitleAbcActions{
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background-color: white;
}

.containerOptionsAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.containerGeneralChangeStatusAbcActions{
  width: 100%;
  height: 12.5rem;
  display: grid;
  position: relative;
}



.containerChangeStatusTitleAbcActions{
  position: absolute;
  display: grid;
  width: 100%;
  height: fit-content;
}

.ChangeStatusTitleAbcActions{
  width: fit-content;
  height: fit-content;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  background-color: white;
  margin: auto;
  font-size: 1.2rem;
}

.containerChangeStatusAbcActions{
  width: 86%;
  height: calc(82% - 2.8rem);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  margin: auto;
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
}

.buttonAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #c49e17;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActions:hover{
  background-color: #e6e2d7;
  color: #c49e17;
  border-left: 1px solid #c49e17;
  border-right: 1px solid #c49e17;
}

.buttonAbcActionsSolicit{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: rgb(241, 170, 63);
  color: white;
  transition: all ease 0.1s;
}
.buttonAbcActionsSolicit:hover{
  background-color: #e6e2d7;
  color: rgb(241, 170, 63);
  border-left: 1px solid rgb(241, 170, 63);
  border-right: 1px solid rgb(241, 170, 63);
}

.buttonAbordadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #3177b8;
  color: white;
  transition: all ease 0.1s;
}
.buttonAbordadoAbcActions:hover{
  background-color: #d3dee7;
  color: #3177b8;
  border-left: 1px solid #3177b8;
  border-right: 1px solid #3177b8;
}

.buttonRecusadoAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #d64747;
  color: white;
  transition: all ease 0.1s;
}
.buttonRecusadoAbcActions:hover{
  background-color: #e6d6d6;
  color: #d64747;
  border-left: 1px solid #d64747;
  border-right: 1px solid #d64747;
}

.buttonNaoAderenteAbcActions{
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  border: none;
  background-color: #696969;
  color: white;
  transition: all ease 0.1s;
}
.buttonNaoAderenteAbcActions:hover{
  background-color: #e6e6e6;
  color: #2b2b2b;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}

.containerClientMenuSubcategoryAbcActions{
  margin: 1.5rem 1rem 1.5rem 1rem;
  border: 1px solid #e2e2e2;
  /* background: #fbfbfb; */
  border-radius: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.headerSubcategoryAbcActions{
  display: grid;
  width: 100%;
  height: fit-content;
  min-height: 3.5rem;
  grid-template-columns: 1fr auto;
}

.headerSubcategoryTitleAbcActions{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 1rem;
  padding-top: 0.8rem;
  overflow-y: auto;
}

.containerIconCopyContentAbcActions{
  width: fit-content;
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* padding-left: 0.7rem;
  padding-right: 0.7rem; */
  margin: 0.4rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcActions:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.containerIconCopyContentAbcDetails{
  width: fit-content;
  height: fit-content;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 50%;
  transition: all ease 0.1s;
}

.containerIconCopyContentAbcDetails:hover{
  background-color: #e2e2e2;
  cursor: pointer;
}

.linkMenuModalBoxAbc{
  height: 1rem;
}

.linkMenuModalBoxDescriptionAbc{
  height: 1rem;
}

.searchIconModalAbc{
  border: none;
  background-color: transparent;
}

.containerSubcategoryDescriptionAbcActions{
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  background-color: #e2e2e2;
}

.descriptionTextAbc{
  margin-left: 1rem;
  margin-top: 1rem;
}

.iconCopyContentAbcActionsAtive{
  animation-name: glowing2;
  animation-duration: 1.0s;
}

.suggestionMessage{
  background-color: #c49e17;
  height: 13rem;
  margin: 1rem;
  color: white
}

.suggestionMessageHeader{
  font-size: 1.2rem;
  padding: 1rem 0.5rem 0rem 0.5rem;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.iconArrowRightAbc{
  float: right;
  margin-right: 1rem;
  margin-top: -0.8rem;
}

.linksBoxAbc{
  overflow-x: auto;
  display: grid;
  width: 100%;
  height: fit-content;
  min-height: 3rem;
  max-height: 9rem;
  grid-template-columns: auto 2rem;
}


.suggestionMessageBody{
  font-size: 1rem;
  padding: 2rem 0.5rem 1rem 0.5rem;
  align-items: center;
  text-align: center;
}

.alertMessageAbc{
  border: 1px solid #c49e17;
  border-radius: 10px;
  animation: glowing3 2000ms infinite;
}

@keyframes glowing3 {
  0% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
  50% { border-color: #c49e17; box-shadow: 0 0 10px  #c49e17; }
  100% { border-color: #c49e17; box-shadow: 0 0 0px  #c49e17;}
}

@keyframes glowing2 {
  0% {color: black;}
  25% { color: rgb(27, 68, 0)}
  50% { color: rgb(45, 112, 0)}
  75% { color: rgb(63, 158, 0)}
  100% {color: rgb(63, 158, 0)}
}

.commentBoxAbc{
  position:relative;
}
.timelineNewCommentBoxAbc{
  /* height: 12rem; */
  position: absolute;
  width: 27.5rem;
  /* top: 8%; */
  margin: 1.5rem;
  display: grid;
  grid-template-rows: 1fr 3fr;
}

.timelineNewCommentContactTypeAbc{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  width: fit-content;
}

.timelineNewCommentContactTypeTitleAbc{
  width: fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 0.4rem;
}

.timelineNewCommentWriteSendAbc{
  display: grid;
  grid-template-columns: 10fr 0fr;
}


.aderenceAbcActiveIcon{
  color: green
}

.aderenceAbcInativeIcon{
  color: rgb(160, 160, 160)
}

.containerProductNameTitleAbc{
  width: fit-content;
  margin: 0.5rem;
  margin-left: 1rem;
}

.containerProductHeaderAbc{
  display: grid;
  grid-template-rows: auto auto;
}

.containerProductNameAbc{
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.modalDetailsIconsAbc{
  margin-left: 2rem;
}

.modalDetailsAderenceAbc{
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  border-width: 0px 3px;
  padding: 0 1rem;
  border-style: solid;
  border-radius: 4px;
  background-color: #EDEDED; 
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.productCadastrationHeaderAbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--headerAllBg);
  width: 100%;
  height: 5rem;
  color: var(--headerFontColor);
  font-size: 1.3rem;
}

.productCadastrationHeaderAbc span{
  border-bottom: 0.1rem solid var(--formBorder);
  padding: 1rem;
}

.productCadastrationHeaderAbc button{
  display: flex;
  margin-right: 2rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid var(--formBorder);
  color: var(--formBorder);
}

.productCadastrationUpperUpperGridAbc {
  /* display: grid;
  grid-template-columns: 1fr;   
  grid-template-rows: auto;    */
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  /* gap: 1rem; */
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationUpperGridAbc {
  display: grid;
  grid-template-columns: 1fr 1fr;   
  grid-template-rows: auto auto auto;   
  width: 50rem;
  /* justify-content: center;
  align-items: center; */
  gap: 1rem;
  margin: 1rem 2rem 0 2rem;
}

.productCadastrationCategoriesGridAbc {
  margin-top: 1rem;

}

.productCadastrationCategoriesGridButtonAbc {
  margin: 1rem 2rem;
}

.productCadastrationCategoryRowAbc{
  display: grid;
  margin: 1rem 1rem 0 2rem;
  gap: 1rem;
  grid-template-columns: 4fr 4fr auto;
  width: 50rem;
}

.productCadastrationCategoryRowAbc .buttonWhiteBg {
  height: auto;
}

.productCadastrationContainerRowItemAbc {
  /* width: 25rem; */
  cursor: pointer;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
}

.productCadastrationSubmitAbc {
  margin-left: 2rem;
}
.modalDetailsAderenceAbcActive{
  border-color: #00801C;
}

.modalDetailsAderenceAbcInative{
  border-color: rgb(160, 160, 160);
}

.conteudoGeralDetailsModalAbc{
  display: grid;
  grid-template-columns: 4fr 7fr 6fr;
  height: 36rem
}

.menuCategoryModalAbc{
  margin-top: 1.5rem;
  position: relative;
}

.listClientsModalAbc{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 450;
  overflow-y: auto;
  /* max-height: 33rem; */
}

.menuCategoryItemAbcActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInative:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcActiveAbrir{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}


.menuCategoryItemAbcInativeAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: fit-content;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
}

.menuCategoryItemAbcInativeAbrir:hover{
  background: #e9e9e9;
}

.menuCategoryItemAbcGeralActive{
  background: #c49e17;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.menuCategoryItemAbcGeralInative{
  background: #D9D9D9;
  border-radius: 2px;
  height: 4rem;
  width: calc(100% - 2rem);
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1.5rem;
}

.listClientsHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
}

.listClientsHeaderModalAbcAbrir{
  display: grid;
  grid-template-columns: auto 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem; 
  padding: 0.5rem 0 0.5rem 0;
  font-weight: 450;
  min-height: 2.1rem;
}

.listClientsBodyModalAbc{
  /* background-color: #00801C; */
  max-height: 29rem;
  overflow-y: auto;
}

.listClientsBodyModalAbcAbrir{
  /* background-color: #00801C; */
  /* max-height: 29.5rem; */
  overflow-y: auto;
}

.menuCategoryListModalAbc{
  max-height: 28rem;
  overflow-y: auto; 
}

.listClientsItemInativeAbc{
  background: #D9D9D9;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemActiveAbc{
  background: #c49e17;
  border-radius: 2px;
  height: 2rem;
  width: calc(100% - 2rem);
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: auto 2rem;
  overflow-y: hidden;
}

.listClientsItemInativeAbc:hover{
  background: #e9e9e9;
}

.listClientsItemInativeAbcAbrir{
  background: #D9D9D9;
  border-radius: 2px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemActiveAbcAbrir{
  background: #c49e17;
  border-radius: 2px;
  min-height: 2rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  text-align: left;
  border: none;
  font-size: 1rem;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 2rem auto;
  overflow-y: hidden;
}

.listClientsItemInativeAbcAbrir:hover{
  background: #e9e9e9;
}

.categoryDetailsModalAbc{
  margin: 1.5rem;
  background-color: #F5F5F5;
  border: 3px solid #EDEDED;
  border-radius: 4px;
  font-size: 1.2rem;
  display:grid;
  grid-template-rows: auto 8fr 6rem;
  max-height: 33rem;
}


.clientSpeechAbc{
  background-color:#D9D9D9;
  height: calc(100% - 2rem);
  max-height: 25rem;
  width: calc(100% - 4rem);
  margin: 0 0 0 1rem;
  padding: 1rem;
  border-radius: 4px;
  overflow-y: auto;
}

.clientSpeechHeaderModalAbc{
  display: grid;
  grid-template-columns: auto 3rem;
  margin-left: 1.4rem;
  margin-right: 1rem;
  padding: 0.5rem;
  font-weight: 450;
  max-width: 26rem;
  overflow-x: hidden;
  align-items: center;
}

.productLinkAbc{
  font-size: 1.2rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: auto 4rem;
  font-weight: 400;
  align-items: center;
  height: fit-content;
  max-height: 6rem;
  overflow-y: auto;


}

.textAreaSpeechAbc{
  background-color: #e2e2e2;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  font-size: 1rem;
  resize: none;
  padding: 1rem;
  border: none;
}

.textAreaSpeechAbc:focus{
  outline: none;
  border: none;
}

.listOnlyAbc {
  height: 1.6rem;
  width: 8rem;
  font-weight: 400;
  font-size: 1rem;
}

.timelineTitleAbc{
  margin-top: 1rem;
  height: 5rem;
  font-size: 1.1rem;
  font-weight: 500;
  /* display: grid;
  grid-template-columns:3fr 3fr;  */
  padding-bottom: 0.3rem;
  align-items: center;
}

.timelineTitleAbcAbrir{
  margin-top: 1rem;
  height: 3rem;
  font-size: 1.1rem;
  font-weight: 500;
  display: grid;
  grid-template-columns:3fr 3fr; 
  padding-bottom: 0.3rem;
  align-items: center;
}

.containerTimelineAbc{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  width: calc(100% - 4rem);
  margin: auto;
  display: grid;
  grid-template-rows: 4rem auto 10rem;
}

.containerTimelineAbcDetails{
  overflow: auto;
  height: calc(100dvh - 11.5rem);
  /* max-height: 100%; */
  /* width: calc(100% - 4rem); */
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
  grid-template-rows: 6rem auto 10rem;
}

.timelineCommentBoxAbc{
  height: 100%;
  overflow: auto;
  border-radius: 2px;
  background-color: #f8f8f8;
  border: 1px solid #dadada;
  display: grid;
}

.refusalReasonBox{
  background-color: white;
  border: 2px solid #c49e17;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #c49e17;
  width: 15rem;
  height: 21.75rem;
  display: block;
  position: absolute;
  z-index: 10;
  top: 8rem;
  padding: 1rem;
  margin-left: 0.2rem;
  /* margin-left: -5rem; */
  /* margin: 0 auto; */
}

.refuseReasontitleAbc{
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  padding-top: 0.25rem;
  
}

.reasonsRefusalList{
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.refuseReasonHeaderAbc{
  display: grid;
  grid-template-columns: 2rem auto 2rem;
  vertical-align: middle;
}

.InputToFormat_two{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.InputToFormat_two div{
  position: relative;
  flex-grow: 1;
  /* margin: 1rem; */
}

.InputToFormat_two div input{
  border: 0;
  border: 1px solid lightgrey;
  height: 1.9rem;
  border: 1px solid var(--formBorder);
  border-radius: 0.3rem !important;
  outline: none;
  min-width: 15rem;
  /* width: 100%; */
  font-size: 16px;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -webkit-appearance:none;
  border-radius: 0;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.InputToFormat_two div input:focus{
    box-shadow: inset 0 0 0 1px var(--formBorder);
}

.InputToFormat_two div input::placeholder{
  color:transparent;
}

.InputToFormat_two div label{
  color: #7F777F;
  font-size: 0.9rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  color: #8f7f7f;
  transition: all .3s ease-out;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  font-weight: 400;
}

.InputToFormat_two div input:required:invalid + label:before{
  content: '*';
}
.InputToFormat_two div input:focus + label,
.InputToFormat_two div input:not(:placeholder-shown) + label{
  font-size: 11px;
  margin-top: -0.5rem;
  margin-left: 0.8rem;
  color: #c49e17;
  background-color: white;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.carousel-container {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18rem;
}

.carousel-menu {
  overflow: hidden;
  white-space: nowrap;
}

#menu-items {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease-in-out;
}

#menu-items li {
  display: inline-block;
  /* padding: 10px; */
}

.carousel-button {
  border: none;
  color: #5f5f5f;
  background-color: transparent;
}

.carousel-button:hover {
  color: #c49e17;
}