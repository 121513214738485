#companiesArrowLoader  {
    
    height: 30rem;
    
}

#companiesTableEmptyRow {
    height: 2.5rem;
    font-size: 1.2rem;
}

.companiesTableContainer  {
    
    max-height: 40rem;
    overflow: auto;
    width: 100%;
    border-radius: 0.5rem 0 0 0;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
}

.companiesTableContainer table {

    width: 100%;
    font-size: 1rem;
}

.companiesTableContainer thead  {
    background: var(--topBarBg);
    color: white;    
}

.companiesTableContainer thead tr:first-child {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: 200;
    border-left: 1px solid  black;
    border-right: 1px solid black;   
}

.companiesTableContainer thead tr:first-child th {
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background: var(--topBarBg);
}

.companiesTableContainer thead tr:nth-child(2) th {
    position: sticky;
    top: 5rem;
    opacity: 1;
    z-index: 100;

    background: var(--tableSecondTHGray);
}

.companiesTableContainer thead tr:nth-child(2){
    height: 1.5rem;
    font-size: 1rem;
    font-weight: 200;
    background: var(--tableSecondTHGray);
    border-left: 1px solid var(--tableSecondTHGray);
    border-right: 1px solid var(--tableSecondTHGray);
}

.companiesTableContainer thead tr:first-child th {
    width: 120rem;
}

.companiesTableContainer thead tr:nth-child(2) th{
    color: white;
    height: 1.5rem;   
    width: 24rem;
    font-size: 1rem;
    font-weight: 300;
}

.companiesTableContainer tbody {
    background: white;
    overflow-y: scroll;
    max-height: 35rem;
    transition: 0.2s;
}

.companiesTableContainer tbody tr:hover{
    background: var(--tableHover);
}

.companiesTableContainer tbody td {
    padding: 0;
    width: 24rem;
    height: 2.5rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
    text-align: center;
    transition: 0.1s;
    cursor: pointer;
}

.companiesTableContainer .companiesTheadFirst{
    display: flex;
    flex-direction: row;
}

.companiesTheadFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.companiesTheadFirst a {
    margin-left: 2rem;
}

.companiesTheadFirst button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    outline: none;
    padding-right: 0.5rem;
    cursor: pointer;
    border: 0.13rem solid var(--formBorder);
    background-color: rgba(255, 255, 255, 0);
    font-size: 1rem;
    width: 8rem;
    height: 1.8rem;
    /* border-radius: 0.6rem; */
    transition: 0.1s;
    color: var(--formButtonFontColor)
}

.companiesTheadFirst button:focus {
    background: var(--formBorder);
    color: black; 
}

.companiesTheadFirst button:hover {
    background: var(--formBorder);
    color: black;
}

.companiesTheadFirst label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 2rem;
}


.companiesTheadFirst:first-child a{

    font-weight: 300;
    font-size: 1.2rem;
}

.companiesTheadFirst label input {

    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
}


.companiesCadastrationModalBG{
    width: calc(100% - 49px);
    height: calc(100% - 49px);
    background: rgb(0,0,0,0.5);
    position: absolute;
}

.companiesCadastrationBGHide {
    display: none;
}

@media(max-width: 1368px){

    .companiesTableContainer  {
    
        max-height: 35rem;
        overflow: auto;
        width: 100%;
        border-radius: 0.5rem 0 0 0;
        -webkit-box-shadow: -7px 0px 9px -7px #000000; 
        box-shadow: -2px 2px 20px -7px #000000;
    }
    .companiesTableContainer thead tr:first-child {
        height: 4rem;
    }

    .companiesTableContainer thead tr:nth-child(2) th {
        top: 4rem;
        height: 1.5rem;
    }

    .companiesTheadFirst label input {
        height: 2rem;
        width: 12rem;
        padding-left: 0.6rem;
    }

    .companiesTheadFirst button {
        font-size: 1rem;
        height: 2rem;
        width: 8rem;
     }

    
}


