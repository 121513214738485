.boxAlertLSOperation{
    margin-top: 1rem;
    text-align: center;
}

.divAlertsLongShorts{
    margin-top: 1rem;
}

.boxJustifyBrokerLs{
    height: 15rem;
    width: 80%;
    margin-top: 2rem;
}

.boxJustifyBrokerLsText{
    margin: 1rem;
    background-color: rgb(226, 226, 226);
    padding: 1rem;
    min-height: 5rem;
    max-height: 10rem;
    overflow: auto;
    border-radius: 5px;
}

.fieldsOperateLsOrder{
    margin-top: 3rem;
    margin-left: auto ;
    margin-right: auto ;
}

.buttonOperateLs{
    width: 10rem;
    padding: 0.2rem;
    font-size: 1.2rem;
    font-weight: 400;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonOperateLs:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
}