.usersCadastrationContainer{
    display: grid;
    grid-template-rows: 10% 90%;
    overflow-y: auto;
    background: white;
    width: 60rem;
    height: 40rem;
}

#errorField {
    filter: hue-rotate(325deg);
    background: rgba(151, 151, 151, 0.144);
}

#cliensCadastrationSendButton:focus {
    border: 2px solid var(--formBorder);
    font-weight: 400;
}

#userCadastrationFormDiv {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    width: 100%;
    justify-content: center;
    gap: 0.8rem;
}

.usersCadastrationBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: calc(100% - 5rem);
}

.usersCadastrationBody form{
    
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1rem;
    gap: 1rem;
}

.usersCadastrationBody form label {
    width: 100%;
}


.userCadastrationHeader {
    top: 0;
    padding: 1rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    background: var(--headerAllBg);
}

.userCadastrationHeader p {
    border-bottom: 0.1rem solid var(--formBorder);
}

.userCadastrationHeader button {
    background: transparent;
    color: var(--formBorder);
    border: 0.1rem solid var(--formBorder);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1rem;
    background: white;
}

.usersCadastrationBrokerSelectionProfile {
    width: 100%;
}


.usersCadastrationAutocompleteHide {
    display: none;
}

@media(max-width:1368px){

    .usersCadastrationContainer{

        display: grid;
        grid-template-rows: 15% 85%;
        overflow-y: auto;
        height: 35rem;
        width: 55rem;
        background: white;
    }
   
    .usersCadastrationBody form label {
        width: 100%;
        display: flex;
        flex-direction: column;      
    }
    
}