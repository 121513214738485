.assetClassCadastrationContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assetClassCadastrationLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.assetClassCadastrationHeader {
    width: 100%;
    height: 3rem;
    background: var(--headerAllBg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.assetClassCadastrationHeader p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.assetClassCadastrationHeader button {
    margin-right: 1rem;
}

.assetCadastrationBody {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.assetCadastrationBodyDescription  p{ 
    font-size: 1rem;
}

.assetCadastrationSelectClass {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;

}

.assetCadastrationSelectClass a {
    font-size: 1rem;
}

.assetCadastrationSelectClass label:first-child {
    width: 20rem;
}

.assetClassCadastrationTypeButton {
    width: 2rem;
    height: 2rem;
    outline: none;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.411);
    font-size: 1.5rem;
    transition: all ease 0.2s;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.assetClassCadastrationTypeButton:focus {
    border: 2px solid var(--goldColor);
}