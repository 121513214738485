.myReservationDetails {
    background: white;
    width: 50rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.myReservationDetails h4 {
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 0.5rem;

}

.myReservationsHeader {

    height: 4rem;
    width: 100%;
    background: var(--headerAllBg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.myReservationsHeader p {
    font-size: 1.2rem;
    margin-left: 1rem;
}

.myReservationsHeader button {
    margin-right: 1rem;
}

.myReservationsBody {
    word-break: break-all;
    overflow-x: hidden;
    max-height: 100%;
    margin-top: 1rem;   
    margin-bottom: 1rem;   
    background: rgb(248, 248, 248);
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    height: 100%;
    width: 90%;
    padding: 1rem;
    border-radius: 10px;
}

.myReservationsBody p {
    font-size: 1rem;
}

.myReservationEmptyBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
}

.containerBackofficeObservation{
    width: 50rem;
    height: 20rem;
    /* box-shadow: 0px 0px 0.5rem 1px var(--goldColor); */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-top: 0.1rem solid #c49f17;
    text-align: center;
}

.textFieldBackofficeObservation{
    margin: 1rem;
    padding: 1rem;
    width: 46rem;
    height: 8rem;
    background: rgb(248, 248, 248);
    border: none;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    border-radius: 10px;
    text-align: left;
}

.saveBackofficeObservation{
    width: 6rem;
    height: 3rem;
    margin: auto;
    font-weight: 400;
    font-size: 1.1rem;
    border-radius: 4px;
    border: none;
    background: var(--headerAllBg);
}

.saveBackofficeObservation:hover{
    background: rgb(214, 214, 214);
}