.assetClassesTable {
    width: 95%;
    overflow-x: hidden;
    max-height: 35rem;
    background: white;
    -webkit-box-shadow: 5px 8px 15px -7px #000000; 
    box-shadow: 5px 1px 15px -7px #000000;
}

.assetClassesTable th { 
    font-size: 1rem;
    font-weight: 300;
    background: var(--tableSecondTh);
    color: white;
    height: 1.5rem;
}

.assetClassesTable tr:first-child th {
    background: var(--topBarBg);
}

#assetClassesTableTheadDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    margin-right: 1rem;
}

#assetClassesTableTheadDiv label{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

#assetClassesTableTheadDiv p {
    font-size: 1.2rem;
    color: white !important;
    font-weight: 300;
    margin-left: 1rem;
}

.assetClassesTable tbody td {
    height: 2rem;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
    transition: all ease 0.2s;
}

.assetClassesTable tbody tr:hover {
    background: var(--tableHover);
}

#assetClassTableLoader {
    height: 20rem;
}

