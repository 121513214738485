.dashboardAdvisorProductsCalendar{
    background: white;
    width: 80rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
  }
  
  .titleModalProductsCalendar{
    background: var(--headerAllBg);
    height: 6rem;
    align-items: center;
    font-size: xx-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 80rem;
  }
  
  .dashboardAdvisorProductsCalendarTable{
    height: 100rem;
  }

  
  .weekProductsCalendarTable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  .headProductsCalendar{
    /* overflow: auto; */
    width: inherit;
    text-align: -webkit-center;
  }

  .weekDayProductsCalendar{
    background: var(--headerAllBg);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .weekCountProductsCalendar{
    text-align: center;
    background-color: cornflowerblue;
    color: white;
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .headProductsCalendar tr{
    width: inherit;
    display: grid;
    text-align: -webkit-center;
  }

  .inlineProducts{
    display: flex;
    align-items: center;
    place-content: center;
    width: 80rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .inlineProductsAdvisor{
    background-color: orange;
  }

  .inlineProductsMesaRV{
    background-color: #8ED1FC;
  }

  .inlineProductsOffshore{
    background-color: #8BC34A;
  }

  .inlineProductsDay{
    display: flex;
    align-items: center;
    place-content: center;
    width: 80rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: white;
  }

  .inlineProductsDay tr{
    display: flex;
  }

  .inlineProductsDay th{
    display: flex;
    align-items: center;
    place-content: center;
    width: 80rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .inlineProducts th{
    margin: 2px;
    display: flex;
    align-items: center;
    place-content: center;
  }
  
  .listProductsCalendar{
    display: grid;
    grid-template-columns: 2fr 20rem 2fr 1fr;
    column-gap: 1rem;
    margin: 1rem;
  }
  
  .listProductsCalendar th{
    font-weight: 300;
    font-size: large;
  }
    

  .modalCloseButtonDashboard {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid var(--goldColor);
    background-color: rgba(255, 255, 255, 0);
    font-size: 40px;
    border-radius: 50%;
    transition: 0.1s;
    color: #D4AC00
}

.modalCloseButtonDashboard:hover {
    background: var(--buttonHover);
    color: white;
}
