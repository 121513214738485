#dashboardTextEditorMainContainer {
    background: white;
    display: flex;
    flex-direction:column;
    align-items: center;
}

#dashboardTextEditorHeader {
    background-color: var(--headerAllBg);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#dashboardTextEditorHeader p {
    margin-left: 1rem;
    font-size: 1.2rem;
}

#dashboardTextEditorHeader button {
    margin-right: 1rem;
}

.dashboardTextDetailsBody {
    width: 90%;
    overflow: auto;
    margin-top: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(61, 61, 189, 0);
    background: rgb(248, 248, 248);
    margin-bottom: 2rem;
    transition: 0.2s;
    font-size: 1.3rem;
    -webkit-box-shadow: inset 0px 0px 15px -5px #000000; 
    box-shadow: inset 0px 0px 20px -3px #c5c5c5;
    transition: 0.2s;
}

#dashboardTextEditor {
    word-break: break-all;
    max-width: 100%;
    max-height: 841.89px;
}

#dashboardTextEditorButton {
    background-color: var(--topBarBg);
    color: white;
    font-size: 1rem;
    border: none;
    width: 8rem;
    height: 2rem;
    transition: 0.2s;
    border-radius: 4px;
}

#dashboardTextEditorButton:hover {
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--topBarBg);
    color: var(--topBarBg);
}

#dashboardTextTitleEditor {
    width: 10rem;
    height: 2rem;
    font-size: 1rem;
    padding-left: 0.5rem;
    outline-color: rgb(173, 173, 173);
    border: 1px solid rgb(168, 168, 168);
}
    

#dashboardTextTitleEditor::placeholder {
    color: rgba(0, 0, 0, 0.479);
    font-size: 1rem;
}

.dashboardTextTitleDiv {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-top: .5rem;
}
