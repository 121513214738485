.containerCabecalhoVBACLIENTSACTIVATION{
  /* display: flex;
  justify-content: space-between; */
  /* min-height: 4rem; */
  display: inline-block;
  display: grid;
  grid-template-columns: 30vh auto 30vh;
  width: 100%;
  background-color: #d8e0e7;
  padding-top: 1rem;
  border-bottom: 1rem solid #d8e0e7;
}

.boxAnswersVBACLIENTSACTIVATION{
  max-height: 34rem;
  height: 34rem;
  overflow-y: auto;
}

.containerCabecalhoPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
}

.boxTituloPerfilVBACLIENTSACTIVATION{
  display: flex;
  margin-right: 5rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  right: 2rem;
  height: fit-content;
  width: fit-content;
  margin: auto
}

.backArrowVBACLIENTSACTIVATION{
  height: fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 2rem;
}

.buttonOpenCloseSectionVBACLIENTSACTIVATION{
  right: 2rem;
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  color: rgb(0, 0, 0);
}

.buttonOpenCloseSection:hover VBACLIENTSACTIVATION{
  color: rgb(97, 97, 97);
  cursor: pointer;
}

.sectionTitleVBACLIENTSACTIVATION{
  position: relative;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
}

.questionDescriptionVBACLIENTSACTIVATION{
  margin-left: 2rem;
}

.checkboxVBACLIENTSACTIVATION{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.radioboxVBACLIENTSACTIVATION{
  width : 1rem/*desired width*/;
  height : 1rem/*desired height*/;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.gridtesteVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 4rem auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.opcoesDaPerguntaVBACLIENTSACTIVATION{
  display: grid;
}

.finishButtonVBACLIENTSACTIVATION{
  margin-top: 2rem;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.finishButton:hoverVBACLIENTSACTIVATION{
  background-color: #cecece;
  color: #141414;
}

.boxFinishVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: #d8e0e7;
  height: 6rem;
}

.saveButtonVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto;
  width: 13rem;
  font-size: 1.2rem;
  margin-right: 1rem;
  border: none;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.2rem;
  background: transparent;
  transition: all ease-in-out 0.1s;
}

.saveButton:hoverVBACLIENTSACTIVATION{
  background: #b8b8b8;
}

.saveProgressTitleVBACLIENTSACTIVATION{
  margin: auto;
  height: fit-content;
  width: fit-content;
}

.backArrowVBACLIENTSACTIVATION{
  border: none;
  padding: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 0.2rem;
  background: transparent;
  color: black;
  transition: all ease-in-out 0.1s;
}

.backArrow:hoverVBACLIENTSACTIVATION{
  background: #d4d4d4;
}

.containerSavingVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto;
}

.savingText VBACLIENTSACTIVATION{
  font-size: 1.6rem;
  height: fit-content;
  margin: auto;
  margin-left: 2.4rem;
  color: #494949;
}

.containerDotsSavingVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 0.6rem;
}

.containerDotsSaving > div:nth-child(2) VBACLIENTSACTIVATION{
  animation-delay: 0.2s;
}

.containerDotsSaving > div:nth-child(3) VBACLIENTSACTIVATION{
  animation-delay: 0.4s;
}

.dotSavingVBACLIENTSACTIVATION{
  width: 0.2rem;
  height: 0.2rem;
  background-color: #242424;
  border-radius: 50%;
  margin: auto;
  margin-left: 0.4rem;
  animation: dotAnimationSaving 0.6s infinite alternate;
}

@keyframes dotAnimationSaving {
  to {
    opacity: 0;
  }
}

.savedTextVBACLIENTSACTIVATION{
  color: #186800;
  font-size: 1.6rem;
  margin: auto;
  margin-left: 2.4rem;
}

.observationVBACLIENTSACTIVATION{
  margin-left: 3rem;
}

.observationTextVBACLIENTSACTIVATION{
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 1.5rem;
}

.containerGeralCreateFormStructureVBACLIENTSACTIVATION{
  width: 100%;
  margin-left: auto;
  height: 34rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.containerSecoesPerguntasVBACLIENTSACTIVATION{
  overflow: auto;
  width: 98%;
  padding-left: 2%;
  max-height: calc(100vh - 18rem);
}

.containerTituloFormVBACLIENTSACTIVATION{
  display: grid;
  height: 5rem;
  padding-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.tituloTituloVBACLIENTSACTIVATION{
  font-size: 1.8rem;
  font-weight: 400;
  color: #11639E;
  text-align: center;
}

.inputTituloTituloVBACLIENTSACTIVATION{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.6rem;
  width: 14rem;
  padding: 0.2rem;
  text-align: center;
  margin: auto;
  margin-top: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloTitulo:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.tituloSecaoVBACLIENTSACTIVATION{
  font-size: 1.4rem;
  font-weight: 400;
  color: #11639E;
  margin-left: 0.4rem;
}

.inputTituloSecaoVBACLIENTSACTIVATION{
  border: none;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  font-size: 1.2rem;
  width: 14rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-top: 0.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloSecao:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-bottom: 2px solid #11639E;
}

.perguntaVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  width: 92%;
  border-top: 2px solid #11639E;
  margin: auto;
  margin-top: 0.6rem;
  padding: 0.6rem;
  background: white;
  box-shadow: -1px 3px 6px gray;
}

.barraLateralVBACLIENTSACTIVATION{
  width: 0.2rem;
  height: 100%;
  background-color: #11639E;
  margin: auto;
  margin-right: 0.6rem;
}

.inputTituloPerguntaVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputTituloPergunta:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.opcaoPerguntaVBACLIENTSACTIVATION{
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

.tituloOpcaoPerguntaVBACLIENTSACTIVATION{
  color: #11639E;
  margin-bottom: 0.1rem;
}

.selectTipoPerguntaVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.selectTipoPergunta:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tipoPerguntaTextoVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 30%;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-top: 1rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaTexto:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerRadioOptionVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: fit-content;
  margin-top: 0.6rem;
}

.containerTipoPerguntaOpcaoMultiplaVBACLIENTSACTIVATION{
  margin-top: 1rem;
}

.inputOptionNameVBACLIENTSACTIVATION{
  border: none;
  border: 1px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  min-width: 4rem;
  width: min-content;
  height: fit-content;
}

.inputOptionName:focusVBACLIENTSACTIVATION{
  outline: none;
  border: 1px solid #11639E;
}

.addSubSectionVBACLIENTSACTIVATION{
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
  border: none;
  background: transparent;
  color: rgb(97, 97, 97);
  transition: all ease-in-out 0.1s;
  white-space: nowrap;
}

.addSubSection:hoverVBACLIENTSACTIVATION{
  color: black;
}

.addRadioOptionVBACLIENTSACTIVATION{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addRadioOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.deleteRadioOptionVBACLIENTSACTIVATION{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteRadioOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.botaoNovaPerguntaVBACLIENTSACTIVATION{
  width: 30%;
  margin-top: 0.8rem;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaPergunta:hoverVBACLIENTSACTIVATION{
  background-color: #064d80;
}

.botaoNovaSecaoVBACLIENTSACTIVATION{
  width: 20%;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  height: fit-content;
  font-size: 1rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.botaoNovaSecao:hoverVBACLIENTSACTIVATION{
  background-color: #064d80;
}

.habilitarObservacaoVBACLIENTSACTIVATION{
  font-weight: 500;
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
}

.containerTipoPerguntaPorcentagemVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 1fr auto;
  width: 20%;
}

.tipoPerguntaPorcentagemVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 1rem !important;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.tipoPerguntaPorcentagem:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.porcentagemVBACLIENTSACTIVATION{
  margin-top: auto;
  padding: 0.05rem;
  padding-left: 0.4rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.tipoPerguntaMonetarioVBACLIENTSACTIVATION{
  margin-top: 1rem !important;
  width: 20%;
}

.containerTipoPerguntaCaixaSelecaoVBACLIENTSACTIVATION{
  margin-top: 1rem;
}

.containerCheckBoxOptionVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  width: fit-content;
  margin-top: 0.6rem;
}

.addCheckBoxOptionVBACLIENTSACTIVATION{
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.addCheckBoxOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.deleteCheckBoxOptionVBACLIENTSACTIVATION{
  margin-right: 0.6rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border: none;
  background: transparent;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.deleteCheckBoxOption:hoverVBACLIENTSACTIVATION{
  background: #dbdbdb;
}

.containerCabecalhoPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: #d8e0e7;
  border-top: 2rem solid #d8e0e7;
  border-bottom: 1rem solid #d8e0e7;
  max-height: 6rem;
}

.descricaoPortfolioVBACLIENTSACTIVATION{
  width: 90%;
  margin: auto;
  margin-top: 0.4rem;
  height: 2.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 1.2rem;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  box-shadow: -1px 3px 6px gray;
}

.descricaoPortfolio:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.containerDescricaoPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto 1fr;
}

.containerSelectTipoPortfolioVBACLIENTSACTIVATION{
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

.selectTipoPortfolioVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  margin-left: 1.2rem;
  height: 2.4rem;
}

.selectTipoPortfolio:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.inputNameFilterVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 0.4rem;
  height: 1.95rem;
}

.inputNameFilter:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.secaoVBACLIENTSACTIVATION{
  margin-top: 1rem;
}

.containerTagsVBACLIENTSACTIVATION{
  display: flex;
  width: fit-content;
  border-left: 2px solid #11639E;
  padding-left: 0.6rem;
}

.inputNameTagVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  margin-left: 0.6rem;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
}

.inputNameTag:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.tagTitleVBACLIENTSACTIVATION{
  font-size: 1.5rem;
  font-weight: 400;
}

.addTagButtonVBACLIENTSACTIVATION{
  border: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  margin: auto;
  margin-left: 0.4rem;
  font-size: 1rem;
  background: #e6e6e6;
  height: fit-content;
}

.addTagButton:hoverVBACLIENTSACTIVATION{
  background: #c9c9c9;
  cursor: pointer;
}

.containerInfoTagsVBACLIENTSACTIVATION{
  margin: auto;
}

.deleteTagButtonVBACLIENTSACTIVATION{
  border: none;
  border-right: none;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1rem;
  background: #c4e6fd;
  font-weight: 500;
}

.deleteTagButton:hoverVBACLIENTSACTIVATION{
  background: #a4c6dd;
  cursor: pointer;
}

.nomeTagVBACLIENTSACTIVATION{
  font-size: 1.2rem;
  background: #c4e6fd;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin: auto;
  border-left: none;
}

.containerTagVBACLIENTSACTIVATION{
  display: grid;
  width: fit-content;
  grid-template-columns: auto auto;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
  border-radius: 10px;
}

.containerDisplayTagsVBACLIENTSACTIVATION{
  overflow: hidden;
  border: 1px solid #dadada;
  margin-left: 0.4rem;
  max-width: 10rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 4px;
}

.formTagsVBACLIENTSACTIVATION{
  display: flex;
}

.tituloSelectPortfolioVBACLIENTSACTIVATION{
  font-size: 1.8rem;
  color: #11639E;
  height: 3rem;
}

.containerHeaderSelectPortfolioVBACLIENTSACTIVATION{
  text-align: center;
  height: 8rem;
  padding-top: 2rem;
  background-color: #d8e0e7;
}

.containerPortfoliosExistentesVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 18rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow: auto;
}

.itemPortfolioAgressivoVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.itemPortfolioModeradoVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioModerado:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.itemPortfolioConservadorVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioConservador:hoverVBACLIENTSACTIVATION{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.itemPortfolioGeralVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 2rem);
  max-width: calc(90% - 2rem);
  height: 14rem;
  margin: auto;
  margin-top: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.itemPortfolioGeral:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #ebebeb;
  cursor: pointer;
}

.tituloPortfolioVBACLIENTSACTIVATION{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.separacaoItemPortfolioAgressivoVBACLIENTSACTIVATION{
  background-color: #ff0000;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioModeradoVBACLIENTSACTIVATION{
  background-color: #ffae00;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioConservadorVBACLIENTSACTIVATION{
  background-color: #00ccff;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.separacaoItemPortfolioGeralVBACLIENTSACTIVATION{
  background-color: #ebebeb;
  width: 96%;
  margin: auto;
  height: 1px;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.divDescricaoVBACLIENTSACTIVATION{
  display: table;
}

.descricaoItemPortfolioVBACLIENTSACTIVATION{
  color: white;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.tipoPerfilPortfolioAgressivoVBACLIENTSACTIVATION{
  color: red;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioModeradoVBACLIENTSACTIVATION{
  color: #ffae00;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioConservadorVBACLIENTSACTIVATION{
  color: #00ccff;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tipoPerfilPortfolioGeralVBACLIENTSACTIVATION{
  color: #ebebeb;
  font-size: 1.4rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.containerOverlayPreviaPortfolioVBACLIENTSACTIVATION{
  position: absolute;
  width: 100%;
  height: calc(100% - 3rem);
  margin-top: 3rem;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 2fr 3fr;
  background-color: #00000063;
}

.overlayPreviaPortfolioVBACLIENTSACTIVATION{
  width: 100%;
  height: 100%;
}

.containerDetalhesPortfolioModeradoVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #302700;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioConservadorVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #00323f;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioGeralVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #3a3a3a;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}

.containerDetalhesPortfolioAgressivoVBACLIENTSACTIVATION{
  width: 100%;
  height: calc(100vh - 3rem);
  background-color: #270000;
  display: grid;
  grid-template-rows: auto auto 5fr 1fr;
}


.containerHeaderDetalhesPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  height: fit-content;
}

.closeDetailButtonVBACLIENTSACTIVATION{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.closeDetailButton:hoverVBACLIENTSACTIVATION{
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.titleHeaderDetailVBACLIENTSACTIVATION{
  color: white;
  font-size: 1.8rem;
  height: fit-content;
  margin: auto;
  text-align: center;
}

.showInfoDetailVBACLIENTSACTIVATION{
  color: #e6e6e6;
  background: transparent;
  border: none;
  margin: auto;
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.containerSubHeaderDetalhesPortfolioVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 10rem auto 10rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
}

.infoSubHeaderLVBACLIENTSACTIVATION{
  color: white;
  font-size: 1.4rem;
  margin-left: auto;
  width: fit-content;
}

.infoSubHeaderRVBACLIENTSACTIVATION{
  color: white;
  font-size: 1.4rem;
  margin-right: auto;
  width: fit-content;
}

.divisaoSubHeaderConservadorVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: #00ccff;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderGeralVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: #ebebeb;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderModeradoVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: #ffae00;
  margin-left: 2rem;
  margin-right: 2rem;
}

.divisaoSubHeaderAgressivoVBACLIENTSACTIVATION{
  width: 2px;
  height: 100%;
  background-color: red;
  margin-left: 2rem;
  margin-right: 2rem;
}

.containerSecoesPortfolioVBACLIENTSACTIVATION{
  width: 90%;
  margin-left: auto;
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.selectPortfolioAtualVBACLIENTSACTIVATION{
  margin-top: auto;
  font-size: 2rem;
  border-radius: 0;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  text-align: center;
  height: fit-content;
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
}

.selectPortfolioAtual:hoverVBACLIENTSACTIVATION{
  background-color: #e6e6e6;
  color: #141414;
}

.secaoDetalhePortfolioConservadorVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioConservador:hoverVBACLIENTSACTIVATION{
  color: #00ccff;
  cursor: pointer;
}

.secaoDetalhePortfolioGeralVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioGeral:hoverVBACLIENTSACTIVATION{
  color: #ebebeb;
  cursor: pointer;
}

.secaoDetalhePortfolioModeradoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioModerado:hoverVBACLIENTSACTIVATION{
  color: #ffae00;
  cursor: pointer;
}

.secaoDetalhePortfolioAgressivoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.secaoDetalhePortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  color: #ff0000;
  cursor: pointer;
}

.perguntaDetalhePortfolioConservadorVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioConservador:hoverVBACLIENTSACTIVATION{
  color: #00ccff;
  cursor: default;
}

.perguntaDetalhePortfolioGeralVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioGeral:hoverVBACLIENTSACTIVATION{
  color: #ebebeb;
  cursor: default;
}

.perguntaDetalhePortfolioModeradoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioModerado:hoverVBACLIENTSACTIVATION{
  color: #ffae00;
  cursor: default;
}

.perguntaDetalhePortfolioAgressivoVBACLIENTSACTIVATION{
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-left: 3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  transition: all ease-in-out 0.1s;
}

.perguntaDetalhePortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  color: #ff0000;
  cursor: default;
}

.loaderAllFormsVBACLIENTSACTIVATION{
  margin-top: 5rem !important;
}

.loaderSpecificFormVBACLIENTSACTIVATION{
  margin-top: 42vh;
}

.resetFilterVBACLIENTSACTIVATION{
  font-size: 1.2rem;
  font-weight: 400;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  height: 2.4rem;
  width: fit-content;
  border: none;
  background: transparent;
  background-color: rgb(218, 218, 218);
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
  box-shadow: -1px 3px 6px gray;
}

.resetFilter:hoverVBACLIENTSACTIVATION{
  background-color: rgb(218, 218, 218);
  box-shadow: -1px 2px 2px gray;
}

.containerButtonCreateFormVBACLIENTSACTIVATION{
  display: grid;
  height: 6rem;
  margin-top: auto;
  background-color: #d8e0e7;
}

.createFormButtonVBACLIENTSACTIVATION{
  font-size: 2rem;
  background-color: #141414;
  border: none;
  color: #e6e6e6;
  transition: all ease-in-out 0.1s;
  width: fit-content;
  margin: auto;
  margin-right: 1rem;
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.createFormButton:hoverVBACLIENTSACTIVATION{
  background-color: #cecece;
  color: #141414;
}

.containerOverlayVBACLIENTSACTIVATION{
  position: absolute;
  background-color: #d8e0e7;
  width: 100%;
  height: calc(100vh - 3rem);
}

.containerOverlayVBACLIENTSACTIVATION{
  display: grid;
}

.containerEscolhaTituloVBACLIENTSACTIVATION{
  display: grid;
  grid-template-rows: auto auto auto;
  width: 50%;
  margin: auto;
}

.tituloEscolhaTituloVBACLIENTSACTIVATION{
  font-size: 1.8rem;
  color: #11639E;
  width: fit-content;
  margin: auto;
}

.inputReplyTitleVBACLIENTSACTIVATION{
  border: none;
  border-bottom: 2px solid #11639E;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  font-size: 1.2rem;
  width: fit-content;
  padding: 0.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: auto;
  border-radius: 0.1rem;
  box-shadow: -1px 3px 6px gray;
  margin-top: 2rem;
  height: 1.95rem;
}

.inputReplyTitle:focusVBACLIENTSACTIVATION{
  outline: none;
  border-top: 2px solid #11639E;
  border-left: 2px solid #11639E;
  border-right: 2px solid #11639E;
}

.confirmReplyTitleVBACLIENTSACTIVATION{
  width: 30%;
  margin: auto;
  margin-top: 2rem;
  height: fit-content;
  font-size: 1.2rem;
  background-color: #11639E;
  border: none;
  color: white;
  padding: 0.4rem;
  border-radius: 0.2rem;
  transition: all ease-in-out 0.1s;
}

.confirmReplyTitle:hoverVBACLIENTSACTIVATION{
  background-color: #064d80;
}

.containerMeusPortfoliosExistentesVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 15rem;
  width: 98%;
  max-width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow: auto;
}

.MeuItemPortfolioAgressivoVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #270000;
  border: 1px solid red;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioAgressivo:hoverVBACLIENTSACTIVATION{
  border: 1px solid red;
  box-shadow: 0px 0px 20px red;
  cursor: pointer;
}

.MeuItemPortfolioModeradoVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #302700;
  border: 1px solid #ffae00;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioModerado:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ffae00;
  box-shadow: 0px 0px 20px #ffae00;
  cursor: pointer;
}

.MeuItemPortfolioConservadorVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #00323f;
  border: 1px solid #00ccff;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioConservador:hoverVBACLIENTSACTIVATION{
  border: 1px solid #00ccff;
  box-shadow: 0px 0px 20px #00ccff;
  cursor: pointer;
}

.MeuItemPortfolioGeralVBACLIENTSACTIVATION{
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  background-color: #3a3a3a;
  border: 1px solid #ebebeb;
  width: calc(90% - 3rem);
  max-width: calc(90% - 3rem);
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 1.5rem;
}

.MeuItemPortfolioGeral:hoverVBACLIENTSACTIVATION{
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 20px #c2c1c1;
  cursor: pointer;
}

.tituloMeuPortfolioVBACLIENTSACTIVATION{
  color: white;
  font-size: 1rem;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detalheMeuItemPortfolioVBACLIENTSACTIVATION{
  color: white;
  text-align: center;
  height: fit-content;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nomeAssessorPortfolioConservadorVBACLIENTSACTIVATION{
  color: #001318;
  background-color: #33d6ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioModeradoVBACLIENTSACTIVATION{
  color: #1f1900;
  background-color: #ffb923;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioAgressivoVBACLIENTSACTIVATION{
  color: #160000;
  background-color: #ff2d2d;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nomeAssessorPortfolioGeralVBACLIENTSACTIVATION{
  color: #141414;
  background-color: #c2c2c2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 30%);
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
  max-width: 84%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.containerButtonCreateFormVBACLIENTSACTIVATION{
  display: grid;
  grid-template-columns: auto 1fr;
}

.modoEdicaoTextVBACLIENTSACTIVATION{
  font-size: 1.6rem;
  font-weight: 500;
  height: auto;
  width: auto;
  margin: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #188f00;
}

.containerSendFormClientActivationVBACLIENTSACTIVATION{
  height: 6rem;
  background-color: #d6d5d580;
  border-top: 1px solid #f3e3a9;
  display: grid;
  grid-template-columns: 1fr 1fr
}

.activateClientButtonVBACLIENTSACTIVATION{
  font-size: 1.4rem;
  font-weight: 400;
  width: fit-content;
  padding: 0.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  margin-right: 2rem;
  color: #c49e17;
  border: 2px solid #c49e17;
  background-color: white;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
}
.activateClientButtonVBACLIENTSACTIVATION:hover{
  color: #fff;
  border: 2px solid #c49e17;
  background-color: #c49e17;
  box-shadow: 0px 0px 10px #c49e17;
}

.activateClientButtonVBACLIENTSACTIVATIONINACTIVE{
  font-size: 1.4rem;
  font-weight: 400;
  width: fit-content;
  padding: 0.2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  margin-right: 2rem;
  color: #c49e17;
  border: 2px solid #c49e17;
  background-color: white;
  border-radius: 3px;
  transition: all ease-in-out 0.1s;
  opacity: 0.4;
  cursor: default;
}

.obsVBACLIENTSACTIVATION{
  width: 60%;
  margin: auto;
  margin-left: 2rem;
  font-size: 1.1rem;
}

.containerShowFinishedFormVBACLIENTSACTIVATION{
  height: 6rem;
  width: 100%;
  background-color: #d6d5d580;
  border-top: 1px solid #f3e3a9;
  display: grid;
}

.dateFinishedFormVBACLIENTSACTIVATION{
  width: fit-content;
  height: fit-content;
  margin: auto;
  font-size: 1.2rem;
}

.containerWarningEmptyStrutureJsonVBACLIENTSACTIVATION{
  width: 100%;
  text-align: center;
  margin-top: 1.4rem;
  font-size: 1.2rem;
}

.clientStandByContainer {
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -20rem;
  top: 50%;
  margin-top: -10rem;
  z-index: 250;
  box-shadow: 0px 0px 0.5rem 1px var(--goldColor);
  width: 40rem;
  height: 20rem;
  background: white;
  display: flex;
  flex-direction: column;

}

.standByStatusBody{
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: grid;
  grid-template-rows: auto 7rem;
}

.messageAlertVba{
  font-weight: 400;
  font-size: 1.1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.buttonsStandByAlert{
  margin: auto;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.modalBackgroundShow2{
  width: 100%;
  height: 100%;
  background: rgb(0,0,0,0.5);
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 241;
}