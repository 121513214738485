.estructuredsTabOption360 {
    color: white;
    background: #C8AA20;
    height: 2rem;
    min-width: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 0.5rem;
}

.estructuredsTabOption360 div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.estructuredsInactiveTabOption360:hover {
    color: white;
    background-color: #BBBBBB;
}

.estructuredsInactiveTabOption360 {
    color: #c5c5c5;
    font-weight: 500;
    background: #e8e8e8;
    height: 2rem;
    min-width: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

.buttomGray360 {
    background: #868686;
    color: white;
    height: 2rem;
    font-weight: 500;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem ;
    border: none;
    transition: .1s;
    font-size: 0.9rem;
    margin-right: 0.5rem;
}

.buttomGray360:hover {
    background: #D4AC00;
    color: white;
}

.buttonYellowBorderWhite360 {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.2rem solid white;
    border-radius: 1rem;
    padding: 0.8rem 2rem 0.8rem 2rem;
    background-color: #D4AC00;
    font-size: 1rem;
    font-weight: 500;
    transition: 0.1s;
    color: white;
    z-index: 3;
    box-shadow: -6px 0px 7px 3px rgba(0,0,0,0.2);
}

.buttonYellowBorderWhiteInactive360 {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.2rem solid white;
    border-radius: 1rem;
    padding: 0.8rem 2rem 0.8rem 2rem;
    background-color: #C8C5C5;
    font-size: 1rem;
    font-weight: 500;
    transition: 0.1s;
    color: white;
}

.buttonYellowBorderWhiteInactive360:hover {
    background: #acabab;
    color: white;
}