.cenariosAlocacaoTable thead tr th {
    background-color: #f5f5f5;
    color: #D4AC00;;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    height: 2rem;
    position: sticky;
    top: 0;
    z-index: 1;
    /* border: 1px solid #c0c0c0; */
}

.cenariosAlocacaoTable{
    border-collapse: separate; /* Separa as bordas das células */
    border-spacing: 0 10px; 
    padding-left: 1rem;
    padding-right: 1rem;
}



.cenariosAlocacaoTable tbody tr td:last-child{
    cursor: default;
    position: sticky;
    border-right: none;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.cenariosAlocacaoTable tbody tr td:first-child{
    cursor: default;
    position: sticky;
    border-left: none;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.cenariosAlocacaoTable thead {
    color: #D4AC00;
}


.cenariosAlocacaoTable tbody tr td {
    cursor: default;
    color: rgb(188, 188, 188);
    font-size: 14px;
    vertical-align: middle;
    /* border: 1px solid #ddd; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 5rem;
}

.cenariosAlocacaoTable td{
    border-right: 1px solid #D4AC00;
}

.cenariosAlocacaoTable td::before{
    content: '';
    position: absolute;
    top: 20%; /* Ajuste o valor para posicionar a borda corretamente em 60% da altura da célula */
    left: -1px; /* Ajuste o valor para alinhar a borda corretamente no início da célula */
    height: 60%; /* Define a altura da borda como 60% da altura da célula */
    width: 1px;
}

.cenariosAlocacaoTable tr{
    background-color: white;
}


.divRowCenarios{
    background-color: #ffffff;
    border-radius: 1rem;
}


.boxCenariosAlocacaoTable {
    overflow: auto;
    max-height: 34rem;
}

.buttonTableAlocacaoTrash {
    text-align: "center";
    
}

.buttonTableAlocacaoTrash svg {
    text-align: "center";
    color: '#B3B3B3'
}

.buttonTableAlocacaoTrash:hover {
    color: #ffffff;
    background-color: #b81313f8;
}

.buttonTableAlocacaoTrash:hover svg {
    color: #ffffff;
}

.buttonTableAlocacaoAdd {
    background-color: #D4AC00;
    color: #ffffff;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 10px;
    cursor: pointer;
    
}

.buttonTableAlocacaoAdd:hover {
    background-color: #e4bf3a;
    color: #ffffff;
}

.buttonTableAlocacaoEdit {
    /* background-color: #ffffff; */
    
}

.buttonTableAlocacaoEdit svg {
    color: '#B3B3B3'
}

.buttonTableAlocacaoEdit:hover {
    background-color: #c49e17;
}

.buttonTableAlocacaoEdit:hover svg {
    color: #ffffff;
}

.custom-textarea-alocacao {
    resize: none;
    height: calc(1.5em * 4);
    max-height: calc(1.5em * 4); 
    overflow-y: auto; 
    border: 1px solid #c49e17; 
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 90%;
    font-size: 1rem;
    /* padding: 0.4rem; */
    border-radius: 5px;
}

.custom-textarea-alocacao:focus{
    border: none;
    outline: none;
    border: 2px solid #c49e17; 
}

.itemNewCenarioAlocacao{
    /* margin-top: auto; */
    margin-bottom: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    align-items: center;
    text-align: center;
}

.titileNewCenarioALocacao{
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}

.newCenarioAlocacao{
    display: grid;
    grid-template-columns: 3fr 6fr 2fr 3fr 1fr;
    grid-template-rows: 2rem auto;
}

.newSettlementBox{
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-template-rows: 2rem auto;
}

.boxNewCenario{
    display: grid;
    grid-template-columns: auto 15rem;
    /* border: 1px solid #ddd; */
    padding: 0.5rem;
    border-radius: 10px;
}

.boxAvatarAutorCenario{
    width: 4rem;
    height: 4rem;
    background-color: #c0c0c0;
    border-radius: 50%;
    margin: auto;
}

.titleModalTacticalAlocations{
    background: #dbdbdb;
    height: 6rem;
    align-items: center;
    text-align: center;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 60rem; */
  }


  .tacticalEditCheck {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.2rem solid white;
    border-radius: 10px;
    padding: 0.2rem 1rem 0.2rem 1rem;
    background-color: #D4AC00;
    font-size: 20px;
    transition: 0.1s;
    color: white;
    margin: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: -6px 0px 7px 3px rgba(0,0,0,0.2);
}

.tacticalEditCheck:hover {
    background: #c49e17;
    color: white;
}

.selectTacticalAlocationsCategory {
    /* width: 100%; */
    height: 2.5rem;
    border-radius: 10px;
    border: 2px solid #A0A0A0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: auto;
    font-size: 1rem;
    color: #A0A0A0;
}

.file-input-wrapper {
    position: relative;
  }
  
  .custom-file-input {
    display: inline-block;
    padding: 8px 12px;
    background-color: #D4AC00;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .customFileInputCenarios {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-name {
    margin-top: 10px;
    display: block; /* Para colocar o texto em uma linha abaixo */
  }

 /* Esconda o checkbox padrão */
.checkboxNewCenario {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #D4AC00; /* Define a borda vermelha para o checkbox */
    border-radius: 4px; /* Define um leve arredondamento nas bordas */
    outline: none; /* Remove o contorno padrão quando o checkbox é focado */
    cursor: pointer;
  }
  
  /* Estilize o checkbox quando estiver marcado */
  .checkboxNewCenario:checked {
    background-color:  #D4AC00/* Define a cor de fundo vermelha quando o checkbox estiver marcado */
  }