.productCardLSHeader{ 
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
  }

.containerIconCopyContentLsCio{
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    transition: all ease 0.1s;
    margin-left: auto;
  }
  
.containerIconCopyContentLsCio:hover{
    color: #464444;
    cursor: pointer;
}

.productCardLsInfos{
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr 2rem;
    gap: 0.5rem;
    min-height: 4rem;
    text-align: center;
}

.toolbarCioLs{
    margin-top: 1rem;
    margin-left: auto;
    margin-right: 2rem;
    height: 2.5rem;
  }

  .buttonOperateSubmitCioLs{
    height: 1.6rem;
    width: 7rem;
    font-size: 1rem;
    font-weight: 400;
    border: 1px solid #c49e17;
    color: #fff;
    background-color: #c49e17;
    border-radius: 3px;
    transition: all ease-in-out 0.1s;
  }

  .buttonOperateSubmitCioLs:hover{
    background-color: #a88812;
    border: 1px solid #a88812;
    box-shadow: 0px 0px 10px #ffc800;
  }

  .tblLs td{
    border: 1px solid black;
    text-align: center;
  }

  .tblLs th{
    border: 1px solid black;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
  
  .containerOperateProductLs{
    border: 1px solid var(--headerAllBg);
    border-radius: 5px;
    margin: 1rem;
    display: grid;
    grid-template-rows: 6rem auto 20rem;
    /* max-width: 36rem; */
  }
  