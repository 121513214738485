.helpSelectedScreen {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.helpSelectedScreenLeft {
    background: rgb(255, 255, 255);
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-items: center;
    gap: 0.5rem;
}

.helpSelectedScreenLeft label {
    width: 100%;
    transition: 0.2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
}

.helpSelectedScreenLeft label svg {
    margin-right: 1rem;
}

.helpSelectedScreenLeft label:hover {
    background: gray;
}

.helpSelectedScreenLeft a {
    cursor: pointer;
    margin-left: 2rem;
}


.helpSelectedScreenRight {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.helpSelectedScreenRightContent {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 90%;
    padding: 2rem;
    overflow-x: hidden;
    background: white;
    gap: 0.5rem;
}

.helpSelectedScreenRightContent  h1 {
    font-size: 1.8rem;
    font-weight: 300;
}

.helpSelectedScreenRightContent span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

#imgHelp {
    width: 1400px;
}