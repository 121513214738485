.usersModal {
    background: white;
    display: flex;
    flex-direction: column;
    height: 40rem;
    width: 65rem;
    overflow: hidden;
}

.usersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.usersTab {
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
    
}

.usersTabOption {
    color: white;
    background: var(--topBarBg);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.usersTabOption div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.usersInactiveTabOption:hover {
    color: white;
}

.usersInactiveTabOption {
    color: white;
    background: var(--topBarBg);
    color: rgba(255, 255, 255, 0.534);
    height: 2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.usersInactiveTable{
    display: none;
}

.usersTable {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    width: 100%;
}

.usersCadastrationModalBG{
    width: calc(100% - 49px);
    height: calc(100% - 49px);
    background: rgb(0,0,0,0.5);
    position: absolute;
}

.usersCadastrationBGHide {
    display: none;
}

.usersBody {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 95%;
}

.usersTableDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 95%;
}

.usersLoading {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.usersChangePassContainer {
    height: 30rem;
    width: 50rem;
    background: white;
    display: flex;
    flex-direction: column;
}

.usersChangePassContainer form {
    gap: 1rem;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
}

.usersChangePassContainer form label:first-child {
    width: 80%;
}

.usersChangePassContainer form  label:first-child a{
    font-size: 1rem;
}

.usersChangePassContainer form a:nth-child(2) {
    font-size: 1.2rem;
}


.usersChangePassContainer form input {
    width: 20rem;
}



.usersChangePassHeader {
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--headerAllBg);
}


.usersChangePassHeader button {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    border-radius: 50%;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);        
}

.usersChangePassHeader button:hover {
    background: var(--buttonHover);
    color: white;
}

.usersChangePassContainer .usersChangePassHeader p {
    font-size: 1.2rem !important;
    border-bottom: 1px solid var(--goldColor);
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}  

.usersSuccessPassword{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}



.usersChangePassContainer h2{
    font-size: 1.5rem;
    font-weight: 400;
}

@media (max-width: 1368px){

    .usersModal {
        background: white;
        display: flex;
        border-radius: 2rem;
        -webkit-box-shadow: 0px 0px 0.5px 0px var(--goldColor); 
        box-shadow: 0px 0px 0.5rem 1px var(--goldColor);
        flex-direction: column;
        height: 30rem;
        width: 50rem;
        overflow: hidden;
    }

    .usersLoading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .usersChangePassContainer {
        height: 30rem;
        width: 50rem;
        display: flex;
        flex-direction: column;
    }

    .usersChangePassContainer form {
        gap: 1rem;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center ;
    }


    .usersChangePassContainer form a {
        font-size: 1.2rem;
    }

    .usersChangePassContainer form a:first-child {
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }

    .usersChangePassContainer form input {
        width: 20rem;
    }


    .usersChangePassHeader {
        
        height: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: var(--headerAllBg);
    }

    .usersChangePassContainer .usersChangePassHeader p {
        font-size: 1.2rem !important;
        border-bottom: 1px solid var(--goldColor);
        margin-bottom: 0.5rem;
        margin-left: 1rem;
    }  

    .usersChangePassContainer .usersChangePassHeader a {
        font-size: 1rem;
    }

    .usersSuccessPassword{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }


    .usersChangePassContainer h2{
        font-size: 1.5rem;
        font-weight: 400;
    }

    
}