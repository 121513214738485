.clientDetailsConfigContainer {
    width: 95vw;
    max-width: 80rem;
    height: 90vh;
    background: #FBFBFB;
    display: flex;
    flex-direction: column;
}

.clientConfigEditBody{
    margin: 1rem;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
    color: #D4AC00;
    font-weight: 300px;
    justify-content: center;
    gap: 1rem;
    height: 80vh;
    overflow-y: hidden;
}

.clientConfigEditBodyCenarios{
    margin: 1rem;
    display: grid;
    color: #D4AC00;
    font-weight: 300px;
    justify-content: center;
    /* gap: 1rem; */
    /* height: 100%; */
    /* overflow-y: hidden; */
}

.boxSettlementProducts{
    color: #D4AC00;
    font-weight: 300px;
    display: grid;
    grid-template-rows: 3rem 1fr;
}

.bodyCalendarBox{
    display: grid;
    
    height: 84vh;
}

.bodyConfigLeftTable{
    display: grid;
    grid-template-rows: 1.5fr 1.5fr 1.5fr 1.5fr;
}
.bodyConfigLeftTable .configBlocBodyItem{
    /* padding: 17px 0px; */   
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    min-height: 86px;
    margin: 0.5rem 0px;
}

.bodyConfigBloc{
    margin-bottom: 1rem;
    display: flex;
    /* flex-direction: column; */
    gap: 1rem;
    border-radius: 10px;
    background-color: #F4F4F4;

    height: 100%;
 overflow-y: auto;
    
}

.bodyConfigBlocHeader{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    
    display: block;
    width:100%; 
}

.bodyConfigBlocBody {
    /* border: 1px solid rgb(209, 209, 209); */
    border-radius: 10px;
    padding: 1rem;
    /* display: inline-flex;*/
    flex-direction: column;  
    flex-wrap: wrap; 
    justify-content: space-between;
    /* overflow-y: auto;
    overflow-x: hidden; */

}

.bodyConfigBlocCenarios{
    /* margin-bottom: 1rem; */
    /* gap: 1rem; */
    border-radius: 10px;
    background-color: #F5F5F5;

    height: 100%;
 overflow-y: auto;
    
}

.bodyConfigBlocHeaderCenarios{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 1rem;
    /* margin-bottom: 0.5rem; */
    
    display: block;
    width:100%; 
}

.bodyConfigBlocBodyCenarios {
    border-radius: 10px;
    padding: 1rem;
    flex-direction: column;  
    flex-wrap: wrap; 
    justify-content: space-between;

}
.bodyConfigRightTable .configBlocBodyItemProduct {
    text-align: center;
}
.bodyConfigLeftTable .configBlocBodyItem .configBlocBodyHeader{
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: none;
}
.bodyConfigRightTable .configBlocBodyItemProduct .configBlocBodyHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
    
}
.configBlocBodyItem{
    width: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    
}

.configBlocBodyItemProduct{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    /* border: 1px solid #A0A0A0; */
    border-radius: 10px;
    padding: 0.5rem;
    min-width: 8rem;
    height: fit-content;
    flex-basis: 20%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.configBlocBodyHeader{
    /* text-align: center; */
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 400;
}

.configBlocBodyContent{
    min-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #AEAEAE;
    border-radius: 30px;
}

.configBlocBodyHeaderSub{
    text-align: center;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    
}
.bodyConfigRightTable{
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr;
}

.saveAndExitButton{
    display: grid;
    grid-template-columns: auto auto;
    margin-right: 2rem;
    width: 10rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid #c49f17;
    color: #c49f17;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
    vertical-align: middle;
}

.saveAndExitButton.FiSave {
    color: #c49f17;
}

.saveAndExitButton:hover{
    background: #c49f17;
    color: white;
}

.saveAndExitButton:hover .FiSave {
    color: white;
}

.modalChoice {
    display: flex;
    flex-direction: column;
    min-height: 10rem;
    height: fit-content;
    padding: 3rem;
    min-width: 20rem;
    width: fit-content;
    margin: auto;
    position: absolute;
    z-index: 220;
    background-color: white;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 1rem 2px var(--goldColor);
}

.modalChoice a {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--goldColor);
    font-size: 1.8rem;
}

.modalChoice div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalChoice div button {
    width: 100%;
    height: auto;
    background: white;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 400;
    border: 1px solid var(--goldColor);
    color: var(--goldColor);
    transition: 0.2s;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modalChoice div button:hover {
    color: white;
    background: var(--buttonHover);
}

.modalChoiceBackgroundShow {
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    /* background: rgb(0,0,0,0.5); */
    position: absolute;
    z-index: 219;
}