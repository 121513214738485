#structuredLoader {
    height: 20rem;
}

#myStructuredNotifyBox {
    position: absolute;
    bottom:55px;
    right: 30px;
    background: var(--goldColor);
    height: 1.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.5rem;
    font-weight: 500;
    transition: 0.5s ;
    border-radius: 0 1rem 0 1rem;
    animation: notify 1s ease;
    z-index: 100;
    box-shadow: 0px 7px 20px -7px #093459;

}

#myStructuredNotifyBox p {
    color: rgb(49, 49, 49);
}

#myStructuredNotifyBox button {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background: #f0f6fb;
    border:none;
}

@keyframes notify {

    0% {
        opacity: 0;
        transform: translateY(-500px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
    
}

.myStructuredTableArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.myStructuredTableContainer {
 display: flex;
 flex-direction: column;
 align-items: center;
 background: rgb(255, 255, 255);
 width: 96%;
 border-radius: 0.5rem 0 0 0;
 margin-top: 1rem;
 overflow-x: hidden;
 -webkit-box-shadow: -7px 0px 9px -7px #000000; 
 box-shadow: -2px 2px 20px -7px #000000;

}

.mainTableContainer thead tr th{
    
    animation: scroll .5s ease-out;
    transition: 0.2s;
}

.myStructuredLeftDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6rem
}

.myStruturedTheadDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.myStruturedTheadDiv p {
    color: white;
    margin-left: 1rem;
}

.myStruturedTheadDiv div:first-child {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.myStruturedTheadDiv div:first-child a {
    font-size: 1rem;
}

.hideClearOptionButton, .showClearOptionButton {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: white;
    background: rgb(105, 105, 105);
    width: 1rem;
    border-radius: 5px;
    border: none;
}

.showClearOptionButton {
   opacity: 1;
   cursor: pointer;
   transition: 0.2s;
}

.showClearOptionButton:hover {
    filter: brightness(0.9);
}

.hideClearOptionButton { 
    opacity: 0;
    display: none;
}

.myStruturedTheadRightGroup {
    margin-right: 1rem;
    display: flex;
    gap: 2rem;
    flex-direction: row;
    justify-content: center;
}

.myStructuredSelectionFilter {
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    max-width: 8rem;
}

.mainTableContainer thead tr:first-child th{
    background: var(--topBarBg);
    height: 5rem;
    position: sticky;
    top: 0px;
    overflow: hidden;
    color: white;
    font-size: 1.28rem;
    font-weight: 300;
    transition: 0.2s;
    z-index: 10;
    animation: scroll .5s ease-out;  
}

.myStructuredMultipleOtions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
    color: var(--goldColor);
}

.myStructuredMultipleOtions p {
    font-size: 1.1rem;
    color: var(--goldColor)
}

#blankSpace {
    background: var(--topBarBg);
    height: 1.8rem;
    width: 8rem;
}

.buttonTableTheadMyStructured {
    border-radius: 4px;
    font-weight: 400;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    background-color: var(--topBarBg);
    font-size: 1rem;
    width: 8rem;
    height: 2rem;
    transition: 0.2s;
    color: var(--formButtonFontColor)
}

.buttonTableTheadMyStructuredExcelButton {
    border-radius: 4px;
    font-weight: 400;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    cursor: pointer;
    border: 1px solid var(--goldColor);
    background-color: var(--topBarBg);
    font-size: 1rem;
    padding-left:0.2rem;
    padding-right:0.2rem;
    height: 2rem;
    transition: 0.2s;
    color: var(--formButtonFontColor)
}

.buttonTableTheadMyStructuredExcelButton:hover{
    background: var(--goldColor);
    color: black;
}

.tableCheckBox {
    cursor: pointer;
}

.buttonTableTheadMyStructured:hover {
    background: var(--goldColor);
    color: black;
}

#myStructuredTheadFirstTh {
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

#myStructuredTheadFirstTh a {
    font-size: 1rem;
}

#myStructuredMultipleActions {
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid var(--goldColor);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
}

#myStructuredMultipleActions:hover {
    background: var(--goldColor);
    color: black
}

#myStruturedTheadDateSelection {
    display:  flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

#myStruturedTheadDateSelection .myStructuredTheadDateSelectionPeriodsLoader {
    width: 15rem;
}

@keyframes scroll {
    from {
      transform: tranlateY(-100%);
    }
  
    to {
       transform: tranlateY(0%);
    }
  }

.mainTableContainer thead tr:nth-child(2) th{
    background: #3E3E3E;
    height: 1.8rem;
    position: sticky;
    overflow: hidden;
    top: 5rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    z-index: 10;
    transition: 0.9s;
    animation: scroll .5s ease-out;
}

.mainTableContainer tbody {
    overflow: auto;
}

.mainTableContainer td:first-child {
    width: 1rem;
}

.mainTableContainer td:nth-child(2) {
    width: 2rem;
    cursor:  pointer !important;
    color: rgb(97, 97, 97);
    font-size: 1rem;
}

/* .mainTableContainer td:nth-child(8) {
    width: 1rem !important;
    cursor:  pointer !important;
    font-size: 1rem;
} */


.mainTableContainer td:nth-child(2):hover {
    font-size: 1.1rem;
}


.mainTableContainer tr:hover td{
    background: var(--tableHover);
}


.mainTableContainer td{
    border: 1px solid var(--tableBorder);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: default !important;
    background: white;
    max-width: 5rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    height: 2rem;
    font-size: 1rem;
    
}
.mainTableContainer tr{
    transition: 0.1s;
}


.mainTableContainer tr:hover{
    background: var(--tableHover) !important;

}

.mainTableContainer tr:nth-child(even){
    background: var(--tableBorderEven)
}


.myStructuredDetails label {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.myStructuredDetails label a:first-child{
    font-weight: 400;
}

#myStructuredDetailsTable {
    margin-top: 1.5rem;
    
}

#myStructuredDetailsRow {
    transition: all ease 0.2s;
}

.myStructuredDetails { 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 1.2rem;
    align-items: center;
    background: #3e3e3e;
    height: 3rem;
    transition: 0.2s;
    margin: 0 auto;
}

.myStructuredDetails a {
    color: white;
}

.myStructuredDetails a:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
}


.myStructuredDetails:hover {
    background: #444444;
}

@media(max-width: 1368px){
    #myStructuredNotifyBox {
        position: absolute;
        bottom: 60px;
        right: 20px;
        background: var(--goldColor);
        height: 1.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
        padding: 0.3rem;
        font-weight: 500;
        transition: 0.5s ;
        border-radius: 0 1rem 0 1rem;
        animation: notify 1s ease;
        z-index: 100;
        box-shadow: 0px 7px 20px -7px #093459;
    
    }
    
    #myStructuredNotifyBox p {
        font-size: 0.9rem;
        color: rgb(49, 49, 49);
    }
    
    #myStructuredNotifyBox button {
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 0.6rem;
        width: 0.9rem;
        height: 0.9rem;
        background: #f0f6fb;
        border:none;
    }

    .mainTableContainer th {
        font-size: 0.9rem;
    }

    .mainTableContainer td {
        font-size: 0.9rem;
    }

    .myStructuredSelectionFilter {
        background: var(--tableSecondTh);
        color: white;
        font-size: 0.8rem;
        max-width: 6rem;
    }
    
}
