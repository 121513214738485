.mainLongShortInfos {
    width: 60rem;
    height: 50rem;
    background: white;
}

.buttonEditCostLs2{
    background-color: transparent;
    border: none;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 5px;
}

.buttonEditCostLs2:hover{
    background-color: #c4f5bd;
    border-radius: 5px;
}

.titleModalLongShortInfos{
    background: var(--headerAllBg);
    height: 6rem;
    align-items: center;
    text-align: center;
    font-size: x-large;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60rem;
  }

.modalCloseButtonDashboard {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: 0.13rem solid rgb(212, 172, 0);
    background-color: rgba(255, 255, 255, 0);
    font-size: 40px;
    border-radius: 50%;
    transition: 0.1s;
    color: #D4AC00
}

.modalCloseButtonDashboard:hover {
    background: var(--buttonHover);
    color: white;
}

.longShortInfoAcompanhamento {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-top: 2rem;
}

.longShortTable1 {
    margin-top: 2rem;
}

.longShortTable2 th{
    padding: 5px;
}

.longShortInfoParameters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
}

th {
    font-weight: 300;
}

.longShortTable2Th1 {
    text-align: right;
    font-weight: 600;
    padding: 3px;
}

.longShortTable2Th2 {
    text-align: left;
    padding: 3px;
}

.btnLongShortInfosModal.AiFillMessage {
    width: 3rem;
    height: 3rem;
    margin: auto;
    padding: 0.3rem;
    background-color: #fffdf9;
    color: #141414;
    border-radius: 2px;
    font-size: 1rem;
    transition: all ease 0.3s;
    box-shadow: none;
}

.btnLongShortInfosModal:hover {
    color: #C49E17;
    cursor: pointer;
  }

.longShortParamsOperations{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}