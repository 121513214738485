.notificationCenterMainContainer {
    width: 24rem;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background:rgba(15, 15, 15, 0.671);
    position: absolute;
    top: 46px;
    right: 0;
    display: flex;
    flex-direction: column;
    color: white;
    backdrop-filter: blur(6px);
}

#notificationHeader{
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    font-weight: 500;
    font-size: 1rem;
    height: 2.3rem;
    min-height: 2.4rem;
    margin-bottom: 0.7rem;
    border-bottom: 1px solid rgba(117, 117, 117, 0.342);
}

.notificationAlerts {
    overflow-x:hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.notificationAlerts #notificationDiv {
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: center;
    width: 22rem;
    border-radius: 5px;
    padding: 0.3rem;
    background:rgba(197, 197, 197, 0.075);
    transition: 0.2s;
}

.notificationAlerts #notificationDiv:hover {
    background: rgba(197, 197, 197, 0.246);
}

.notificationAlerts div svg {
    margin-left: 1rem;
}

#textNotification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 3rem;
    font-size: 0.8rem;
    cursor: pointer;
    text-align: center;
}

.notificationAlerts label {
     
    display: inline-block;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    transition: 0.2s;
    font-size: 1rem;
    font-weight: 500;
    
}
.notificationAlerts:last-child{
    margin-bottom: 5rem;
}

.notificationTitleText{
    width: 100%;
    white-space: normal !important;
    overflow-wrap: break-word;
}