.booksOperationTable {
  max-height: 45rem;
  width: 95%;
  height: 100%;
  margin-top: 2rem;
}

#booksOperationLegend {
  background: #2c2c2c;
  border-radius: 5px;
  color:rgb(224, 224, 224);
  height: 5rem;
  width: 17rem;
  padding: 0.3rem;
  padding-bottom: 0.3rem;
  margin-top: 12rem;
  /* position: absolute; */
  z-index: 99;
  transition: 2s;
  -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.77); 
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.77);
}

#booksOperationLegend p{
  font-size: 0.8rem;
}

#successButonClose {
  background: rgb(20, 20, 20);
  outline: none;
  color: white;
  border: none;
  margin-right: 0.5rem;
  cursor: pointer;
  width: 2rem;
  border-left: 1px solid rgb(197, 197, 197);
}

#showAlert {
  margin-top: 1rem;
  position: absolute;
  right:0;
  height: 2.5rem;
  left:  49px;
  bottom: 0;
  transition: 0.6s;
  color: #f0f0f0;
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.77); 
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.77);
  width: calc(100% -49px);

}

#showAlert a{
  margin-left: 0.5rem;
}

#hideAlert {
  margin-top: 1rem;
  position: absolute;
  right:0;
  height: 2.2rem;
  opacity: 0;
  left:  49px;
  bottom: 0;
  transition: 0.6s;
  color: #f0f0f0;
  font-weight: 400;
  font-size: 1.2rem;
  background: rgb(20, 20, 20);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  -webkit-box-shadow: 5px 5px 15px -6px rgba(0,0,0,0.77); 
  box-shadow: 0px 0px 25px -6px rgba(0,0,0,0.77);
  width: calc(100% -49px);

}

#booksOperationTdLoading {
  height: 20rem !important;
}

#tabLoading {
  display:  flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

#firsTHTable {
  height: 2rem;
  background: #E5E5E5;
  position: sticky;
  top: 5.5rem;
}

#firsTdBG {
  background: #E5E5E5;
  width: 2rem !important;
}

#booksOperationTableLoader {
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.booksOperationTableContent {
  width: 100%;
  min-height: 8rem;
  max-height: 95%;
  overflow-x: hidden;
  background: white;
  -webkit-box-shadow: -7px 0px 9px -7px #000000; 
  box-shadow: -2px 2px 20px -7px #000000;
}

.booksTabs {
  
  overflow-y: hidden;
  height: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

#booksOperationMenuHeader {
  display: grid;
  align-items: center;
  
}
#booksOperationMenuHeaderRightDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}


#booksOperationMenuHeaderRightDiv #booksOperationTheadButton {

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.3rem;
  outline: none;
  padding-right: 0.5rem;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  width: 8rem;
  height: 1.8rem;
  transition: 0.1s;
  color: var(--formButtonFontColor)
}

#booksOperationMenuHeaderRightDiv #booksOperationTheadButton:hover {
    background: #9e8013;
    color: black;
}

.booksOperationMenuHeaderOrderDiv a{
  font-size: 1rem;
  /* color: var(--goldColor); */
  /* transition: 0.2s; */
  font-weight: 300;
}

.pendingOrders{
  animation: glowing 2000ms infinite;
}

.pendingOrders:hover {
  animation: paused !important;
}

@keyframes glowing {
  0% { background-color: var(--goldColor); box-shadow: 0 0 3px;color: black;}
  50% { background-color: black; box-shadow: 0 0 3px black; color: var(--goldColor);}
  100% { background-color: var(--goldColor); box-shadow: 0 0 3px;color: black;}
}

.notificationOrders{
  background-color: red;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  font-weight: 400;
  margin-top: -1.5rem;
  margin-left: -1.5rem;
}

.booksOperationMenuHeaderOrderDiv svg {
  color: var(--goldColor);
  font-size: 1.3rem;
  filter: brightness(0.8);
  transition: 0.2s;
  
}

.booksOperationMenuHeaderOrderDiv {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.3rem;
  outline: none;
  padding-right: 0.5rem;
  cursor: pointer;
  border: 0.13rem solid var(--formBorder);
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  width: 8rem;
  height: 1.553rem;
  transition: 0.1s;
  color: var(--formButtonFontColor)
}

.booksOperationMenuHeaderOrderDiv:hover {
  background: var(--goldColor);
  color: black;
}

.booksOperationMenuHeaderOrderDiv:hover svg {
  color: black;
  filter: brightness(1);
  transition: 0.2s;
}

.booksOperationMenuHeaderOrderDiv:hover a {
  color:black;
  filter: brightness(1);
  transition: 0.2s;
}

#booksTabsLabel {
  overflow: hidden;
  white-space: nowrap;
  border-radius: 8px 8px 0 0 ;
  height: 100%;
  width: 7rem;
  max-width: 7rem;
  min-width: 7rem;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

#booksTabsLabel a {
  color: rgb(211, 210, 210);
  font-size: 0.9rem;
  filter: brightness(0.80);
  font-weight: 400;
}


#booksTabsLabel:hover {
  filter: brightness(1.3)
}

#booksTabsLabelActive {
  overflow: hidden;
  white-space: nowrap; 
  border-radius: 8px 8px 0 0 ; 
  height: 100%;
  width: 7rem;
  max-width: 7rem;
  min-width: 7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--topBarBg);
  transition: 0.2s;
}

#booksOperationInputFilter {
  padding-left: 0.5rem;
  height: 1.8rem;
  width: 10rem;
  background: #313131;
  border: none;
  outline: none;
  color: white;
  font-size: 1rem;
  margin-right: 1rem;
}

#booksOperationInputFilter::placeholder{
  font-size: 1rem;
}

#booksTabsLabelActive a {
  color: rgb(246, 246, 246);
  font-size: 0.9rem;
  border-bottom: 1px solid var(--goldColor);
}

#booksThead tr:first-child th {
  position: sticky;
  top:0;
  z-index: 20;
}

#booksTheadDiv {
  height: 4rem;
  background: var(--topBarBg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
}

.booksOperationBookName {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

}

#booksThead tr:nth-child(2) th {
  position: sticky;
  top: 4rem;
}

#booksOperationTheadLastTH th {
  background: #E5E5E5;
  height: 1.8rem;
  position: sticky;
  top: 5.2rem;
  z-index: 20;
}

#booksTheadDiv p:first-child {
  margin-left: 30px;
}

#booksTheadDiv p {
  
  color: white;
  font-size: 1.2rem !important;
  font-weight: 300;
}

#booksThead tr:nth-child(2) th {
  color: white;
  background: var(--tableSecondTh);
  font-weight: 300;
  font-size: 1rem;
  height: 2rem;
  z-index: 20;
}

#booksOperationActiveTh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#booksOperationActiveThName {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

#booksOperationActiveThValue {
  border-left: 1px solid #dfdfdf46;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

#booksTheadButtonsGroup {
  display: flex;
  flex-direction: row;
}

#booksTheadButtonsGroup input {
  margin-right: 1rem;
}

.booksOperationTableEmpty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: -7px 0px 9px -7px #000000; 
  box-shadow: -2px 2px 20px -7px #000000;
  
}

#booksOperationTableEmptyTable {
  height: 20rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  align-items: center;
  -webkit-box-shadow: -7px 0px 9px -7px #000000; 
  box-shadow: -2px 2px 20px -7px #000000;
}

#booksOperationTableEmptyTable  a {
  font-size: 1.5rem;
  font-weight: 400;
}

.booksOperationTableEmpty a {
  font-size: 1rem;
  
}

#BooksOperationTableTbody td:nth-child(1) {
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
 }
 

#BooksOperationTableTbody td{
  transition: 0.2s;
  text-align: center;
  height: 2.5rem;
  border-bottom: 1px solid #E5E5E5;
  border-right: 1px solid var(--tableBorder);
}


.booksOperationClosedTr:hover {
  background: #caddf170;
  color: black;
}

.booksOperationActiveNameAndValue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto !important;
  overflow-x: hidden;
  
}


#booksOperationActiveName {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

#booksOperationActiveName div:first-child {
  margin-left: 1.5rem;
}

#booksOperationActiveName div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
}

.booksOperationAssetCardButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  transition: 0.2s;
}

.booksOperationAssetCardButton:hover {
  border-bottom: 1px solid black;
}

.booksOperationAssetNameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.booksOperationStar {
  font-size: 1.2rem;
  color:#9e8013;
  stroke: #9e8013;
}

#booksOperationActiveValue {
  border-left: 1px solid #dfdfdf;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
}


#booksOperationActiveValue label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  margin-left: 1rem;
}


#BooksOperationTableTbody tr:hover #booksOperationPlusIcon {
  display: contents;
}

#BooksOperationTableTbody tr td:first-child {
  width: 25rem;
}


#booksOperationTrActive td {
  border-bottom: none;
  border-left: #5f5f5f;
}

#booksOperationActiveValue #downActive {
  color: rgb(160, 4, 4);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-size: 1rem;
}

#booksOperationActiveValue #downActive i {
  font-weight: 300;
  font-size: 0.7rem;
}

#booksOperationActiveValue #upActive {
  color: rgb(66, 146, 66);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-size: 1rem;
}
#booksOperationActiveValue #upActive i {
  font-weight: 300;
}

#booksOperationBuyBoxOpen {
  background:#333333;
}

#booksOperationBuyBoxClose {
  display: none;
}

.booksBuySell {
  display: flex;
  flex-direction: row;
  width: 90%;  
}
.heightOn {
  height: 90%;
}

.heightOff {
  height:none;
}

.booksOperationTrActive td{
 
 background: var(--topBarBg);
 color: white;
 text-align: center;
 transition: .2s;
 border: 1px solid #2c2c2c !important;
}

#booksOrdersErrorsTr {
  background: rgba(255, 255, 255, 0.219) !important;
  color:rgb(160, 4, 4);
  font-weight: 500;
}


.booksOperationTrActive tr:hover{
  background: var(--topBarBg) !important;
  color: var(--goldColor);
}


#booksOperationBuyBoxClose {
  display: none;
  height: 0;
}

#booksOperationBuyBoxOpen {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.booksBuySell {
  background-color: var(--tableSelectedOperation);
  display: grid;
  grid-template-columns: 15% 85%;
  justify-content: center;
  transition: 0.2s;
  width: 100%;
  height: 6rem;
  font-weight: 100;
}

.booksBuySellFalse {
  display: none;
}

.booksBuySellBtn {
  padding-left: 1rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;    
}

.booksBuySellBtn div:nth-child(2) input[type=checkbox]:before {
  background-color: #CB1111;
}

.booksBuySellOpt{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
}

.booksBuySellOpt input[type=checkbox] {
  position: relative;
  cursor: pointer;
  width: 1rem;
  height: 1.2rem;
}
.booksBuySellOpt input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}

.booksBuySellOpt input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}
.booksBuySellOpt input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 0.2rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.booksBuySellOpt a {
color: white;
margin-left: 0.8rem;
font-size: 1rem;
} 


/* Novos campos envio de orden */
.booksBuySellBtnOptions {
  padding-left: 1rem;
  /* width: 10rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;    
}

.booksBuySellBtnOptions div:nth-child(2) input[type=checkbox]:before {
  background-color: #CB1111;
}

.booksBuySellOptOptions{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
}

.booksBuySellOptOptions input[type=checkbox] {
  position: relative;
  cursor: pointer;
  width: 1rem;
  height: 1.2rem;
}
.booksBuySellOptOptions input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}

.booksBuySellOptOptions input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1.2rem;
  top: 0;
  left: 0;
  background-color:#1E80EF;
}
.booksBuySellOptOptions input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 0.2rem;
  height: 0.6rem;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.booksBuySellOptOptions a {
color: white;
margin-left: 0.8rem;
font-size: 1rem;
} 

/* --- */

.booksClientData {
display: flex;
flex-direction: row;

}

.booksAlignItemsClient {
display: flex;
flex-direction: row;
align-items: center;
gap: 1.5rem;
width: 100%;
}


.booksAlignItemsClient:nth-child(4) div a{
background: red !important;
color: red !important;
}

input[type=number]::-webkit-inner-spin-button { 
-webkit-appearance: none;

}
input[type=number] { 
-moz-appearance: textfield;
appearance: textfield;

}

.booksLabel {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
width: 8rem;
}

.booksLabel a {
font-size: 1rem;
margin-bottom: 0.2rem;
color: white;
}


.booksLabelQtd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  }
  
  .booksLabelQtd a {
  font-size: 1rem;
  margin-bottom: 0.2rem;
  color: white;
  }

  #booksOperationLabelCalculatedQtd {
    border: none;
    height: 2.2rem;
    padding-left: 0.5rem;
    display: flex;
    color: white;
    cursor: not-allowed;
    background: #535355;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 6rem;
  }

  #booksOperationLabelCalculatedValue {
    border: none;
    height: 2.2rem;
    display: flex;
    color: white;
    cursor: not-allowed;
    background: #535355;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 8rem;
  }

  .booksLabelComission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 10rem;
  }
    
  .booksLabelComission a {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    color: white;
  }
  

#booksLabelClientSelection {
width: 12rem;
display: flex;
flex-direction: column;
justify-content: center;
}

#booksLabelClientSelection a {
font-size: 1rem;
margin-bottom: 0.2rem;
color: white;
}

#booksLabelClientSelection label{
  display:  flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#booksLabelClientSelection .MuiInputBase-root {
  color: white;
  font-weight: 300;
  background: #535355;
}

.booksANone {
opacity: 0;
}

.booksLabel select {
width:10rem;
height: 2rem;
outline: none;
border: none;
background-color: rgb(83, 83, 85);
color: white;
font-size: 1rem;
}

.booksLabel .MuiTypography-colorTextSecondary {
  color: white;
  font-weight: 300;
}

.booksLabelQtd input {
padding-left: 0.5rem;
border: none;
width: 6rem;
color: white;
height: 2.2rem;
font-size: 1rem;
font-weight: 300;
background-color: rgb(83, 83, 85);
}

.booksLabelAuto input {
color: white;
margin-left: 0.5rem;
}


.booksLabelButton {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
height: 6rem;
}

.booksLabelButton button {
background-color: #1E80EF;
border:none;
color: white;
height: 2.2rem;
width: 10rem;
margin-top: 0.8rem;
transition: 0.2s;
font-size: 1rem;
cursor: pointer;
font-weight: 400;

}

.booksLabelButton button:hover{
background-color: #1c6bc5;
transition: .2s;
}

.booksLabelAndAskIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
  font-size: 1rem;
  color: #a0a0a0;
}



.booksOperationButtonOrderSend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booksOperationButtonOrderSend:focus {
  background: #CB1111;
}

.booksSendDataTrue {
display: flex;
flex-direction: column;
color: white;
font-size: 1rem;
justify-content: center;
align-items: center;
width: 100%;
height: 6rem;
background-color: #1E80EF;
transition: .2s;
}


.booksSendDataTrue button {
margin-top: 0.5rem;
outline: none;
border: 0.1rem solid white;
width: 5rem;
height: 1.5rem;
background: none;
cursor: pointer;
color: white;
transition: .2s;
}

.booksSendDataTrue button:hover{
background: white;
color:#1c6bc5;
}

.booksSendDataFalse {
display: none;
transition: .2s;
}
#booksShowLoadingScroll{
  background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));
  cursor: default !important;
}

#booksShowLoadingScroll:hover {
  cursor: default !important;
  background: radial-gradient(rgb(255, 255, 255), rgb(218, 218, 218));

}

#booksHideLoadingScroll {
  display: none;
}


.ldsellipsis {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
}
.ldsellipsis div {
  position: absolute;
  top: 0.4rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--headerFontColor);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 1368px){

  .booksOperationTable {
    max-height: 35rem;
    width: 95%;
    height: 100%;
    margin-top: 1rem;
  }

  .booksOperationTableContent {
    width: 100%;
    min-height: 8rem;
    max-height: 85%;
    overflow-x: hidden;
    background: white;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
  }
  
}