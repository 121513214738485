#myClientsPendingsInputFilter {
    margin-right: 1rem;
}

#myIndicationsPendingsHomeLoader {
    height: 15rem;
}

.clientsIndicationPendingsTableContainer tr:hover td {
    background: rgb(204, 204, 204) !important;
}

#myPendingsApprovalList td {
    background: white;
    cursor: pointer;
    font-size: 0.9rem;
}

#myPendingsApprovalList24 td {
    background: rgb(255, 197, 73);
    cursor: pointer;
    font-size: 0.9rem;
}

#myPendingsApprovalList48 td {
    background: rgb(255, 59, 59);
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: underline;
}



.clientsIndicationPendingsTableContainer {
    overflow: auto;
    width: 100%;
    max-height: 40rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;

}

.clientsIndicationPendingsTableFirstThead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 4rem;
    border-radius: 0.5rem 0 0 0;
    background: var(--topBarBg);
}


.clientsIndicationPendingsCursorDefault {
    cursor: default !important;
}

.clientsIndicationTableFirstThead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 4rem;
    border-radius: 0.5rem 0 0 0;
    background: var(--topBarBg);
}

.clientsIndicationPendingsTableFirstThead label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-right: 1rem;
}

.clientsIndicationPendingsTableFirstThead label input {
    width: 10rem;
    height: 1.8rem;
    background: #313131;
    border: none;
    outline: none;
    color: white;
    font-size: 1rem;
    padding-left: 1rem;
}


.clientsIndicationPendingsTableFirstThead p {
    font-size: 1.2rem !important;
    margin-left: 2rem;
    font-weight: 300;
    white-space: nowrap;
}

.totalPendings{
    color: var(--goldColor);
    font-weight: 700;
    font-size: larger;
    margin-left: 1rem;
}