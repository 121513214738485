.brokerPayrollTable {
    display: flex;  
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    
}

.brokerPayrollTableArea {
    margin-top: 1rem;
    -webkit-box-shadow: -7px 0px 9px -7px #000000; 
    box-shadow: -2px 2px 20px -7px #000000;
    overflow-x: auto;
    width: 95%;
}

.brokerPayrollTableLoader {
    height: 20rem;
}

.brokerPayrollTable thead tr:first-child th{
    border-radius: 0.5rem 0 0;
}

.brokerPayrollTable thead tr:first-child th {
    position: sticky;
    top: 0;
    height: 4rem;
    background: var(--topBarBg) !important;
    z-index: 99;
}

.brokerPayrollTable thead tr:nth-child(2) th {
    position: sticky;
    top: 3.9rem;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
}

.brokerPayrollTable table thead tr th div {
    display: flex;
    flex: row;
    align-items: center;
    justify-content: space-between;
}

.brokerPayrollTable table thead tr th div p{
    font-size: 1.2rem;
    color: white;
    font-weight: 300;
    padding-left: 1rem;
}

.payrollHeaderRight {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    gap: 0.5rem;
}

.brokerPayrollTable tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
    white-space: nowrap;
}

.brokerPayrollTable tbody tr:hover td {
    background: var(--tableHover);
}

.containerTabelaExtratoMensal tbody td {
    height: 2rem;
    border: 1px solid var(--tableBorder);
    font-size: 1rem;
    text-align: center;
    background: white;
    cursor: default;
    white-space: nowrap;
}

.containerTabelaExtratoMensal tbody tr:hover td {
    background: var(--tableHover);
}

tbody{
    overflow: auto;
}

.containerTabelaExtratoMensal{
    overflow: auto;

}

.containerTabelaExtratoMensal table tr th{
    position: sticky;
    z-index: 99;
    background: var(--tableSecondTh);
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    height: 1.5rem;
    border-radius: 0px !important;
    white-space: nowrap;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    align-items: center;
}

.containerTabelaExtratoMensal td{
    padding-left: 1rem;
    padding-right: 1rem;
}

.buttonsBrokerPayroll > * {
    margin-right: 1rem;
}

.tdSum{
    background: #c0c0c0 !important;
    border-left: 1px solid #c0c0c0 !important;
    border-right: 1px solid #c0c0c0 !important;
    border-top: 1px solid #c0c0c0 !important;
}

.tdSumEmpty{
    background: #d1d1d1 !important;
    border-left: 1px solid #d1d1d1 !important;
    border-right: 1px solid #d1d1d1 !important;
    border-top: 1px solid #d1d1d1 !important;
}

.unsuitedStructuredContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}


.estructuredsBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    /* height: 100%; */
}

.estructuredsTabOption {
    color: white;
    background: var(--topBarBg);
    height: 2rem;
    width: 15rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.estructuredsTabOption div {
    margin-top: 0.1rem;
    width: 8rem;
    background: var(--formBorder);
    height: .1rem;
}

.estructuredsInactiveTabOption:hover {
    color: white;
}

.estructuredsInactiveTabOption {
    color: white;
    background: var(--topBarBg);
    color: rgba(255, 255, 255, 0.534);
    height: 2rem;
    width: 15rem;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: .1s;
    font-size: 0.9rem;
}

.estructuredsTab {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: pointer;
}

/* .tss-1qtl85h-MUIDataTableBodyCell-root{
    text-align: center !important;
} */

/* .css-1w1rijm-MuiButtonBase-root-MuiButton-root{ */
    /* width: 100% !important;
    max-height: 4rem !important;
    white-space: nowrap !important; */
    /* display: table-cell !important; */
    
/* } */

/* .tss-178gktx-MUIDataTableHeadCell-contentWrapper{
    text-align: center !important
} */
/* .css-1ygcj2i-MuiTableCell-root{
    padding: 0 !important;
} */
