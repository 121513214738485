#showAssetCardContainer {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* overflow: scroll; */
}
.textEditorLoadingAssetCard {
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

#assetCardPDF {
    height: 100%;
    width: 100%;
}

#assetCardPdfButtons {
    background: rgb(59 59 59);
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

#assetCardPdfButtons a {
    color: white;
}

#assetCardPdfButtons input {
    display: none;
}

#assetCardPdfButtons button {
    background: rgb(114, 114, 114);
    border: none;
    width: 10rem;
    height: 2rem;
    margin-left: 0.5rem;
    font-size: 1rem;
    color: rgb(248, 248, 248);
    border-radius: 4px;
}

#assetCardPdfButtons button:hover {
    background: gray;
}

#assetCardPdfButtons button:last-child {
    margin-right: 0.5rem;
    background: rgb(3, 3, 97);
    font-size: 1rem;
    width: 10rem;
    border-radius: 4px;
}
#assetCardPdfButtons button:last-child:hover {
    background: rgb(5, 5, 117);
}


#showAssetCardHeader {
    background-color: var(--topBarBg);
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#showAssetCardHeader p {
    color: white;
    font-size: 1.2rem;
    margin-left: 1rem;
}

#showAssetCardHeader button {

    margin-right: 1rem;
}

#showAssetCardFooter {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

#showAssetCardFooter  button{

    width: 10rem;
    height: 2rem;
    margin-top: 1rem;
    background-color: var(--topBarBg);
    color: white;
    border: none;
    font-size: 1rem;
    transition: 0.2s;
}

#showAssetCardFooter button:hover {
    background: white;
    color: var(--topBarBg);
    border: 1px solid var(--topBarBg);
}

#showAssetCardEmpty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
    gap: 1rem;
}

#showAssetCardEmpty a {
    font-size: 1rem;
}

#showAssetCardEmpty button {
    
    margin-right: 0.5rem;
    background: rgb(3, 3, 97);
    font-size: 1rem;
    width: 10rem;
    height: 2rem;
    margin-top: 1rem;
    color: white;
    border: none;
    font-size: 1rem;
    transition: 0.2s;
}

#showAssetCardEmpty button:hover {
    background: rgb(5, 5, 117);

}

#assetCardPdfButtonsEmpty input {
    display: none;
}


#assetCardNewFileReceveidMsg {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    margin-top: 0.2rem;
    right: 0;
    text-align: center;
    color: rgb(211, 211, 211);
    font-size: 1.5rem;
    font-weight: 500;
    
}