.divLoading{
    background-color: white;
    padding: 3rem;
}

.tss-1nmvwsz-MUIDataTableViewCol-title {
    font-weight: 500 !important;
    font-size: 1.2rem !important;
}

.selectLastInteractionType{
    width: 10rem;
    margin: auto;
    border-left: 10px solid white; /* MIGUÉ PRA MOVER UM POUCO PRA DIREITA */
}

.bgSelectInterationGERAL{
    background-color: #ece3d9;
}
.bgSelectInterationLIGACAO{
    background-color: #CBE6FF;
}
.bgSelectInterationWHATSAPP{
    background-color: #A4F097;
}
.bgSelectInterationREUNIAO{
    background-color: #FEFFBC;
}
.bgSelectInterationEMAIL{
    background-color: #FFC8C8;
}

.containerSelectContactTypeHeader{
    font-weight: 500 !important;
    font-size: 0.8rem !important;
    text-align: center;
    display: grid;
}

.textLastInteractionDays{
    width: 100%;
    text-align: center;
    margin-bottom: 0.2rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    border-radius: 6px;
    transition: all ease 0.2s;
    white-space: nowrap;
}
.textLastInteractionDays:hover{
    background-color: rgb(245, 245, 245);
    cursor: grab;
}

.textSumCell{
    width: 100%;
    text-align: center;
    vertical-align: bottom;
    padding-top: 0.2rem;
    border-radius: 6px;
    transition: all ease 0.2s;
    min-width: 6rem;
    /* position: absolute;
    bottom: 0.5rem; */
    margin-top: 1.5rem;
    /* left: 50%;
    transform: translateX(-50%); */
}
.textSumCell:hover{
    background-color: rgb(245, 245, 245);
    cursor: grab;
}

.textSumCell p{
    color: green;
    margin-top: 0.5rem;
}