.modalHidden{
    position: absolute;
    z-index: 0;
    height: 0;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    left: 0;
    top: 0;
    display: hidden;
}

.modalShow{
    
    overflow: auto;
    display: flex;
    margin: 0 auto;
    position: absolute;
    z-index: 240;
    width: calc(100% - 49px);
    height: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
}

.modalContent {
 
    z-index: 240;
    border-radius: 0.5rem;
    /* -webkit-box-shadow: 0px 0px 0.5px 0px var(--goldColor); 
    box-shadow: 0px 0px 0.5rem 1px var(--goldColor); */
}

.elementsOfModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalBackgroundShow{
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    background: rgb(0,0,0,0.5);
    position: absolute;
    z-index: 151;
}

@media (max-width: 1368px){

    .modalShow{
        min-width: 1150px;
        position: absolute;
        display: flex;
        margin: 0 auto;
        position: absolute;
        z-index: 240;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
        overflow: auto;
    }

    .modalContent {
        max-height: 50rem;
      
    }

    .modalBackgroundShow{
        min-width: 1150px;
        width: calc(100% - 48px);
        height: calc(100% - 48px);
    }
}


.moldaBackgroundHide{
    display: none !important;
}

.displayAlignModal{
    min-width: 1150px;
    display: flex;
    justify-content: center;
}
.displayAlignModalNone{
    display: hidden;
}

.modalContentNone{
    display: none;
}

@media (max-height: 575px){

    .modalShow{
        min-width: 1150px;
        position: absolute;
        display: flex;
        margin: 0 auto;
        position: absolute;
        z-index: 240;
        display: flex;
        align-items: center;
        justify-content: left;
        transition: .2s;
        overflow: auto;
    }
}