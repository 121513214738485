.myPushOrderErrorsTable {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.myPushOrderErrorsTable table thead th {
    position: sticky;
    top: 0;
    background: var(--topBarBg);
    color: white;
    font-size: 1.2rem;
    font-size: 300;
    height: 3rem;
}

.myPushOrderErrorsTable table thead th div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.myPushOrderErrorsTable table thead th div button {
    margin-right: 1rem;
}

.myPushOrderErrorsTable table thead th div p {
    margin-left: 1rem;
}

.myPushOrderErrorsTable table tbody td {
    background: white;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    border: 1px solid var(--tableBorder);
} 